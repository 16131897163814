import styled from '@emotion/styled';
import { FlexGroupWrapper, FlexItem, Text } from '@tecton/ComponentRedesign';
import React, { FC } from 'react';
import { GlobalSearchFCOProps } from './GlobalSearch';
import SearchListResult from './SearchResult';

export type SearchResultGroupNames = 'Name' | 'Description' | 'Tag' | 'Owner' | 'ID';

interface SearchResultGroupProps {
  groupName: SearchResultGroupNames;
  searchResults: GlobalSearchFCOProps[];
  focusedIndex: number;
  setFocusedIndex: (value: React.SetStateAction<number>) => void;
  itemRefs: React.MutableRefObject<(HTMLButtonElement | HTMLAnchorElement | null)[]>;
  navigateToFco: (fco: GlobalSearchFCOProps) => void;
  filterValue: string | undefined;
}

const GroupHeader = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.subduedText};
  padding: ${({ theme }) => theme.spacing.sm} ${({ theme }) => theme.spacing.xl};
`;

const SearchResultGroup: FC<SearchResultGroupProps> = ({
  groupName,
  searchResults,
  focusedIndex,
  setFocusedIndex,
  itemRefs,
  navigateToFco,
  filterValue,
}) => {
  if (searchResults.length === 0) {
    return <></>;
  }

  if (groupName === 'Name') {
    return (
      <>
        {searchResults.map((item: GlobalSearchFCOProps, index: number) => {
          return (
            <>
              <SearchListResult
                searchResult={item}
                index={index}
                focusedIndex={focusedIndex}
                setFocusedIndex={setFocusedIndex}
                itemRefs={itemRefs}
                navigateToFco={navigateToFco}
                filterValue={filterValue}
              />
            </>
          );
        })}
      </>
    );
  }

  return (
    <>
      <FlexGroupWrapper gap="none">
        <FlexItem>
          <GroupHeader>
            <Text element="p" small weight={'semiBold'} inline>
              SEARCH BY {groupName.toUpperCase()}
            </Text>
          </GroupHeader>
        </FlexItem>
        <FlexItem>
          {searchResults.map((item: GlobalSearchFCOProps, index: number) => (
            <>
              <SearchListResult
                searchResult={item}
                index={index}
                focusedIndex={focusedIndex}
                setFocusedIndex={setFocusedIndex}
                itemRefs={itemRefs}
                navigateToFco={navigateToFco}
                filterValue={filterValue}
              />
            </>
          ))}
        </FlexItem>
      </FlexGroupWrapper>
    </>
  );
};

export default SearchResultGroup;
