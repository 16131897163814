import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { transformJobs } from '../components/jobs/jobsTransform';
import {
  CancelJobRequest,
  CancelJobResponse,
} from '../types/tecton_proto/materializationjobservice/materialization_job_service';
import { MDS_PATH, SimpleError, Tecton_Api } from './';

import _camelCase from 'lodash/camelCase';
import _snakeCase from 'lodash/snakeCase';
import {
  ForceRetryMaterializationTaskRequest,
  ForceRetryMaterializationTaskResponse,
  GetJobDetailsRequest,
  GetJobDetailsResponse,
  GetJobLogsRequest,
  GetJobLogsResponse,
  GetJobsRequest,
  GetJobsResponse,
} from '../types/tecton_proto/metadataservice/metadata_service';
import IdUtils from '../utils/id-utils';
import { GetJobsRequest as GetJobsRequestGQL } from './gql/graphql';
import { convertKeysToSnakeCase } from './utils';

// using Signal will cancel old requests when new requests of the same queryKey are made
export const fetchJobs = async (args: GetJobsRequestGQL, signal?: AbortSignal): Promise<GetJobsResponse> => {
  // while migrating to GraphQL
  if (args?.pagination?.sortKey) args.pagination.sortKey = _snakeCase(args.pagination.sortKey);

  const response = await Tecton_Api(`${MDS_PATH}jobs`, { data: convertKeysToSnakeCase(args), method: 'POST', signal });
  if (response?.data?.pagination?.sort_key)
    response.data.pagination.sort_key = _camelCase(response?.data?.pagination?.sort_key);

  return response.data;
};

export const useGetJobsQuery = (args: GetJobsRequestGQL) => {
  return useQuery(['jobs', args], () => fetchJobs(args), {
    keepPreviousData: true,
    select: (data) => transformJobs(data),
    notifyOnChangeProps: ['data', 'error', 'isFetching', 'isLoading'],
  });
};

//MUTATIONS
const updateForceRetryMaterializationTask = async (
  args: ForceRetryMaterializationTaskRequest
): Promise<ForceRetryMaterializationTaskResponse> => {
  const response = await Tecton_Api.post(`${MDS_PATH}force-retry-materialization-task`, args);
  return response.data;
};

const updateCancelJob = async (args: CancelJobRequest): Promise<CancelJobResponse> => {
  const response = await Tecton_Api.post(`jobs/cancel-materialization-job`, args);
  return response.data;
};

// SIMPLY ERROR OBJECT
const MutationError = (error: any) => {
  // transform error to custom type
  const simpleError: SimpleError = {
    message: 'An error occurred',
    code: 'UNKNOWN',
  };

  if (error.response) {
    simpleError.message = error?.response?.data?.message || error?.message || simpleError.message;
    simpleError.code = error?.code as string;
  }

  throw simpleError;
};

export const useGetInfiniteJobs = (args: GetJobsRequest, keepPreviousData: boolean) => {
  return useInfiniteQuery(
    ['infinite-jobs', args],
    ({ pageParam = '', signal }) => {
      const params: GetJobsRequest = {
        ...args,
        pagination: {
          ...args.pagination,
          page_token: pageParam,
        },
      };

      return fetchJobs(params as GetJobsRequestGQL, signal);
    },
    {
      refetchOnWindowFocus: false,
      keepPreviousData,
      getNextPageParam: (lastPage) => {
        return lastPage?.pagination?.next_page_token || '';
      },
      select: (data) => {
        return {
          pageParams: data.pageParams,
          pages: data.pages.map((page) => transformJobs(page)),
        };
      },
      notifyOnChangeProps: ['data', 'error', 'isFetching', 'isLoading', 'isFetchingNextPage'],
    }
  );
};

export const useForceRetryMaterializationTaskMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (payload: ForceRetryMaterializationTaskRequest) => {
      return await updateForceRetryMaterializationTask(payload);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['infinite-jobs']);
      },
      onError: MutationError,
    }
  );
};
export const useCancelJobMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (payload: CancelJobRequest) => {
      return await updateCancelJob(payload);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['infinite-jobs']);
      },
      onError: MutationError,
    }
  );
};

const fetchJobDetails = async (args: GetJobDetailsRequest): Promise<GetJobDetailsResponse> => {
  const response = await Tecton_Api.post(`${MDS_PATH}job-details`, args);
  return response.data;
};

const fetchJobLogs = async (args: GetJobLogsRequest): Promise<GetJobLogsResponse> => {
  const response = await Tecton_Api.post(`${MDS_PATH}job-logs`, args);
  return response.data;
};

export const useGetJobDetails = (tecton_managed_attempt_id: string) => {
  const payload: GetJobDetailsRequest = { tecton_managed_attempt_id: IdUtils.fromStringId(tecton_managed_attempt_id) };

  return useQuery(['job-details', tecton_managed_attempt_id], () => {
    return fetchJobDetails(payload);
  });
};

type UseGetJobLogsOptions = {
  enabled?: boolean;
};

export const useGetJobLogs = (tecton_managed_attempt_id: string, options: UseGetJobLogsOptions = {}) => {
  const payload: GetJobLogsRequest = { tecton_managed_attempt_id: IdUtils.fromStringId(tecton_managed_attempt_id) };

  return useQuery(
    ['job-logs', tecton_managed_attempt_id],
    () => {
      return fetchJobLogs(payload);
    },
    options
  );
};
