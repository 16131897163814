import React from 'react';

import styles from './styles.module.css';

interface ContentAndDocsTwoColumnLayoutProps {
  mainColumn?: React.ReactNode;
  docsColumn?: React.ReactNode;
}

const ContentAndDocsTwoColumnLayout = ({ mainColumn, docsColumn }: ContentAndDocsTwoColumnLayoutProps) => {
  return (
    <div id="content" role="main" className={styles['with-docs-column-wrapper']}>
      <section className={styles['main-column']}>{mainColumn}</section>
      <section className={styles['docs-column']}>{docsColumn}</section>
    </div>
  );
};

export default ContentAndDocsTwoColumnLayout;
