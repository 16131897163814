import { DataSourceFCOType } from '../../../../../core/types/fcoTypes';
import { DataFlowEdge, DataFlowNode, NodesMapType } from '../dataFlowTypes';
import edgeIdFn from './edgeIdFn';
import { getVisibleAnimationsOnAllEdgesGivenAnchors, VisibleAnimationRecord } from './highlight-utils';
import highlightDownstream from './highlightDownstream';
import { HighlightFunctionType } from './highlightFunctionType';
import highlightUpstream from './highlightUpstream';

const upstreamEdgeShouldBeSkippedForOnlineStore = (sourceNode: DataFlowNode, targetNode: DataFlowNode) => {
  /**
   * We are highlighting the online store. Should a raw_batch_node be highlighted?
   *
   * It depends on whether it is used in a batch or stream data source.
   *
   * If it is used in a batch data source, then data from it will end up in the
   * online store, so it should not be skipped.
   *
   * If on the other hand, it is used in a stream data source, then data from
   * this raw batch source won't be used (b/c it will come from the stream
   * source instead.)
   */

  if (
    sourceNode.type === 'raw_batch_node' &&
    targetNode.type === 'data_source' &&
    targetNode.dataSourceType !== DataSourceFCOType.BATCH
  ) {
    return true;
  }

  return false;
};

const upstreamEdgeShouldBeSkippedForOfflineStore = (sourceNode: DataFlowNode, _targetNode: DataFlowNode) => {
  if (sourceNode.type === 'raw_stream_node' && sourceNode.pushesToOfflineStore !== true) {
    return true;
  }

  return false;
};

const downstreamEdgeShouldBeSkippedForOnlineStore = (sourceNode: DataFlowNode, targetNode: DataFlowNode) => {
  /**
   * Since we are highlighting the online store,
   * and model training never uses the online store,
   * we skip that connection
   */
  const targetIsModelTrainer = targetNode.type === 'model_trainer';

  /**
   * If the feature service has online serving disabled,
   * then we skip the path to the model inference node as well.
   */
  const targetIsOfflineModelInference =
    targetNode.type === 'model_inference' &&
    sourceNode.type === 'feature_service' &&
    !sourceNode.isOnlineServingEnabled;

  if (targetIsModelTrainer || targetIsOfflineModelInference) {
    return true;
  }

  return false;
};

const downstreamEdgeShouldBeSkippedForOfflineStore = (sourceNode: DataFlowNode, targetNode: DataFlowNode) => {
  if (sourceNode.type === 'feature_service' && targetNode.type === 'model_inference') {
    /**
     * When highlighting the offline store,
     * whether we show the model inference
     * node depends on whether online serving
     * is enabled on the feature service.
     */
    if (sourceNode.isOnlineServingEnabled) {
      /**
       * If online serving is enabled, then means
       * inference is using the online store,
       * and thus we can skip it when highlighting the
       * offline store.
       */
      return true;
    } else {
      /**
       * Otherwise it is shown
       */

      return false;
    }
  }

  return false;
};

const animationsAccumulator = () => {
  const record: Record<string, VisibleAnimationRecord> = {};

  const addAnimation = (key: string, animations: VisibleAnimationRecord) => {
    record[key] = {
      ...record[key],
      ...animations,
    };
  };

  return {
    record,
    addAnimation,
  };
};

export const highlightStoreLinkages = (
  nodesMap: NodesMapType,
  edgesList: DataFlowEdge[],
  storeType: 'ONLINE_STORE' | 'OFFLINE_STORE' | 'STORE_WRAPPER'
) => {
  /**
   * Set up
   */
  const allAnimations = animationsAccumulator();

  /**
   * Trace Upstream Edges
   */
  const edgesFlowingIntoStore = edgesList.filter(({ source, target }) => {
    if (target !== 'STORE') {
      return false;
    }

    const node = nodesMap[source];

    /**
     * Check whether this feature view is materializing
     * to the Online/Offline store.
     *
     * It should only be highlighted if that type of
     * materialization is active.
     */
    if (node.type === 'feature_view') {
      if (storeType === 'ONLINE_STORE' && node.isOnlineMaterializationEnabled !== true) {
        return false;
      }

      if (storeType === 'OFFLINE_STORE' && node.isOfflineMaterializationEnabled !== true) {
        return false;
      }
    }

    return true;
  });

  const upstreamStartingNodes = edgesFlowingIntoStore.map(({ source }) => source);

  const upstreamSets = upstreamStartingNodes.map((id) => {
    return highlightUpstream(id, nodesMap, edgesList, {
      edgeShouldBeSkipped:
        storeType === 'STORE_WRAPPER'
          ? undefined
          : storeType === 'ONLINE_STORE'
          ? upstreamEdgeShouldBeSkippedForOnlineStore
          : upstreamEdgeShouldBeSkippedForOfflineStore,
    });
  });

  upstreamSets.forEach((branch, index) => {
    const id = upstreamStartingNodes[index];
    const fv = nodesMap[id];

    let showBatchMaterializationPath = false;
    let showStreamMaterializationPath = false;

    if (!fv) {
      throw new Error(`Expect upstream starting node to exist.`);
    }

    if (fv.type === 'transformation') {
      console.log('Not handling transformations yet.');

      return;
    }

    if (fv.type !== 'feature_view') {
      throw new Error(`Expect upstream starting node to be a Feature View. Received ${(JSON.stringify(fv), null, 2)}`);
    }

    branch.linkedEdges.add(edgeIdFn({ source: id, target: 'STORE' }));

    if (storeType === 'STORE_WRAPPER' || storeType === 'ONLINE_STORE') {
      branch.linkedEdges.add(edgeIdFn({ source: 'store-input', target: 'ONLINE_STORE' }));

      showStreamMaterializationPath = !!(fv.featureViewType === 'stream' && fv.isOnlineMaterializationEnabled);

      if (fv.featureViewType !== 'stream') {
        showBatchMaterializationPath = !!fv.isOnlineMaterializationEnabled;
      }
    }

    if (storeType === 'STORE_WRAPPER' || storeType === 'OFFLINE_STORE') {
      branch.linkedEdges.add(edgeIdFn({ source: 'store-input', target: 'OFFLINE_STORE' }));

      showBatchMaterializationPath = true;
    }

    const branchAnimations = getVisibleAnimationsOnAllEdgesGivenAnchors(
      branch.linkedIds,
      edgesList,
      branch.linkedEdges,
      {
        showBatchMaterializationPath,
        showStreamMaterializationPath,
      }
    );

    Object.entries(branchAnimations).forEach(([key, animations]) => {
      allAnimations.addAnimation(key, animations);
    });
  });

  /**
   * Trace Downstream Edges
   */
  const edgesFlowingOutOfStore = edgesList.filter(({ source, target, pathProperties }) => {
    if (source === 'STORE') {
      if (storeType === 'STORE_WRAPPER') {
        return true;
      }

      const targetNode: DataFlowNode = nodesMap[target];

      if (storeType === 'ONLINE_STORE') {
        if (targetNode.type === 'feature_service') {
          return targetNode.isOnlineServingEnabled;
        }

        if (
          targetNode.type === 'odfv' ||
          targetNode.type === 'transformation' ||
          targetNode.type === 'aggregation' ||
          targetNode.type === 'feature_view'
        ) {
          return true;
        }

        throw new Error(
          'Expecting paths coming out of the store to only be Feature Views, ODFVs, Transformations, Aggregation, or Feature Services.'
        );
      }

      if (storeType === 'OFFLINE_STORE') {
        // highlighted node is OFFLINE_STORE
        if (targetNode.type === 'feature_service') {
          return pathProperties?.isOfflineReadPath;
        }

        if (targetNode.type === 'odfv' || targetNode.type === 'transformation') {
          const upstreamFVsThatUsesTheOfflineStore = targetNode.upstreamNodes.filter((id) => {
            const node = nodesMap[id];

            return node.type === 'feature_view' && node.isOfflineMaterializationEnabled;
          });

          return upstreamFVsThatUsesTheOfflineStore.length > 0;
        }

        if (targetNode.type === 'aggregation' || targetNode.type === 'feature_view') {
          return true;
        }

        throw new Error(
          'Expecting paths coming out of the store to only be Feature Views, ODFVs, Transformations, Aggregation, or Feature Services.'
        );
      }

      throw new Error(`Expecting store type to be one of: ONLINE_STORE, OFFLINE_STORE, STORE_WRAPPER`);
    } else {
      return false;
    }
  });

  let downstreamStartingNodes: string[] = [];

  const featureViewAnchorNode = Object.values(nodesMap).find((node) => {
    return node.type === 'feature_view' && node.isAnchor;
  });

  if (featureViewAnchorNode) {
    // downstream starting nodes should be
    // all of the downstream nodes from the anchor
    // feature view
    downstreamStartingNodes = featureViewAnchorNode.downstreamNodes || [];

    Object.values(nodesMap).forEach((node) => {
      if (node.type === 'aggregation') {
        downstreamStartingNodes.push(node.id);
      }
    });
  } else {
    downstreamStartingNodes = edgesFlowingOutOfStore.map(({ target }) => target);
  }

  const downstreamSets = downstreamStartingNodes.map((id) => {
    return highlightDownstream(id, nodesMap, edgesList, {
      edgeShouldBeSkipped:
        storeType === 'STORE_WRAPPER'
          ? undefined
          : storeType === 'ONLINE_STORE'
          ? downstreamEdgeShouldBeSkippedForOnlineStore
          : downstreamEdgeShouldBeSkippedForOfflineStore,
    });
  });

  downstreamSets.forEach((branch, index) => {
    const id = downstreamStartingNodes[index];

    branch.linkedEdges.add(edgeIdFn({ source: 'STORE', target: id }));

    if (storeType === 'STORE_WRAPPER' || storeType === 'ONLINE_STORE') {
      branch.linkedEdges.add(edgeIdFn({ source: 'ONLINE_STORE', target: 'store-output' }));
    }

    if (storeType === 'STORE_WRAPPER' || storeType === 'OFFLINE_STORE') {
      branch.linkedEdges.add(edgeIdFn({ source: 'OFFLINE_STORE', target: 'store-output' }));
    }

    const branchAnimations = getVisibleAnimationsOnAllEdgesGivenAnchors(
      branch.linkedIds,
      edgesList,
      branch.linkedEdges,
      {
        showOfflineReadPath: storeType === 'STORE_WRAPPER' || storeType === 'OFFLINE_STORE',
        showOnlineServingPath: storeType === 'STORE_WRAPPER' || storeType === 'ONLINE_STORE',
      }
    );

    Object.entries(branchAnimations).forEach(([key, animations]) => {
      allAnimations.addAnimation(key, animations);
    });
  });

  /**
   * Collect IDs and Edges
   */
  const linkedIds = new Set<string>();
  const linkedEdges = new Set<string>();

  upstreamSets.forEach((s) => {
    s.linkedIds.forEach((id) => {
      linkedIds.add(id);
    });

    s.linkedEdges.forEach((e) => {
      linkedEdges.add(e);
    });
  });

  downstreamSets.forEach((s) => {
    s.linkedIds.forEach((id) => {
      linkedIds.add(id);
    });

    s.linkedEdges.forEach((e) => {
      linkedEdges.add(e);
    });
  });

  /**
   * Get edges directly around the store
   */
  edgesFlowingIntoStore.forEach((e) => {
    linkedEdges.add(edgeIdFn(e));
  });

  edgesFlowingOutOfStore.forEach((e) => {
    linkedEdges.add(edgeIdFn(e));
  });

  /**
   * Get edges inside of the store
   */
  if (storeType === 'ONLINE_STORE' || storeType === 'STORE_WRAPPER') {
    linkedIds.add('ONLINE_STORE');

    if (upstreamSets.length > 0) {
      linkedEdges.add(edgeIdFn({ source: 'store-input', target: 'ONLINE_STORE' }));
    }

    if (downstreamSets.length > 0) {
      linkedEdges.add(edgeIdFn({ source: 'ONLINE_STORE', target: 'store-output' }));
    }
  }

  if (storeType === 'OFFLINE_STORE' || storeType === 'STORE_WRAPPER') {
    linkedIds.add('OFFLINE_STORE');

    if (upstreamSets.length > 0) {
      linkedEdges.add(edgeIdFn({ source: 'store-input', target: 'OFFLINE_STORE' }));
    }

    if (downstreamSets.length > 0) {
      linkedEdges.add(edgeIdFn({ source: 'OFFLINE_STORE', target: 'store-output' }));
    }
  }

  return {
    linkedIds,
    linkedEdges,
    animations: allAnimations.record,
  };
};

export const highlightOnlineStore: HighlightFunctionType = (id, nodesMap, edgesList) => {
  return highlightStoreLinkages(nodesMap, edgesList, 'ONLINE_STORE');
};

export const highlightOfflineStore: HighlightFunctionType = (id, nodesMap, edgesList) => {
  return highlightStoreLinkages(nodesMap, edgesList, 'OFFLINE_STORE');
};

export const highlightWholeStore: HighlightFunctionType = (id, nodesMap, edgesList) => {
  return highlightStoreLinkages(nodesMap, edgesList, 'STORE_WRAPPER');
};
