import React, { useState } from 'react';
import { useParams } from 'react-router';
import { Box, Grid, Typography } from '@mui/material';
import DateRangeSelectorMonitoring from '../../../components/monitoring/DateRangeSelectorMonitoring';
import { Stack } from '@mui/material';
import {
  getDefaultMonitoringDateRange,
  MonitoringDateRange,
} from '../../../components/monitoring/DateRange-Dropdown-util';
import { logEvent } from '../../../utils/analytics-utils';
import { FEATURE_VIEW_MONITORING_SUMMARY_GRAPH } from '../../graph-configuration';
import MonitoringErrorBoundary from '../../../components/monitoring/MonitoringErrorBoundary';
import NumberOfFeatureViewsAndTablesTotal from './monitoring/NumberOfFeatureViewsAndTablesTotal';
import NumberOfFeatureViewsAndTablesMaterialized from './monitoring/NumberOfFeatureViewsAndTablesMaterialized';
import FeatureViewHealthDrillDowns from './monitoring/FeatureViewHealthDrillDowns';
import FeatureViewFailingIds from './monitoring/FeatureViewFailingIds';
import { CustomGraph } from '../graph-types';
import MonitoringGraphs from '../MonitoringGraphs';

const MonitoringSummary = () => {
  const { workspace } = useParams();
  const defaultMonitoringDateRange: MonitoringDateRange = getDefaultMonitoringDateRange();
  const [monitoringDateRange, setMonitoringDateRange] = useState<MonitoringDateRange>(defaultMonitoringDateRange);
  const onChange = (monitoringDateRange: MonitoringDateRange) => {
    setMonitoringDateRange(monitoringDateRange);
  };

  logEvent(`Graphs Feature View Monitoring Summary`, '', { workspace: workspace });

  const customGraphs: Record<string, React.ReactNode> = {
    [CustomGraph.NUMBER_OF_FEATURE_VIEWS_AND_TABLE]: (
      <Box display={'flex'}>
        <Box flex={1} className="pa2">
          <Grid container justifyContent={'center'} alignItems={'center'} className="pt3">
            <Typography style={{ fontWeight: 'bold', color: '#74808e' }}> # of Feature Views and Table</Typography>
          </Grid>
          <Box display={'flex'}>
            <Box className="pa2" flex={1}>
              <MonitoringErrorBoundary>
                <NumberOfFeatureViewsAndTablesTotal monitoringDateRange={monitoringDateRange} />
              </MonitoringErrorBoundary>
            </Box>
            <Box className="pa2" flex={1}>
              <MonitoringErrorBoundary>
                <NumberOfFeatureViewsAndTablesMaterialized monitoringDateRange={monitoringDateRange} />
              </MonitoringErrorBoundary>
            </Box>
          </Box>
        </Box>
      </Box>
    ),
    [CustomGraph.FEATURE_VIEW_DRILL_DOWN]: (
      <Box display={'flex'} width={'100%'}>
        <Box flex={1} className="pa2">
          <MonitoringErrorBoundary>
            <FeatureViewHealthDrillDowns monitoringDateRange={monitoringDateRange} />
          </MonitoringErrorBoundary>
        </Box>
        <Box flex={1} className="pa2">
          <MonitoringErrorBoundary>
            <FeatureViewFailingIds monitoringDateRange={monitoringDateRange} />
          </MonitoringErrorBoundary>
        </Box>
      </Box>
    ),
  };

  return (
    <Stack spacing={2}>
      <Box display={'flex'}>
        <Box flex={1}>
          <DateRangeSelectorMonitoring onChange={onChange} />
        </Box>
      </Box>

      <MonitoringGraphs
        monitoringDateRange={monitoringDateRange}
        graphsData={FEATURE_VIEW_MONITORING_SUMMARY_GRAPH}
        customGraphRecord={customGraphs}
        workspace={workspace ?? ''}
      />
    </Stack>
  );
};

export default MonitoringSummary;
