import React, { FC } from 'react';
import { Stack } from '@mui/material';
import { RequestInterface, DatabricksSettingsInterface } from '../common/types';
import DatabricksCredentialsForm, {
  UpdateUserDeploymentSettingsRequestInterface,
} from '../../../../feature/data-platform-configuration/databricks/setup/DatabricksCredentialsForm';
import { useDatabricksConfiguration } from '../../../../feature/data-platform-configuration/query';
import { LoadingIndicator } from '../../../LoadingIndicator';
import UserDeploymentSettings from '../../../../service/UserDeploymentSettings';

export interface DatabricksRequestInterface extends RequestInterface {
  data?: unknown;
  sendRequest?: (
    settings: UpdateUserDeploymentSettingsRequestInterface,
    onSuccess?: (response: any) => void,
    onError?: (response: any) => void
  ) => void;
}

export interface CreateNotebookInterface extends RequestInterface {
  data?: unknown;
  sendRequest?: (settings: DatabricksSettingsInterface) => void;
}

export interface ValidateGlueCatalogInterface extends RequestInterface {
  data?: unknown;
  sendRequest?: (glueCatalog: string) => void;
}
interface ConfigureDatabricksInterface {
  validateDatabricksConnectionRequest: DatabricksRequestInterface;
}

const ConfigureDatabricks: FC<ConfigureDatabricksInterface> = ({ validateDatabricksConnectionRequest }) => {
  const configurationState = useDatabricksConfiguration();

  let body: React.ReactNode = <></>;

  if (configurationState.isLoading) {
    body = <LoadingIndicator title="Loading Configuration" />;
  }

  if (configurationState.isSuccess) {
    const userDeploymentSettings: UserDeploymentSettings = configurationState.data;

    body = (
      <Stack spacing={3}>
        <DatabricksCredentialsForm
          userDeploymentSettings={userDeploymentSettings}
          validateDatabricksConnectionRequest={validateDatabricksConnectionRequest}
          isGuidedOnboarding={true}
        />
      </Stack>
    );
  }

  return <>{body}</>;
};

export default ConfigureDatabricks;
