import { useState } from 'react';
import { Navigate, Outlet, Route, Routes, useLocation, useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';

// Tecton
import { useGetFcos } from '../../../api/fcos';
import {
  FCOType,
  FeatureViewFCO,
  FeatureViewFCOFields,
  FeatureViewFCOType,
  WorkspaceFCOContainer,
} from '../../../core/types/fcoTypes';
import { useUserSettings } from '../../context/UserSettingsContext';
import { EntityRoutes, getEntityRouteName, getEntityRoutes } from './entitiesUtils';
import EntityCards from './EntityOverview';

// @tecton
import { Breadcrumbs, HeaderLayout, RelatedFCOsTable, Subtitle, Tabs, ViewLayout } from '@tecton/ComponentRedesign';
import WorkspaceRootBreadcrumb from '../WorkspaceRootBreadcrumb';

const EntityContainerOutlet = () => {
  /*
    This contains the header portion of the Feature view
    - Breadcrumbs
    - Tabs
    - Outlet to the content
  */
  const navigation = useNavigate();
  const { name } = useParams();
  const { workspace, workspaceDetails } = useUserSettings();
  const location = useLocation();
  const featureRoutes = getEntityRouteName(location.pathname);
  const [selectedTab, setSelectedTab] = useState<EntityRoutes>(featureRoutes);

  const { data, isLoading } = useGetFcos(workspace ?? '', {
    select: (data: WorkspaceFCOContainer) => {
      return {
        entity: data?.entities.find((entity) => {
          return entity?.name === name;
        }),
        featureViews: data?.featureViews,
      };
    },
  });

  if (isLoading) {
    // TODO: Let's figure out what loader to use
    return <></>;
  }

  const targetFeatureView = data?.featureViews?.find(
    (fv: FeatureViewFCO) => fv[FeatureViewFCOFields.FEATURE_VIEW_TYPE] === FeatureViewFCOType.FEATURE_TABLE
  );

  const breadcrumbs = (
    <Breadcrumbs
      workspace={<WorkspaceRootBreadcrumb workspace={workspaceDetails} />}
      crumbs={[{ label: <Link to={`../${workspace}/entities`}>Entities</Link> }, { label: name }]}
    />
  );

  const routeNames = Object.keys(getEntityRoutes());

  const tabs = (
    <Tabs
      selectedTab={selectedTab}
      tabs={routeNames}
      setSelectedTab={(tab: EntityRoutes) => {
        const routes = getEntityRoutes();
        navigation(routes[tab]);
        setSelectedTab(tab);
      }}
    />
  );
  const subtitle = <Subtitle description={data?.entity?.description ?? 'No description provided.'} />;
  const header = <HeaderLayout breadcrumbs={breadcrumbs} subtitle={subtitle} tabs={tabs} />;

  return <ViewLayout header={header} body={<Outlet />} />;
};

const EntityContainer = () => {
  const { workspace } = useUserSettings();
  const { name } = useParams();
  const { data, isLoading } = useGetFcos(workspace ?? '', {
    select: (data: WorkspaceFCOContainer) => {
      return {
        entity: data?.entities.find((entity) => {
          return entity?.name === name;
        }),
        idToFcoMap: data?.idToFcoMap,
      };
    },
  });

  const dependentFeatureViews = data?.entity?.dependentFeatureViews?.map((id: string) => data.idToFcoMap[id]) ?? [];

  if (isLoading) {
    return <></>;
  }

  if (!data?.entity) {
    // We switched workspaces and didn't find the entity name, so we redirect them to the entity lists
    return <Navigate to={`../${workspace}/entities`} />;
  }

  const entityRoutes = getEntityRoutes();

  return (
    <Routes>
      <Route element={<EntityContainerOutlet />}>
        <Route index element={<Navigate to={entityRoutes[`Metadata`]} replace />} />
        <Route path={entityRoutes[`Metadata`]} element={<EntityCards entity={data?.entity} />} />
        <Route
          path={entityRoutes[`Feature Views`]}
          element={
            <RelatedFCOsTable
              fco={data?.entity}
              featureViews={dependentFeatureViews}
              workspace={workspace}
              type={FCOType.FEATURE_VIEW}
              idToFcoMap={data.idToFcoMap}
            />
          }
        />
      </Route>
    </Routes>
  );
};

export default EntityContainer;
