import { FC, useState } from 'react';
import _sortBy from 'lodash/sortBy';
import { Link } from 'react-router-dom';
import { Routes } from '../../../core/routes';
import { User } from '../../../api/gql/graphql';

import {
  EmptyPrompt,
  ErrorPromptButtons,
  SearchAndActionButton,
  EuiBasicTableColumn,
  Table,
  FlexGroup,
  FlexItem,
  IconTip,
  FlexGroupWrapper,
  AvatarAndName,
} from '@tecton/ComponentRedesign';

import QuestionInCircle from '@svg/question-in-circle.svg';
import PermissionRoles from './PermissionRoles';
import { RoleAssignmentType } from '../../../types/tecton_proto/auth/resource_role_assignments';

export interface PermissionsUserListProps {
  users: User[];
  hasWorkspaceOwnerRole: boolean;
  isError?: boolean;
  isLoading?: boolean;
  onAddUserClick: VoidFunction;
  onEditUserRole: (user: User) => void;
}

const PermissionsUserList: FC<PermissionsUserListProps> = ({
  users,
  hasWorkspaceOwnerRole,
  isError,
  isLoading,
  onAddUserClick,
  onEditUserRole,
}) => {
  const [searchText, setSearchText] = useState<string | undefined>(undefined);
  const filteredUsers = searchText
    ? users?.filter((user: User) => {
        return user.loginEmail?.toLowerCase()?.includes(searchText?.toLowerCase());
      })
    : users;

  const sortedUsers = isLoading ? undefined : _sortBy(filteredUsers, ['loginEmail']);
  const columns: EuiBasicTableColumn<User>[] = [
    {
      name: 'Name',
      render: (user: User) => {
        return hasWorkspaceOwnerRole ? (
          <Link to={`${Routes.accountsAndAccessUsers}/${user.loginEmail}`}>
            <AvatarAndName name={user.loginEmail ?? ''} />
          </Link>
        ) : (
          <AvatarAndName name={user.loginEmail ?? ''} />
        );
      },
    },
    {
      name: (
        <>
          <FlexGroup justifyContent="center" alignItems="center" direction="row" gutterSize="none">
            <FlexItem title="Role">Role</FlexItem>
            <FlexItem>
              <IconTip
                content={`A user’s role can be
directly assigned, inherited
from a group, or set via their ‘All
Workspaces’ roles, Tecton will use
the highest role as the effective
role, as shown in this column.`}
                type={QuestionInCircle}
              />
            </FlexItem>
          </FlexGroup>
        </>
      ),
      render: (user: User) => {
        const roleAssignmentSources =
          user.rolesGranted?.flatMap((item) => {
            return (
              item.roleAssignmentSources
                ?.filter((roleAssignSource) => {
                  return roleAssignSource.assignmentType !== RoleAssignmentType.ROLE_ASSIGNMENT_TYPE_DIRECT;
                })
                ?.map((assignmentSource) => {
                  return assignmentSource;
                }) ?? []
            );
          }) ?? [];

        return (
          <>
            <PermissionRoles
              roleAssignmentSources={roleAssignmentSources}
              principalName={user.loginEmail ?? ''}
              roleName={user.workspaceRole ?? ''}
              onClick={() => {
                onEditUserRole(user);
              }}
              hasWorkspaceOwnerRole={hasWorkspaceOwnerRole}
            />
          </>
        );
      },
    },
  ];

  if (isError) {
    const emptyPrompt = (
      <EmptyPrompt
        orientation="horizontal"
        variant="storm"
        title={<>Error Loading Users</>}
        body={
          <>
            <p>We ran into an error while loading Users.</p>
          </>
        }
        actions={<ErrorPromptButtons />}
      />
    );
    return <Table items={[]} columns={columns} emptyPrompt={emptyPrompt} />;
  }

  return (
    <FlexGroupWrapper gap="s" direction="column">
      <FlexItem>
        <SearchAndActionButton
          searchPlaceHolder={'Search for User'}
          actionButtonLabel={'Add User'}
          actionButtonVariant={hasWorkspaceOwnerRole ? 'primaryAction' : 'disabledAction'}
          onActionButtonClick={onAddUserClick}
          onSearchInputChange={(search: string) => {
            setSearchText(search ?? undefined);
          }}
        />
      </FlexItem>
      <FlexItem>
        {isLoading ? (
          <Table
            items={[]}
            columns={columns}
            emptyPrompt={
              <EmptyPrompt title={<>Loading Users</>} body={<></>} variant="loading" orientation="vertical" />
            }
          />
        ) : (
          <Table items={sortedUsers ?? []} columns={columns} />
        )}
      </FlexItem>
    </FlexGroupWrapper>
  );
};

export default PermissionsUserList;
