import styled from '@emotion/styled';
import React, { FC, useState } from 'react';
import { FlexGroup, Text, Icon, Button } from '.';
import { css, keyframes } from '@emotion/react';
import { useTectonTheme } from '../../Theme/ThemeProvider';

type CalloutVariant = 'info' | 'success' | 'warning' | 'danger';
type CalloutSize = 'small' | 'medium';

interface CalloutProps {
  title: React.ReactNode;
  content: React.ReactNode;
  variant?: CalloutVariant;
  size?: CalloutSize;
  icon?: boolean;
  closable?: boolean;
  singleLine?: boolean;
  expandable?: boolean;
  setIsClosed?: React.Dispatch<React.SetStateAction<boolean>>;
}

const closingAnimation = keyframes`
  from {
  transform:scale(1);
  }
  to {
    transform:scale(0);
    opacity:0;
  }
`;

const expandAnimation = keyframes`
  from {
    max-height: 0em;
  }

  to {
    max-height: 2em; // Arbitrary large value just to allow animation
  }
`;

const collapseAnimation = keyframes`
  from {
    max-height: 2em;
  }

  to {
    max-height: 0em;
  }
`;

const CalloutContainer = styled.div<{ variant: CalloutVariant; size: CalloutSize; isClosing: boolean }>(
  ({ theme, variant, size, isClosing }) => {
    const backgroundColorMap: Record<CalloutVariant, string> = {
      info: theme.v1.colors.background.default,
      success: theme.v1.colors.background.success,
      warning: theme.v1.colors.background.warning,
      danger: theme.v1.colors.background.dangerSecondary,
    };

    const animation = isClosing
      ? `${closingAnimation} ${theme.v1.motion.duration.fast} ${theme.v1.motion.easing.exit} forwards`
      : 'none';

    return {
      borderRadius: `${theme.v1.size['2']}px`,
      backgroundColor: backgroundColorMap[variant],
      padding: size === 'small' ? `${theme.v1.size['2']}px` : `${theme.v1.size['4']}px`,
      animation,
    };
  }
);

const CalloutHeading = styled.div<{ variant: CalloutVariant }>(({ theme, variant }) => {
  const textColorMap: Record<CalloutVariant, string> = {
    info: theme.v1.colors.text.title,
    success: theme.v1.colors.text.successText,
    warning: theme.v1.colors.text.warningText,
    danger: theme.v1.colors.text.dangerText,
  };
  return {
    color: textColorMap[variant],
    fontWeight: theme.font.weight.medium,
  };
});

const ExpandWrapper = styled.div<{ state: ExpansionState }>`
  max-height: ${({ state }) => {
    return state === 'showing' ? '500px' : '0px';
  }};
  padding: 0;
  margin: 0;
  overflow: hidden;
  animation: ${({ theme, state }) => {
    if (state === 'expanding') {
      return css`
        ${expandAnimation} ${theme.v1.motion.duration.fast} ${theme.v1.motion.easing.enter} forwards
      `;
    }

    if (state === 'collapsing') {
      return css`
        ${collapseAnimation} ${theme.v1.motion.duration.xfast} ${theme.v1.motion.easing.exit} forwards
      `;
    }
  }};
`;

type ExpansionState = 'collapsed' | 'expanding' | 'showing' | 'collapsing';

const CalloutInternal: FC<CalloutProps> = ({
  title,
  content,
  variant = 'info',
  size = 'medium',
  icon = true,
  closable = false,
  setIsClosed,
  singleLine,
  expandable,
}) => {
  const [expandedState, setExpandedState] = useState<ExpansionState>('collapsed');
  const [isClosing, setIsClosing] = useState<boolean>(false);

  const { theme } = useTectonTheme();

  const iconMap: Record<CalloutVariant, React.ReactNode> = {
    info: <Icon size="s" icon={'Information'} type={'color'} color={theme.v1.colors.icon.primary} />,
    success: <Icon size="s" icon={'CheckCircle'} type={'color'} color={theme.v1.colors.text.successText} />,
    warning: <Icon size="s" icon={'Warning'} type={'color'} color={theme.v1.colors.text.warningText} />,
    danger: <Icon size="s" icon={'Error'} type={'color'} color={theme.v1.colors.text.dangerText} />,
  };

  return (
    <>
      <CalloutContainer
        variant={variant}
        size={size}
        isClosing={isClosing}
        onAnimationEnd={(event) => {
          if (event.target !== event.currentTarget) {
            return;
          }
          setIsClosed && setIsClosed(true);
        }}
      >
        <FlexGroup direction="column" gapSize="2" padding="0">
          <FlexGroup justifyContent="space-between" alignItems="center" padding="0">
            <FlexGroup gapSize="2" alignItems="center">
              {expandable && (
                <Button
                  onClick={() => {
                    if (expandedState === 'collapsed') {
                      setExpandedState('expanding');
                    } else {
                      setExpandedState('collapsing');
                    }
                  }}
                  icon={expandedState !== 'collapsed' ? 'LargeChevronDown' : 'LargeChevronRight'}
                  type="ghost"
                  size="s"
                />
              )}
              {icon && iconMap[variant]}
              <CalloutHeading variant={variant}>{title}</CalloutHeading>
              {singleLine && <Text small={size === 'small'}>{content}</Text>}
              {expandable && expandedState === 'collapsed' && <>Click for more info.</>}
            </FlexGroup>
            {closable && <Button size="s" icon={'Exit'} type="ghost" onClick={() => setIsClosing(true)} />}
          </FlexGroup>
          {!expandable && !singleLine && <Text small={size === 'small'}>{content}</Text>}
          {expandable && (
            <ExpandWrapper
              state={expandedState}
              onAnimationEnd={() => {
                if (expandedState === 'expanding') {
                  setExpandedState('showing');
                }

                if (expandedState === 'collapsing') {
                  setExpandedState('collapsed');
                }
              }}
            >
              <Text small={size === 'small'}>{content}</Text>
            </ExpandWrapper>
          )}
        </FlexGroup>
      </CalloutContainer>
    </>
  );
};

const Callout: FC<CalloutProps> = ({
  title,
  content,
  variant = 'info',
  size = 'medium',
  icon = true,
  closable = false,
  singleLine = false,
  expandable = false,
}) => {
  const [isClosed, setIsClosed] = useState<boolean>(false);

  if (isClosed) {
    return <></>;
  }

  return (
    <CalloutInternal
      title={title}
      content={content}
      variant={variant}
      size={size}
      icon={icon}
      closable={closable}
      singleLine={singleLine}
      setIsClosed={setIsClosed}
      expandable={expandable}
    />
  );
};

export default Callout;
