import React, { FC, useState, useMemo } from 'react';
import { AnyFCO, FCOFields } from '../../../core/types/fcoTypes';
import {
  ColumnDisplayControls,
  ComboFilter,
  ComboFilterSelector,
  Criteria,
  EuiTableSortingType,
  FlexGroup,
  FlexItem,
  SearchInput,
  Spacer,
  Table,
} from '@tecton/ComponentRedesign';
import { every, sortBy } from 'lodash';
import { FCOFilterStateType } from './FCOListViewSharedFilters';
import { DatasetFilterStateType } from '../dataSets/DatasetsList';
import { ColumnsOptionType } from '@tecton/ComponentRedesign/ColumnDisplayControls';

interface FCOListViewProps {
  unfilteredFCOItems: AnyFCO[];
  filters: Record<string, ComboFilterSelector>;
  columns: any[];
  filterState: FCOFilterStateType | DatasetFilterStateType;
  setFilterState: React.Dispatch<React.SetStateAction<any>>;
  defaultFilterState: any;
  filterFunctionsByFilterKey: any;
  searchPlaceholder: string;
}

const FCOListView: FC<FCOListViewProps> = ({
  unfilteredFCOItems,
  filters,
  columns,
  filterState,
  setFilterState,
  defaultFilterState,
  filterFunctionsByFilterKey,
  searchPlaceholder,
}) => {
  const [isFiltersOpen, setIsFiltersOpen] = useState<boolean>(false);
  const [selectedFilter, setSelectedFilter] = useState<string | undefined>(undefined);
  const [isColumnControlsOpen, setIsColumnControlsOpen] = useState<boolean>(false);
  const [searchString, setSearchString] = useState<string | undefined>(undefined);

  const [columnOptionsForDropdown, setColumnOptionsForDropdown] = useState<ColumnsOptionType[]>(
    columns
      .filter((column) => column?.name !== 'Name') // Name filter isn't included in dropdown selector
      .map((column) => {
        return {
          label: column.key,
          columnKey: '',
          checked: 'on',
        };
      })
  );

  const filteredColumns = columns.filter((column) => {
    if (column?.name === 'Name') {
      return true;
    }
    const activeColumns = columnOptionsForDropdown
      .filter((option) => option.checked === 'on')
      .map((option) => option.label);
    return activeColumns.includes(column.key);
  });

  const activeFilterCount = Object.values(filters).filter((item) => item.hasActiveFilters).length;

  const [sortField, setSortField] = useState<any>(FCOFields.NAME);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

  const sorting: EuiTableSortingType<AnyFCO> = {
    sort: {
      field: sortField,
      direction: sortDirection,
    },
  };

  const onTableChange = ({ page, sort }: Criteria<any>) => {
    if (sort) {
      const { field: sortField, direction: sortDirection } = sort;
      setSortField(sortField);
      setSortDirection(sortDirection);
    }
  };

  const filteredItems = unfilteredFCOItems.filter((item) => {
    const isVisible = Object.keys(filterFunctionsByFilterKey).map((key) => {
      if (filters[key].hasActiveFilters) {
        return filterFunctionsByFilterKey[key](item);
      }
      return true;
    });

    if (searchString) {
      isVisible.push(
        item[FCOFields.NAME]?.toLowerCase().includes(searchString.toLowerCase()) ||
          item[FCOFields.ID].includes(searchString.toLowerCase())
      );
    }
    return every(isVisible);
  });

  const sortedItems = useMemo(() => {
    return sortDirection === 'desc'
      ? sortBy(filteredItems, (item: any) => {
          const value = item[sortField];
          return Array.isArray(value) ? value.length : value;
        }).reverse()
      : sortBy(filteredItems, (item: any) => {
          const value = item[sortField];
          return Array.isArray(value) ? value.length : value;
        });
  }, [filteredItems, sortDirection, sortField]);

  return (
    <>
      <FlexGroup gutterSize="s" alignItems="center">
        <FlexItem>
          <SearchInput
            placeholder={searchPlaceholder}
            value={searchString}
            fullWidth
            onChange={(event) => {
              const value = event?.target?.value;
              setSearchString(value);
            }}
          />
        </FlexItem>
        <FlexItem>
          <FlexGroup gutterSize="s" justifyContent="flexEnd">
            <ComboFilter
              buttonLabel={'Filters'}
              filterTypes={filters}
              isOpen={isFiltersOpen}
              selectedFilter={selectedFilter}
              setIsOpen={setIsFiltersOpen}
              setSelectedFilter={setSelectedFilter}
              numberOfActiveFilters={activeFilterCount}
              resetActiveFilter={(filter) => {
                setFilterState({
                  ...filterState,
                  [filter]: defaultFilterState[filter],
                });
              }}
              clearAllFilterCallback={() => {
                setFilterState(defaultFilterState);
              }}
            />
            <ColumnDisplayControls
              isColumnControlsOpen={isColumnControlsOpen}
              setIsColumnControlsOpen={setIsColumnControlsOpen}
              columnOptions={columnOptionsForDropdown}
              numberOfSelectedColumnsOptions={0}
              setColumnOptions={setColumnOptionsForDropdown}
            />
          </FlexGroup>
        </FlexItem>
      </FlexGroup>
      <Spacer size="xs" />
      <Table items={sortedItems} columns={filteredColumns} layout="auto" sorting={sorting} onChange={onTableChange} />
    </>
  );
};

export default FCOListView;
