import React from 'react';
import { Badge } from '@tecton';
import { css } from '@emotion/css';

interface FeatureViewStatusBadgeProps {
  enabled: boolean | undefined;
}

const enabledTextColor = `#195474`;
const enabledBackgroundColor = `#a1ddff`;
const disabledTextColor = `#98a2b3`;

const enabledCss = css`
  .euiBadge__text {
    color: ${enabledTextColor};
  }

  .euiIcon path {
    stroke: ${enabledTextColor};
  }
`;

const disabledCss = css`
  .euiBadge__text {
    color: ${disabledTextColor};
  }

  .euiIcon path {
    stroke: ${disabledTextColor};
  }
`;

const FeatureViewStatusBadge = ({ enabled }: FeatureViewStatusBadgeProps) => {
  return (
    <Badge className={enabled ? enabledCss : disabledCss} color={enabled ? enabledBackgroundColor : 'hollow'}>
      {enabled ? 'enabled' : 'disabled'}
    </Badge>
  );
};

export default FeatureViewStatusBadge;
