import React from 'react';
import EnabledDisabled from '../../../components/EnabledDisabled';

export default function MaterializationEnabledDisabled({ isMaterializable, isOnlineEnabled, isOfflineEnabled }) {
  return (
    <div className="flex">
      {!isMaterializable ? (
        <div>-</div>
      ) : (
        <div>
          <EnabledDisabled isEnabled={isOnlineEnabled} prefix="online_" />
          <div className="pl2" />
          <EnabledDisabled isEnabled={isOfflineEnabled} prefix="offline_" />
        </div>
      )}
    </div>
  );
}
