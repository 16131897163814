import React, { FC } from 'react';
import { useNavigate } from 'react-router';
import { TableRow, TableCell, Tooltip, Typography } from '@mui/material';
import WorkspaceUtils from '../../../../utils/workspace-utils';
import styles from '../styles.module.scss';
import AccessControlWorkspaceNameSpan from '../../../../feature/access-control/Components/AccessControlWorkspaceNameSpan';
import Workspace from '../../../../service/Workspace';

interface WorkspaceSelectionTableRowProperties {
  workspace: { name: string; isAccessible: boolean; capabilities: any };
}

const WorkspaceSelectionTableRow: FC<WorkspaceSelectionTableRowProperties> = ({ workspace }) => {
  const navigate = useNavigate();

  const setWorkspaceAndRedirect = (workspace: any) => {
    WorkspaceUtils.setWorkspaceForLocalStorage(workspace.name);
    navigate('/repo/' + workspace.name + '/home');
  };

  const { name, capabilities } = workspace;
  const ws = new Workspace({ name: name, capabilities: capabilities, resource_id: name });

  if (workspace.isAccessible === false) {
    return (
      <TableRow className={styles.workspaceSelectionRow}>
        <Tooltip
          arrow
          placement="right"
          title={
            <Typography>
              You do not have access to this workspace. Contact your Tecton administrator for access.
            </Typography>
          }
        >
          <TableCell className={`${styles.workspaceSelectionCell} word-wrap`}>
            <AccessControlWorkspaceNameSpan
              workspace={ws}
              isInaccessible={true}
              isLive={workspace.capabilities.materializable}
              isDevelopment={!workspace.capabilities.materializable}
            />
          </TableCell>
        </Tooltip>
      </TableRow>
    );
  }

  return (
    <TableRow
      className={styles.workspaceSelectionRowActive}
      onClick={() => {
        if (!workspace.isAccessible) {
          return;
        }
        setWorkspaceAndRedirect(workspace);
      }}
    >
      <TableCell className={styles.workspaceSelectionCell}>
        <AccessControlWorkspaceNameSpan
          workspace={ws}
          isInaccessible={false}
          isLive={workspace.capabilities.materializable}
          isDevelopment={!workspace.capabilities.materializable}
        />
      </TableCell>
    </TableRow>
  );
};

export default WorkspaceSelectionTableRow;
