import React, { FC, useState } from 'react';
import { Outlet, useMatch, useNavigate } from 'react-router';

import { ACLGroupRoutes, getACLGroupRoutes } from './acl-util';

// @tecton
import { Tabs } from '@tecton/ComponentRedesign';

interface ACLGroupOutletProps {
  path: string;
  tabCountRecord: Record<string, number>;
}

const ACLGroupOutlet: FC<ACLGroupOutletProps> = ({ path, tabCountRecord }) => {
  const navigation = useNavigate();
  const indexRoute = {
    pathName: 'Workspaces',
    isMatch: !!useMatch({
      path: `${path}/workspaces`,
      end: false,
    }),
    count: tabCountRecord['Workspaces'],
  };

  const pages = [
    indexRoute,
    {
      pathName: 'Users',
      isMatch: !!useMatch({
        path: `${path}/users`,
        end: false,
      }),
      count: tabCountRecord['Users'],
    },
    {
      pathName: 'Service Accounts',
      isMatch: !!useMatch({
        path: `${path}/service-accounts`,
        end: false,
      }),
      count: tabCountRecord['Service Accounts'],
    },
  ];

  const selectedPage = pages.find((page) => page.isMatch) ?? indexRoute;

  const [selectedTab, setSelectedTab] = useState<ACLGroupRoutes>(selectedPage?.pathName as ACLGroupRoutes);

  return (
    <>
      <Tabs
        selectedTab={selectedTab}
        tabsWithCount={pages?.map((page) => {
          return { label: page.pathName, count: page.count };
        })}
        setSelectedTab={(tab: ACLGroupRoutes) => {
          const routes = getACLGroupRoutes();
          navigation(routes[tab] ?? indexRoute.pathName);
          setSelectedTab(tab);
        }}
      />
      <Outlet />
    </>
  );
};

export default ACLGroupOutlet;
