import {
  Breadcrumbs,
  EmptyPrompt,
  FlexGroup,
  FlexItem,
  HeaderLayout,
  Subtitle,
  ViewLayout,
} from '@tecton/ComponentRedesign';
import { useGetFcos } from '../../../api/fcos';
import { WorkspaceFCOContainer } from '../../../core/types/fcoTypes';
import { useUserSettings } from '../../context/UserSettingsContext';
import { TECTON_DOCUMENTATION_LINKS } from '../application-links';
import WorkspaceRootBreadcrumb from '../WorkspaceRootBreadcrumb';
import DataSourcesList from './DataSourcesList';

export const DataSourcesContainer = () => {
  const { workspace, workspaceDetails } = useUserSettings();

  const { data, isLoading, isError } = useGetFcos<WorkspaceFCOContainer>(workspace as string, {
    select: (data: WorkspaceFCOContainer) => {
      return {
        dataSources: data.dataSources,
        idToFcoMap: data.idToFcoMap,
      };
    },
  });

  const subtitle = (
    <Subtitle
      description={'Data Sources define how Tecton connects to batch and stream data.'}
      learnMoreUrl={TECTON_DOCUMENTATION_LINKS.DATA_SOURCES}
    />
  );

  const header = (
    <HeaderLayout
      breadcrumbs={
        <Breadcrumbs
          workspace={<WorkspaceRootBreadcrumb workspace={workspaceDetails} />}
          crumbs={[{ label: 'Sources' }]}
        />
      }
      subtitle={subtitle}
    />
  );

  if (isLoading) {
    return (
      <ViewLayout
        header={header}
        body={
          <FlexGroup direction="column" alignItems="center">
            <FlexItem>
              <EmptyPrompt
                title={<>Loading Data Sources</>}
                body={<>Data Sources are loading.</>}
                variant="loading"
                orientation="vertical"
              />
            </FlexItem>
          </FlexGroup>
        }
      />
    );
  }

  if (isError) {
    return (
      <ViewLayout
        header={header}
        body={
          <EmptyPrompt
            title={<>Error Loading Data Sources</>}
            body={<>There was an error loading Data Sources for this workspace. Please try again.</>}
            variant="storm"
            orientation="vertical"
          />
        }
      />
    );
  }

  return (
    <ViewLayout
      header={header}
      body={
        <DataSourcesList
          items={data?.dataSources ?? []}
          workspace={workspace ?? ''}
          idToFcoMap={data?.idToFcoMap ?? {}}
        />
      }
    />
  );
};

export default DataSourcesContainer;
