import styled from '@emotion/styled';
import React, { FC, useState } from 'react';
import { Button, Tooltip } from '.';

interface CopyButtonProps {
  code: string;
}

const NoPointerEvents = styled.div`
  pointer-events: none;
`;

const CopyButton: FC<CopyButtonProps> = ({ code }) => {
  const [isCopyIndicatorVisible, setIsCopyIndicatorVisible] = useState<boolean>(false);

  return (
    <>
      {' '}
      {isCopyIndicatorVisible && (
        <NoPointerEvents>
          <Tooltip openByDefault trigger={<Button type="ghost" icon="Check" size="m" />} content={'Copied!'} />
        </NoPointerEvents>
      )}
      {!isCopyIndicatorVisible && (
        <Tooltip
          trigger={
            <Button
              type="ghost"
              icon="Copy"
              size="m"
              onClick={() => {
                navigator.clipboard.writeText(code);
                setIsCopyIndicatorVisible(true);
                setTimeout(() => {
                  setIsCopyIndicatorVisible(false);
                }, 1000);
              }}
            />
          }
          content={'Copy this code'}
        />
      )}
    </>
  );
};

export default CopyButton;
