import { useQuery } from '@tanstack/react-query';
import { Metadata_Service } from '.';
import {
  GetFeatureServiceRequest,
  GetFeatureServiceSummaryRequest,
} from '../types/tecton_proto/metadataservice/metadata_service';
import { FeatureServiceSummary } from '../core/types/featureServiceSummary';
import { SummaryItem } from '../types/tecton_proto/data/summary';
import { Id } from '../types/tecton_proto/common/id';

const fetchServiceSummary = async (args: GetFeatureServiceSummaryRequest): Promise<FeatureServiceSummary> => {
  const response = await Metadata_Service('/get-feature-service-summary', {
    data: {
      feature_service_name: args.feature_service_name,
      workspace: args.workspace,
    },
  });

  const findItemFromSummary = (itemKey: string) => {
    const findValue = response.data.general_items.find((item: SummaryItem) => item.key === itemKey);
    if (!findValue) {
      return undefined;
    }

    if (findValue.value) {
      return findValue.value;
    }

    return findValue.multiValue;
  };

  const loggingConfigEnabled = findItemFromSummary('loggingConfigEnabled') === 'Enabled';
  const loggingSampleRate = findItemFromSummary('loggingConfigSampleRate');
  const loggingEffectiveTimes = findItemFromSummary('loggingConfigLogEffectiveTimes');
  const requestContextKeys = findItemFromSummary('requestContextKeys');
  const curlEndpoint = findItemFromSummary('curlEndpoint');
  const curlHeader = findItemFromSummary('curlHeader');
  const curlParamsJson = findItemFromSummary('curlParamsJson');
  const onlineJoinKeys = findItemFromSummary('onlineJoinKeys');

  const summary: FeatureServiceSummary = {
    isLoggingEnabled: loggingConfigEnabled,
    loggingSampleRate: loggingSampleRate,
    onDemandEnvironment: undefined,
    logEffectiveTimes: loggingEffectiveTimes,
    requestContextKeys: requestContextKeys,
    curlEndpoint,
    curlHeader,
    curlParamsJson,
    onlineJoinKeys,
  };

  return summary;
};

export const useGetFeatureServiceSummary = (workspace: string, name: string) => {
  return useQuery(['/get-feature-service-summary', workspace, name], () => {
    return fetchServiceSummary({ workspace: workspace, feature_service_name: name });
  });
};

const fetchFeatureService = async (args: GetFeatureServiceRequest) => {
  const response = await Metadata_Service('/get-feature-service', {
    data: args,
  });

  return response.data;
};

export const useGetFeatureService = (workspace: string, id: Id) => {
  return useQuery(['/get-feature-service', workspace, name], () => {
    return fetchFeatureService({ workspace: workspace, id: id });
  });
};
