/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import styled from '@emotion/styled';
import React, { FC, useState } from 'react';
import { Text } from '../';

import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useClick,
  useDismiss,
  useRole,
  useInteractions,
  FloatingFocusManager,
  FloatingPortal,
  FloatingContext,
  ReferenceType,
  ExtendedRefs,
} from '@floating-ui/react';
import FlexGroup from './FlexGroup';
import { css, keyframes } from '@emotion/react';

type PopoverLifecycleState = 'spawning' | 'active' | 'exiting';

interface PopoverProps {
  trigger: NonNullable<React.ReactNode>;
  body: React.ReactNode;
  title?: React.ReactNode;
  buttons?: React.ReactNode;
}

interface PopoverInternalProps extends PopoverProps {
  lifeCycleState: PopoverLifecycleState;
  setLifeCycleState: React.Dispatch<React.SetStateAction<PopoverLifecycleState>>;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  context: FloatingContext<ReferenceType>;
  refs: ExtendedRefs<ReferenceType>;
  floatingStyles: React.CSSProperties;
  getFloatingProps: (userProps?: React.HTMLProps<HTMLElement>) => Record<string, unknown>;
}

const enter = keyframes`
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
`;

const exit = keyframes`
  from {
  opacity: 1;
  }
  to {
  opacity: 0;
  }
`;

const PopoverBody = styled.div`
  max-height: ${({ theme }) => `${theme.v1.size[80]}px`};
  overflow: auto;
  width: 100%;
`;

const PopoverContainer = styled.div<{ lifeCycleState: PopoverLifecycleState }>`
  background-color: ${({ theme }) => theme.v1.colors.background.empty};
  box-shadow: ${({ theme }) => theme.v1.shadow.l};
  z-index: ${({ theme }) => theme.v1.layers.popover};
  border-radius: ${({ theme }) => theme.v1.radius[6]};
  border: ${({ theme }) => `1px solid ${theme.v1.colors.border.default}`};
  min-width: ${({ theme }) => theme.v1.size[16]}px;
  max-width: ${({ theme }) => theme.v1.size[80]}px;
  color: ${({ theme }) => theme.v1.colors.text.text};
  animation: ${({ theme, lifeCycleState }) => {
    if (lifeCycleState === 'exiting') {
      return css`
        ${exit} ${theme.v1.motion.duration.fast} ${theme.v1.motion.easing.exit} forwards
      `;
    }
    return css`
      ${enter} ${theme.v1.motion.duration.fast} ${theme.v1.motion.easing.enter} forwards
    `;
  }};
`;

const ContentWrapper = styled.div`
  padding: ${({ theme }) => theme.v1.size['4']}px;
`;

const PopoverInternal: FC<PopoverInternalProps> = ({
  title,
  body,
  buttons,
  lifeCycleState,
  setLifeCycleState,
  setIsOpen,
  context,
  refs,
  floatingStyles,
  getFloatingProps,
}) => {
  return (
    <>
      <FloatingPortal>
        <FloatingFocusManager context={context} modal={false}>
          <div ref={refs.setFloating} style={{ ...floatingStyles, zIndex: 99999 }} {...getFloatingProps()}>
            <PopoverContainer
              lifeCycleState={lifeCycleState}
              onAnimationEnd={() => {
                if (lifeCycleState === 'spawning') {
                  setLifeCycleState('active');
                }
                if (lifeCycleState === 'exiting') {
                  setIsOpen(false);
                  setLifeCycleState('spawning');
                }
              }}
            >
              <ContentWrapper>
                <FlexGroup direction="column" alignItems="flex-start" gapSize="1.5">
                  {title && <Text element="h5">{title}</Text>}

                  <PopoverBody>
                    <Text>{body}</Text>
                  </PopoverBody>
                  {buttons}
                </FlexGroup>
              </ContentWrapper>
            </PopoverContainer>
          </div>
        </FloatingFocusManager>
      </FloatingPortal>
    </>
  );
};

const Popover: FC<PopoverProps> = ({ trigger, title, body, buttons }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [lifeCycleState, setLifeCycleState] = useState<PopoverLifecycleState>('spawning');

  const { refs, floatingStyles, context } = useFloating({
    whileElementsMounted: autoUpdate,
    open: isOpen,
    onOpenChange: (change) => {
      if (change) {
        setIsOpen(true);
      } else {
        setLifeCycleState('exiting');
      }
    },
    middleware: [offset(10), flip(), shift()],
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss, role]);

  return (
    <>
      <span onClick={() => setIsOpen(true)} ref={refs.setReference} {...getReferenceProps()}>
        {trigger}
      </span>
      {isOpen && (
        <PopoverInternal
          lifeCycleState={lifeCycleState}
          setLifeCycleState={setLifeCycleState}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          trigger={''}
          body={body}
          buttons={buttons}
          title={title}
          context={context}
          refs={refs}
          floatingStyles={floatingStyles}
          getFloatingProps={getFloatingProps}
        />
      )}
    </>
  );
};

export default Popover;
