import styled from '@emotion/styled';
import React, { FC } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { useTectonTheme } from '../../Theme/ThemeProvider';
import { CodeLanguage, CopyButton, customSyntaxTheme, FlexGroup } from '.';

interface CodeProps {
  code: string;
  language?: CodeLanguage;
  hideLineNumbers?: boolean;
  hideCopy?: boolean;
  errorLines?: number[];
}

const CodeContainer = styled.div`
  background-color: ${({ theme }) => theme.v1.colors.background.default};
  padding: ${({ theme }) => theme.v1.size['2']}px;

  .error-line {
    display: block;
    border-left: ${({ theme }) => theme.v1.size['0.5']}px solid
      ${({ theme }) => theme.v1.colors.background.dangerPrimary};
    background-color: ${({ theme }) => theme.v1.colors.background.warning};

    &:before {
      border-left: 5px solid black;
    }
  }
`;

const CodeColumn = styled.div`
  flex: 3;
`;

const ButtonColumn = styled.div`
  flex: 0;
  padding: ${({ theme }) => `${theme.v1.size['3']}px`};
`;

const Code: FC<CodeProps> = ({
  code,
  language = 'python',
  hideLineNumbers = false,
  hideCopy = false,
  errorLines = [],
}) => {
  const { theme } = useTectonTheme();

  const customTheme = customSyntaxTheme(theme);

  return (
    <CodeContainer>
      <FlexGroup gapSize="2">
        <CodeColumn>
          <SyntaxHighlighter
            language={language}
            style={customTheme}
            showLineNumbers={!hideLineNumbers}
            wrapLines
            lineProps={(lineNumber) => {
              return {
                className: errorLines.includes(lineNumber) ? 'error-line' : '',
              };
            }}
          >
            {code}
          </SyntaxHighlighter>
        </CodeColumn>
        {!hideCopy && (
          <ButtonColumn>
            <CopyButton code={code} />
          </ButtonColumn>
        )}
      </FlexGroup>
    </CodeContainer>
  );
};

export default Code;
