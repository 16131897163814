import { Metadata_Service } from './';
import { useQuery } from '@tanstack/react-query';
import { GetInternalSparkClusterStatusResponse } from '../types/tecton_proto/metadataservice/metadata_service';

const fetchSparkClusterStatus = async (): Promise<GetInternalSparkClusterStatusResponse> => {
  const { data } = await Metadata_Service('get-internal-spark-cluster-status', { method: 'POST' });
  return data;
};

export const useGetInternalSparkClusterStatusQuery = (interval: number | false = false) =>
  useQuery(['getSparkClusterStatus'], () => fetchSparkClusterStatus(), {
    refetchInterval: interval,
  });
