import { useParams } from 'react-router';
import {
  Breadcrumbs,
  EmptyPrompt,
  FlexGroup,
  FlexItem,
  HeaderLayout,
  Subtitle,
  ViewLayout,
} from '@tecton/ComponentRedesign';
import { DatasetFCO, WorkspaceFCOContainer } from '../../../core/types/fcoTypes';

import { useGetFcos } from '../../../api/fcos';
import { useGetDatasets } from '../../../api/datasets';
import DataSetsList from './DatasetsList';
import { useUserSettings } from '../../context/UserSettingsContext';
import { TECTON_DOCUMENTATION_LINKS } from '../application-links';
import WorkspaceRootBreadcrumb from '../WorkspaceRootBreadcrumb';

const DatasetsListContainer = () => {
  const { workspace } = useParams();
  const { workspaceDetails } = useUserSettings();

  // eslint-disable-next-line prefer-const
  let { data: datasetsData, isLoading: datasetsIsLoading, error: dataSetsError } = useGetDatasets(workspace as string);
  const {
    data: fcoData,
    isLoading: fcosAreLoading,
    error: fcoError,
  } = useGetFcos<WorkspaceFCOContainer>(workspace as string, {
    select: (data: WorkspaceFCOContainer) => {
      return {
        featureViews: data.featureViews,
        featureServices: data.featureServices,
        idToFcoMap: data.idToFcoMap,
      };
    },
  });

  const subtitle = (
    <Subtitle
      description="Datasets save feature data for use in model training and analysis."
      learnMoreUrl={TECTON_DOCUMENTATION_LINKS.DATASETS}
    />
  );

  const header = (
    <HeaderLayout
      breadcrumbs={
        <Breadcrumbs
          workspace={<WorkspaceRootBreadcrumb workspace={workspaceDetails} />}
          crumbs={[{ label: 'Datasets' }]}
        />
      }
      subtitle={subtitle}
    />
  );

  if (fcosAreLoading || datasetsIsLoading) {
    return (
      <ViewLayout
        header={header}
        body={
          <FlexGroup direction="column" alignItems="center">
            <FlexItem>
              <EmptyPrompt
                title={<>Loading Datasets</>}
                body={<>Datasets are loading.</>}
                variant="loading"
                orientation="vertical"
              />
            </FlexItem>
          </FlexGroup>
        }
      />
    );
  }

  if (fcoError || dataSetsError) {
    return (
      <ViewLayout
        header={header}
        body={
          <EmptyPrompt
            title={<>Error Loading Datasets</>}
            body={<>There was an error loading Datasets for this workspace. Please try again.</>}
            variant="storm"
            orientation="vertical"
          />
        }
      />
    );
  }

  const emptyDatasets: DatasetFCO[] = [];

  if (datasetsData === undefined) {
    datasetsData = { data: emptyDatasets };
  }

  return (
    <ViewLayout header={header} body={<DataSetsList items={datasetsData.data} idToFcoMap={fcoData.idToFcoMap} />} />
  );
};

export default DatasetsListContainer;
