import React, { FC } from 'react';
import styled from '@emotion/styled';
import { FlexGroup, Icon, Text } from './';
import { useTectonTheme } from '../../Theme/ThemeProvider';
import { V1Sizes } from '../../Theme/emotion';
import { IconSize } from './Icon';

type TagSize = 's' | 'm';

interface TagProps {
  icon?: boolean;
  label: string;
  category?: string;
  size?: TagSize;
}

const TagContainer = styled.div<{ size: TagSize }>`
  display: inline-block;
  border-radius: ${({ theme }) => `${theme.v1.size['8']}px`};
  border: ${({ theme }) => `1px solid ${theme.v1.colors.border.default}`};
  width: auto;
  background-color: ${({ theme }) => theme.v1.colors.background.default};
`;

const TruncateText = styled.div`
  display: block;
  max-width: ${({ theme }) => `${theme.v1.size['32']}px`};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Tag: FC<TagProps> = ({ icon, label, category, size = 'm' }) => {
  const { theme } = useTectonTheme();

  const IconSizeMap: Record<TagSize, IconSize> = {
    s: 'xs',
    m: 's',
  };

  return (
    <TagContainer size={size}>
      <FlexGroup
        gapSize={theme.v1.inlineSizing.gap[size] as V1Sizes}
        justifyContent="flex-start"
        alignItems="center"
        direction="row"
        css={{ padding: theme.v1.inlineSizing.padding[size] }}
      >
        {icon && <Icon icon={'Tag'} type={'custom'} size={IconSizeMap[size]} />}
        {category && (
          <Text small inline>
            <TruncateText>{category}: </TruncateText>
          </Text>
        )}
        <Text small inline weight="medium">
          <TruncateText>{label}</TruncateText>
        </Text>
      </FlexGroup>
    </TagContainer>
  );
};

export default Tag;
