/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, useEffect, useRef, useState } from 'react';
import WorkspaceUtils from '../../utils/workspace-utils';
import { EuiSelectableOption, Panel, useTectonTheme, Badge, FlexGroup, FlexItem, Icon, Selectable } from '@tecton';
import UrlUtils from '../../utils/url-utils';
import { useSelector } from 'react-redux';
import { getPathStateAction } from '../../shared/selectors';
import { useNavigate } from 'react-router';
import { css } from '@emotion/css';
import { useWindowSize } from '../../feature/feature-views/feature-view/component/BatchMaterializationQualityMonitoringPanel/hooks';
import { useUserSettings } from '../context/UserSettingsContext';
import { WorkspaceWithPermissions } from '../context/types';

enum WorkspaceType {
  RECENT = 'recent',
  LIVE = 'live',
  DEV = 'dev',
}

type WorkspaceOption = EuiSelectableOption<{
  data?: {
    label: React.ReactNode;
    type?: WorkspaceType;
  };
}>;

const organizeWorkspacesForSelector = (workspaces: WorkspaceWithPermissions[], recentWorkspaces: string[]) => {
  const liveWorkspaces: WorkspaceOption[] = workspaces
    .filter((workspace) => workspace.capabilities.materializable)
    .map((workspace) => {
      return {
        label: workspace.name,
        searchableLabel: workspace.name,
        data: {
          label: <>{workspace.name}</>,
          type: WorkspaceType.LIVE,
        },
      };
    });

  const devWorkspaces: WorkspaceOption[] = workspaces
    .filter((workspace) => !workspace.capabilities.materializable)
    .map((workspace) => {
      return {
        label: workspace.name,
        searchableLabel: workspace.name,
        data: {
          label: <>{workspace.name}</>,
          type: WorkspaceType.DEV,
        },
      };
    });

  const liveWorkspaceNames = workspaces
    .filter((workspace) => workspace.capabilities.materializable)
    .map((workspace) => workspace.name);

  const recentWorkspacesWithType: WorkspaceOption[] = recentWorkspaces.map((workspaceName) => {
    let type = WorkspaceType.DEV;

    if (liveWorkspaceNames.includes(workspaceName)) {
      type = WorkspaceType.LIVE;
    }

    return {
      label: workspaceName,
      searchableLabel: ' ',
      data: {
        label: <>{workspaceName}</>,
        type: type,
      },
    };
  });

  let arrayToReturn: WorkspaceOption[] =
    recentWorkspacesWithType.length > 0
      ? [
          {
            label: 'Recent Workspaces',
            searchableLabel: ' ',
            isGroupLabel: true,
            append: <Badge>{recentWorkspaces.length}</Badge>,
          },
          ...recentWorkspacesWithType,
        ]
      : [];

  arrayToReturn = arrayToReturn.concat([
    {
      label: 'Live Workspaces',
      searchableLabel: ' ',
      isGroupLabel: true,
      append: <Badge>{liveWorkspaces.length}</Badge>,
    },
    ...liveWorkspaces,
    {
      label: 'Dev Workspaces',
      searchableLabel: ' ',
      isGroupLabel: true,
      append: <Badge>{devWorkspaces.length}</Badge>,
    },
    ...devWorkspaces,
  ]);

  return arrayToReturn.flat();
};

const WorkspaceTypeBadge = ({ type: type }: { type: WorkspaceType | undefined }) => {
  const { theme } = useTectonTheme();

  const background = type === WorkspaceType.LIVE ? theme.colors.primary : theme.colors.disabled;

  return (
    <span style={{ marginRight: theme.size.m }}>
      <Badge color={background}>
        <span style={{ color: theme.colors.lightestShade }}>{type}</span>
      </Badge>
    </span>
  );
};

interface WorkspaceSelectorDefaultStateProps {
  setIsPanelVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const WorkspaceSelectorDefaultState: FC<WorkspaceSelectorDefaultStateProps> = ({ setIsPanelVisible }) => {
  const { theme } = useTectonTheme();
  const currentWorkspace = WorkspaceUtils.getWorkspaceFromStore();
  const { allWorkspaces } = useUserSettings();
  const currentWorkspaceWithPermissions = allWorkspaces?.filter(
    (iteratedWorkspace) => iteratedWorkspace.name === currentWorkspace
  )[0];
  const currentWorkspaceType = currentWorkspaceWithPermissions?.capabilities.materializable;

  const groupCss = css`
    height: 40px;
    background-color: ${theme.color.light.chromeBackground};
    color: ${theme.color.light.navigationText};
    padding-left: ${theme.size.m};
    padding-right: ${theme.size.m};
    cursor: pointer;
    display: grid;
    grid-template-columns: min-content 1fr min-content;
    padding-top: auto;
    padding-bottom: auto;
    place-items: center;
  `;

  return (
    <div style={{ width: '100%' }}>
      <div
        className={groupCss}
        onClick={() => {
          setIsPanelVisible(true);
        }}
      >
        <div>
          <Badge color={theme.color.light.navigationText}>{currentWorkspaceType ? 'live' : 'dev'}</Badge>
        </div>
        <div
          style={{
            width: `calc(100%)`,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            paddingLeft: theme.size.s,
            paddingRight: theme.size.s,
            textAlign: 'left',
          }}
        >
          {currentWorkspace}
        </div>
        <div>
          <Icon type="arrowDown" />
        </div>
      </div>
    </div>
  );
};

interface WorkspaceSelectableProps {
  setIsPanelVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const WorkspaceSelectable: FC<WorkspaceSelectableProps> = ({ setIsPanelVisible }) => {
  const { height } = useWindowSize();
  const { theme } = useTectonTheme();
  const { allWorkspaces } = useUserSettings();
  const pathState = useSelector((state) => getPathStateAction(state));
  const history = useNavigate();
  const inputRef = useRef<HTMLInputElement | null>();

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus(); // AUTOMATICALLY FOCUS ON THE SELECTOR WHEN ACTIVE
    }
  }, []);

  const recentWorkspaces = WorkspaceUtils.getRecentWorkspaces();
  const data = organizeWorkspacesForSelector(allWorkspaces ?? [], recentWorkspaces);

  const selectWorkspace = (name: string) => {
    const newPathName = UrlUtils.buildPath(pathState.path, { ...pathState.params, workspace: name });
    history(newPathName);
    WorkspaceUtils.addWorkspaceToRecent(name);
  };

  const optionsCss = css`
    position: absolute;
    width: 100%;
    top: 41px;
    left: 0;
    background: ${theme.color.light.navigationCategoryBackground};

    .euiSelectableListItem:hover:not([aria-disabled='true']) .euiSelectableListItem__text {
      text-decoration: none;
      color: black;
    }

    .euiSelectableListItem-isFocused span {
      text-decoration: none !important;
    }
  `;

  const searchBarOldTectonCustomizationCss = css`
    .euiFieldSearch {
      border-radius: 0;
    }

    .euiFormControlLayoutIcons--right {
      margin-top: 8px;
    }
  `;

  const FALLBACK_HEIGHT = 32 * 7.5; // 7.5 rows of content

  const placeholder = 'Search for Workspace';

  return (
    <Selectable
      singleSelection
      className={searchBarOldTectonCustomizationCss}
      aria-label={placeholder}
      searchable
      options={data}
      onChange={(newOptions) => {
        const selectedOption = newOptions.find((o) => {
          return o.checked === 'on';
        });

        const listElement: HTMLDivElement | null = document.querySelector('div.euiSelectableList__list');
        if (listElement) {
          listElement.blur();
        }

        if (selectedOption as WorkspaceOption) {
          if (inputRef.current) {
            inputRef.current.blur();
          }
          selectWorkspace(selectedOption!.label);
          setIsPanelVisible(false);
        }
      }}
      renderOption={(option: { type?: WorkspaceType; label?: string }) => {
        return (
          <FlexGroup dir="row" gutterSize="xs">
            <FlexItem grow={0}>{option.type && <WorkspaceTypeBadge type={option.type} />}</FlexItem>
            <FlexItem grow={0}>
              <span style={{ pointerEvents: 'none', fontWeight: 500 }}>{option.label}</span>
            </FlexItem>
          </FlexGroup>
        );
      }}
      listProps={{
        showIcons: false,
        bordered: true,
      }}
      height={height ? height * 0.6 : FALLBACK_HEIGHT}
      searchProps={{
        placeholder: placeholder,
        onBlur: (event) => {
          // Checks if the target that called the blur event is a list item from the workspace selector
          // If that's the case, we shouldn't hide the panel. This would fire _before_ the onChange handler above,
          // and as a result we wouldn't switch workspaces.
          if (event.relatedTarget?.className !== 'euiSelectableList__list') {
            setIsPanelVisible(false);
          }
        },
        inputRef: (node) => {
          inputRef.current = node;
        },
        onKeyUp: (event: React.KeyboardEvent<HTMLInputElement>) => {
          if (event.code === 'Escape' && inputRef.current) {
            inputRef.current.blur();
          }
        },
      }}
    >
      {(list, search) => (
        <>
          {search}
          <Panel paddingSize="none" className={`tectonSearchOptionsPanel ${optionsCss}`}>
            {list}
          </Panel>
        </>
      )}
    </Selectable>
  );
};

const WorkspaceSelectorDropdown: FC = () => {
  const { theme } = useTectonTheme();

  const wrapperCss = css`
    position: relative;

    .euiFormControlLayoutIcons {
      display: block;
      margin-top: 3px;
      color: ${theme.color.light.navigationText};
    }

    + .euiFormControlLayoutIcons {
      path {
        fill: ${theme.color.light.navigationText};
      }
    }

    .euiSelectableListItem__content {
      display: grid;
      grid-template-columns: 1fr 48px;
    }

    .euiSelectableList__list {
      // Hack to override <Selectable> height specification
      // Otherwise the dropdown will be a fixed size
      // even when there's only a few matching results
      height: inherit !important;
      max-height: 60vh;
    }

    .euiSelectableListItem__append {
      display: grid;
      grid-template-columns: 1fr min-content;
      flex-align: middle;
      align-items: center;

      .euiText {
        color: ${theme.colors.subduedText};
      }

      .euiSelectableListItem__onFocusBadge {
        margin-left: ${theme.size.s};
      }
    }

    .euiSelectableList__groupLabel {
      color: ${theme.colors.darkShade};
      justify-content: space-between;
    }

    .euiSelectableListItem {
      background: white;
      text-decoration: none;
    }

    .euiSelectableListItem-isFocused:not([aria-disabled='true']),
    .euiSelectableListItem:hover:not([aria-disabled='true']) {
      background-color: ${theme.color.light.subtleHighlight};
      color: black;
    }
  `;

  const [isPanelVisible, setIsPanelVisible] = useState<boolean>(false);

  return (
    <div className={wrapperCss}>
      {!isPanelVisible && <WorkspaceSelectorDefaultState setIsPanelVisible={setIsPanelVisible} />}
      {isPanelVisible && <WorkspaceSelectable setIsPanelVisible={setIsPanelVisible} />}
    </div>
  );
};

export default WorkspaceSelectorDropdown;
