import React, { FC } from 'react';
import { Routes } from '../../../core/routes';

import { IconTypes } from '@tecton/ComponentRedesign';
import TectonSideNavigationFooter from '@tecton/SideNavigation/TectonSideNavigationFooter';
import TectonSideNavigationHeader from '@tecton/SideNavigation/TectonSideNavigationHeader';
import TectonSideNavigationItem from '@tecton/SideNavigation/TectonSideNavigationItem';

import {
  CollapsedContentWrapper,
  CollapsedFooterWrapper,
  CollapsedHeaderWrapper,
  CollapsedItemGroupWrapper,
  CollapsedNavWrapper,
  CollapsedSpacer,
  FixedSpacer,
  FooterBlock,
  HeaderBlock,
  MiddleSection,
  MiddleSpacer,
  NavWrapper,
} from '@tecton/SideNavigation/StyledComponents';

import { ScrollingBlock } from '@tecton/SideNavigation/TectonSideNavigation';

interface TectonSideNavigationProps {
  isPublicFreeTrial: boolean;
  isCollapsed?: boolean;
  setIsCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
  setShowSearchModal: React.Dispatch<React.SetStateAction<boolean>>;
  userName: string;
  email: string;
  onBellClick: () => void;
  onLogoutClick: () => void;
}

const CollapsedSideNavigation: FC<TectonSideNavigationProps> = ({
  setIsCollapsed,
  userName,
  email,
  onBellClick,
  onLogoutClick,
}) => {
  return (
    <CollapsedNavWrapper>
      <CollapsedHeaderWrapper>
        <TectonSideNavigationHeader isCollapsed setIsCollapsed={setIsCollapsed} />
      </CollapsedHeaderWrapper>
      <CollapsedContentWrapper>
        <CollapsedSpacer />
        <CollapsedItemGroupWrapper style={{ border: '10x solid green' }}></CollapsedItemGroupWrapper>
      </CollapsedContentWrapper>
      <CollapsedFooterWrapper>
        <TectonSideNavigationFooter
          isCollapsed
          userName={userName}
          email={email}
          onBellClick={onBellClick}
          onLogoutClick={onLogoutClick}
        />
      </CollapsedFooterWrapper>
    </CollapsedNavWrapper>
  );
};

const GuidedOnBoardingSideNavigation: FC<TectonSideNavigationProps> = ({
  isPublicFreeTrial,
  isCollapsed,
  userName,
  email,
  setIsCollapsed,
  setShowSearchModal,
  onBellClick,
  onLogoutClick,
}) => {
  const navigationInformation = Routes.accountAndAccess;
  if (isCollapsed) {
    return (
      <CollapsedSideNavigation
        isPublicFreeTrial={isPublicFreeTrial}
        isCollapsed
        userName={userName}
        email={email}
        setIsCollapsed={setIsCollapsed}
        setShowSearchModal={setShowSearchModal}
        onBellClick={onBellClick}
        onLogoutClick={onLogoutClick}
      />
    );
  }

  return (
    <NavWrapper>
      <HeaderBlock>
        <TectonSideNavigationHeader setIsCollapsed={setIsCollapsed} />
      </HeaderBlock>
      <MiddleSection>
        <ScrollingBlock isPublicFreeTrial={isPublicFreeTrial}>
          <MiddleSpacer />
          <div>
            <TectonSideNavigationItem
              label="Accounts & Access"
              icon={IconTypes.ACCOUNTS_AND_ACCESS}
              route={navigationInformation}
            />
          </div>
        </ScrollingBlock>
        <FixedSpacer />
      </MiddleSection>
      <FooterBlock>
        <TectonSideNavigationFooter
          userName={userName}
          email={email}
          onBellClick={() => {
            return;
          }}
          onLogoutClick={onLogoutClick}
        />
      </FooterBlock>
    </NavWrapper>
  );
};

export default GuidedOnBoardingSideNavigation;
