import React from 'react';
import { Stack, Chip, Box, Typography } from '@mui/material';
import LoomVideo from '../../../core/component/loom/LoomVideo';

const ConfigureDatabricksHelp = () => {
  return (
    <Stack spacing={4} sx={{ m: 2 }}>
      <Stack spacing={2}>
        <Typography variant="h4">Connection Guide</Typography>
        <Typography variant="body1">
          <span className="fw9">Note</span>: You will need{' '}
          <a href="https://docs.databricks.com/getting-started/account-setup.html" target="_blank" rel="noreferrer">
            Databricks Admin Access
          </a>
          .
        </Typography>
        <Typography variant="body1">
          Tecton leverages Databricks as a data platform to access, materialize, and transform your feature data. To
          connect Tecton to Databricks, you need a few pieces of information:
        </Typography>
        <Box component="ol" sx={{ pl: 4 }}>
          <Typography variant="body1" component="li">
            The Databricks Workspace URL
          </Typography>
          <Typography variant="body1" component="li">
            The Databricks API Token
          </Typography>
          <Typography variant="body1" component="li">
            The Instance Profile ARN
          </Typography>
          <Typography variant="body1" component="li">
            (Optional) AWS Glue Catalog ID
          </Typography>
        </Box>
        <Typography variant="body1">
          These credentials allow Tecton to connect to Databricks, and through Databricks access data in your AWS
          account.
        </Typography>
      </Stack>

      <Stack component="section" spacing={2}>
        <Typography variant="h5">
          <Chip label="Step 1" size="small" /> Databricks Workspace URL
        </Typography>
        <Typography variant="body1">
          The URL of your Databricks workspace. The URL must start with “https://” and end with “cloud.databricks.com”.
        </Typography>
        <Typography variant="body2">
          Note that you can only set this once. If you would like to change your Databricks workspace once it’s set, you
          will need to contact <a href="mailto:support@tecton.ai">support@tecton.ai</a>.
        </Typography>
      </Stack>

      <Stack component="section" spacing={2}>
        <Typography variant="h5">
          <Chip label="Step 2" size="small" /> Databricks API Token
        </Typography>
        <Typography variant="body1">
          Tecton uses this API token to access your Databricks workspace. We recommend setting up a dedicated{' '}
          <a
            href="https://docs.databricks.com/administration-guide/users-groups/service-principals.html"
            target="_blank"
            rel="noreferrer"
          >
            service principal account
          </a>{' '}
          for Tecton in your workspace, giving that account the appropriate privileges, and generating an API token
          associated with that account. This way, you can ensure that production services will not be interrupted due to
          account or personnel changes. If you do not want to manage a service principal account, you can also use a
          token associated with an admin user account.
        </Typography>
      </Stack>

      <Stack component="section" spacing={2}>
        <Typography variant="h6">Ensure Account has &ldquo;Unrestricted Cluster Creation&rdquo; privileges</Typography>
        <Typography variant="body1">
          Tecton will use this token to spin up clusters for materialization jobs as needed. Thus, the account it is
          associated with must have the privilege to do so.
        </Typography>
        <Typography variant="body1">
          If using a service principal, you must grant it this privilege by following these{' '}
          <a
            href="https://docs.databricks.com/administration-guide/users-groups/service-principals.html#manage-access-tokens-for-a-service-principal"
            target="_blank"
            rel="noreferrer"
          >
            instructions by Databricks
          </a>
          {'.'}
        </Typography>
        <Typography variant="body1">
          If using an admin user account, you already have that privilege by default but you can verify it in the
          Databricks Web UI:
        </Typography>
        <Typography variant="body1">1. Go to Settings &gt; Admin Console on the left menu.</Typography>
        <Typography variant="body1">2. Go to the Users tab on the Admin Console page.</Typography>
        <Typography variant="body1">
          3. Ensure the column labeled &ldquo;Allow unrestricted cluster creation&rdquo; is checked.
        </Typography>
        {/*
        If we ever allow non-admin users in Data Platform Onboarding, uncomment these instructions.
        <Typography variant="body1">
          If you do not have that privilege, ask an admin of your Databricks workspace to grant the privilege to you.
        </Typography>
        */}
      </Stack>

      <Stack component="section" spacing={2}>
        <Typography variant="h6">Generate a Databricks API token</Typography>

        <Typography variant="body1">
          If creating an API token for a service principal, follow these{' '}
          <a
            href="https://docs.databricks.com/administration-guide/users-groups/service-principals.html#manage-access-tokens-for-a-service-principal"
            target="_blank"
            rel="noreferrer"
          >
            instructions by Databricks
          </a>
          {'.'}
        </Typography>

        <Typography variant="body1">
          If creating an API token for a user account, you can use the Databricks UI and follow{' '}
          <a
            href="https://docs.databricks.com/dev-tools/api/latest/authentication.html#generate-a-personal-access-token"
            target="_blank"
            rel="noreferrer"
          >
            these steps
          </a>
          {'.'}
        </Typography>

        <Typography variant="body1">
          Carefully specify the purpose of the token (e.g. &ldquo;Materialization in Tecton&rdquo;), and the lifetime of
          the token. Be mindful of the lifetime of the token. When the token expires, Tecton feature materialization
          will stop running. We recommend setting a longer expiration time.
        </Typography>
      </Stack>

      <Stack component="section" spacing={2}>
        <Typography variant="h5">
          <Chip label="Step 3" size="small" /> Instance Profile
        </Typography>
        <Typography variant="body1">
          Tecton requires an AWS IAM instance profile that is used by EC2 for data access. Instance profiles are used to
          provide role information to EC2 instances managed by Databricks. These allow Tecton-managed Databricks
          clusters to access data without requiring AWS keys. This instance profile should already exist in your
          Databricks workspace. To find your Instance Profile information, do the following.
        </Typography>
        <Typography variant="body1">In the Databricks Web UI:</Typography>
        <Typography variant="body1">1. Go to Settings &gt; Admin Console on the left menu.</Typography>
        <Typography variant="body1">2. Go to the Instance Profiles tab on the Admin Console page.</Typography>
        <Typography variant="body1">
          3. Select the Instance Profile that enables Databricks to access your data. (Note that when using Service
          Principals, you must grant the Service Principal access to the Instance Profile in Databricks)
        </Typography>
        <Typography>
          If your Databricks is not yet setup with Instance Profiles for accessing data in AWS, see the{' '}
          <a
            href="https://docs.databricks.com/administration-guide/cloud-configurations/aws/instance-profiles.html"
            target="_blank"
            rel="noreferrer"
          >
            Secure access to S3 buckets using instance profiles
          </a>{' '}
          in Databricks&rsquo; documentation to learn more.
        </Typography>
      </Stack>

      <Stack component="section" spacing={2}>
        <Typography variant="h5">
          <Chip label="Step 4" size="small" /> AWS Glue Catalog
        </Typography>
        <Typography variant="body1">
          Enabling Glue Catalog allows Tecton to access your Glue Catalog in your AWS account. Setting this to true adds
          `spark.databricks.hive.metastore.glueCatalog.enabled` value in your Spark configuration.
        </Typography>
        <Typography>
          Note: Without enabling Glue Catalog, you will not be able to use the{' '}
          <a rel="noreferrer" href="https://docs.tecton.ai/docs/defining-features/data-sources" target="_blank">
            Hive Data Source
          </a>{' '}
          type, which has much faster data access.
        </Typography>
      </Stack>
    </Stack>
  );
};

export default ConfigureDatabricksHelp;
