import React from 'react';
import { Box } from '@mui/material';
import WorkspaceSelectionMockNavigation from '../WorkspaceSelectionMockNavigation';
import { ContentAndDocsTwoColumnLayout } from '../../../../components';
import NoWorkspacesAvailableMainColumn from './NoWorkspacesAvailableMainColumn';
import NoWorkspacesAvailableCreateWorkspaceInstructionsColumn from './NoWorkspacesAvailableCreateWorkspaceInstructionsColumn';
import { Header } from '../../Header';

const NoWorkspacesAvailable = () => {
  return (
    <Box display="flex" sx={{ width: '100vw', height: '100vh', overflowY: 'scroll' }}>
      <WorkspaceSelectionMockNavigation />
      <div id="main">
        <Header />
        <div style={{ backgroundColor: '#f2f7fa', width: '100%' }}>
          <div id="content" className="overflow-y-hidden h-100 content-shadow w-100 ">
            <ContentAndDocsTwoColumnLayout
              mainColumn={<NoWorkspacesAvailableMainColumn />}
              docsColumn={<NoWorkspacesAvailableCreateWorkspaceInstructionsColumn />}
            />
          </div>
        </div>
      </div>
    </Box>
  );
};

export default NoWorkspacesAvailable;
