import { DataFlowNode, SUMMARYNodeTypes, NodesMapType } from './dataFlowTypes';
import { sugiyama, graphConnect, tweakFlip, layeringSimplex, coordCenter } from 'd3-dag';
import { Node, Edge } from '@xyflow/react';
import { STORE_PANEL_WIDTH } from './helpers';

const featureServicesHasAtLeastOneUpstreamDependency = (
  featureServices: DataFlowNode[],
  nodesMap: NodesMapType,
  criteria: 'hasMaterializationUpstream' | 'hasOnDemandFeatureUpstream'
): boolean => {
  const hasMaterializationUpstream = (upstreamNode: DataFlowNode): boolean => {
    return upstreamNode.type === 'feature_view';
  };

  const hasOnDemandFeatureUpstream = (upstreamNode: DataFlowNode): boolean => {
    return upstreamNode.type === 'odfv';
  };

  const criteriaFn =
    criteria === 'hasMaterializationUpstream' ? hasMaterializationUpstream : hasOnDemandFeatureUpstream;

  if (featureServices.length > 0) {
    const fsMeetingCriteria = featureServices.find((fs) => {
      if (fs.upstreamNodes) {
        const result = fs.upstreamNodes.find((upstreamNodeId) => {
          const upstreamNode = nodesMap[upstreamNodeId];

          if (criteriaFn(upstreamNode)) {
            return true;
          }
        });

        return !!result;
      } else {
        return false;
      }
    });

    return !!fsMeetingCriteria;
  }

  return false;
};

const ODFVsHasAtLeastOneUpstreamDependency = (ODFVs: DataFlowNode[], nodesMap: NodesMapType): boolean => {
  if (ODFVs.length > 0) {
    return !!ODFVs.find((odfv) => {
      if (odfv.upstreamNodes) {
        const upstreamFV = odfv.upstreamNodes.find((upstreamNodeId) => {
          const upstreamNode = nodesMap[upstreamNodeId];

          if (upstreamNode.type === 'feature_view') {
            return true;
          }
        });

        return !!upstreamFV;
      }
    });
  }

  return false;
};

type AbstractNodesAndEdges = {
  abstractNodes: string[];
  abstractEdges: [string, string][];
  counts: Record<string, number>;
};

/**
 * Aggregates nodes into abstract nodes and edges,
 * so that each abstract node represents a category of nodes
 */
export const constructSummaryNodesAndEdges = (
  nodesList: DataFlowNode[],
  nodesMap: NodesMapType
): AbstractNodesAndEdges => {
  const abstractNodes: Set<SUMMARYNodeTypes> = new Set();
  const counts: Record<string, number> = {};
  const abstractEdges: [SUMMARYNodeTypes, SUMMARYNodeTypes][] = [];

  // Data Sources by Type
  const batchDataSources = nodesList.filter((n) => n.type === 'data_source' && n.dataSourceType === 'Batch');

  if (batchDataSources.length > 0) {
    abstractNodes.add('SUMMARY_BATCH_DATA_SOURCES');
    counts['SUMMARY_BATCH_DATA_SOURCES'] = batchDataSources.length;
  }

  const streamDataSources = nodesList.filter((n) => n.type === 'data_source' && n.dataSourceType === 'Stream');

  if (streamDataSources.length > 0) {
    abstractNodes.add('SUMMARY_STREAM_DATA_SOURCES');
    counts['SUMMARY_STREAM_DATA_SOURCES'] = streamDataSources.length;
  }

  // Feature Views by Type
  const batchFeatureViews = nodesList.filter((n) => n.type === 'feature_view' && n.featureViewType === 'batch');

  if (batchFeatureViews.length > 0) {
    abstractNodes.add('SUMMARY_BATCH_FEATURE_VIEWS');
    abstractNodes.add('SUMMARY_STORE_WRAPPER');
    abstractEdges.push(['SUMMARY_BATCH_DATA_SOURCES', 'SUMMARY_BATCH_FEATURE_VIEWS']);
    abstractEdges.push(['SUMMARY_BATCH_FEATURE_VIEWS', 'SUMMARY_STORE_WRAPPER']);

    counts['SUMMARY_BATCH_FEATURE_VIEWS'] = batchFeatureViews.length;
  }

  const streamFeatureViews = nodesList.filter((n) => n.type === 'feature_view' && n.featureViewType === 'stream');

  if (streamFeatureViews.length > 0) {
    abstractNodes.add('SUMMARY_STREAM_FEATURE_VIEWS');
    abstractNodes.add('SUMMARY_STORE_WRAPPER');
    abstractEdges.push(['SUMMARY_STREAM_DATA_SOURCES', 'SUMMARY_STREAM_FEATURE_VIEWS']);
    abstractEdges.push(['SUMMARY_STREAM_FEATURE_VIEWS', 'SUMMARY_STORE_WRAPPER']);

    counts['SUMMARY_STREAM_FEATURE_VIEWS'] = streamFeatureViews.length;
  }

  const featureTables = nodesList.filter((n) => n.type === 'feature_view' && n.featureViewType === 'feature table');

  if (featureTables.length > 0) {
    abstractNodes.add('SUMMARY_FEATURE_TABLES');
    abstractNodes.add('SUMMARY_STORE_WRAPPER');
    abstractEdges.push(['SUMMARY_FEATURE_TABLES', 'SUMMARY_STORE_WRAPPER']);

    counts['SUMMARY_FEATURE_TABLES'] = featureTables.length;
  }

  const ODFVs = nodesList.filter((n) => n.type === 'odfv');

  if (ODFVs.length > 0) {
    abstractNodes.add('SUMMARY_ODFVS');
    counts['SUMMARY_ODFVS'] = ODFVs.length;

    if (ODFVsHasAtLeastOneUpstreamDependency(ODFVs, nodesMap)) {
      abstractNodes.add('SUMMARY_STORE_WRAPPER');
      abstractEdges.push(['SUMMARY_STORE_WRAPPER', 'SUMMARY_ODFVS']);
    }
  }

  // Feature Services - online serving or not
  const onlineFeatureServices = nodesList.filter((n) => n.type === 'feature_service' && n.isOnlineServingEnabled);

  if (onlineFeatureServices.length > 0) {
    abstractNodes.add('SUMMARY_ONLINE_FEATURE_SERVICES');
    counts['SUMMARY_ONLINE_FEATURE_SERVICES'] = onlineFeatureServices.length;

    if (featureServicesHasAtLeastOneUpstreamDependency(onlineFeatureServices, nodesMap, 'hasOnDemandFeatureUpstream')) {
      abstractNodes.add('SUMMARY_ODFVS');
      abstractEdges.push(['SUMMARY_ODFVS', 'SUMMARY_ONLINE_FEATURE_SERVICES']);
    }

    if (featureServicesHasAtLeastOneUpstreamDependency(onlineFeatureServices, nodesMap, 'hasMaterializationUpstream')) {
      abstractNodes.add('SUMMARY_STORE_WRAPPER');
      abstractEdges.push(['SUMMARY_STORE_WRAPPER', 'SUMMARY_ONLINE_FEATURE_SERVICES']);
    }
  }

  const offlineFeatureServices = nodesList.filter((n) => n.type === 'feature_service' && !n.isOnlineServingEnabled);

  if (offlineFeatureServices.length > 0) {
    abstractNodes.add('SUMMARY_OFFLINE_FEATURE_SERVICES');
    counts['SUMMARY_OFFLINE_FEATURE_SERVICES'] = offlineFeatureServices.length;

    if (
      featureServicesHasAtLeastOneUpstreamDependency(offlineFeatureServices, nodesMap, 'hasOnDemandFeatureUpstream')
    ) {
      abstractNodes.add('SUMMARY_ODFVS');
      abstractEdges.push(['SUMMARY_ODFVS', 'SUMMARY_OFFLINE_FEATURE_SERVICES']);
    }

    if (
      featureServicesHasAtLeastOneUpstreamDependency(offlineFeatureServices, nodesMap, 'hasMaterializationUpstream')
    ) {
      abstractNodes.add('SUMMARY_STORE_WRAPPER');
      abstractEdges.push(['SUMMARY_STORE_WRAPPER', 'SUMMARY_OFFLINE_FEATURE_SERVICES']);
    }
  }

  return {
    abstractNodes: Array.from(abstractNodes),
    abstractEdges,
    counts,
  };
};

const rankMapping: Record<SUMMARYNodeTypes, number | undefined> = {
  SUMMARY_BATCH_DATA_SOURCES: 100,
  SUMMARY_STREAM_DATA_SOURCES: 100,
  SUMMARY_BATCH_FEATURE_VIEWS: undefined,
  SUMMARY_STREAM_FEATURE_VIEWS: undefined,
  SUMMARY_FEATURE_TABLES: undefined,
  SUMMARY_ODFVS: 300,
  SUMMARY_ONLINE_FEATURE_SERVICES: 400,
  SUMMARY_OFFLINE_FEATURE_SERVICES: 400,
  SUMMARY_STORE_WRAPPER: 200,
};

const VERTICAL_GAP = 20;
const HORIZONTAL_GAP = 40;
const NODE_HEIGHT = 60;
const NODE_WIDTH = 300;

export const layoutNodePositions = (abstractEdges: [string, string][]) => {
  const builder = graphConnect();
  const dag = builder(abstractEdges);

  const flip = tweakFlip('diagonal');

  const layout = sugiyama()
    .layering(
      layeringSimplex().rank(({ data }) => {
        const rank = rankMapping[data];

        return rank;
      })
    )
    .coord(coordCenter())
    // Somehow Typescript couldn't find the nodeSize() method, even though
    // it clearly exists in the library, hence this ignore.
    // @ts-ignore
    .nodeSize((node) => {
      const data = node.data;

      if (data === 'SUMMARY_STORE_WRAPPER') {
        return [1, STORE_PANEL_WIDTH];
      } else {
        return [NODE_HEIGHT, NODE_WIDTH];
      }
    })
    .gap([VERTICAL_GAP, HORIZONTAL_GAP]);

  const res = layout(dag);

  flip(dag, res);

  const positionMap: Record<string, { x: number; y: number }> = {};

  for (const sugiyamaNode of dag.nodes()) {
    const id = sugiyamaNode.data;

    positionMap[id] = {
      x: sugiyamaNode.x,
      y: sugiyamaNode.y,
    };

    if (id === 'SUMMARY_STORE_WRAPPER') {
      positionMap[id].x += 50;
      positionMap[id].y -= 80;
    }
  }

  return positionMap;
};

type SUMMARYNodeAbstractTypes =
  | 'SUMMARY_DATA_SOURCES'
  | 'SUMMARY_FEATURE_VIEWS'
  | 'SUMMARY_FEATURE_SERVICES'
  | 'SUMMARY_ODFVS'
  | 'SUMMARY_STORE_WRAPPER';

const abstractTypeMapping: Record<string, SUMMARYNodeAbstractTypes> = {
  SUMMARY_BATCH_DATA_SOURCES: 'SUMMARY_DATA_SOURCES',
  SUMMARY_STREAM_DATA_SOURCES: 'SUMMARY_DATA_SOURCES',
  SUMMARY_BATCH_FEATURE_VIEWS: 'SUMMARY_FEATURE_VIEWS',
  SUMMARY_STREAM_FEATURE_VIEWS: 'SUMMARY_FEATURE_VIEWS',
  SUMMARY_FEATURE_TABLES: 'SUMMARY_FEATURE_VIEWS',
  SUMMARY_ODFVS: 'SUMMARY_ODFVS',
  SUMMARY_ONLINE_FEATURE_SERVICES: 'SUMMARY_FEATURE_SERVICES',
  SUMMARY_OFFLINE_FEATURE_SERVICES: 'SUMMARY_FEATURE_SERVICES',
  SUMMARY_STORE_WRAPPER: 'SUMMARY_STORE_WRAPPER',
};

/**
 * Computes the position of the last
 * node of each abstract type.
 * Used only for nodes that don't have a position
 */
const abstractColumnPositions = (nodes: Node[]) => {
  const columnPositions: Record<string, { x: number; y: number }> = {};

  for (const node of nodes) {
    const position = node.position;

    if (!position) {
      continue;
    }

    const type = node.type;
    const abstractType: string | undefined = type ? abstractTypeMapping[type] || undefined : undefined;

    if (!abstractType) {
      throw new Error(`No abstract type found for ${type}`);
    }

    columnPositions[abstractType] = {
      x: node.position.x,
      y:
        columnPositions[abstractType] && columnPositions[abstractType].y > node.position.y
          ? columnPositions[abstractType].y
          : node.position.y,
    };
  }

  return columnPositions;
};

/**
 * Remedial layout for nodes that don't have positions
 * Should only occur for nodes that are not part of the abstract graph
 * because they are not connected to anything
 * In context of this SUMMARY graph, this means that
 * every node of this category is unconnected, which is possible
 * but really weird.
 */
const remedyNodesWithoutPositions = (nodes: Node[]) => {
  const nodesWithoutPositions = nodes.filter((n) => !n.position);
  const nodesWithPositions = nodes.filter((n) => !!n.position);

  const columnPositions = abstractColumnPositions(nodes);
  let minX = 0;
  let maxY = 0;

  if (nodesWithPositions.length > 0) {
    minX = Math.min(...nodesWithPositions.map((n) => n.position.x));
    maxY = Math.max(...nodesWithPositions.map((n) => n.position.y));
  }

  for (const node of nodesWithoutPositions) {
    if (!node.type) {
      throw new Error(`Node ${node.id} does not have a type`);
    }

    const abstractType = abstractTypeMapping[node.type];

    if (!abstractType) {
      throw new Error(`No abstract type found for ${node.type}`);
    }

    const columnPosition = columnPositions[abstractType];

    if (columnPosition) {
      node.position = {
        x: columnPosition.x,
        y: columnPosition.y + NODE_HEIGHT + VERTICAL_GAP,
      };
    } else {
      node.position = {
        x: minX,
        y: maxY + NODE_HEIGHT + VERTICAL_GAP,
      };

      minX += NODE_WIDTH + HORIZONTAL_GAP;
    }
  }
};

const ICON_X = 30;
const ONLINE_ICON_Y = 45;
const OFFLINE_ICON_Y = 130;

export const processSummaryModeForReactFlow = (
  nodesList: DataFlowNode[],
  nodesMap: NodesMapType
): {
  nodes: Node[];
  edges: Edge[];
} => {
  const nodes: Node[] = [];
  const edges: Edge[] = [];

  const { abstractNodes, abstractEdges, counts } = constructSummaryNodesAndEdges(nodesList, nodesMap);

  const positionMap = layoutNodePositions(abstractEdges);

  for (const abstractNode of abstractNodes) {
    const position = positionMap[abstractNode];

    const node: Node = {
      id: abstractNode,
      type: abstractNode,
      position,
      data: {
        type: abstractNode,
        count: counts[abstractNode],
      },
    };

    nodes.push(node);
  }

  const nodesWithoutPositions = nodes.filter((n) => !n.position);

  if (nodesWithoutPositions.length > 0) {
    // Remedial layout for nodes that don't have positions
    remedyNodesWithoutPositions(nodes);
  }

  for (const abstractEdge of abstractEdges) {
    const edge: Edge = {
      id: `${abstractEdge[0]}-${abstractEdge[1]}`,
      source: abstractEdge[0],
      target: abstractEdge[1],
      type: 'SUMMARYEdge',
    };

    edges.push(edge);
  }

  const hasStoreWrapper = !!nodes.find((n) => n.type === 'SUMMARY_STORE_WRAPPER');

  if (hasStoreWrapper) {
    // Only add the online and offline store icons if there is a store wrapper
    nodes.push({
      id: 'SUMMARY_ONLINE_STORE',
      parentId: 'SUMMARY_STORE_WRAPPER',
      position: {
        x: ICON_X,
        y: ONLINE_ICON_Y,
      },
      type: 'SUMMARY_ONLINE_STORE',
      data: {
        type: 'SUMMARY_ONLINE_STORE',
      },
    });

    nodes.push({
      id: 'SUMMARY_OFFLINE_STORE',
      parentId: 'SUMMARY_STORE_WRAPPER',
      position: {
        x: ICON_X,
        y: OFFLINE_ICON_Y,
      },
      type: 'SUMMARY_OFFLINE_STORE',
      data: {
        type: 'SUMMARY_OFFLINE_STORE',
      },
    });
  }

  return {
    nodes,
    edges,
  };
};
