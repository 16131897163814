import { Navigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';

// Tecton
import { useGetDatasets } from '../../../api/datasets';
import { useGetFcos } from '../../../api/fcos';
import { DatasetFCO, FCOFields, WorkspaceFCOContainer } from '../../../core/types/fcoTypes';
import { useUserSettings } from '../../context/UserSettingsContext';
import DataSetOverview from './DataSetOverview';

// @tecton
import { Breadcrumbs, HeaderLayout, Loading, Subtitle, ViewLayout } from '@tecton/ComponentRedesign';
import WorkspaceRootBreadcrumb from '../WorkspaceRootBreadcrumb';

const DatasetContainer = () => {
  const { workspace, workspaceDetails } = useUserSettings();
  const { name } = useParams();
  const { data, isLoading } = useGetFcos(workspace ?? '', {
    select: (data: WorkspaceFCOContainer) => {
      return {
        idToFcoMap: data?.idToFcoMap,
      };
    },
  });

  const { data: dataSetsData, isLoading: isLoadingDataset } = useGetDatasets(workspace ?? '');

  if (isLoading || isLoadingDataset) {
    return <Loading />;
  }

  const fco = dataSetsData?.data.find((fcoDataset: DatasetFCO) => {
    return fcoDataset[FCOFields.NAME] === name;
  });

  if (!fco) {
    // We switched workspaces and didn't find the datasets name, so we redirect them to the datasets lists
    return <Navigate to={`../${workspace}/datasets`} />;
  }

  const breadcrumbs = (
    <Breadcrumbs
      workspace={<WorkspaceRootBreadcrumb workspace={workspaceDetails} />}
      crumbs={[{ label: <Link to={`../${workspace}/datasets`}>Datasets</Link> }, { label: name }]}
    />
  );
  const subtitle = <Subtitle description={fco?.[FCOFields.DESCRIPTION] ?? 'No description provided.'} />;
  const header = <HeaderLayout breadcrumbs={breadcrumbs} subtitle={subtitle} />;

  return <ViewLayout header={header} body={<DataSetOverview fco={fco} idToFcoMap={data.idToFcoMap} />} />;
};

export default DatasetContainer;
