import { ButtonEmpty } from '@tecton/ComponentRedesign';
import { FC } from 'react';

interface RoleIconNameProps {
  role: string;
  onClick?: VoidFunction;
}

import AdminIcon from '@svg/admin.svg';
import BlockIcon from '@svg/block.svg';
import ConsumerIcon from '@svg/consumer.svg';
import EditorIcon from '@svg/editor.svg';
import OperatorIcon from '@svg/operator.svg';
import OwnerIcon from '@svg/owner.svg';
import RegularIcon from '@svg/person.svg';
import ViewerIcon from '@svg/viewer.svg';

export const aclIcons: Record<string, string> = {
  none: BlockIcon,
  Owner: OwnerIcon,
  Editor: EditorIcon,
  Operator: OperatorIcon,
  Consumer: ConsumerIcon,
  Viewer: ViewerIcon,
  None: BlockIcon,
  Admin: AdminIcon,
  Regular: RegularIcon,
  'Trial Consumer': ConsumerIcon,
};

const RoleIconName: FC<RoleIconNameProps> = ({ role, onClick }) => {
  return (
    <ButtonEmpty size="s" iconType={aclIcons[role]} onClick={onClick} isDisabled={!onClick}>
      {role === 'none' ? 'None' : role}
    </ButtonEmpty>
  );
};

export default RoleIconName;
