import styled from '@emotion/styled';
import React, { FC } from 'react';
import { FlexGroup, FlexItem, Text } from '.';

interface ViewLayoutProps {
  breadcrumbs: React.ReactNode;
  title: React.ReactNode;
  tabs: React.ReactNode;
  content: React.ReactNode;
}

const Wrapper = styled.div`
  border-radius: ${({ theme }) => theme.v1.size['4']}px;
  background-color: ${({ theme }) => theme.v1.colors.background.empty};
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

// ALL LAYOUTS ARE WiP and pending final design
const ViewLayout: FC<ViewLayoutProps> = ({ breadcrumbs, title, tabs, content }) => {
  return (
    <Wrapper>
      <FlexGroup gapSize="1" direction="column" padding="4" css={{ height: '100%' }}>
        <FlexItem shrink="0" grow="0">
          {breadcrumbs}
        </FlexItem>

        <FlexItem shrink="0" grow="0">
          <Text element="h3">{title}</Text>
        </FlexItem>

        <FlexItem shrink="0" grow="0">
          {tabs}
        </FlexItem>
        <FlexItem grow="1" shrink="1">
          {content}
        </FlexItem>
      </FlexGroup>
    </Wrapper>
  );
};

export default ViewLayout;
