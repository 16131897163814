import React from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { useGeneratedHtmlId } from '@tecton';
import { fcoColors } from './DataFlowConstants';
import { AggregationNode, DataflowCardPropType, TransformationNode } from './dataFlowTypes';

const WIDTH = 200;

const Wrapper = styled.div<{ isFaded?: boolean }>`
  width: 44px;
  position: relative;
  cursor: pointer;
  opacity: ${(props) => (props.isFaded ? 0.15 : 1)};

  span:after {
    transform: scale(1.2, 1) translate(4px);
  }
`;

const TruncatedName = styled.span`
  display: block;
  position: absolute;
  top: -1.5em;
  left: 0;
  width: ${WIDTH}px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  z-index: 10;

  &:hover {
    width: 1000px;
  }
`;

const animationOne = keyframes`
  0% {
    transform: translate(-60px, 0);
  }

  27% {
    transform: translate(0, 0);
  }

  94% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(40px, 0);
  }
`;

const animationTwo = keyframes`
  0% {
    transform: translate(-60px, 0);
  }

  27% {
    transform: translate(-60px, 0);
  }

  54% {
    transform: translate(0, 0);
  }

  94% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(40px, 0);
  }
`;

const animationThree = keyframes`
  0% {
    transform: translate(-60px, 0);
  }

  54% {
    transform: translate(-60px, 0);
  }

  81% {
    transform: translate(0, 0);
  }

  94% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(40px, 0);
  }
`;

const duration = '2s';

const AnimatedCircleOne = styled.circle`
  fill: ${fcoColors.feature_view.background};
  animation-name: ${animationOne};
  animation-duration: ${duration};
  animation-timing-function: linear;
  animation-iteration-count: infinite;
`;

const AnimatedCircleTwo = styled.circle`
  fill: ${fcoColors.feature_view.background};
  animation-name: ${animationTwo};
  animation-duration: ${duration};
  animation-timing-function: linear;
  animation-iteration-count: infinite;
`;

const AnimatedCircleThree = styled.circle`
  fill: ${fcoColors.feature_view.background};
  animation-name: ${animationThree};
  animation-duration: ${duration};
  animation-timing-function: linear;
  animation-iteration-count: infinite;
`;

const NodeSVG = styled.svg<{ width: number; height: number }>`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
`;

const SvgWithGlowWrapper = styled.span<{
  isFaded?: boolean;
  hasFocus?: boolean;
  inset?: [number, number];
}>`
  position: relative;
  opacity: ${(props) => (props.isFaded ? 0.15 : 1)};
  transition: opacity 0.3;

  svg {
    display: block;
    position: relative;
    z-index: 2;
  }

  ${({ hasFocus, inset }) => {
    if (!hasFocus) {
      return '';
    }

    return `
      &:after {
        content: '';
        position: absolute;
        z-index: 1;
        top: ${inset ? inset[1] : 0}px;
        left: ${inset ? inset[0] : 0}px;
        width: calc(100% - ${inset ? inset[0] * 2 : 0}px);
        height: calc(100% - ${inset ? inset[1] * 2 : 0}px);
        box-shadow: 0 0 20px 10px ${fcoColors['feature_view'].glow};
      }`;
  }}
`;

const PipeIcon = () => {
  return (
    <>
      <path
        d={`
              M 4 0 A 4 7 0 0 0 4 14 L 38 14 A 4 7 0 0 0 38 0 Z
            `}
        fill={fcoColors['feature_view'].glow}
        stroke={fcoColors['feature_view'].background}
        strokeWidth="2"
      />
      <ellipse
        cx="4"
        cy="7"
        rx="4"
        ry="7"
        fill={fcoColors['feature_view'].background}
        stroke={fcoColors['feature_view'].background}
        strokeWidth="2"
      />
    </>
  );
};

const DataFlowFeatureViewPipelineNode = ({
  data,
  onMouseEnter,
  onMouseLeave,
  onClick,
  hasFocus,
  isFaded,
}: { data: AggregationNode | TransformationNode } & DataflowCardPropType) => {
  const generatedId = useGeneratedHtmlId({ prefix: 'transiformation-clippath' });

  if (data.type === 'transformation') {
    return (
      <>
        {/* <Handle isConnectable={false} type="target" position={Position.Left} /> */}
        <Wrapper onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onClick={onClick} isFaded={isFaded}>
          <TruncatedName>{data.name}</TruncatedName>
          <SvgWithGlowWrapper hasFocus={hasFocus} inset={[4, 2]}>
            <NodeSVG width={45} height={16} style={{ display: 'block' }}>
              <g transform="translate(2, 1)">
                <PipeIcon />
              </g>
            </NodeSVG>
          </SvgWithGlowWrapper>
        </Wrapper>
        {/* <Handle isConnectable={false} type="source" position={Position.Right} /> */}
      </>
    );
  }

  return (
    <>
      <Wrapper onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onClick={onClick} isFaded={isFaded}>
        <TruncatedName>{data.name}</TruncatedName>
        <SvgWithGlowWrapper hasFocus={hasFocus} inset={[0, 5]}>
          <NodeSVG width={55} height={22} style={{ display: 'block' }}>
            <defs>
              <clipPath id={generatedId}>
                <path d="M 11 2 L 44 2 A 9 9 0 0 1 44 20 L 11 20 A 9 9 0 0 1 11 2 Z" fill="black" />
              </clipPath>
            </defs>

            <path
              d="M 11 2 L 44 2 A 9 9 0 0 1 44 20 L 11 20 A 9 9 0 0 1 11 2 Z"
              stroke={fcoColors.feature_view.background}
              strokeWidth="2"
              fill={fcoColors.feature_view.glow}
            />
            <g clipPath={`url(#${generatedId})`}>
              <AnimatedCircleOne cx={44} cy={11} r={6} />
              <AnimatedCircleTwo cx={32} cy={11} r={6} />
              <AnimatedCircleThree cx={20} cy={11} r={6} />
            </g>
          </NodeSVG>
        </SvgWithGlowWrapper>
      </Wrapper>
    </>
  );
};

export default DataFlowFeatureViewPipelineNode;
