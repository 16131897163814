/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Title from '../../../utils/title-utils';
import MonitoringSummary from './monitoring/FeatureServiceMonitoring';
import FeatureServiceTabs from '../../../components/FeatureServiceTabs';
import { Paper } from '@mui/material';

const OnlineStoreMonitoring = () => {
  return (
    <div id="content" className="content-shadow overflow-y-scroll h-100">
      <Title title="Online Store Monitoring" />
      <div className="content-head">
        <div>
          <div className="f3 fw7 mb3">Services</div>
          <FeatureServiceTabs />
        </div>
      </div>
      <div className="pa5 " data-testid="tabs-content">
        <div>
          <Paper square>
            <div className="pa4">
              <MonitoringSummary />
            </div>
          </Paper>
        </div>
      </div>
    </div>
  );
};

export default OnlineStoreMonitoring;
