import React from 'react';
import { Position } from '@xyflow/react';
import { AnchorDataflowCardDescriptionWrapper, AnchorWrapper } from './VisualPrimitives/CardTypography';
import { FeatureServiceNode, DataflowCardPropType } from './dataFlowTypes';
import { ANCHOR_NODE_WIDTH } from './helpers';
import DataFlowHandle from './DataFlowHandle';
import { DataFlowFCOIcon } from './DataFlowFCONode';
import styled from '@emotion/styled';

import DataFlowFCOCountBadge from './DataFlowFCOCountBadge';

const AnchorDataflowCard = styled.div`
  position: relative;
  width: ${ANCHOR_NODE_WIDTH}px;
  transition: opacity 0.3;
  box-shadow: ${({ theme }) => theme.shadow.xl};
  // background: ${({ theme }) => theme.colors.emptyShade};
`;

const AnchorHeader = styled.div`
  background: ${({ theme }) => theme.colors.emptyShade};
  border-bottom: 1px solid #999;
  padding: 1.2em 1.75em 0.7em 1.75em;
  border-top-right-radius: ${({ theme }) => theme.border.radius.medium};
  border-top-left-radius: ${({ theme }) => theme.border.radius.medium};
`;

const FeatureServiceTypeLabel = styled.div`
  font-size: ${({ theme }) => theme.font.fontSizes.xs};
  font-weight: ${({ theme }) => theme.font.weight.regular};
  color: ${({ theme }) => theme.colors.subduedText};
`;

const FeatureServiceNameLabel = styled.div`
  font-size: ${({ theme }) => theme.font.fontSizes.s};
  font-weight: ${({ theme }) => theme.font.weight.medium};
  color: ${({ theme }) => theme.colors.title};
`;

const TitleLayout = styled.div`
  display: grid;
  grid-template-columns: 48px 1fr;
`;

const CountBadgePositioner = styled.div`
  position: absolute;
  right: -16px;
  top: 16px;
`;

const DataFlowFeatureServiceAnchorCard = ({
  data,
  onMouseEnter,
  onMouseLeave,
  onClick,
}: { data: FeatureServiceNode } & DataflowCardPropType) => {
  const description = data.description || 'No description';

  return (
    <AnchorWrapper>
      <AnchorDataflowCard
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={onClick}
        style={data.hasRequestDataSource ? { paddingBottom: '54px' } : undefined}
      >
        <AnchorHeader>
          <TitleLayout>
            <div>
              <DataFlowFCOIcon fcoType={'feature_service'} />
            </div>
            <div>
              <FeatureServiceTypeLabel>FEATURE SERVICE</FeatureServiceTypeLabel>
              <FeatureServiceNameLabel>{data.name}</FeatureServiceNameLabel>
            </div>
          </TitleLayout>
        </AnchorHeader>
        <AnchorDataflowCardDescriptionWrapper>
          <p>{description}</p>
        </AnchorDataflowCardDescriptionWrapper>
      </AnchorDataflowCard>
      <DataFlowHandle position={Position.Left} top={'36px'} />
      <DataFlowHandle position={Position.Right} top={'36px'} />
      <CountBadgePositioner>
        <DataFlowFCOCountBadge data={data} />
      </CountBadgePositioner>
    </AnchorWrapper>
  );
};

export default DataFlowFeatureServiceAnchorCard;
