import { TectonBasicTable } from '@shared';
import { ListGroup, ListGroupItem, Title } from '@tecton';
import React from 'react';
import { WorkspaceStoreDetailsPanelData } from './detailsPanelQueryTypes';
import { DataFlowSidePanel, DescriptionSection } from './StyledPanelComponents';
import DetailsPanelAccordionSection from './DetailsPanelAccordionSection';

const WorkspaceStoreContextPanel = ({
  id,
  data,
  zoomToNode,
  detailsPanelClearAll,
}: {
  id: string;
  data?: WorkspaceStoreDetailsPanelData;
  zoomToNode?: () => void;
  detailsPanelClearAll: () => void;
}) => {
  return (
    <DataFlowSidePanel
      name={'Feature Store'}
      prehead={''}
      nodeId={id}
      detailsPanelClearAll={detailsPanelClearAll}
      zoomToNode={zoomToNode}
    >
      <DescriptionSection description={`Feature Storage`} />

      {data && (
        <>
          {data && (
            <DetailsPanelAccordionSection
              id="materialization"
              title={
                <Title size="xxs">
                  <h4>Materializing Feature Views</h4>
                </Title>
              }
            >
              {data.featureViews.length > 0 ? (
                <TectonBasicTable
                  items={data.featureViews}
                  columns={[
                    {
                      field: 'name',
                      name: 'Name',
                    },
                    {
                      field: 'isOnlineMaterializationEnabled',
                      name: 'Online Store',
                    },
                    {
                      field: 'isOfflineMaterializationEnabled',
                      name: 'Offline Store',
                    },
                  ]}
                  pageIndex={0}
                  pageSize={0}
                  totalItemCount={0}
                />
              ) : (
                <p>No Feature Views materializing data to the store.</p>
              )}
            </DetailsPanelAccordionSection>
          )}

          {data && (
            <DetailsPanelAccordionSection
              id="store-odfv-list"
              title={
                <Title size="xxs">
                  <h4>Realtime Feature Views</h4>
                </Title>
              }
            >
              {data.ODFVs.length > 0 ? (
                <ListGroup>
                  {data.ODFVs.map((odfv) => {
                    return <ListGroupItem key={odfv.id} label={odfv.name} />;
                  })}
                </ListGroup>
              ) : (
                <p>No Realtime Feature Views reading from the store.</p>
              )}
            </DetailsPanelAccordionSection>
          )}

          {data && (
            <DetailsPanelAccordionSection
              id="store-fs-list"
              title={
                <Title size="xxs">
                  <h4>Consumers: Feature Services</h4>
                </Title>
              }
            >
              {data.featureServices.length > 0 ? (
                <ListGroup>
                  {data.featureServices.map((fs) => {
                    return <ListGroupItem key={fs.id} label={fs.name} />;
                  })}
                </ListGroup>
              ) : (
                <p>No Feature Services reading from the store.</p>
              )}
            </DetailsPanelAccordionSection>
          )}
        </>
      )}
    </DataFlowSidePanel>
  );
};

export default WorkspaceStoreContextPanel;
