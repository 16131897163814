import React, { useState } from 'react';

// @tecton
import { HeaderLayout, ViewLayout, Tabs } from '@tecton/ComponentRedesign';
import Lookup from './Lookup';
import AllMaterializedFeatureViews from './AllMaterializedFeatureViews';

const Debug = () => {
  const indexPage = {
    tabName: 'Look Up',
    component: <Lookup />,
  };

  const [selectedTab, setSelectedTab] = useState<{ tabName: string; component: React.JSX.Element }>(indexPage);

  const pages = [
    indexPage,
    {
      tabName: 'All Materialized FVs',
      component: <AllMaterializedFeatureViews />,
    },
  ];

  const tabs = (
    <Tabs
      selectedTab={`${selectedTab?.tabName}`}
      tabs={pages.map((page) => page.tabName)}
      setSelectedTab={(tabName: string) => {
        const page = pages.find((tab) => tab.tabName === tabName) ?? indexPage;
        setSelectedTab(page);
      }}
    />
  );
  const header = <HeaderLayout breadcrumbs={[]} tabs={tabs} />;
  return <ViewLayout header={header} body={selectedTab.component} />;
};

export default Debug;
