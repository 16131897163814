import { CategoricalSelectorHierarchicalOption } from '@tecton/ComponentRedesign';
import { AnyFCO, FCO } from '../../../core/types/fcoTypes';
import { isEqual, uniqWith } from 'lodash';
import { extent, group } from 'd3-array';

const getUniqueTagsFromFcos: (fcos: AnyFCO[]) => Record<string, string[]> = (fcos) => {
  const allTagPairs = fcos
    .filter((fco) => fco.tags.length > 0)
    .map((fco) => fco.tags)
    .flat();

  const allTagPairsUniq = uniqWith(allTagPairs, isEqual);
  const groupedByKey = group(allTagPairsUniq, (pair) => pair.key);
  const returnStructure: Record<string, string[]> = {};
  groupedByKey.forEach((keyValue) => {
    const key = keyValue[0].key;
    const allValues = keyValue.map((tag) => tag.value);
    returnStructure[key] = allValues;
  });

  return returnStructure;
};

export const structureTagsForFilter = (items: AnyFCO[]) => {
  const allTags = getUniqueTagsFromFcos(items);
  const structuredTags: CategoricalSelectorHierarchicalOption[] = [];
  Object.keys(allTags).map((key) => {
    structuredTags.push({ label: key });
    allTags[key].forEach((value) => {
      structuredTags.push({ label: value, parent: key });
    });
  });

  return structuredTags;
};

export const valuesForFCOCount = [
  {
    label: '0',
    value: [0],
  },
  { label: '1', value: [1] },
  { label: '2', value: [2] },
  { label: '3 - 5', value: [3, 4, 5] },
  { label: '6 - 10', value: [6, 7, 8, 9, 10] },
  { label: '> 10', value: [11] },
];

export const createdDatesExtent: (fcos: FCO[]) => [Date, Date] = (fcos) => {
  const allDates = fcos.filter((fco) => fco.createdDate as Date).map((fco) => fco.createdDate as Date);
  const attemptExtent = extent(allDates);
  return attemptExtent as [Date, Date];
};
