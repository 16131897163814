import { ApplicationLayout, ErrorBoundary, ExploreTopBanner } from '@tecton/ComponentRedesign';
import { IconTypes } from '@tecton/ComponentRedesign/Icons/Icon';
import { Workspace } from '@tecton/ComponentRedesign/SharedComponentTypes';
import TectonSideNavigation from '@tecton/SideNavigation/TectonSideNavigation';
import { TectonSideNavigationItemProps } from '@tecton/SideNavigation/TectonSideNavigationItem';
import { useCallback, useRef, useState } from 'react';
import { GlobalHotKeys } from 'react-hotkeys';
import { Outlet, useNavigate } from 'react-router';
import { useGetOnboardingStatus } from '../../api/onboarding';
import { Routes } from '../../core/routes';
import useGlobalState from '../../shared/useGlobalState';
import { OnboardingStatusEnum } from '../../types/tecton_proto/data/onboarding';
import { logEvent } from '../../utils/analytics-utils';
import { logout } from '../../utils/okta';
import UrlUtils from '../../utils/url-utils';
import { useUserSettings } from '../context/UserSettingsContext';
import GlobalSearch from './GlobalSearch';
import GuidedOnBoardingSideNavigation from './guidedOnBoarding/GuidedOnBoardingSideNavigation';
import { getSideNavigationWorkspaces } from './util';

const ApplicationContainer = () => {
  const { user, workspace, canShowMainApplication, oktaAuth, allWorkspaces, workspaceDetails, isPublicFreeTrial } =
    useUserSettings();
  const { data: onboardingStatus } = useGetOnboardingStatus(workspace ?? 'prod');
  const navigate = useNavigate();
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const [isWorkspaceSelectorOpen, setIsWorkspaceSelectorOpen] = useState<boolean>(false);
  const [canShowBanner, setCanShowBanner] = useState(true);
  const [showSearchModal, setShowSearchModal] = useState<boolean>(false);
  const SearchRef = useRef<HTMLDivElement>(null);
  const { config } = useGlobalState();
  // ------------- Fetch Data
  const workspaces = getSideNavigationWorkspaces(allWorkspaces ?? []);

  const foundWorkspace = allWorkspaces?.find((ws) => {
    return ws.name === workspace;
  });

  const canShowGuidedOnboardingSideNavigation = !(
    onboardingStatus?.finish_onboarding === OnboardingStatusEnum.ONBOARDING_STATUS_COMPLETED
  );

  // -------------------------- Workspace selector

  const getSideNavigationItems = useCallback(() => {
    const canShowCompute = config.COMPUTE_ENGINES.includes('Spark') && config.SPARK_RUNTIME.includes('DATABRICKS');
    const canShowDataQuality = config.DATA_OBSERVABILITY_VALIDATION_ENABLED === 'true';

    const computeItem = canShowCompute
      ? [
          {
            label: 'Compute',
            icon: IconTypes.COMPUTE,
            route: Routes.dataPlatformConfiguration,
          },
        ]
      : [];

    const dataQualityItem = canShowDataQuality
      ? [
          {
            label: 'Data Quality',
            icon: IconTypes.DATA_QUALITY,
            route: Routes.validations,
          },
        ]
      : [];

    const workspaceItems: TectonSideNavigationItemProps[] = canShowMainApplication
      ? [
          {
            label: 'Dataflow',
            icon: IconTypes.DATAFLOW,
            route: Routes.dashboard,
          },
          {
            label: 'Sources',
            icon: IconTypes.SOURCES,
            route: Routes.dataSources,
          },
          {
            label: 'Entities',
            icon: IconTypes.ENTITIES,
            route: Routes.entities,
          },
          {
            label: 'Transformations',
            icon: IconTypes.TRANSFORMATIONS,
            route: Routes.transformations,
          },
          {
            label: 'Features',
            icon: IconTypes.FEATURE_VIEWS,
            route: Routes.featureViews,
          },
          {
            label: 'Services',
            icon: IconTypes.FEATURE_SERVICE,
            route: Routes.featureServices,
          },
          {
            label: 'Datasets',
            icon: IconTypes.DATASET,
            route: Routes.savedFeatureDataFrames,
          },
          {
            label: 'Permissions',
            icon: IconTypes.PERMISSIONS,
            route: Routes.configurations,
          },
          ...dataQualityItem,
        ]
      : [
          {
            label: 'Workspaces',
            icon: IconTypes.WORKSPACES,
            route: `/repo/*`,
          },
          ...computeItem,
          {
            label: 'Jobs',
            icon: IconTypes.JOBS,
            route: Routes.jobs,
          },
          {
            label: 'Accounts & Access',
            icon: IconTypes.ACCOUNTS_AND_ACCESS,
            route: Routes.accountAndAccess,
          },
        ];

    const generalItems = canShowMainApplication
      ? [
          ...computeItem,
          {
            label: 'Jobs',
            icon: IconTypes.JOBS,
            route: Routes.jobs,
          },
          {
            label: 'Monitoring',
            icon: IconTypes.MONITORING,
            route: Routes.monitoring,
          },
          {
            label: 'Accounts & Access',
            icon: IconTypes.ACCOUNTS_AND_ACCESS,
            route: Routes.accountAndAccess,
          },
        ]
      : [];

    return { workspaceItems, generalItems };
  }, [workspace]);

  const { workspaceItems, generalItems } = getSideNavigationItems();

  // ----------------------- callBack Handlers ----------------
  const onWorkspaceSelected = (selectedWorkspace: Workspace) => {
    const workspaceURLPath = UrlUtils.getRedirectWorkspaceUrl(workspace ?? '', selectedWorkspace?.name);
    navigate(workspaceURLPath);
  };

  const keyMap = {
    GLOBAL_SEARCH: 'command+k',
  };

  const handlers = {
    GLOBAL_SEARCH: (event: any) => {
      event.preventDefault();
      setShowSearchModal(true);
      logEvent('Search', '', { action: 'command+k', description: 'Show search modal' });
    },
  };

  const onLogoutClick = () => {
    logout(oktaAuth);
  };

  const leftSide = canShowGuidedOnboardingSideNavigation ? (
    <GuidedOnBoardingSideNavigation
      isPublicFreeTrial={isPublicFreeTrial}
      isCollapsed={isCollapsed}
      setIsCollapsed={setIsCollapsed}
      setShowSearchModal={setShowSearchModal}
      userName={user?.name ?? ''}
      email={user?.email ?? ''}
      onBellClick={() => {}}
      onLogoutClick={onLogoutClick}
    />
  ) : (
    <>
      <GlobalHotKeys keyMap={keyMap} handlers={handlers} />
      <TectonSideNavigation
        isPublicFreeTrial={isPublicFreeTrial}
        searchRef={SearchRef}
        isCollapsed={isCollapsed}
        setIsCollapsed={setIsCollapsed}
        setShowSearchModal={setShowSearchModal}
        showSearchModal={showSearchModal}
        setIsWorkspaceSelectorOpen={setIsWorkspaceSelectorOpen}
        isWorkspaceSelectorOpen={isWorkspaceSelectorOpen}
        workspaces={workspaces}
        onWorkspaceSelected={onWorkspaceSelected}
        canShowWorkspaceSelector={canShowMainApplication}
        currentWorkspace={
          workspaceDetails ?? {
            name: '',
            workspaceType: 'Development',
            isAccessible: false,
          }
        }
        userName={user?.name ?? ''}
        email={user?.email ?? ''}
        onBellClick={() => {}}
        workspaceItems={workspaceItems.map((item) => {
          return {
            ...item,
          };
        })}
        generalItems={generalItems.map((item) => {
          return {
            ...item,
          };
        })}
        onLogoutClick={onLogoutClick}
      />

      {showSearchModal && (
        <>
          <GlobalSearch
            SearchRef={SearchRef}
            setShowSearchModal={setShowSearchModal}
            workspace={foundWorkspace as any}
          />
        </>
      )}
    </>
  );

  return (
    <>
      <ApplicationLayout
        leftSideContent={leftSide}
        rightSideContent={
          <ErrorBoundary>
            <Outlet />
          </ErrorBoundary>
        }
      />
      {isPublicFreeTrial && canShowBanner && (
        <ExploreTopBanner
          onClick={() => {
            setCanShowBanner(false);
          }}
        />
      )}
    </>
  );
};

export default ApplicationContainer;
