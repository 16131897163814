import React, { FC, useState } from 'react';
import _sortBy from 'lodash/sortBy';
import { Link, useNavigate } from 'react-router-dom';

import { Routes } from '../../../core/routes';

// generated code
import { Group } from '../../../api/gql/graphql';

// @tecton
import {
  SearchAndActionButton,
  EmptyPrompt,
  Table,
  ButtonIcon,
  Tooltip,
  EuiBasicTableColumn,
  FlexGroupWrapper,
  FlexItem,
  ButtonEmpty,
} from '@tecton/ComponentRedesign';

// tecton icons
import GroupIcon from '@svg/group.svg';
import { ReactComponent as TrashIcon } from '@svg/trash.svg';

export interface ACLPrincipalGroupMembershipListProps {
  assignedGroups: Group[];
  isAdminView: boolean;
  principalName: string;
  onEditGroup: (group: Group) => void;
  onAssignGroupAccess: () => void;
  isLoading?: boolean;
}

// Errors and loading state should handler out side if this components.
const ACLPrincipalGroupMembershipList: FC<ACLPrincipalGroupMembershipListProps> = ({
  assignedGroups,
  principalName,
  isAdminView,
  onEditGroup,
  onAssignGroupAccess,
  isLoading,
}) => {
  const [searchText, setSearchText] = useState<string | undefined>(undefined);
  const navigate = useNavigate();

  const filteredWorkspaceRole = searchText
    ? assignedGroups?.filter((group: Group) => {
        return group.name?.toLowerCase()?.includes(searchText?.toLowerCase());
      })
    : assignedGroups;

  const sortedGroups = isLoading ? undefined : _sortBy(filteredWorkspaceRole, ['name']);

  const columns: EuiBasicTableColumn<Group>[] = [
    {
      name: 'Groups',
      render: (group: Group) => {
        return (
          <>
            <ButtonEmpty
              size="s"
              iconType={GroupIcon}
              onClick={() => {
                navigate(`${Routes.accountsAndAccessGroups}/${group.id}`);
              }}
            >
              {group.name}
            </ButtonEmpty>
          </>
        );
      },
    },

    {
      name: 'Actions',
      actions: [
        {
          name: 'Edit',
          isPrimary: true,
          render: (group: Group) => {
            return (
              <>
                <Tooltip
                  content={`Edit role for ${principalName} for this ${group.name} group.`}
                  trigger={
                    <>
                      <ButtonIcon
                        variant="dangerousAction"
                        iconType={TrashIcon}
                        color="ghost"
                        size="xs"
                        onClick={() => {
                          onEditGroup(group);
                        }}
                      />
                    </>
                  }
                />
              </>
            );
          },
        },
      ],
    },
  ];

  return (
    <FlexGroupWrapper gap="s" direction="column">
      <FlexItem>
        {' '}
        <SearchAndActionButton
          searchPlaceHolder={'Search for Group'}
          actionButtonLabel={'Assign Group Access'}
          actionButtonVariant={isAdminView ? 'primaryAction' : 'disabledAction'}
          onActionButtonClick={() => {
            onAssignGroupAccess();
          }}
          onSearchInputChange={function (search: string): void {
            setSearchText(search ?? undefined);
          }}
        />
      </FlexItem>
      <FlexItem>
        {/* The list will never be empty */}
        <Table
          items={sortedGroups ?? []}
          columns={columns}
          emptyPrompt={
            <EmptyPrompt
              title={<>No Results</>}
              body={
                <>
                  <p>The search filter returned no results.</p>
                </>
              }
            />
          }
        />
      </FlexItem>
    </FlexGroupWrapper>
  );
};

export default ACLPrincipalGroupMembershipList;
