import type { FC, ReactElement } from 'react';
import type { FcoTransitionType } from 'src/types/tecton_proto/data/state_update';
import { Badge } from '@tecton/ComponentRedesign/lib/v1';

type ActionBadgeProps = {
  type?: FcoTransitionType;
};

export const ActionBadge: FC<ActionBadgeProps> = ({ type }) => {
  if (!type) return <></>;

  const typeMapping: Record<FcoTransitionType, ReactElement> = {
    CREATE: <Badge size="s" color="blue" label="Create" icon="Plus" />,
    DELETE: <Badge size="s" color="red" label="Delete" icon="Trash" />,
    RECREATE: <Badge size="s" color="violet" label="Recreate" icon="Refresh" />,
    UNCHANGED: <Badge size="s" color="gray" label="Unchanged" icon="NotSet" />,
    UNKNOWN: <Badge size="s" color="gray" label="Unknown" icon="QuestionInCircle" />,
    UPDATE: <Badge size="s" color="purple" label="Update" icon="LargeChevronUp" />,
    UPGRADE: <Badge size="s" color="pink" label="Upgrade" icon="ArrowUp" />,
  };

  return typeMapping[type];
};
