import { FC, useContext, useState } from 'react';
import { Navigate, Route, Routes, useNavigate, useParams } from 'react-router';
import { Routes as AppRoutes } from '../../../core/routes';
import { getRolesGQL } from '../../acl/util';
import { useUserSettings } from '../../context/UserSettingsContext';
import { SimpleRequestStatus } from './acl-types';
import ACLGroupDetailsCard from './ACLGroupDetailsCard';
import ACLGroupOutlet from './ACLGroupOutlet';
import ACLGroupServiceAccountsList from './ACLGroupServiceAccountsList';
import ACLGroupUserList from './ACLGroupUserList';
import ACLGroupWorkspaceRoleList from './ACLGroupWorkspaceRoleList';
import AddWorkspaceToGroup from './AddWorkspaceToGroup';
import AssignServiceAccount from './AssignServiceAccount';
import AssignUser from './AssignUser';
import Delete from './Delete';
import ModifyGroupAccountType from './ModifyGroupAccountType';
import ModifyWorkspaceAccess from './ModifyWorkspaceAccess';

// CRUD hooks
import {
  useAddPrincipalGroupMembersMutation,
  useAssignRoleMutation,
  useAssignRolesMutation,
  useDeleteGroupMutation,
  useRemovePrincipalGroupMembersMutation,
  useUpdateAccountTypeMutation,
  useUpdatePrincipalGroupMutation,
} from '../../../api/acl';
import { useGetACLGroupsDetails } from '../../../api/queries/aclGraphql';

// utils
import { getLegacyRoleIdGQL, getRoleNameGQL } from '../../acl/aclUtils';
import { getACLGroupRoutes, getRoleDescriptionMap, getRoleOptions, getRoleToPermissionMap } from './acl-util';

// generated
import { AclGroupWorkspaceType, ServiceAccount, User } from '../../../api/gql/graphql';
import { PrincipalType } from '../../../types/tecton_proto/auth/principal';
import { ResourceType } from '../../../types/tecton_proto/auth/resource';

// @tecton
import {
  AsymmetricBodyLayout,
  EmptyPrompt,
  ErrorPromptButtons,
  Modal,
  getToastComponent,
} from '@tecton/ComponentRedesign';
import { ToastContext, addToast } from '@tecton/ToastContext';
import { logEvent } from '../../../utils/analytics-utils';

const ACLGroupDetailsContainer: FC = () => {
  const { group: groupID } = useParams();
  const { isAdmin } = useUserSettings();
  const navigate = useNavigate();
  const toastContext = useContext(ToastContext);

  // CRUD
  const { data, isLoading, isError } = useGetACLGroupsDetails(groupID ?? '');
  const assignRolesMutation = useAssignRolesMutation();
  const deleteGroupMutation = useDeleteGroupMutation();
  const addPrincipalGroupMembersMutation = useAddPrincipalGroupMembersMutation();
  const removePrincipalGroupMembersMutation = useRemovePrincipalGroupMembersMutation();
  const updatePrincipalGroupMutation = useUpdatePrincipalGroupMutation();
  const updateAccountTypeToRegularMutation = useUpdateAccountTypeMutation();
  const assignAdminRole = useAssignRoleMutation();

  const roleDescriptionMap = getRoleToPermissionMap(data?.roles ?? []);
  const groupRoles = getRolesGQL(data?.roles, PrincipalType.PRINCIPAL_TYPE_SERVICE_ACCOUNT);

  const tabCountRecord: Record<string, number> = {
    Workspaces:
      data?.assignedWorkspaces?.filter((assignedWorkspaces) => {
        if (assignedWorkspaces.workspaceId === 'All Workspaces') {
          // We don't count "All Workspaces" when it's not set.
          return !(assignedWorkspaces.role === 'none');
        }
        return true;
      })?.length ?? 0,
    Users: data?.assignedUsers?.length ?? 0,
    'Service Accounts': data?.assignedServiceAccounts?.length ?? 0,
  };

  const addWorkspaceRoles = getRoleOptions(
    data?.roles ?? [],
    groupRoles?.map((role) => {
      return role?.id ?? '';
    }) ?? [],
    false
  );
  const editableRolesOptions = getRoleOptions(
    data?.roles ?? [],
    groupRoles?.map((role) => {
      return role?.id ?? '';
    }) ?? [],
    true
  );

  // General State
  const [originalRole, setOriginalRole] = useState<string | undefined>();
  const [selectedWorkspaceRole, setSelectedWorkspaceRole] = useState<AclGroupWorkspaceType | undefined>(undefined);
  const [selectedAccountType, setSelectedAccountType] = useState<string | undefined>(undefined);

  // -- radio button selection from modal
  const [selectedRole, setSelectedRole] = useState<string | undefined>();
  const [selectedUser, setSelectedUser] = useState<User | undefined>();
  const [selectedUsers, setSelectedUsers] = useState<User[] | undefined>();
  const [selectedServiceAccount, setSelectedServiceAccount] = useState<ServiceAccount | undefined>();
  const [selectedServiceAccounts, setSelectedServiceAccounts] = useState<ServiceAccount[] | undefined>();
  const [workspaceSelected, setWorkspaceSelected] = useState<string | undefined>();
  const [addAttributeName, setAddAttributeName] = useState<string | undefined>();
  const [deleteAttributeName, setDeleteAttributeName] = useState<string | undefined>();

  // Modal States
  const [showEditWorkspaceRoleModal, setShowEditWorkspaceRoleModal] = useState<boolean>();
  const [showAssignWorkspaceAccessModal, setShowAssignWorkspaceAccessModal] = useState<boolean>();
  const [showDeleteGroupAccessModal, setShowDeleteGroupAccessModal] = useState<boolean>();
  const [showDeleteUserModal, setShowDeleteUserModal] = useState<boolean>(false);
  const [showAddUserModal, setShowAddUserModal] = useState<boolean>();
  const [showAddServiceAccountModal, setShowAddServiceAccountModal] = useState<boolean>();
  const [showDeleteServiceAccountModal, setShowDeleteServiceAccountModal] = useState<boolean>(false);
  const [showDeleteAttributeModal, setShowDeleteAttributeModal] = useState<boolean>(false);
  const [showEditAccountTypeModal, setShowEditAccountTypeModal] = useState<boolean>(false);

  if (isError) {
    const emptyPrompt = (
      <EmptyPrompt
        orientation="vertical"
        variant="storm"
        title={<>Error Loading Group</>}
        body={
          <>
            <p>We ran into an error while loading this group.</p>
          </>
        }
        actions={<ErrorPromptButtons />}
      />
    );
    return emptyPrompt;
  }

  const reset = () => {
    setShowEditWorkspaceRoleModal(false);
    setShowAssignWorkspaceAccessModal(false);
    setShowDeleteGroupAccessModal(false);
    setShowDeleteUserModal(false);
    setShowAddUserModal(false);
    setShowAddServiceAccountModal(false);
    setShowDeleteServiceAccountModal(false);
    setShowDeleteAttributeModal(false);
    setShowEditAccountTypeModal(false);
    setOriginalRole(undefined);
    setSelectedWorkspaceRole(undefined);
    setSelectedAccountType(undefined);
    setSelectedRole(undefined);
    setSelectedUser(undefined);
    setSelectedUsers(undefined);
    setSelectedServiceAccount(undefined);
    setSelectedServiceAccounts(undefined);
    setWorkspaceSelected(undefined);
    setAddAttributeName(undefined);
    setDeleteAttributeName(undefined);
  };

  // --------------------------------------- Start: Actions hooks ---------------------------------

  const onEditRole = (workspaceRole: AclGroupWorkspaceType) => {
    const selectedRole = getRoleNameGQL(data?.roles ?? [], workspaceRole?.role ?? '');
    setShowEditWorkspaceRoleModal(true);
    setSelectedWorkspaceRole(workspaceRole);
    setOriginalRole(selectedRole ?? '');
    setSelectedRole(selectedRole ?? '');
  };

  const onAssignWorkspaceAccess = () => {
    setShowAssignWorkspaceAccessModal(true);
  };

  const onRoleChange = (roleName: string) => {
    setSelectedRole(roleName);
  };

  const onWorkspaceSelected = (workspace: string) => {
    setWorkspaceSelected(workspace);
  };

  const onAddGroupToWorkspace = () => {
    const principal_type = PrincipalType.PRINCIPAL_TYPE_GROUP;
    const role = getLegacyRoleIdGQL(data?.roles ?? [], selectedRole ?? '');
    const roles = [role ?? ''];
    const resource_id = workspaceSelected;

    const resource_type = ResourceType.RESOURCE_TYPE_WORKSPACE;

    const payload = {
      principal_id: data?.group?.id ?? '',
      roles,
      resource_id,
      principal_type,
      resource_type,
    };

    assignRolesMutation.mutate(payload, {
      onSuccess: () => {
        reset();
        logEvent('Account & Access: Workspace Added to Group', '', {
          workspace: resource_id,
          group_name: data?.group?.name,
          role: selectedRole,
        });
        const toastContent = getToastComponent({
          variant: 'success',
          title: `Workspace Added to Group`,
          children: (
            <>
              Workspace {resource_id} added to group {data?.group?.name} with {selectedRole} role.
            </>
          ),
          showIcon: true,
        });

        toastContext?.dispatchToast(
          addToast({
            text: toastContent,
          })
        );
      },
      onError: () => {
        const toastContent = getToastComponent({
          variant: 'danger',
          title: `Failed to Add Workspace to Group`,
          children: (
            <>
              Failed to add workspace {resource_id} to group {selectedRole}. Please try again.
            </>
          ),
          showIcon: true,
        });

        toastContext?.dispatchToast(
          addToast({
            text: toastContent,
          })
        );
      },
    });
  };

  const onDeleteGroupConfirmClicked = () => {
    const principal_id = data?.group?.id ?? '';

    deleteGroupMutation.mutate(
      { ids: [principal_id] },
      {
        onSuccess: () => {
          reset();
          logEvent('Account & Access: Group Deleted', '', {
            group_id: data?.group?.id,
            group_name: data?.group?.name,
          });
          const toastContent = getToastComponent({
            variant: 'success',
            title: `Group Deleted`,
            children: <>Group {data?.group?.name} deleted.</>,
            showIcon: true,
          });

          toastContext?.dispatchToast(
            addToast({
              text: toastContent,
            })
          );
          navigate(`${AppRoutes.accountsAndAccessGroups}`);
        },
        onError: () => {
          const toastContent = getToastComponent({
            variant: 'danger',
            title: `Failed to Delete Group`,
            children: <>Failed to delete Group {data?.group?.name}. Please try again.</>,
            showIcon: true,
          });

          toastContext?.dispatchToast(
            addToast({
              text: toastContent,
            })
          );
        },
      }
    );
  };

  const onDeleteUserConfirmClicked = () => {
    const payload = {
      id: `${groupID}`,
      members: [{ principal_type: PrincipalType.PRINCIPAL_TYPE_USER, principal_id: `${selectedUser?.oktaId}` }],
    };

    removePrincipalGroupMembersMutation.mutate(payload, {
      onSuccess: () => {
        reset();
        logEvent('Account & Access: User Removed From Group', '', {
          email: selectedUser?.loginEmail,
          group_name: data?.group?.name,
          group_id: groupID,
        });
        const toastContent = getToastComponent({
          variant: 'success',
          title: `User Removed From Group`,
          children: <>User {selectedUser?.loginEmail} removed from group.</>,
          showIcon: true,
        });

        toastContext?.dispatchToast(
          addToast({
            text: toastContent,
          })
        );
      },

      onError: () => {
        const toastContent = getToastComponent({
          variant: 'danger',
          title: `Failed Remove User From Group`,
          children: <>Failed to remove {selectedUser?.loginEmail} from group. Please try again.</>,
          showIcon: true,
        });

        toastContext?.dispatchToast(
          addToast({
            text: toastContent,
          })
        );
      },
    });
  };

  const onModifyWorkspaceRole = () => {
    const legacyId = getLegacyRoleIdGQL(data?.roles ?? [], selectedRole ?? '');

    const removingRole = legacyId === 'none';
    const principal_type = PrincipalType.PRINCIPAL_TYPE_GROUP;

    const roles = removingRole ? [] : [legacyId ?? ''];
    const resource_id = selectedWorkspaceRole?.workspaceId;
    const isAllWorkspaces = resource_id === 'All Workspaces';

    const resource_type = isAllWorkspaces
      ? ResourceType.RESOURCE_TYPE_ORGANIZATION
      : ResourceType.RESOURCE_TYPE_WORKSPACE;

    if (isAllWorkspaces && legacyId === 'Admin') {
      roles.unshift('admin_role');
    }

    const payload = {
      principal_id: data?.group?.id ?? '',
      roles,
      ...(!isAllWorkspaces && { resource_id }),
      principal_type,
      resource_type,
    };

    assignRolesMutation.mutate(payload, {
      onSuccess: () => {
        reset();
        logEvent(`Account & Access: ${removingRole ? `Removed Workspace From Group` : `Workspace Role Modified`}`, '', {
          workspace: resource_id,
          group_name: data?.group?.name,
        });
        const toastContent = getToastComponent({
          variant: 'success',
          title: removingRole ? `Removed Workspace From Group` : `Workspace Role Modified`,
          children: removingRole ? (
            <>
              Workspace {resource_id} removed from group {data?.group?.name}.
            </>
          ) : (
            <>
              Workspace {resource_id} role modified to {selectedRole}
            </>
          ),
          showIcon: true,
        });

        toastContext?.dispatchToast(
          addToast({
            text: toastContent,
          })
        );
      },
      onError: () => {
        const toastContent = getToastComponent({
          variant: 'danger',
          title: removingRole ? `Failed to Removed Workspace From Group` : `Failed to Update Workspace Role`,
          children: removingRole ? (
            <>Failed to remove workspace {resource_id} from group.</>
          ) : (
            <>Failed to update Workspace Role. Please try again.</>
          ),
          showIcon: true,
        });

        toastContext?.dispatchToast(
          addToast({
            text: toastContent,
          })
        );
      },
    });
  };

  const onDeleteUser = (user: User) => {
    setSelectedUser(user);
    setShowDeleteUserModal(true);
  };

  const onAddUser = () => {
    setShowAddUserModal(true);
  };

  const onAddServiceAccount = () => {
    setShowAddServiceAccountModal(true);
  };

  const onAssignUserChange = (users?: User[]) => {
    setSelectedUsers(users);
  };

  const onAssignServiceAccountChange = (serviceAccounts: ServiceAccount[]) => {
    setSelectedServiceAccounts(serviceAccounts);
  };

  const onAssignUserConfirm = () => {
    const userMembersToAdd =
      selectedUsers?.map((item) => {
        return { principal_type: PrincipalType.PRINCIPAL_TYPE_USER, principal_id: `${item?.oktaId}` };
      }) ?? [];
    const payload = {
      principal_group_id: `${groupID}`,
      members: userMembersToAdd,
    };
    addPrincipalGroupMembersMutation.mutate(payload, {
      onSuccess: () => {
        reset();
        logEvent(`Account & Access: Users Added To Group`, '', {
          user_ids: userMembersToAdd.map((user) => user.principal_id)?.join(', '),
          group_id: groupID,
          group_name: data?.group?.name,
        });
        const toastContent = getToastComponent({
          variant: 'success',
          title: `Users Added`,
          children: <>Users added to group</>,
          showIcon: true,
        });

        toastContext?.dispatchToast(
          addToast({
            text: toastContent,
          })
        );
      },
      onError: () => {
        const toastContent = getToastComponent({
          variant: 'danger',
          title: `Failed to add Users `,
          children: <>Users added to group</>,
          showIcon: true,
        });

        toastContext?.dispatchToast(
          addToast({
            text: toastContent,
          })
        );
      },
      onSettled: () => {
        setSelectedUsers(undefined);
        setShowAddUserModal(false);
      },
    });
  };

  const onDeleteServiceAccount = (serviceAccount: ServiceAccount) => {
    setSelectedServiceAccount(serviceAccount);
    setShowDeleteServiceAccountModal(true);
  };

  const onAssignServiceAccountConfirm = () => {
    const serviceAccountMembersToAdd =
      selectedServiceAccounts?.map((serviceAccount) => {
        return {
          principal_type: PrincipalType.PRINCIPAL_TYPE_SERVICE_ACCOUNT,
          principal_id: `${serviceAccount?.id}`,
        };
      }) ?? [];
    const payload = {
      principal_group_id: `${groupID}`,
      members: serviceAccountMembersToAdd,
    };
    addPrincipalGroupMembersMutation.mutate(payload, {
      onSuccess: () => {
        reset();
        logEvent(`Account & Access: Service Accounts Added to groups`, '', {
          service_account_ids: serviceAccountMembersToAdd.map((service) => service.principal_id)?.join(', '),
          group_id: groupID,
          group_name: data?.group?.name,
        });
        const toastContent = getToastComponent({
          variant: 'success',
          title: `Service Accounts Added to Group`,
          children: <>Service Accounts Added to groups</>,
          showIcon: true,
        });

        toastContext?.dispatchToast(
          addToast({
            text: toastContent,
          })
        );
      },
      onError: () => {
        const toastContent = getToastComponent({
          variant: 'danger',
          title: `Failed to Add Service Accounts`,
          children: <>Failed to Add Service Accounts to group. Please try again.</>,
          showIcon: true,
        });

        toastContext?.dispatchToast(
          addToast({
            text: toastContent,
          })
        );
      },
      onSettled: () => {
        setShowAddServiceAccountModal(undefined);
        setShowAddServiceAccountModal(false);
      },
    });
  };

  const onDeleteServiceAccountConfirmClicked = () => {
    const payload = {
      id: `${groupID}`,
      members: [
        { principal_type: PrincipalType.PRINCIPAL_TYPE_SERVICE_ACCOUNT, principal_id: `${selectedServiceAccount?.id}` },
      ],
    };

    removePrincipalGroupMembersMutation.mutate(payload, {
      onSuccess: () => {
        reset();
        logEvent(`Account & Access: Service Account Removed from Group`, '', {
          service_account_name: selectedServiceAccount?.name,
          service_account_id: selectedServiceAccount?.id,
          group_id: groupID,
          group_name: data?.group?.name,
        });
        const toastContent = getToastComponent({
          variant: 'success',
          title: `Service Account Removed from Group`,
          children: (
            <>
              {selectedServiceAccount?.name} removed from group {data?.group.name}.
            </>
          ),
          showIcon: true,
        });

        toastContext?.dispatchToast(
          addToast({
            text: toastContent,
          })
        );
      },

      onError: () => {
        const toastContent = getToastComponent({
          variant: 'danger',
          title: `Failed to Removed Service Account from Group`,
          children: <>Failed to remove {selectedServiceAccount?.name} from group.</>,
          showIcon: true,
        });

        toastContext?.dispatchToast(
          addToast({
            text: toastContent,
          })
        );
      },
    });
  };

  const onChangeAddAttributeName = (e: any) => {
    setAddAttributeName(e?.target?.value);
  };

  const onDeleteAttributeConfirm = () => {
    const originalAttributes = (data?.group?.idpMappingNames as any[]) ?? [];
    const index = originalAttributes.indexOf(deleteAttributeName);
    originalAttributes.splice(index, index + 1);

    const updateObject = {
      name: data?.group?.name,
      id: data?.group?.id,
      description: data?.group?.description ?? '',
      idp_mapping_names: [...originalAttributes],
    };
    updatePrincipalGroupMutation.mutate(updateObject, {
      onSuccess: () => {
        reset();
        logEvent(`Account & Access: Group IDP deleted`, '', {
          idp: deleteAttributeName,
          group_id: groupID,
          group_name: data?.group?.name,
        });
        const toastContent = getToastComponent({
          variant: 'success',
          title: `IDP deleted`,
          children: <>{deleteAttributeName} IDP Attribute deleted.</>,
          showIcon: true,
        });

        toastContext?.dispatchToast(
          addToast({
            text: toastContent,
          })
        );
      },
      onError: () => {
        const toastContent = getToastComponent({
          variant: 'danger',
          title: `Failed to Deleted IDP`,
          children: <>Failed to delete {deleteAttributeName} IDP Attribute.</>,
          showIcon: true,
        });

        toastContext?.dispatchToast(
          addToast({
            text: toastContent,
          })
        );
      },
    });
  };

  const onDeleteAttribute = (attributeName: string) => {
    setDeleteAttributeName(attributeName);
    setShowDeleteAttributeModal(true);
  };

  const onAddIDPAttributeClick = (successCallBack: VoidFunction) => {
    const originalAttributes = (data?.group?.idpMappingNames as any[]) ?? [];

    const updateObject = {
      name: data?.group?.name,
      id: data?.group?.id,
      description: data?.group?.description ?? '',
      idp_mapping_names: [...originalAttributes, addAttributeName],
    };
    updatePrincipalGroupMutation.mutate(updateObject, {
      onSuccess: () => {
        reset();
        logEvent(`Account & Access: Group IDP Added`, '', {
          idp: addAttributeName,
          group_id: groupID,
          group_name: data?.group?.name,
        });
        successCallBack();
        const toastContent = getToastComponent({
          variant: 'success',
          title: `IDP Attribute Added`,
          children: <>{addAttributeName} IDP Attribute added.</>,
          showIcon: true,
        });

        toastContext?.dispatchToast(
          addToast({
            text: toastContent,
          })
        );
      },
      onError: () => {
        const toastContent = getToastComponent({
          variant: 'success',
          title: `Failed to Add IDP Attribute`,
          children: <>Failed to add {addAttributeName} IDP Attribute.</>,
          showIcon: true,
        });

        toastContext?.dispatchToast(
          addToast({
            text: toastContent,
          })
        );
      },
    });
  };

  const onFieldSaveConfirm = (
    fieldName: string,
    newValue: string,
    updateCallBack: (status: SimpleRequestStatus) => void
  ) => {
    if (data?.group) {
      const groupCopy: any = { ...data?.group };
      groupCopy[`${fieldName}`] = newValue;

      const updateObject = {
        ...groupCopy,
      };

      updatePrincipalGroupMutation.mutate(updateObject, {
        onSuccess: (e) => {
          updateCallBack('success');
          reset();
          logEvent(`Account & Access: Group Name Updated`, '', {
            group_id: groupID,
            new_name: newValue,
            old_name: data?.group?.name,
          });
          const toastContent = getToastComponent({
            variant: 'success',
            title: `Group Updated`,
            children: (
              <>
                {fieldName} group updated with {newValue}.
              </>
            ),
            showIcon: true,
          });

          toastContext?.dispatchToast(
            addToast({
              text: toastContent,
            })
          );
        },
        onError: () => {
          updateCallBack('failed');
          const toastContent = getToastComponent({
            variant: 'danger',
            title: `Failed to Updated Group`,
            children: <>Failed to update {fieldName} from group.</>,
            showIcon: true,
          });

          toastContext?.dispatchToast(
            addToast({
              text: toastContent,
            })
          );
        },
      });
    }
  };

  const onEditAccountType = () => {
    setShowEditAccountTypeModal(true);
  };

  const onConfirmAccountTypeChange = () => {
    const principal_id = data?.group?.id ?? '';
    const payload = {
      assignments: [
        {
          principal_id,
          role: 'admin_role',
          principal_type: PrincipalType.PRINCIPAL_TYPE_GROUP,
          resource_type: ResourceType.RESOURCE_TYPE_ORGANIZATION,
        },
      ],
    };

    if (data?.group?.accountType === 'Regular') {
      // We set the account type to 'Admin'
      assignAdminRole.mutate(payload, {
        onSuccess: () => {
          reset();
          logEvent(`Account & Access: Group Account type Updated to Admin`, '', {
            group_id: groupID,
            group_name: data?.group?.name,
          });
          const toastContent = getToastComponent({
            variant: 'success',
            title: `Account Type Updated`,
            children: <>Account type Updated to Admin</>,
            showIcon: true,
          });

          toastContext?.dispatchToast(
            addToast({
              text: toastContent,
            })
          );
        },
        onError: () => {
          const toastContent = getToastComponent({
            variant: 'danger',
            title: `Failed Account Type`,
            children: <>Failed to Update Account Type. Please try again.</>,
            showIcon: true,
          });

          toastContext?.dispatchToast(
            addToast({
              text: toastContent,
            })
          );
        },
      });
    } else {
      updateAccountTypeToRegularMutation.mutate(payload, {
        onSuccess: () => {
          reset();
          logEvent(`Account & Access: Group Account Type Updated to Regular`, '', {
            group_id: groupID,
            group_name: data?.group?.name,
          });
          const toastContent = getToastComponent({
            variant: 'success',
            title: `Group Account Type Updated`,
            children: <>Account Type Updated to Admin</>,
            showIcon: true,
          });

          toastContext?.dispatchToast(
            addToast({
              text: toastContent,
            })
          );
        },
        onError: () => {
          const toastContent = getToastComponent({
            variant: 'danger',
            title: `Failed Account Type`,
            children: <>Failed to Update Account Type. Please try again.</>,
            showIcon: true,
          });

          toastContext?.dispatchToast(
            addToast({
              text: toastContent,
            })
          );
        },
      });
    }
  };

  // --------------------------------------- End: Actions hooks ---------------------------------

  const aclGroupsRoutes = getACLGroupRoutes();

  const tabBodySelected = (
    <>
      <Routes>
        <Route
          element={
            <ACLGroupOutlet path={`${AppRoutes.accountsAndAccessGroups}/:group`} tabCountRecord={tabCountRecord} />
          }
        >
          <Route index element={<Navigate to={aclGroupsRoutes[`Workspaces`]} replace />} />
          <Route
            path={aclGroupsRoutes[`Workspaces`]}
            element={
              <>
                {
                  <ACLGroupWorkspaceRoleList
                    assignedWorkspaceRoles={data?.assignedWorkspaces ?? []}
                    isAdminView={isAdmin ?? false}
                    onEditRole={onEditRole}
                    onAssignWorkspaceAccess={onAssignWorkspaceAccess}
                    roles={data?.roles ?? []}
                  />
                }
              </>
            }
          />
          <Route
            path={aclGroupsRoutes[`Users`]}
            element={
              <>
                <ACLGroupUserList
                  assignedUsers={data?.assignedUsers ?? []}
                  isAdminView={isAdmin ?? false}
                  onDeleteUser={(user: User) => {
                    onDeleteUser(user);
                  }}
                  onAddUser={onAddUser}
                />
              </>
            }
          />
          <Route
            path={aclGroupsRoutes[`Service Accounts`]}
            element={
              <>
                <ACLGroupServiceAccountsList
                  assignedServiceAccounts={data?.assignedServiceAccounts ?? []}
                  isAdminView={isAdmin ?? false}
                  onDeleteServiceAccount={(serviceAccount: ServiceAccount) => {
                    onDeleteServiceAccount(serviceAccount);
                  }}
                  onAddonAddServiceAccount={onAddServiceAccount}
                />
              </>
            }
          />
        </Route>
      </Routes>
    </>
  );

  const leftSideServiceAccountDetailsCard = (
    <>
      {showEditWorkspaceRoleModal && (
        <Modal
          title={`Modify role of ${data?.group?.name} in ${selectedWorkspaceRole?.workspaceId} `}
          body={
            <>
              <ModifyWorkspaceAccess
                roles={editableRolesOptions}
                workspaceRole={getRoleNameGQL(data?.roles ?? [], selectedWorkspaceRole?.role ?? '')}
                roleDescriptionMap={roleDescriptionMap}
                onRoleChange={onRoleChange}
              />
            </>
          }
          onCancel={() => {
            setShowEditWorkspaceRoleModal(false);
            setOriginalRole(undefined);
            setSelectedWorkspaceRole(undefined);
            setWorkspaceSelected(undefined);
          }}
          confirmLabel="Change Access"
          onConfirm={() => {
            onModifyWorkspaceRole();
          }}
          variant="positive"
          confirmVariant={originalRole !== selectedRole ? 'primaryAction' : 'disabledAction'}
          maxWidth={1000}
          isConfirmButtonLoading={assignRolesMutation.isLoading}
        />
      )}

      {showDeleteGroupAccessModal && (
        <Modal
          title={`Delete ${data?.group?.name}`}
          body={<Delete message={`Are you sure you want to delete this group?`} />}
          onCancel={() => {
            setShowDeleteGroupAccessModal(false);
          }}
          confirmLabel="Delete"
          onConfirm={onDeleteGroupConfirmClicked}
          variant="negative"
        />
      )}

      {showDeleteUserModal && (
        <>
          <Modal
            title={`Delete ${selectedUser?.loginEmail}`}
            body={<Delete message={`Are you sure you want to delete this User?`} />}
            onCancel={() => {
              setShowDeleteUserModal(false);
            }}
            confirmLabel="Delete"
            onConfirm={onDeleteUserConfirmClicked}
            variant="negative"
          />
        </>
      )}

      {showAssignWorkspaceAccessModal && (
        <Modal
          title={`Add Workspace to this Group.`}
          body={
            <>
              <AddWorkspaceToGroup
                roles={addWorkspaceRoles}
                assignableWorkspaces={
                  data?.assignableWorkspaces?.map((workspace) => {
                    return { label: workspace };
                  }) ?? []
                }
                workspaceRole={selectedWorkspaceRole}
                roleDescriptionMap={roleDescriptionMap}
                onRoleChange={onRoleChange}
                onWorkspaceSelected={onWorkspaceSelected}
              />
            </>
          }
          onCancel={() => {
            setShowAssignWorkspaceAccessModal(false);
            setSelectedWorkspaceRole(undefined);
            setSelectedRole(undefined);
            setWorkspaceSelected(undefined);
          }}
          confirmLabel="Add Workspace"
          onConfirm={() => {
            onAddGroupToWorkspace();
          }}
          variant="positive"
          confirmVariant={selectedRole !== undefined && workspaceSelected ? 'primaryAction' : 'disabledAction'}
          isConfirmButtonLoading={assignRolesMutation.isLoading}
          maxWidth={1000}
        />
      )}

      {showAddUserModal && (
        <Modal
          title={`Add User to this Group`}
          body={
            <>
              <AssignUser assignableUser={data?.assignableUsers ?? []} onAssignUserConfirm={onAssignUserChange} />
            </>
          }
          onCancel={() => {
            setShowAddUserModal(false);
            setSelectedUsers(undefined);
          }}
          confirmLabel={selectedUsers && selectedUsers?.length > 1 ? 'Add Users' : 'Add User'}
          onConfirm={onAssignUserConfirm}
          variant="positive"
          confirmVariant={selectedUsers && selectedUsers?.length > 0 ? 'primaryAction' : 'disabledAction'}
          isConfirmButtonLoading={addPrincipalGroupMembersMutation.isLoading}
        />
      )}

      {showAddServiceAccountModal && (
        <Modal
          title={`Add Service Account to this Group`}
          body={
            <>
              <AssignServiceAccount
                assignableServiceAccounts={data?.assignableServiceAccounts ?? []}
                onAssignServiceAccountChange={onAssignServiceAccountChange}
              />
            </>
          }
          onCancel={() => {
            setShowAddServiceAccountModal(false);
            setSelectedServiceAccounts(undefined);
          }}
          confirmLabel={
            selectedServiceAccounts && selectedServiceAccounts?.length > 1
              ? 'Add Service Accounts'
              : 'Add Service Account'
          }
          onConfirm={onAssignServiceAccountConfirm}
          variant="positive"
          confirmVariant={
            selectedServiceAccounts && selectedServiceAccounts?.length > 0 ? 'primaryAction' : 'disabledAction'
          }
          isConfirmButtonLoading={addPrincipalGroupMembersMutation.isLoading}
          maxWidth={1000}
        />
      )}

      {showDeleteServiceAccountModal && (
        <>
          <Modal
            title={`Delete ${selectedServiceAccount?.name}`}
            body={<Delete message={`Are you sure you want to delete this Service Account?`} />}
            onCancel={() => {
              setShowDeleteServiceAccountModal(false);
            }}
            confirmLabel="Delete"
            onConfirm={onDeleteServiceAccountConfirmClicked}
            variant="negative"
            isConfirmButtonLoading={removePrincipalGroupMembersMutation.isLoading}
          />
        </>
      )}

      {showDeleteAttributeModal && (
        <>
          <Modal
            title={`Delete Identity Provider Attribute?`}
            body={
              <Delete
                message={`Are you sure you want to delete ${deleteAttributeName} for the group '${data?.group?.name}'? Users with this attribute will no longer be automatically add to this group.`}
              />
            }
            onCancel={() => {
              setShowDeleteAttributeModal(false);
            }}
            confirmLabel="Delete"
            onConfirm={onDeleteAttributeConfirm}
            variant="negative"
            isConfirmButtonLoading={updatePrincipalGroupMutation.isLoading}
          />
        </>
      )}

      {showEditAccountTypeModal && (
        <Modal
          title={`Modify Account Type of ${data?.group?.name}`}
          body={
            <ModifyGroupAccountType
              group={data?.group}
              selectedAccountType={data?.group?.accountType}
              roleDescriptionMap={getRoleDescriptionMap()}
              onSelectionClicked={(selectedAccountType: string) => {
                setSelectedAccountType(selectedAccountType);
              }}
            />
          }
          onCancel={() => {
            setShowEditAccountTypeModal(false);
          }}
          confirmLabel="Change assigned Account Type to Admin"
          onConfirm={onConfirmAccountTypeChange}
          variant="positive"
          confirmVariant={data?.group?.accountType === selectedAccountType ? 'disabledAction' : 'primaryAction'}
          isConfirmButtonLoading={assignAdminRole.isLoading || updateAccountTypeToRegularMutation?.isLoading}
          maxWidth={1000}
        />
      )}

      <ACLGroupDetailsCard
        group={data?.group}
        isAdminView={isAdmin ?? false}
        onDeleteGroupClicked={() => {
          setShowDeleteGroupAccessModal(true);
        }}
        attributeValue={addAttributeName}
        onChangeAddAttributeName={onChangeAddAttributeName}
        onAddIDPAttributeClick={onAddIDPAttributeClick}
        isSavingAttributeName={addAttributeName !== undefined && updatePrincipalGroupMutation.isLoading}
        onDeleteAttribute={onDeleteAttribute}
        onFieldSaveConfirm={onFieldSaveConfirm}
        onEditAccountType={onEditAccountType}
      />
    </>
  );

  const body = isLoading ? (
    <EmptyPrompt variant="loading" title={<>Loading Group</>} body={<></>} orientation="vertical" />
  ) : (
    <AsymmetricBodyLayout leftSide={leftSideServiceAccountDetailsCard} rightSide={tabBodySelected} />
  );

  return <>{body}</>;
};

export default ACLGroupDetailsContainer;
