import { format } from 'd3-format';
import { FC } from 'react';

import { MonitoringDateRange } from '../../monitoring/DateRange-Dropdown-util';

import { FCOFields, FeatureViewFCO, FeatureViewFCOFields, FeatureViewFCOType } from '../../../core/types/fcoTypes';
import { interval } from '../../../feature/feature-services/feature-service/component/ChartDefaultParams';

import { X_AXIS_LABEL } from '../../monitoring/GraphContainer';

// @tecton
import { EuiSelectableOption } from '@tecton/ComponentRedesign';
import PrometheusMonitoring from '@tecton/ComponentRedesign/Charts/PrometheusMonitoring';
import { PipelineNodeTypes } from '../../../service/Pipeline';
import { getMetricLabel } from './OnlineStoreLatency';

interface FeatureViewsBelowFreshnessThresholdProps {
  monitoringDateRange: MonitoringDateRange;
  selectedOption: EuiSelectableOption<{ option?: FeatureViewFCO }>;
  selectedGraphType: EuiSelectableOption<{ option?: string }>;
  relatedFvs: FeatureViewFCO[];
}

export const getTableQuery = (featureViewIds: string[], relatedFvs: FeatureViewFCO[]): string => {
  // exclude feature view type lists
  const fvTypesToExclude = [FeatureViewFCOType.REALTIME]; // Exclude Realtime FVs since we don't log on it. We only log on the underlying FVs
  const realtimeFVDependantFVIds: string[] = []; //

  const filteredFVIds = featureViewIds?.filter((fvId) => {
    const foundFV = relatedFvs.find((fv) => {
      return fv[FCOFields.ID] === fvId;
    });
    if (foundFV) {
      const canInclude = !fvTypesToExclude.includes(foundFV[FeatureViewFCOFields.FEATURE_VIEW_TYPE]);

      if (!canInclude) {
        // It's a realtime FV which mean we need to get the underlying FVs in the nodes.
        foundFV[FeatureViewFCOFields.PIPELINE]?.allLeafNodes
          ?.filter((node) => {
            return node?.nodeType === PipelineNodeTypes.FEATURE_VIEW;
          })
          ?.forEach((node) => {
            realtimeFVDependantFVIds.push(node?.innerNodeId ?? '');
          });
      }

      // If you are part of the exclusion list, filter it out.
      return canInclude;
    }
    // we can't find the fv so don't show it
    return false;
  });

  return [...filteredFVIds, ...realtimeFVDependantFVIds]
    ?.map((fvId: string, index: number) => {
      // Find the feature view from related features views so we can find if compaction is enabled
      const foundFV = relatedFvs.find((fv) => {
        return fv[FCOFields.ID] === fvId;
      });
      const isFVCompactionEnabled = foundFV ? foundFV[FeatureViewFCOFields.BATCH_COMPACTION_ENABLED] : false;
      // When FV is compaction, we add a .* to the end of the FV Id.
      return `.*_${fvId}${isFVCompactionEnabled ? '.*' : ''}${featureViewIds.length - 1 === index ? '' : '|'}`;
    })
    .join('');
};

const DynamoDBP99Latency: FC<FeatureViewsBelowFreshnessThresholdProps> = ({
  monitoringDateRange,
  selectedOption,
  selectedGraphType,
  relatedFvs,
}) => {
  const chartTitle = '';
  const yAxisLabel = 'Latency';
  const featureViewIds: string[] = selectedOption?.data?.featureViewIds ?? [];

  const tableQuery = getTableQuery(featureViewIds, relatedFvs);
  const queries = [
    {
      label: `P${selectedGraphType?.data?.value}`,
      query: `histogram_quantile(0.${selectedGraphType?.data?.value}, sum(rate(dynamodb_query_latency_seconds_bucket_rollup_no_pod{ aws_region=~'.*', tecton_cluster=~'.*' , table=~'${tableQuery}'}[${interval}])) by(le, table))`,
    },
  ];

  const yTicksCallback = (value: any) => {
    const formatter = format('.2~f'); // 20ms instead of .02 s
    return `${formatter(value * 1000)} ms`;
  };

  const toolTipLabelCallback = (context: any) => {
    const { y } = context.parsed;
    const formatter = format('.2~f');

    const { label } = context.dataset;

    const labelText = `${label}: ${formatter(y * 1000)} ms`;
    return labelText;
  };

  const getMetricLabelCallback = (result: any, label?: string) => {
    return getMetricLabel(result, relatedFvs, label, selectedOption?.data?.isAllFeatures);
  };

  const yOverride = {
    title: {
      display: true,
      text: yAxisLabel,
      font: {
        size: 12,
      },
    },
    min: 0,

    ticks: {
      callback: yTicksCallback,
    },
  };

  return (
    <>
      <PrometheusMonitoring
        chartTitle={chartTitle}
        monitoringDateRange={monitoringDateRange}
        queries={queries}
        xAxisLabel={X_AXIS_LABEL}
        yAxisLabel={yAxisLabel}
        yTicksCallback={yTicksCallback}
        yGrace={0}
        toolTipLabelCallback={toolTipLabelCallback}
        yOverride={yOverride}
        parseValue={parseFloat}
        getMetricLabelCallback={selectedOption?.data?.isAllFeatures ? getMetricLabelCallback : undefined}
        height={160}
      />
    </>
  );
};

export default DynamoDBP99Latency;
