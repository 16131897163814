import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { range } from 'd3-array';
import React, { FC } from 'react';

type SkeletonType = 'text' | 'title' | 'circle' | 'rectangle';
type SkeletonSize = 'xs' | 's' | 'm' | 'l' | 'xl';

interface SkeletonProps {
  type?: SkeletonType;
  size?: SkeletonSize;
  lines?: number;
}

const animateLine = keyframes`
    from {
    background-position: 0% 50%;
  }
  to {
    background-position: -200% 50%;
  }
`;

const SingleLine = styled.div<{
  delay: number;
  size: SkeletonSize;
}>(({ theme, size, delay }) => {
  const sizeHeightMap: Record<SkeletonSize, string> = {
    xs: `${theme.v1.size['2']}px`,
    s: `${theme.v1.size['3']}px`,
    m: `${theme.v1.size['4']}px`,
    l: `${theme.v1.size['5']}px`,
    xl: `${theme.v1.size['6']}px`,
  };

  return {
    background: `linear-gradient(94deg, ${theme.v1.colors.border.default} 0%, ${theme.v1.colors.background.empty} 25%, ${theme.v1.colors.border.default} 50%)`,
    'background-size': '200% 100%',
    'background-position': '0% 50%',
    width: '100%', // Handle not last line
    height: sizeHeightMap[size],
    'border-radius': `${theme.v1.size['1']}px`,
    'margin-bottom': `${theme.v1.size['1.5']}px`,
    animation: `${animateLine} ${theme.v1.motion.duration.xxxlong} ${theme.v1.motion.easing.linear} ${
      0.25 * delay
    }s infinite`,
  };
});

const animateCircle = keyframes`
    from {
    background-position: 0% 50%;
  }
  to {
    background-position: -200% 50%;
  }
`;

const SkeletonCircle = styled.div<{
  size: SkeletonSize;
}>(({ theme, size }) => {
  const sizeHeightMap: Record<SkeletonSize, string> = {
    xs: `${theme.v1.size['4']}px`,
    s: `${theme.v1.size['8']}px`,
    m: `${theme.v1.size['12']}px`,
    l: `${theme.v1.size['16']}px`,
    xl: `${theme.v1.size['32']}px`,
  };
  return {
    background: `linear-gradient(94deg, ${theme.v1.colors.border.default} 0%, ${theme.v1.colors.background.empty} 25%, ${theme.v1.colors.border.default} 50%)`,
    width: sizeHeightMap[size],
    height: sizeHeightMap[size],
    'border-radius': theme.v1.radius['9999'],
    animation: `${animateCircle} 2s linear infinite`,
    'background-size': '200% 100%',
    'background-position': '0% 50%',
  };
});

const SkeletonText: FC<SkeletonProps> = ({ size, lines }) => {
  return (
    <div>
      {range(0, lines!).map((_, index) => {
        return <SingleLine delay={index} size={size!} />;
      })}
    </div>
  );
};

const SkeletonTitle: FC = () => {
  return <SingleLine size="xl" delay={0} />;
};

const SkeletonRect = styled.div<{
  size: SkeletonSize;
}>(({ theme }) => {
  return {
    background: `linear-gradient(94deg, ${theme.v1.colors.border.default} 0%, ${theme.v1.colors.background.empty} 25%, ${theme.v1.colors.border.default} 50%)`,
    'background-size': '200% 100%',
    'background-position': '0% 50%',
    width: '100%',
    height: '100%',
    'border-radius': `${theme.v1.size['1']}px`,
    animation: `${animateLine} 2s linear infinite`,
  };
});

const Skeleton: FC<SkeletonProps> = ({ type = 'text', size = 'm', lines = 3 }) => {
  switch (type) {
    case 'text':
      return <SkeletonText size={size} lines={lines} />;
    case 'title':
      return <SkeletonTitle />;
    case 'circle':
      return <SkeletonCircle size={size} />;
    case 'rectangle':
      return <SkeletonRect size={size} />;
  }
};

export default Skeleton;
