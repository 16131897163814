import React from 'react';
import { DataFlowSidePanel, DescriptionSection } from './StyledPanelComponents';

const ModelTrainerContextPanel = ({
  id,
  name,
  detailsPanelClearAll,
  zoomToNode,
}: {
  id: string;
  name: string;
  detailsPanelClearAll: () => void;
  zoomToNode?: () => void;
}) => {
  return (
    <DataFlowSidePanel
      name={name}
      prehead="Model Trainer"
      nodeId={id}
      detailsPanelClearAll={detailsPanelClearAll}
      zoomToNode={zoomToNode}
    >
      <DescriptionSection
        description={`Batch consumer(s) of the '${name}' Feature Service. Examples include a model training process, or a batch scoring process.`}
      />
    </DataFlowSidePanel>
  );
};

export default ModelTrainerContextPanel;
