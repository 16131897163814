import { Box, IconButton, InputAdornment, TextField } from '@mui/material';
import React, { FC } from 'react';
import { useWorkspaceList } from '../../../../shared/query';
import BoltIcon from '@mui/icons-material/Bolt';
import ConstructionIcon from '@mui/icons-material/Construction';
import style from './style.module.scss';
import { useUserSettings } from '../../../../components/context/UserSettingsContext';

interface NewWorkspaceSelectorInputProperties {
  params: any;
}

const NewWorkspaceSelectorInput: FC<NewWorkspaceSelectorInputProperties> = ({ params }) => {
  const { workspace: currentWorkspace } = useUserSettings();
  const allWorkspaces = useWorkspaceList();

  if (!allWorkspaces.isSuccess) {
    return <></>;
  }

  if (currentWorkspace === null || currentWorkspace === undefined) {
    return <></>;
  }

  const workspaceWithCapabilities = allWorkspaces.data.workspaces.find((workspace: { name: string }) => {
    return workspace.name === currentWorkspace;
  });

  const workspaceIcon = workspaceWithCapabilities.capabilities.materializable ? (
    <BoltIcon sx={{ color: '#f2f7fa' }} />
  ) : (
    <ConstructionIcon sx={{ color: '#f2f7fa' }} />
  );

  return (
    <Box sx={{ marginRight: '12px' }}>
      <TextField
        {...params}
        variant="standard"
        placeholder={workspaceWithCapabilities?.name}
        inputProps={{
          ...params.inputProps,
          className: `${params.inputProps.className} ${style.searchInput}`,
        }}
        InputProps={{
          ...params.InputProps,
          disableUnderline: true,

          startAdornment: (
            <InputAdornment position="start">
              <IconButton sx={{ padding: 0, paddingLeft: '12px' }}>{workspaceIcon}</IconButton>
            </InputAdornment>
          ),
        }}
        sx={{
          color: 'white',
          backgroundColor: '#263449',
          paddingTop: '8px',
          paddingBottom: '8px',
          borderBottom: 'none',
        }}
      />
    </Box>
  );
};

export default NewWorkspaceSelectorInput;
