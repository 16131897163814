import React, { FC } from 'react';
import {
  useFloating,
  useClick,
  useDismiss,
  useRole,
  useInteractions,
  FloatingFocusManager,
  FloatingOverlay,
} from '@floating-ui/react';

import styled from '@emotion/styled';
import { css, keyframes } from '@emotion/react';
import { Button, FlexItem, FlexGroup, Text } from '.';

interface FlyoutProps {
  title: React.ReactNode;
  content: React.ReactNode;
  footer?: React.ReactNode;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

// These values are going to change pending decisions from UX
const slideIn = keyframes`
   from {
       right:-100vw;
   }
   to {
       right:0vw;
   }
`;

const FlyoutContainer = styled.div`
  position: absolute;
  width: 50%;
  height: 100%;
  background-color: ${({ theme }) => theme.v1.colors.background.empty};
  right: -50vw;

  overflow: scroll;

  animation: ${({ theme }) => {
    return css`
      ${slideIn} ${theme.v1.motion.duration.normal} ${theme.v1.motion.easing.enter} forwards
    `;
  }};

  z-index: ${({ theme }) => theme.v1.layers.flyout};
  box-shadow: ${({ theme }) => theme.v1.shadow.l};

  padding: 0;
`;

const TopRow = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.v1.colors.border.default};
  padding: ${({ theme }) => {
    return `${theme.v1.size['4']}px ${theme.v1.size['6']}px`;
  }};
  width: 100%;
  height: auto;
`;

const ContentContainer = styled.div`
  background-color: ${({ theme }) => theme.v1.colors.background.empty};
  padding: ${({ theme }) => theme.v1.size[6]}px;
  align-self: stretch;
  flex: 1;
  overflow: scroll;
`;

const FooterContainer = styled.div`
  background-color: ${({ theme }) => theme.v1.colors.background.default};
  padding: ${({ theme }) => {
    return `${theme.v1.size['4']}px ${theme.v1.size['6']}px`;
  }};
  width: 100%;
  height: auto;
  margin: 0;
`;

const Flyout: FC<FlyoutProps> = ({ title, content, footer, isOpen, setIsOpen }) => {
  const { refs, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
  });

  const click = useClick(context);
  const dismiss = useDismiss(context, {
    outsidePressEvent: 'mousedown',
  });
  const role = useRole(context);
  const { getFloatingProps } = useInteractions([click, dismiss, role]);

  return (
    <>
      {isOpen && (
        <FloatingOverlay
          lockScroll
          style={{
            zIndex: 9999,
            overflow: 'hidden',
            top: 0,
            left: 0,
          }}
        >
          <FloatingFocusManager context={context}>
            <div ref={refs.setFloating} {...getFloatingProps()}>
              <FlyoutContainer>
                <FlexGroup
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  padding={'0'}
                  gapSize="0"
                >
                  <TopRow>
                    <FlexItem shrink={'0'}>
                      <FlexGroup justifyContent="space-between" alignItems="center" padding={'0'}>
                        <div>
                          <Text element="h3">{title}</Text>
                        </div>
                        <div>
                          <Button type="ghost" icon={'Exit'} onClick={() => setIsOpen(false)} />
                        </div>
                      </FlexGroup>
                    </FlexItem>
                  </TopRow>

                  <FlexItem grow={'1'} shrink={'1'}>
                    <ContentContainer>{content}</ContentContainer>
                  </FlexItem>

                  {footer && (
                    <FooterContainer>
                      <FlexItem shrink={'0'}>
                        <FlexGroup justifyContent="flex-end" gapSize={'3'} padding={'0'}>
                          {footer}
                        </FlexGroup>
                      </FlexItem>
                    </FooterContainer>
                  )}
                </FlexGroup>
              </FlyoutContainer>
            </div>
          </FloatingFocusManager>
        </FloatingOverlay>
      )}
    </>
  );
};

export default Flyout;
