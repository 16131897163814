import { DataSourceFCOType } from '../../../../../core/types/fcoTypes';
import { DataFlowNode } from '../dataFlowTypes';
import edgeIdFn from './edgeIdFn';
import { getFeatureServiceIdGivenConsumer, getVisibleAnimationsOnAllEdgesGivenAnchors } from './highlight-utils';
import { HighlightFunctionType } from './highlightFunctionType';
import highlightUpstream from './highlightUpstream';

const offlineInferenceAnimations = {
  showBatchMaterializationPath: true,
  showOfflineReadPath: true,
};

const onlineInferenceAnimations = {
  showBatchMaterializationPath: true,
  showStreamMaterializationPath: true,
  showOnlineServingPath: true,
};

const highlightModelInference: HighlightFunctionType = (id, nodesMap, edgesList) => {
  const featureServiceId = getFeatureServiceIdGivenConsumer(id, nodesMap);
  const featureService = nodesMap[featureServiceId];

  if (featureService.type !== 'feature_service') {
    throw new Error(`Expecting node with id '${featureServiceId}' to be a FeatureServiceNode.`);
  }

  const edgeShouldBeSkipped = (sourceNode: DataFlowNode, targetNode: DataFlowNode) => {
    if (
      sourceNode.type === 'raw_batch_node' &&
      targetNode.type === 'data_source' &&
      targetNode.dataSourceType !== DataSourceFCOType.BATCH
    ) {
      /**
       * If the raw source is a batch source
       * on a stream data source, then
       * it should be skipped.
       */
      return true;
    }

    if (sourceNode.type === 'request_data_source') {
      /**
       * If we encounter a request data source, and
       * it is not embedded in the same feature service
       * that this online consumer is connected to,
       * then skip.
       */
      if (sourceNode.upstreamNodes && sourceNode.upstreamNodes[0] !== featureServiceId) {
        return true;
      }
    }

    return false;
  };

  const { linkedIds, linkedEdges } = highlightUpstream(id, nodesMap, edgesList, {
    hideOfflineStore: featureService?.isOnlineServingEnabled,
    hideOnlineStore: !featureService?.isOnlineServingEnabled,
    edgeShouldBeSkipped,
  });

  /**
   * Handle Edge Case:
   * The direct path from a FV to a FS (only
   * applicable when the FV has offline=false)
   * needs to be disabled when highlighting
   * this inference node IF the FS has online
   * serving enabled.
   */
  edgesList.forEach((edge) => {
    const source = nodesMap[edge.source];
    const target = nodesMap[edge.target];

    if (source.type === 'feature_view' && target.type === 'feature_service' && target.isOnlineServingEnabled) {
      linkedEdges.delete(edgeIdFn(edge));
    }
  });

  /**
   * Get Animations
   */
  const anchors = new Set<string>(featureService.upstreamNodes);
  anchors.add(featureServiceId);

  const animations = getVisibleAnimationsOnAllEdgesGivenAnchors(
    anchors,
    edgesList,
    linkedEdges,
    featureService.isOnlineServingEnabled ? onlineInferenceAnimations : offlineInferenceAnimations
  );

  return {
    linkedIds,
    linkedEdges,
    animations,
  };
};

export default highlightModelInference;
