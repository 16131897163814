import React, { ReactChild, useState, MouseEvent } from 'react';

import { useWhatsNewQuery } from './whatNewQuery';
import { logEvent } from '../../utils/analytics-utils';

import MegaphoneIcon from '../../assets/image/tecton-ui/icon/megaphone.svg';
import moment from 'moment-timezone';
import styles from './whatsnew.module.scss';

const STORAGE_KEY = 'tecton-whats-new-last-checked';
const THREE_DAYS_IN_MILLISECONDS = 2.592e8;

const WhatsNew = ({ children }: { children: ReactChild }) => {
  const { data } = useWhatsNewQuery();

  const [lastChecked, setLastChecked] = useState(() => {
    // getting stored value
    const saved: string = window.localStorage.getItem(STORAGE_KEY) || 'Nov 1, 2019 01:00:00'; // Last check defaults to an early time
    return new Date(saved);
  });

  const updateLastCheckedTimestamp = (e: MouseEvent) => {
    logEvent('Click on Whats New Notification');

    // Update LocalStorage Timestamp
    const currentTime = new Date();
    const currentTimeString = currentTime.toUTCString();
    window.localStorage.setItem(STORAGE_KEY, currentTimeString);

    setLastChecked(currentTime);
  };

  let notif = undefined;
  let link = 'https://www.tecton.ai/whats-new/';

  let showNewLink = false;
  if (data) {
    const feedLastUpdated = data[0].date;
    const now = new Date();
    let timeSinceLastPost = now.getTime() - feedLastUpdated.getTime();

    showNewLink = feedLastUpdated > lastChecked && timeSinceLastPost < THREE_DAYS_IN_MILLISECONDS;

    if (showNewLink) {
      const newestEntry = data[0];
      link = newestEntry.link;

      notif = (
        <>
          <div className={styles['whats-new-notification-badge']}>new</div>
          <div className={styles['title-on-hover-over']}>
            <span className={styles['whats-new-post-date']}>{moment(newestEntry.date).format('YYYY-MM-DD')}</span>
            <span className={styles['whats-new-post-title']}>{newestEntry.title} »</span>
          </div>
        </>
      );
    }
  }

  return (
    <a
      href={link}
      rel="noreferrer"
      target="_blank"
      className={`tray-item ${styles['whats-new-link']}`}
      onClick={updateLastCheckedTimestamp}
    >
      <div className={styles['whats-new-anchor']}>
        <img alt="What's New" src={MegaphoneIcon} />
        {notif}
      </div>
      <span>What&rsquo;s New</span>
    </a>
  );
};

export default WhatsNew;
