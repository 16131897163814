import React, { FC } from 'react';
import { FlexGroup, Icon, IconType, Text } from '.';
import styled from '@emotion/styled';
import { useTectonTheme } from '../../Theme/ThemeProvider';
import { Theme } from '@emotion/react';
import { IconSize } from './Icon';
import { V1Sizes } from '../../Theme/emotion';

export type BadgeColor =
  | 'gray'
  | 'green'
  | 'blueMist'
  | 'blue'
  | 'violet'
  | 'purple'
  | 'pink'
  | 'yellow'
  | 'orange'
  | 'red'
  | 'rose';

type BadgeSize = 'xs' | 's' | 'm';

interface BadgeProps {
  label?: React.ReactNode;
  color?: BadgeColor;
  icon?: IconType;
  size?: BadgeSize;
}

const badgeColorPairs: (theme: Theme, color: BadgeColor) => { behindText: string; text: string } = (theme, color) => {
  const BadgeColorPairs: Record<BadgeColor, { behindText: string; text: string }> = {
    gray: {
      behindText: theme.v1.colors.background.disabled,
      text: theme.v1.colors.text.text,
    },
    green: theme.v1.colors.viz['10-green'],
    blueMist: theme.v1.colors.viz['1-blueMist'],
    blue: theme.v1.colors.viz['7-blue'],
    violet: theme.v1.colors.viz['3-violet'],
    purple: theme.v1.colors.viz['9-purple'],
    pink: theme.v1.colors.viz['4-pink'],
    yellow: theme.v1.colors.viz['5-yellow'],
    orange: theme.v1.colors.viz['6-orange'],
    red: theme.v1.colors.viz['8-red'],
    rose: theme.v1.colors.viz['2-rose'],
  };

  return BadgeColorPairs[color];
};

const BadgeWrapper = styled.div<BadgeProps>(({ theme, color }) => {
  return {
    backgroundColor: badgeColorPairs(theme, color!).behindText,
    color: badgeColorPairs(theme, color!).text,
    display: 'inline-block',
    borderRadius: theme.v1.size['6'],
    width: 'auto',
  };
});

const Badge: FC<BadgeProps> = ({ label, color = 'gray', icon, size = 'm' }) => {
  const { theme } = useTectonTheme();

  const colors = badgeColorPairs(theme, color);

  const IconSizeMap: Record<BadgeSize, IconSize> = {
    xs: 'xs',
    s: 'xs',
    m: 's',
  };

  if (icon && label === undefined) {
    return (
      <>
        <BadgeWrapper color={color}>
          <FlexGroup
            gapSize={theme.v1.inlineSizing.gap[size] as V1Sizes}
            alignItems="center"
            justifyContent="center"
            padding="1.5"
          >
            <Icon icon={icon} size={IconSizeMap[size as BadgeSize]} type={'color'} color={colors.text} />
          </FlexGroup>
        </BadgeWrapper>
      </>
    );
  }

  return (
    <>
      <BadgeWrapper color={color}>
        <FlexGroup
          gapSize={theme.v1.inlineSizing.gap[size] as V1Sizes}
          css={{ padding: theme.v1.inlineSizing.padding[size] }}
          alignItems="center"
          justifyContent="center"
        >
          {icon && <Icon icon={icon} size={IconSizeMap[size as BadgeSize]} type={'color'} color={colors.text} />}
          {label && (
            <Text small={size === 's'} extraSmall={size === 'xs'} weight={size === 'm' ? 'medium' : 'regular'}>
              {label}
            </Text>
          )}
        </FlexGroup>
      </BadgeWrapper>
    </>
  );
};

export default Badge;
