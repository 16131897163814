import { useState } from 'react';
import { Navigate, Outlet, Route, Routes, useLocation, useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';

// Tecton
import { useGetFcos } from '../../../api/fcos';
import { FCOType, WorkspaceFCOContainer } from '../../../core/types/fcoTypes';
import { useUserSettings } from '../../context/UserSettingsContext';
import { ServiceRoutes, getFeatureServiceRouteName, getFeatureServiceRoutes } from './featureServiceUtils';

// @tecton
import { Breadcrumbs, HeaderLayout, RelatedFCOsTable, Subtitle, Tabs, ViewLayout } from '@tecton/ComponentRedesign';
import { DataflowContainer } from '@tecton/ComponentRedesign/Dataflow';
import { dataflowSpecSelector, idFormatter } from '@tecton/ComponentRedesign/Dataflow/workspaceDataflowSpecSelector';

import FeatureServiceOverview from '@tecton/ComponentRedesign/FeatureServiceOverview';
import { RelatedDatasetsTable } from '@tecton/ComponentRedesign/RelatedFCOsTable';
import { useGetDatasets } from '../../../api/datasets';
import { useGetFeatureServiceSummary } from '../../../api/serviceSummary';
import { FeatureServiceSummary } from '../../../core/types/featureServiceSummary';
import WorkspaceRootBreadcrumb from '../WorkspaceRootBreadcrumb';
import FeatureServiceDetailMonitoring from './FeatureServiceDetailMonitoring';
import FeatureServiceMaterialization from './FeatureServiceMaterialization';

const FeatureServiceOutlet = () => {
  /*
    This contains the header portion of the Feature Service
    - Breadcrumbs
    - Tabs
    - Outlet to the content
  */
  const navigation = useNavigate();
  const { name } = useParams();
  const { workspace, workspaceDetails } = useUserSettings();
  const location = useLocation();
  const featureRoutes = getFeatureServiceRouteName(location.pathname);
  const [selectedTab, setSelectedTab] = useState<ServiceRoutes>(featureRoutes);

  const { data, isLoading } = useGetFcos(workspace ?? '', {
    select: (data: WorkspaceFCOContainer) => {
      return {
        featureServices: data?.featureServices ?? [],
        featureViews: data?.featureViews ?? [],
        idToFcoMap: data?.idToFcoMap,
      };
    },
  });

  if (isLoading) {
    // TODO: Let's figure out what loader to use
    return <></>;
  }

  const featureService = data.featureServices.find((fs: any) => fs.name === name);

  const breadcrumbs = (
    <Breadcrumbs
      workspace={<WorkspaceRootBreadcrumb workspace={workspaceDetails} />}
      crumbs={[{ label: <Link to={`../${workspace}/feature-services`}>Services</Link> }, { label: name }]}
    />
  );
  const routeName = Object.keys(getFeatureServiceRoutes());

  const tabs = (
    <Tabs
      selectedTab={selectedTab}
      tabs={routeName}
      setSelectedTab={(tab: ServiceRoutes) => {
        const routes = getFeatureServiceRoutes();
        navigation(routes[tab]);
        setSelectedTab(tab);
      }}
    />
  );

  const subtitle = <Subtitle description={featureService.description ?? 'No description provided.'} />;
  const header = <HeaderLayout breadcrumbs={breadcrumbs} subtitle={subtitle} tabs={tabs} />;

  return <ViewLayout header={header} body={<Outlet />} />;
};

const FeatureServiceContainer = () => {
  const { workspace } = useUserSettings();
  const { name } = useParams();
  const { data, isLoading } = useGetFcos(workspace ?? '', {});
  const { data: datasetsData, isLoading: datasetsLoading } = useGetDatasets(workspace ?? '');
  const { data: summaryStateData, isLoading: summaryIsLoading } = useGetFeatureServiceSummary(workspace!, name ?? '');

  if (isLoading || datasetsLoading || summaryIsLoading) {
    return <></>;
  }

  const featureService = data?.featureServices.find((fs: any) => {
    return fs.name === name;
  });

  if (!featureService) {
    // We couldn't find the feature service so we will redirect you to the Feature Service lists.
    // This can happen when you switch workspaces and that particular FS detail doesn't exist in the workspace you are switching to.
    return <Navigate to={`../${workspace}/feature-services`} />;
  }

  const featureViews = featureService.allFeatureViews.map((fvId: string) => data.idToFcoMap[fvId]);

  const asData = dataflowSpecSelector(data, name ?? '', {
    id: idFormatter(featureService),
    type: FCOType.FEATURE_SERVICE,
  });

  const featureServiceRoutes = getFeatureServiceRoutes();

  return (
    <Routes>
      <Route element={<FeatureServiceOutlet />}>
        <Route index element={<Navigate to={featureServiceRoutes['Dataflow']} replace />} />
        <Route path={featureServiceRoutes['Dataflow']} element={<DataflowContainer data={asData} />} />
        <Route
          path={featureServiceRoutes['Metadata']}
          element={
            <FeatureServiceOverview
              service={featureService}
              fcos={data}
              workspace={workspace ?? ''}
              servingSummary={{ ...summaryStateData } as FeatureServiceSummary}
              // servingSummary={mockFetchServiceSummary()}
              curlSummary={summaryStateData}
            />
          }
        />
        <Route
          path={featureServiceRoutes['Materialization']}
          element={<FeatureServiceMaterialization featureService={featureService} featureViews={data.featureViews} />}
        />
        <Route
          path={featureServiceRoutes['Feature Views']}
          element={
            <RelatedFCOsTable
              type={FCOType.FEATURE_VIEW}
              fco={featureService}
              workspace={workspace}
              featureViews={featureViews}
              idToFcoMap={data.idToFcoMap}
            />
          }
        />
        <Route
          path={featureServiceRoutes['Monitoring']}
          element={
            <FeatureServiceDetailMonitoring featureService={featureService} featureViews={data?.featureViews ?? []} />
          }
        />
        <Route
          path={featureServiceRoutes['Datasets']}
          element={
            <RelatedDatasetsTable
              data={datasetsData?.data ?? []}
              isLoading={datasetsLoading}
              fco={featureService}
              workspace={workspace}
              idToFcoMap={data.idToFcoMap}
            />
          }
        />
      </Route>
    </Routes>
  );
};

export default FeatureServiceContainer;
