import React, { FC } from 'react';
import { Grid, Typography } from '@mui/material';
import StandardPage from '../../../../components/page/StandardPage';
import Emoji from '../../../../components/emoji/Emoji';

const SetupInProgress: FC = () => {
  return (
    <StandardPage canShowTitle={false} pageTitle="Choose Platform">
      <Grid container spacing={4} direction="column" alignItems="center" justifyContent="top">
        <Grid item xs={3} style={{ width: 460 }}>
          <Typography style={{ fontSize: 48, fontWeight: 350 }}>
            Hang tight. We&lsquo;re getting systems setup for you.
          </Typography>
        </Grid>
        <Grid item xs={3} style={{ width: 460 }}>
          <Emoji size={70} symbol={'\u{1f3d7}'} label="construction" />
          <Emoji size={70} symbol={'\u{1F69C}'} label="tractor" />
          <Emoji size={70} symbol={'\u{1F6E0}'} label="hammer-and-wrench" />
        </Grid>
        <Grid item xs={3} style={{ width: 460 }}>
          <Typography variant="h6">
            You will receive an email once everything is ready to go. Check back in 5-10 minutes
          </Typography>
        </Grid>
      </Grid>
    </StandardPage>
  );
};

export default SetupInProgress;
