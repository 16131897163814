import React from 'react';
import { Title } from '@tecton';

import { DataFlowSidePanel, DescriptionSection } from './StyledPanelComponents';
import DetailsPanelAccordionSection from './DetailsPanelAccordionSection';
import UpstreamDownstreamTable from './UpstreamDownstreamTable';

import FeatureColumnsTable from './FeatureColumnsTable';
import TransformationAccordion from './TransformationAccordion';
import { ODFVSchemaTable } from './OnDemandInputSchemaSection';
import { ODFVDetailsPanelData } from './detailsPanelQueryTypes';
import { NodeClickCallbackCreator } from './detailsPanelTypes';

const mockDescription = 'No Description';

const ODFVContextPanel = ({
  id,
  name,
  description,
  url,
  detailsPanelClearAll,
  zoomToNode,
  onNodeClick,
  data,
}: {
  id: string;
  name: string;
  description?: string;
  url: string;
  detailsPanelClearAll: () => void;
  zoomToNode?: () => void;
  onNodeClick: NodeClickCallbackCreator;
  data?: ODFVDetailsPanelData;
}) => {
  return (
    <DataFlowSidePanel
      name={name}
      prehead={`Realtime Feature View`}
      nodeId={id}
      url={url}
      detailsPanelClearAll={detailsPanelClearAll}
      zoomToNode={zoomToNode}
    >
      <DescriptionSection description={description || mockDescription} />

      {data && (
        <>
          <DetailsPanelAccordionSection
            id="odfv-features-list"
            title={
              <Title size="xxs">
                <h4>Features List ({data.features.length})</h4>
              </Title>
            }
          >
            <FeatureColumnsTable items={data.features} />
          </DetailsPanelAccordionSection>

          {data.schema.length > 0 && (
            <DetailsPanelAccordionSection
              id="odfv-request-schema"
              title={
                <Title size="xxs">
                  <h4>Request Schema</h4>
                </Title>
              }
            >
              <ODFVSchemaTable schema={data.schema} />
            </DetailsPanelAccordionSection>
          )}

          <UpstreamDownstreamTable
            onNodeClick={onNodeClick}
            upstreamNodes={data.upstreamNodes}
            downstreamNodes={data.downstreamNodes}
          />
          {data.transformations && <TransformationAccordion transformations={data.transformations} />}
        </>
      )}
    </DataFlowSidePanel>
  );
};

export default ODFVContextPanel;
