import { css } from '@emotion/css';
import _uniqBy from 'lodash/uniqBy';
import { AccountType, Permission, RoleDefinition } from '../../../api/gql/graphql';
import { PrincipalType } from '../../../types/tecton_proto/auth/principal';
import { ResourceType } from '../../../types/tecton_proto/auth/resource';
import { RadioGroupOption } from './acl-types';

export const getACLRoutes = (): Record<string, string> => {
  return {
    Users: './accounts-and-access/users',
    'Service Accounts': './accounts-and-access/service-accounts',
    Groups: './accounts-and-access/groups',
  };
};

export const getAccountTypeOptions = (selectedId: string): RadioGroupOption[] => {
  const options = [
    {
      id: `Admin`,
      label: 'Admin',
      description: 'Can make sensitive infrastructure and access control changes.',
    },
    {
      id: `Regular`,
      label: 'Regular',
      description: 'Can take actions based on workspace-level roles.',
    },
  ];

  return options.map((option) => {
    if (option.id === selectedId) {
      return {
        ...option,
        checked: true,
      };
    }

    return {
      ...option,
      checked: false,
    };
  });
};

export const getRoleOptions = (
  roles: RoleDefinition[],
  roleFilters: string[],
  canAddNoneOption: boolean
): RadioGroupOption[] => {
  const availableRoles =
    roles
      ?.filter((role) => {
        return roleFilters?.includes(role.id);
      })
      ?.map((role) => {
        const { id, name: label, description } = role;
        return {
          id,
          label,
          description,
        };
      }) ?? [];

  if (canAddNoneOption) {
    availableRoles.push({ id: 'none', label: 'None', description: 'No access' });
  }

  return availableRoles;
};

export const getRoleRadioGroupOptions = (roles: RadioGroupOption[], workspaceRole?: string): RadioGroupOption[] => {
  return (
    roles?.map((role) => {
      if (role.label === workspaceRole) {
        return {
          ...role,
          checked: true,
        };
      }

      return {
        ...role,
        checked: false,
      };
    }) ?? []
  );
};

export const getRoleDescriptionMap = (): Record<AccountType, Permission[]> => {
  return {
    Admin: adminTableValues,
    Regular: regularValues,
  };
};

export const getNoneRolePermissions = (roleDefinitions: RoleDefinition[]) => {
  // Since we don't get None role metadata from the backend, we need to construct it here.
  // It's basically all permissions with isAuthorized set to false
  const allPermissions = roleDefinitions?.flatMap((role) => {
    return [...(role?.permissions ?? [])];
  });

  // Get only unique permission so we can display it on the None option
  const uniqPermissions = _uniqBy(allPermissions, 'description').map((p) => {
    const copyPermissions = { ...p };
    copyPermissions.isAuthorized = false;
    return copyPermissions;
  });

  return uniqPermissions;
};

export const getRoleToPermissionMap = (roleDefinitions: RoleDefinition[]) => {
  const rolePermissionMap = roleDefinitions?.reduce<Record<string, Permission[]>>((result, map) => {
    result[`${map.name}`] = map?.permissions ?? [];
    return result;
  }, {});

  // We take all the permissions for option 'None';
  const noneRolePermissions = getNoneRolePermissions(roleDefinitions ?? []);
  rolePermissionMap['None'] = noneRolePermissions;

  return rolePermissionMap;
};

export const getFilterRolesForServiceAccount = (roleDefinitions: RoleDefinition[], principalType: PrincipalType) => {
  const filteredRoles = roleDefinitions?.filter((roleDefinition) => {
    return (
      roleDefinition.assignableOnResourceTypes.includes(ResourceType.RESOURCE_TYPE_WORKSPACE) &&
      roleDefinition.assignableToPrincipalTypes.includes(principalType)
    );
  });

  return filteredRoles;
};

const adminTableValues: Permission[] = [
  {
    isAuthorized: true,
    description: 'Create a live workspace',
  },
  {
    isAuthorized: true,
    description: ' Manage users',
  },
  {
    isAuthorized: true,
    description: 'Modify roles',
  },
  {
    isAuthorized: true,
    description: 'Modify Platform Configuration',
  },
  {
    isAuthorized: true,
    description: 'Create Service Accounts',
  },
  {
    isAuthorized: true,
    description: 'Create a dev workspace',
  },
];

const regularValues: Permission[] = [
  {
    isAuthorized: false,
    description: 'Create a live workspace',
  },
  {
    isAuthorized: false,
    description: ' Manage users',
  },
  {
    isAuthorized: false,
    description: 'Modify roles',
  },
  {
    isAuthorized: false,
    description: 'Modify Platform Configuration',
  },
  {
    isAuthorized: true,
    description: 'Create Service Accounts',
  },
  {
    isAuthorized: true,
    description: 'Create a dev workspace',
  },
];

export type ACLMembershipRoutes = 'Workspaces' | 'Groups';
export type ACLGroupRoutes = 'Workspaces' | 'Users' | 'Service Accounts';

export const getACLAccountRoutes = (): Record<string, string> => {
  return {
    Workspaces: 'workspaces',
    Groups: 'groups',
  };
};

export const getAClACcountRouteName = (pathname: string): ACLMembershipRoutes => {
  const pathSegments = pathname.split('/');
  const lastPathSegment = pathSegments[pathSegments.length - 1];
  const routes = getACLAccountRoutes();
  const record = Object?.entries(routes)?.find((record) => {
    return record[1] === lastPathSegment;
  });

  return (record?.[0] ?? 'Workspaces') as ACLMembershipRoutes;
};

export const getACLGroupRoutes = (): Record<ACLGroupRoutes, string> => {
  return {
    Workspaces: 'workspaces',
    Users: 'users',
    'Service Accounts': 'service-accounts',
  };
};

export const hiddenEditLinkClass = 'tecton-hidden-edit-button';

export const hiddenEditLinkRowCss = css`
  &:hover .${hiddenEditLinkClass} {
    opacity: 1;
    visibility: visible;
  }
`;

export const cellCss = css`
  width: 100%;
  align-items: center;

  .${hiddenEditLinkClass} {
    margin-left: 5px;
    opacity: 0;
    transition: opacity 0.3s;
    visibility: hidden;

    svg {
      transform: translate(0, -1px);
    }
  }

  &:hover {
    .euiLink {
      opacity: 1;
      visibility: visible;
    }
  }
`;
