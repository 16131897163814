import { Badge, BadgeVariants, Icon } from '@tecton/ComponentRedesign';
import { FC } from 'react';
import { TectonManagedStage_State } from '../../../types/tecton_proto/materialization/job_metadata';

import { ReactComponent as RunningIcon } from '@svg/running.svg';

interface JobStatusBadgeProps {
  status: TectonManagedStage_State;
}

const TectonManageJobStatusBadge: FC<JobStatusBadgeProps> = ({ status }) => {
  const variantAndLabel: Record<TectonManagedStage_State, { label: string; variant: BadgeVariants }> = {
    [TectonManagedStage_State.STATE_UNSPECIFIED]: {
      label: 'Drained',
      variant: 'hollow',
    },
    [TectonManagedStage_State.ERROR]: {
      label: 'Error',
      variant: 'danger',
    },
    [TectonManagedStage_State.CANCELLED]: {
      label: 'Manually Cancelled',
      variant: 'warning',
    },
    [TectonManagedStage_State.PENDING]: {
      label: 'Pending',
      variant: 'accent',
    },
    [TectonManagedStage_State.RUNNING]: {
      label: 'Running',
      variant: 'primary',
    },
    [TectonManagedStage_State.SUCCESS]: {
      label: 'Success',
      variant: 'primary',
    },
  };

  const icon = status === TectonManagedStage_State.RUNNING ? <Icon type={RunningIcon} /> : <></>;

  return <Badge {...variantAndLabel[status]} icon={icon} />;
};

export default TectonManageJobStatusBadge;
