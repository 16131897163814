import React, { FC } from 'react';
import {
  FeatureServiceFCO,
  FeatureServiceFCOFields,
  FeatureViewFCO,
  FeatureViewFCOFields,
  FeatureViewServingStatusSummaryWithId,
} from '../../../core/types/fcoTypes';
import { useFeatureServiceServingStatus } from '../../../feature/feature-services/feature-service/query';
import IdUtils from '../../../utils/id-utils';
import ServingStateChart from '../../@tecton/ComponentRedesign/ServingStatus';
import { CenterContentLayout, EmptyPrompt } from '@tecton/ComponentRedesign';
import { useUserSettings } from '../../context/UserSettingsContext';

interface FeatureServiceMaterializationProps {
  featureService: FeatureServiceFCO;
  featureViews: FeatureViewFCO[];
}

const FeatureServiceMaterialization: FC<FeatureServiceMaterializationProps> = ({ featureService, featureViews }) => {
  const { workspace } = useUserSettings();

  const { data: fullServingStatusSummary, isLoading } = useFeatureServiceServingStatus(
    featureService.id,
    0,
    featureService[FeatureServiceFCOFields.ALL_FEATURE_VIEWS].length
  );

  if (isLoading || !fullServingStatusSummary) {
    return (
      <CenterContentLayout>
        <EmptyPrompt title={<>Loading Materialization</>} body={<></>} variant="loading" orientation="vertical" />
      </CenterContentLayout>
    );
  }

  const attemptStatuses: FeatureViewServingStatusSummaryWithId[] =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fullServingStatusSummary.feature_view_serving_status_summaries.map((summary: any) => {
      const actualId = IdUtils.toStringId(summary.feature_view_id);
      return {
        id: actualId,
        serving_status_summary: summary.serving_status_summary,
      };
    });

  const allFeatureViewsAreODFV = featureViews
    .map((featureView: FeatureViewFCO) => featureView[FeatureViewFCOFields.IS_REALTIME])
    .every((result: boolean) => result === true);

  if (allFeatureViewsAreODFV) {
    return (
      <EmptyPrompt
        title={<>No Materialization Data Available</>}
        body={<>This Feature Service does not contain any Feature Views with materialization enabled.</>}
        variant="search"
      />
    );
  }

  return <ServingStateChart featureViews={featureViews} attemptSummary={attemptStatuses} workspace={workspace ?? ''} />;
};

export default FeatureServiceMaterialization;
