import React, { useLayoutEffect, useMemo, useState } from 'react';
import { NodeTypes, ReactFlow, useNodesInitialized, useReactFlow, MiniMap } from '@xyflow/react';
import { DataFlowSpec } from './dataFlowTypes';
import styled from '@emotion/styled';
import BaseSummaryCard from './SummaryDiagramCards/BaseSummaryCard';
import SummaryStoreNode from './SummaryDiagramCards/SummaryStoreNode';
import { createNodesMap } from './helpers';
import { processSummaryModeForReactFlow } from './processSummaryModeForReactFlow';
import { DataFlowWrapper } from './VisualPrimitives/CardTypography';
import DataFlowControls from './DataFlowControls';
import DataFlowBackground from './DataFlowBackground';
import DataFlowStorageNode from './DataFlowStorageNode';
import { getTopLeftNodeRelativeToViewport } from './DataflowDiagram';

const nodeTypes = {
  SUMMARY_BATCH_DATA_SOURCES: BaseSummaryCard,
  SUMMARY_STREAM_DATA_SOURCES: BaseSummaryCard,
  SUMMARY_BATCH_FEATURE_VIEWS: BaseSummaryCard,
  SUMMARY_STREAM_FEATURE_VIEWS: BaseSummaryCard,
  SUMMARY_FEATURE_TABLES: BaseSummaryCard,
  SUMMARY_ODFVS: BaseSummaryCard,
  SUMMARY_ONLINE_FEATURE_SERVICES: BaseSummaryCard,
  SUMMARY_OFFLINE_FEATURE_SERVICES: BaseSummaryCard,
  SUMMARY_STORE_WRAPPER: DataFlowStorageNode,
  SUMMARY_ONLINE_STORE: SummaryStoreNode,
  SUMMARY_OFFLINE_STORE: SummaryStoreNode,
};

const MiniMapStyled = styled(MiniMap)`
  background-color: ${({ theme }) => theme.colors.lightShade};

  .react-flow__minimap-mask {
  }

  .react-flow__minimap-node {
    stroke: none;
  }
`;

const Initialize = ({ setInteractionReady }: { setInteractionReady: (interactionReady: boolean) => void }) => {
  const nodesInitialized = useNodesInitialized();
  const { setViewport, getNodes } = useReactFlow();

  const nodes = getNodes();

  useLayoutEffect(() => {
    const vp = getTopLeftNodeRelativeToViewport({ nodes, viewType: 'summary', zoomLevel: 0.7 });

    setViewport(vp, { duration: 500 });

    if (nodesInitialized) {
      setTimeout(() => {
        setInteractionReady(true);
      }, 1000);
    }
  });

  return null;
};

const DataflowSummaryDiagram = ({
  nodesList,
  height,
  setShowSummarizedView,
}: DataFlowSpec & {
  height: string;
  setShowSummarizedView: React.Dispatch<React.SetStateAction<boolean | undefined>>;
}) => {
  const nodesMap = useMemo(() => {
    return createNodesMap(nodesList);
  }, [nodesList]);

  const { nodes, edges } = useMemo(() => {
    return processSummaryModeForReactFlow(nodesList, nodesMap);
  }, [nodesList, nodesMap]);

  const [interactionReady, setInteractionReady] = useState<boolean>(false);
  const [reduceAnimation, setReduceAnimation] = useState<boolean>(false);

  edges.forEach((edge) => {
    edge.type = 'smoothstep';
  });

  return (
    <>
      <DataFlowWrapper height={height}>
        <ReactFlow
          nodeTypes={nodeTypes as unknown as NodeTypes}
          nodes={nodes}
          defaultNodes={nodes}
          edges={edges}
          panOnScroll
          nodesDraggable={false}
          nodesConnectable={false}
          onMove={() => {}}
        >
          <MiniMapStyled zoomable pannable />

          <DataFlowControls
            setShowSummarizedView={setShowSummarizedView}
            showSummarizedView={true}
            setReduceAnimation={setReduceAnimation}
            configPopoverRef={undefined}
            reduceAnimation={reduceAnimation}
          />
          <DataFlowBackground />
          <Initialize setInteractionReady={setInteractionReady} />
        </ReactFlow>
      </DataFlowWrapper>
    </>
  );
};

export default DataflowSummaryDiagram;
