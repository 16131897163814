import React, { FC } from 'react';
import { DataSourceFCO } from '../../../core/types/fcoTypes';
import { Table, Monospace, Card } from '@tecton/ComponentRedesign';

interface SchemaCardProps {
  source: DataSourceFCO;
}

const SchemaCard: FC<SchemaCardProps> = ({ source }) => {
  const columns = [
    {
      name: 'Name',
      field: 'name',
    },
    {
      name: 'Type',
      field: 'type',
      render: (item: unknown) => {
        return (
          <Monospace>
            <>{item}</>
          </Monospace>
        );
      },
    },
  ];

  if (source.columns.length === 0) {
    return <></>;
  }

  return (
    <Card title={`Schema (${source.columns.length})`}>
      <Table columns={columns} items={source.columns} compressed />
    </Card>
  );
};

export default SchemaCard;
