import React from 'react';
import { Accordion, Panel, Title, useTectonTheme, AttributesList } from '@tecton';
import { DataFlowSidePanel, DescriptionSection } from './StyledPanelComponents';
import DetailsPanelAccordionSection from './DetailsPanelAccordionSection';
import UpstreamDownstreamTable from './UpstreamDownstreamTable';
import FeatureColumnsTable from './FeatureColumnsTable';

import styled from '@emotion/styled';
import OnDemandInputSchemaSection from './OnDemandInputSchemaSection';
import { FeatureServiceDetailsPanelData } from './detailsPanelQueryTypes';
import { NodeClickCallbackCreator } from './detailsPanelTypes';

const FeatureServiceContextPanel = ({
  id,
  name,
  description,
  url,
  detailsPanelClearAll,
  zoomToNode,
  onNodeClick,
  data,
}: {
  id: string;
  name: string;
  description?: string;
  url: string;
  detailsPanelClearAll: () => void;
  zoomToNode?: () => void;
  onNodeClick: NodeClickCallbackCreator;
  data: FeatureServiceDetailsPanelData;
}) => {
  const { theme } = useTectonTheme();

  const FVName = styled.strong`
    font-weight: ${theme.font.weight.semiBold};
  `;

  const FeatureGroupAccordion = styled(Accordion)`
    padding: ${theme.size.xs} 0;

    &.euiAccordion-isOpen {
      padding-bottom: ${theme.size.m};

      > .euiAccordion__triggerWrapper {
        margin-bottom: ${theme.size.m};
      }
    }
  `;

  return (
    <DataFlowSidePanel
      name={name}
      prehead={'Feature Service'}
      nodeId={id}
      url={url}
      detailsPanelClearAll={detailsPanelClearAll}
      zoomToNode={zoomToNode}
    >
      <DescriptionSection description={description} />
      {data && (
        <>
          <DetailsPanelAccordionSection
            id="configurations"
            title={
              <Title size="xxs">
                <h4>Materialization Configs</h4>
              </Title>
            }
            initialIsOpen
          >
            <AttributesList
              listItems={[
                {
                  title: 'Online Serving Enabled',
                  description: data.isOnlineServingEnabled ? 'True' : 'False',
                },
              ]}
              compressed
            />
          </DetailsPanelAccordionSection>
          <DetailsPanelAccordionSection
            id="features-list"
            title={
              <Title size="xxs">
                <h4>
                  Features (
                  {data.featureGroups.reduce((count, g) => {
                    return count + g.features.length;
                  }, 0)}
                  )
                </h4>
              </Title>
            }
          >
            {data.featureGroups.map((g) => {
              return (
                <FeatureGroupAccordion
                  key={g.id}
                  id={g.id}
                  arrowDisplay="right"
                  buttonContent={
                    <>
                      {g.features.length} feature{g.features.length > 1 && 's'} from <FVName>{g.name}</FVName>
                    </>
                  }
                >
                  <Panel hasBorder paddingSize={'m'}>
                    <FeatureColumnsTable items={g.features} />
                  </Panel>
                </FeatureGroupAccordion>
              );
            })}
          </DetailsPanelAccordionSection>

          {data.schemaList.length > 0 && <OnDemandInputSchemaSection schemaList={data.schemaList} />}

          <UpstreamDownstreamTable onNodeClick={onNodeClick} upstreamNodes={data.upstreamNodes} downstreamNodes={[]} />
        </>
      )}
    </DataFlowSidePanel>
  );
};

export default FeatureServiceContextPanel;
