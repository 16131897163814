import { FC, useState } from 'react';
import AccountTypeRadioOption from './AccountTypeRadioOption';
import CapabilityList from './CapabilityList';
import { RadioGroupOption } from './acl-types';
import { getRoleRadioGroupOptions } from './acl-util';

// generated
import { Option, Permission, PermissionGroup } from '../../../api/gql/graphql';

// @tecton
import { ComboBox, FlexGroupWrapper, FlexItem } from '@tecton/ComponentRedesign';
import { ACLModalFormWrapper } from './StyledComponents';

interface AddGroupToWorkspaceProps {
  roles: RadioGroupOption[];
  roleDescriptionMap?: Record<string, Permission[]>;
  onChange: (roleName?: string, selectedGroup?: PermissionGroup) => void;
  assignableGroups: PermissionGroup[];
}

const AddGroupToWorkspace: FC<AddGroupToWorkspaceProps> = ({
  roles,
  roleDescriptionMap,
  onChange,
  assignableGroups,
}) => {
  const permissions = roleDescriptionMap ? roleDescriptionMap[``] : [];

  // general state
  const [roleDescription, setRoleDescription] = useState<Permission[]>(permissions);
  const [options, setOptions] = useState<RadioGroupOption[]>(getRoleRadioGroupOptions(roles));
  const [selectedGroup, setSelectedGroup] = useState<PermissionGroup | undefined>();
  const [selectedRole, setSelectedRole] = useState<string | undefined>();
  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);

  if (assignableGroups.length === 0) {
    return <>To add another group go to ‘Accounts & Access’ settings to create a new group.</>;
  }

  const groupOptions =
    assignableGroups?.map((group) => {
      return {
        label: group.name ?? '',
        value: group,
      };
    }) ?? [];

  return (
    <>
      <FlexGroupWrapper gap="xl" direction="column">
        <FlexItem>
          <ComboBox
            key="test-id-group-list"
            data-testid="test-id-group-list"
            placeholder="Select a Group"
            singleSelection={{ asPlainText: true }}
            options={groupOptions}
            fullWidth={true}
            selectedOptions={selectedOptions}
            onChange={(selectedOptions) => {
              setSelectedOptions(selectedOptions);
              setSelectedGroup(selectedOptions[0].value);
              onChange(selectedRole, selectedOptions[0].value);
            }}
          />
        </FlexItem>
        <FlexItem>
          <ACLModalFormWrapper>
            <FlexGroupWrapper gap="xl" direction="row">
              <FlexItem grow={8}>
                <AccountTypeRadioOption
                  options={options ?? []}
                  onSelection={(selectedOption) => {
                    const permissions = roleDescriptionMap ? roleDescriptionMap[`${selectedOption?.label}`] : [];
                    setOptions(getRoleRadioGroupOptions(roles, selectedOption?.label ?? ''));
                    setRoleDescription(permissions);
                    setSelectedRole(selectedOption?.label);
                    onChange(selectedOption?.label ?? '', selectedGroup);
                  }}
                />
              </FlexItem>
              <FlexItem grow={6}>
                <CapabilityList roleDescriptions={roleDescription ?? []} />
              </FlexItem>
            </FlexGroupWrapper>
          </ACLModalFormWrapper>
        </FlexItem>
      </FlexGroupWrapper>
    </>
  );
};

export default AddGroupToWorkspace;
