import styled from '@emotion/styled';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router';

import { Button, Card, FlexGrid, FlexGroupWrapper, FlexItem, FormInput } from '@tecton/ComponentRedesign';
import { Routes } from '../../../core/routes';
import { useFindFCOWorkspace } from './query';

const FormWrapper = styled.div`
  width: 400px;
`;

const ButtonWrapper = styled.div`
  width: 180px;
`;
const Lookup: FC = () => {
  const navigate = useNavigate();
  const [workspace, setWorkspace] = useState<string | undefined>('prod');
  const [fcoId, setFcoId] = useState('');

  const [canShowResults, setCanShowResults] = useState(false);
  let responseView = null;

  const fcoWorkspace = useFindFCOWorkspace(fcoId);

  const clickHandler = () => {
    // @ts-ignore
    fcoWorkspace.refetch(fcoId);
    setCanShowResults(true);
  };

  if (canShowResults) {
    if (fcoWorkspace.data && fcoWorkspace.data.error) {
      responseView = (
        <div>
          {fcoId} : {fcoWorkspace.data.error}
        </div>
      );
    } else if (fcoWorkspace.data) {
      responseView = (
        <div>
          {fcoWorkspace?.data?.workspace ? (
            <>
              <div>Workspace Name : {fcoWorkspace?.data?.workspace}</div>
            </>
          ) : (
            'Workspace Not Found'
          )}
        </div>
      );
    }
  }

  return (
    <>
      <FlexGrid columns={1} gutterSize="xl">
        <FlexItem grow={false}>
          <FormInput
            label="Workspace"
            value={workspace}
            onChange={(ws) => {
              setWorkspace(ws.target.value);
            }}
          />
        </FlexItem>
        <FlexItem grow={false}>
          <Card showOverlay={false}>
            <FlexGroupWrapper gap="m" direction="column">
              <FlexItem grow={false}>
                <FormWrapper>
                  <FormInput
                    label="FCO ID"
                    fullWidth
                    value={fcoId}
                    onChange={(e) => {
                      setFcoId(e.target.value);
                    }}
                    placeholder=""
                  />
                </FormWrapper>
              </FlexItem>
              <FlexItem>
                <ButtonWrapper>
                  <Button
                    label={'Get Workspace Name'}
                    isLoading={fcoWorkspace.isFetching}
                    variant={fcoId ? 'primaryAction' : 'disabledAction'}
                    onClick={() => {
                      clickHandler();
                    }}
                  />
                </ButtonWrapper>
              </FlexItem>
              <FlexItem>
                <div>{responseView}</div>
              </FlexItem>
            </FlexGroupWrapper>
          </Card>
        </FlexItem>
        <FlexItem grow={false}>
          <Card showOverlay={false}>
            <FlexGroupWrapper gap="m" direction="column">
              <FlexItem grow={false}>
                <FormWrapper>
                  <FormInput
                    label="Feature View ID"
                    onChange={(e) => {
                      setFcoId(e?.target?.value);
                    }}
                  />
                </FormWrapper>
              </FlexItem>
              <FlexItem>
                <ButtonWrapper>
                  <Button
                    label={'Load'}
                    onClick={() => {
                      const path = Routes.featureViewDebug
                        .replace(':workspace', workspace ?? 'prod')
                        .replace(':id', fcoId);

                      navigate(path);
                    }}
                  />
                </ButtonWrapper>
              </FlexItem>
            </FlexGroupWrapper>
          </Card>
        </FlexItem>
        <FlexItem grow={false}>
          <Card showOverlay={false}>
            <FlexGroupWrapper gap="m" direction="column">
              <FlexItem grow={false}>
                <FormWrapper>
                  <FormInput
                    label="Feature Service ID"
                    onChange={(e) => {
                      setFcoId(e?.target?.value);
                    }}
                  />
                </FormWrapper>
              </FlexItem>
              <FlexItem>
                <ButtonWrapper>
                  <Button
                    label={'Load'}
                    onClick={() => {
                      const path = Routes.featureServiceDebug
                        .replace(':workspace', workspace ?? 'prod')
                        .replace(':id', fcoId);
                      navigate(path);
                    }}
                  />
                </ButtonWrapper>
              </FlexItem>
            </FlexGroupWrapper>
          </Card>
        </FlexItem>
      </FlexGrid>
    </>
  );
};

export default Lookup;
