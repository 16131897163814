import React from 'react';

const DatabricksIcon = ({ invert }: { invert?: boolean }) => {
  return (
    <g>
      <path
        d="M21.5404 9.97059L12.0578 15.3461L1.98319 9.62027L1.51208 9.87395V14.0294L12.0699 20.0452L21.5525 14.6696V16.8803L12.0699 22.2558L1.98319 16.5299L1.51208 16.7836V17.4842L12.0699 23.5L22.6639 17.4842V13.3288L22.1928 13.0751L12.0578 18.8009L2.61134 13.4254V11.2511L12.0578 16.5903L22.6518 10.5746V6.47952L22.1082 6.1896L12.0578 11.8792L3.1187 6.79359L12.0578 1.74422L19.4386 5.89968L20.1029 5.54937V5.04202L12.0578 0.5L1.5 6.4916V7.13183L12.0578 13.1476L21.5404 7.77206V9.97059Z"
        fill={invert ? '#fff' : '#FF3621'}
      />
    </g>
  );
};

export default DatabricksIcon;
