/* eslint-disable prefer-rest-params */
import styled from '@emotion/styled';
import {
  AvatarAndName,
  Breadcrumbs,
  EnabledDisabledBadge,
  FCOIconAndName,
  FCOTagBadge,
  FlexGroup,
  FlexGroupWrapper,
  FlexItem,
  Monospace,
  Text,
  TruncateText,
} from '@tecton/ComponentRedesign';
import React, { FC } from 'react';
// @svg
import { ReactComponent as NotSetIcon } from '@svg/not-set.svg';
import { Link } from 'react-router-dom';
import { FCOTypeHumanReadable, fcoTypeRecord } from '../../core/types/fcoTypes';
import { FcoType } from '../../types/tecton_proto/metadataservice/metadata_service';
import { getFcoData, GlobalSearchFCOProps } from './GlobalSearch';
import WorkspaceRootBreadcrumb from './WorkspaceRootBreadcrumb';

export const breakPointForHidingFCODetails = '1008px';

const DetailWrapper = styled.div`
  line-height: ${({ theme }) => theme.font.lineHeights.s};
  padding: ${({ theme }) => theme.padding.xl};
`;

const FcoNameStyle = styled.div`
  .tooltipName {
    color: ${({ theme }) => theme.colors.title};
    font-size: ${({ theme }) => theme.font.fontSizes.m};
  }

  @media (max-width: calc(${breakPointForHidingFCODetails} + 64px)) {
    div {
      max-width: 248px;
    }
  }
`;

const DescriptionWrapper = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightShade};
  padding-bottom: ${({ theme }) => theme.gap.m};
`;

const IdWrapper = styled.div`
  max-width: 21vw;
`;

const FCOAttributeValueStyle = styled.div`
  color: ${({ theme }) => theme.colors.title};
`;

const DetailFlexGroupWrapper = styled(FlexGroup)`
  flex-direction: row;
  width: 100%;
  @media only screen and (max-width: ${breakPointForHidingFCODetails}) {
    flex-direction: column;
    width: unset;
  }
`;

const FlexFieldNameWrapper = styled(FlexItem)`
  flex-basis: 80px; // Force this column to be a specific width so we get more space for the values
  flex-grow: 0;
  flex-shrink: 0;

  @media only screen and (max-width: calc(${breakPointForHidingFCODetails})) {
    flex-direction: column;
    flex-basis: 0px; // Force this column to be a specific width so we get more space for the values
  }
`;

const SearchResultSelected: FC<{
  fco: GlobalSearchFCOProps;
  setShowSearchModal: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ fco, setShowSearchModal }) => {
  const owner = fco?.owner ? (
    <AvatarAndName name={fco?.owner ?? ''} />
  ) : (
    <AvatarAndName icon={<NotSetIcon />} name="Not Set" type="icon" />
  );

  const fcoType = fcoTypeRecord[fco.type];
  const readableFCO = FCOTypeHumanReadable[fcoType];
  const url = `repo/${fco?.workspace?.name}/${getFcoData(fco.type).typeUrl}/`;
  const fcoUrl = `repo/${fco?.workspace?.name}/${getFcoData(fco.type).typeUrl}/${fco.name}`;

  const FCOAttribute: FC<{ value: React.ReactNode; title: string }> = ({ title, value }) => {
    return (
      <>
        <DetailFlexGroupWrapper gutterSize="m" responsive={true} alignItems="flexStart" justifyContent="flexStart">
          <FlexFieldNameWrapper grow={false}>
            <Text weight="regular" small={true}>
              {title}
            </Text>
          </FlexFieldNameWrapper>
          <FlexItem grow={true}>
            <FCOAttributeValueStyle>{value}</FCOAttributeValueStyle>
          </FlexItem>
        </DetailFlexGroupWrapper>
      </>
    );
  };

  const workspace = (
    <>
      <WorkspaceRootBreadcrumb
        workspace={fco?.workspace}
        variant="small"
        maxWidth="180px"
        onClick={() => {
          setShowSearchModal(false);
        }}
      />
    </>
  );

  return (
    <DetailWrapper>
      <FlexGroupWrapper direction="column" gap="l">
        <FlexItem grow={false}>
          <FlexGroupWrapper direction="column" gap="m">
            <FlexItem>
              <Breadcrumbs
                variant="small"
                workspace={workspace}
                crumbs={[{ label: readableFCO, url }]}
                onClick={() => {
                  setShowSearchModal(false);
                }}
              />
            </FlexItem>
            <FlexItem grow={false}>
              <FcoNameStyle>
                <Link
                  to={fcoUrl}
                  onClick={() => {
                    setShowSearchModal(false);
                  }}
                >
                  <FCOIconAndName
                    name={fco?.name}
                    type={fcoTypeRecord[fco.type]}
                    isModified={false}
                    maxWidthName="294px"
                  />
                </Link>
              </FcoNameStyle>
            </FlexItem>
            <FlexItem grow={false}>
              <DescriptionWrapper>{fco?.description}</DescriptionWrapper>
            </FlexItem>
          </FlexGroupWrapper>
        </FlexItem>
        <FlexItem grow={false}>
          <FlexGroupWrapper
            direction="column"
            gap="m"
            responsive={true}
            alignItems="flexStart"
            justifyContent="flexStart"
          >
            <FCOAttribute
              value={
                <Text weight="regular" element={'h6'}>
                  {fco.lastUpdated ?? ''}
                </Text>
              }
              title="Last Updated"
            />
            <FCOAttribute title="Owner" value={owner} />

            <FCOAttribute
              value={
                <IdWrapper>
                  <Text element={'h6'}>
                    <Monospace>
                      <TruncateText>{fco.id}</TruncateText>
                    </Monospace>
                  </Text>
                </IdWrapper>
              }
              title="ID"
            />

            {fco.type === FcoType.FCO_TYPE_FEATURE_VIEW && (
              <>
                <FCOAttribute title="Offline" value={<EnabledDisabledBadge enabled={fco.materializationOffline} />} />
              </>
            )}

            {fco.type === FcoType.FCO_TYPE_FEATURE_VIEW && (
              <>
                <FCOAttribute title="Online" value={<EnabledDisabledBadge enabled={fco.materializationOnline} />} />
              </>
            )}

            <FCOAttribute
              value={
                <>
                  {fco.tags?.map((tag) => {
                    return (
                      <div>
                        <FCOTagBadge tag={tag} maxWidth={'190px'} />
                      </div>
                    );
                  })}
                </>
              }
              title="Tags"
            />
          </FlexGroupWrapper>
        </FlexItem>
      </FlexGroupWrapper>
    </DetailWrapper>
  );
};

export default SearchResultSelected;
