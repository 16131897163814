import React, { FC, useState } from 'react';
import { TransformationFCO, FCOType, TransformationFCOFields, AnyFCO } from '../../../core/types/fcoTypes';

// @tecton
import { RelatedFCOs, ComboSelectorChecklistOption, ComboFilterSelector } from '@tecton/ComponentRedesign';
import {
  FCOFilterStateType,
  FCOFilterType,
  FCOHistoryFilterState,
  FCOHistoryFilterTypes,
  FCOHistoryTableColumns,
  FCOListView,
  NameColumn,
  RelatedFCOCountFilterLogic,
  RelatedFCOCountFilterType,
  RelatedFCOFilterLogic,
  RelatedFCOFilterType,
  SharedFCOHistoryFilterLogic,
  SimpleChecklistFilterLogic,
  SimpleChecklistFilterType,
  TagFilterLogic,
  TagsColumn,
  TagsFilterType,
  structureTagsForFilter,
  valuesForFCOCount,
} from '../fcoListView';
import { uniq } from 'lodash';

interface TransformationsListProps {
  items: TransformationFCO[];
  idToFcoMap: Record<string, AnyFCO>;
  workspace: string;
}

const TransformationsList: FC<TransformationsListProps> = ({ items, idToFcoMap, workspace }) => {
  type TransformationFilterKeys =
    | FCOFilterType
    | 'Mode'
    | '# of Feature Views'
    | 'Feature Views'
    | '# of Feature Services'
    | 'Feature Services';

  interface TransformationsFilterStateType extends FCOFilterStateType {
    Mode: ComboSelectorChecklistOption[];
    '# of Feature Views': ComboSelectorChecklistOption[];
    'Feature Views': ComboSelectorChecklistOption[];
    '# of Feature Services': ComboSelectorChecklistOption[];
    'Feature Services': ComboSelectorChecklistOption[];
  }

  const featureViews = uniq(items.map((item) => item.dependentFeatureViews).flat()).map((fvId) => idToFcoMap[fvId]);

  const featureServices = uniq(items.map((item) => item.dependentFeatureServices).flat()).map(
    (fvId) => idToFcoMap[fvId]
  );

  const modes = uniq(items.map((item) => item[TransformationFCOFields.MODE]));

  const defaultFilterState: TransformationsFilterStateType = {
    Tags: structureTagsForFilter(items),
    Mode: modes.map((mode) => {
      return { label: mode ?? 'n/a', value: mode };
    }),
    '# of Feature Views': valuesForFCOCount,
    'Feature Views': featureViews.map((featureView) => {
      return { label: featureView.name ?? '', value: featureView.id };
    }),
    '# of Feature Services': valuesForFCOCount,
    'Feature Services': featureServices.map((featureService) => {
      return { label: featureService.name ?? '', value: featureService.id };
    }),
    ...FCOHistoryFilterState(items as AnyFCO[]),
  };

  const [filterState, setFilterState] = useState<TransformationsFilterStateType>(defaultFilterState);

  const filterTypes: Record<TransformationFilterKeys, ComboFilterSelector> = {
    Tags: TagsFilterType(filterState, setFilterState as React.Dispatch<React.SetStateAction<FCOFilterStateType>>),
    Mode: SimpleChecklistFilterType(filterState, setFilterState, 'Mode'),
    '# of Feature Views': RelatedFCOCountFilterType(filterState, setFilterState, '# of Feature Views'),
    'Feature Views': RelatedFCOFilterType(filterState, setFilterState, 'Feature Views', FCOType.FEATURE_VIEW),
    '# of Feature Services': RelatedFCOCountFilterType(filterState, setFilterState, '# of Feature Services'),
    'Feature Services': RelatedFCOFilterType(filterState, setFilterState, 'Feature Services', FCOType.FEATURE_SERVICE),
    ...FCOHistoryFilterTypes(filterState, setFilterState as React.Dispatch<React.SetStateAction<FCOFilterStateType>>),
  };

  const columns = [
    ...NameColumn,
    ...TagsColumn,
    {
      name: 'Mode',
      key: 'Mode',
      field: TransformationFCOFields.MODE,
      sortable: true,
      render: (_: unknown, transformation: TransformationFCO) => {
        return transformation[TransformationFCOFields.MODE];
      },
    },
    {
      name: 'Feature Views',
      key: 'Feature Views',
      field: TransformationFCOFields.DEPENDENT_FEATURE_VIEWS,
      sortable: true,
      render: (_: unknown, transformation: TransformationFCO) => {
        const items = transformation[TransformationFCOFields.DEPENDENT_FEATURE_VIEWS]
          .map((featureViewId) => idToFcoMap[featureViewId])
          .map((fco) => {
            return { name: fco.name ?? '', description: fco.description };
          });
        return <RelatedFCOs fcos={items} type={FCOType.FEATURE_VIEW} workspace={workspace} />;
      },
    },
    {
      name: 'Feature Services',
      key: 'Feature Services',
      field: TransformationFCOFields.DEPENDENT_FEATURE_SERVICES,
      sortable: true,
      render: (_: unknown, transformation: TransformationFCO) => {
        const items = transformation[TransformationFCOFields.DEPENDENT_FEATURE_SERVICES]
          .map((featureViewId) => idToFcoMap[featureViewId])
          .map((fco) => {
            return { name: fco.name ?? '', description: fco.description };
          });
        return <RelatedFCOs fcos={items} type={FCOType.FEATURE_SERVICE} workspace={workspace} />;
      },
    },
    ...FCOHistoryTableColumns,
  ];

  const filterFunctionsByFilterKey: Record<TransformationFilterKeys, (fco: TransformationFCO) => boolean> = {
    Tags: TagFilterLogic(filterState['Tags']),
    ...SharedFCOHistoryFilterLogic(filterState),
    Mode: SimpleChecklistFilterLogic(filterState, 'Mode', TransformationFCOFields.MODE),
    '# of Feature Views': RelatedFCOCountFilterLogic(
      filterState,
      '# of Feature Views',
      TransformationFCOFields.DEPENDENT_FEATURE_VIEWS
    ),
    'Feature Views': RelatedFCOFilterLogic(
      filterState,
      'Feature Views',
      TransformationFCOFields.DEPENDENT_FEATURE_VIEWS
    ),
    '# of Feature Services': RelatedFCOCountFilterLogic(
      filterState,
      '# of Feature Services',
      TransformationFCOFields.DEPENDENT_FEATURE_SERVICES
    ),
    'Feature Services': RelatedFCOFilterLogic(
      filterState,
      'Feature Services',
      TransformationFCOFields.DEPENDENT_FEATURE_SERVICES
    ),
  };

  return (
    <FCOListView
      unfilteredFCOItems={items}
      filters={filterTypes}
      columns={columns}
      filterState={filterState}
      setFilterState={setFilterState}
      defaultFilterState={defaultFilterState}
      filterFunctionsByFilterKey={filterFunctionsByFilterKey}
      searchPlaceholder={'Search Transformations'}
    />
  );
};

export default TransformationsList;
