import React, { FC } from 'react';
import { FlexGroup, Icon, Text, Tooltip } from '.';
import styled from '@emotion/styled';
import { useTectonTheme } from '../../Theme/ThemeProvider';

interface PaginationProps {
  pageCount: number;
  selectedPage: number;
  setSelectedPage: (page: number) => void;
  maxVisible?: number;
  compressed?: boolean;
  indeterminate?: boolean;
}

const PageLink = styled.div`
  padding: ${({ theme }) => `${theme.v1.size['0.5']}px ${theme.v1.size['2']}px 0px ${theme.v1.size['2']}px`};
  cursor: pointer;
  flex-grow: 0;
  flex-shrink: 0;
  color: ${({ theme }) => theme.v1.colors.text.text};
  border-bottom: ${({ theme }) => `${theme.v1.size['0.5']}px solid ${theme.v1.colors.background.transparent}`};

  &:hover {
    color: ${({ theme }) => theme.v1.colors.text.title};
    font-weight: ${({ theme }) => theme.font.weight.bold};
    border-bottom: ${({ theme }) => `${theme.v1.size['0.5']}px solid ${theme.v1.colors.text.title}`};
  }
`;

const SelectedPage = styled.div`
  cursor: default;
  font-weight: ${({ theme }) => theme.font.weight.bold};

  padding: ${({ theme }) => `${theme.v1.size['0.5']}px ${theme.v1.size['2']}px 0px ${theme.v1.size['2']}px`};
  flex-grow: 0;
  flex-shrink: 0;
  border-bottom: ${({ theme }) => `${theme.v1.size['0.5']}px solid ${theme.v1.colors.text.title}`};
`;

const NavigationLink = styled.div`
  display: inline;
  cursor: pointer;
`;

const NavigationLinkDisabled = styled.div`
  display: inline;
  cursor: not-allowed;
`;

const getArrayOfPages = (
  minPage: number,
  maxPage: number,
  currentPage: number,
  maxPagesToDisplay: number
): (number | string)[] => {
  if (minPage > maxPage || currentPage < minPage || currentPage > maxPage) {
    console.error('Invalid page values.');
  }

  const result: (number | string)[] = [];
  const totalRange = maxPage - minPage + 1;

  if (totalRange <= maxPagesToDisplay) {
    for (let i = minPage; i <= maxPage; i++) {
      result.push(i);
    }
    return result;
  }

  // Always include minimum and maximum
  result.push(minPage);
  const midRange = Math.floor((maxPagesToDisplay - 3) / 2);

  const rangeStart = Math.max(minPage + 1, Math.min(currentPage - midRange, maxPage - maxPagesToDisplay + 2));
  const rangeEnd = Math.min(maxPage - 1, Math.max(currentPage + midRange, minPage + maxPagesToDisplay - 2));

  // Add ellipsis if needed before range start
  if (rangeStart > minPage + 1) {
    result.push('...');
  }

  // Add numbers in the calculated range
  for (let i = rangeStart; i <= rangeEnd; i++) {
    result.push(i);
  }

  // Add ellipsis if needed after range end
  if (rangeEnd < maxPage - 1) {
    result.push('...');
  }

  // Always include the max page
  result.push(maxPage);

  return result;
};

const FirstPageLink: FC<{ onClick?: () => void }> = ({ onClick }) => {
  const { theme } = useTectonTheme();

  if (onClick) {
    return (
      <Tooltip
        trigger={
          <NavigationLink onClick={onClick}>
            <Icon type="mono" icon="LargeChevronLeft" size="xs" />
            <Icon type="mono" icon="LargeChevronLeft" size="xs" />
          </NavigationLink>
        }
        content="First Page"
      />
    );
  }

  return (
    // TODO: Need real icons from UX
    <NavigationLinkDisabled>
      <Icon type="mono" icon="LargeChevronLeft" size="xs" color={theme.v1.colors.text.subduedText} />
      <Icon type="mono" icon="LargeChevronLeft" size="xs" color={theme.v1.colors.text.subduedText} />
    </NavigationLinkDisabled>
  );
};

const PreviousPageLink: FC<{ onClick?: () => void }> = ({ onClick }) => {
  const { theme } = useTectonTheme();

  if (onClick) {
    return (
      <Tooltip
        trigger={
          <NavigationLink onClick={onClick}>
            <Icon type="mono" icon="LargeChevronLeft" size="xs" />
          </NavigationLink>
        }
        content="Previous Page"
      />
    );
  }

  return (
    <NavigationLinkDisabled>
      <Icon type="mono" icon="LargeChevronLeft" size="xs" color={theme.v1.colors.text.subduedText} />
    </NavigationLinkDisabled>
  );
};

const NextPageLink: FC<{ onClick?: () => void }> = ({ onClick }) => {
  const { theme } = useTectonTheme();

  if (onClick) {
    return (
      <Tooltip
        trigger={
          <NavigationLink onClick={onClick}>
            <Icon type="mono" icon="LargeChevronRight" size="xs" />
          </NavigationLink>
        }
        content="Next Page"
      />
    );
  }

  return (
    <NavigationLinkDisabled>
      <Icon type="mono" icon="LargeChevronRight" size="xs" color={theme.v1.colors.text.subduedText} />
    </NavigationLinkDisabled>
  );
};

const LastPageLink: FC<{ onClick?: () => void }> = ({ onClick }) => {
  if (onClick) {
    return (
      <Tooltip
        trigger={
          <NavigationLink onClick={onClick}>
            <Icon type="mono" icon="LargeChevronRight" size="xs" />
            <Icon type="mono" icon="LargeChevronRight" size="xs" />
          </NavigationLink>
        }
        content="Last Page"
      />
    );
  }
  return (
    <NavigationLinkDisabled>
      <Icon type="mono" icon="LargeChevronRight" size="xs" />
      <Icon type="mono" icon="LargeChevronRight" size="xs" />
    </NavigationLinkDisabled>
  );
};

const CompressedPagination: FC<PaginationProps> = ({ pageCount, selectedPage, setSelectedPage }) => {
  return (
    <FlexGroup alignItems="center" css={{ userSelect: 'none' }}>
      <FirstPageLink
        onClick={
          selectedPage === 1
            ? undefined
            : () => {
                setSelectedPage(1);
              }
        }
      />
      <PreviousPageLink
        onClick={
          selectedPage === 1
            ? undefined
            : () => {
                setSelectedPage(selectedPage - 1);
              }
        }
      />
      <PageLink
        onClick={() => {
          setSelectedPage(selectedPage as number);
        }}
      >
        {selectedPage}
      </PageLink>
      <div>
        <Text>of</Text>
      </div>
      <PageLink
        onClick={() => {
          setSelectedPage(pageCount);
        }}
      >
        {pageCount}
      </PageLink>
      <NextPageLink
        onClick={
          selectedPage === pageCount
            ? undefined
            : () => {
                setSelectedPage(selectedPage + 1);
              }
        }
      />
      <LastPageLink
        onClick={
          selectedPage === pageCount
            ? undefined
            : () => {
                setSelectedPage(pageCount);
              }
        }
      />
    </FlexGroup>
  );
};

const IndeterminatePagination: FC<PaginationProps> = ({ pageCount, selectedPage, setSelectedPage }) => {
  return (
    <FlexGroup alignItems="center" css={{ userSelect: 'none' }}>
      <FirstPageLink />
      <PreviousPageLink />
      <NextPageLink />
      <LastPageLink />
    </FlexGroup>
  );
};

const Pagination: FC<PaginationProps> = ({
  pageCount,
  selectedPage,
  setSelectedPage,
  maxVisible = 10,
  compressed = false,
  indeterminate = false,
}) => {
  const pagesToShow = getArrayOfPages(1, pageCount, selectedPage, maxVisible);

  if (compressed) {
    return <CompressedPagination pageCount={pageCount} selectedPage={selectedPage} setSelectedPage={setSelectedPage} />;
  }

  if (indeterminate) {
    return (
      <IndeterminatePagination pageCount={pageCount} selectedPage={selectedPage} setSelectedPage={setSelectedPage} />
    );
  }

  return (
    <FlexGroup alignItems="center" css={{ userSelect: 'none' }}>
      {selectedPage > 1 && (
        <>
          <FirstPageLink
            onClick={() => {
              setSelectedPage(1);
            }}
          />
          <PreviousPageLink
            onClick={() => {
              setSelectedPage(selectedPage - 1);
            }}
          />
        </>
      )}
      {selectedPage === 1 && (
        <>
          <FirstPageLink />
          <PreviousPageLink />
        </>
      )}
      {pagesToShow.map((page) => {
        if (page === '...') {
          return <div>...</div>;
        }

        if (page === selectedPage) {
          return (
            <SelectedPage>
              <Text>{page}</Text>
            </SelectedPage>
          );
        }
        return (
          <PageLink
            onClick={() => {
              setSelectedPage(page as number);
            }}
          >
            <Text>{page}</Text>
          </PageLink>
        );
      })}
      {selectedPage < pageCount && (
        <>
          <NextPageLink
            onClick={() => {
              setSelectedPage(selectedPage + 1);
            }}
          />
          <LastPageLink
            onClick={() => {
              setSelectedPage(pageCount);
            }}
          />
        </>
      )}
      {selectedPage === pageCount && (
        <>
          <NextPageLink />
          <LastPageLink />
        </>
      )}
    </FlexGroup>
  );
};

export default Pagination;
