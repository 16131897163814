import React, { FC, useContext } from 'react';

import {
  useTableRowExpansionState,
  EmptyPrompt,
  FCOIconAndName,
  Table,
  TableLoadMoreFlex,
  Button,
  FlexGroup,
  FlexItem,
  TitledItem,
  IconTip,
  Badge,
} from '@tecton/ComponentRedesign';
import styled from '@emotion/styled';
import { TectonDateTimeFormat } from '../../@tecton/ComponentRedesign/utils';
import moment from 'moment-timezone';
import { ExpectationResultEnum } from '../../../types/tecton_proto/dataobs/validation';
import Code from '../../@tecton/ComponentRedesign/lib/Code';
import TectonTitledItem from '../../@tecton/ComponentRedesign/lib/TitledItem';
import { FCOType } from '../../../core/types/fcoTypes';
import { ValidationRecord } from './types';
import { ValidationsContext } from './ValidationsContext';
import { ReactComponent as QuestionInCircle } from '@svg/question-in-circle.svg';

const RecordWrapper = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.padding.l};
  flex-direction: row;
  align-items: flex-start;
  gap: ${({ theme }) => theme.padding.l};
  align-self: stretch;
  width: 100%;

  background: ${({ theme }) => theme.colors.body};
`;

const RecordPanel = styled(FlexItem)`
  background-color: white;
  padding: ${({ theme }) => theme.padding.xl};
  align-items: flex-start;
  gap: ${({ theme }) => theme.padding.s};
  flex: 2 1 1;
`;

const RecordPanelTitle = styled.div`
  color: ${({ theme }) => theme.colors.title};
  font-size: ${({ theme }) => theme.padding.l}
  font-weight: ${({ theme }) => theme.font.weight.medium};
`;

const FullWidth = styled.div`
  width: 100%;
`;

const ValidationRecordExpansion: FC<{ validation: ValidationRecord }> = ({ validation }) => {
  return (
    <RecordWrapper>
      <FlexGroup>
        <RecordPanel grow={2}>
          <RecordPanelTitle>Expectation Expression</RecordPanelTitle>
          <Code code={validation.metricExpectationMetadata?.expression.expression ?? ''} />
        </RecordPanel>
        <RecordPanel grow={1}>
          <RecordPanelTitle>
            Input Parameters{' '}
            <IconTip
              content={`Parameters used as input to the expectation expression, from the data in the current interval.`}
              type={QuestionInCircle}
            />
          </RecordPanelTitle>
          <FullWidth>
            {validation.metricExpectationMetadata?.inputMetrics.map((metric) => {
              return <TitledItem title={metric.metric} content={metric.value} />;
            })}
          </FullWidth>
        </RecordPanel>
        <RecordPanel grow={1}>
          <RecordPanelTitle>Data Interval</RecordPanelTitle>
          <FullWidth>
            <TectonTitledItem
              title={'Interval Start'}
              content={TectonDateTimeFormat(moment(validation.featureIntervalStartTime))}
            />
            <TectonTitledItem
              title={'Interval End'}
              content={TectonDateTimeFormat(moment(validation.featureIntervalEndTime))}
            />
          </FullWidth>
        </RecordPanel>
      </FlexGroup>
    </RecordWrapper>
  );
};

const AlertCell = styled.div`
  color: ${({ theme }) => theme.colors.dangerText};
  font-weight: ${({ theme }) => theme.font.weight.regular};
`;

const ResultBagde: FC<{ result: ExpectationResultEnum }> = ({ result }) => {
  if (result === ExpectationResultEnum.RESULT_PASSED) {
    return <Badge data-testid="passed-badge" variant={'success'} label={'Passed'} />;
  }

  if (result === ExpectationResultEnum.RESULT_FAILED) {
    return <Badge variant={'danger'} data-testid="failed-badge" label={'Failed'} />;
  }

  return <Badge variant={'warning'} data-testid="error-badge" label={'Error'} />;
};

const ValidationsTable: FC = () => {
  const {
    data,
    isLoading,
    columns: visibleColumns,
    hasMore,
    loadMore,
    isFetching,
    isFetchingNextPage,
  } = useContext(ValidationsContext);

  const { itemIdToExpandedRowMap, toggleRowExpansion } = useTableRowExpansionState(
    (record: ValidationRecord) => {
      return record.id;
    },
    (record: ValidationRecord) => {
      return <ValidationRecordExpansion validation={record} />;
    }
  );

  const columns = [
    {
      name: 'Status',
      label: 'Status',
      key: 'status',
      render: (item: ValidationRecord) => {
        return <ResultBagde result={item.status} />;
      },
    },
    {
      name: 'Feature View',
      key: 'featureView',
      render: (item: ValidationRecord) => {
        return <FCOIconAndName name={item.featureViewName} type={FCOType.FEATURE_VIEW} />;
      },
    },
    {
      name: 'Validation Parameters',
      key: 'validationParameters',
      render: (item: ValidationRecord) => {
        return <>{item.expectationName}</>;
      },
    },
    {
      name: 'Details',
      key: 'details',
      render: (item: ValidationRecord) => {
        return <AlertCell>{item.metricExpectationMetadata?.alertMessage}</AlertCell>;
      },
    },
    {
      name: 'Completed At',
      render: (item: ValidationRecord) => <>{TectonDateTimeFormat(moment(item.validationTime))}</>,
      key: 'completedAt',
      label: 'Completed At',
    },
  ].filter((oneColumn) => {
    const matchColumn = visibleColumns.find((visibleColumn) => {
      return visibleColumn.columnKey === oneColumn.key;
    });

    return matchColumn?.checked !== undefined;
  });

  if (isLoading) {
    return (
      <FlexGroup direction="column" alignItems="center">
        <FlexItem>
          <EmptyPrompt variant="loading" title={<>Validations are Loading</>} body={<></>} orientation="vertical" />
        </FlexItem>
      </FlexGroup>
    );
  }

  return (
    <>
      <Table
        columns={columns}
        items={data}
        itemIdToExpandedRowMap={itemIdToExpandedRowMap}
        rowProps={(row) => {
          return {
            onClick: () => {
              toggleRowExpansion(row);
            },
          };
        }}
        isExpandable
        itemId={'id'}
      />
      {!!loadMore && hasMore && !((isLoading || isFetching) && !isFetchingNextPage) && (
        <TableLoadMoreFlex>
          <Button
            label={'Load More'}
            variant={'emptyAction'}
            onClick={() => {
              loadMore();
            }}
          />
        </TableLoadMoreFlex>
      )}
    </>
  );
};

export default ValidationsTable;
