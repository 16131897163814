import { Metadata_Service } from './';
import { useQuery } from '@tanstack/react-query';
import { MaterializationQueryMetricResponse } from '../feature/feature-views/feature-view/component/BatchMaterializationQualityMonitoringPanel/apiTypes';
import {
  FeatureAndMetricMap,
  MaterializationEvent,
  MaterializationQueryFeatureResult,
  MaterializationQueryResult,
} from '../feature/feature-views/feature-view/component/BatchMaterializationQualityMonitoringPanel/types';
import { determineMaxNumberOfEventsToDisplayInUi } from '../feature/feature-views/feature-view/component/BatchMaterializationQualityMonitoringPanel/utils';
import {
  GetMetricAndExpectationDefinitionResponse,
  GetMetricAndExpectationDefinitionRequest,
  GetObservabilityConfigRequest,
  GetObservabilityConfigResponse,
} from '../types/tecton_proto/metadataservice/metadata_service';
import { MaterializationMetricTypes } from '../components/featureViews/DataQualityMetricsTypes';
import { parseMetricTime } from '../components/featureViews/DataQualityMetricsUtils';
import { group } from 'd3-array';

interface QueryMetricRequestType {
  workspace: string;
  featureViewName: string;
  metric_type: string;
  start_time: string | undefined;
  end_time: string;
}

const getMapOfFeatureColumnsToFeatureDataOrder = (results: MaterializationQueryMetricResponse, features: string[]) => {
  const featuresToIndexMap = new Map<string, number>();

  if (results.metric_data) {
    const findMatchingDate = results.metric_data.find((dataForDate) => dataForDate.metric_values);
    if (findMatchingDate) {
      const featuresInMatchingMetricData = findMatchingDate.metric_values.map(
        (matchingMetricValues) => matchingMetricValues.feature_name
      );

      features.forEach((feature) => {
        featuresToIndexMap.set(feature, featuresInMatchingMetricData.indexOf(feature));
      });
    }
  }

  return featuresToIndexMap;
};

const parseFeatureResponse = (results: MaterializationQueryMetricResponse): MaterializationQueryFeatureResult => {
  const arrayToReturn: { name: string; data: MaterializationEvent[] }[] = [];
  const features = results.column_names.filter((columnName) => columnName !== 'joinKey');

  const featuresToIndexMap = getMapOfFeatureColumnsToFeatureDataOrder(results, features);

  const timeResolution = parseMetricTime(results.metric_data_point_interval);
  const maximumRowsToDisplay = determineMaxNumberOfEventsToDisplayInUi(timeResolution);
  const slicedMetricData = results.metric_data?.slice(-maximumRowsToDisplay); // Limits the number of events passed to the UI to be rendered

  features.forEach((feature: string) => {
    if (results.metric_data) {
      const filteredData = slicedMetricData.map((datum) => {
        const matchingIndex = featuresToIndexMap.get(feature);
        return {
          date: new Date(datum.interval_start_time),
          id: datum.materialization_run_id ? datum.materialization_run_id : undefined,
          value:
            datum.metric_values !== undefined &&
            matchingIndex !== undefined &&
            datum.metric_values[matchingIndex] !== undefined
              ? +datum.metric_values[matchingIndex].value
              : 0,
          status: datum.metric_status,
          url: datum.materialization_task_attempt_url,
        };
      });
      arrayToReturn.push({ name: feature, data: filteredData });
    }
  });

  return { apiResponse: results, features: arrayToReturn };
};

// NOT AN ACTUAL UTIL. GOES IN THE QUERY
const parseMetricsAndExpectationsDefinitions = (
  data: GetMetricAndExpectationDefinitionResponse
): FeatureAndMetricMap => {
  const flattenedValues = new Map<string, MaterializationMetricTypes[]>();
  if (data.metrics) {
    const allSplit: { metric: MaterializationMetricTypes; feature: string }[] = [];

    data.metrics.map((metric) => {
      const split = metric.name?.split('__');
      split &&
        split[1] !== 'joinKeys' &&
        allSplit.push({
          metric: split[0].toUpperCase() as MaterializationMetricTypes,
          feature: split[1],
        });
    });

    const groupedValues = group(allSplit, (datum) => datum.feature);

    for (const [feature, rawMetrics] of Array.from(groupedValues)) {
      flattenedValues.set(
        feature,
        rawMetrics.map((metricObject) => metricObject.metric)
      );
    }
  }
  return flattenedValues as FeatureAndMetricMap;
};

const parseFeatureViewResponse = (results: MaterializationQueryMetricResponse): MaterializationQueryResult => {
  let arrayToReturn: MaterializationEvent[] = [];

  if (results.metric_data) {
    const timeResolution = parseMetricTime(results.metric_data_point_interval);
    const maximumRowsToDisplay = determineMaxNumberOfEventsToDisplayInUi(timeResolution);
    const slicedMetricData = results.metric_data.slice(-maximumRowsToDisplay);

    arrayToReturn = slicedMetricData.map((datum) => {
      return {
        date: new Date(datum.interval_start_time),
        id: datum.materialization_run_id,
        url: datum.materialization_task_attempt_url,
        value: datum.metric_values ? +datum.metric_values[0].value : 0,
        status: datum.metric_status,
      };
    });
  }

  return { apiResponse: results, data: arrayToReturn };
};

const fetchMetrics = async (args: QueryMetricRequestType): Promise<MaterializationQueryMetricResponse> => {
  const response = await Metadata_Service('query-metric', { data: args, method: 'POST' });
  return response.data;
};

const fetchMetricsAndExpectation = async (
  args: GetMetricAndExpectationDefinitionRequest
): Promise<GetMetricAndExpectationDefinitionResponse> => {
  const response = await Metadata_Service('get-metric-and-expectation-definition', { data: args, method: 'POST' });
  return response.data;
};

const fetchObervabilityConfig = async (
  args: GetObservabilityConfigRequest
): Promise<GetObservabilityConfigResponse> => {
  const response = await Metadata_Service('get-observability-config', { data: args, method: 'POST' });
  return response.data;
};

export const useGetFeatureViewMetricsQuery = (args: QueryMetricRequestType) => {
  return useQuery(['getFeatureViewsMetrics', args], () => fetchMetrics(args), {
    select: (data: MaterializationQueryMetricResponse): MaterializationQueryResult => {
      return parseFeatureViewResponse(data);
    },
  });
};

export const useGetMetricsForFeaturesQuery = (args: QueryMetricRequestType) => {
  return useQuery(['getMetricsForFeatures', args], () => fetchMetrics(args), {
    select: (data: MaterializationQueryMetricResponse): MaterializationQueryFeatureResult => {
      return parseFeatureResponse(data);
    },
  });
};
export const useGetMetricsAndExpectationsDefinitionsQuery = (args: GetMetricAndExpectationDefinitionRequest) => {
  return useQuery(['getMetricsAndExpectationsDefinitions', args], () => fetchMetricsAndExpectation(args), {
    select: (data: GetMetricAndExpectationDefinitionResponse): FeatureAndMetricMap => {
      return parseMetricsAndExpectationsDefinitions(data);
    },
  });
};
export const useGetObservabilityConfigQuery = (args: GetObservabilityConfigRequest) => {
  return useQuery(['getObervabilityConfig', args], () => fetchObervabilityConfig(args), {
    select: (data: GetObservabilityConfigResponse): boolean | undefined => {
      return data.is_dataobs_metric_enabled;
    },
  });
};
