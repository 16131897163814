import React, { FC } from 'react';
import { usePrometheus } from '../../../query';
import { getFeatureHealthStateIds } from '../../../graph-util';
import _ from 'lodash';
import FeatureViewIdsTable from './FeatureViewIdsTable';
import { MonitoringDateRange } from '../../../../components/monitoring/DateRange-Dropdown-util';

interface FeatureViewHealthDrillDownsProps {
  monitoringDateRange: MonitoringDateRange;
}

const FeatureViewHealthDrillDowns: FC<FeatureViewHealthDrillDownsProps> = ({ monitoringDateRange }) => {
  const queries = [
    {
      label: 'Stale IDs',
      query: `count by (tecton_cluster, feature_package_id) (ALERTS{alertstate='firing', alertname='FeatureViewHighStaleness', tecton_cluster=~'.*', aws_region=~'.*'})`,
    },
  ];

  const state = usePrometheus(queries, monitoringDateRange);
  if (state.isLoading) {
    return <>Loading...</>;
  }

  const result = _.get(state, 'data');
  const staleIds = getFeatureHealthStateIds(result ? result : []);

  return <FeatureViewIdsTable columnLabel="Stale Feature View IDs" featureViewIds={staleIds} />;
};

export default FeatureViewHealthDrillDowns;
