import React, { FC } from 'react';
import { Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

interface FeatureViewIdsTableProps {
  columnLabel: string;
  featureViewIds: string[];
}

const FeatureViewIdsTable: FC<FeatureViewIdsTableProps> = ({ columnLabel, featureViewIds }) => {
  // empty state
  if (featureViewIds.length === 0) {
    return (
      <Paper square style={{ minHeight: 250 }} className="pa4">
        <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" textAlign={'center'}>
          No data to show.
        </Grid>
      </Paper>
    );
  }

  return (
    <div style={{ overflow: 'auto' }}>
      <Table>
        <TableHead>
          <TableRow
            style={{
              backgroundColor: '#f5f5f5',
              height: '35px',
            }}
          >
            <TableCell>
              {columnLabel} ({featureViewIds.length})
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
      </Table>
      <div style={{ overflow: 'auto', height: 250 }}>
        <Table style={{ tableLayout: 'fixed' }}>
          <TableBody>
            {featureViewIds.map((n, i) => {
              return (
                <TableRow key={i}>
                  <TableCell>{n}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default FeatureViewIdsTable;
