import { timeWeek } from 'd3-time';
import { ValidationsDefaultStatuses, ValidationsFilterState, defaultPaginationState } from './types';
import { ExpectationResultEnum } from '../../../types/tecton_proto/dataobs/validation';
import { PaginationRequest } from '../../../types/tecton_proto/metadataservice/metadata_service';

export default false;

export const parseURLParametersForFilterState: (rawParameters: URLSearchParams) => ValidationsFilterState = (
  rawParameters
) => {
  return {
    featureViews: parseStringArrayFromURLParameters(rawParameters.get('featureViews')),
    expectations: parseStringArrayFromURLParameters(rawParameters.get('expectations')),
    statuses: parseStatuses(rawParameters.get('statuses')),
    startDate: parseTimeFromURL(rawParameters.get('startDate'), timeWeek.offset(new Date(), -2)),
    endDate: parseTimeFromURL(rawParameters.get('endDate'), new Date()),
  };
};

export const parseStatuses: (rawParams: string | null) => ExpectationResultEnum[] = (rawParams) => {
  if (rawParams === null) {
    return ValidationsDefaultStatuses;
  }

  try {
    const asJson = JSON.parse(rawParams) as ExpectationResultEnum[];
    return asJson;
  } catch {
    return [];
  }
};

export const parseStringArrayFromURLParameters: (rawParams: string | null) => string[] = (rawParams) => {
  if (rawParams === null) {
    return [];
  }

  try {
    const asJson = JSON.parse(rawParams) as string[];
    return asJson;
  } catch {
    return [];
  }
  return [];
};

export const parseTimeFromURL: (time: string | null, defaultValue: Date) => Date = (time, defaultValue) => {
  if (!time) {
    return defaultValue;
  }

  const checkForDate = new Date(parseInt(time));

  if (isNaN(checkForDate.getTime())) {
    // Check to ensure that there was a valid date in the URL parameters
    return defaultValue;
  }

  return checkForDate;
};

export const parseURLParametersForPaginationState: (rawParameters: URLSearchParams) => PaginationRequest = (
  rawParameters
) => {
  return {
    perPage: parseNumberFromURL(rawParameters.get('perPage'), defaultPaginationState.perPage),
    page: parseNumberFromURL(rawParameters.get('page'), defaultPaginationState.page),
  };
};

export const parseNumberFromURL: (parameter: string | null, defaultValue: number) => number = (
  parameter,
  defaultValue
) => {
  if (!parameter) {
    return defaultValue;
  }
  return parseInt(parameter);
};

// 4/11/2023: TODO: Check if there's already a mechanism for this.
// Otherwise, it might make sense to move it to higher-level utils
export const hexToRGBA = (hex: string, alpha: number) => {
  const r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
  } else {
    return 'rgb(' + r + ', ' + g + ', ' + b + ')';
  }
};
