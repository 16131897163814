import { ReactNode } from 'react';

import { TaskType, TaskTypeForDisplay } from '../../../types/tecton_proto/materialization/spark_cluster';

import { FilterOptionType } from '@tecton/CategoricalSelector';
import { Moment } from 'moment-timezone';
import { GetJobsRequest, MaterializationStatusState, PaginationRequest } from '../../../api/gql/graphql';
import { MaterializationAttemptStatus } from '../../../types/tecton_proto/data/materialization_status';
import {
  TectonManagedStage_StageType,
  TectonManagedStage_State,
} from '../../../types/tecton_proto/materialization/job_metadata';
import {
  CountRange,
  DurationRange,
  TaskWithAttempts,
} from '../../../types/tecton_proto/metadataservice/metadata_service';

export interface JobsContextProviderProps {
  children: ReactNode;
  feature_view?: string;
  workspace?: string;
}
export type JobStatusInput = MaterializationStatusState | string | undefined;
export type JobTaskTypeInput = TaskType | string | undefined;
export interface JobStatusValue {
  label: string;
  color?: string;
  backgroundColor?: string;
  value?: string;
  checked?: 'on' | 'off' | undefined;
}
export interface JobsTaskTypeValue {
  label: string;
  color?: string;
  backgroundColor?: string;
  value?: string;
  checked?: 'on' | 'off' | undefined;
}

export interface JobsDurationOptionTypeValue {
  label: string;
  value?: { start?: string; end?: string };
  checked?: 'on' | 'off' | undefined;
}

export interface JobsNumAttemptsOptionType {
  label: string;
  value?: [number, number];
  checked?: 'on' | 'off' | undefined;
}

export enum TaskTypeOmitted {
  BATCH = TaskType.BATCH,
  STREAMING = TaskType.STREAMING,
  INGEST = TaskType.INGEST,
  DELETION = TaskType.DELETION,
  //DELTA_MAINTENANCE = TaskType.DELTA_MAINTENANCE,
  FEATURE_EXPORT = TaskType.FEATURE_EXPORT,
  DATASET_GENERATION = TaskType.DATASET_GENERATION,
  PLAN_INTEGRATION_TEST_BATCH = TaskType.PLAN_INTEGRATION_TEST_BATCH,
  PLAN_INTEGRATION_TEST_STREAM = TaskType.PLAN_INTEGRATION_TEST_STREAM,
}

export enum TaskTypeForDisplayOmitted {
  STREAMING_JOB = TaskTypeForDisplay.STREAMING_JOB,
  BATCH_JOB = TaskTypeForDisplay.BATCH_JOB,
  INGEST_JOB = TaskTypeForDisplay.INGEST_JOB,
  DELETION_JOB = TaskTypeForDisplay.DELETION_JOB,
  DELTA_MAINTENANCE_JOB = TaskTypeForDisplay.DELTA_MAINTENANCE_JOB,
  FEATURE_EXPORT_JOB = TaskTypeForDisplay.FEATURE_EXPORT_JOB,
  DATASET_GENERATION_JOB = TaskTypeForDisplay.DATASET_GENERATION_JOB,
  PLAN_INTEGRATION_TEST_BATCH_JOB = TaskTypeForDisplay.PLAN_INTEGRATION_TEST_BATCH_JOB,
  PLAN_INTEGRATION_TEST_STREAM_JOB = TaskTypeForDisplay.PLAN_INTEGRATION_TEST_STREAM_JOB,
  COMPACTION_JOB = TaskTypeForDisplay.COMPACTION_JOB,
}

export type TransformedMaterializationAttemptStatus = Omit<MaterializationAttemptStatus, 'materializationState'> & {
  materializationState?: JobStatusValue | undefined | string;
  tectonManagedAttemptId?: string;
};

export type MomentDateTime = { start: Moment; end: Moment };
export type MomentDateTimeKeys = 'lastTaskStateChange' | 'featureViewStart' | 'featureViewEnd';
export interface updateCategoricalSelectorStateProps {
  prop: keyof JobsSelectorProps;
  items: FilterOptionType[];
  isolate?: boolean;
  stateOnly?: boolean;
  autoTriggered?: boolean;
}
export interface updateCountsSelectorStateProps {
  prop: keyof JobsSelectorProps;
  items: FilterOptionType[];
  range: [number, number];
  max: number;
  stateOnly?: boolean;
}
export interface JobsContextProps {
  jobsData: TaskWithAttempts[];
  jobsLoading: boolean;
  jobsError: boolean;
  updateDateFilter: (start: Moment) => void;
  isComboFilterOpen: boolean;
  setComboFilterOpen: (open: boolean) => void;
  selectedFilter: string | undefined;
  setSelectedFilter: (filter: string | undefined) => void;
  startDate: Moment;
  setStartDate: (start: Moment) => void;
  endDate: Moment;
  setEndDate: (end: Moment) => void;
  itemIdToExpandedRowMap: Record<string, ReactNode>;
  setMapOfExpandedItems: (map: Record<string, ReactNode>) => void;
  collapseItem: (rowId: string) => void;
  expandItemWithContent: (rowId: string, content: ReactNode) => void;
  resetPageIndexToZero: () => void;
  workspaces: SelectableLabelOption[];
  setWorkspaces: (options: SelectableLabelOption[]) => void;
  featureViews: SelectableLabelOption[];
  setFeatureViews: (options: SelectableLabelOption[]) => void;
  fvTypes: SelectableLabelOption[];
  setfvTypes: (options: SelectableLabelOption[]) => void;
  triggerTypes: SelectableLabelOption[];
  setTriggerTypes: (options: SelectableLabelOption[]) => void;
  jobStatusItems: SelectableLabelOption[];
  setJobStatusItems: (options: SelectableLabelOption[]) => void;
}
interface SelectableLabelOption {
  label: string;
}
export type MomentRange = { start: Moment | string; end: Moment | string };
export type FilterProp = keyof GetJobsRequest | keyof JobsSelectorProps;
export type DurationQuery = { start: string; end?: string };

export type PayloadProp =
  | string
  | string[]
  | boolean
  | boolean[]
  | FilterOptionType[]
  | number
  | [number, number]
  | Moment
  | CountRange
  | DurationRange
  | DurationQuery
  | MomentRange
  | undefined;

export type MaterializationTerminationReasons =
  | 'MANUAL_CANCELATION'
  | 'JOB_FINISHED'
  | 'NON_CLOUD_FAILURE'
  | 'INSTANCE_ALLOCATION_FAILURE'
  | 'UNKNOWN_TERMINATION_REASON';

export type FV_TYPE = 'stream' | 'batch' | 'ingest' | 'deletion' | undefined;

//JobsUtils
export interface JobsSelectorProps {
  workspaces: FilterOptionType[];
  featureViews: FilterOptionType[];
  taskType: FilterOptionType[];
  taskTypeForDisplays: FilterOptionType[];
  manuallyTriggered: FilterOptionType[];
  statuses: FilterOptionType[];
  numAttempts: FilterOptionType[];
  lastTaskStateChange: MomentDateTime;
  writesOnline: FilterOptionType[];
  writesOffline: FilterOptionType[];
  duration: FilterOptionType[];
  featureViewStart: MomentDateTime;
  featureViewEnd: MomentDateTime;
}
export type SelectedFilterType = string | undefined;

export interface JobsFilterStateProps {
  selectorState: JobsSelectorProps;
  queryState: GetJobsRequest;
}

export type JobsDefaultProp = keyof JobsSelectorProps | keyof GetJobsRequest | keyof PaginationRequest;

export interface UpdateDefaultsProps {
  defaults: JobsFilterStateProps;
  param: string;
  selector: PayloadProp;
  query: PayloadProp;
}

// Once the protos are created, we should remove these types
// export type JobAttemptStageTypes = 'SNOWFLAKE' | 'PYTHON' | 'AGGREGATE' | 'OFFLINE_STORE' | 'ONLINE_STORE';
// export type JobAttemptStageStates = 'PENDING' | 'RUNNING' | 'SUCCESS' | 'ERROR' | 'CANCELLED' | 'STATE_UNSPECIFIED';

export type StageTypes = `${TectonManagedStage_StageType}`;
export type TectonManageState = `${TectonManagedStage_State}`;

export interface StageEntryInterface {
  type: TectonManagedStage_StageType;
  state: TectonManagedStage_State;
  progress?: number;
  description: string;
  externalLink?: string;
  compiledQuery?: string;
  errorType?: string;
  errorDetail?: string;
}

export enum FilterCountsOptions {
  ZERO = '0',
  ONE = '1',
  TWO = '2',
  THREE_TO_FIVE = '3 - 5',
  SIX_TO_NINE = '6 - 9',
  MORE_THAN_TEN = '> 10',
}
