import React, { useRef } from 'react';
import Select, { components } from 'react-select';
import _get from 'lodash/get';
import { useSavedFeatureDataFrames } from '../feature/datasets/query';
import { FirstClassObjectTypes, FirstClassObject } from './FirstClassObject';
import { Routes } from '../core/routes';
import { useNavigate } from 'react-router-dom';
import { configure, GlobalHotKeys } from 'react-hotkeys';
import UrlUtils from '../utils/url-utils';
import IdUtils from '../utils/id-utils';
import { filterOption } from '../utils/global-search-utils';
import { useFcoContainer } from '../feature/query';
import { useUserSettings } from './context/UserSettingsContext';

configure({
  ignoreTags: ['input', 'select', 'textarea'],
  ignoreEventsCondition: function () {},
});

const GlobalSearch = () => {
  const { workspace } = useUserSettings();
  const savedFeatureDataFrames = useSavedFeatureDataFrames(workspace).data;
  const fcoContainer = useFcoContainer(workspace);
  const navigate = useNavigate();
  const selectComponentRef = useRef(null);

  if (fcoContainer.isLoading) {
    return <></>;
  }

  const entities = fcoContainer.data.allEntities;
  const dataSources = fcoContainer.data.allVirtualDataSources;
  const featureServices = fcoContainer.data.allFeatureServices;
  const featureViews = fcoContainer.data.allFeatureViews;
  const transformations = fcoContainer.data.allTransformation;

  const createSearchableLabel = (fco) => {
    return fco.name + ' ' + fco.description;
  };

  const options = [
    {
      label: 'Sources',
      options: (dataSources || []).map((ds) => {
        return {
          fco: ds,
          fcoType: FirstClassObjectTypes.DATA_SOURCE,
          path: Routes.dataSource,
          label: createSearchableLabel(ds),
        };
      }),
    },
    {
      label: 'Entities',
      options: (entities || []).map((entity) => {
        return {
          fco: entity,
          fcoType: FirstClassObjectTypes.ENTITY,
          path: Routes.entity,
          label: createSearchableLabel(entity),
        };
      }),
    },
    {
      label: 'Transformations',
      options: (transformations || []).map((transform) => {
        return {
          fco: transform,
          fcoType: FirstClassObjectTypes.TRANSFORMATION,
          path: Routes.transformation,
          label: createSearchableLabel(transform),
        };
      }),
    },
    {
      label: 'Feature Views and Tables',
      options: (featureViews || []).map((fv) => {
        return {
          fco: fv,
          fcoType: FirstClassObjectTypes.FEATURE_VIEW,
          path: Routes.featureView,
          label: createSearchableLabel(fv),
          search_id: IdUtils.toStringId(_get(fv, 'proto.feature_view_id')),
        };
      }),
    },
    {
      label: 'Feature Services',
      options: (featureServices || []).map((fs) => {
        return {
          fco: fs,
          fcoType: FirstClassObjectTypes.FEATURE_SERVICE,
          path: Routes.featureService,
          label: createSearchableLabel(fs),
          search_id: IdUtils.toStringId(_get(fs, 'proto.feature_service_id')),
        };
      }),
    },
    {
      label: 'Datasets',
      options: (savedFeatureDataFrames || []).map((df) => {
        return {
          fco: df,
          fcoType: FirstClassObjectTypes.SAVED_FEATURE_DATAFRAME,
          path: Routes.savedFeatureDataFrame,
          label: createSearchableLabel(df),
        };
      }),
    },
  ];

  const handleChange = (option) => {
    const path = UrlUtils.buildPath(option.path, {
      name: option.fco.name,
      workspace: workspace,
      tab: null,
    });

    navigate(path);
    document.activeElement.blur();
  };

  const keyMap = {
    GLOBAL_SEARCH: 'command+p',
  };

  const handlers = {
    GLOBAL_SEARCH: (event) => {
      event.preventDefault();
      selectComponentRef.current.select.focus();
    },
  };

  const customStyles = {
    input: (provided, { isFocused }) => ({
      ...provided,
      color: isFocused ? '#b6c5cf' : '#022738',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: 'b6c5cf',
    }),
    option: (provided, { data, isDisabled, isFocused, isSelected }) => {
      let activeBackground = '#b6c5cf';
      let backgroundColor = 'white';

      if (isFocused) {
        backgroundColor = '#F4F9FE';
      } else if (isSelected) {
        backgroundColor = '#f2f7fa';
      } else if (isDisabled) {
        backgroundColor = '#f2f7fa';
      } else {
        //shouldn't get here
      }

      return {
        ...provided,
        color: 'rgb(2, 39, 56)',
        backgroundColor: backgroundColor,
        '&:active': {
          ...provided[':active'],
          backgroundColor: activeBackground,
        },
      };
    },
    indicatorSeparator: (provided, state) => ({
      border: 'none',
    }),
    control: (provided, { data, isDisabled, isFocused, isSelected }) => ({
      height: '47px',
      flex: 'none',
      display: 'flex',
      'align-items': 'center',
      cursor: 'pointer',
      background: isFocused ? 'white' : '#263549',
      color: isFocused ? '#022738' : '#b6c5cf',
      'padding-left': '12px',
      'font-size': '16px',
      'font-family': 'Inter',
    }),
    placeholder: (provided, { isFocused }) => ({
      ...provided,
      color: isFocused ? '#022738' : '#b6c5cf',
    }),
    groupHeading: (provided, state) => ({
      ...provided,
      color: '#8f9cac',
      backgroundColor: '#f2f7fa',
    }),
    menu: (provided, state) => ({
      ...provided,
      backgroundColor: '#f2f7fa',
      color: '#022738',
      fontSize: '13px',
      borderRadius: 0,
      'font-family': 'Inter',
      top: '38px',
      'z-index': 100,
    }),
  };

  const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    textAlign: 'center',
    top: '50%',
    left: '0px',
    transform: 'translate(-0%,-40%)',
    fontWeight: '800',
  };

  const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
  };

  const formatGroupLabel = (data) => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  );

  const ControlComponent = (props) => {
    if (props.isFocused) {
      return (
        <components.Control {...props}>
          <components.ValueContainer {...props} />
        </components.Control>
      );
    } else {
      return (
        <components.Control {...props}>
          <components.ValueContainer {...props} />
        </components.Control>
      );
    }
  };

  const OptionComponent = (props) => {
    const getOptionStyles = (defaultStyles) => ({
      ...defaultStyles,
      // 'z-index': 10000
    });

    const { data, getStyles, innerRef, innerProps } = props;
    const defaultStyles = getStyles('option', props);
    const styles = getOptionStyles(defaultStyles);
    return (
      <div {...innerProps} style={styles} ref={innerRef}>
        <FirstClassObject
          name={data.fco.name}
          description={data.fco.description}
          type={data.fcoType}
          path={data.path}
          workspace={workspace}
        />
      </div>
    );
  };

  return (
    <>
      <GlobalHotKeys keyMap={keyMap} handlers={handlers} />
      <Select
        value=""
        placeholder="Search..."
        isSearchable={true}
        filterOption={filterOption}
        options={options}
        styles={customStyles}
        onChange={handleChange}
        formatGroupLabel={formatGroupLabel}
        components={{ Control: ControlComponent, Option: OptionComponent }}
        maxMenuHeight={600}
        ref={selectComponentRef}
        aria-label={'global search bar'}
        role="searchbox"
      />
    </>
  );
};

export default GlobalSearch;
