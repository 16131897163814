import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { ComponentLifecycleState } from './Lifecycle';

const fromState = `opacity:0;
       backdrop-filter:blur(0px);
`;

const toState = `opacity: 1;
      backdrop-filter:blur(10px);
`;

const fadeAndBlurIn = keyframes`
   from {${fromState}}
   to {${toState}}
`;

const fadeAndBlurOut = keyframes`
   from {${toState}}
   to {${fromState}}

`;

const Mask = styled.div<{ lifecycleState: ComponentLifecycleState }>`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.v1.colors.background.mask};
  backdrop-filter: blur(10px);
  animation: ${({ theme, lifecycleState }) => {
    if (lifecycleState === 'entering') {
      return css`
        ${fadeAndBlurIn} ${theme.v1.motion.duration.fast} linear forwards
      `;
    }

    if (lifecycleState === 'exiting') {
      return css`
        ${fadeAndBlurOut} ${theme.v1.motion.duration.xfast} ${theme.v1.motion.easing.exit} forwards
      `;
    }
  }};
  z-index: 0;
`;

export default Mask;
