import React, { FC } from 'react';

import { ReactComponent as Card } from '../Illustrations/Card.svg';
import { ReactComponent as CardDark } from '../Illustrations/CardDark.svg';
import { ReactComponent as CardError } from '../Illustrations/CardError.svg';
import { ReactComponent as Dataflow } from '../Illustrations/Dataflow.svg';
import { ReactComponent as DataflowDark } from '../Illustrations/DataflowDark.svg';
import { ReactComponent as DataflowError } from '../Illustrations/DataflowError.svg';
import { ReactComponent as EmptySearch } from '../Illustrations/EmptySearch.svg';
import { ReactComponent as EmptySearchDark } from '../Illustrations/EmptySearchDark.svg';
import { ReactComponent as EmptyTable } from '../Illustrations/EmptyTable.svg';
import { ReactComponent as EmptyTableDark } from '../Illustrations/EmptyTableDark.svg';
import { ReactComponent as EmptyTableError } from '../Illustrations/EmptyTableError.svg';
import { ReactComponent as Issue } from '../Illustrations/Issue.svg';
import { ReactComponent as IssueDark } from '../Illustrations/IssueDark.svg';
import { ReactComponent as IssueError } from '../Illustrations/IssueError.svg';
import { ReactComponent as NoResults } from '../Illustrations/NoResults.svg';
import { ReactComponent as NoResultsDark } from '../Illustrations/NoResultsDark.svg';
import { ReactComponent as NoResultsError } from '../Illustrations/NoResultsError.svg';
import { ReactComponent as WalletEmpty } from '../Illustrations/WalletEmpty.svg';
import { ReactComponent as WalletEmptyDark } from '../Illustrations/WalletEmptyDark.svg';
import { ReactComponent as WalletEmptyError } from '../Illustrations/WalletEmptyError.svg';

import { ReactComponent as CardErrorDark } from '../Illustrations/CardErrorDark.svg';
import { ReactComponent as DataflowErrorDark } from '../Illustrations/DataflowErrorDark.svg';
import { ReactComponent as EmptySearchErrorDark } from '../Illustrations/EmptySearchDark.svg';
import { ReactComponent as EmptyTableErrorDark } from '../Illustrations/EmptyTableErrorDark.svg';
import { ReactComponent as IssueErrorDark } from '../Illustrations/IssueErrorDark.svg';
import { ReactComponent as NoResultsErrorDark } from '../Illustrations/NoResultsErrorDark.svg';
import { ReactComponent as WalletEmptyErrorDark } from '../Illustrations/WalletEmptyErrorDark.svg';

import { useTectonTheme } from '../../Theme/ThemeProvider';

// Names must be unique between icons and illustrations, so appending 'Illustration' here
export type IllustrationType =
  | 'CardIllustration'
  | 'DataflowIllustration'
  | 'EmptySearchIllustration'
  | 'EmptyTableIllustration'
  | 'IssueIllustration'
  | 'NoResultsIllustration'
  | 'WalletEmptyIllustration';

interface IllustrationProps {
  illustration: IllustrationType;
  error?: boolean;
}

const IllustrationTypeMap: Record<'light' | 'dark', Record<IllustrationType, React.ReactNode>> = {
  light: {
    CardIllustration: <Card />,
    DataflowIllustration: <Dataflow />,
    EmptySearchIllustration: <EmptySearch />,
    EmptyTableIllustration: <EmptyTable />,
    IssueIllustration: <Issue />,
    NoResultsIllustration: <NoResults />,
    WalletEmptyIllustration: <WalletEmpty />,
  },
  dark: {
    CardIllustration: <CardDark />,
    DataflowIllustration: <DataflowDark />,
    EmptySearchIllustration: <EmptySearchDark />,
    EmptyTableIllustration: <EmptyTableDark />,
    IssueIllustration: <IssueDark />,
    NoResultsIllustration: <NoResultsDark />,
    WalletEmptyIllustration: <WalletEmptyDark />,
  },
};

const IllustrationTypeMapError: Record<'light' | 'dark', Record<IllustrationType, React.ReactNode>> = {
  light: {
    CardIllustration: <CardError />,
    DataflowIllustration: <DataflowError />,
    EmptySearchIllustration: <EmptySearch />,
    EmptyTableIllustration: <EmptyTableError />,
    IssueIllustration: <IssueError />,
    NoResultsIllustration: <NoResultsError />,
    WalletEmptyIllustration: <WalletEmptyError />,
  },
  dark: {
    CardIllustration: <CardErrorDark />,
    DataflowIllustration: <DataflowErrorDark />,
    EmptySearchIllustration: <EmptySearchErrorDark />,
    EmptyTableIllustration: <EmptyTableErrorDark />,
    IssueIllustration: <IssueErrorDark />,
    NoResultsIllustration: <NoResultsErrorDark />,
    WalletEmptyIllustration: <WalletEmptyErrorDark />,
  },
};

const Illustration: FC<IllustrationProps> = ({ illustration, error = false }) => {
  const { theme } = useTectonTheme();

  if (error) {
    return <>{IllustrationTypeMapError[theme.v1.mode][illustration]}</>;
  }

  return <>{IllustrationTypeMap[theme.v1.mode][illustration]}</>;
};

export default Illustration;
