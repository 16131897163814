import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { useParams } from 'react-router';

import { useUserSettings } from '../../context/UserSettingsContext';
import {
  useDebugFeatureViewById,
  useFeatureViewMaterializationStatus,
} from '../../../feature/feature-views/feature-view/query';
import { logEvent } from '../../../utils/analytics-utils';
import { Card, Code, EmptyPrompt, FlexGrid, FlexGroup, FlexItem } from '@tecton/ComponentRedesign';

export const DebugWrapper = styled.div`
  padding: 24px;
  background-color: #fff;
`;

const FeatureViewDebug = () => {
  const { updateWorkspace } = useUserSettings();
  const { workspace, id } = useParams();
  const fvState = useDebugFeatureViewById(workspace, id);

  useEffect(() => {
    logEvent('Redesign: View FeatureViewDebug');
  }, []);

  if (workspace) {
    updateWorkspace(workspace);
  }

  const materializationStatusState = useFeatureViewMaterializationStatus(id, workspace, true);

  if (fvState.isLoading || materializationStatusState.isLoading) {
    return <EmptyPrompt title="Loading" body={''} variant="loading" />;
  }

  const fv = fvState.data;
  const materializationStatus = materializationStatusState.data;

  if (!fv) {
    return (
      <FlexGroup alignItems="center">
        <FlexItem>
          <EmptyPrompt variant="search" title={`Feature view '${id}' not found`} body={''} />
        </FlexItem>
      </FlexGroup>
    );
  }

  const fvJson = JSON.stringify(fv._proto, null, 2);
  const materializationStatusJson = JSON.stringify(materializationStatus, null, 2);

  return (
    <DebugWrapper>
      <FlexGrid gutterSize="l" columns={1}>
        <FlexItem>
          <Card title={'Proto'}>
            <Code language="json" code={fvJson}></Code>
          </Card>
        </FlexItem>
        <FlexItem>
          <Card title={'Materialization Status'}>
            <Code language="json" code={materializationStatusJson}></Code>
          </Card>
        </FlexItem>
      </FlexGrid>
    </DebugWrapper>
  );
};

export default FeatureViewDebug;
