export enum DATABRICKS_LINKS {
  AWS_GLUE_METASTORE = 'https://docs.databricks.com/data/metastores/aws-glue-metastore.html',
  AWS_GLUE_METASTORE_STEP_5 = 'https://docs.databricks.com/data/metastores/aws-glue-metastore.html#step-5-add-the-glue-catalog-instance-profile-to-a-databricks-workspace',
  CLUSTER_ACL = 'https://docs.databricks.com/administration-guide/access-control/cluster-acl.html#configure-cluster-creation-entitlement',
}

export enum TECTON_DOCUMENTATION_LINKS {
  FEATURE_SERVER_PRODUCTION_SLO = 'https://docs.tecton.ai/docs/running-in-production/production-slos',
  CONNECTING_DATA_SOURCES_HIVE = 'https://docs.tecton.ai/docs/setting-up-tecton/connecting-data-sources/connect-data-sources-to-spark/connecting-data',
  QUICK_START = 'https://docs.tecton.ai/docs/tutorials/tecton-quick-start',
  DATASETS = 'https://docs.tecton.ai/docs/reading-feature-data/reading-feature-data-for-training/creating-and-managing-datasets',
  ENTITIES = 'https://docs.tecton.ai/docs/defining-features/entities',
  DATA_QUALITY_METRICS = `https://docs.tecton.ai/docs/monitoring/data-quality-metrics`,
  FEATURE_VIEWS = 'https://docs.tecton.ai/docs/defining-features/feature-views',
  DATA_SOURCES = 'https://docs.tecton.ai/docs/defining-features/data-sources',
  TRANSFORMATIONS = 'https://docs.tecton.ai/docs/defining-features/feature-views/transformations',
  MONITORING_AND_DEBUGGING_ONLINE_SERVING = 'https://docs.tecton.ai/docs/beta/monitoring/monitoring-and-debugging-online-serving',
}
