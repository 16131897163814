import {
  DatasetFCO,
  DatasetFCOFields,
  DatasetFCOSchemaItem,
  DatasetFCOType,
  FCOFields,
  FCOType,
} from '../../core/types/fcoTypes';
import { SavedFeatureDataFrame as DatasetProto } from '../../types/tecton_proto/data/saved_feature_data_frame';
import IdUtils from '../../utils/id-utils';

export const transformDataSetProtoToDatasetFCO: (datasetProto: DatasetProto) => DatasetFCO = (datasetProto) => {
  const asDatasetFco: DatasetFCO = {
    [DatasetFCOFields.FEATURE_VIEW_ID]: getFeatureViewIdFromProto(datasetProto),
    [DatasetFCOFields.FEATURE_SERVICE_ID]: getFeatureServiceIdFromProto(datasetProto),
    [DatasetFCOFields.FEATURE_VIEW_NAME]: getFeatureViewNameFromProto(datasetProto),
    [DatasetFCOFields.FEATURE_SERVICE_NAME]: getFeatureServiceNameFromProto(datasetProto),
    [DatasetFCOFields.TYPE]: getDatasetTypeFromProto(datasetProto),
    [DatasetFCOFields.SCHEMA]: getSchemaFromProto(datasetProto),
    [DatasetFCOFields.UNIFIED_SCHEMA]: getUnifiedSchemaFromProto(datasetProto),
    [DatasetFCOFields.STATE_UPDATE_ENTRY_COMMIT_ID]: getStateUpdateEntryCommitId(datasetProto),
    [FCOFields.ID]: IdUtils.toStringId(datasetProto.saved_feature_dataframe_id),
    [FCOFields.NAME]: datasetProto.info?.name ?? '',
    [FCOFields.DESCRIPTION]: '',
    [FCOFields.TRUNCATED_DESCRIPTION]: '',
    [FCOFields.TAGS]: [],
    [FCOFields.FCO_TYPE]: FCOType.DATASET,
    [FCOFields.CREATED_DATE]: new Date(datasetProto.info!.created_at!),
    [FCOFields.OWNER]: undefined,
    [FCOFields.LAST_MODIFIED_BY]: undefined,
    [FCOFields.LAST_MODIFIED_DATE]: undefined,
    [FCOFields.WORKSPACE]: datasetProto.info?.workspace,
    [FCOFields.SOURCE_FILE_NAME]: undefined,
    [FCOFields.PREVENT_DESTROY]: undefined,
  };

  return asDatasetFco;
};

// Class Replication Methods
// NOTE: A lot of these are so simple that they don't really need separate methods
// However, I'm doing it this way to ease the transition from the old class version (service/SavedFeatureDataFrame.ts) to the new interface version
// We can definitely simplify this later if we want.

const getUnifiedSchemaFromProto: (proto: DatasetProto) => DatasetFCOSchemaItem[] | undefined = (proto) => {
  if (proto.unified_schema && proto.unified_schema.columns) {
    return proto.unified_schema.columns
      .map((item) => {
        if (item.offline_data_type) {
          const asSchemaItem: DatasetFCOSchemaItem = {
            name: item.name ?? '',
            type: item.offline_data_type.type ?? '',
          };
          return asSchemaItem;
        }
      })
      .filter((item) => item) as DatasetFCOSchemaItem[];
  }

  return undefined;
};

const getFeatureViewIdFromProto: (proto: DatasetProto) => string | undefined = (proto) => {
  if (proto.feature_package_id) {
    return IdUtils.toStringId(proto.feature_package_id);
  }
  return undefined;
};

const getFeatureServiceIdFromProto: (proto: DatasetProto) => string | undefined = (proto) => {
  if (proto.feature_service_id) {
    return IdUtils.toStringId(proto.feature_service_id);
  }
  return undefined;
};

const getFeatureViewNameFromProto: (proto: DatasetProto) => string | undefined = (proto) => {
  return proto.feature_package_name ?? undefined;
};
const getFeatureServiceNameFromProto: (proto: DatasetProto) => string | undefined = (proto) => {
  return proto.feature_service_name ?? undefined;
};

const getStateUpdateEntryCommitId: (proto: DatasetProto) => string | undefined = (proto) => {
  return proto.state_update_entry_commit_id;
};

const getSchemaFromProto: (proto: DatasetProto) => DatasetFCOSchemaItem[] = (proto) => {
  if (!proto.schema || !proto.schema.fields) {
    return [];
  }

  const reshapedSchema: DatasetFCOSchemaItem[] = proto.schema.fields.map((protoField) => {
    let finalTypeForSchema;
    let finalNameForSchema = protoField.name;

    if (protoField.structfield_json) {
      const parsedSparkJson = JSON.parse(protoField.structfield_json);
      if (parsedSparkJson.type === 'object') {
        finalTypeForSchema = parsedSparkJson.elementType + '_' + parsedSparkJson.type;
      } else {
        finalTypeForSchema = parsedSparkJson.type;
      }
    }

    if (Object.keys(finalTypeForSchema).includes('type')) {
      finalTypeForSchema = finalTypeForSchema.type;
      finalNameForSchema = 'type';
    }

    return {
      name: finalNameForSchema,
      type: finalTypeForSchema,
    } as DatasetFCOSchemaItem;
  });

  return reshapedSchema;
};

const getDatasetTypeFromProto: (proto: DatasetProto) => DatasetFCOType = (proto) => {
  if (proto.type === 'LOGGED') {
    return DatasetFCOType.LOGGED;
  }

  return DatasetFCOType.SAVED;
};
