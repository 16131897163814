import { useContext } from 'react';
import { DataFlowFocusContext } from './DataFlowFocusProvider';
import { DataFlowEdge, DataFlowTemplateType, PathDataflowType } from './dataFlowTypes';
import { DetailsPanelContext } from './DetailsPanelFocusProvider';
import DataflowLegend from './VisualPrimitives/DataflowLegend';

const LegendContainer = ({
  edgesList,
  isFcoLevelDiagram,
  templateType,
}: {
  edgesList: DataFlowEdge[];
  isFcoLevelDiagram?: boolean;
  templateType: DataFlowTemplateType;
}) => {
  const { setDataflowMode, setLegendInFocus } = useContext(DataFlowFocusContext);
  const { reduceAnimation } = useContext(DetailsPanelContext);

  const setLegendDataflowMode = (pathTypes: PathDataflowType[] | undefined) => {
    if (pathTypes !== undefined) {
      setDataflowMode(pathTypes);
      setLegendInFocus(true);
    } else {
      setDataflowMode(undefined);
      setLegendInFocus(false);
    }
  };

  return (
    <DataflowLegend
      edgesList={edgesList}
      setDataflowMode={setLegendDataflowMode}
      reduceAnimation={reduceAnimation}
      templateType={templateType}
      isFcoLevelDiagram={isFcoLevelDiagram}
    />
  );
};

export default LegendContainer;
