/* eslint-disable */

export const protobufPackage = "tecton_proto.common";

export enum BatchComputeMode {
  BATCH_COMPUTE_MODE_UNSPECIFIED = "BATCH_COMPUTE_MODE_UNSPECIFIED",
  BATCH_COMPUTE_MODE_SPARK = "BATCH_COMPUTE_MODE_SPARK",
  BATCH_COMPUTE_MODE_SNOWFLAKE = "BATCH_COMPUTE_MODE_SNOWFLAKE",
  BATCH_COMPUTE_MODE_RIFT = "BATCH_COMPUTE_MODE_RIFT",
}
