import { useInfiniteQuery } from '@tanstack/react-query';
import _camelCase from 'lodash/camelCase';
import _snakeCase from 'lodash/snakeCase';
import { Tecton_Graphql } from '..';
import { FeatureFlags, useUserSettings } from '../../components/context/UserSettingsContext';
import { transformJobs } from '../../components/jobs/jobsTransform';
import { graphql } from '../gql';
import { GetJobsRequest } from '../gql/graphql';
import { fetchJobs } from '../jobs';
import { convertKeysToCamelCase, convertKeysToSnakeCase } from '../utils';

export const jobsQuery = graphql(`
  query GetJobs($getJobsRequest: GetJobsRequest) {
    GetJobs(GetJobsRequest: $getJobsRequest) {
      jobs {
        fullId {
          mostSignificantBits
          leastSignificantBits
        }
        id
        workspace
        featureView
        featureStartTime
        featureEndTime
        fvType {
          label
          color
          backgroundColor
          value
          checked
        }
        fvTaskTypeForDisplay {
          label
          color
          backgroundColor
          value
          checked
        }
        latestStatus {
          label
          color
          backgroundColor
          value
          checked
        }
        lastTaskStateChange
        duration
        attempts {
          dataSourceType
          windowStartTime
          windowEndTime
          featureStartTime
          featureEndTime
          materializationState
          stateMessage
          terminationReason
          spotInstanceFailure
          materializationTaskCreatedAt
          attemptNumber
          sparkClusterEnvironmentVersion
          runPageUrl
          attemptCreatedAt
          duration
          allowForcedRetry
          allowOverwriteRetry
          allowCancel
          allowRestart
          writeToOnlineFeatureStore
          writeToOfflineFeatureStore
          tectonManagedAttemptId
          attemptConsumption {
            onlineRowsWritten
            offlineRowsWritten
            jobDuration
          }
          consumptionInfo {
            timeBucketStart
            unitsConsumed
            metric
            sourceId
            featureViewName
            workspace
            featureViewId {
              mostSignificantBits
              leastSignificantBits
            }
            details {
              offlineStoreType
            }
          }
          materializationTaskId {
            mostSignificantBits
            leastSignificantBits
          }
          materializationAttemptId
        }
        numAttempts
        manuallyTriggered
        planId {
          mostSignificantBits
          leastSignificantBits
        }
        allowRetry
        allowCancel
        allowOverwrite
        writesOnline
        writesOffline
        scheduleErrorMessage
      }
      pagination {
        page
        perPage
        nextPageToken
        sortKey
        sortDirection
        total
      }
    }
  }
`);

// Switch to this once we do the full switch over
export const useGetInfiniteJobsGQL = (args: GetJobsRequest, keepPreviousData: boolean) => {
  return useInfiniteQuery(
    ['infinite-jobs-gql', args],
    async ({ pageParam = '', signal }) => {
      const variables = {
        getJobsRequest: {
          ...args,
          pagination: {
            ...args.pagination,
            pageToken: pageParam,
          },
        },
      };
      // eslint-disable-next-line
      const response = await Tecton_Graphql.request<any>({
        document: jobsQuery,
        variables: convertKeysToCamelCase(variables),
        signal,
      });

      return convertKeysToSnakeCase(response.GetJobs);
    },
    {
      refetchOnWindowFocus: false,
      keepPreviousData,
      getNextPageParam: (lastPage) => {
        return lastPage?.pagination?.nextPageToken || '';
      },
      select: (data) => {
        return {
          pageParams: data.pageParams,
          pages: data.pages.map((page) => page),
        };
      },
      notifyOnChangeProps: ['data', 'error', 'isFetching', 'isLoading', 'isFetchingNextPage'],
    }
  );
};

export const useGetInfiniteJobs = (args: GetJobsRequest, keepPreviousData: boolean) => {
  const { featureEnabled } = useUserSettings();
  const useGraphQL = featureEnabled(FeatureFlags.JOBS_GQL);
  const fetchFunction = useGraphQL ? fetchJobsGQL : doFetchJobs;

  return useInfiniteQuery(
    ['infinite-jobs', args],
    ({ pageParam = '', signal }) => fetchFunction(args, pageParam, signal as AbortSignal),
    {
      refetchOnWindowFocus: false,
      keepPreviousData,
      getNextPageParam: (lastPage) => {
        return lastPage?.pagination?.[useGraphQL ? 'nextPageToken' : 'next_page_token'] || '';
      },
      select: (data) => {
        return {
          pageParams: data.pageParams,
          pages: data.pages.map((page) => (useGraphQL ? page : transformJobs(page))),
        };
      },
      notifyOnChangeProps: ['data', 'error', 'isFetching', 'isLoading', 'isFetchingNextPage'],
    }
  );
};

const doFetchJobs = (args: GetJobsRequest, pageParam: string, signal: AbortSignal) => {
  const params = {
    ...args,
    pagination: {
      ...args.pagination,
      page_token: pageParam,
    },
  };

  return fetchJobs(params as GetJobsRequest, signal);
};

const fetchJobsGQL = async (args: GetJobsRequest, pageParam: string, signal: AbortSignal) => {
  if (args?.pagination?.sortKey) args.pagination.sortKey = _snakeCase(args.pagination.sortKey);

  const variables = {
    getJobsRequest: {
      ...args,
      pagination: {
        ...args.pagination,
        pageToken: pageParam,
      },
    },
  };
  // eslint-disable-next-line
  const response = await Tecton_Graphql.request<any>({
    document: jobsQuery,
    variables: convertKeysToCamelCase(variables),
    signal,
  });

  if (response?.GetJobs?.pagination?.sortKey)
    response.GetJobs.pagination.sortKey = _camelCase(response?.GetJobs?.pagination?.sortKey);

  return response.GetJobs;
};
