import { DataFlowEdge, DataFlowNode, DataFlowPathProperties, NodesMapType, PathDataflowType } from '../dataFlowTypes';

import edgeIdFn from './edgeIdFn';
import { HighlightFunctionType } from './highlightFunctionType';

import highlightAggregation from './highlightAggregation';
import highlightDataSource from './highlightDataSource';
import highlightFeatureView from './highlightFeatureView';
import highlightODFV from './highlightODFV';
import highlightRawBatchNode from './highlightRawBatchNode';
import highlightRawStreamNode from './highlightRawStreamNode';
import highlightTransformation from './highlightTransformation';
import highlightFeatureService from './highlightFeatureService';
import highlightModelTrainer from './highlightModelTrainer';
import highlightModelInference from './highlightModelInference';
import highlightRequestDataSource from './highlightRequestDataSource';
import { highlightOfflineStore, highlightOnlineStore, highlightWholeStore } from './highlightStores';
import { DataSourceFCOType } from '../../../../../core/types/fcoTypes';

type PathKeys = keyof DataFlowPathProperties;

const mergePathProperties = (a: DataFlowPathProperties, b: DataFlowPathProperties) => {
  const keys: PathKeys[] = [
    'isBatchMaterializationPath',
    'isStreamMaterializationPath',
    'isOnlineServingPath',
    'isOfflineReadPath',
  ];

  const result: DataFlowPathProperties = {};

  keys.forEach((key) => {
    const valueA = a[key];
    const valueB = b[key];

    if (valueA === true || valueB === true) {
      result[key] = true;
    }
  });

  return result;
};

const getFeatureServiceIdGivenConsumer = (id: string, nodesMap: NodesMapType) => {
  const consumerNode = nodesMap[id];

  if (!consumerNode.upstreamNodes || consumerNode.upstreamNodes.length === 0 || !consumerNode.upstreamNodes[0]) {
    throw new Error(
      `Expect consumer node (${id}) to have exactly one upstream node, corresponding to the feature service.`
    );
  }

  const featureServiceId = consumerNode.upstreamNodes[0];

  return featureServiceId;
};

const getHighlightedNodesAndEdgesGivenFocusedNode = (
  focusedNode: DataFlowNode,
  nodesMap: NodesMapType,
  edgesList: DataFlowEdge[]
) => {
  let highlightFn: HighlightFunctionType;
  let dataflowModes: PathDataflowType[] | undefined;

  /**
   * Depending on the type of node that is
   * in focus, we invoke different highlighting
   * functions.
   *
   * We optionally fade out different
   * materialization animations.
   */
  switch (focusedNode.type) {
    case 'raw_stream_node':
      highlightFn = highlightRawStreamNode;
      if (!focusedNode.pushesToOfflineStore) {
        /**
         * Extra special case
         *
         * If the highlighted node is a raw stream node
         * we generally want to show only the stream
         * materialization animation. However
         * if the highlighted node is a push source,
         * AND it is configured to write to the offline store
         * then we don't do that.
         */
        dataflowModes = ['isStreamMaterializationPath'];
      }

      break;

    case 'raw_batch_node':
      highlightFn = highlightRawBatchNode;
      dataflowModes = ['isBatchMaterializationPath', 'isOfflineReadPath'];
      break;

    case 'data_source':
      highlightFn = highlightDataSource;
      if (focusedNode.dataSourceType === DataSourceFCOType.BATCH) {
        dataflowModes = ['isBatchMaterializationPath', 'isOfflineReadPath'];
      }

      break;

    case 'request_data_source':
      highlightFn = highlightRequestDataSource;
      break;

    case 'feature_view':
      highlightFn = highlightFeatureView;
      break;

    case 'odfv':
      highlightFn = highlightODFV;
      break;

    case 'transformation':
      highlightFn = highlightTransformation;
      break;

    case 'aggregation':
      highlightFn = highlightAggregation;
      break;

    case 'online_store':
      highlightFn = highlightOnlineStore;
      dataflowModes = ['isBatchMaterializationPath', 'isStreamMaterializationPath', 'isOnlineServingPath'];
      break;

    case 'offline_store':
      highlightFn = highlightOfflineStore;
      dataflowModes = ['isBatchMaterializationPath', 'isOfflineReadPath'];
      break;

    case 'store_wrapper':
      highlightFn = highlightWholeStore;
      break;

    case 'feature_service':
      highlightFn = highlightFeatureService;
      break;

    case 'model_inference':
      highlightFn = highlightModelInference;
      if (focusedNode.inferenceType === 'real_time') {
        dataflowModes = ['isOnlineServingPath', 'isBatchMaterializationPath', 'isStreamMaterializationPath'];
      } else {
        dataflowModes = ['isBatchMaterializationPath', 'isOfflineReadPath'];
      }

      break;

    case 'model_trainer':
      highlightFn = highlightModelTrainer;
      dataflowModes = ['isOfflineReadPath', 'isBatchMaterializationPath', 'isStreamMaterializationPath'];
      break;

    default:
      /**
       * No highlight function defined for given node type, throw an error.
       */
      throw new Error(`No highlighting function defined for node type: '${focusedNode.type}'`);
  }

  const { linkedIds, linkedEdges, animations } = highlightFn(focusedNode.id, nodesMap, edgesList);

  return {
    linkedIds,
    linkedEdges,
    animations,
    dataflowModes,
  };
};

/**
 * getAddDependencyEdgeFn
 * ===========================
 * Why do we need this?
 *
 * These functions reconcile the differences between:
 *
 * - Logical Dependencies: FCOs depending on one-another.
 * - Dataflow Paths: Data flowing from nodes on the visual graph.
 *
 * The most notable difference between the two are the store nodes,
 * which appear on the Dataflow Paths but are not part of the logical
 * dependency graphs.
 *
 * addDependencyEdge takes the source and target of a Logical dependency
 * link that we want to highlight, and figures out what Dataflow
 * paths need to be highlighted as a result.
 *
 * Much of the logic here is around knowing which pairs of Logical FCOs
 * are on opposite sides of the store, and adding the intermediate
 * Dataflow edges necessary to connect them.
 */

const getAddDependencyEdgeFn = (linkedIds: Set<string>, linkedEdges: Set<string>) => {
  const bridgeThroughStore = (
    upstreamNode: DataFlowNode,
    downstreamNode: DataFlowNode,
    config?: {
      hideOnlineStore?: boolean;
      hideOfflineStore?: boolean;
    }
  ) => {
    if (
      upstreamNode.type === 'feature_view' &&
      upstreamNode.isOnlineMaterializationEnabled &&
      (!config || config.hideOnlineStore !== true)
    ) {
      linkedEdges.add(edgeIdFn({ source: upstreamNode.id, target: 'STORE' }));
      linkedEdges.add(edgeIdFn({ source: 'STORE', target: downstreamNode.id }));

      linkedIds.add('STORE');
      linkedIds.add('store-input');
      linkedIds.add('ONLINE_STORE');
      linkedEdges.add(edgeIdFn({ source: 'store-input', target: 'ONLINE_STORE' }));

      if (downstreamNode.type === 'odfv') {
        linkedIds.add('store-output');
        linkedEdges.add(edgeIdFn({ source: 'ONLINE_STORE', target: 'store-output' }));
      }

      if (downstreamNode.type === 'feature_service' && downstreamNode.isOnlineServingEnabled) {
        linkedIds.add('store-output');
        linkedEdges.add(edgeIdFn({ source: 'ONLINE_STORE', target: 'store-output' }));
      }
    }

    if (
      upstreamNode.type === 'feature_view' &&
      upstreamNode.isOfflineMaterializationEnabled &&
      (!config || config.hideOfflineStore !== true)
    ) {
      linkedEdges.add(edgeIdFn({ source: upstreamNode.id, target: 'STORE' }));
      linkedEdges.add(edgeIdFn({ source: 'STORE', target: downstreamNode.id }));

      linkedIds.add('STORE');
      linkedIds.add('OFFLINE_STORE');

      linkedIds.add('store-input');
      linkedEdges.add(edgeIdFn({ source: 'store-input', target: 'OFFLINE_STORE' }));

      linkedIds.add('store-output');
      linkedEdges.add(edgeIdFn({ source: 'OFFLINE_STORE', target: 'store-output' }));
    }
  };

  const addDependencyEdge = (
    upstreamNode: DataFlowNode,
    downstreamNode: DataFlowNode,
    config?: {
      hideOnlineStore?: boolean;
      hideOfflineStore?: boolean;
    }
  ) => {
    /**
     * Pairs of FCOs that are on the opposite sides of a store.
     */
    if (upstreamNode.type === 'feature_view' && downstreamNode.type === 'transformation') {
      bridgeThroughStore(upstreamNode, downstreamNode, config);
      // return;
    }

    if (upstreamNode.type === 'transformation' && downstreamNode.type === 'aggregation') {
      bridgeThroughStore(upstreamNode, downstreamNode, config);
      // return;
    }

    if (upstreamNode.type === 'feature_view' && downstreamNode.type === 'odfv') {
      bridgeThroughStore(upstreamNode, downstreamNode, config);
      // return;
    }

    if (upstreamNode.type === 'feature_view' && downstreamNode.type === 'feature_service') {
      bridgeThroughStore(upstreamNode, downstreamNode, config);
      // return;
    }

    /**
     * Default case - FCOs that are directly connected by Dataflow Paths
     */
    const edgeId = edgeIdFn({ source: upstreamNode.id, target: downstreamNode.id });
    linkedEdges.add(edgeId);
  };

  return addDependencyEdge;
};

export type VisibleAnimationRecord = {
  showBatchMaterializationPath?: boolean;
  showStreamMaterializationPath?: boolean;
  showOfflineReadPath?: boolean;
  showOnlineServingPath?: boolean;
};

/**
 * This function returns the animations that should be visible
 * when given a particular set of anchors.
 *
 * Anchors here are IDs of particular FVs or FSs. Since all
 * dataflow in Tecton is determined by:
 * - A Feature View (Whether it is onlined enabled?, offline enabled?), or...
 * - A Feature Service (Is it serving online? Is it reading offline)
 *
 * To find the relevant animations:
 * - We loop through each edge, then
 * - Check its list of `animationsFlow` to see if it has an
 *   animation that involves one of the anchors provided
 * - Collect all the animations
 * - Return it for highlighting
 */
export const getVisibleAnimationsOnAllEdgesGivenAnchors = (
  anchors: Set<string>,
  edgesList: DataFlowEdge[],
  linkedEdges: Set<string>,
  includedAnimationTypes?: VisibleAnimationRecord
): Record<string, VisibleAnimationRecord> => {
  const results: Record<string, VisibleAnimationRecord> = {};

  edgesList
    .filter((edge) => {
      return linkedEdges.has(edgeIdFn(edge));
    })
    .forEach((e) => {
      if (e.animationFlows) {
        const initial: DataFlowPathProperties = {};

        const relevantAnimations = Object.keys(e.animationFlows).reduce<DataFlowPathProperties>((memo, anchorKey) => {
          if (anchors.has(anchorKey) && e.animationFlows && e.animationFlows[anchorKey]) {
            return mergePathProperties(memo, e.animationFlows[anchorKey]);
          } else {
            return memo;
          }
        }, initial);

        const visibleAnimations: VisibleAnimationRecord = {};

        if (
          relevantAnimations.isBatchMaterializationPath &&
          (!includedAnimationTypes || includedAnimationTypes.showBatchMaterializationPath === true)
        ) {
          visibleAnimations.showBatchMaterializationPath = true;
        }

        if (
          relevantAnimations.isStreamMaterializationPath &&
          (!includedAnimationTypes || includedAnimationTypes.showStreamMaterializationPath === true)
        ) {
          visibleAnimations.showStreamMaterializationPath = true;
        }

        if (
          relevantAnimations.isOnlineServingPath &&
          (!includedAnimationTypes || includedAnimationTypes.showOnlineServingPath === true)
        ) {
          visibleAnimations.showOnlineServingPath = true;
        }

        if (
          relevantAnimations.isOfflineReadPath &&
          (!includedAnimationTypes || includedAnimationTypes.showOfflineReadPath === true)
        ) {
          visibleAnimations.showOfflineReadPath = true;
        }

        results[edgeIdFn(e)] = visibleAnimations;
      }
    });

  return results;
};

type VisibleAnimationKeys = keyof VisibleAnimationRecord;

const mergeVisibleAnimations = (a: VisibleAnimationRecord, b: VisibleAnimationRecord): VisibleAnimationRecord => {
  const keys: VisibleAnimationKeys[] = [
    'showBatchMaterializationPath',
    'showStreamMaterializationPath',
    'showOnlineServingPath',
    'showOfflineReadPath',
  ];

  return keys.reduce<VisibleAnimationRecord>((result, key) => {
    const valueA = a[key];
    const valueB = b[key];

    if (valueA === true || valueB === true) {
      result[key] = true;
    }

    return result;
  }, {});
};

const mergeEdgeAnimationsRecords = (
  inputs: Record<string, VisibleAnimationRecord>[]
): Record<string, VisibleAnimationRecord> => {
  return inputs.reduce<Record<string, VisibleAnimationRecord>>((results, map) => {
    Object.entries(map).forEach(([key, edges]) => {
      if (results[key]) {
        results[key] = mergeVisibleAnimations(results[key], edges);
      } else {
        results[key] = edges;
      }
    });
    return results;
  }, {});
};

export {
  mergePathProperties,
  mergeEdgeAnimationsRecords,
  getHighlightedNodesAndEdgesGivenFocusedNode,
  getFeatureServiceIdGivenConsumer,
  getAddDependencyEdgeFn,
};
