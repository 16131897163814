import React from 'react';
import ReactTooltip from 'react-tooltip';
import { v4 as uuidv4 } from 'uuid';

export default function Avatar({ username = '' }) {
  if (username === '') {
    return '-';
  }

  const initials = username.charAt(0).toUpperCase();
  const ascii = initials.charCodeAt(0);
  let avatarColor = 1;
  for (let color = 5; color >= 1; color--) {
    if (ascii % color === 0) {
      avatarColor = color;
      break;
    }
  }

  const className = `cursor-default avatar avatar--small avatar--initial avatar--initial--${avatarColor}`;
  const id = uuidv4();

  return (
    <>
      <div data-tip data-for={id} className={className}>
        {initials}
      </div>
      <ReactTooltip id={id} effect="solid">
        <span className="fw6">{username}</span>
      </ReactTooltip>
    </>
  );
}
