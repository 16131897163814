import React, { VFC } from 'react';

interface EmojiInterface {
  symbol: string;
  label?: string;
  size?: number;
}

const Emoji: VFC<EmojiInterface> = ({ symbol, label, size = 25 }) => (
  <span style={{ fontSize: size }} role="img" aria-label={label ? label : ''} aria-hidden={label ? 'false' : 'true'}>
    {symbol}
  </span>
);
export default Emoji;
