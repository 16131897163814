import React from 'react';
import Button from '@mui/material/Button';

export const PrimaryButton = ({ children, ...rest }) => {
  return (
    <Button {...rest} color="primary" variant="contained">
      {children}
    </Button>
  );
};
