import React, { FC } from 'react';

// @tecton
import { FlexGroupWrapper, FlexItem, Text } from '@tecton/ComponentRedesign';

interface DeactivateAccountProps {
  serviceAccountName: string;
}

const DeactivateAccount: FC<DeactivateAccountProps> = ({ serviceAccountName }) => {
  return (
    <>
      <FlexGroupWrapper gap="l" direction="column">
        <FlexItem grow={false}>
          <Text>Are you sure you want to deactivate {serviceAccountName}?</Text>
        </FlexItem>
        <FlexItem grow={false}></FlexItem>
      </FlexGroupWrapper>
    </>
  );
};

export default DeactivateAccount;
