import { WorkspaceFCOContainer } from '../../../core/types/fcoTypes';

import {
  Breadcrumbs,
  EmptyPrompt,
  FlexGroup,
  FlexItem,
  HeaderLayout,
  Subtitle,
  ViewLayout,
} from '@tecton/ComponentRedesign';
import { useGetFcos } from '../../../api/fcos';
import { useUserSettings } from '../../context/UserSettingsContext';
import { TECTON_DOCUMENTATION_LINKS } from '../application-links';
import WorkspaceRootBreadcrumb from '../WorkspaceRootBreadcrumb';
import EntitiesList from './EntitiesList';

export const EntitiesContainer = () => {
  const { workspace, workspaceDetails } = useUserSettings();

  const { data, isError, isLoading } = useGetFcos<WorkspaceFCOContainer>(workspace as string, {
    select: (data: WorkspaceFCOContainer) => {
      return {
        entities: data.entities,
        idToFcoMap: data.idToFcoMap,
      };
    },
  });

  const subtitle = (
    <Subtitle
      description="Entities are the primary keys for your features."
      learnMoreUrl={TECTON_DOCUMENTATION_LINKS.ENTITIES}
    />
  );

  const header = (
    <HeaderLayout
      breadcrumbs={
        <Breadcrumbs
          workspace={<WorkspaceRootBreadcrumb workspace={workspaceDetails} />}
          crumbs={[{ label: 'Entities' }]}
        />
      }
      subtitle={subtitle}
    />
  );

  if (isLoading) {
    return (
      <ViewLayout
        header={header}
        body={
          <FlexGroup direction="column" alignItems="center">
            <FlexItem>
              <EmptyPrompt
                title={<>Loading Entities</>}
                body={<>Entities are loading.</>}
                variant="loading"
                orientation="vertical"
              />
            </FlexItem>
          </FlexGroup>
        }
      />
    );
  }

  if (isError) {
    return (
      <ViewLayout
        header={header}
        body={
          <EmptyPrompt
            title={<>Error Loading Entities</>}
            body={<>There was an error loading Entities for this workspace. Please try again.</>}
            variant="storm"
            orientation="vertical"
          />
        }
      />
    );
  }
  return (
    <ViewLayout
      header={header}
      body={<EntitiesList workspace={workspace ?? ''} items={data?.entities ?? []} idToFcoMap={data?.idToFcoMap} />}
    />
  );
};

export default EntitiesContainer;
