import React, { FC } from 'react';
import TectonCard from '@tecton/ComponentRedesign/lib/Card';
import { Link } from 'react-router-dom';
import {
  FCOFields,
  FCOType,
  FeatureForFCO,
  FeatureServiceFCO,
  FeatureServiceFCOFields,
  FeatureViewFCO,
  FeatureViewFCOFields,
} from '../../../core/types/fcoTypes';
import {
  Table,
  RelatedFCOs,
  Monospace,
  EnabledDisabledBadge,
  FCOIconAndName,
  AvatarAndName,
} from '@tecton/ComponentRedesign';
import { NOT_APPLICABLE, TectonDateTimeFormat } from '@tecton/ComponentRedesign/utils';
import EmptyValue from '@tecton/ComponentRedesign/lib/EmptyValue';
import moment from 'moment-timezone';

interface FeatureServiceFeatureTableProps {
  fco: FeatureServiceFCO;
  fcos: any; // from useGetFcos,
  workspace: string;
}

interface FeatureRow {
  feature: FeatureForFCO;
  featureView: FeatureViewFCO;
}

const FeatureServiceFeatureTable: FC<FeatureServiceFeatureTableProps> = ({ fco, fcos, workspace }) => {
  const dependentFeatureViews = fco[FeatureServiceFCOFields.ALL_FEATURE_VIEWS].map((featureViewId) => {
    return fcos.featureViews.find((featureView: FeatureViewFCO) => featureView.id === featureViewId);
  });

  // TODO: This is apparently not sufficient
  // We need to filter these by only the features actually USED by the service
  // This is just all of the POSSIBLE features for the Feature Service
  const features: FeatureRow[] = [];

  dependentFeatureViews.forEach((featureView: FeatureViewFCO) => {
    featureView[FeatureViewFCOFields.FEATURES].forEach((feature) => {
      features.push({
        feature: feature,
        featureView: featureView,
      });
    });
  });

  const columns = [
    {
      name: 'Feature',
      render: (item: FeatureRow) => <>{item.feature.name}</>,
    },
    {
      name: 'Feature View or Table',
      render: (item: FeatureRow) => (
        <Link to={`/repo/${workspace}/features/${item.featureView.name}/dataflow`}>
          <FCOIconAndName
            name={item.featureView.name ?? ''}
            type={FCOType.FEATURE_VIEW}
            description={item.featureView.description}
          />
        </Link>
      ),
    },
    {
      name: 'Entities',
      render: (item: FeatureRow) => {
        if (!item.featureView) {
          return <></>;
        }
        const entities = item.featureView[FeatureViewFCOFields.ENTITY_IDS].map((id: string) => {
          return fcos.idToFcoMap[id];
        });
        return <RelatedFCOs fcos={entities} type={FCOType.ENTITY} workspace={workspace} />;
      },
    },
    {
      name: 'Type',
      render: (item: FeatureRow) => <Monospace>{item.feature.type}</Monospace>,
    },
    {
      name: 'Online',
      render: (item: FeatureRow) => (
        <>
          {item.featureView[FeatureViewFCOFields.IS_REALTIME] ? (
            NOT_APPLICABLE
          ) : (
            <EnabledDisabledBadge
              enabled={item.featureView[FeatureViewFCOFields.IS_ONLINE_MATERIALIZATION_ENABLED] ?? false}
            />
          )}
        </>
      ),
    },
    {
      name: 'Offline',
      render: (item: FeatureRow) => (
        <>
          {item.featureView[FeatureViewFCOFields.IS_REALTIME] ? (
            NOT_APPLICABLE
          ) : (
            <EnabledDisabledBadge
              enabled={item.featureView[FeatureViewFCOFields.IS_OFFLINE_MATERIALIZATION_ENABLED] ?? false}
            />
          )}
        </>
      ),
    },
    {
      name: 'Owner',
      render: (item: FeatureRow) => <AvatarAndName name={item.featureView[FCOFields.OWNER] ?? ''} type="initials" />,
    },
    {
      name: 'Created',
      render: (item: FeatureRow) =>
        item.featureView[FCOFields.CREATED_DATE] ? (
          TectonDateTimeFormat(moment(item.featureView[FCOFields.CREATED_DATE]))
        ) : (
          <EmptyValue />
        ),
    },
  ];

  return (
    <TectonCard title={`Features (${features.length})`}>
      <Table items={features} columns={columns} />
    </TectonCard>
  );
};

export default FeatureServiceFeatureTable;
