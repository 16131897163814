import { Outlet, useMatch, useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Routes } from '../../../core/routes';

// @tecton
import { Breadcrumbs, HeaderLayout, Tabs, ViewLayout } from '@tecton/ComponentRedesign';

export const getComputeRoutes = (): Record<string, string> => {
  return {
    Overview: './overview',
    Environments: './environments',
    'DB Principals': './db-principals',
  };
};

const ACLTabs = () => {
  const navigation = useNavigate();
  const { user, serviceAccount, group } = useParams();

  const indexRoute = {
    pathName: 'Overview',
    path: `./overview`,
    paramsContext: user,
    isMatch: !!useMatch({
      path: `${Routes.computeOverview}`,
      end: false,
    }),
  };

  const pages = [
    indexRoute,
    {
      pathName: 'Environments',
      path: `./environments`,
      paramsContext: serviceAccount,
      isMatch: !!useMatch({ path: Routes.computeEnvironments, end: false }),
    },
    {
      pathName: 'DB Principals',
      path: `./db-principals`,
      paramsContext: group,
      isMatch: !!useMatch({ path: Routes.computeDBPrincipals, end: false }),
    },
  ];

  const selectedPage = pages.find((page) => page.isMatch) ?? indexRoute;

  return (
    <Tabs
      selectedTab={`${selectedPage?.pathName}`}
      tabs={pages.map((page) => {
        return page.pathName;
      })}
      setSelectedTab={(tab: string) => {
        const routes = getComputeRoutes();
        navigation(routes[tab]);
      }}
    />
  );
};

const ComputeOutlet = () => {
  const BREAD_CRUMB_ROOT_PAGE = 'Compute';
  const indexRoute = {
    pathName: 'Overview',
    path: `./overview`,
    paramsContext: 'overview',
    isMatch: !!useMatch({
      path: `${Routes.computeOverview}`,
      end: false,
    }),
  };

  const pages = [
    indexRoute,
    {
      pathName: 'Environments',
      path: `./environments`,
      paramsContext: 'environments',
      isMatch: !!useMatch({ path: Routes.computeEnvironments, end: false }),
    },
    {
      pathName: 'DB Principals',
      path: `./db-principals`,
      paramsContext: 'db-principals',
      isMatch: !!useMatch({ path: Routes.computeDBPrincipals, end: false }),
    },
  ];

  const selectedPage = pages.find((page) => page.isMatch) ?? indexRoute;

  const breadcrumbs = (
    <Breadcrumbs
      workspace={<Link to={`${Routes.dataPlatformConfiguration}`}>{BREAD_CRUMB_ROOT_PAGE}</Link>}
      crumbs={[
        {
          label: selectedPage.paramsContext ? (
            <Link to={`./${selectedPage?.path}`}>{selectedPage?.pathName}</Link>
          ) : (
            selectedPage?.pathName
          ),
        },
        ...(selectedPage.paramsContext ? [{ label: selectedPage?.paramsContext }] : []),
      ]}
    />
  );

  const tabs = <ACLTabs />;
  const header = <HeaderLayout breadcrumbs={breadcrumbs} tabs={tabs} />;
  return (
    <>
      <ViewLayout header={header} body={<Outlet />} />
    </>
  );
};

export default ComputeOutlet;
