import React, { FC } from 'react';
import { FeatureViewFCO } from '../../../core/types/fcoTypes';

import FeatureViewConfigurationCard, { ConfigCardItemType } from './FeatureViewConfigurationCard';

interface FeatureViewConfigCardProps {
  fco: FeatureViewFCO;
  relatedFCOs: any;
}

export const BatchFeatureViewConfigCard: FC<FeatureViewConfigCardProps> = ({ fco, relatedFCOs }) => {
  return (
    <FeatureViewConfigurationCard
      title="Configuration"
      fco={fco}
      relatedFCOs={relatedFCOs}
      items={[
        ConfigCardItemType.TYPE,
        ConfigCardItemType.ENTITIES,
        ConfigCardItemType.JOIN_KEYS,
        ConfigCardItemType.TIMESTAMP_FIELD,
        ConfigCardItemType.ONLINE_MATERIALIZATION,
        ConfigCardItemType.OFFLINE_MATERIALIZATION,
        ConfigCardItemType.FEATURE_START_TIME,
        ConfigCardItemType.AGGREGATION_SECONDARY_KEY,
        ConfigCardItemType.ALERT_EMAIL,
        ConfigCardItemType.DATA_QUALITY_ENABLED,
        ConfigCardItemType.SKIP_DEFAULT_EXPECTATIONS,
      ]}
    />
  );
};

export const StreamingFeatureViewConfigCard: FC<FeatureViewConfigCardProps> = ({ fco, relatedFCOs }) => {
  return (
    <FeatureViewConfigurationCard
      title="Configuration"
      fco={fco}
      relatedFCOs={relatedFCOs}
      items={[
        ConfigCardItemType.TYPE,
        ConfigCardItemType.ENTITIES,
        ConfigCardItemType.JOIN_KEYS,
        ConfigCardItemType.TIMESTAMP_FIELD,
        ConfigCardItemType.ONLINE_MATERIALIZATION,
        ConfigCardItemType.OFFLINE_MATERIALIZATION,
        ConfigCardItemType.FEATURE_START_TIME,
        ConfigCardItemType.AGGREGATION_SECONDARY_KEY,
        ConfigCardItemType.ALERT_EMAIL,
        ConfigCardItemType.DATA_QUALITY_ENABLED,
        ConfigCardItemType.SKIP_DEFAULT_EXPECTATIONS,
      ]}
    />
  );
};

export const OnDemandFeatureViewConfigCard: FC<FeatureViewConfigCardProps> = ({ fco, relatedFCOs }) => {
  return (
    <FeatureViewConfigurationCard
      title="Configuration"
      fco={fco}
      relatedFCOs={relatedFCOs}
      items={[ConfigCardItemType.TYPE, ConfigCardItemType.REQUEST_DATA_SOURCE_KEYS, ConfigCardItemType.ENVIRONMENTS]}
    />
  );
};

export const FeatureTableConfigCard: FC<FeatureViewConfigCardProps> = ({ fco, relatedFCOs }) => {
  return (
    <FeatureViewConfigurationCard
      title="Configuration"
      fco={fco}
      relatedFCOs={relatedFCOs}
      items={[
        ConfigCardItemType.TYPE,
        ConfigCardItemType.ENTITIES,
        ConfigCardItemType.JOIN_KEYS,
        ConfigCardItemType.TIMESTAMP_FIELD,
        ConfigCardItemType.ONLINE_MATERIALIZATION,
        ConfigCardItemType.OFFLINE_MATERIALIZATION,
        ConfigCardItemType.ALERT_EMAIL,
      ]}
    />
  );
};
