import React, { useLayoutEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import _get from 'lodash/get';

import DataFlowDiagram, { MAX_NODES_SUMMARY_THRESHOLD } from './DataflowDiagram';

import DataflowSummaryDiagram from './DataflowSummaryDiagram';
import { DataFlowSpec } from './dataFlowTypes';
import { Loading, EmptyPrompt, Button, FlexGroup } from '@tecton/ComponentRedesign';
import { useUserSettings } from '../../../context/UserSettingsContext';

const LoadingWrapper = styled.div<{ width?: string; height?: string }>`
  width: 100%;
  height: 100%;
`;

const Layout = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

const LoadingPanel = () => {
  return (
    <LoadingWrapper>
      <Layout>
        <Loading size={'xl'} />
      </Layout>
    </LoadingWrapper>
  );
};

const DataflowSizedContainer = ({
  data,
  isLoading,
  isFcoLevelDiagram,
  height,
}: {
  isLoading?: boolean;
  data?: DataFlowSpec;
  isFcoLevelDiagram?: boolean;
  height: string;
  width: string;
}) => {
  const [showSummarizedView, setShowSummarizedView] = useState<boolean | undefined>(undefined);

  // Account for the bottom banner in explorer.
  const DIAGRAM_HEIGHT = height;

  // TODO: IN PRACTICE, 'data' SHOULD COME FROM THE FETCH FCOs WORKSPACE CONTAINER
  // HERE I AM TEMPORARILY FAKING IT

  if (isLoading) {
    return <LoadingPanel />;
  }

  if (data) {
    if (showSummarizedView === undefined && data.nodesList.length > MAX_NODES_SUMMARY_THRESHOLD) {
      // Checks whether the INITIAL value for showSummarizedView should be true
      setShowSummarizedView(true); // Initial value depends entirely on the the amount of nodes and the pre-set threshold
    }

    if (data.nodesList && data.nodesList.length > 0) {
      if (showSummarizedView) {
        // Once the initial check has passed, showSummarizedView is user-selectable
        return (
          <DataflowSummaryDiagram {...data} height={DIAGRAM_HEIGHT} setShowSummarizedView={setShowSummarizedView} />
        );
      }

      return (
        <DataFlowDiagram
          {...data}
          height={DIAGRAM_HEIGHT}
          setShowSummarizedView={setShowSummarizedView}
          isFcoLevelDiagram={isFcoLevelDiagram}
        />
      );
    }
  }
  return (
    <>
      <FlexGroup alignItems="flexEnd" justifyContent="center">
        <div>
          <EmptyPrompt
            title={<>No features in dataflow</>}
            body={
              <>
                There isn’t a dataflow setup for this workspace or feature. This diagram renders an end-to-end
                visualization of feature lineage, from data sources through serving features in production.
              </>
            }
            actions={
              <Button variant="emptyAction" label={'Learn More'} onClick={() => window.open('http://docs.tecton.ai')} />
            }
            orientation="vertical"
            variant="dataflow"
          />
        </div>
      </FlexGroup>
    </>
  );
};

const SizeWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const DataflowContainer = ({
  data,
  isLoading,
  isFcoLevelDiagram,
}: {
  isLoading?: boolean;
  data?: DataFlowSpec;
  isFcoLevelDiagram?: boolean;
}) => {
  const { isPublicFreeTrial } = useUserSettings();

  const sizeWrapperRef = useRef<HTMLDivElement>(null);
  const [size, setSize] = useState<{ width: string; height: string }>({ width: '0', height: '0' });

  useLayoutEffect(() => {
    if (sizeWrapperRef.current) {
      const { width, height } = sizeWrapperRef.current.getBoundingClientRect();
      const calcHeight = isPublicFreeTrial ? height - 30 : height;
      setSize({
        width: `${width}px`,
        height: `${calcHeight}px`,
      });
    }
  }, [isPublicFreeTrial, sizeWrapperRef]);

  return (
    <SizeWrapper ref={sizeWrapperRef}>
      <>
        <DataflowSizedContainer
          data={data}
          isLoading={isLoading}
          isFcoLevelDiagram={isFcoLevelDiagram}
          height={size.height}
          width={size.width}
        />
      </>
    </SizeWrapper>
  );
};

export default DataflowContainer;
