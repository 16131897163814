import React from 'react';
import { CodeBlock, Title } from '@tecton';
import { TransformationNode } from '../dataFlowTypes';
import { DataFlowSidePanel } from './StyledPanelComponents';

const TransformationContextPanel = ({
  node,
  name,
  zoomToNode,
  detailsPanelClearAll,
}: {
  node: TransformationNode;
  name: string;
  zoomToNode?: () => void;
  detailsPanelClearAll: () => void;
}) => {
  return (
    <DataFlowSidePanel
      name={name}
      prehead={`Transformation`}
      nodeId={node.id}
      detailsPanelClearAll={detailsPanelClearAll}
      zoomToNode={zoomToNode}
    >
      <section>
        <Title size="xs">
          <h4>Transformation Code</h4>
        </Title>
        <CodeBlock language="python" paddingSize="m">
          {`def user_ad_embedding_similarity(ad_embedding, user_embedding):
    import numpy as np
    from numpy.linalg import norm

    def cosine_similarity(a: np.ndarray, b: np.ndarray):
        # Handle the case where one or both entities do not have a precomputed embedding.
        if a is None or b is None:
            return -1.0

        return np.dot(a, b)/(norm(a)*norm(b))

    result = {}
    result["cosine_similarity"] = cosine_similarity(user_embedding["user_embedding"], ad_embedding["ad_embedding"]).astype('float64')
`}
        </CodeBlock>
      </section>
    </DataFlowSidePanel>
  );
};

export default TransformationContextPanel;
