import React, { FC } from 'react';
import { FeatureViewFCO, EntityFCO, FeatureViewFCOFields, OnlineStoreType } from '../../core/types/fcoTypes';
import {
  FeatureViewTypeBadge,
  EmptyValue,
  Monospace,
  DateDisplay,
  TimeIntervalDisplay,
  FCOCard,
  AttributesList,
  Spacer,
} from '../@tecton';
import EnabledDisabledMaterializationBadge from '../@tecton/EnabledDisabledMaterializationBadge';
import FCONameDisplayWithIconAndTooltip from '../@tecton/FCONameDisplayWithIconAndTooltip';
import { AttributeListItem } from '../@tecton/AttributesList';
import { CardInnerTitle } from '../@shared';

export enum ConfigCardItemType {
  TYPE,
  TIMESTAMP_FIELD,
  STREAM_PROCESSING_MODE,
  SKIP_DEFAULT_EXPECTATIONS,
  SERVING_TTL,
  REQUEST_DATA_SOURCE_KEYS,
  ONLINE_STORE_TYPE,
  ONLINE_MATERIALIZATION,
  OFFLINE_STORE_TYPE,
  OFFLINE_MATERIALIZATION,
  MAX_DATA_SOURCE_DELAY,
  MAX_BACKFILL_INTERVAL,
  MATERIALIZATION_START_TIME,
  MATERIALIZATION_RUNTIME,
  MANUAL_TRIGGER_BACKFILL_END_TIME,
  JOIN_KEYS,
  INCREMENTAL_BACKFILL,
  FRESHNESS_MONITORING_ENABLED,
  FEATURE_START_TIME,
  EXPECTED_FRESHNESS,
  ENVIRONMENTS,
  ENTITIES,
  DATA_QUALITY_ENABLED,
  CACHE_MAX_AGE,
  BATCH_TRIGGER,
  BATCH_SCHEDULE,
  ALERT_EMAIL,
  AGGREGATION_SECONDARY_KEY,
  AGGREGATION_INTERVAL,
  PRIMARY_ENDPOINT,
  PUBLISH_FULL_FEATURES,
  PUBLISH_START_TIME,
  BATCH_COMPACTION_ENABLED,
}

const aggregationIntervalItem = (fco: FeatureViewFCO) => {
  return {
    title: <>Aggregation Interval</>,
    description: fco[FeatureViewFCOFields.MATERIALIZATION_INTERVAL_IN_SECONDS] ? (
      <TimeIntervalDisplay
        interval={{ seconds: fco[FeatureViewFCOFields.MATERIALIZATION_INTERVAL_IN_SECONDS].toString() }}
      />
    ) : (
      <EmptyValue />
    ),
  };
};

const aggregationSecondaryKeyItem = (fco: FeatureViewFCO) => {
  return {
    title: <>Aggregation Secondary Key</>,
    description: fco[FeatureViewFCOFields.TEMPORAL_AGGREGATE_SECONDARY_KEY] ? (
      <Monospace>{fco[FeatureViewFCOFields.TEMPORAL_AGGREGATE_SECONDARY_KEY]}</Monospace>
    ) : (
      <EmptyValue />
    ),
  };
};

const alertEmailItem = (fco: FeatureViewFCO) => {
  return {
    title: <>Alert Email</>,
    description: <>{fco[FeatureViewFCOFields.ALERT_EMAIL] ? fco[FeatureViewFCOFields.ALERT_EMAIL] : <EmptyValue />}</>,
  };
};

const batchScheduleItem = (fco: FeatureViewFCO) => {
  const batchSchedule = fco[FeatureViewFCOFields.MATERIALIZATION_PARAMS].schedule_interval
    ? (fco[FeatureViewFCOFields.MATERIALIZATION_PARAMS].schedule_interval as string).replace('s', '')
    : undefined; // TODO Oct-18-2023: This is messy and should be unnecessary. Confirm that this is producing the correct values with Derek and refactor.

  return {
    title: <>Batch Schedule</>,
    description: <TimeIntervalDisplay interval={{ seconds: batchSchedule }} />,
  };
};

const batchTriggerItem = (fco: FeatureViewFCO) => {
  return {
    title: <>Batch Trigger</>,
    description: <>{fco[FeatureViewFCOFields.BATCH_TRIGGER]}</>,
  };
};

const dataQualityItem = (fco: FeatureViewFCO) => {
  return {
    title: <>Data Quality Enabled</>,
    description: (
      <EnabledDisabledMaterializationBadge mode={'online'} value={fco[FeatureViewFCOFields.IS_DATA_QUALITY_ENABLED]} />
    ),
  };
};

const environmentsConfigItem = (fco: FeatureViewFCO) => {
  if (fco[FeatureViewFCOFields.REALTIME_ENVIRONMENTS].length === 0) {
    return {
      title: <>Environments</>,
      description: <EmptyValue />,
    };
  }

  return {
    title: <>Environments</>,
    description: <>{fco[FeatureViewFCOFields.REALTIME_ENVIRONMENTS].join(',')}</>,
  };
};

const featureStartTimeItem = (fco: FeatureViewFCO) => {
  const materializationStart = fco[FeatureViewFCOFields.MATERIALIZATION_PARAMS].feature_start_timestamp
    ? new Date(fco[FeatureViewFCOFields.MATERIALIZATION_PARAMS].feature_start_timestamp)
    : undefined;

  return {
    title: <>Feature Start Time</>,
    description: (
      <>{materializationStart ? <DateDisplay date={materializationStart} showTime={true} /> : <EmptyValue />}</>
    ),
  };
};

const incrementalBackfillItem = (fco: FeatureViewFCO) => {
  const incrementalBackFill =
    fco[FeatureViewFCOFields.FRAMEWORK_VERSION] &&
    fco[FeatureViewFCOFields.FRAMEWORK_VERSION] >= 4 &&
    fco[FeatureViewFCOFields.HAS_INCREMENTAL_BACKFILL];

  return {
    title: <>Incremental Backfill</>,
    description: <EnabledDisabledMaterializationBadge mode={'online'} value={!!incrementalBackFill} />,
  };
};

const joinKeysItem = (fco: FeatureViewFCO) => {
  const joinKeysFragment =
    fco[FeatureViewFCOFields.JOIN_KEYS].length === 0 ? (
      <EmptyValue />
    ) : (
      <Monospace>{fco[FeatureViewFCOFields.JOIN_KEYS].join(',')}</Monospace>
    );
  return {
    title: <>Join Keys</>,
    description: joinKeysFragment,
  };
};

const manualTriggerBackfillEndTimeItem = (fco: FeatureViewFCO) => {
  return {
    title: <>Manual Trigger Backfill End Time</>,
    description: (
      <>
        {fco[FeatureViewFCOFields.MANUAL_TRIGGER_BACKFILL_END_TIMESTAMP] ? (
          <DateDisplay showTime date={new Date(fco[FeatureViewFCOFields.MANUAL_TRIGGER_BACKFILL_END_TIMESTAMP])} />
        ) : (
          <EmptyValue />
        )}
      </>
    ),
  };
};

const materializationRunTimeItem = (fco: FeatureViewFCO) => {
  return fco[FeatureViewFCOFields.JOB_ENVIRONMENT]
    ? {
        title: <>Job Environment</>,
        description: <>{fco[FeatureViewFCOFields.JOB_ENVIRONMENT]}</>,
      }
    : {
        title: <>Materialization Runtime</>,
        description: <>{fco[FeatureViewFCOFields.MATERIALIZATION_RUNTIME] || <EmptyValue />}</>,
      };
};

const batchCompactionEnabledItem = (fco: FeatureViewFCO) => {
  return {
    title: <>Compaction</>,
    description: (
      <EnabledDisabledMaterializationBadge mode={'online'} value={fco[FeatureViewFCOFields.BATCH_COMPACTION_ENABLED]} />
    ),
  };
};

const materializationStartTimeItem = (fco: FeatureViewFCO) => {
  return {
    title: <>Materialization Start Time</>,
    description: (
      <>
        {fco[FeatureViewFCOFields.MATERIALIZATION_START_TIMESTAMP] ? (
          <DateDisplay showTime date={new Date(fco[FeatureViewFCOFields.MATERIALIZATION_START_TIMESTAMP])} />
        ) : (
          <EmptyValue />
        )}
      </>
    ),
  };
};

const maxBackfillIntervalItem = (fco: FeatureViewFCO) => {
  return {
    title: <>Max Backfill Interval</>,
    description: (
      <>
        {fco[FeatureViewFCOFields.MAX_BACKFILL_INTERVAL] ? (
          <TimeIntervalDisplay interval={{ seconds: fco[FeatureViewFCOFields.MAX_BACKFILL_INTERVAL] }} />
        ) : (
          <EmptyValue />
        )}
      </>
    ),
  };
};

const maxDataSourceDelayItem = (fco: FeatureViewFCO) => {
  return {
    title: <>Max Data Source Delay</>,
    description: (
      <TimeIntervalDisplay interval={fco[FeatureViewFCOFields.MATERIALIZATION_MAX_SOURCE_DATA_DELAY_SECONDS]} />
    ),
  };
};

const offlineServingItem = (fco: FeatureViewFCO) => {
  return {
    title: <>Status</>,
    description: (
      <EnabledDisabledMaterializationBadge
        mode={'online'}
        value={fco[FeatureViewFCOFields.IS_OFFLINE_MATERIALIZATION_ENABLED]}
      />
    ),
  };
};

const offlineStoreTypeItem = (fco: FeatureViewFCO) => {
  return {
    title: <>Staging Table Format</>,
    description: fco[FeatureViewFCOFields.OFFLINE_STORE_TYPE] ? (
      <Monospace>{fco[FeatureViewFCOFields.OFFLINE_STORE_TYPE]}</Monospace>
    ) : (
      <EmptyValue />
    ),
  };
};

const onlineServingItem = (fco: FeatureViewFCO) => {
  return {
    title: <>Status</>,
    description: (
      <EnabledDisabledMaterializationBadge
        mode={'online'}
        value={fco[FeatureViewFCOFields.IS_ONLINE_MATERIALIZATION_ENABLED]}
      />
    ),
  };
};

const onlineStoreTypeItem = (fco: FeatureViewFCO) => {
  return {
    title: <>Store Type</>,
    description: fco[FeatureViewFCOFields.ONLINE_STORE_TYPE] ? (
      <Monospace>{fco[FeatureViewFCOFields.ONLINE_STORE_TYPE]}</Monospace>
    ) : (
      <EmptyValue />
    ),
  };
};

const relatedEntitiesItem = (fco: FeatureViewFCO, relatedFcos: { entities: EntityFCO[] } | undefined) => {
  const entityFragments: React.ReactNode[] = relatedFcos!.entities.map((entity: EntityFCO) => {
    return <FCONameDisplayWithIconAndTooltip fco={entity} inline={true} />;
  });

  const fragmentArray: React.ReactNode[] = [];
  const interleave = (arr: React.ReactNode[], fragment: React.ReactNode) =>
    fragmentArray.concat(...arr.map((n) => [n, fragment])).slice(0, -1);

  if (entityFragments.length === 0) {
    return {
      title: <>Entities</>,
      description: <EmptyValue />,
    };
  }
  return {
    title: <>Entities</>,
    description: (
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {interleave(entityFragments, <span style={{ marginRight: '1em' }}>, </span>).map((fragment) => fragment)}
      </div>
    ),
  };
};

const requestDataSourceKeysItem = (fco: FeatureViewFCO) => {
  if (fco[FeatureViewFCOFields.ON_DEMAND_REQUEST_DATA_SOURCE_KEYS].length === 0) {
    return {
      title: <>Request Data Source Keys</>,
      description: <EmptyValue />,
    };
  }

  return {
    title: <>Request Data Source Keys</>,
    description: <Monospace>{fco[FeatureViewFCOFields.ON_DEMAND_REQUEST_DATA_SOURCE_KEYS].join(', ')}</Monospace>,
  };
};

const servingTTLItem = (fco: FeatureViewFCO) => {
  return {
    title: <>Serving TTL</>,
    description: fco[FeatureViewFCOFields.SERVING_TTL] ? <>{fco[FeatureViewFCOFields.SERVING_TTL]}</> : <EmptyValue />,
  };
};

const skipDefaultExpectationsItem = (fco: FeatureViewFCO) => {
  return {
    title: <>Skip Default Expectations</>,
    description: (
      <EnabledDisabledMaterializationBadge
        mode={'online'}
        value={fco[FeatureViewFCOFields.SKIP_DEFAULT_EXPECTATIONS]}
      />
    ),
  };
};

const streamProcessingModeItem = (fco: FeatureViewFCO) => {
  const mode = fco[FeatureViewFCOFields.IS_CONTINUOUS] ? 'Continuous' : 'Time Interval';
  return {
    title: <>Stream Processing Mode</>,
    description: <>{mode}</>,
  };
};

const timestampFieldItem = (fco: FeatureViewFCO) => {
  return {
    title: <>Timestamp Field</>,
    description: <Monospace>{fco[FeatureViewFCOFields.TIME_KEY]}</Monospace>,
  };
};

const typeItem = (fco: FeatureViewFCO) => {
  return {
    title: <>Feature View Type</>,
    description: <FeatureViewTypeBadge featureView={fco} />,
  };
};

const cacheMaxAgeItem = (fco: FeatureViewFCO) => {
  return {
    title: <>Cache Max Age</>,
    description: <TimeIntervalDisplay interval={fco[FeatureViewFCOFields.CACHE_MAX_AGE_SECONDS]} />,
  };
};

const freshnessMonitoringEnabledItem = (fco: FeatureViewFCO) => {
  return {
    title: <>Freshness Monitoring Enabled</>,
    description: (
      <EnabledDisabledMaterializationBadge mode="online" value={fco[FeatureViewFCOFields.IS_MATERIALIZATION_ENABLED]} />
    ),
  };
};

const expectedFreshnessItem = (fco: FeatureViewFCO) => {
  return {
    title: <>Expected Freshness</>,
    description: fco[FeatureViewFCOFields.EXPECTED_FRESHNESS] ? (
      fco[FeatureViewFCOFields.EXPECTED_FRESHNESS]
    ) : (
      <EmptyValue />
    ),
  };
};

const primaryEndpointItem = (fco: FeatureViewFCO) => {
  if (fco[FeatureViewFCOFields.ONLINE_STORE_TYPE] !== OnlineStoreType.REDIS) {
    return undefined;
  }

  return {
    title: <>Primary Endpoint</>,
    description: fco[FeatureViewFCOFields.PRIMARY_ENDPOINT] ? (
      fco[FeatureViewFCOFields.PRIMARY_ENDPOINT]
    ) : (
      <EmptyValue />
    ),
  };
};

const publishFullFeaturesItem = (fco: FeatureViewFCO) => {
  return {
    title: <>Publish Full Features</>,
    description: fco[FeatureViewFCOFields.PUBLISH_FULL_FEATURES] ? (
      <EnabledDisabledMaterializationBadge mode={'online'} value={fco[FeatureViewFCOFields.PUBLISH_FULL_FEATURES]} />
    ) : (
      <EmptyValue />
    ),
  };
};

const publishStarttimeItem = (fco: FeatureViewFCO) => {
  return {
    title: <>Publish Start Time</>,
    description: fco[FeatureViewFCOFields.PUBLISH_START_TIME] ? (
      <DateDisplay date={fco[FeatureViewFCOFields.PUBLISH_START_TIME]} showTime />
    ) : (
      <EmptyValue />
    ),
  };
};

type ConfigFunctionType =
  | ((fco: FeatureViewFCO) => AttributeListItem)
  | ((fco: FeatureViewFCO, relatedFCOs: { entities: EntityFCO[] } | undefined) => AttributeListItem | undefined);

const ConfigCardItemMap: Record<ConfigCardItemType, ConfigFunctionType> = {
  [ConfigCardItemType.TYPE]: typeItem,
  [ConfigCardItemType.TIMESTAMP_FIELD]: timestampFieldItem,
  [ConfigCardItemType.STREAM_PROCESSING_MODE]: streamProcessingModeItem,
  [ConfigCardItemType.SKIP_DEFAULT_EXPECTATIONS]: skipDefaultExpectationsItem,
  [ConfigCardItemType.SERVING_TTL]: servingTTLItem,
  [ConfigCardItemType.REQUEST_DATA_SOURCE_KEYS]: requestDataSourceKeysItem,
  [ConfigCardItemType.ONLINE_STORE_TYPE]: onlineStoreTypeItem,
  [ConfigCardItemType.ONLINE_MATERIALIZATION]: onlineServingItem,
  [ConfigCardItemType.OFFLINE_STORE_TYPE]: offlineStoreTypeItem,
  [ConfigCardItemType.OFFLINE_MATERIALIZATION]: offlineServingItem,
  [ConfigCardItemType.MAX_DATA_SOURCE_DELAY]: maxDataSourceDelayItem,
  [ConfigCardItemType.MAX_BACKFILL_INTERVAL]: maxBackfillIntervalItem,
  [ConfigCardItemType.MATERIALIZATION_START_TIME]: materializationStartTimeItem,
  [ConfigCardItemType.MATERIALIZATION_RUNTIME]: materializationRunTimeItem,
  [ConfigCardItemType.BATCH_COMPACTION_ENABLED]: batchCompactionEnabledItem,
  [ConfigCardItemType.MANUAL_TRIGGER_BACKFILL_END_TIME]: manualTriggerBackfillEndTimeItem,
  [ConfigCardItemType.JOIN_KEYS]: joinKeysItem,
  [ConfigCardItemType.INCREMENTAL_BACKFILL]: incrementalBackfillItem,
  [ConfigCardItemType.FRESHNESS_MONITORING_ENABLED]: freshnessMonitoringEnabledItem,
  [ConfigCardItemType.FEATURE_START_TIME]: featureStartTimeItem,
  [ConfigCardItemType.EXPECTED_FRESHNESS]: expectedFreshnessItem,
  [ConfigCardItemType.ENVIRONMENTS]: environmentsConfigItem,
  [ConfigCardItemType.ENTITIES]: relatedEntitiesItem,
  [ConfigCardItemType.DATA_QUALITY_ENABLED]: dataQualityItem,
  [ConfigCardItemType.CACHE_MAX_AGE]: cacheMaxAgeItem,
  [ConfigCardItemType.BATCH_TRIGGER]: batchTriggerItem,
  [ConfigCardItemType.BATCH_SCHEDULE]: batchScheduleItem,
  [ConfigCardItemType.ALERT_EMAIL]: alertEmailItem,
  [ConfigCardItemType.AGGREGATION_SECONDARY_KEY]: aggregationSecondaryKeyItem,
  [ConfigCardItemType.AGGREGATION_INTERVAL]: aggregationIntervalItem,
  [ConfigCardItemType.PRIMARY_ENDPOINT]: primaryEndpointItem,
  [ConfigCardItemType.PUBLISH_FULL_FEATURES]: publishFullFeaturesItem,
  [ConfigCardItemType.PUBLISH_START_TIME]: publishStarttimeItem,
};

interface FeatureViewConfigurationCardProps {
  fco: FeatureViewFCO;
  relatedFCOs?: { entities: EntityFCO[] };
  title: string;
  items: ConfigCardItemType[];
}

interface FeatureViewStorageCardProps {
  fco: FeatureViewFCO;
  onlineItems: ConfigCardItemType[];
  offlineItems: ConfigCardItemType[];
}

export const FeatureViewMaterializationStorageCard: FC<FeatureViewStorageCardProps> = ({
  fco,
  onlineItems,
  offlineItems,
}) => {
  const onlineList = onlineItems.map((itemKey) => ConfigCardItemMap[itemKey](fco, undefined));
  const offlineList = offlineItems.map((itemKey) => ConfigCardItemMap[itemKey](fco, undefined));

  return (
    <FCOCard title={'Storage Configuration'}>
      <CardInnerTitle>
        <h4>Online</h4>
      </CardInnerTitle>
      <AttributesList listItems={onlineList} compressed={false} />
      <Spacer size="l" />
      <CardInnerTitle>
        <h4>Offline</h4>
      </CardInnerTitle>
      <AttributesList listItems={offlineList} compressed={false} />
    </FCOCard>
  );
};

const FeatureViewConfigurationCard: FC<FeatureViewConfigurationCardProps> = ({ fco, relatedFCOs, title, items }) => {
  let itemsOut = items;

  // DON'T SHOW THESE ITEMS FOR RIFT
  if (fco?.BATCH_COMPUTE_MODE === 'BATCH_COMPUTE_MODE_RIFT') {
    itemsOut = items.filter(
      (item) =>
        item !== ConfigCardItemType.DATA_QUALITY_ENABLED && item !== ConfigCardItemType.SKIP_DEFAULT_EXPECTATIONS
    );
  }

  const itemList = itemsOut.map((itemKey) => {
    return ConfigCardItemMap[itemKey](fco, relatedFCOs);
  });

  return (
    <FCOCard title={title}>
      <AttributesList listItems={itemList} compressed={false} />
    </FCOCard>
  );
};

export default FeatureViewConfigurationCard;
