import { useQuery } from '@tanstack/react-query';

import { ServerGroupStatus } from '../types/tecton_proto/common/server_group_status';
import { ServerGroupType } from '../types/tecton_proto/common/server_group_type';
import {
  ListServerGroupsRequest,
  ListServerGroupsResponse,
  ServerGroupInfo,
} from '../types/tecton_proto/servergroupservice/server_group_service';
import { Server_Group_Service } from './index';

export interface ServerGroup {
  serverGroupId?: string;
  name?: string;
  owner?: string;
  description?: string;
  status?: ServerGroupStatus;
  environment?: string;
  type?: ServerGroupType;
}

const fetchListServerGroups = async (args: ListServerGroupsRequest): Promise<ListServerGroupsResponse> => {
  const response = await Server_Group_Service.post(`list-server-groups`, args);
  return response.data;
};

export const useListServerGroups = (workspace: string, enabled: boolean) => {
  const payload: ListServerGroupsRequest = {
    workspace: workspace,
  };

  return useQuery(
    ['list-server-groups', workspace],
    () => {
      return fetchListServerGroups(payload);
    },
    {
      select: (data) => {
        const featureServerGroups =
          data.server_groups?.map((serverGroupInfo: ServerGroupInfo) => {
            const { server_group_id, name, owner, description, status, environment, type } = serverGroupInfo;
            return {
              serverGroupId: server_group_id,
              name,
              owner,
              description,
              status,
              environment,
              type,
            };
          }) ?? [];

        return featureServerGroups;
      },
      enabled,
    }
  );
};
