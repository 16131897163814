import { EmptyPrompt, Loading, useTectonTheme } from '@tecton/ComponentRedesign';
import { FC } from 'react';
import { Line } from 'react-chartjs-2';
import { usePrometheus } from '../../../../feature/query';
import { MonitoringDateRange } from '../../../monitoring/DateRange-Dropdown-util';
import useChartJsRegister from '../../../monitoring/useChartJsRegister';
import { getChartOptions, getGraphColors, transformPrometheusResponse } from './graph-util';

interface PrometheusMonitoringProps {
  chartTitle: string;
  monitoringDateRange: MonitoringDateRange;
  queries: { label: string; query: string }[];
  xAxisLabel: string;
  yAxisLabel: string;
  yTicksCallback: any;
  yGrace: string | number;
  yOverride?: any;
  pluginLegendOverride?: any;
  toolTipLabelCallback: any;
  parseValue?: (arg1: string) => number;
  getMetricLabelCallback?: (arg1: any, label?: string) => string;
  height?: number;
}

const PrometheusMonitoring: FC<PrometheusMonitoringProps> = ({
  chartTitle,
  monitoringDateRange,
  queries,
  xAxisLabel,
  yAxisLabel,
  yGrace,
  yTicksCallback,
  yOverride,
  pluginLegendOverride,
  toolTipLabelCallback,
  parseValue,
  getMetricLabelCallback,
  height,
}) => {
  const state = usePrometheus(queries, monitoringDateRange);
  const { theme } = useTectonTheme();

  useChartJsRegister();

  if (state.isLoading) {
    return <Loading size={'xxl'} />;
  }

  let dataset: any[] = [];

  if (state.data && Array.isArray(state.data)) {
    dataset = state.data.map((response, resultIndex) => {
      const dataRow = response.result.map((result: any, metricIndex: number) => {
        // default parseValue can be null, but the default is parseInt
        const dataset = transformPrometheusResponse(result.values, parseValue);

        // Let the client figure out how to get the label
        const label = getMetricLabelCallback
          ? getMetricLabelCallback(result, queries[resultIndex].label)
          : queries[resultIndex].label;

        // chose which index to use for the color since the payload are different
        const colorIndex = metricIndex > resultIndex ? metricIndex : resultIndex;

        const borderColor = getGraphColors()[colorIndex];
        const backgroundColor = getGraphColors(0.3)[colorIndex];

        return {
          label: label,
          data: dataset,
          borderColor: borderColor,
          backgroundColor: backgroundColor,
        };
      });

      return dataRow;
    });
  }

  const dataFlat = dataset.flat();

  if (dataFlat.length === 0) {
    return (
      <>
        <EmptyPrompt title={<>No Data Available</>} body={<></>} orientation="vertical" variant="search" />
      </>
    );
  }

  const data = {
    datasets: dataFlat,
  };

  const chartOption: any = getChartOptions({
    title: chartTitle,
    xTitle: xAxisLabel,
    yTitle: yAxisLabel,
    yGrace,
    yTicksCallback,
    toolTipLabelCallback,
    unit: monitoringDateRange.unit,
    yOverride,
    pluginLegendOverride,
    fontFamily: theme.font.family,
  });

  return (
    <>
      <Line options={chartOption} data={data} height={height} />
    </>
  );
};

export default PrometheusMonitoring;
