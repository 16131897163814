import { Code, FlexGroupWrapper, FlexItem, LinkIcon, Text } from '@tecton/ComponentRedesign';
import { DATABRICKS_LINKS, TECTON_DOCUMENTATION_LINKS } from '../application-links';
import FormExplanation from './FormExplanation';
import { DataPlatformHeaderHelpWrapper } from './StyledComponents';

//Icons
import { ReactComponent as Book } from '@svg/book.svg';

const DataPlatformHelpColumn = ({ entryWithFocus }: { entryWithFocus?: string }) => {
  return (
    <>
      <FlexGroupWrapper direction="column" gap="l">
        <FlexItem>
          <FlexGroupWrapper direction="column" gap="m">
            <FlexItem>
              <>Help</>
            </FlexItem>
            <FlexItem>
              <DataPlatformHeaderHelpWrapper>Databricks Configurations</DataPlatformHeaderHelpWrapper>
            </FlexItem>
            <FlexItem>
              <Text>
                Tecton leverages Databricks as a data platform to access, materialize, and transform your feature data.
                To connect Tecton to Databricks, you need a few pieces of information:
              </Text>
            </FlexItem>
            <FlexItem>
              <ul style={{ listStyle: 'inside' }}>
                <li>The Databricks Workspace URL</li>
                <li>The Databricks API Token</li>
                <li>(Optional) The Instance Profile ARN</li>
                <li>(Optional) AWS Glue Catalog ID</li>
              </ul>
            </FlexItem>
            <FlexItem>
              These credentials allow Tecton to connect to Databricks, and through Databricks access data in your AWS
              account.
            </FlexItem>
          </FlexGroupWrapper>
        </FlexItem>
        <FormExplanation
          entryWithFocus={entryWithFocus}
          entries={[
            {
              title: 'Databricks Workspace URL',
              id: 'docs-workspace-url',
              children: (
                <Text>
                  <>
                    The URL must start with “https://” and end with “cloud.databricks.com”. You can only set this once.
                    If you would like to change your Databricks workspace once it’s set, please contact Tecton Support.
                  </>
                </Text>
              ),
            },
            {
              title: 'Databricks API Token',
              id: 'docs-api-token',
              children: (
                <Text>
                  <>
                    The Databricks API token with cluster creation privileges for your Databricks workspace. The token
                    must be associated with a user or service principal which has workspace access and allow
                    unrestricted cluster creation entitlements, along with access to the AWS instance profile below.{' '}
                    <LinkIcon
                      linkName={'Learn More'}
                      href={DATABRICKS_LINKS.CLUSTER_ACL}
                      iconType={Book}
                      target="_blank"
                    />
                  </>
                </Text>
              ),
            },
            {
              title: 'Instance Profile',
              id: 'docs-instance-profile',
              children: (
                <FlexGroupWrapper direction="column" gap="s">
                  <FlexItem>
                    <>
                      Optional: An AWS IAM instance profile used by EC2 for data access. Instance profiles are used to
                      provide role information to EC2 instances managed by Databricks. These allow Tecton-managed
                      Databricks clusters to access data without requiring AWS keys. This instance profile should
                      already be added to your Databricks workspace. This field is required in order for Tecton to read
                      data from AWS.
                    </>
                  </FlexItem>
                  <FlexItem>
                    <>
                      See Databricks documentation for info about using instance profiles with Databricks.
                      <LinkIcon
                        linkName={'Learn More'}
                        href={DATABRICKS_LINKS.AWS_GLUE_METASTORE_STEP_5}
                        iconType={Book}
                        target="_blank"
                      />
                    </>
                  </FlexItem>
                </FlexGroupWrapper>
              ),
            },
            {
              title: 'AWS Glue Catalog',
              id: 'docs-glue-catalog',
              children: (
                <FlexGroupWrapper direction="column" gap="s">
                  <FlexItem>
                    <>
                      Optional: Enable access to your AWS Glue Catalog to create Hive data sources with much faster data
                      access. This sets
                      <Code code="spark.databricks.hive.metastore.glueCatalog.enabled" /> to true in your Spark
                      configuration. See Databricks documentation to learn more about Databricks Glue metastore support.
                      <LinkIcon
                        linkName={'Learn More'}
                        href={DATABRICKS_LINKS.AWS_GLUE_METASTORE}
                        iconType={Book}
                        target="_blank"
                      />
                    </>
                  </FlexItem>
                  <FlexItem>
                    <>
                      If the Glue Data Catalog is in a different AWS account than this Databricks workspace, provide the
                      AWS Glue Catalog ID. See Databricks Documentation for more information about permissions required
                      to connect a Glue metastore.{' '}
                      <LinkIcon
                        linkName={'Learn More'}
                        href={TECTON_DOCUMENTATION_LINKS.CONNECTING_DATA_SOURCES_HIVE}
                        iconType={Book}
                        target="_blank"
                      />
                    </>
                  </FlexItem>
                </FlexGroupWrapper>
              ),
            },
          ]}
        />
      </FlexGroupWrapper>
    </>
  );
};

export default DataPlatformHelpColumn;
