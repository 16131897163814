import { EuiBasicTableColumn } from '@tecton';
import {
  Badge,
  ButtonIcon,
  ComboFilterChecklist,
  ComboFilterDateRangeSelector,
  EmptyValue,
  EnabledDisabledBadge,
  FlexGroupWrapper,
  FlexItem,
  IconTip,
  JobStatusBadge,
  Tooltip,
} from '@tecton/ComponentRedesign';
import { TectonDateTimeFormatWithTz } from '@tecton/ComponentRedesign/utils';
import moment from 'moment';
import { useContext } from 'react';

import { FCOType } from '../../../core/types/fcoTypes';
import { JobsSelectorProps } from './types';

import { Link } from 'react-router-dom';
import { Job, MaterializationStatusState } from '../../../api/gql/graphql';
import { useTimezoneSettings } from '../../../components/context/TimezoneContext';
import FCOIconAndName from '../../@tecton/ComponentRedesign/FCOIconAndName';
import { JobsContext } from './JobsContext';
import {
  activeDateFilter,
  durationFromSeconds,
  JobsTableColumnKeys,
  jobsTableColumnLabels,
  numberOfActiveItems,
} from './JobsUtils';

import { ReactComponent as QuestionInCircle } from '@svg/question-in-circle.svg';
import { useUserSettings } from '../../context/UserSettingsContext';

export const useGetJobsFilterTypes = () => {
  const { updateCountsFilter, jobsFilterState, updateDateFilter, updateCategoricalSelectorState, isFeatureView } =
    useContext(JobsContext);
  const {
    statuses,
    workspaces,
    taskType,
    taskTypeForDisplays,
    manuallyTriggered,
    numAttempts,
    featureViewStart,
    featureViewEnd,
    featureViews,
    writesOnline,
    writesOffline,
    duration,
  } = jobsFilterState.selectorState as JobsSelectorProps;

  const filterTypes = {
    status: {
      numFilters: statuses.length,
      hasActiveFilters: numberOfActiveItems(statuses) > 0,
      numActiveFilters: numberOfActiveItems(statuses),
      label: 'Status',
      filterElement: (
        <ComboFilterChecklist
          items={statuses}
          sort
          renderOption={(option: any) => {
            return <JobStatusBadge status={option.value} />;
          }}
          setItems={(items) => {
            updateCategoricalSelectorState({ prop: 'statuses', items });
          }}
        />
      ),
    },
    workspaces: {
      numFilters: workspaces.length,
      hasActiveFilters: numberOfActiveItems(workspaces) > 0,
      numActiveFilters: numberOfActiveItems(workspaces),
      label: 'Workspace',
      hidden: isFeatureView,
      filterElement: (
        <ComboFilterChecklist
          sort
          items={workspaces}
          setItems={(items) => {
            updateCategoricalSelectorState({ prop: 'workspaces', items });
          }}
        />
      ),
    },
    featureViews: {
      numFilters: featureViews.length,
      hasActiveFilters: numberOfActiveItems(featureViews) > 0,
      numActiveFilters: numberOfActiveItems(featureViews),
      hidden: isFeatureView,
      label: 'Feature View',
      filterElement: (
        <ComboFilterChecklist
          items={featureViews}
          sort
          searchable
          setItems={(items) => {
            updateCategoricalSelectorState({ prop: 'featureViews', items });
          }}
          renderOption={(option) => {
            return <FCOIconAndName name={option.label ?? ''} type={FCOType.FEATURE_VIEW} />;
          }}
        />
      ),
    },
    fvType: {
      numFilters: taskTypeForDisplays.length,
      hasActiveFilters: numberOfActiveItems(taskTypeForDisplays) > 0,
      numActiveFilters: numberOfActiveItems(taskTypeForDisplays),
      label: 'Task Type',
      filterElement: (
        <ComboFilterChecklist
          items={taskTypeForDisplays}
          setItems={(items) => {
            updateCategoricalSelectorState({ prop: 'taskTypeForDisplays', items });
          }}
        />
      ),
    },
    featureStartTime: {
      numFilters: activeDateFilter(featureViewStart) ? 1 : undefined,
      hasActiveFilters: activeDateFilter(featureViewStart),
      numActiveFilters: activeDateFilter(featureViewStart) ? 1 : undefined,
      label: 'Start Time',
      filterElement: (
        <ComboFilterDateRangeSelector
          startDate={featureViewStart.start.toDate()}
          endDate={featureViewStart.end.toDate()}
          setStartDate={(d: Date) => {
            updateDateFilter('featureViewStart', d);
          }}
          setEndDate={(d: Date) => {
            updateDateFilter('featureViewStart', null, d);
          }}
        />
      ),
    },
    featureEndTime: {
      numFilters: activeDateFilter(featureViewEnd) ? 1 : undefined,
      hasActiveFilters: activeDateFilter(featureViewEnd),
      numActiveFilters: activeDateFilter(featureViewEnd) ? 1 : undefined,
      label: 'End Time',
      filterElement: (
        <ComboFilterDateRangeSelector
          startDate={featureViewEnd.start.toDate()}
          endDate={featureViewEnd.end.toDate()}
          setStartDate={(d: Date) => {
            updateDateFilter('featureViewEnd', d);
          }}
          setEndDate={(d: Date) => {
            updateDateFilter('featureViewEnd', null, d);
          }}
        />
      ),
    },
    writesOnline: {
      numFilters: writesOnline?.length,
      hasActiveFilters: numberOfActiveItems(writesOnline) > 0,
      numActiveFilters: numberOfActiveItems(writesOnline),
      label: 'Writes Online',
      filterElement: (
        <ComboFilterChecklist
          items={writesOnline}
          single
          setItems={(items) => {
            updateCategoricalSelectorState({ prop: 'writesOnline', items });
          }}
          renderOption={(option) => {
            return <Badge label={option.label} />;
          }}
        />
      ),
    },
    writesOffline: {
      numFilters: writesOffline?.length,
      hasActiveFilters: numberOfActiveItems(writesOffline) > 0,
      numActiveFilters: numberOfActiveItems(writesOffline),
      label: 'Writes Offline',
      filterElement: (
        <ComboFilterChecklist
          items={writesOffline}
          single
          setItems={(items) => {
            updateCategoricalSelectorState({ prop: 'writesOffline', items });
          }}
          renderOption={(option) => {
            return <Badge label={option.label} />;
          }}
        />
      ),
    },
    numAttempts: {
      numFilters: numAttempts.length,
      hasActiveFilters: numberOfActiveItems(numAttempts) > 0,
      numActiveFilters: numberOfActiveItems(numAttempts) ? 1 : 0,
      label: 'Attempts',
      filterElement: (
        <ComboFilterChecklist
          items={numAttempts}
          key={'selector_attempts'}
          name={'attempts'}
          single
          setItems={(items) => {
            const range = items.filter((item) => item.checked === 'on')[0] as unknown as { value: number[] };
            updateCountsFilter({ prop: 'numAttempts', items, range: range.value });
          }}
        />
      ),
    },
    duration: {
      numFilters: duration.length,
      hasActiveFilters: numberOfActiveItems(duration) > 0,
      numActiveFilters: numberOfActiveItems(duration),
      label: 'Duration',
      filterElement: (
        <ComboFilterChecklist
          items={duration}
          key={'selector_duration'}
          name={'duration'}
          setItems={(items) => {
            updateCategoricalSelectorState({ prop: 'duration', items });
          }}
          single={true}
        />
      ),
    },
    manuallyTriggered: {
      numFilters: manuallyTriggered.length,
      hasActiveFilters: numberOfActiveItems(manuallyTriggered) > 0,
      numActiveFilters: numberOfActiveItems(manuallyTriggered),
      label: 'Trigger type',
      filterElement: (
        <ComboFilterChecklist
          key={'selector_triggerType'}
          items={manuallyTriggered}
          name={'triggerType'}
          single
          setItems={(items) => {
            updateCategoricalSelectorState({ prop: 'manuallyTriggered', items });
          }}
          renderOption={(option) => {
            return <Badge label={option.label} />;
          }}
        />
      ),
    },
  };

  return { filterTypes };
};

const getActionButtonTooltipText = (
  job: Job,
  actionableWorkspaces: string[],
  tooltipText: string,
  isAdmin?: boolean
): string => {
  // Does the user have actionable access to our jobs action buttons?
  if (
    !job?.allowRetry &&
    !job.allowOverwrite &&
    !job.allowCancel &&
    !actionableWorkspaces.includes(job.workspace ?? '') // Check to see if this user has access a given workspace with operator role or greater.
  ) {
    if (isAdmin) {
      return `You do not have access to the ${job.workspace} workspace.`;
    }
    // Tell the user why our action button is disabled. The need at least operator or greater role.
    return `You do not have access to the ${job.workspace} workspace.`;
  }

  // Return the regular tool tip message since our user have access
  return tooltipText;
};

export const useGetJobsColumns = ({
  displayedColumns,
  setShowCancelModal,
  setShowRetryModal,
  setCurrentJobAction,
}: any) => {
  const { timezone } = useTimezoneSettings();
  const { isAdmin, actionableWorkspaces, featureEnabled } = useUserSettings();
  const noOperatorRoleOrGreaterAccess = actionableWorkspaces && actionableWorkspaces?.length === 0;
  const canActionIcon = !isAdmin && noOperatorRoleOrGreaterAccess;

  const JobsColumns: EuiBasicTableColumn<Job>[] = [
    {
      name: 'Status',
      render: (_, job: Job) => {
        const asStatus = job.latestStatus?.value as MaterializationStatusState;
        return <>{asStatus ? <JobStatusBadge status={asStatus} /> : <EmptyValue />}</>;
      },
      field: 'latestStatus',
    },
    {
      name: 'Type',
      render: (_, job: Job) => {
        return <>{job.fvTaskTypeForDisplay?.label}</>;
      },

      field: 'fvTaskTypeForDisplay',
    },
    {
      name: 'Feature View',
      render: (_, job: Job) => {
        return (
          <Link to={`../repo/${job.workspace}/features/${job.featureView}`}>
            <FCOIconAndName name={job.featureView ?? ''} type={FCOType.FEATURE_VIEW} />
          </Link>
        );
      },
      field: 'featureView',
    },
    {
      name: 'Attempts',
      sortable: true,
      render: (_, job: Job) => <>{job.attempts ? job.attempts.length : <EmptyValue />}</>,
      field: 'numAttempts',
    },
    {
      name: 'Workspace',
      render: (_, job: Job) =>
        job.workspace ? <Link to={`/repo/${job.workspace}/home`}>{job.workspace}</Link> : <EmptyValue />,
      field: 'workspace',
    },
    {
      name: 'Latest State Change',
      sortable: true,
      render: (_, job: Job) =>
        job.lastTaskStateChange ? (
          TectonDateTimeFormatWithTz(moment(job.lastTaskStateChange), timezone)
        ) : (
          <EmptyValue />
        ),
      field: 'lastTaskStateChange',
    },
    {
      name: 'Online',
      render: (_, job: Job) => <EnabledDisabledBadge enabled={job.writesOnline ?? false} />,
      field: 'writesOnline',
    },
    {
      name: 'Offline',
      render: (_, job: Job) => <EnabledDisabledBadge enabled={job.writesOffline ?? false} />,
      field: 'writesOffline',
    },
    {
      name: jobsTableColumnLabels['featureStartTime'],
      sortable: true,
      render: (_, job: Job) =>
        job.featureStartTime ? TectonDateTimeFormatWithTz(moment(job.featureStartTime), timezone) : <EmptyValue />,
      field: 'featureStartTime',
    },
    {
      name: jobsTableColumnLabels['featureEndTime'],
      sortable: true,
      render: (_, job: Job) =>
        job.featureEndTime ? TectonDateTimeFormatWithTz(moment(job.featureEndTime), timezone) : <EmptyValue />,
      field: 'featureEndTime',
    },
    {
      name: jobsTableColumnLabels['latestDuration'],
      sortable: true,
      render: (_, job: Job) =>
        job.attempts && job.attempts[0]?.duration ? durationFromSeconds(job.attempts[0]?.duration) : <EmptyValue />,
      field: 'latestDuration',
    },
    {
      name: 'Trigger Type',
      render: (_, job: Job) => (job?.manuallyTriggered ? job.manuallyTriggered : <EmptyValue />),
      field: 'manuallyTriggered',
    },
    {
      name: (
        <>
          {jobsTableColumnLabels['actions']}
          {canActionIcon && (
            <IconTip
              content={`Actions are available to admins or users with operator role or greater.`}
              type={QuestionInCircle}
            />
          )}
        </>
      ),
      render: (_, job: Job) => {
        return (
          <>
            <FlexGroupWrapper gap="l" direction={'row'} alignItems="center" justifyContent="flexStart">
              <FlexItem grow={false}>
                <Tooltip
                  trigger={
                    <ButtonIcon
                      iconType={'refresh'}
                      size="xs"
                      onClick={() => {
                        setCurrentJobAction(job);
                        setShowRetryModal(true);
                      }}
                      aria-label={'retry'}
                      variant={job?.allowRetry ? 'emptyAction' : 'disabledAction'}
                    />
                  }
                  content={getActionButtonTooltipText(
                    job,
                    actionableWorkspaces ?? [],
                    job.allowRetry ? 'Retry' : `Retry is suspended when the status  is ${job.latestStatus?.label}.`,
                    isAdmin
                  )}
                />
              </FlexItem>
              <FlexItem grow={false}>
                <Tooltip
                  trigger={
                    <ButtonIcon
                      iconType={'merge'}
                      size="xs"
                      onClick={() => {
                        setCurrentJobAction(job);
                        setShowRetryModal(true);
                      }}
                      aria-label={'overwrite'}
                      variant={job?.allowOverwrite ? 'emptyAction' : 'disabledAction'}
                    />
                  }
                  content={getActionButtonTooltipText(
                    job,
                    actionableWorkspaces ?? [],
                    job.allowOverwrite
                      ? 'Overwrite'
                      : `Overwrite is suspended when the status  is ${job.latestStatus?.label}.`,
                    isAdmin
                  )}
                />
              </FlexItem>
              <FlexItem grow={false}>
                <Tooltip
                  trigger={
                    <ButtonIcon
                      iconType={'cross'}
                      size="xs"
                      onClick={() => {
                        setCurrentJobAction(job);
                        setShowCancelModal(true);
                      }}
                      aria-label={'cancel'}
                      variant={job?.allowCancel ? 'emptyAction' : 'disabledAction'}
                    />
                  }
                  content={getActionButtonTooltipText(
                    job,
                    actionableWorkspaces ?? [],
                    job.allowCancel ? 'Cancel' : `Cancel is suspended when the status  is ${job.latestStatus?.label}.`,
                    isAdmin
                  )}
                />
              </FlexItem>
            </FlexGroupWrapper>
          </>
        );
      },
      field: 'actions',
    },
  ];

  const displayedColumnObjects: EuiBasicTableColumn<Job>[] = JobsColumns.filter((column) => {
    if (displayedColumns) {
      return (
        !('field' in column) || (column.field && displayedColumns.indexOf(column.field as JobsTableColumnKeys) >= 0)
      );
    } else {
      return true;
    }
  });

  return { displayedColumnObjects };
};
