import React, { FC } from 'react';
import { DataSourceFCO, DataSourceFCOFields, StreamingDataSourceType } from '../../../core/types/fcoTypes';

import { FCOCard, Monospace, EmptyValue, EnabledDisabledBadge, ViewDefinitionFlyout } from '@tecton/ComponentRedesign';

interface StreamConfigCardProps {
  source: DataSourceFCO;
}

const monospaceOrEmptyField = (property: unknown) => {
  if (property) {
    return (
      <Monospace>
        <>{property}</>
      </Monospace>
    );
  }

  return <EmptyValue />;
};

const postProcessorField = (fco: DataSourceFCO) => {
  if (fco.rawStreamTranslator) {
    return {
      title: <>Post-Processor</>,
      content: (
        <>
          <ViewDefinitionFlyout
            type={fco.rawStreamTranslator.name ?? ''}
            definition={fco.rawStreamTranslator.body ?? ''}
          />
        </>
      ),
    };
  } else {
    return {
      title: <>Post-Processor</>,
      content: <EmptyValue />,
    };
  }
};

const KinesisConfigCard: FC<StreamConfigCardProps> = ({ source }) => {
  const listItems = [
    {
      title: <>Stream Name</>,
      content: monospaceOrEmptyField(source.streamDataSource?.kinesis_data_source?.stream_name),
    },
    {
      title: <>Region</>,
      content: monospaceOrEmptyField(source.streamDataSource?.kinesis_data_source?.region),
    },
    {
      title: <>Timestamp Field</>,
      content: monospaceOrEmptyField(source.streamDataSource?.time_column),
    },
    {
      title: <>Initial Stream Position</>,
      content: monospaceOrEmptyField(source.streamDataSource?.stream_config?.initial_stream_position),
    },
    {
      title: <>Watermark Delay Threshold</>,
      content: monospaceOrEmptyField(source.streamDataSource?.stream_config?.watermark_delay_threshold),
    },
    postProcessorField(source),
  ];

  return <FCOCard title="Kinesis Stream Config" items={listItems} />;
};

const KafkaConfigCard: FC<StreamConfigCardProps> = ({ source }) => {
  const listItems = [
    {
      title: <>Kafka Bootstrap Servers</>,
      content: monospaceOrEmptyField(source.streamDataSource?.kafka_data_source?.bootstrap_servers),
    },
    {
      title: <>Topics</>,
      content: monospaceOrEmptyField(source.streamDataSource?.kafka_data_source?.topics),
    },
    {
      title: <>Timestamp Field</>,
      content: monospaceOrEmptyField(source.streamDataSource?.time_column),
    },
    {
      title: <>Watermark Delay Threshold</>,
      content: monospaceOrEmptyField(source.streamDataSource?.stream_config?.watermark_delay_threshold),
    },
    postProcessorField(source),
  ];
  return <FCOCard title="Kafka Stream Config" items={listItems} />;
};

const SparkStreamConfigCard: FC<StreamConfigCardProps> = ({ source }) => {
  const listItems = [
    {
      title: <>Data Source Function</>,
      content: (
        <>
          <ViewDefinitionFlyout
            type={source.streamDataSource?.spark_data_source_function?.function?.name ?? ''}
            definition={source.streamDataSource?.spark_data_source_function?.function?.body ?? ''}
          />
        </>
      ),
    },
  ];

  return <FCOCard title="Spark Stream Config" items={listItems} />;
};

const PushStreamSourceCard: FC<StreamConfigCardProps> = ({ source }) => {
  const listItems = [
    {
      title: <>Offline Logging Enabled</>,
      content: <EnabledDisabledBadge enabled={source.streamDataSource?.push_source?.log_offline ?? false} />,
    },
  ];

  return <FCOCard title="Push Config" items={listItems} />;
};

const StreamConfigCard: FC<StreamConfigCardProps> = ({ source }) => {
  if (source[DataSourceFCOFields.STREAM_SOURCE] === undefined) {
    return <></>;
  }

  switch (source[DataSourceFCOFields.STREAM_SOURCE]) {
    case StreamingDataSourceType.S3:
      return <></>; // Legacy and does not need a card
    case StreamingDataSourceType.KAFKA:
      return <KafkaConfigCard source={source} />;
    case StreamingDataSourceType.KINESIS:
      return <KinesisConfigCard source={source} />;
    case StreamingDataSourceType.DATA_SOURCE_FUNCTION:
      return <SparkStreamConfigCard source={source} />;
    case StreamingDataSourceType.PUSH:
      return <PushStreamSourceCard source={source} />;
    case StreamingDataSourceType.UNKNOWN:
      return <></>;
  }

  return <></>;
};

export default StreamConfigCard;
