import React, { FC } from 'react';
import { Box } from '@mui/material';
import { AccessControlsWorkspace } from '../../../../feature/access-control/types';
import style from './style.module.scss';
import AccessControlWorkspaceNameSpan from '../../../../feature/access-control/Components/AccessControlWorkspaceNameSpan';

interface NewWorkspaceSelectorOptionAccessibleProperties {
  workspace: AccessControlsWorkspace;
  propsToPass: any;
}

const NewWorkspaceSelectorOptionAccessible: FC<NewWorkspaceSelectorOptionAccessibleProperties> = ({
  workspace,
  propsToPass,
}) => {
  return (
    <Box className={`${style.workspaceSelectorOptionSelectable} ${style.workspaceSelectorOuter}`} {...propsToPass}>
      <Box className={`${style.workspaceSelectorOptionSelectable} ${style.workspaceSelectorOption}`}>
        <AccessControlWorkspaceNameSpan workspace={workspace} showIcon={true} />
      </Box>
    </Box>
  );
};

export default NewWorkspaceSelectorOptionAccessible;
