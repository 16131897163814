import { FC } from 'react';
import { MonitoringDateRange } from '../../monitoring/DateRange-Dropdown-util';

import { interval } from '../../../feature/feature-services/feature-service/component/ChartDefaultParams';
import { formatBytes } from '../../../utils/math-utils';
import { X_AXIS_LABEL } from '../../monitoring/GraphContainer';

// generated
import { FeatureViewFCO } from '../../../core/types/fcoTypes';

import { EuiSelectableOption } from '@tecton/ComponentRedesign';
import PrometheusMonitoring from '@tecton/ComponentRedesign/Charts/PrometheusMonitoring';
import { getTableQuery } from './DynamoDBP99Latency';
import { getMetricLabel } from './OnlineStoreLatency';
interface DynamoDBP99ResponseSizeProps {
  monitoringDateRange: MonitoringDateRange;
  selectedOption: EuiSelectableOption<{ option?: FeatureViewFCO }>;
  selectedGraphType: EuiSelectableOption<{ option?: string }>;
  relatedFvs: FeatureViewFCO[];
}

const DynamoDBP99ResponseSize: FC<DynamoDBP99ResponseSizeProps> = ({
  monitoringDateRange,
  selectedOption,
  selectedGraphType,
  relatedFvs,
}) => {
  const chartTitle = '';
  const yAxisLabel = 'Bytes';
  const featureViewIds = selectedOption?.data?.featureViewIds ?? [];
  const tableQuery = getTableQuery(featureViewIds, relatedFvs);

  const queries = [
    {
      label: `P${selectedGraphType?.data?.value}`,
      query: `histogram_quantile(0.${selectedGraphType?.data?.value}, sum(rate(dynamodb_response_size_per_query_by_table_bucket_rollup_no_pod{tecton_cluster=~'.*',  aws_region=~'.*', table=~'${tableQuery}'}[${interval}])) by(le, table))`,
    },
  ];

  const yTicksCallback = (value: any) => {
    const y = formatBytes(value, 0);
    return `${y}`;
  };

  const toolTipLabelCallback = (context: any) => {
    const { y } = context.parsed;
    const { label } = context.dataset;
    const value = formatBytes(y, 0);

    const labelText = `${label}: ${value}`;
    return labelText;
  };

  const getMetricLabelCallback = (result: any, label?: string) => {
    return getMetricLabel(result, relatedFvs, label, selectedOption?.data?.isAllFeatures);
  };

  const yOverride = {
    title: {
      display: true,
      text: yAxisLabel,
      font: {
        size: 12,
      },
    },
    min: 0,

    ticks: {
      callback: yTicksCallback,
    },
  };

  return (
    <>
      <PrometheusMonitoring
        chartTitle={chartTitle}
        monitoringDateRange={monitoringDateRange}
        queries={queries}
        xAxisLabel={X_AXIS_LABEL}
        yAxisLabel={yAxisLabel}
        yTicksCallback={yTicksCallback}
        yGrace={0}
        toolTipLabelCallback={toolTipLabelCallback}
        yOverride={yOverride}
        parseValue={parseFloat}
        getMetricLabelCallback={selectedOption?.data?.isAllFeatures ? getMetricLabelCallback : undefined}
        height={160}
      />
    </>
  );
};

export default DynamoDBP99ResponseSize;
