import React, { FC } from 'react';
import { Grid, Stack, Typography } from '@mui/material';

interface NoFcosMessageProps {
  fcoLabel: string;
  documentationLink: string;
}

const NoFcosMessage: FC<NoFcosMessageProps> = ({ fcoLabel, documentationLink }) => {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '60vh' }}
    >
      <Grid item xs={3}>
        <Stack spacing={1}>
          <Typography style={{ fontSize: 24 }} align="center">
            No {fcoLabel} have been created in this Workspace.
          </Typography>
          <Typography variant="subtitle2" align="center">
            <div>
              {fcoLabel} are created using the Tecton SDK.
              <a href={documentationLink} target="_blank" rel="noreferrer">
                {' '}
                Learn more in Tecton&apos;s Documentation.
              </a>
            </div>
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default NoFcosMessage;
