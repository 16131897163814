import React, { FC } from 'react';
import _sortBy from 'lodash/sortBy';

import TitleValueLayout from './TitleValueLayout';
import PopoverAccountTypeDetails from './PopoverAccountTypeDetails';
import { AccountType } from '../../acl/types';

import { FlexGroup, FlexItem, Icon, EditableFieldWrapper } from '@tecton/ComponentRedesign';

import EditIcon from '@svg/edit.svg';

export interface SimpleGroup {
  groupName: string;
  groupId: string;
}

interface PrincipalDetailsAccountTypeProps {
  accountType: AccountType;
  groups: SimpleGroup[];
  onEditAccountType: VoidFunction;
}

const PrincipalDetailsAccountType: FC<PrincipalDetailsAccountTypeProps> = ({
  accountType,
  groups,
  onEditAccountType,
}) => {
  return (
    <>
      <TitleValueLayout
        title={`Account Type`}
        valueContent={
          <>
            <EditableFieldWrapper
              paddingTop="0px"
              onClick={() => {
                onEditAccountType();
              }}
            >
              <FlexGroup justifyContent="spaceBetween" gutterSize="s">
                <FlexItem grow={false}>
                  <PopoverAccountTypeDetails
                    accountType={accountType}
                    groups={groups}
                    onRelatedStatusClicked={() => {
                      onEditAccountType();
                    }}
                  />
                </FlexItem>
                <FlexItem grow={false} style={{ alignSelf: 'center' }}>
                  <Icon type={EditIcon} />
                </FlexItem>
              </FlexGroup>
            </EditableFieldWrapper>
          </>
        }
      />
    </>
  );
};

export default PrincipalDetailsAccountType;
