import React from 'react';
import { NewMaterializationParams } from '../../types/tecton_proto/data/feature_view';
import { durationToSeconds, secondsToHumanFriendlyString } from '../../utils/misc-utils';
import { LIGHT_COLOR_SCHEME_OVERRIDES } from '../@tecton/TectonThemeProvider';

export const EMPTY_ATTRIBUTE_VALUE = <span style={{ color: LIGHT_COLOR_SCHEME_OVERRIDES.mediumShade }}>n/a</span>;

export enum MATERIALIZATION_OFFLINE_TYPE {
  UNKNOWN = '-',
  SUB_DIRECTORY = 'Subdirectory Override',
  DELTA = 'Delta',
  PARQUET = 'Parquet',
}

export interface FCOAttributeListItem {
  title: NonNullable<React.ReactNode>;
  description: NonNullable<React.ReactNode>;
  rawValue?: any;
}

const VALUE_TO_HUMAN_READABLE_VALUE_MAP: Record<string, string> = {
  on_demand: 'On Demand',
  extra_pip_dependencies: 'Extra pip Dependencies',
  pinned_spark_version: 'Pinned Spark Version',
  databricks_spark_version: 'Databricks Spark Version',
  emr_spark_version: 'EMR Spark Version',
  scheduled: 'Scheduled',
  dynamo: 'Dynamo',
  redis: 'Redis',
  bigtable: 'Bigtable',
};

export const convertValueToHumanReadableValue = (value: string): string => {
  // return the original value if it's not listed on the map
  return VALUE_TO_HUMAN_READABLE_VALUE_MAP[`${value}`] ?? value;
};

export const getOfflineDeltaConfigTimePartition = (materializationParams: NewMaterializationParams): string => {
  let timePartition = 'n/a';

  if (materializationParams?.offline_store_config?.delta?.time_partition_size) {
    timePartition = secondsToHumanFriendlyString(
      durationToSeconds(materializationParams?.offline_store_config?.delta?.time_partition_size as string) ?? '-'
    );
  }

  return timePartition;
};

export const FCOTypeNiceName: Record<string, string> = {
  dataSource: 'Data Source',
  transformation: 'Transformation',
  featureView: 'Feature View',
};
