import React, { FC } from 'react';
import { ButtonEmpty } from '@tecton/ComponentRedesign';
import { aclIcons } from './RoleIconName';

interface AccountTypeIconNameProps {
  accountType: string;
  onAccountTypeRowClicked?: VoidFunction;
}

const AccountTypeIconName: FC<AccountTypeIconNameProps> = ({ accountType, onAccountTypeRowClicked }) => {
  return (
    <ButtonEmpty
      size="s"
      iconType={aclIcons[accountType]}
      onClick={() => {
        onAccountTypeRowClicked?.call(this);
      }}
    >
      {accountType}
    </ButtonEmpty>
  );
};

export default AccountTypeIconName;
