import { Chip, Stack, Typography } from '@mui/material';
import React, { FC } from 'react';
import NoWorkspacesAvailableTerminalCommand from './NoWorkspacesTerminalCommand';
import style from './style.module.scss';

interface NoWorkspacesAvailableCreateWorkspaceInstructionsColumnProperties {}

const NoWorkspacesAvailableCreateWorkspaceInstructionsColumn: FC<
  NoWorkspacesAvailableCreateWorkspaceInstructionsColumnProperties
> = () => {
  return (
    <div className="pa3">
      <Stack spacing={4}>
        <Stack spacing={2}>
          <div>
            <div className={style.instructionsLeadParagraph}>
              <Typography variant="h4">Create Your First Workspace</Typography>
              <p style={{ marginBottom: '6px' }}>
                Follow these instructions to create your first workspace in Tecton. This will be a{' '}
                <span className="fw6">development workspace</span>, which is appropriate for learning and exploration in
                Tecton and does not trigger live materialization jobs or allow online serving. See our{' '}
                <a
                  href="https://docs.tecton.ai/docs/setting-up-tecton/administration-setup/creating-a-workspace-and-adding-users-to-the-workspace"
                  target="_blank"
                  rel="noreferrer"
                >
                  documentation on Workspaces
                </a>{' '}
                for more information.
              </p>
              <p>
                The workspace that you create will by empty. You can add workspace objects, such as data sources and
                feature views.
              </p>
            </div>

            <div className={style.instructionsStep}>
              <div className={style.instructionsHeading}>
                <Typography variant="h5">
                  <Chip label="Step 1" size="small" /> Install the Tecton CLI
                </Typography>
              </div>
              <div className={style.instructionsStepContent}>
                <p>To install the CLI, open a terminal in your desired directory, and run the following command:</p>
                <NoWorkspacesAvailableTerminalCommand code={"pip install 'tecton[pyspark]'"} />
                <p>
                  Note, you will need to make sure that you have an appropriate Python environment installed locally.
                  See{' '}
                  <a
                    href="https://docs.tecton.ai/docs/setting-up-tecton/development-setup/installing-the-tecton-cli"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Tecton CLI Setup
                  </a>{' '}
                  documentation for more information.
                </p>
              </div>
            </div>

            <div className={style.instructionsStep}>
              <div className={style.instructionsHeading}>
                <Typography variant="h5">
                  <Chip label="Step 2" size="small" /> Log in to the CLI
                </Typography>
              </div>
              <div className={style.instructionsStepContent}>
                <p>To log in to Tecton, run the command:</p>
                <NoWorkspacesAvailableTerminalCommand code={'tecton login'} />
              </div>
            </div>

            <div className={style.instructionsStep}>
              <div className={style.instructionsHeading}>
                <Typography variant="h5">
                  <Chip label="Step 3" size="small" /> Create a Workspace
                </Typography>
              </div>
              <div className={style.instructionsStepContent}>
                <p>To create your first workspace, run the command:</p>
                <NoWorkspacesAvailableTerminalCommand code={'tecton workspace create my-workspace'} />
              </div>
            </div>

            <div className={style.instructionsStep}>
              <div className={style.instructionsHeading}>
                <Typography variant="h5">
                  <Chip label="Step 4" size="small" /> Clone a Repository or Create Your Own
                </Typography>
              </div>
              <div className={style.instructionsStepContent}>
                <p>
                  If your organization already has a feature repository, make a local clone. Then, navigate to the
                  directory and run:
                </p>
                <NoWorkspacesAvailableTerminalCommand code={'tecton apply'} />
                <p>If you don&apos;t have a repository to clone, see the guide for creating a new one.</p>
                <p>
                  Note: A live workspace incurs materialization costs, while a development workspace does not; running
                  tecton apply in a development workspace will not have an impact on materialization costs in that
                  workspace.
                </p>
              </div>
            </div>

            <div className={style.instructionsStep}>
              <div className={style.instructionsHeading}>
                <Typography variant="h5">
                  <Chip label="Step 5" size="small" /> Return to the WebUI
                </Typography>
              </div>
              <div className={style.instructionsStepContent}>
                <p>With your active repository in place, refresh this page to begin using Tecton.</p>
              </div>
            </div>
          </div>
        </Stack>
      </Stack>
    </div>
  );
};

export default NoWorkspacesAvailableCreateWorkspaceInstructionsColumn;
