export type ServiceRoutes = 'Dataflow' | 'Metadata' | 'Materialization' | 'Feature Views' | 'Monitoring' | 'Datasets';

export const getFeatureServiceRoutes = (): Record<ServiceRoutes, string> => {
  return {
    Dataflow: 'dataflow',
    Metadata: 'overview',
    Materialization: 'materialization',
    'Feature Views': 'feature-views',
    Monitoring: 'monitoring',
    Datasets: 'dataset',
  };
};

export const getFeatureServiceRouteName = (pathname: string): ServiceRoutes => {
  const pathSegments = pathname.split('/');
  const lastPathSegment = pathSegments[pathSegments.length - 1];
  const routes = getFeatureServiceRoutes();
  const record = Object?.entries(routes)?.find((record) => {
    return record[1] === lastPathSegment;
  });

  return (record?.[0] ?? 'Dataflow') as ServiceRoutes;
};
