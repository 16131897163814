import React, { useContext } from 'react';
import { EdgeProps, getBezierPath, getSmoothStepPath, Position, Edge } from '@xyflow/react';
import { DataFlowPathProperties } from './dataFlowTypes';
import { DetailsPanelContext } from './DetailsPanelFocusProvider';
import { DataFlowFocusContext } from './DataFlowFocusProvider';
import DataFlowEdge, { EdgeVisibilityState } from './DataFlowEdge';
import { VisibleAnimationRecord } from './highlightLogic/highlight-utils';

const NONE_VISIBLE: VisibleAnimationRecord = {
  showOfflineReadPath: false,
  showOnlineServingPath: false,
  showBatchMaterializationPath: false,
  showStreamMaterializationPath: false,
};

type CustomEdge = Edge<DataFlowPathProperties>;

const DFEdgeWithContext: React.FC<EdgeProps<CustomEdge> & { path?: string }> = (props) => {
  const { source, sourceX, sourceY, sourcePosition, target, targetX, targetY, targetPosition, path, data } = props;
  const pathProperties = data;

  const { hoveredNodeId, shouldEdgeBeVisible, legendInFocus } = useContext(DataFlowFocusContext);
  const { nodesMap, reduceAnimation, DEBUG_MODE } = useContext(DetailsPanelContext);

  const shouldReduceAnimation = DEBUG_MODE || legendInFocus || reduceAnimation;

  let [edgePath] = getSmoothStepPath({
    sourceX: sourceX - 3,
    sourceY,
    sourcePosition,
    targetX: targetX + 6,
    targetY,
    targetPosition,
  });

  const isCosmeticRequestPath = !!data?.isCosmeticRequestPath;

  if (nodesMap[source] && isCosmeticRequestPath) {
    const sourceType = nodesMap[source].type;

    [edgePath] = getBezierPath({
      sourceX: targetX + (sourceType === 'request_data_source' ? 4 : -4),
      sourceY: targetY,
      sourcePosition: sourceType === 'request_data_source' ? Position.Left : Position.Right,
      targetX: sourceX + (sourceType === 'request_data_source' ? 4 : -4),
      targetY: sourceY,
      targetPosition: sourceType === 'request_data_source' ? Position.Right : Position.Left,
      curvature: sourceType === 'request_data_source' ? 0 : 40,
    });
  }

  const visibility = shouldEdgeBeVisible(source, target, pathProperties || {});

  const { showOfflineReadPath, showOnlineServingPath, showBatchMaterializationPath, showStreamMaterializationPath } =
    visibility ? visibility : NONE_VISIBLE;

  const displayMode: EdgeVisibilityState = hoveredNodeId ? (visibility ? 'in_focus' : 'out_of_focus') : 'default';

  return (
    <DataFlowEdge
      path={path || edgePath}
      displayMode={displayMode}
      reduceAnimation={shouldReduceAnimation}
      showOfflineReadPath={showOfflineReadPath}
      showOnlineServingPath={showOnlineServingPath}
      showBatchMaterializationPath={showBatchMaterializationPath}
      showStreamMaterializationPath={showStreamMaterializationPath}
    />
  );
};

export default DFEdgeWithContext;
