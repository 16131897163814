import React, { FC } from 'react';
import { Box } from '@mui/material';
import Logo from '../../../assets/image/tecton-ui/logo/logo-white.svg';
import WhatsNew from '../WhatsNew';
import DocumentationIcon from '../../../assets/image/tecton-ui/icon/documentation.svg';

interface WorkspaceSelectionMockNavigationProperties {}

const WorkspaceSelectionMockNavigation: FC<WorkspaceSelectionMockNavigationProperties> = () => {
  return (
    <Box
      sx={{
        width: '230px',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        overflowY: 'hidden',
      }}
    >
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          height: '84px',
          justifyContent: 'center',
          position: 'relative',
        }}
      >
        <img src={Logo} alt="Tecton Logo " className="logo-white" />
      </div>

      <section id="sidebar-footer" aria-label="Miscellaneous" className="nav tray">
        <div className="tray-icons">
          <a href="https://docs.tecton.ai/" rel="noreferrer" target="_blank" className="tray-item">
            <img src={DocumentationIcon} alt="Documentation" /> <span>Documentation</span>
          </a>
          <WhatsNew>
            <> </>
          </WhatsNew>
        </div>
      </section>
    </Box>
  );
};

export default WorkspaceSelectionMockNavigation;
