import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import React, { FC, useState } from 'react';
import ReactDOM from 'react-dom';
import FlexGroup from './FlexGroup';
import FlexItem from './FlexItem';
import Text from './Text';
import Button from './Button';

type FlyinLifecycleState = 'entering' | 'entered' | 'exiting' | 'exited';

interface FlyInProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: React.ReactNode;
  content: React.ReactNode;
  footer: React.ReactNode;
}

interface FlyinInternalProps extends FlyInProps {
  lifecycleState: FlyinLifecycleState;
  setLifecycleState: React.Dispatch<React.SetStateAction<FlyinLifecycleState>>;
}

const enter = keyframes`
    from {
        left: 100%;
    }

    to {
        left: 0%;
    }
`;

const exit = keyframes`
    to {
        left: 100%;
    }

    from {
        left: 0%;
    }
`;

const FlyinWrapper = styled.div<{ lifecycleState: FlyinLifecycleState }>`
  height: 100%;
  background-color: ${({ theme }) => {
    return theme.v1.colors.background.empty;
  }};

  width: ${({ theme }) => theme.v1.size['120']}px;
  position: relative;

  border-left: ${({ theme }) => {
    return `1px solid ${theme.v1.colors.border.focus}`;
  }};

  animation: ${({ theme, lifecycleState }) => {
    if (lifecycleState === 'entering') {
      return css`
        ${enter} ${theme.v1.motion.duration.fast} ${theme.v1.motion.easing.enter} forwards
      `;
    }

    if (lifecycleState === 'exiting') {
      return css`
        ${exit} ${theme.v1.motion.duration.xfast} ${theme.v1.motion.easing.exit} forwards
      `;
    }

    return ``;
  }};
`;

// This prevents lines from growing strangely during the transition in by creating a fixed layer for the content of the Flyin
const Container = styled.div`
  position: absolute;
  width: 500px;
  height: 100%;
  padding: ${({ theme }) => theme.v1.size['4']}px;
`;

const FlyinInternal: FC<FlyinInternalProps> = ({
  lifecycleState,
  setLifecycleState,
  title,
  content,
  footer,
  setIsOpen,
}) => {
  return (
    <FlyinWrapper
      lifecycleState={lifecycleState}
      onAnimationEnd={() => {
        if (lifecycleState === 'exiting') {
          setIsOpen(false);
          setLifecycleState('entering');
        }
      }}
    >
      <Container>
        <FlexGroup direction="column" alignItems="space-between" gapSize="4">
          <FlexItem grow="0" shrink={'0'}>
            <FlexGroup alignItems="center" padding="2">
              <FlexItem grow={'1'}>
                <FlexGroup direction="column" justifyContent="center">
                  <Text element="h3">{title}</Text>
                </FlexGroup>
              </FlexItem>
              <FlexItem grow="0">
                <FlexItem>
                  <Button
                    type="ghost"
                    icon={'Exit'}
                    onClick={() => {
                      setLifecycleState('exiting');
                    }}
                  />
                </FlexItem>
              </FlexItem>
            </FlexGroup>
          </FlexItem>
          <FlexItem grow={'1'} shrink={'1'}>
            {content}
          </FlexItem>
          <FlexItem grow="0" shrink={'0'}>
            {footer}
          </FlexItem>
        </FlexGroup>
      </Container>
    </FlyinWrapper>
  );
};

const FlyIn: FC<FlyInProps> = ({ isOpen, setIsOpen, title, content, footer }) => {
  const [lifecycleState, setLifecycleState] = useState<FlyinLifecycleState>('entering');

  const portal = document.getElementById('flyin-portal');
  if (isOpen) {
    if (portal) {
      return ReactDOM.createPortal(
        <FlyinInternal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          lifecycleState={lifecycleState}
          setLifecycleState={setLifecycleState}
          title={title}
          content={content}
          footer={footer}
        />,
        portal
      );
    } else {
      console.warn('TectonUI attempted to open a Flyin but no acceptable portal was found.');
      return <></>;
    }
  }

  return <></>;
};

export default FlyIn;
