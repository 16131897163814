import {
  Card,
  CardGridLayout,
  CenterContentLayout,
  EmptyPrompt,
  FullWidthCard,
  TwoCardRow,
} from '@tecton/ComponentRedesign';
import ServingStateChart from '@tecton/ComponentRedesign/ServingStatus';
import { FC } from 'react';
import { useGetSparkConfig } from '../../../api/spark-config';
import {
  FeatureViewFCO,
  FeatureViewFCOFields,
  FeatureViewServingStatusSummaryWithId,
} from '../../../core/types/fcoTypes';
import { useFeatureViewServingStatus } from '../../../feature/feature-views/feature-view/query';
import { useUserSettings } from '../../context/UserSettingsContext';
import FeatureViewMaterializationBatchConfigurationCard from './FeatureViewMaterializationBatchCard';
import FeatureViewMaterializationConfigCard from './FeatureViewMaterializationConfigCard';
import FeatureViewMaterializationStorageConfigurationCard from './FeatureViewMaterializationStorageConfigurationCard';
import FeatureViewMaterializationStreamConfigurationCard from './FeatureViewMaterializationStreamConfigurationCard';

interface FeatureViewMaterializationProps {
  featureView: FeatureViewFCO;
}

const FeatureViewMaterialization: FC<FeatureViewMaterializationProps> = ({ featureView }) => {
  const { data: servingStatus, isLoading } = useFeatureViewServingStatus(featureView.id);
  const { workspace } = useUserSettings();
  const { data: dataSparkConfig, isLoading: isLoadingSparkConfig } = useGetSparkConfig(
    workspace ?? 'prod',
    featureView.name ?? ''
  );

  if (isLoading || isLoadingSparkConfig) {
    return (
      <CenterContentLayout>
        <EmptyPrompt title={<>Loading Materialization</>} body={<></>} variant="loading" orientation="vertical" />
      </CenterContentLayout>
    );
  }
  const attemptSummary: FeatureViewServingStatusSummaryWithId[] = [
    {
      id: featureView.id,
      serving_status_summary: servingStatus,
    },
  ];

  return (
    <>
      <CardGridLayout>
        <TwoCardRow>
          <FeatureViewMaterializationConfigCard fco={featureView} />

          {/* According to this ticket:
          https://linear.app/tecton/issue/UI-880/spark-config-is-not-correctly-rendered-for-stream-fv */}
          {dataSparkConfig?.stream_config ? (
            <FeatureViewMaterializationStreamConfigurationCard fco={featureView} />
          ) : (
            <FeatureViewMaterializationBatchConfigurationCard fco={featureView} />
          )}
          <FeatureViewMaterializationStorageConfigurationCard fco={featureView} />
        </TwoCardRow>

        {featureView[FeatureViewFCOFields.IS_MATERIALIZATION_ENABLED] && (
          <FullWidthCard>
            <Card title="Materialization Status" showOverlay={false}>
              {!servingStatus && (
                <ServingStateChart
                  featureViews={[featureView]}
                  isLoading
                  attemptSummary={attemptSummary}
                  hideTitle
                  workspace={workspace ?? ''}
                />
              )}
              {servingStatus && (
                <ServingStateChart
                  featureViews={[featureView]}
                  attemptSummary={attemptSummary}
                  hideTitle
                  workspace={workspace ?? ''}
                />
              )}
            </Card>
          </FullWidthCard>
        )}
      </CardGridLayout>
    </>
  );
};

export default FeatureViewMaterialization;
