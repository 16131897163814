import React from 'react';
import { Stack, Typography } from '@mui/material';
import NoWorkspacesAvailableTable from './NoWorkspacesAvailableTable';
import style from './style.module.scss';
import usePrincipalInformation from '../../../../feature/access-control/Hooks/usePrincipalInformation';
import { PrimaryButton } from '../../../../components/button/PrimaryButton';
import { useNavigate } from 'react-router';
import { Routes } from '../../../../core/routes';
import useGlobalState from '../../../../shared/useGlobalState';
import { Box } from '@mui/system';

const NoWorkspacesAvailableMainColumn = () => {
  const { isAdmin, hasZeroWorkspaceAccess } = usePrincipalInformation();
  const navigate = useNavigate();
  const { loggedInUser } = useGlobalState();

  return (
    <div className={style.mainColumn}>
      <Stack spacing={2}>
        <Typography variant="h1">Get Started</Typography>
        <Typography variant="body1">
          Tecton organizes features, permissions, and data into{' '}
          <a
            href="https://docs.tecton.ai/docs/setting-up-tecton/administration-setup/creating-a-workspace-and-adding-users-to-the-workspace"
            target="_blank"
            rel="noreferrer"
          >
            Workspaces
          </a>
          .
        </Typography>
        <Typography variant="body1">
          In order to access Tecton, all users need to have access to at least one workspace. Your account does not have
          access to any workspaces yet.
        </Typography>
        {!(isAdmin && hasZeroWorkspaceAccess) && (
          <Typography variant="body1">You can gain workspace access in one of two ways:</Typography>
        )}
        <div className={style.mainColumnBlock}>
          {!(isAdmin && hasZeroWorkspaceAccess) && (
            <div className={style.sectionSubHeading}>Method One (Recommended)</div>
          )}
          {isAdmin && hasZeroWorkspaceAccess ? (
            <>
              <Typography variant="h3">Grant yourself access to an existing workspace</Typography>
              <Typography variant="body1">
                Your organization has created workspaces in Tecton, but you do not yet have access to them. As an Admin,
                you can grant yourself access to one or more of these workspaces:
              </Typography>
              <Box
                m={1}
                //margin
                display="flex"
                justifyContent="flex-end"
                alignItems="flex-end"
              >
                <PrimaryButton
                  style={{ marginTop: 20 }}
                  onClick={() => {
                    navigate(`${Routes.accountsAndAccessUserProfile.replace(':user/*', loggedInUser.email)}`);
                  }}
                >
                  Add Workspace
                </PrimaryButton>
              </Box>
            </>
          ) : (
            <>
              <Typography variant="h3">Request Access to an Existing Workspace</Typography>
              <Typography variant="body1">
                Your organization has created workspaces in Tecton, but you do not yet have access to them. Contact your
                Tecton administrator and let them know that you would like access to one or more of these workspaces:
              </Typography>
            </>
          )}
          <div className="mt4">
            <NoWorkspacesAvailableTable />
          </div>
        </div>

        <div className={style.mainColumnBlock}>
          {!(isAdmin && hasZeroWorkspaceAccess) && <div className={style.sectionSubHeading}>Method Two</div>}
          <Typography variant="h3">Create Your First Workspace</Typography>
          <Typography>
            To get started immediately, create a workspace yourself by following the instructions on the right side of
            the screen.
          </Typography>
        </div>
      </Stack>
    </div>
  );
};

export default NoWorkspacesAvailableMainColumn;
