import { FC, useContext, useState } from 'react';

import { useUserSettings } from '../../context/UserSettingsContext';
import ACLServiceAccountList from './ACLServiceAccountList';
import ActivateAccount from './ActivateAccount';
import CreateServiceAccount from './CreateServiceAccount';
import CreatedServiceAccountInformation from './CreatedServiceAccountInformation';
import DeactivateAccount from './DeactivateAccount';
import Delete from './Delete';
import ModifyServiceAccountAccountType from './ModifyServiceAccountAccountType';

// utils
import { getRoleDescriptionMap } from './acl-util';

// CRUD hooks
import {
  useAssignRoleMutation,
  useCreateServiceAccountMutation,
  useDeleteServiceAccount,
  useMutateServiceAccount,
  useUpdateAccountTypeMutation,
} from '../../../api/acl';

// generated
import { ServiceAccount } from '../../../api/gql/graphql';
import { useGetACLServiceAccountsTable } from '../../../api/queries/aclGraphql';
import { AssignRolesRequest } from '../../../types/tecton_proto/auth/authorization_service';
import { PrincipalType } from '../../../types/tecton_proto/auth/principal';
import { ResourceType } from '../../../types/tecton_proto/auth/resource';
import { CreateServiceAccountResponse } from '../../../types/tecton_proto/metadataservice/metadata_service';

// @tecton
import { Modal, getToastComponent } from '@tecton/ComponentRedesign';
import { ToastContext, addToast } from '@tecton/ToastContext';
import { useSearchParams } from 'react-router-dom';
import { logEvent } from '../../../utils/analytics-utils';

const ACLServiceAccountListsContainer: FC = () => {
  const { user, isAdmin } = useUserSettings();
  const toastContext = useContext(ToastContext);
  const [searchParams] = useSearchParams();

  const canOpenCreateServiceAccountModal = searchParams.get('create-service-account') === 'true'; // for explorer cluster

  // CRUD (Mutation) hooks
  const { data, isLoading, isError } = useGetACLServiceAccountsTable(user?.sub ?? '');
  const createServiceAccountMutation = useCreateServiceAccountMutation();
  const updateAccountTypeToRegularMutation = useUpdateAccountTypeMutation();
  const assignRoleMutation = useAssignRoleMutation();
  const mutateServiceAccount = useMutateServiceAccount();
  const deleteServiceAccountMutation = useDeleteServiceAccount();

  // General states
  const [selectedServiceAccount, setSelectedServiceAccount] = useState<ServiceAccount | undefined>(undefined);
  const [selectedAccountType, setSelectedAccountType] = useState<string | undefined>(undefined);
  const [newServiceAccountName, setNewServiceAccountName] = useState<string>('');
  const [newServiceAccountDescription, setNewServiceAccountDescription] = useState<string>('');
  const [createServiceAccountResponse, setCreateServiceAccountResponse] = useState<
    CreateServiceAccountResponse | undefined
  >();
  const [isAPIKeyCopied, setIsAPIKeyCopied] = useState<boolean>(false);

  // Modal states
  const [showCreateServiceAccountModal, setShowCreateServiceAccountModal] = useState<boolean>(
    canOpenCreateServiceAccountModal
  );
  const [showServiceAccountApiKey, setShowServiceAccountApiKey] = useState<boolean>(false);
  const [showAccountTypeModal, setShowAccountTypeModal] = useState<boolean>(false);
  const [showDeactivateModal, setShowDeactivateModal] = useState<boolean>(false);
  const [showDeleteServiceAccountModal, setShowDeleteServiceAccountModal] = useState<boolean>(false);
  const [showActivateServiceAccountModal, setShowActivateServiceAccountModal] = useState<boolean>(false);

  // Validation States
  const [hasDuplicateServiceAccountName, setHasDuplicateServiceAccountName] = useState<boolean>(false);

  // Account Type descriptions
  const roleDescriptionMap = getRoleDescriptionMap();

  const reset = () => {
    setShowCreateServiceAccountModal(false);
    setShowServiceAccountApiKey(false);
    setShowAccountTypeModal(false);
    setShowDeactivateModal(false);
    setShowDeleteServiceAccountModal(false);
    setHasDuplicateServiceAccountName(false);
    setSelectedServiceAccount(undefined);
    setSelectedAccountType(undefined);
    setNewServiceAccountName('');
    setNewServiceAccountDescription('');
    setCreateServiceAccountResponse(undefined);
    setShowActivateServiceAccountModal(false);
  };

  // --------------------------------------- Start: Actions hooks ---------------------------------
  const onCreateServiceAccountChange = (serviceAccountName: string, serviceAccountDescription: string) => {
    setNewServiceAccountName(serviceAccountName);
    setNewServiceAccountDescription(serviceAccountDescription);
    const serviceAccountNames =
      data?.serviceAccounts?.map((serviceAccount) => serviceAccount.name?.toLowerCase()) ?? [];
    setHasDuplicateServiceAccountName(serviceAccountNames.includes(serviceAccountName.toLowerCase()));
  };

  const onAPIKeyCopyConfirmed = (isAPIKeyCopied: boolean) => {
    setIsAPIKeyCopied(isAPIKeyCopied);
  };

  const onCreateServiceAccountConfirmClick = () => {
    createServiceAccountMutation.mutate(
      { name: newServiceAccountName, description: newServiceAccountDescription },
      {
        onSuccess: (response) => {
          setCreateServiceAccountResponse(response);
          setShowCreateServiceAccountModal(false);
          setShowServiceAccountApiKey(true);

          logEvent('Account & Access: Create Service Account', '', {
            id: response.id,
            name: response.name,
          });

          const toastContent = getToastComponent({
            variant: 'success',
            title: `Service Account Created`,
            children: <>{newServiceAccountName} Service Account created.</>,
            showIcon: true,
          });

          toastContext?.dispatchToast(
            addToast({
              text: toastContent,
            })
          );
        },
        onError: () => {
          const toastContent = getToastComponent({
            variant: 'danger',
            title: `Failed to Create Service Account`,
            children: <>Failed to create service account {newServiceAccountName}. Please try again.</>,
            showIcon: true,
          });

          toastContext?.dispatchToast(
            addToast({
              text: toastContent,
            })
          );
        },
      }
    );
  };

  const onSelectedAccountType = (selectedAccountType: string) => {
    setSelectedAccountType(selectedAccountType);
  };

  const onConfirmAccountTypeChange = () => {
    const role = 'admin_role';

    const payload: AssignRolesRequest = {
      assignments: [
        {
          principal_id: selectedServiceAccount?.id ?? '',
          role,
          principal_type: PrincipalType.PRINCIPAL_TYPE_SERVICE_ACCOUNT,
          resource_type: ResourceType.RESOURCE_TYPE_ORGANIZATION,
        },
      ],
    };

    if (selectedAccountType === 'Admin') {
      assignRoleMutation.mutate(payload, {
        onSuccess: () => {
          reset();
          logEvent('Account & Access: Edit Account Type', '', {
            account_type: 'Admin',
            principal_id: selectedServiceAccount?.id,
          });
          const toastContent = getToastComponent({
            variant: 'success',
            title: `Account Type Updated`,
            children: (
              <>
                Service Account {selectedServiceAccount?.name} Account type has been updated to {selectedAccountType}.
              </>
            ),
            showIcon: true,
          });

          toastContext?.dispatchToast(
            addToast({
              text: toastContent,
            })
          );
        },
        onError: () => {
          const toastContent = getToastComponent({
            variant: 'danger',
            title: `Account Type Updated Failed`,
            children: <>Failed to Update Account Type for {selectedServiceAccount?.name}.</>,
            showIcon: true,
          });

          toastContext?.dispatchToast(
            addToast({
              text: toastContent,
            })
          );
        },
      });
    } else {
      updateAccountTypeToRegularMutation.mutate(payload, {
        onSuccess: () => {
          reset();
          logEvent('Account & Access: Edit Account Type', '', {
            account_type: 'Regular',
            principal_id: selectedServiceAccount?.id,
          });
          const toastContent = getToastComponent({
            variant: 'success',
            title: `Account Type Updated`,
            children: (
              <>
                Service Account {selectedServiceAccount?.name} Account type has been updated to {selectedAccountType}.
              </>
            ),
            showIcon: true,
          });

          toastContext?.dispatchToast(
            addToast({
              text: toastContent,
            })
          );
        },
        onError: () => {
          const toastContent = getToastComponent({
            variant: 'danger',
            title: `Account Type Updated Failed`,
            children: (
              <>
                Updating Account Type for Service Account {selectedServiceAccount?.name} has failed. Please try again.
              </>
            ),
            showIcon: true,
          });

          toastContext?.dispatchToast(
            addToast({
              text: toastContent,
            })
          );
        },
      });
    }
  };

  const onDeactivateServiceConfirmClicked = () => {
    const deprovisionServiceAccount = { id: selectedServiceAccount?.id ?? '', is_active: false };
    mutateServiceAccount.mutate(deprovisionServiceAccount, {
      onSuccess: () => {
        reset();
        logEvent('Account & Access: Deprovision', '', {
          name: selectedServiceAccount?.name,
          id: selectedServiceAccount?.id,
        });
        const toastContent = getToastComponent({
          variant: 'success',
          title: `Service Account Deactivated`,
          children: <>Service Account {selectedServiceAccount?.name} as been deactivated.</>,
          showIcon: true,
        });

        toastContext?.dispatchToast(
          addToast({
            text: toastContent,
          })
        );
      },
      onError: () => {
        const toastContent = getToastComponent({
          variant: 'danger',
          title: `Service Account Deactivated Failed`,
          children: <>Deactivating Service Account {selectedServiceAccount?.name} failed. Please try again.</>,
          showIcon: true,
        });

        toastContext?.dispatchToast(
          addToast({
            text: toastContent,
          })
        );
      },
    });
  };

  const onDeleteServiceConfirmClicked = () => {
    const id = selectedServiceAccount?.id ?? '';
    deleteServiceAccountMutation.mutate(
      { id },
      {
        onSuccess: () => {
          reset();
          logEvent('Account & Access: Delete Account', '', {
            id,
          });
          const toastContent = getToastComponent({
            variant: 'success',
            title: `Service Account Deleted`,
            children: <>Service Account {selectedServiceAccount?.name} deleted.</>,
            showIcon: true,
          });

          toastContext?.dispatchToast(
            addToast({
              text: toastContent,
            })
          );
        },
        onError: () => {
          const toastContent = getToastComponent({
            variant: 'danger',
            title: `Service Account Deleted Failed`,
            children: <>Service Account {selectedServiceAccount?.name} deleted failed. Please try again.</>,
            showIcon: true,
          });

          toastContext?.dispatchToast(
            addToast({
              text: toastContent,
            })
          );
        },
      }
    );
  };

  const onActivateServiceConfirmClicked = () => {
    if (selectedServiceAccount !== undefined) {
      const { createdAt: created_at, description, id, name } = selectedServiceAccount;
      const deprovisionServiceAccount = {
        created_at: created_at ?? '',
        description: description ?? '',
        id: id ?? '',
        name: name ?? '',
        is_active: true,
      };
      mutateServiceAccount.mutate(deprovisionServiceAccount, {
        onSuccess: () => {
          reset();
          logEvent('Account & Access: Activate Service Account', '', {
            name: selectedServiceAccount?.name,
            id: selectedServiceAccount?.id,
            //creator: serviceAccount?.created_by?.user?.login_email,
          });
          const toastContent = getToastComponent({
            variant: 'success',
            title: `Service Account Activated`,
            children: <>{selectedServiceAccount?.name} service account has been activated.</>,
            showIcon: true,
          });

          toastContext?.dispatchToast(
            addToast({
              text: toastContent,
            })
          );
        },
        onError: () => {
          const toastContent = getToastComponent({
            variant: 'danger',
            title: `Service Account Activated Failed`,
            children: <>{selectedServiceAccount?.name} service account activation failed.</>,
            showIcon: true,
          });

          toastContext?.dispatchToast(
            addToast({
              text: toastContent,
            })
          );
        },
      });
    }
  };

  // ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ End: Actions hooks ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^

  return (
    <>
      {showCreateServiceAccountModal && (
        <Modal
          title={`Create Service Account`}
          body={
            <CreateServiceAccount
              onChange={onCreateServiceAccountChange}
              hasDuplicateServiceAccountName={hasDuplicateServiceAccountName}
            />
          }
          onCancel={() => {
            setShowCreateServiceAccountModal(false);
            setNewServiceAccountName('');
            setNewServiceAccountDescription('');
            setHasDuplicateServiceAccountName(false);
          }}
          confirmLabel="Register Account and Generate API Key"
          onConfirm={onCreateServiceAccountConfirmClick}
          variant="positive"
          confirmVariant={
            newServiceAccountName === '' || hasDuplicateServiceAccountName ? 'disabledAction' : 'primaryAction'
          }
          isConfirmButtonLoading={createServiceAccountMutation.isLoading}
        />
      )}

      {showServiceAccountApiKey && (
        <Modal
          title={`Service Account ID and API Key Generated`}
          body={
            <>
              <CreatedServiceAccountInformation
                createServiceAccountResponse={createServiceAccountResponse}
                onChange={onAPIKeyCopyConfirmed}
              />
            </>
          }
          onCancel={() => {
            reset();
          }}
          confirmLabel="close"
          onConfirm={() => {
            setShowServiceAccountApiKey(false);
            setIsAPIKeyCopied(false);
          }}
          variant="positive"
          confirmVariant={isAPIKeyCopied ? 'primaryAction' : 'disabledAction'}
        />
      )}
      {showAccountTypeModal && (
        <Modal
          title={`Modify Account Type of ${selectedServiceAccount?.name}`}
          body={
            <ModifyServiceAccountAccountType
              serviceAccount={selectedServiceAccount}
              selectedAccountType={selectedAccountType}
              roleDescriptionMap={roleDescriptionMap}
              onSelectionClicked={onSelectedAccountType}
            />
          }
          onCancel={() => {
            reset();
          }}
          confirmLabel="Change assigned Account Type to Admin"
          onConfirm={onConfirmAccountTypeChange}
          variant="positive"
          confirmVariant={
            selectedServiceAccount?.accountType === selectedAccountType ? 'disabledAction' : 'primaryAction'
          }
          isConfirmButtonLoading={assignRoleMutation.isLoading || updateAccountTypeToRegularMutation?.isLoading}
        />
      )}

      {showDeleteServiceAccountModal && (
        <Modal
          title={`Delete ${selectedServiceAccount?.name}`}
          body={<Delete />}
          onCancel={() => {
            reset();
          }}
          confirmLabel="Delete"
          onConfirm={onDeleteServiceConfirmClicked}
          variant="negative"
          isConfirmButtonLoading={deleteServiceAccountMutation.isLoading}
        />
      )}

      {showDeactivateModal && (
        <Modal
          title={`Deactivate`}
          body={<DeactivateAccount serviceAccountName={selectedServiceAccount?.name ?? ''} />}
          onCancel={() => {
            reset();
          }}
          confirmLabel="Deactivate"
          onConfirm={onDeactivateServiceConfirmClicked}
          variant="negative"
          isConfirmButtonLoading={mutateServiceAccount.isLoading}
        />
      )}

      {showActivateServiceAccountModal && (
        <Modal
          title={`Activate`}
          body={<ActivateAccount serviceAccountName={selectedServiceAccount?.name ?? ''} />}
          onCancel={() => {
            reset();
          }}
          confirmLabel="Activate"
          onConfirm={onActivateServiceConfirmClicked}
          variant="negative"
          isConfirmButtonLoading={mutateServiceAccount.isLoading}
        />
      )}

      <ACLServiceAccountList
        serviceAccounts={data?.serviceAccounts ?? []}
        isAdminView={isAdmin ?? false}
        isLoading={isLoading}
        isError={isError}
        onCreateServiceAccountClick={() => {
          setShowCreateServiceAccountModal(true);
        }}
        onAccountTypeRowClicked={(serviceAccount) => {
          setSelectedServiceAccount(serviceAccount);
          setSelectedAccountType(serviceAccount?.accountType);
          setShowAccountTypeModal(true);
        }}
        onDeactivateServiceAccountRowClicked={(serviceAccount) => {
          setSelectedServiceAccount(serviceAccount);
          setShowDeactivateModal(true);
        }}
        onDeleteServiceAccountRowClicked={(serviceAccount) => {
          setSelectedServiceAccount(serviceAccount);
          setShowDeleteServiceAccountModal(true);
        }}
        onActivateServiceAccountRowClicked={(serviceAccount) => {
          setSelectedServiceAccount(serviceAccount);
          setShowActivateServiceAccountModal(true);
        }}
      />
    </>
  );
};

export default ACLServiceAccountListsContainer;
