import { getVisibleAnimationsOnAllEdgesGivenAnchors } from './highlight-utils';
import highlightDownstream from './highlightDownstream';
import { HighlightFunctionType } from './highlightFunctionType';
import highlightUpstream from './highlightUpstream';

const highlightDataSource: HighlightFunctionType = (id, nodesMap, edgesList) => {
  const upstream = highlightUpstream(id, nodesMap, edgesList);
  const downstreamToStore = highlightDownstream(id, nodesMap, edgesList);

  const linkedIds = new Set([...upstream.linkedIds, ...downstreamToStore.linkedIds]);
  const linkedEdges = new Set([...upstream.linkedEdges, ...downstreamToStore.linkedEdges]);

  const animations = getVisibleAnimationsOnAllEdgesGivenAnchors(linkedIds, edgesList, linkedEdges);

  return {
    linkedIds,
    linkedEdges,
    animations,
  };
};

export default highlightDataSource;
