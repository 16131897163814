import styled from '@emotion/styled';
import { animated, config, useTransition } from '@react-spring/web';
import { ButtonIcon, CallOut, DescriptionList, Icon, Link, Text } from '@tecton';
import React from 'react';

export const PANEL_DEFAULT_PADDING = `16px`;

const PanelCloseButton = styled(ButtonIcon)`
  position: absolute;
  inset-inline-end: 4px;
  inset-block-start: 4px;
`;

const DetailsPanelTitle = styled.div`
  font-weight: 500;
  font-size: 16px;
  word-wrap: break-word;
`;

const DetailsPanelHeaderArea = styled.div`
  padding: ${PANEL_DEFAULT_PADDING};
`;

const ContentArea = styled.div`
  height: 100%;
  overflow-y: scroll;
  margin-right: -16px;
  padding-top: 16px;

  > section:last-of-type {
    padding-bottom: 16px;
  }
`;

const DescriptionWrapper = styled.section`
  margin: 0 ${PANEL_DEFAULT_PADDING} 18px;
`;

const ConfigurationList = styled(DescriptionList)`
  .euiDescriptionList__title {
    inline-size: 40%;
    font-weight: 600;
  }

  .euiDescriptionList__description {
    inline-size: 60%;
    word-wrap: break-word;
  }

  .euiDescriptionList__title:not(:first-of-type),
  .euiDescriptionList__description:not(:first-of-type) {
    margin-block-start: 2px;
  }
`;

const ContextPanelConfigurationList = (props: React.ComponentProps<typeof DescriptionList>) => {
  return <ConfigurationList {...props} type="column" />;
};

const DescriptionSection = ({ description }: { description?: string }) => {
  return (
    <DescriptionWrapper>
      <Text>
        <p>{description || 'No Description.'}</p>
      </Text>
    </DescriptionWrapper>
  );
};

const DetailsPanelPrehead = styled.p`
  font-size: 12px;
  color: #999;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.04em;
`;

const Actions = styled.p`
  margin: 8px 0;

  button + button {
    margin-left: 1em;
  }

  button > svg {
    vertical-align: text-top;
  }
`;

const ContextSection = styled.section`
  & > .euiTitle.context-panel-no-underline {
    border-bottom: none !important;
    margin-bottom: 4px !important;
  }
`;

const ContextPanelCallOut = styled(CallOut)`
  margin: 16px;
`;

const DataFlowSidePanel = ({
  name,
  nodeId,
  prehead,
  url,
  children,
  detailsPanelClearAll,
  zoomToNode,
}: {
  name: string;
  nodeId: string;
  prehead: string;
  url?: string;
  children: React.ReactNode;
  detailsPanelClearAll: () => void;
  zoomToNode?: () => void;
}) => {
  const transitions = useTransition([nodeId], {
    from: { opacity: 0, transform: 'translate(3em, 0)' },
    enter: { opacity: 1, transform: 'translate(0, 0)' },
    leave: { opacity: 0, transform: 'translate(3em, 0)' },
    exitBeforeEnter: true,
    config: { ...config.stiff, duration: 100 },
  });

  return transitions((style) => (
    <animated.div
      style={{
        ...style,
        height: '100%',
        paddingBottom: 0,
        display: 'grid',
        gridTemplateRows: 'auto 1fr',
        background: 'white',
        boxShadow: `0px 3px 6px rgba(0, 10, 40, 0.35)`,
      }}
    >
      <DetailsPanelHeaderArea>
        <DetailsPanelPrehead>{prehead}</DetailsPanelPrehead>
        <DetailsPanelTitle>
          <>{name}</>
        </DetailsPanelTitle>
        <Actions>
          {url && (
            <Link href={'../../..' + url}>
              <Icon type={'link'} /> Go to Details
            </Link>
          )}

          {zoomToNode && (
            <Link onClick={zoomToNode}>
              <Icon type={'crosshairs'} /> Center
            </Link>
          )}
        </Actions>
        <PanelCloseButton iconType={'cross'} onClick={detailsPanelClearAll} aria-label="Close Side Panel" />
      </DetailsPanelHeaderArea>
      <ContentArea tabIndex={0} role="region" aria-label="Context Panel" className={`eui-yScroll`}>
        {children}
      </ContentArea>
    </animated.div>
  ));
};

export {
  ContextPanelCallOut,
  ContextPanelConfigurationList,
  ContextSection,
  DataFlowSidePanel,
  DescriptionSection,
  DetailsPanelHeaderArea,
  DetailsPanelPrehead,
  DetailsPanelTitle,
  PanelCloseButton,
};
