import { groupBy } from 'lodash';
import { FcoTransitionType, StateUpdatePlanSummaryDiff } from '../../../types/tecton_proto/data/state_update';

export const filterPlanDataByType = (items: StateUpdatePlanSummaryDiff[], matchingTypes: string[]) => {
  return items.filter((planItem) => {
    return matchingTypes.includes(planItem.fco_type ?? '');
  });
};

export const materializationTypesForFeatureView = (item: StateUpdatePlanSummaryDiff) => {
  const summaries = [];
  if (Object.keys(item?.materialization_info?.batch_task_diff ?? {}).length > 0) {
    summaries.push(`1 recurring batch job`);
  }
  if (Object.keys(item?.materialization_info?.stream_task_diff ?? {}).length > 0) {
    summaries.push(`1 recurring stream job`);
  }

  if (item?.materialization_info?.backfill_task_diffs) {
    const backfillCount = item.materialization_info.backfill_task_diffs.reduce<number>((memo, current) => {
      return current?.number_of_jobs ? memo + current?.number_of_jobs : memo;
    }, 0);

    summaries.push(`${backfillCount} backfill job${backfillCount > 1 ? 's' : ''}`);
  }

  return summaries;
};

const planApplyFcoDisplayNames = ['Feature View / Table', 'Feature Service', 'Data Source', 'Entity', 'Transformation'];

// This file reflects FCOStrings.kt on the backend
const planApplyFcoTypes: Record<string, string> = {
  'Feature Table': planApplyFcoDisplayNames[0],
  'Realtime Feature View': planApplyFcoDisplayNames[0],
  'Batch Feature View': planApplyFcoDisplayNames[0],
  'Batch Window Aggregate Feature View': planApplyFcoDisplayNames[0],
  'Stream Window Aggregate Feature View': planApplyFcoDisplayNames[0],
  'Stream Window Aggregate Feature View with Push Source': planApplyFcoDisplayNames[0],
  'Stream Feature View': planApplyFcoDisplayNames[0],
  'Stream Feature View with Push Source': planApplyFcoDisplayNames[0],
  'Feature Service': planApplyFcoDisplayNames[1],
  'Stream Data Source': planApplyFcoDisplayNames[2],
  'Batch Data Source': planApplyFcoDisplayNames[2],
  'Push Source': planApplyFcoDisplayNames[2],
  Entity: planApplyFcoDisplayNames[3],
  Transformation: planApplyFcoDisplayNames[4],
  UNKNOWN: 'Unknown',
};

export interface SingleDiffSummaryLine {
  status: FcoTransitionType;
  items: StateUpdatePlanSummaryDiff[];
}

export type DiffCountSummary = {
  fco_type: string;
  items: StateUpdatePlanSummaryDiff[];
  items_by_status: SingleDiffSummaryLine[];
}[];

export const processDiffItems = (diff_items?: StateUpdatePlanSummaryDiff[]) => {
  const g = groupBy(diff_items, (item) => {
    let fcoKey = item.fco_type;

    if (planApplyFcoTypes[item?.fco_type ?? 'UNKNOWN']) {
      fcoKey = planApplyFcoTypes[item.fco_type ?? 'UNKNOWN'];
    }

    return fcoKey;
  });

  const nestedGroup: DiffCountSummary = Object.keys(g)
    .map((fco_type) => {
      const groupedByStatus = groupBy(g[fco_type], (item) => {
        return item.type;
      });

      const itemsByStatus = Object.keys(groupedByStatus).map((status) => {
        const toReturn = { status: status as FcoTransitionType, items: groupedByStatus[status] };
        return toReturn;
      });

      const group = {
        fco_type,
        items: g[fco_type],
        items_by_status: itemsByStatus,
      };

      return group;
    })
    .sort((a, b) => {
      return planApplyFcoDisplayNames.indexOf(a.fco_type) - planApplyFcoDisplayNames.indexOf(b.fco_type);
    });

  return nestedGroup;
};
