import * as d3 from 'd3';

export function smartDecimalFormat(num) {
  /*
    Does a mixture of SI formatting and fixed precision.
    SI only kicks in for extremely small or large values, and precision is adjusted according to
    integer part.
    Example:
    1 => 1.00000
    2.0 => 2.00000
    0.001 => 0.001000
    0.00000001 => 1\mu
    14_000_000 => 14M
    1450 => 1450.00

   */
  const integralDigits = Math.floor(Math.abs(num)).toString().length;
  if (isNaN(num)) {
    return num;
  } else if (num > 1e6 || num < 1e-4) {
    return d3.format('.3s')(num);
  } else if (num === Math.floor(num)) {
    return num.toString();
  } else {
    const minPrecision = 2;
    const maxPrecision = 6;
    const sigFigs = 6;

    const precision = Math.floor(Math.max(minPrecision, Math.min(maxPrecision - integralDigits, sigFigs)));
    return d3.format(`.${precision}f`)(num);
  }
}

export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i] ?? 'Bytes'}`;
};
