import React, { FC, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { Link } from 'react-router-dom';

import { useAllMaterializingFeatureViews } from './query';
import { EmptyPrompt, Table } from '@tecton/ComponentRedesign';
import { logEvent } from '../../../utils/analytics-utils';

const AllMaterializedFeatureViews: FC = () => {
  const allMaterializingFeatureviews = useAllMaterializingFeatureViews();

  useEffect(() => {
    logEvent('Redesign: View All Materializations');
  }, []);

  if (allMaterializingFeatureviews.isLoading) {
    return <EmptyPrompt title="All Materializing Feature Views" body={''} variant="loading" />;
  }

  if (!Array.isArray(allMaterializingFeatureviews.data) || allMaterializingFeatureviews.data.length === 0) {
    return <EmptyPrompt title={'No Materializing Feature Views Available'} body={''} variant="search" />;
  }

  const allFeatureViews = allMaterializingFeatureviews.data;
  const workspaceNames = allFeatureViews.map((featureView) => {
    return featureView.workspace;
  });

  const workspacesCount = _.uniq(workspaceNames).length;
  const materializingFeaturesCount = allFeatureViews.length;

  const columns = [
    {
      name: `Feature View (${materializingFeaturesCount})`,
      render: (row: any) => {
        return (
          <>
            <Link to={`/repo/${row.workspace}/features/${row.name}`} target="_blank">
              {row.name}
            </Link>
          </>
        );
      },
    },
    {
      name: `workspace (${workspacesCount})`,
      render: (row: any) => {
        return (
          <>
            <Link to={`/repo/${row.workspace}/home`}>{row.workspace}</Link>
          </>
        );
      },
    },
    {
      name: 'Created',
      render: (row: any) => {
        return <>{moment(row.createdAt).fromNow()}</>;
      },
    },
    {
      name: 'ID',
      render: (row: any) => {
        return (
          <>
            {' '}
            <Link to={`/debug/${row.workspace}/features/${row.id}`}>{row.id}</Link>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Table items={allFeatureViews} columns={columns} />
    </>
  );
};

export default AllMaterializedFeatureViews;
