import { get } from 'lodash';

export function findObject(objects, key, match) {
  for (const object of objects) {
    if (get(object, key) === match) {
      return object;
    }
  }
  return null;
}

/**
 * Extract features from FeatureViews
 * @param [featureViews] featureViews
 * @params [featureNamesFilter] only extract features include in the featureNamesFilter if not null. filter must be in format of ViewName.FeatureName
 */
export function extractFeatures(featureViews, idToEntityMap) {
  const features = {};

  featureViews.forEach((fv) => {
    const rawFeatures = fv.features;
    if (Array.isArray(rawFeatures)) {
      // We don't show EXCLUDE_TYPES (timestamp) as a feature.
      const EXCLUDE_TYPES = ['timestamp'];

      let entityNames = [];
      // We check to see if "fv" using the new FCO (by allEntities). Use the Old one if "allEntities" doesn't exist
      if (fv?.allEntities?.map) {
        entityNames = fv?.allEntities.map((entityId) => {
          return idToEntityMap[entityId];
        });
      } else {
        entityNames = fv.entity_names(idToEntityMap);
      }

      rawFeatures
        .filter((fv) => {
          return !EXCLUDE_TYPES.includes(fv.type);
        })
        .forEach((feature) => {
          features[fv.name + ':' + feature.name] = {
            name: feature.name,
            entity_names: entityNames,
            viewName: fv.name,
            tags: fv.tags,
            owner: fv.owner,
            summary: fv.summary,
            type: feature.type,
            view: fv,
          };
        });
    }
  });

  return features;
}

export const fcosToMapByKey = (fcos, key) => {
  if (!fcos || fcos.length === 0) {
    return {};
  }
  // Hack: fcos.concat(fcos) to avoid a one element array where reducer does nothing
  return fcos.concat(fcos).reduce((acc, vds) => {
    let elemKey = vds[key];
    return { ...acc, [elemKey]: vds };
  });
};
