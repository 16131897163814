import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { Button, Popover } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import moment, { Moment } from 'moment-timezone';
import { FC, useState } from 'react';

import { ButtonIcon, DateRangePicker, Spacer } from '@tecton';
import {
  calculateStep,
  calculateUnit,
  dropdownOptions,
  getDateRangeForMonitoringGraph,
  MonitoringDateRange,
  MonitoringDateRangeOption,
} from './DateRange-Dropdown-util';

const componentStyle: any = makeStyles({
  box: {
    borderBottom: 'solid 1px #eff2f7',
    borderLeft: 'solid 1px #eff2f7',
    borderRight: 'solid 1px #eff2f7',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f2f7fa',
    },
  },
});

interface DateRangeSelectorMonitoringProps {
  onChange: (arg: MonitoringDateRange) => void;
}

const DateRangeSelectorMonitoring: FC<DateRangeSelectorMonitoringProps> = ({ onChange }) => {
  const [isSelectRangeButtonEnabled, setIsSelectRangeButtonEnabled] = useState(false);
  const [startDate, setStartDate] = useState(moment().local().subtract(1, 'hours'));
  const [endDate, setEndDate] = useState(moment().local());
  const [dropdownAnchor, setDropdownAnchor] = useState(null);
  const [canShowPopper, setCanShowPopper] = useState<boolean>(false);
  const [canShowDateRangePicker, setCanShowDateRangePicker] = useState(false);

  // Last 1 hour is default
  const [buttonLabel, setButtonLabel] = useState('Last 1 Hour');
  const [endArrow, setEndArrow] = useState(<ArrowDropDownIcon />);
  const classes: any = componentStyle();

  const buttonClick = (event: any) => {
    setCanShowPopper(true);
    setEndArrow(<ArrowDropUpIcon />);
    if (!dropdownAnchor) {
      setDropdownAnchor(event.currentTarget);
    }
  };

  const handleClose = () => {
    setCanShowPopper(!canShowPopper);
    setCanShowDateRangePicker(false);
    setEndArrow(<ArrowDropDownIcon />);
  };

  const onOptionSelected = (option: MonitoringDateRangeOption) => {
    if (option.label === 'Custom') {
      // Show Date range picker component so the user can select a date range.
      setCanShowDateRangePicker(true);
    } else {
      const dateRange = getDateRangeForMonitoringGraph(option);
      setButtonLabel(option.label);
      setCanShowPopper(false);
      onChange(dateRange);
    }
  };

  const onDateRangePickerChange = (start: Moment, end: Moment) => {
    const startTime = () => start.valueOf();
    const endTime = () => end.valueOf();
    const startTimeLabel = start.format('MM/DD/YYYY hh:mm');
    const endTimeLabel = end.format('MM/DD/YYYY hh:mm');

    if (end) {
      const step = calculateStep(start, end);
      const unit = calculateUnit(start, end);

      const customDateRange: MonitoringDateRange = {
        startTime: startTime,
        endTime: endTime,
        requestId: `${startTime()}${endTime()}`,
        step: step,
        unit: unit,
      };

      onChange(customDateRange);
    }

    if (startTimeLabel !== 'Invalid date' && endTimeLabel !== 'Invalid date') {
      setButtonLabel(`From: ${startTimeLabel} To: ${endTimeLabel}`);
    }
  };

  return (
    <Box display="flex">
      <>
        <Button
          size="small"
          variant="outlined"
          startIcon={<DateRangeIcon />}
          endIcon={endArrow}
          onClick={buttonClick}
          style={{ borderRadius: 0 }}
        >
          {buttonLabel}
        </Button>
        <Popover
          PaperProps={{
            style: { borderRadius: 0, border: '1p solid black' },
          }}
          anchorEl={dropdownAnchor}
          open={Boolean(canShowPopper)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <>
            <Box display={'flex'}>
              {canShowDateRangePicker ? (
                <Box flex={1}>
                  <Box className={`pa3`}>
                    <div>
                      <ButtonIcon
                        iconType="arrowLeft"
                        aria-label="Close"
                        onClick={() => {
                          setCanShowPopper(false);
                          setCanShowDateRangePicker(false);
                        }}
                        title="Close"
                      />

                      <Spacer size="s" />
                      <DateRangePicker
                        onChange={(startDate: Moment, endDate: Moment) => {
                          setIsSelectRangeButtonEnabled(!!startDate && !!endDate);
                          setStartDate(startDate);
                          setEndDate(endDate);
                        }}
                        minDate={moment().local().subtract(2, 'months').startOf('day')}
                        maxDate={moment().local()}
                        momentStartDate={startDate}
                        canShowYearDropdown={false}
                      />
                      <Spacer size="s" />

                      <Button
                        size="small"
                        variant="outlined"
                        disabled={!isSelectRangeButtonEnabled}
                        onClick={() => {
                          onDateRangePickerChange(startDate, endDate);
                        }}
                      >
                        Select Range
                      </Button>
                    </div>
                  </Box>
                </Box>
              ) : (
                <Box flex={1}>
                  <div style={{ height: 305, overflow: 'auto', width: 350, borderTop: '1px solid #eff2f7' }}>
                    {dropdownOptions.map((option: any, index: number) => {
                      return (
                        <Box
                          key={index}
                          className={`pa2 pl3 pr6 ${classes.box}`}
                          onClick={() => {
                            onOptionSelected(option);
                          }}
                        >
                          {option.label}
                        </Box>
                      );
                    })}
                  </div>
                </Box>
              )}
            </Box>
          </>
        </Popover>
      </>
    </Box>
  );
};

export default DateRangeSelectorMonitoring;
