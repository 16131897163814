import React from 'react';
import { NavLink } from 'react-router-dom';
import { Routes } from '../core/routes';
import { isNavigationActiveCallback } from '../utils/NavLink-Utils';
import { replaceWorkspace } from '../utils/route-utils';
import { useUserSettings } from './context/UserSettingsContext';

const FeatureServiceTabs = () => {
  const { workspace } = useUserSettings();
  return (
    <div className="tabs" data-testid="tabs">
      <NavLink
        to={replaceWorkspace(Routes.featureServices, workspace)}
        end={true}
        className={isNavigationActiveCallback}
      >
        Feature Services
      </NavLink>
      <NavLink to={replaceWorkspace(Routes.sloDashboard, workspace)} end={true} className={isNavigationActiveCallback}>
        SLO Monitoring
      </NavLink>
      <NavLink
        to={replaceWorkspace(Routes.featureServerMonitoring, workspace)}
        end={true}
        className={isNavigationActiveCallback}
      >
        Feature Server Monitoring
      </NavLink>
    </div>
  );
};

export default FeatureServiceTabs;
