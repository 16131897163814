import React from 'react';
import { AttributesList, Title } from '@tecton';
import { FeatureViewNode } from '../dataFlowTypes';
import { ContextPanelCallOut, DataFlowSidePanel, DescriptionSection } from './StyledPanelComponents';
import DetailsPanelAccordionSection from './DetailsPanelAccordionSection';
import UpstreamDownstreamTable from './UpstreamDownstreamTable';
import FeatureColumnsTable from './FeatureColumnsTable';
import TransformationAccordion from './TransformationAccordion';
import { FeatureViewDetailsPanelData } from './detailsPanelQueryTypes';
import { NodeClickCallbackCreator } from './detailsPanelTypes';
import { EnabledDisabledBadge } from '@tecton/ComponentRedesign';

const mockDescription = 'No Description';

const FeatureViewContextPanel = ({
  id,
  name,
  featureViewType,
  description,
  url,
  detailsPanelClearAll,
  zoomToNode,
  onNodeClick,
  data,
}: {
  id: string;
  name: string;
  featureViewType: FeatureViewNode['featureViewType'];
  description?: string;
  url: string;
  detailsPanelClearAll: () => void;
  zoomToNode?: () => void;
  onNodeClick: NodeClickCallbackCreator;
  data?: FeatureViewDetailsPanelData;
}) => {
  const prehead = `${featureViewType === 'batch' ? 'Batch' : 'Stream'} Feature View`;

  return (
    <DataFlowSidePanel
      name={name}
      prehead={prehead}
      nodeId={id}
      url={url}
      detailsPanelClearAll={detailsPanelClearAll}
      zoomToNode={zoomToNode}
    >
      <DescriptionSection description={description || mockDescription} />

      {data && (
        <>
          {data.downstreamNodes.length === 0 && (data.onlineMaterialization || data.offlineMaterialization) && (
            <ContextPanelCallOut color="warning" title="Unused Feature View" iconType={'warning'}>
              This Feature View has materialization enabled, but it is not being used in any Realtime Feature Views or
              Feature Services. To help reduce costs, we suggest disabling materialization on unused Feature Views.
            </ContextPanelCallOut>
          )}
          <DetailsPanelAccordionSection
            id="materialization"
            title={
              <Title size="xxs">
                <h4>Materialization Configs</h4>
              </Title>
            }
            initialIsOpen
          >
            <AttributesList
              compressed
              listItems={[
                {
                  title: 'Online',
                  description: <EnabledDisabledBadge enabled={data.onlineMaterialization} />,
                },
                {
                  title: 'Offline',
                  description: <EnabledDisabledBadge enabled={data.offlineMaterialization} />,
                },
              ]}
            />
          </DetailsPanelAccordionSection>

          <DetailsPanelAccordionSection
            id="output-features"
            title={
              <Title size="xxs">
                <h4>Feature List ({data.features.length})</h4>
              </Title>
            }
          >
            <FeatureColumnsTable items={data.features} />
          </DetailsPanelAccordionSection>

          <UpstreamDownstreamTable
            onNodeClick={onNodeClick}
            upstreamNodes={data.upstreamNodes}
            downstreamNodes={data.downstreamNodes}
          />

          {data.transformations && data.transformations.length > 0 && (
            <TransformationAccordion transformations={data.transformations} />
          )}
        </>
      )}
    </DataFlowSidePanel>
  );
};

export default FeatureViewContextPanel;
