import React from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { Routes } from '../../../core/routes';
import { useNavigate } from 'react-router';

import {
  useTectonTheme,
  Panel,
  Icon,
  LoadingSpinner,
  Text,
  Spacer,
  Button,
  FlexGroup,
  FlexItem,
} from '@tecton/ComponentRedesign';

import { StatusListItem } from './StyledComponents';
import { DataPlatformSetupTaskStatus } from '../../../types/tecton_proto/data/onboarding';

import Checkmark from '@svg/checkmark.svg';

export const FormProgressFeedback = ({
  tasks,
  setupCompleted,
}: {
  tasks: DataPlatformSetupTaskStatus[];
  setupCompleted?: boolean;
}) => {
  const { theme } = useTectonTheme();
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  return (
    <Panel paddingSize="m" color="subdued">
      <FlexGroup direction="column" gutterSize="m">
        <FlexItem>
          <>Platform Set Up Progress&hellip;</>
        </FlexItem>
        <FlexItem>
          <Panel hasShadow={false} hasBorder paddingSize="none">
            {tasks.map((p) => {
              return (
                <StatusListItem key={p.task_display_name} paddingSize="s" hasShadow={false}>
                  <div>
                    {p.task_status === 'TASK_STATUS_SUCCEEDED' && <Icon type={Checkmark} />}
                    {p.task_status === 'TASK_STATUS_FAILED' && <Icon type="warning" color={theme.colors.danger} />}
                    {p.task_status === 'TASK_STATUS_RUNNING' && <LoadingSpinner size="m" />}
                    {p.task_status === 'TASK_STATUS_NOT_STARTED' && <LoadingSpinner size="m" />}
                  </div>
                  <div>{p.error_message ? p.error_message : p.task_display_name}</div>
                </StatusListItem>
              );
            })}
            {setupCompleted && (
              <Panel>
                <Spacer size="s" />
                <Text>
                  <h3>Databricks Setup Complete</h3>
                  <p>
                    With Databricks connected, your feature platform is ready for use. We have created your first
                    Workspace, where you can begin developing features.
                  </p>
                </Text>
                <Spacer size="l" />
                <Button
                  variant="emptyAction"
                  label={'Go to prod workspace dashboard'}
                  onClick={() => {
                    queryClient.invalidateQueries(['get-onboarding-status']);
                    navigate(`${Routes.defaultProdHome}?onboarding=success`);
                  }}
                />
              </Panel>
            )}
          </Panel>
        </FlexItem>
      </FlexGroup>
    </Panel>
  );
};

export default FormProgressFeedback;
