import MetadataService from '../../service/MetadataService';
import { useQuery } from '@tanstack/react-query';

export function useSavedFeatureDataFrames(workspace) {
  const state = useQuery(['workspace', workspace, 'savedFeatureDataFrames'], () => {
    const data = MetadataService.sharedInstance().getAllSavedFeatureDataFrames();
    return data;
  });
  if (state.error) {
    throw state.error;
  }
  return state;
}
