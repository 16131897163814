export const getWorkspaceFromLocalStorage = () => localStorage.getItem('MOST_RECENTLY_VIEWED_WORKSPACE') ?? 'prod';

const toCamelCase = (str: string) => {
  return str.replace(/([-_][a-z])/gi, ($1: string) => {
    return $1.toUpperCase().replace('-', '').replace('_', '');
  });
};

export const toSnakeCase = (str: string) => {
  return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
};

export const convertKeysToCamelCase: any = (obj: any) => {
  if (Array.isArray(obj)) {
    return obj.map((v) => convertKeysToCamelCase(v));
  } else if (obj !== null && obj?.constructor === Object) {
    return Object.keys(obj).reduce((result: any, key) => {
      result[toCamelCase(key)] = convertKeysToCamelCase(obj[key]);
      return result;
    }, {});
  }
  return obj;
};

export const convertKeysToSnakeCase: any = (obj: any) => {
  if (Array.isArray(obj)) {
    return obj.map((v) => convertKeysToSnakeCase(v));
  } else if (obj !== null && obj?.constructor === Object) {
    return Object.keys(obj).reduce((result: any, key) => {
      result[toSnakeCase(key)] = convertKeysToSnakeCase(obj[key]);
      return result;
    }, {});
  }
  return obj;
};
