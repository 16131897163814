/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { replaceWorkspace } from '../../../utils/route-utils';
import { Routes } from '../../../core/routes';
import { NavLink, useParams } from 'react-router-dom';
import Title from '../../../utils/title-utils';
import { isNavigationActiveCallback } from '../../../utils/NavLink-Utils';
import MonitoringSummary from './MonitoringSummary';
import { Paper } from '@mui/material';

const FeatureMonitoringSummary = () => {
  const { workspace } = useParams();

  return (
    <div id="content" className="content-shadow overflow-y-scroll h-100">
      <Title title="Monitoring Summary" />
      <div className="content-head">
        <div>
          <div className="f3 fw7 mb3">Features</div>
          <div className="tabs" data-testid="tabs">
            <NavLink
              to={replaceWorkspace(Routes.featureViews, workspace)}
              end={true}
              className={isNavigationActiveCallback}
            >
              Feature Views and Tables
            </NavLink>
            <NavLink
              to={replaceWorkspace(Routes.features, workspace)}
              end={true}
              className={isNavigationActiveCallback}
            >
              All Features
            </NavLink>
            <NavLink
              to={replaceWorkspace(Routes.featureMonitoringSummary, workspace)}
              end={true}
              className={isNavigationActiveCallback}
            >
              Monitoring Summary
            </NavLink>
          </div>
        </div>
      </div>
      <div className="pa5 " data-testid="tabs-content">
        <Paper square>
          <div className="pa4">
            <MonitoringSummary />
          </div>
        </Paper>
      </div>
    </div>
  );
};

export default FeatureMonitoringSummary;
