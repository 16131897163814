import styled from '@emotion/styled';
import React, { type FC, type ReactElement, useState } from 'react';
import {
  Avatar,
  Tooltip,
  Popover,
  Icons,
  IconTypes,
  AvatarAndName,
  Switch,
  FlexGroup,
} from '@tecton/ComponentRedesign';
import { useUserSettings } from '../../context/UserSettingsContext';
import { useNavigate } from 'react-router';

const ItemWrapper = styled.div<{ isHighlighted: boolean }>`
  display: flex;
  padding: ${({ theme }) => theme.padding.s} ${({ theme }) => theme.padding.m};
  justify-content: flex-start;
  align-items: center;
  gap: ${({ theme }) => theme.padding.s};
  align-self: stretch;
  border-radius: ${({ theme }) => theme.border.radius.medium};
  cursor: pointer;
  min-width: 160px;
  width: 100%;

  color: ${({ theme, isHighlighted }) => (isHighlighted ? theme.colors.title : theme.colors.text)};

  background-color: ${({ theme, isHighlighted }) => (isHighlighted ? theme.colors.emptyShade : 'none')};

  :hover {
    background-color: ${({ theme }) => theme.colors.lightestShade};

    span {
      text-decoration: underline;
    }

    ellipse,
    path,
    rect,
    circle {
      stroke: ${({ theme }) => theme.colors.primary};
    }
  }
`;

const IconWrapper = styled.div`
  align-items: center;
  display: flex;
  height: ${({ theme }) => theme.size['l']};
  justify-content: center;
  width: ${({ theme }) => theme.size['l']};

  div {
    flex: 0 0 auto;
  }
`;

const PopoverItem: FC<{ label: string; icon: ReactElement; onClick?: () => void }> = ({ label, icon, onClick }) => {
  const [isHighlighted, setIsHighlighted] = useState<boolean>(false);

  return (
    <ItemWrapper
      isHighlighted={isHighlighted}
      onMouseEnter={() => {
        setIsHighlighted(true);
      }}
      onMouseLeave={() => {
        setIsHighlighted(false);
      }}
      onClick={onClick}
    >
      <IconWrapper>{icon}</IconWrapper>
      <span>{label}</span>
    </ItemWrapper>
  );
};

// This will be used once dark mode is ready for testing
const ThemeModeSwitcher: FC<{}> = ({}) => {
  const [isHighlighted, setIsHighlighted] = useState<boolean>(false);

  const { uiTheme, setUiTheme } = useUserSettings();

  return (
    <ItemWrapper
      isHighlighted={isHighlighted}
      onMouseEnter={() => {
        setIsHighlighted(true);
      }}
      onMouseLeave={() => {
        setIsHighlighted(false);
      }}
      onClick={(e) => {
        if (uiTheme === 'light') {
          setUiTheme && setUiTheme!('dark');
        } else {
          setUiTheme && setUiTheme('light');
        }
      }}
    >
      <IconWrapper>
        <FlexGroup gutterSize="xs">
          <Switch label={undefined} checked={uiTheme === 'dark'} setChecked={() => {}} />
          <div style={{ whiteSpace: 'nowrap' }}>{uiTheme === 'dark' ? <>Dark UI</> : <>Light UI</>}</div>
        </FlexGroup>
      </IconWrapper>
    </ItemWrapper>
  );
};

type AvatarPopoverType = {
  userName: string;
  email: string;
  onLogoutClick: () => void;
  closePopover: () => void;
};

const InfoPopoverItem = styled.div`
  align-items: center;
  border-bottom: ${({ theme }) => theme.border.thin};
  display: flex;
  gap: ${({ theme }) => theme.padding.s};
  margin-bottom: ${({ theme }) => theme.padding.s};
  padding: 0 0 ${({ theme }) => theme.padding.s} 0;
`;

const AvatarPopover: FC<AvatarPopoverType> = ({ onLogoutClick, userName, email, closePopover }) => {
  const navigate = useNavigate();

  return (
    <>
      <InfoPopoverItem>
        <PopoverItem
          label={email}
          icon={<Avatar name={userName} />}
          onClick={() => {
            navigate(`/settings/accounts-and-access/users/${email}/workspaces`);
            closePopover();
          }}
        />
      </InfoPopoverItem>
      <PopoverItem
        label={'Logout'}
        icon={Icons[IconTypes.LOGOUT]}
        onClick={() => {
          onLogoutClick();
        }}
      />
      {/* Add the theme switcher here when dark mode is available */}
    </>
  );
};

const DocsPopover = () => {
  return (
    <>
      <a href="https://docs.tecton.ai/" target="_blank">
        <PopoverItem label={'Documentation'} icon={Icons[IconTypes.DOCUMENTATION]} />
      </a>
      <a href="https://www.tecton.ai/whats-new/" target="_blank">
        <PopoverItem label={"What's New"} icon={Icons[IconTypes.WHATS_NEW]} />
      </a>
    </>
  );
};

const DocsButtonIconWrapper = styled.div`
  width: 44px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer !important;

  :hover {
    ellipse,
    path,
    rect,
    circle {
      stroke: ${({ theme }) => theme.colors.primary};
    }
  }
`;

const DocsButton: FC<{ onClick: () => void }> = ({ onClick }) => {
  return <DocsButtonIconWrapper onClick={onClick}>{Icons[IconTypes.SUPPORT]}</DocsButtonIconWrapper>;
};

interface TectonSideNavigationFooterProps {
  isCollapsed?: boolean;
  whatsNewCount?: boolean;
  userName: string;
  email: string;
  onBellClick: () => void;
  onLogoutClick: () => void;
}

const Footer = styled.div`
  width: 44px;
  height: 96px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const CollapsedFooterIconWrapper = styled.div`
  width: 44px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer !important;
`;

const CollapsedFooter: FC<TectonSideNavigationFooterProps> = ({ userName, email, onLogoutClick }) => {
  const [isAvatarPopoverOpen, setIsAvatarPopoverOpen] = useState<boolean>(false);
  const [isDocsPopoverOpen, setIsDocsPopoverOpen] = useState<boolean>(false);

  const closeAvatarPopover = () => {
    setIsAvatarPopoverOpen(false);
  };

  return (
    <Footer>
      <Popover
        panelPaddingSize="s"
        button={
          <>
            <CollapsedFooterIconWrapper
              onClick={() => {
                setIsAvatarPopoverOpen(!isAvatarPopoverOpen);
              }}
            >
              {isAvatarPopoverOpen ? (
                <>
                  <Avatar name={userName} hasMargin={false} />
                </>
              ) : (
                <>
                  <Tooltip trigger={<Avatar name={userName} hasMargin={false} />} content={userName} position="right" />
                </>
              )}
            </CollapsedFooterIconWrapper>
          </>
        }
        body={
          <AvatarPopover
            userName={userName}
            email={email}
            closePopover={closeAvatarPopover}
            onLogoutClick={onLogoutClick}
          />
        }
        isOpen={isAvatarPopoverOpen}
        closePopover={() => {
          setIsAvatarPopoverOpen(false);
        }}
      />
      <Popover
        button={
          isDocsPopoverOpen ? (
            <DocsButton
              onClick={() => {
                setIsDocsPopoverOpen(!isDocsPopoverOpen);
              }}
            />
          ) : (
            <Tooltip
              trigger={
                <DocsButton
                  onClick={() => {
                    setIsDocsPopoverOpen(!isDocsPopoverOpen);
                  }}
                />
              }
              content={'Documentation'}
              position="right"
            />
          )
        }
        body={<DocsPopover />}
        isOpen={isDocsPopoverOpen}
        closePopover={() => {
          setIsDocsPopoverOpen(false);
        }}
      />
    </Footer>
  );
};

const FooterContainer = styled.div`
  width: 216px;
  height: 64px;
  border-top: ${({ theme }) => theme.border.thin};
  display: flex;
  align-items: center;
`;

const FooterStyle = styled.div`
  display: grid;
  grid-template-columns: 162px 44px;
  gap: 10px;
`;

const AvatarContainer = styled.div`
  width: 162px;
  height: 36px;
  display: grid;
  grid-template-columns: 1fr 20px;
  overflow: hidden;
  align-items: center;
  cursor: pointer;
  border-radius: ${({ theme }) => theme.padding.s};

  :hover {
    background-color: ${({ theme }) => theme.colors.lightestShade};
  }
`;
const TectonSideNavigationFooter: FC<TectonSideNavigationFooterProps> = ({
  isCollapsed,
  whatsNewCount,
  userName,
  email,
  onBellClick,
  onLogoutClick,
}) => {
  const [isAvatarPopoverOpen, setIsAvatarPopoverOpen] = useState<boolean>(false);
  const [isDocsPopoverOpen, setIsDocsPopoverOpen] = useState<boolean>(false);

  const closeAvatarPopover = () => {
    setIsAvatarPopoverOpen(false);
  };

  if (isCollapsed) {
    return (
      <CollapsedFooter
        whatsNewCount={whatsNewCount}
        userName={userName}
        email={email}
        onBellClick={onBellClick}
        onLogoutClick={onLogoutClick}
      />
    );
  }

  return (
    <FooterContainer>
      <FooterStyle>
        <Popover
          panelPaddingSize="s"
          body={
            <AvatarPopover
              userName={userName}
              email={email}
              closePopover={closeAvatarPopover}
              onLogoutClick={onLogoutClick}
            />
          }
          isOpen={isAvatarPopoverOpen}
          closePopover={() => {
            setIsAvatarPopoverOpen(false);
          }}
          button={
            <AvatarContainer
              onClick={() => {
                setIsAvatarPopoverOpen(!isAvatarPopoverOpen);
              }}
            >
              <AvatarAndName name={userName} />
              {Icons[IconTypes.CHEVRON_DOWN]}
            </AvatarContainer>
          }
        />
        <Popover
          button={
            <DocsButton
              onClick={() => {
                setIsDocsPopoverOpen(!isDocsPopoverOpen);
              }}
            />
          }
          body={<DocsPopover />}
          isOpen={isDocsPopoverOpen}
          closePopover={() => {
            setIsDocsPopoverOpen(false);
          }}
        />
      </FooterStyle>
    </FooterContainer>
  );
};

export default TectonSideNavigationFooter;
