import { FC, useContext, useState } from 'react';
import { useAssignRolesMutation } from '../../../api/acl';
import { useUserSettings } from '../../context/UserSettingsContext';
import AddServiceAccountToWorkspace from '../acl/AddServiceAccountToWorkspace';
import ModifyWorkspaceAccess from '../acl/ModifyWorkspaceAccess';
import PermissionsServiceAccountList from './PermissionsServiceAccountList';

import { getLegacyRoleIdGQL } from '../../acl/aclUtils';
import { getFilterRolesForServiceAccount, getRoleOptions, getRoleToPermissionMap } from '../acl/acl-util';

// generated
import { RoleAssignmentSource, ServiceAccount } from '../../../api/gql/graphql';
import { useGetPermissions } from '../../../api/queries/aclGraphql';
import { PrincipalType } from '../../../types/tecton_proto/auth/principal';
import { ResourceType } from '../../../types/tecton_proto/auth/resource';

import { Modal, getToastComponent } from '@tecton/ComponentRedesign';
import { ToastContext, addToast } from '@tecton/ToastContext';
import { logEvent } from '../../../utils/analytics-utils';

const PermissionsServiceAccountsListContainer: FC = () => {
  const { hasWorkspaceManagerAccess, workspace } = useUserSettings();
  const toastContext = useContext(ToastContext);

  const { data, isLoading, isError } = useGetPermissions(workspace ?? '');
  const assignRolesMutation = useAssignRolesMutation();

  const [selectedServiceAccount, setSelectedServiceAccount] = useState<ServiceAccount | undefined>();

  // General states
  const [originalRole, setOriginalRole] = useState<string | undefined>();
  const [selectedRole, setSelectedRole] = useState<string | undefined>();
  const [roleAssignmentSource, setRoleAssignmentSource] = useState<RoleAssignmentSource[] | undefined>();

  // Modal state
  const [showEditWorkspaceRoleModal, setShowEditWorkspaceRoleModal] = useState<boolean>(false);
  const [showAddServiceAccountModal, setShowAddServiceAccountModal] = useState<boolean>(false);

  const permissionRoleFilter = ['Admin'];
  const filteredRoles = getFilterRolesForServiceAccount(
    data?.roles ?? [],
    PrincipalType.PRINCIPAL_TYPE_SERVICE_ACCOUNT
  );
  const roleIds = data?.roles.filter((role) => !permissionRoleFilter.includes(role.name))?.map((role) => role.id) ?? [];
  const editableRoles = getRoleOptions(filteredRoles, [...roleIds, 'none'], true);
  const AddServiceAccountRoles = getRoleOptions(filteredRoles, roleIds, false);

  const roleDescriptionMap = getRoleToPermissionMap(filteredRoles);

  const reset = () => {
    setSelectedServiceAccount(undefined);
    setOriginalRole(undefined);
    setRoleAssignmentSource(undefined);
    setShowEditWorkspaceRoleModal(false);
    setShowAddServiceAccountModal(false);
  };

  // --------------------------------------- Start: Actions ---------------------------------

  const onEditServiceAccountRole = (serviceAccount: ServiceAccount) => {
    const roleSource =
      serviceAccount.rolesGranted?.flatMap((rolesGranted) => {
        return rolesGranted?.roleAssignmentSources?.map((roleAssignmentSource) => roleAssignmentSource) ?? [];
      }) ?? [];
    setSelectedServiceAccount(serviceAccount);
    setOriginalRole(selectedServiceAccount?.workspaceRole ?? '');
    setSelectedRole(selectedServiceAccount?.workspaceRole ?? '');
    setRoleAssignmentSource(roleSource?.filter((group) => !!group?.principalGroupId));
    setShowEditWorkspaceRoleModal(true);
  };

  const onRoleChange = (roleName: string) => {
    setSelectedRole(roleName);
  };

  const onModifyWorkspaceRole = () => {
    const removingRole = selectedRole === 'None';
    const principal_type = PrincipalType.PRINCIPAL_TYPE_SERVICE_ACCOUNT;

    const role = getLegacyRoleIdGQL(filteredRoles, selectedRole ?? ''); // fix this.
    const roles = removingRole ? [] : [role ?? ''];
    const resource_id = workspace;

    const resource_type = ResourceType.RESOURCE_TYPE_WORKSPACE;

    const payload = {
      principal_id: selectedServiceAccount?.id ?? '',
      roles,
      resource_id,
      principal_type,
      resource_type,
    };

    assignRolesMutation.mutate(payload, {
      onSuccess: () => {
        reset();
        logEvent(
          `Permissions: Service Account ${removingRole ? `Service Account Removed` : `Service Role Modified`}`,
          '',
          {
            workspace: workspace,
            service_account_id: selectedServiceAccount?.id,
            service_account_name: selectedServiceAccount?.name,
            role: selectedRole,
          }
        );
        const toastContent = getToastComponent({
          variant: 'success',
          title: removingRole ? `Service Account Removed` : `Service Role Modified`,
          children: removingRole ? (
            <>
              Service Account {selectedServiceAccount?.name} removed from {workspace} workspace.
            </>
          ) : (
            <>
              Service Account {selectedServiceAccount?.name} role modified to {selectedRole}.
            </>
          ),
          showIcon: true,
        });

        toastContext?.dispatchToast(
          addToast({
            text: toastContent,
          })
        );
      },
      onError: () => {
        const toastContent = getToastComponent({
          variant: 'danger',
          title: removingRole ? `Failed To Remove Service Account` : `Failed To Modify Service Account Role`,
          children: removingRole ? (
            <>Failed to remove Service account from {workspace} workspace.</>
          ) : (
            <>Failed to modify service account role.</>
          ),
          showIcon: true,
        });

        toastContext?.dispatchToast(
          addToast({
            text: toastContent,
          })
        );
      },
    });
  };

  const onAddServiceAccountToWorkspaceConfirm = () => {
    const principal_type = PrincipalType.PRINCIPAL_TYPE_SERVICE_ACCOUNT;
    const role = getLegacyRoleIdGQL(data?.roles ?? [], selectedRole ?? '');
    const roles = [role ?? ''];
    const resource_id = workspace;
    const resource_type = ResourceType.RESOURCE_TYPE_WORKSPACE;

    const payload = {
      principal_id: selectedServiceAccount?.id ?? '',
      roles,
      resource_id,
      principal_type,
      resource_type,
    };

    assignRolesMutation.mutate(payload, {
      onSuccess: () => {
        reset();
        logEvent(`Permissions: Service Account Service Account Added`, '', {
          workspace: workspace,
          service_account_id: selectedServiceAccount?.id,
          service_account_name: selectedServiceAccount?.name,
          role: selectedRole,
        });
        const toastContent = getToastComponent({
          variant: 'success',
          title: `Service Account Added`,
          children: (
            <>
              Service Account {selectedServiceAccount?.name} added to {workspace} as {selectedRole}.
            </>
          ),
          showIcon: true,
        });

        toastContext?.dispatchToast(
          addToast({
            text: toastContent,
          })
        );
      },
      onError: () => {
        const toastContent = getToastComponent({
          variant: 'danger',
          title: `Failed To Add Service Account`,
          children: <>Failed to add service account to {workspace} workspace.</>,
          showIcon: true,
        });

        toastContext?.dispatchToast(
          addToast({
            text: toastContent,
          })
        );
      },
    });
  };

  // ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ End: Actions  ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^

  return (
    <>
      {showEditWorkspaceRoleModal && (
        <Modal
          title={`Modify role of ${selectedServiceAccount?.name} in ${workspace} `}
          body={
            <>
              <ModifyWorkspaceAccess
                roles={editableRoles}
                workspaceRole={selectedServiceAccount?.workspaceRole ?? ''}
                roleDescriptionMap={roleDescriptionMap}
                onRoleChange={onRoleChange}
                hasRoleFromOtherSources={selectedServiceAccount?.hasOtherSourcesOfAccountType ?? false}
                roleAssignmentSources={roleAssignmentSource}
                id={selectedServiceAccount?.id ?? ''}
              />
            </>
          }
          onCancel={() => {
            reset();
          }}
          confirmLabel="Change Access"
          onConfirm={() => {
            onModifyWorkspaceRole();
          }}
          variant="positive"
          confirmVariant={originalRole !== selectedRole ? 'primaryAction' : 'disabledAction'}
          isConfirmButtonLoading={assignRolesMutation.isLoading}
        />
      )}

      {showAddServiceAccountModal && (
        <Modal
          title={
            data?.assignableServiceAccounts?.length === 0
              ? 'All Service Accounts have access'
              : `Add Service Account to ${workspace}`
          }
          body={
            <>
              <AddServiceAccountToWorkspace
                roles={AddServiceAccountRoles}
                roleDescriptionMap={roleDescriptionMap}
                onChange={(selectedRole?: string, serviceAccount?: ServiceAccount) => {
                  setSelectedRole(selectedRole);
                  setSelectedServiceAccount(serviceAccount);
                }}
                assignableServiceAccounts={data?.assignableServiceAccounts ?? []}
              />
            </>
          }
          onCancel={() => {
            reset();
          }}
          confirmLabel="Change Access"
          onConfirm={() => {
            onAddServiceAccountToWorkspaceConfirm();
          }}
          variant={data?.assignableServiceAccounts?.length === 0 ? 'negative' : 'positive'}
          confirmVariant={selectedServiceAccount && selectedRole ? 'primaryAction' : 'disabledAction'}
          isConfirmButtonLoading={assignRolesMutation.isLoading}
        />
      )}

      <PermissionsServiceAccountList
        serviceAccounts={data?.serviceAccounts ?? []}
        hasWorkspaceOwnerRole={hasWorkspaceManagerAccess ?? false}
        isLoading={isLoading}
        isError={isError}
        onAddServiceAccountClick={() => {
          setShowAddServiceAccountModal(true);
        }}
        onEditServiceAccountRole={onEditServiceAccountRole}
      />
    </>
  );
};

export default PermissionsServiceAccountsListContainer;
