import _get from 'lodash/get';
interface FCO_OPTION {
  label: string;
  data: OPTION;
  value?: string;
}

interface OPTION {
  fco: any;
  fcoType: string;
  path: string;
  label: string;
  search_id?: string;
}

export const filterOption = (option: FCO_OPTION, inputValue: string) => {
  // if search id exists check that and label for a match
  if (option && _get(option, 'data.search_id')) {
    return option?.data.search_id?.includes(inputValue) || option?.label?.includes(inputValue);
  }

  return _get(option, 'label').includes(inputValue);
};
