import { TectonBasicTable } from '@shared';
import { Accordion, Panel, Title } from '@tecton';
import { isBoolean } from 'lodash';
import React from 'react';
import { ODFVSchemaEntry } from './detailsPanelQueryTypes';
import DetailsPanelAccordionSection from './DetailsPanelAccordionSection';

const ODFVSchemaTable = ({ schema }: { schema: ODFVSchemaEntry['schema'] }) => {
  if (!schema) {
    return <></>;
  }

  const items = schema.map((entry) => {
    const json = entry.structfield_json ? JSON.parse(entry.structfield_json) : undefined;

    const result = {
      name: entry.name || 'n/a',
      type: json && json.type ? json.type : 'n/a',
      nullable: json && isBoolean(json.nullable) ? json.nullable : 'n/a',
    };

    return result;
  });

  const columns = [
    {
      field: 'name',
      name: 'Name',
    },
    {
      field: 'type',
      name: 'Type',
    },
    {
      field: 'nullable',
      name: 'Nullable',
    },
  ];

  return (
    <Panel paddingSize="s" hasBorder>
      <TectonBasicTable items={items} columns={columns} pageIndex={0} pageSize={0} totalItemCount={items.length} />
    </Panel>
  );
};

const OnDemandInputSchemaSection = ({ schemaList }: { schemaList: ODFVSchemaEntry[] }) => {
  return (
    <DetailsPanelAccordionSection
      id="features-list"
      title={
        <Title size="xxs">
          <h4>Request Data Source Schema</h4>
        </Title>
      }
    >
      {schemaList.map((odfv) => {
        return (
          <Accordion
            id={odfv.id}
            arrowDisplay="right"
            buttonContent={
              <>
                {odfv.schema && odfv.schema.length} key from {odfv.name}
              </>
            }
          >
            {odfv.schema ? <ODFVSchemaTable schema={odfv.schema} /> : <>Schema Information Unavailable</>}
          </Accordion>
        );
      })}
    </DetailsPanelAccordionSection>
  );
};

export default OnDemandInputSchemaSection;
export { ODFVSchemaTable };
