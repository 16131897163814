import styled from '@emotion/styled';
import { FC } from 'react';
import {
  DataSourceNode,
  EmbeddingModelNode,
  FeatureServiceNode,
  FeatureViewNode,
  ODFVNode,
  TransformationNode,
} from './dataFlowTypes';

import { Tooltip } from '@tecton/ComponentRedesign';

interface DataFlowFCOCountBadgeProps {
  data: DataSourceNode | FeatureViewNode | ODFVNode | FeatureServiceNode | TransformationNode | EmbeddingModelNode;
}

const CountPillWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const CountPill = styled.div`
  color: ${({ theme }) => theme.colors.mediumShade};
  text-align: center;
  font-size: ${({ theme }) => theme.font.fontSizes.s};
  font-weight: ${({ theme }) => theme.font.weight.medium};
  padding: 0px ${({ theme }) => theme.padding.xs};
  width: 32px;
  height: 32px;
  border-radius: 32px; // Custom border-radius and size for this pill
  background-color: ${({ theme }) => theme.colors.lightestShade};
  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    background-color: ${({ theme }) => theme.colors.fullShade};
    color: ${({ theme }) => theme.colors.lightestShade};
  }
`;

const DataFlowFCOCountBadge: FC<DataFlowFCOCountBadgeProps> = ({ data }) => {
  if (data.type === 'odfv' || data.type === 'feature_service' || data.type === 'feature_view') {
    let embeddingCount = ``;
    let featuresCount = ``;

    if (data.type === 'feature_view') {
      const featureViewNode = data as FeatureViewNode;
      if (featureViewNode.modelCount) {
        embeddingCount =
          featureViewNode?.modelCount > 0
            ? featureViewNode?.modelCount > 1
              ? `${featureViewNode?.modelCount} Models`
              : `${featureViewNode?.modelCount} Model`
            : '';
      }

      if (featureViewNode.featuresCount) {
        featuresCount =
          featureViewNode?.featuresCount > 0
            ? featureViewNode?.featuresCount > 1
              ? `${featureViewNode?.featuresCount} Features`
              : `${featureViewNode?.featuresCount} Feature`
            : '';
      }
    }

    return (
      <CountPillWrapper>
        <Tooltip
          trigger={<CountPill>{data.schemaCount}</CountPill>}
          title={<>Schema Count: {data.schemaCount}</>}
          content={`${embeddingCount} ${featuresCount}`}
        />
      </CountPillWrapper>
    );
  }

  return <></>;
};

export default DataFlowFCOCountBadge;
