/*
This is the format for the chart
  const data = {
    datasets: [
      {
        data: [
          { x: 1661367780, y: 150 },
          { x: 1661367840, y: 148 },
          { x: 1661367900, y: 149 },
          { x: 1661368020, y: 130 },
        ],
      },
      {
        data: [
          { x: 1661367780, y: 60 },
          { x: 1661367840, y: 248 },
          { x: 1661367900, y: 139 },
          { x: 1661368020, y: 120 },
        ],
      },
    ],
  };
*/

import { format } from 'd3-format';
import _ from 'lodash';
import { ChartInstance } from './feature-views/graph-types';

export const getGraphColors = (opacity: number = 1.0) => {
  // Colors for graphs, like border  color or bar charts.
  return [
    `rgb(1,121,208, ${opacity})`,
    `rgb(230,117,26, ${opacity})`,
    `rgb(16,196,126, ${opacity})`,
    `rgb(212,41,22, ${opacity})`,
    `rgb(76,2,179, ${opacity})`,
    `rgb(165,42,42, ${opacity})`,
    `rgb(216,48,249, ${opacity})`,
    `rgb(65,86,107, ${opacity})`,
  ];
};

export const getTimeDisplayFormats = () => {
  // Display format for time x axis
  return {
    millisecond: 'HH:MM A',
    second: 'HH:mm',
    minute: 'HH:mm',
    hour: 'MM/DD/YY HH:MM',
    day: 'MM/DD/YY HH:MM',
    quarter: 'MM/DD/YY HH:MM',
  };
};

// Tuple type variable
type ChartData = [number, string];

export const transformPrometheusResponse = (chartData: ChartData[], parseValue = parseInt): any[] => {
  return chartData.map((data) => {
    // Convert it back to correct milliseconds.
    const x = data[0] * 1000;
    return { x: x, y: parseValue(data[1]) };
  });
};

export const transformGraphiteResponse = (graphiteResponse: []): any => {
  const data = graphiteResponse.map((data, indexCount) => {
    let chartData: any = [];
    const dataPoints: [] = _.get(data, 'datapoints');
    const target = _.get(data, 'target');
    if (dataPoints) {
      /**
       * https://tecton.atlassian.net/browse/TEC-15436
       * For Zoom feature to work in chartJS changing the payload
       *
       * from
       * "data": [
            [1688415990000, 0.66],
            [1688416050000, 0.429],
            [1688416110000, 0.627],
            [1688416170000, 0.726],
       ]
       to:
      "data": [
            [{
                "x": 1688416560000,
                "y": 12.282
            }],
            [{
                "x": 1688416620000,
                "y": 19.25
            }],
            [{
                "x": 1688416680000,
                "y": 18.9
            }],
            [{
                "x": 1688416740000,
                "y": 13.715
            }],
            [{
                "x": 1688416800000,
                "y": 8.857
            }],
       *
      */
      chartData = dataPoints
        .map((rowData: any) => {
          return [{ x: rowData[1] * 1000, y: rowData[0] }];
        })
        .flat();
    }

    const borderColor = getGraphColors()[indexCount];

    return {
      label: target,
      data: chartData,
      borderColor: borderColor,
    };
  });

  return data;
};

export const getMinutes = (seconds: any) => {
  return Math.round((seconds / 60) * 100) / 100;
};

export const customFeatureServicePluginLegendOverride = {
  display: true,
  position: 'right',
  align: 'start',
  labels: {
    boxHeight: 2,
    boxWidth: 10,
    display: false,
    useBorderRadius: true,
    borderRadius: 24,
  },
  font: {
    family: 'Inter',
  },
};

export const getChartOptions = ({
  title,
  xTitle,
  yTitle,
  yGrace,
  yTicksCallback,
  toolTipLabelCallback,
  unit,
  yOverride,
  pluginLegendOverride,
}: {
  title: string;
  xTitle: string;
  yTitle: string;
  yGrace: number | string;
  yTicksCallback: any;
  toolTipLabelCallback: (a: ChartInstance) => string;
  unit: string;
  yOverride?: any;
  pluginLegendOverride?: any;
}) => {
  //Check to see if we have overrides
  const pluginLegend = pluginLegendOverride
    ? pluginLegendOverride
    : {
        display: true,
        labels: {
          boxHeight: 2,
          boxWidth: 15,
          display: false,
        },
      };

  //Check to see if we have overrides
  const yOptions = yOverride
    ? yOverride
    : {
        grace: yGrace,
        title: {
          display: true,
          text: yTitle,
          font: {
            size: 12,
          },
        },
        ticks: {
          callback: yTicksCallback,
        },
      };

  let hasListener = false;

  return {
    spanGaps: true,
    defaultFontFamily: 'Inter',
    animation: {
      duration: 1000,
    },
    borderColor: getGraphColors(),
    backgroundColor: getGraphColors(0.1),
    fill: false,
    radius: 2,
    tension: 0.0,
    scales: {
      x: {
        bounds: 'ticks',
        title: {
          display: true,
          text: xTitle,
          font: {
            size: 12,
          },
        },
        type: 'time',
        time: {
          unit: unit,
          displayFormats: getTimeDisplayFormats(),
        },
      },
      y: yOptions,
    },
    legend: {
      display: true,
    },
    plugins: {
      legend: pluginLegend,
      title: {
        display: true,
        text: title,
        font: {
          size: 15,
        },
      },
      tooltip: {
        mode: 'interpolate',
        intersect: false,
        callbacks: {
          label: toolTipLabelCallback,
        },
      },
      crosshair: {
        line: {
          color: '#F66', // crosshair line color
          width: 1, // crosshair line width
        },
        sync: {
          enabled: true, // enable trace line syncing with other charts
          group: 4, // chart group
          suppressTooltips: false, // suppress tooltips when showing a synced tracer
        },
        snap: {
          enabled: true,
        },
        zoom: {
          enabled: true, // enable zooming
          zoomboxBackgroundColor: 'rgba(108,118,130, 0.2)', // background color of zoom box
          zoomboxBorderColor: '#48F', // border color of zoom box
          zoomButtonText: 'Reset Zoom', // reset zoom button text
          zoomButtonClass: 'reset-zoom', // reset zoom button class
        },
      },
    },
    onResize: (chart: any) => {
      try {
        // make sure we only have one listener
        if (!hasListener) {
          hasListener = true;
          window.addEventListener('resize', () => {
            // Causes a Render to fit it's container
            chart.resize(10, 10);
          });
        }
      } catch (e) {
        // ignore error
      }
    },
  };
};

export const getFeatureHealthStateIds = (result: any[]) => {
  const staleIds = result.map((i) => {
    return i.result.map((r: any) => {
      return r.metric.feature_package_id;
    });
  });

  return staleIds[0];
};

export const getFeatureHealthResults = (result: any[]) => {
  const healthResults = result.map((i) => {
    return i.result.map((r: any) => {
      return { ...r.metric };
    });
  });
  return healthResults.flat();
};

export const getValueUnit = (value: number): string | Error => {
  if (typeof value !== 'number') {
    throw new Error('Value must be a number.');
  }

  if (value / 1000000 >= 1 || Math.round((value / 1000000) * 100) / 100 === 1) {
    return `${Math.round((value / 1000000) * 100) / 100}Mil`;
  } else if (value / 1000 >= 1 || Math.round((value / 1000) * 100) / 100 === 1) {
    return `${Math.round((value / 1000) * 100) / 100}k`;
  }

  const floatFormatterBy3 = format('.3~f');

  return `${floatFormatterBy3(value)}`;
};
