import React, { FC } from 'react';

import { TaskType } from '../../../types/tecton_proto/materialization/spark_cluster';

interface TaskTypeBadgeProps {
  taskType?: TaskType;
}

export const taskTypeLabelMap: Record<TaskType, string> = {
  [TaskType.UNKNOWN]: 'Unknown',
  [TaskType.BATCH]: 'Batch',
  [TaskType.DELETION]: 'Deletion',
  [TaskType.DELTA_MAINTENANCE]: 'Delta Maintenance',
  [TaskType.FEATURE_EXPORT]: 'Feature Export',
  [TaskType.INGEST]: 'Ingest',
  [TaskType.STREAMING]: 'Stream',
  [TaskType.DATASET_GENERATION]: 'Dataset Generation',
  [TaskType.PLAN_INTEGRATION_TEST_BATCH]: 'Integration Test (Batch)',
  [TaskType.PLAN_INTEGRATION_TEST_STREAM]: 'Integration Test (Stream)',
};

const TaskTypeBadge: FC<TaskTypeBadgeProps> = ({ taskType }) => {
  if (taskType) {
    return <>{taskTypeLabelMap[taskType]}</>;
  }

  return <></>;
};

export default TaskTypeBadge;
