export type EntityRoutes = 'Metadata' | 'Feature Views';

export const getEntityRoutes = (): Record<EntityRoutes, string> => {
  return {
    Metadata: 'overview',
    'Feature Views': 'feature-views',
  };
};

export const getEntityRouteName = (pathname: string): EntityRoutes => {
  const pathSegments = pathname.split('/');
  const lastPathSegment = pathSegments[pathSegments.length - 1];
  const routes = getEntityRoutes();
  const record = Object?.entries(routes)?.find((record) => {
    return record[1] === lastPathSegment;
  });

  return (record?.[0] ?? 'Metadata') as EntityRoutes;
};
