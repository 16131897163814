import { Autocomplete, FilterOptionsState, Popper } from '@mui/material';
import React, { SyntheticEvent, FC, useState, ReactNode } from 'react';
import NewWorkspaceSelectorInput from './NewWorkspaceSelectorInput';
import NewWorkspaceSelectorOption from './NewWorkspaceSelectorOption';
import { generateAutocompleteList } from './utils';
import { useNavigate, useParams } from 'react-router';
import { getPathStateAction } from '../../../../shared/selectors';
import UrlUtils from '../../../../utils/url-utils';
import style from './style.module.scss';
import { useSelector } from 'react-redux';
import { useUserSettings } from '../../../../components/context/UserSettingsContext';

export enum NewWorkspaceSelectorDropdownType {
  GROUP_HEADING = 'GROUP_HEADING',
  OPTION = 'OPTION',
}

export interface NewWorkspaceSelectorAutocompleteOption {
  dropdownType: NewWorkspaceSelectorDropdownType;
  label: string;
  isAccessible?: boolean;
  capabilities?: { [key: string]: boolean };
}

interface NewWorkspaceSelectorProperties {}

const caretSvg = (
  <svg height="20" width="20" viewBox="0 0 20 20">
    <path
      fill="#f2f7fa"
      d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"
    ></path>
  </svg>
);

const NewWorkspaceSelector: FC<NewWorkspaceSelectorProperties> = () => {
  const { allWorkspaces } = useUserSettings();
  // Styling for the caret
  const downCaretWrapper = <div className="mr4">{caretSvg}</div>;
  const upCaretWrapper = <div style={{ position: 'relative', left: 18, top: 5 }}>{caretSvg}</div>;

  // Styling for the Popper. No rounded corners for the popper
  const popperStyle = {
    paper: {
      sx: {
        borderRadius: 0,
      },
    },
  };

  // Copied from existing selector, will need to be reviewed
  const { workspace } = useParams();
  const { workspace: currentWorkspace, updateWorkspace } = useUserSettings();

  const history = useNavigate();
  const pathState = useSelector((state) => getPathStateAction(state));
  const [downCaretState, setDownCaretState] = useState<ReactNode>(downCaretWrapper);

  // Don't proceed if workspaces are loading
  if (allWorkspaces === undefined) {
    return (
      <>
        <div style={{ color: 'white' }}>NOT LOADED</div>
      </>
    );
  }

  const autoCompleteOptions = generateAutocompleteList(allWorkspaces!);

  const renderInput = (params: any) => {
    return <NewWorkspaceSelectorInput params={params} />;
  };

  const renderOption = (props: any, option: NewWorkspaceSelectorAutocompleteOption) => {
    return <NewWorkspaceSelectorOption props={props} option={option} />;
  };

  const listboxProps = {
    style: { height: 'auto', maxHeight: '90vh', borderRadius: 0, padding: 0, margin: 0 },
  };

  if (currentWorkspace === null) {
    return <></>;
  }

  const filterOptions = (
    options: NewWorkspaceSelectorAutocompleteOption[],
    state: FilterOptionsState<NewWorkspaceSelectorAutocompleteOption>
  ): NewWorkspaceSelectorAutocompleteOption[] => {
    return options.filter((option) => {
      if (option.dropdownType === NewWorkspaceSelectorDropdownType.GROUP_HEADING) {
        return true;
      }

      if (option.label.includes(state.inputValue)) {
        return true;
      }

      return false;
    });
  };

  return (
    <Autocomplete
      blurOnSelect={true}
      className={style.autoComplete}
      renderInput={renderInput}
      renderOption={renderOption}
      options={autoCompleteOptions}
      PopperComponent={Popper}
      ListboxProps={listboxProps}
      disableClearable={true}
      popupIcon={downCaretState}
      componentsProps={popperStyle}
      filterOptions={filterOptions}
      onOpen={() => {
        setDownCaretState(upCaretWrapper);
      }}
      onClose={() => {
        setDownCaretState(downCaretWrapper);
      }}
      onChange={(event: SyntheticEvent<Element, Event>, value: NewWorkspaceSelectorAutocompleteOption | null) => {
        if (value === null) {
          return;
        }

        if (value?.label) {
          updateWorkspace(value.label);
        }

        if (workspace !== undefined) {
          const newPathName = UrlUtils.buildPath(pathState.path, { ...pathState.params, workspace: value.label });
          history(newPathName);
        }
      }}
    />
  );
};

export default NewWorkspaceSelector;
