import * as yup from 'yup';
import { DPOConfigRequestType } from '../../api/userDeploymentSettings';
import { UserDeploymentSettings } from '../../types/tecton_proto/data/user_deployment_settings';
import { SparkConfigurationMapKey, SubmissionInterface, FieldMaskValues, KeyPairInterface } from './types';

export const databricksTextfieldPlaceholder = `${window.location.protocol}//tecton-${window.location.hostname}.cloud.databricks.com`;

export const databricksApiTokenValidation = yup
  .string()
  .matches(new RegExp('^dapi'), 'API tokens must start with "dapi"')
  .matches(new RegExp('^[a-zA-Z0-9]*$'), 'API tokens should only contains letters and numbers')
  .min(36, 'API tokens are expected to be 36 characters long.')
  .max(36, 'API tokens are expected to be 36 characters long.')
  .required('API token is required.');

export const datatBricksUrlValidation = yup
  .string()
  .matches(new RegExp(/^https:\/\//), 'Workspace url must start with https://')
  .matches(new RegExp(/.cloud.databricks.com$/), 'Workspace url must end in cloud.databricks.com')
  .url('Must be a valid URL.')
  .required(`Databricks URL is required. Example: ${databricksTextfieldPlaceholder}`);

export const instanceProfileValidation = yup
  .string()
  .notRequired()
  .matches(
    new RegExp(/^arn:aws:iam::[0-9]{12}:instance-profile\/[a-zA-Z0-9-_+=,.@/]+$/gm),
    'Instance profile ARN should use the format "arn:aws:iam::<account-ID>:instance-profile/<profile-name>"'
  );

export const glueCatalogValidation = yup
  .string()
  .notRequired()
  .matches(/^[0-9]{12}$/, 'Glue Catalog ID must be exactly 12 digits.');

export const dataPlatformConfigFormSchema = yup.object().shape({
  api_token: databricksApiTokenValidation,
  instance_profile_arn: instanceProfileValidation,
  glueCatalogId: glueCatalogValidation,
  workspace_url: datatBricksUrlValidation,
});

export const dataPlatformDatabricksUrlSchema = yup.object().shape({
  workspace_url: datatBricksUrlValidation,
});

export const mapDPOSubmission = (submission: SubmissionInterface, fields: FieldMaskValues[]): DPOConfigRequestType => {
  const spark_conf: KeyPairInterface | {} = submission.glueCatalogEnabled
    ? {
        [SparkConfigurationMapKey.GLUE_CATALOG_ID_ENABLED]: 'true',
        [SparkConfigurationMapKey.GLUE_CATALOG_ID]: submission.glueCatalogId,
      }
    : {};

  const user_deployment_settings: UserDeploymentSettings = {
    databricks_config: {
      workspace_url: `${submission.workspace_url}`,
      api_token: {
        value: `${submission.api_token}`,
      },
    },
    user_spark_settings: {
      instance_profile_arn: `${submission.instance_profile_arn}`,
      spark_conf: spark_conf,
    },
  };

  return {
    user_deployment_settings,
    field_mask: fields.join(','),
  };
};

export const getFieldMaskValues = (submission: SubmissionInterface, savedData: SubmissionInterface): any => {
  const out = new Set();
  for (const [key, value] of Object.entries(submission)) {
    const field = FieldMaskValues[key as keyof SubmissionInterface];
    if (value !== savedData[key as keyof SubmissionInterface] && field) {
      out.add(field as string);
    }
  }
  return [...out];
};
