import { Badge, FlexGroup, FlexItem, useTectonTheme } from '@tecton/ComponentRedesign';
import SelectableListItem from '@tecton/ComponentRedesign/lib/SelectableListItem';
import { Text } from '@tecton/ComponentRedesign/lib/v1';
import { FC, useEffect, useState } from 'react';
import { ServerGroup } from '../../../api/serverGroup';
import { ServerGroupType } from '../../../types/tecton_proto/common/server_group_type';

export interface ServerGroupSelectable extends ServerGroup {
  checked?: boolean;
}
export interface FeatureServerGroupListsProps {
  featureServerGroups?: ServerGroupSelectable[];
  transformationServerGroups?: ServerGroupSelectable[];
  selectedServerGroup?: ServerGroupSelectable | undefined;
  onServerGroupSelected: (serverGroup?: ServerGroupSelectable) => void;
}

const EmptyServerGroup: FC<{ emptyText: string }> = ({ emptyText }) => {
  const { theme } = useTectonTheme();
  return (
    <SelectableListItem variant="normal" isSelectable={false}>
      <FlexGroup direction="column" justifyContent="center" alignItems="center">
        <FlexItem>
          <Text small weight="regular" color={theme.colors.title}>
            {emptyText}
          </Text>
        </FlexItem>
      </FlexGroup>
    </SelectableListItem>
  );
};

const GroupHeader: FC<{ groupName: string; showBadge?: boolean }> = ({ groupName, showBadge = true }) => {
  const { theme } = useTectonTheme();
  return (
    <SelectableListItem variant="group">
      <FlexGroup gutterSize="s" alignItems="center">
        <FlexItem grow={false}>
          <Text element="h5" color={theme.colors.title}>
            {groupName}
          </Text>
        </FlexItem>

        {showBadge && (
          <FlexItem grow={false}>
            <Badge label="Preview" />
          </FlexItem>
        )}
      </FlexGroup>
    </SelectableListItem>
  );
};

const SelectableServerGroup: FC<{
  isSelected: boolean;
  serverGroup: ServerGroupSelectable;
  onClick: (type: ServerGroupType, serverGroup?: ServerGroupSelectable) => void;
  serverGroupType: ServerGroupType;
}> = ({ isSelected, serverGroup: serverGroup, onClick, serverGroupType }) => {
  const { theme } = useTectonTheme();
  return (
    <SelectableListItem
      variant="normal"
      isSelected={isSelected}
      onClick={() => {
        onClick(serverGroupType, serverGroup);
      }}
    >
      <FlexGroup direction="row" justifyContent="spaceBetween" alignItems="center">
        <FlexItem>
          <Text small weight="medium" color={theme.colors.title}>
            {serverGroup.name}
          </Text>
        </FlexItem>
      </FlexGroup>
    </SelectableListItem>
  );
};

const FeatureServerGroupLists: FC<FeatureServerGroupListsProps> = ({
  featureServerGroups = [],
  transformationServerGroups = [],
  onServerGroupSelected,
  selectedServerGroup,
}) => {
  const [featureGS, setFeatureGS] = useState<ServerGroupSelectable[]>(
    featureServerGroups.map((serverGroup) => {
      if (serverGroup.serverGroupId === selectedServerGroup?.serverGroupId) {
        return { ...serverGroup, checked: true };
      }
      return serverGroup;
    })
  );
  const [transformSG, setTransformSG] = useState<ServerGroupSelectable[]>(
    transformationServerGroups.map((serverGroup) => {
      if (serverGroup.serverGroupId === selectedServerGroup?.serverGroupId) {
        return { ...serverGroup, checked: true };
      }
      return serverGroup;
    })
  );

  useEffect(() => {
    if (selectedServerGroup === undefined) {
      resetFsgTsg();
    }
  }, [selectedServerGroup]);

  const resetFsgTsg = () => {
    // Allows for only one selection;
    setFeatureGS(
      featureGS.map((fsg) => {
        fsg.checked = false;
        return fsg;
      })
    );
    setTransformSG(
      transformSG.map((tsg) => {
        tsg.checked = false;
        return tsg;
      })
    );
  };

  const onClick = (type: ServerGroupType, serverGroup?: ServerGroupSelectable) => {
    // Set all the isSelected to false;
    resetFsgTsg();
    onServerGroupSelected({ ...serverGroup, checked: true });

    if (type === ServerGroupType.SERVER_GROUP_TYPE_FEATURE_SERVER_GROUP) {
      setFeatureGS(
        featureGS.map((fsg) => {
          if (fsg.serverGroupId === serverGroup?.serverGroupId) {
            fsg.checked = !fsg?.checked;
          }
          return fsg;
        })
      );
    }

    if (type === ServerGroupType.SERVER_GROUP_TYPE_TRANSFORM_SERVER_GROUP) {
      setTransformSG(
        transformSG.map((tsg) => {
          if (tsg.serverGroupId === serverGroup?.serverGroupId) {
            tsg.checked = !tsg?.checked;
          }
          return tsg;
        })
      );
    }
  };
  return (
    <>
      <FlexGroup direction="column" gutterSize="s">
        <GroupHeader groupName="Feature Server" showBadge={false} />
        <SelectableServerGroup
          isSelected={selectedServerGroup?.serverGroupId === undefined}
          onClick={onClick}
          serverGroup={{ name: 'Default Feature Server' }}
          serverGroupType={ServerGroupType.SERVER_GROUP_TYPE_UNSPECIFIED}
        />
        <GroupHeader groupName="Feature Server Group" />
        {featureGS.length > 0 ? (
          featureGS.map((featureServerGroup) => {
            return (
              <SelectableServerGroup
                isSelected={featureServerGroup.checked ?? false}
                onClick={onClick}
                serverGroup={featureServerGroup}
                serverGroupType={ServerGroupType.SERVER_GROUP_TYPE_FEATURE_SERVER_GROUP}
              />
            );
          })
        ) : (
          <EmptyServerGroup emptyText="No Feature Server Groups" />
        )}
        <GroupHeader groupName="Transformation Server Group" />
        {transformSG.length > 0 ? (
          transformSG.map((transformationServerGroup) => {
            return (
              <SelectableServerGroup
                isSelected={transformationServerGroup.checked ?? false}
                onClick={onClick}
                serverGroup={transformationServerGroup}
                serverGroupType={ServerGroupType.SERVER_GROUP_TYPE_TRANSFORM_SERVER_GROUP}
              />
            );
          })
        ) : (
          <EmptyServerGroup emptyText="No Transform Server Groups" />
        )}
      </FlexGroup>
    </>
  );
};

export default FeatureServerGroupLists;
