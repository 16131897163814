import React, { FC, useEffect, useRef, useState } from 'react';
import { FlexGroup, Icon, IconType, Tooltip } from '.';
import { Link as RouterLink } from 'react-router-dom';
import styled from '@emotion/styled';
import { IconSize } from './Icon';

type LinkSize = 's' | 'm';

interface LinkProps {
  name: React.ReactNode;
  icon?: IconType;
  url?: string;
  size?: LinkSize;
}

const LinkWrapper = styled.div<{ size: LinkSize }>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  color: ${({ theme }) => theme.v1.colors.text.link};
  font-size: ${({ theme, size }) => {
    return size === 'm' ? theme.font.fontSizes.s : theme.font.fontSizes.xs;
  }};
`;

const Link: FC<LinkProps> = ({ name, icon, url, size = 'm' }) => {
  const textRef = useRef<HTMLDivElement>(null);
  const [isTruncated, setIsTruncated] = useState(false);

  useEffect(() => {
    if (textRef.current) {
      const { scrollWidth, offsetWidth } = textRef.current;
      setIsTruncated(scrollWidth > offsetWidth);
    }
  }, [textRef]);

  const IconSizeMap: Record<LinkSize, IconSize> = {
    s: 'xs',
    m: 's',
  };

  if (url) {
    return (
      <Tooltip
        disabled={!isTruncated}
        trigger={
          <RouterLink to={url}>
            <FlexGroup gapSize="1" alignItems="center">
              {icon && (
                <div style={{ flexShrink: '0' }}>
                  <FlexGroup alignItems="center" padding="0">
                    <Icon icon={icon} type={'color'} size={IconSizeMap[size]} />
                  </FlexGroup>
                </div>
              )}
              <LinkWrapper size={size} ref={textRef}>
                {name}
              </LinkWrapper>
            </FlexGroup>
          </RouterLink>
        }
        content={name}
      />
    );
  }

  return (
    <Tooltip
      disabled={!isTruncated}
      trigger={
        <FlexGroup gapSize="1" alignItems="center">
          {icon && (
            <div style={{ flexShrink: '0' }}>
              <FlexGroup alignItems="center" padding="0">
                <Icon icon={icon} type={'color'} size={IconSizeMap[size]} />
              </FlexGroup>
            </div>
          )}
          <LinkWrapper size={size} ref={textRef}>
            {name}
          </LinkWrapper>
        </FlexGroup>
      }
      content={name}
    />
  );
};

export default Link;
