import React, { FC } from 'react';

import { RoleGranted } from '../../../api/gql/graphql';
import _sortBy from 'lodash/sortBy';

import PopoverAccountTypeDetails from './PopoverAccountTypeDetails';

interface PrincipalListAccountTypeProps {
  rolesGranted: RoleGranted[];
  accountType: string;
  principalId: string;
  onAccountTypeRowClicked?: VoidFunction;
}

const PrincipalListAccountType: FC<PrincipalListAccountTypeProps> = ({
  rolesGranted,
  accountType,
  onAccountTypeRowClicked,
}) => {
  const assignmentSources =
    rolesGranted?.flatMap((item) => {
      return (
        item?.roleAssignmentSources
          ?.filter((roleAssignmentSource) => {
            return !!roleAssignmentSource?.principalGroupId;
          })
          .map((assignmentSource) => {
            return {
              groupId: assignmentSource?.principalGroupId ?? '',
              groupName: assignmentSource?.principalGroupName ?? '',
            };
          }) ?? []
      );
    }) ?? [];

  return (
    <>
      <PopoverAccountTypeDetails
        groups={assignmentSources}
        accountType={accountType}
        onRelatedStatusClicked={onAccountTypeRowClicked}
      />
    </>
  );
};

export default PrincipalListAccountType;
