import { EmptyPrompt } from '@tecton/ComponentRedesign';
import { useParams } from 'react-router';
import { RIFT_JOB_GRAPHS } from '../../../feature/graph-configuration';
import MonitoringGraphs from '../monitoring/MonitoringGraphs';
import { getMonitoringDateRange } from '../../monitoring/DateRange-Dropdown-util';
import { useGetJobDetails } from '../../../api/jobs';
import { Duration } from '../../../types/google/protobuf/duration';

const QUERY_RANGE_BUFFER_MS = 60 * 1000; // 1 minute

// Returns the start and end date for the query based on the job attempt's created at timestamp and duration
const getQueryTimestamps = (jobCreatedAt: string, jobDuration: Duration): { startDate: Date; endDate: Date } => {
  const startTs = new Date(jobCreatedAt).getTime();
  // Duration is not actually an object here, it's a string
  // This date handling shenanigans shouldn't be necessary once this component is migrated to GraphQL, as this is already handled in mds-graph
  const durationMillis = Number.parseInt(jobDuration.toString().replace('s', '')) * 1000;
  const endTs = startTs + durationMillis;

  return {
    startDate: new Date(startTs - QUERY_RANGE_BUFFER_MS),
    endDate: new Date(endTs + QUERY_RANGE_BUFFER_MS),
  };
};

const JobMetrics = () => {
  const { jobId } = useParams();
  const { data: jobDetailData, isLoading } = useGetJobDetails(jobId ?? '');

  if (isLoading) {
    return <EmptyPrompt variant="loading" title={'Job Metrics Loading'} body={<></>} />;
  }

  const workspace = jobDetailData?.fco_locator?.workspace;

  const attemptStatus = jobDetailData?.attempt_details?.attempt_status ?? {};
  const { attempt_created_at: createdAt, duration: attemptDuration } = attemptStatus;

  if (!jobId || !createdAt || !attemptDuration || !workspace) {
    return <EmptyPrompt variant={'storm'} title={'Unable to retrieve job metadata'} body={<></>} />;
  }

  const { startDate, endDate } = getQueryTimestamps(createdAt, attemptDuration);

  const monitoringDateRange = getMonitoringDateRange({
    option: `custom`,
    startDate,
    endDate,
  });

  return (
    <MonitoringGraphs
      monitoringDateRange={monitoringDateRange}
      graphsData={RIFT_JOB_GRAPHS}
      customGraphRecord={{}}
      workspace={workspace}
      fcoId={jobId}
    />
  );
};

export default JobMetrics;
