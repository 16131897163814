import React from 'react';
import CenterContent from '../shared/components/Layout/CenterContent';

// This class uses the old React style because `componentDidCatch` is not supported by hooks.
export default class MonitoringErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }

  render() {
    if (this.state.errorInfo) {
      const header = 'Failed loading the graph.';

      return (
        <CenterContent minHeight={300} minWidth={250}>
          {header}
        </CenterContent>
      );
    }
    return this.props.children;
  }
}
