import React from 'react';
import styled from '@emotion/styled';
import { FeatureColumnSummaryType } from '../../../../featureViews/FeatureColumnSummaryPanel';

const Wrapper = styled.div`
  .euiTableCellContent {
    padding-left: 0;
  }
`;

const FeatureColumnsTable = ({ items }: { items: FeatureColumnSummaryType[] }) => {
  return (
    <Wrapper>
      <p>TODO</p>
    </Wrapper>
  );
};

export default FeatureColumnsTable;
