import React, { FC } from 'react';
import FlexGroup from './FlexGroup';
import styled from '@emotion/styled';
import { Badge, Icon, IconType } from '.';
import { useTectonTheme } from '../../Theme/ThemeProvider';

interface TabItem {
  label: string;
  icon?: IconType;
  badgeCount?: number;
  disabled?: boolean;
}

interface TabsProps {
  items: TabItem[];
  selectedTab: string;
  setSelectedTab: React.Dispatch<React.SetStateAction<string>>;
}

const TabWrapper = styled.div<{ active: boolean; disabled: boolean }>`
  border-bottom: ${({ theme, active }) =>
    `1px solid ${active ? theme.v1.colors.border.primary : theme.v1.colors.background.transparent}`};
  color: ${({ theme, disabled }) => (disabled ? theme.v1.colors.text.disabledText : theme.v1.colors.text.title)};
  font-weight: ${({ theme, active }) => (active ? theme.font.weight.medium : theme.font.weight.regular)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  padding: ${({ theme }) => `${theme.v1.size['3']}px ${theme.v1.size['1.5']}px`};
`;

const Tabs: FC<TabsProps> = ({ items, selectedTab, setSelectedTab }) => {
  const { theme } = useTectonTheme();

  return (
    <>
      <FlexGroup
        gapSize="4"
        alignItems="flex-end"
        css={{ borderBottom: `1px solid ${theme.v1.colors.border.default}` }}
        padding="0"
      >
        {items.map((tab) => {
          return (
            <TabWrapper
              active={selectedTab === tab.label}
              disabled={tab.disabled ?? false}
              onClick={() => {
                if (tab.disabled) return;
                setSelectedTab(tab.label);
              }}
            >
              <FlexGroup alignItems="center" gapSize="1.5">
                {tab.icon && <Icon size="s" icon={tab.icon} type="mono" />}
                {tab.label}
                {tab.badgeCount && <Badge label={tab.badgeCount} size="s" />}
              </FlexGroup>
            </TabWrapper>
          );
        })}
      </FlexGroup>
    </>
  );
};

export default Tabs;
