import React, { FC, useState } from 'react';
import _sortBy from 'lodash/sortBy';
import { Link, useNavigate } from 'react-router-dom';

import { Routes } from '../../../core/routes';
import RoleIconName from '../acl/RoleIconName';
// Tecton components
import {
  Avatar,
  Icon,
  FlexGroup,
  FlexItem,
  IconTip,
  EmptyPrompt,
  ErrorPromptButtons,
  SearchAndActionButton,
  EuiBasicTableColumn,
  Table,
  FlexGroupWrapper,
  ButtonEmpty,
} from '@tecton/ComponentRedesign';

// generated
import { PermissionGroup } from '../../../api/gql/graphql';

import GroupIcon from '@svg/group.svg';
import QuestionInCircle from '@svg/question-in-circle.svg';

export interface PermissionsGroupListProps {
  groups: PermissionGroup[];
  hasWorkspaceOwnerRole: boolean;
  isError?: boolean;
  isLoading?: boolean;
  onCreateGroupClick: VoidFunction;
  onEditGroupRole: (group: PermissionGroup) => void;
  isAdmin: boolean;
}

const PermissionsGroupList: FC<PermissionsGroupListProps> = ({
  groups,
  hasWorkspaceOwnerRole,
  isError,
  isLoading,
  onCreateGroupClick,
  onEditGroupRole,
  isAdmin,
}) => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState<string | undefined>(undefined);
  const filteredGroups = searchText
    ? groups?.filter((user: PermissionGroup) => {
        return user.name?.toLowerCase()?.includes(searchText?.toLowerCase());
      })
    : groups;

  const columns: EuiBasicTableColumn<PermissionGroup>[] = [
    {
      name: 'Group Name',
      render: (group: PermissionGroup) => {
        return (
          <>
            <ButtonEmpty
              size="s"
              iconType={GroupIcon}
              onClick={() => {
                navigate(`${Routes.accountsAndAccessGroups}/${group.id}`);
              }}
            >
              {group.name}
            </ButtonEmpty>
          </>
        );
      },
    },
    {
      name: (
        <>
          <FlexGroup justifyContent="center" alignItems="center" direction="row" gutterSize="none">
            <FlexItem>Role</FlexItem>
            <FlexItem>
              <IconTip
                content={`A group's role can be directly assigned or set via their default workspace role. If there are multiple roles, Tecton will use the highest role as the effective role, as shown in this column.`}
                type={QuestionInCircle}
              />
            </FlexItem>
          </FlexGroup>
        </>
      ),
      render: (group: PermissionGroup) => {
        return (
          <>
            <RoleIconName
              role={group?.workspaceRole ?? ''}
              onClick={() => {
                if (hasWorkspaceOwnerRole) {
                  onEditGroupRole(group);
                }
              }}
            />
          </>
        );
      },
    },
    {
      name: '# of Users',
      render: (item: PermissionGroup) => {
        return item.membersCount;
      },
    },
  ];

  if (isError) {
    const emptyPrompt = (
      <EmptyPrompt
        orientation="horizontal"
        variant="storm"
        title={<>Error Loading Groups</>}
        body={
          <>
            <p>We ran into an error while loading Groups.</p>
          </>
        }
        actions={<ErrorPromptButtons />}
      />
    );
    return <Table items={[]} columns={columns} emptyPrompt={emptyPrompt} />;
  }

  return (
    <FlexGroupWrapper gap="s" direction="column">
      <FlexItem>
        <SearchAndActionButton
          searchPlaceHolder={'Search for Group'}
          actionButtonLabel={'Add Group'}
          actionButtonVariant={hasWorkspaceOwnerRole ? 'primaryAction' : 'disabledAction'}
          onActionButtonClick={onCreateGroupClick}
          onSearchInputChange={(search: string) => {
            setSearchText(search ?? undefined);
          }}
        />
      </FlexItem>
      <FlexItem>
        {isLoading ? (
          <Table
            items={[]}
            columns={columns}
            emptyPrompt={<EmptyPrompt title={<>Loading Groups</>} body={<></>} variant="loading" />}
          />
        ) : (
          <Table items={filteredGroups ?? []} columns={columns} allowWordBreak={false} />
        )}
      </FlexItem>
    </FlexGroupWrapper>
  );
};

export default PermissionsGroupList;
