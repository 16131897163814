import { FC } from 'react';
import { MonitoringDateRange } from '../../monitoring/DateRange-Dropdown-util';

import { EuiSelectableOption } from '@tecton';
import PrometheusMonitoring from '@tecton/ComponentRedesign/Charts/PrometheusMonitoring';
import { format } from 'd3-format';
import { FCOFields, FeatureViewFCO, FeatureViewFCOFields, FeatureViewFCOType } from '../../../core/types/fcoTypes';
import { interval } from '../../../feature/feature-services/feature-service/component/ChartDefaultParams';

import { PipelineNodeTypes } from '../../../service/Pipeline';
import { X_AXIS_LABEL } from '../../monitoring/GraphContainer';
import { getRealTimeFeatureViewLabel } from './OnlineStoreLatency';
interface FeatureViewsBelowFreshnessThresholdProps {
  monitoringDateRange: MonitoringDateRange;
  selectedOption: EuiSelectableOption<{ option?: FeatureViewFCO }>;
  relatedFvs: FeatureViewFCO[];
}

const getTableQuery = (featureViewIds: string[], relatedFvs: FeatureViewFCO[]): string[] => {
  // exclude feature view type lists
  const fvTypesToExclude = [FeatureViewFCOType.REALTIME]; // Exclude Realtime FVs since we don't log on it. We only log on the underlying FVs
  const realtimeFVDependantFVIds: string[] = []; //

  const filteredFVIds = featureViewIds?.filter((fvId) => {
    const foundFV = relatedFvs.find((fv) => {
      return fv[FCOFields.ID] === fvId;
    });
    if (foundFV) {
      const canInclude = !fvTypesToExclude.includes(foundFV[FeatureViewFCOFields.FEATURE_VIEW_TYPE]);

      if (!canInclude) {
        // It's a realtime FV which mean we need to get the underlying FVs in the nodes.
        foundFV[FeatureViewFCOFields.PIPELINE]?.allLeafNodes
          ?.filter((node) => {
            return node?.nodeType === PipelineNodeTypes.FEATURE_VIEW;
          })
          ?.forEach((node) => {
            realtimeFVDependantFVIds.push(node?.innerNodeId ?? '');
          });
      }

      // If you are part of the exclusion list, filter it out.
      return canInclude;
    }
    // we can't find the fv so don't show it
    return false;
  });

  return [...filteredFVIds, ...realtimeFVDependantFVIds];
};

const DynamoDBQPSPerTable: FC<FeatureViewsBelowFreshnessThresholdProps> = ({
  monitoringDateRange,
  selectedOption,
  relatedFvs,
}) => {
  const chartTitle = '';
  const yAxisLabel = 'Queries Per Second';
  const featureViewIds = selectedOption?.data?.featureViewIds ?? [];
  const fvIDs = getTableQuery(featureViewIds, relatedFvs);
  const tableQuery = selectedOption?.data?.isAllFeatures
    ? `feature_view_id=~'${fvIDs?.join('|')}'`
    : `feature_view_id='${fvIDs?.join('')}'`;

  const queries = [
    {
      label: 'Value',
      query: `sum(rate(cm_fv_read_count{type=~'dynamo', tecton_cluster=~'.*',  aws_region=~'.*' ,${tableQuery}}[${interval}])) by (feature_view_id)`,
    },
  ];

  const yTicksCallback = (value: any) => {
    const formatter = format('.3~f');
    return `${formatter(value)} rd/s`;
  };

  const toolTipLabelCallback = (context: any) => {
    const { y } = context.parsed;
    const formatter = format('.3~f');

    const { label } = context.dataset;
    const labelText = `${label}: ${formatter(y)} rd/s`;
    return labelText;
  };

  const getMetricLabelCallback = (result: any) => {
    const foundFV = relatedFvs?.find((fv) => {
      if (fv[FeatureViewFCOFields.FEATURE_VIEW_TYPE] === FeatureViewFCOType.REALTIME) {
        const underlyingFeatureView = fv[FeatureViewFCOFields.PIPELINE]?.allLeafNodes?.find((node) => {
          return node?.nodeType === PipelineNodeTypes.FEATURE_VIEW;
        });
        return !!underlyingFeatureView;
      }
      return result.metric.feature_view_id === fv[FCOFields.ID];
    });

    if (foundFV && foundFV[FeatureViewFCOFields.FEATURE_VIEW_TYPE] === FeatureViewFCOType.REALTIME) {
      return getRealTimeFeatureViewLabel(foundFV);
    }

    return foundFV?.[FCOFields.NAME] ?? result.metric.feature_view_id;
  };

  const yOverride = {
    title: {
      display: true,
      text: yAxisLabel,
      font: {
        size: 12,
      },
    },
    min: 0,

    ticks: {
      callback: yTicksCallback,
    },
  };

  return (
    <>
      <PrometheusMonitoring
        chartTitle={chartTitle}
        monitoringDateRange={monitoringDateRange}
        queries={queries}
        xAxisLabel={X_AXIS_LABEL}
        yAxisLabel={yAxisLabel}
        yTicksCallback={yTicksCallback}
        yGrace={0}
        toolTipLabelCallback={toolTipLabelCallback}
        yOverride={yOverride}
        parseValue={parseFloat}
        getMetricLabelCallback={selectedOption?.data?.isAllFeatures ? getMetricLabelCallback : undefined}
        height={160}
      />
    </>
  );
};

export default DynamoDBQPSPerTable;
