import { ReactNode } from 'react';
import { utcFormat } from 'd3-time-format';
import { Moment } from 'moment';
import { ExpectationResultEnum } from '../../../types/tecton_proto/dataobs/validation';
import { MetricExpectation } from '../../../types/tecton_proto/dataobs/expectation';

export const defaultPaginationState = {
  page: 1,
  perPage: 25,
};

export interface ValidationRecord {
  id: string;
  status: ExpectationResultEnum;
  validationTime: Date;
  featureIntervalStartTime: Date;
  featureIntervalEndTime?: Date;
  featureViewName: string;
  expectationName: string;
  featureExpectationMetadata?: ValidationsFeatureExpectation;
  metricExpectationMetadata?: ValidationsMetricsExpectation;
  recordType: 'feature' | 'metric';
}

export interface ValidationsFeatureExpectation {
  name: string;
  inputColumnNames: string;
  createdTime: Date;
  lastUpdateTime: Date;
  alertMessage?: string;
  failurePercentage: number;
  failedJoinKeySamples: string[];
}

export interface ValidationInputMetrics {
  metric: string;
  value: number;
}

export interface ValidationsMetricsExpectation {
  name: string;
  inputMetrics: ValidationInputMetrics[];
  createdTime: Date;
  lastUpdateTime: Date;
  alertMessage?: string;
  expression: MetricExpectation;
}

export interface ValidationSummaryRecordDatum {
  [ExpectationResultEnum.RESULT_PASSED]: number;
  [ExpectationResultEnum.RESULT_FAILED]: number;
  [ExpectationResultEnum.RESULT_ERROR]: number;
  [ExpectationResultEnum.RESULT_UNKNOWN]: number;
  total: number;
}

export interface ValidationSummaryFeatureViewRecord {
  name: string;
  results: ValidationSummaryRecordDatum;
}

export interface ValidationSummaryRecords {
  workspace: string;
  workspaceResults: ValidationSummaryRecordDatum;
  featureViews?: ValidationSummaryFeatureViewRecord[];
  expectations: string[];
}

export interface ValidationsContextProps {
  allFeatureViews: { label: string; dataInterval: string }[];
  allExpectations: string[];
  earliestStartDate: Date;
  expectationOptions: { label: string; value: string }[];
  filterState: ValidationsFilterState;
  data: ValidationRecord[];
  isLoading: boolean;
  isError: boolean;
  isReady: boolean;
  isFetching: boolean;
  isFetchingNextPage: boolean;
  filtersAreActive: boolean;
  updateFilters: (newState: ValidationsFilterState) => void;
  resetFilters: () => void;
  loadMore: () => void;
  hasMore: boolean;
  workspace: string;
  wasEndDatePassedViaParameters: boolean;
  columns: Record<string, boolean>;
}

export interface ValidationsFilterState {
  featureViews: string[];
  expectations: string[];
  statuses: ExpectationResultEnum[];
  startDate: Date;
  endDate: Date;
  sortKey?: string;
  sortOrder?: string;
}

export interface ValidationsContextProviderProps {
  dateRange: [Moment, Moment];
  children: ReactNode;
}

export const ValidationsDefaultStatuses = [];

export const dateFormatter = utcFormat('%Y-%m-%d %H:%M');
export const timeFormatter = utcFormat('%H:%M');
