import { useQuery } from '@tanstack/react-query';

import {
  GetObservabilityConfigRequest,
  GetObservabilityConfigResponse,
} from 'src/types/tecton_proto/metadataservice/metadata_service';
import { Metadata_Service } from '.';

const fetchDataObsConfig = async (args: GetObservabilityConfigRequest): Promise<GetObservabilityConfigResponse> => {
  const response = await Metadata_Service('get-observability-config', { data: args, method: 'POST' });
  return response.data;
};

export const useCanShowDataQualityMetrics = (workspace: string, name: string) => {
  return useQuery(
    ['dataObsConfig', workspace, name],
    () => fetchDataObsConfig({ workspace: workspace, feature_view_name: name }),
    {
      select: (data) => data.is_dataobs_metric_enabled,
    }
  );
};
