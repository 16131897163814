import { uniq } from 'lodash';
import React, { FC, useState } from 'react';
import {
  AnyFCO,
  FCOType,
  FeatureViewFCO,
  FeatureViewFCOFields,
  FeatureViewFCOType,
} from '../../../core/types/fcoTypes';

// @tecton
import {
  ComboFilterChecklist,
  ComboFilterSelector,
  ComboSelectorChecklistOption,
  EmptyValue,
  EnabledDisabledBadge,
  RelatedFCOs,
  checkIfComboFilterChecklistHasActiveCategories,
  countActiveSelectionsForComboFilterChecklist,
} from '@tecton/ComponentRedesign';
import {
  FCOFilterStateType,
  FCOFilterType,
  FCOHistoryFilterState,
  FCOHistoryFilterTypes,
  FCOHistoryTableColumns,
  FCOListView,
  NameColumn,
  RelatedFCOCountFilterLogic,
  RelatedFCOCountFilterType,
  RelatedFCOFilterLogic,
  RelatedFCOFilterType,
  SharedFCOHistoryFilterLogic,
  SimpleChecklistFilterLogic,
  SimpleChecklistFilterType,
  TagFilterLogic,
  TagsColumn,
  TagsFilterType,
  structureTagsForFilter,
  valuesForFCOCount,
} from '../fcoListView';
import { getRelatedFCOsComboFilterOptions } from './featureViewUtils';

interface FeatureViewsListProps {
  items: FeatureViewFCO[];
  idToFcoMap: Record<string, AnyFCO>;
  workspace: string;
}

const FeatureViewsList: FC<FeatureViewsListProps> = ({ items, idToFcoMap, workspace }) => {
  type FeatureViewsFilterKeys =
    | FCOFilterType
    | 'Type'
    | 'Online'
    | 'Offline'
    | '# of Features'
    | '# of Data Sources'
    | 'Data Sources'
    | '# of Entities'
    | 'Entities'
    | '# of Transformations'
    | 'Transformations'
    | '# of Feature Services'
    | 'Feature Services';

  interface FeatureViewsFilterStateType extends FCOFilterStateType {
    Type: ComboSelectorChecklistOption[];
    Online: ComboSelectorChecklistOption[];
    Offline: ComboSelectorChecklistOption[];
    '# of Features': ComboSelectorChecklistOption[];
    '# of Data Sources': ComboSelectorChecklistOption[];
    'Data Sources': ComboSelectorChecklistOption[];
    '# of Entities': ComboSelectorChecklistOption[];
    Entities: ComboSelectorChecklistOption[];
    '# of Transformations': ComboSelectorChecklistOption[];
    Transformations: ComboSelectorChecklistOption[];
    '# of Feature Services': ComboSelectorChecklistOption[];
    'Feature Services': ComboSelectorChecklistOption[];
  }

  const getTransformationIdCallback = (item: FeatureViewFCO) => {
    return uniq(item[FeatureViewFCOFields.ALL_TRANSFORMATIONS]) ?? [];
  };
  const getDataSourcesIdCallback = (item: FeatureViewFCO) => {
    return uniq(item[FeatureViewFCOFields.DATA_SOURCE_IDS]) ?? [];
  };
  const getEntitiesIdCallback = (item: FeatureViewFCO) => {
    return uniq(item[FeatureViewFCOFields.ENTITY_IDS]) ?? [];
  };
  const getFeatureServicesIdCallback = (item: FeatureViewFCO) => {
    return uniq(item[FeatureViewFCOFields.FEATURE_SERVICES]) ?? [];
  };

  const transformations = getRelatedFCOsComboFilterOptions(items, getTransformationIdCallback, idToFcoMap);
  const dataSources = getRelatedFCOsComboFilterOptions(items, getDataSourcesIdCallback, idToFcoMap);
  const entities = getRelatedFCOsComboFilterOptions(items, getEntitiesIdCallback, idToFcoMap);
  const featureServices = getRelatedFCOsComboFilterOptions(items, getFeatureServicesIdCallback, idToFcoMap);

  const types = uniq(items.map((item) => item[FeatureViewFCOFields.FEATURE_VIEW_TYPE]));

  const defaultFilterState: FeatureViewsFilterStateType = {
    Tags: structureTagsForFilter(items),
    Type: types.map((type) => {
      return { label: type, value: type };
    }),
    Online: [
      { label: 'Enabled', value: true },
      { label: 'Disabled', value: false },
    ],
    Offline: [
      { label: 'Enabled', value: true },
      { label: 'Disabled', value: false },
    ],
    '# of Features': valuesForFCOCount,
    '# of Data Sources': valuesForFCOCount,
    'Data Sources': dataSources,
    '# of Entities': valuesForFCOCount,
    Entities: entities,
    '# of Transformations': valuesForFCOCount,
    Transformations: transformations,
    '# of Feature Services': valuesForFCOCount,
    'Feature Services': featureServices,
    ...FCOHistoryFilterState(items as AnyFCO[]),
  };

  const [filterState, setFilterState] = useState(defaultFilterState);

  const filterTypes: Record<FeatureViewsFilterKeys, ComboFilterSelector> = {
    Tags: TagsFilterType(filterState, setFilterState as React.Dispatch<React.SetStateAction<FCOFilterStateType>>),

    Type: SimpleChecklistFilterType(filterState, setFilterState, 'Type'),
    Online: SimpleChecklistFilterType(filterState, setFilterState, 'Online', (item) => {
      const enabled = (item as { value: boolean }).value;
      return <EnabledDisabledBadge enabled={enabled} />;
    }),
    Offline: SimpleChecklistFilterType(filterState, setFilterState, 'Offline', (item) => {
      const enabled = (item as { value: boolean }).value;
      return <EnabledDisabledBadge enabled={enabled} />;
    }),
    '# of Features': {
      hidden: undefined,
      label: '# of Features',
      numFilters: countActiveSelectionsForComboFilterChecklist(filterState['# of Features']),
      hasActiveFilters: checkIfComboFilterChecklistHasActiveCategories(filterState['# of Features']),
      numActiveFilters: countActiveSelectionsForComboFilterChecklist(filterState['# of Features']),
      filterElement: (
        <ComboFilterChecklist
          items={filterState['# of Features']}
          setItems={(items) => {
            setFilterState({
              ...filterState,
              ['# of Features']: items,
            });
          }}
        />
      ),
    },
    '# of Data Sources': RelatedFCOCountFilterType(filterState, setFilterState, '# of Data Sources'),
    'Data Sources': RelatedFCOFilterType(filterState, setFilterState, 'Data Sources', FCOType.DATA_SOURCE),
    '# of Entities': RelatedFCOCountFilterType(filterState, setFilterState, '# of Entities'),
    Entities: RelatedFCOFilterType(filterState, setFilterState, 'Entities', FCOType.ENTITY),
    '# of Transformations': RelatedFCOCountFilterType(filterState, setFilterState, '# of Transformations'),
    Transformations: RelatedFCOFilterType(filterState, setFilterState, 'Transformations', FCOType.TRANSFORMATION),
    '# of Feature Services': RelatedFCOCountFilterType(filterState, setFilterState, '# of Feature Services'),
    'Feature Services': RelatedFCOFilterType(filterState, setFilterState, 'Feature Services', FCOType.FEATURE_SERVICE),
    ...FCOHistoryFilterTypes(filterState, setFilterState as React.Dispatch<React.SetStateAction<FCOFilterStateType>>),
  };

  const columns = [
    ...NameColumn,
    ...TagsColumn,
    {
      name: 'Type',
      key: 'Type',
      field: FeatureViewFCOFields.FEATURE_VIEW_TYPE,
      sortable: true,
      render: (_: unknown, featureView: FeatureViewFCO) => {
        const fvTypeMap: Record<FeatureViewFCOType, string> = {
          [FeatureViewFCOType.STREAM]: 'Stream',
          [FeatureViewFCOType.BATCH]: 'Batch',
          [FeatureViewFCOType.STREAM_WINDOW_AGGREGATE]: 'Stream',
          [FeatureViewFCOType.BATCH_WINDOW_AGGREGATE]: 'Batch',
          [FeatureViewFCOType.REALTIME]: 'Realtime',
          [FeatureViewFCOType.FEATURE_TABLE]: 'Feature Table',
          [FeatureViewFCOType.UNKNOWN]: '',
          [FeatureViewFCOType.PUSH_WITH_BATCH]: 'Push',
          [FeatureViewFCOType.PUSH_NO_BATCH]: 'Push',
        };
        return fvTypeMap[featureView[FeatureViewFCOFields.FEATURE_VIEW_TYPE]];
      },
    },
    {
      name: 'Online',
      key: 'Online',
      field: FeatureViewFCOFields.IS_ONLINE_MATERIALIZATION_ENABLED,
      sortable: true,
      render: (_: unknown, featureView: FeatureViewFCO) => {
        if (featureView[FeatureViewFCOFields.IS_OFFLINE_MATERIALIZATION_ENABLED] === undefined) {
          return <EmptyValue />;
        }

        return (
          <EnabledDisabledBadge
            enabled={featureView[FeatureViewFCOFields.IS_ONLINE_MATERIALIZATION_ENABLED] ?? false}
          />
        );
      },
    },
    {
      name: 'Offline',
      key: 'Offline',
      field: FeatureViewFCOFields.IS_OFFLINE_MATERIALIZATION_ENABLED,
      sortable: true,
      render: (_: unknown, featureView: FeatureViewFCO) => {
        if (featureView[FeatureViewFCOFields.IS_OFFLINE_MATERIALIZATION_ENABLED] === undefined) {
          return <EmptyValue />;
        }
        return (
          <EnabledDisabledBadge
            enabled={!!featureView[FeatureViewFCOFields.IS_OFFLINE_MATERIALIZATION_ENABLED] ?? false}
          />
        );
      },
    },
    {
      name: '# of Features',
      key: '# of Features',
      field: FeatureViewFCOFields.FEATURES,
      sortable: true,
      render: (_: unknown, featureView: FeatureViewFCO) => {
        return featureView[FeatureViewFCOFields.FEATURES].length;
      },
    },
    {
      name: 'Data Sources',
      key: 'Data Sources',
      field: FeatureViewFCOFields.DATA_SOURCE_IDS,
      sortable: true,
      render: (_: unknown, featureView: FeatureViewFCO) => {
        const items = featureView[FeatureViewFCOFields.DATA_SOURCE_IDS]
          .map((featureViewId) => idToFcoMap[featureViewId])
          .map((fco) => {
            return { name: fco.name ?? '', description: fco.description };
          });
        return <RelatedFCOs fcos={items} type={FCOType.DATA_SOURCE} workspace={workspace} />;
      },
    },
    {
      name: 'Entities',
      key: 'Entities',
      field: FeatureViewFCOFields.ENTITY_IDS,
      sortable: true,
      render: (_: unknown, featureView: FeatureViewFCO) => {
        const items = featureView[FeatureViewFCOFields.ENTITY_IDS]
          .map((featureViewId) => idToFcoMap[featureViewId])
          .map((fco) => {
            return { name: fco.name ?? '', description: fco.description };
          });
        return <RelatedFCOs fcos={items} type={FCOType.ENTITY} workspace={workspace} />;
      },
    },
    {
      name: 'Transformations',
      key: 'Transformations',
      field: FeatureViewFCOFields.ALL_TRANSFORMATIONS,
      sortable: true,
      render: (_: unknown, featureView: FeatureViewFCO) => {
        const items = featureView[FeatureViewFCOFields.ALL_TRANSFORMATIONS]
          .map((featureViewId) => idToFcoMap[featureViewId])
          .map((fco) => {
            return { name: fco.name ?? '', description: fco.description };
          });
        return <RelatedFCOs fcos={items} type={FCOType.TRANSFORMATION} workspace={workspace} />;
      },
    },
    {
      name: 'Feature Services',
      key: 'Feature Services',
      field: FeatureViewFCOFields.FEATURE_SERVICES,
      sortable: true,
      render: (_: unknown, featureView: FeatureViewFCO) => {
        const items = featureView[FeatureViewFCOFields.FEATURE_SERVICES]
          .map((featureViewId) => idToFcoMap[featureViewId])
          .map((fco) => {
            return { name: fco.name ?? '', description: fco.description };
          });
        return <RelatedFCOs fcos={items} type={FCOType.FEATURE_SERVICE} workspace={workspace} />;
      },
    },
    ...FCOHistoryTableColumns,
  ];

  const filterFunctionsByFilterKey: Record<FeatureViewsFilterKeys, (fco: FeatureViewFCO) => boolean> = {
    Tags: TagFilterLogic(filterState['Tags']),
    ...SharedFCOHistoryFilterLogic(filterState),
    Type: SimpleChecklistFilterLogic(filterState, 'Type', FeatureViewFCOFields.FEATURE_VIEW_TYPE),
    Online: SimpleChecklistFilterLogic(filterState, 'Online', FeatureViewFCOFields.IS_ONLINE_MATERIALIZATION_ENABLED),
    Offline: SimpleChecklistFilterLogic(
      filterState,
      'Offline',
      FeatureViewFCOFields.IS_OFFLINE_MATERIALIZATION_ENABLED
    ),
    '# of Features': (fco: FeatureViewFCO) => {
      const featureCount = fco[FeatureViewFCOFields.FEATURES].length;

      const filterValues = (filterState['# of Features'] as { checked: string; value: unknown }[])
        .filter((option: { checked: string }) => option.checked === 'on')
        .map((option: { value: unknown }) => option.value)
        .flat();

      if (filterValues.includes(featureCount)) {
        return true;
      }

      if (filterValues.includes(11) && featureCount > 10) {
        return true;
      }

      return false;
    },
    '# of Data Sources': RelatedFCOCountFilterLogic(
      filterState,
      '# of Data Sources',
      FeatureViewFCOFields.DATA_SOURCE_IDS
    ),
    'Data Sources': RelatedFCOFilterLogic(filterState, 'Data Sources', FeatureViewFCOFields.DATA_SOURCE_IDS),
    '# of Entities': RelatedFCOCountFilterLogic(filterState, '# of Entities', FeatureViewFCOFields.DATA_SOURCE_IDS),
    Entities: RelatedFCOFilterLogic(filterState, 'Entities', FeatureViewFCOFields.ENTITY_IDS),
    '# of Transformations': RelatedFCOCountFilterLogic(
      filterState,
      '# of Transformations',
      FeatureViewFCOFields.ALL_TRANSFORMATIONS
    ),
    Transformations: RelatedFCOFilterLogic(filterState, 'Transformations', FeatureViewFCOFields.ALL_TRANSFORMATIONS),
    '# of Feature Services': RelatedFCOCountFilterLogic(
      filterState,
      '# of Feature Services',
      FeatureViewFCOFields.FEATURE_SERVICES
    ),
    'Feature Services': RelatedFCOFilterLogic(filterState, 'Feature Services', FeatureViewFCOFields.FEATURE_SERVICES),
  };

  return (
    <FCOListView
      unfilteredFCOItems={items}
      filters={filterTypes}
      columns={columns}
      filterState={filterState}
      setFilterState={setFilterState}
      defaultFilterState={defaultFilterState}
      filterFunctionsByFilterKey={filterFunctionsByFilterKey}
      searchPlaceholder={'Search Features'}
    />
  );
};

export default FeatureViewsList;
