import React from 'react';
import { ColoredFCOTypes } from '../VisualPrimitives/CardTypography';
import DataFlowHandle from '../DataFlowHandle';
import { Position } from '@xyflow/react';
import { DataFlowFCOIcon } from '../../Dataflow/DataFlowFCONode';
import styled from '@emotion/styled';
import { Link, useParams } from 'react-router-dom';
import { Routes as AppRoutes } from '../../../../../core/routes';

const Wrapper = styled.div<{ NODE_HEIGHT: number }>(({ theme, NODE_HEIGHT }) => ({
  boxShadow: theme.shadow.m,
  'background-color': theme.colors.emptyShade,
  width: '200px',
  height: `${NODE_HEIGHT}px`,
  'border-radius': theme.border.radius.medium,
  'vertical-align': 'center',
  overflow: 'visible',
  display: 'flex',
  padding: '12px 9px',
}));

const TextWrapper = styled.div`
  display: flex;
  padding: 0px 6px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const NumberDisplay = styled.div`
  color: ${({ theme }) => theme.colors.title};
  font-size: ${({ theme }) => theme.font.fontSizes.xs};
  font-style: normal;
  font-weight: ${({ theme }) => theme.font.weight.medium};
  line-height: 15px;
`;

const TypeDisplay = styled.div`
  color: ${({ theme }) => theme.colors.subduedText};
  font-size: 9px;
  font-style: normal;
  font-weight: ${({ theme }) => theme.font.weight.regular};
  line-height: 15px; /* 166.667% */
  text-transform: uppercase;
`;
const BaseSummaryCard = ({
  data,
}: {
  data: {
    type: string;
    count: number;
  };
}) => {
  const { workspace } = useParams();

  const NODE_HEIGHT = 48;

  const typeIconMap: Record<string, ColoredFCOTypes> = {
    SUMMARY_BATCH_DATA_SOURCES: 'data_source',
    SUMMARY_STREAM_DATA_SOURCES: 'data_source',
    SUMMARY_BATCH_FEATURE_VIEWS: 'feature_view',
    SUMMARY_STREAM_FEATURE_VIEWS: 'feature_view',
    SUMMARY_FEATURE_TABLES: 'feature_view',
    SUMMARY_ODFVS: 'odfv',
    SUMMARY_ONLINE_FEATURE_SERVICES: 'feature_service',
    SUMMARY_OFFLINE_FEATURE_SERVICES: 'feature_service',
  };

  const typeLabelMap: Record<string, string> = {
    SUMMARY_BATCH_DATA_SOURCES: 'Batch Data Source',
    SUMMARY_STREAM_DATA_SOURCES: 'Stream Data Source',
    SUMMARY_BATCH_FEATURE_VIEWS: 'Batch Feature View',
    SUMMARY_STREAM_FEATURE_VIEWS: 'Streaming Feature View',
    SUMMARY_FEATURE_TABLES: 'Feature Table',
    SUMMARY_ODFVS: 'Realtime Feature View',
    SUMMARY_ONLINE_FEATURE_SERVICES: 'Online Feature Service',
    SUMMARY_OFFLINE_FEATURE_SERVICES: 'Offline Feature Service',
  };

  const typeLabelMapPlural: Record<string, string> = {
    SUMMARY_BATCH_DATA_SOURCES: 'Batch Data Sources',
    SUMMARY_STREAM_DATA_SOURCES: 'Stream Data Sources',
    SUMMARY_BATCH_FEATURE_VIEWS: 'Batch Feature Views',
    SUMMARY_STREAM_FEATURE_VIEWS: 'Streaming Feature Views',
    SUMMARY_FEATURE_TABLES: 'Feature Tables',
    SUMMARY_ODFVS: 'Realtime Feature Views',
    SUMMARY_ONLINE_FEATURE_SERVICES: 'Online Feature Services',
    SUMMARY_OFFLINE_FEATURE_SERVICES: 'Offline Feature Services',
  };

  const typeRouteMap: Record<string, string> = {
    SUMMARY_BATCH_DATA_SOURCES: AppRoutes.dataSources,
    SUMMARY_STREAM_DATA_SOURCES: AppRoutes.dataSources,
    SUMMARY_BATCH_FEATURE_VIEWS: AppRoutes.featureViews,
    SUMMARY_STREAM_FEATURE_VIEWS: AppRoutes.featureViews,
    SUMMARY_FEATURE_TABLES: AppRoutes.featureViews,
    SUMMARY_ODFVS: AppRoutes.featureViews,
    SUMMARY_ONLINE_FEATURE_SERVICES: AppRoutes.featureServices,
    SUMMARY_OFFLINE_FEATURE_SERVICES: AppRoutes.featureServices,
  };

  const fcoRoute = typeRouteMap[data.type].replace(':workspace', workspace ?? '');

  return (
    <>
      <Link to={fcoRoute}>
        <Wrapper NODE_HEIGHT={NODE_HEIGHT}>
          <div>
            <DataFlowFCOIcon fcoType={typeIconMap[data.type]} denseMode />
          </div>
          <TextWrapper>
            {data.count === 1 && <TypeDisplay>{typeLabelMap[data.type]}</TypeDisplay>}
            {data.count > 1 && <TypeDisplay>{typeLabelMapPlural[data.type]}</TypeDisplay>}
            <NumberDisplay>{data.count}</NumberDisplay>
          </TextWrapper>
        </Wrapper>{' '}
        <DataFlowHandle top={`${NODE_HEIGHT / 2}px`} position={Position.Left} />
        <DataFlowHandle top={`${NODE_HEIGHT / 2}px`} position={Position.Right} />
      </Link>
    </>
  );
};

export default BaseSummaryCard;
