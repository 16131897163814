import { FC, useContext, useState } from 'react';
import { useAssignRolesMutation } from '../../../api/acl';
import { useGetPermissions } from '../../../api/queries/aclGraphql';
import { getLegacyRoleIdGQL } from '../../acl/aclUtils';
import { useUserSettings } from '../../context/UserSettingsContext';
import ModifyWorkspaceAccess from '../acl/ModifyWorkspaceAccess';
import { getFilterRolesForServiceAccount, getRoleOptions, getRoleToPermissionMap } from '../acl/acl-util';
import PermissionsUserList from './PermissionsUserList';

import { RoleAssignmentSource, User } from '../../../api/gql/graphql';
import { PrincipalType } from '../../../types/tecton_proto/auth/principal';
import { ResourceType } from '../../../types/tecton_proto/auth/resource';

import { Modal, getToastComponent } from '@tecton/ComponentRedesign';
import { ToastContext, addToast } from '@tecton/ToastContext';
import { logEvent } from '../../../utils/analytics-utils';
import AddUserToWorkspace from '../acl/AddUserToWorkspace';

const PermissionsUserListContainer: FC = () => {
  const { hasWorkspaceManagerAccess, workspace } = useUserSettings();
  const toastContext = useContext(ToastContext);

  // CRUD (Mutation) hooks
  const { data, isLoading, isError } = useGetPermissions(workspace ?? '');
  const assignRoleMutation = useAssignRolesMutation();

  const [selectedUser, setSelectedUser] = useState<User | undefined>();

  // General states
  const [originalRole, setOriginalRole] = useState<string | undefined>();
  const [selectedRole, setSelectedRole] = useState<string | undefined>();
  const [roleAssignmentSource, setRoleAssignmentSource] = useState<RoleAssignmentSource[] | undefined>();

  // Modal state
  const [showEditWorkspaceRoleModal, setShowEditWorkspaceRoleModal] = useState<boolean>(false);
  const [showAddUserModal, setShowAddUserModal] = useState<boolean>(false);

  const permissionRoleFilter = ['Admin', 'Consumer'];
  const filteredRoles = getFilterRolesForServiceAccount(data?.roles ?? [], PrincipalType.PRINCIPAL_TYPE_USER);
  const roleIds =
    filteredRoles?.filter((role) => !permissionRoleFilter.includes(role.name))?.map((role) => role.id) ?? [];

  const editableRoles = getRoleOptions(filteredRoles, [...roleIds, 'none'], true);
  const AddUserRoles = getRoleOptions(filteredRoles, roleIds, false);

  const roleDescriptionMap = getRoleToPermissionMap(filteredRoles);

  const reset = () => {
    setSelectedUser(undefined);
    setOriginalRole(undefined);
    setSelectedRole(undefined);
    setRoleAssignmentSource(undefined);
    setShowEditWorkspaceRoleModal(false);
    setShowAddUserModal(false);
  };

  // --------------------------------------- Start: Actions ---------------------------------

  const onEditUserRole = (user: User) => {
    const roleSource =
      user.rolesGranted?.flatMap((rolesGranted) => {
        return rolesGranted?.roleAssignmentSources?.map((roleAssignmentSource) => roleAssignmentSource) ?? [];
      }) ?? [];
    setSelectedUser(user);
    setOriginalRole(selectedUser?.workspaceRole ?? '');
    setSelectedRole(selectedUser?.workspaceRole ?? '');
    setRoleAssignmentSource(roleSource?.filter((group) => !!group?.principalGroupId));
    setShowEditWorkspaceRoleModal(true);
  };

  const onRoleChange = (roleName: string) => {
    setSelectedRole(roleName);
  };

  const onModifyWorkspaceRole = () => {
    const removingRole = selectedRole === 'None';
    const principal_type = PrincipalType.PRINCIPAL_TYPE_USER;

    const role = getLegacyRoleIdGQL(data?.roles ?? [], selectedRole ?? ''); // fix this.
    const roles = removingRole ? [] : [role ?? ''];
    const resource_id = workspace;

    const resource_type = ResourceType.RESOURCE_TYPE_WORKSPACE;

    const payload = {
      principal_id: selectedUser?.oktaId,
      roles,
      resource_id,
      principal_type,
      resource_type,
    };

    assignRoleMutation.mutate(payload, {
      onSuccess: () => {
        reset();
        logEvent(`Permissions: ${removingRole ? `User Removed From Workspace` : `User Role Updated`}`, '', {
          workspace: workspace,
          email: selectedUser?.loginEmail,
        });
        const toastContent = getToastComponent({
          variant: 'success',
          title: removingRole ? `User Removed From Workspace` : `User Role Updated`,
          children: removingRole ? (
            <>
              User {selectedUser?.loginEmail} removed from {workspace}.
            </>
          ) : (
            <>
              Role updated for {selectedUser?.loginEmail} to {selectedRole}.
            </>
          ),
          showIcon: true,
        });

        toastContext?.dispatchToast(
          addToast({
            text: toastContent,
          })
        );
      },
      onError: () => {
        const toastContent = getToastComponent({
          variant: 'danger',
          title: removingRole ? `Failed To Remove User` : `Failed To Update Role`,
          children: removingRole ? (
            <>
              Failed To Remove User {selectedUser?.loginEmail} from workspace {workspace}.
            </>
          ) : (
            <>Failed to update user role. Please try again.</>
          ),
          showIcon: true,
        });

        toastContext?.dispatchToast(
          addToast({
            text: toastContent,
          })
        );
      },
    });
  };

  const onAddUserToWorkspaceConfirm = () => {
    const principal_type = PrincipalType.PRINCIPAL_TYPE_USER;
    const role = getLegacyRoleIdGQL(data?.roles ?? [], selectedRole ?? '');
    const roles = [role ?? ''];
    const resource_id = workspace;
    const resource_type = ResourceType.RESOURCE_TYPE_WORKSPACE;

    const payload = {
      principal_id: selectedUser?.oktaId ?? '',
      roles,
      resource_id,
      principal_type,
      resource_type,
    };

    assignRoleMutation.mutate(payload, {
      onSuccess: () => {
        reset();
        logEvent(`Permissions: User Added To Workspace`, '', {
          workspace: workspace,
          email: selectedUser?.loginEmail,
        });
        const toastContent = getToastComponent({
          variant: 'success',
          title: `User Added To Workspace`,
          children: (
            <>
              User {selectedUser?.loginEmail} was added to workspace {workspace} as {selectedRole}.
            </>
          ),
          showIcon: true,
        });

        toastContext?.dispatchToast(
          addToast({
            text: toastContent,
          })
        );
      },
      onError: () => {
        const toastContent = getToastComponent({
          variant: 'danger',
          title: `Failed To Add User`,
          children: <>Failed to Add user to workspace {workspace}</>,
          showIcon: true,
        });

        toastContext?.dispatchToast(
          addToast({
            text: toastContent,
          })
        );
      },
    });
  };

  // ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ End: Actions  ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^

  return (
    <>
      {showEditWorkspaceRoleModal && (
        <Modal
          title={`Modify role of ${selectedUser?.loginEmail} in ${workspace} `}
          body={
            <>
              <ModifyWorkspaceAccess
                roles={editableRoles}
                workspaceRole={selectedUser?.workspaceRole ?? ''}
                roleDescriptionMap={roleDescriptionMap}
                onRoleChange={onRoleChange}
                hasRoleFromOtherSources={selectedUser?.hasOtherSourcesOfAccountType}
                roleAssignmentSources={roleAssignmentSource}
                id={selectedUser?.loginEmail ?? ''}
              />
            </>
          }
          onCancel={() => {
            reset();
          }}
          confirmLabel="Change Access"
          onConfirm={() => {
            onModifyWorkspaceRole();
          }}
          variant="positive"
          confirmVariant={originalRole !== selectedRole ? 'primaryAction' : 'disabledAction'}
          isConfirmButtonLoading={assignRoleMutation.isLoading}
        />
      )}

      {showAddUserModal && (
        <Modal
          title={data?.assignableUsers?.length === 0 ? 'All Users have access.' : `Add User to ${workspace}`}
          body={
            <>
              <AddUserToWorkspace
                roles={AddUserRoles}
                roleDescriptionMap={roleDescriptionMap}
                onChange={(selectedRole?: string, user?: User) => {
                  setSelectedRole(selectedRole);
                  setSelectedUser(user);
                }}
                assignableUsers={data?.assignableUsers ?? []}
              />
            </>
          }
          onCancel={() => {
            reset();
          }}
          confirmLabel="Change Access"
          onConfirm={() => {
            onAddUserToWorkspaceConfirm();
          }}
          variant={data?.assignableUsers?.length === 0 ? 'negative' : 'positive'}
          confirmVariant={selectedUser && selectedRole ? 'primaryAction' : 'disabledAction'}
          isConfirmButtonLoading={assignRoleMutation.isLoading}
        />
      )}

      <PermissionsUserList
        users={data?.users ?? []}
        hasWorkspaceOwnerRole={hasWorkspaceManagerAccess ?? false}
        isLoading={isLoading}
        isError={isError}
        onAddUserClick={() => {
          setShowAddUserModal(true);
        }}
        onEditUserRole={onEditUserRole}
      />
    </>
  );
};

export default PermissionsUserListContainer;
