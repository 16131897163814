import { AvatarAndName, FCOIconAndName, RelatedTags } from '@tecton/ComponentRedesign';
import { TectonDateTimeFormat } from '@tecton/ComponentRedesign/utils';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { AnyFCO, FCOFields } from '../../../core/types/fcoTypes';

export const SharedFCOListViewTableColumns = () => [
  {
    name: <>Last Updated By</>,
    render: (item: AnyFCO) => {
      return <AvatarAndName maxWidth="150px" name={item[FCOFields.LAST_MODIFIED_BY]} />;
    },
  },
  {
    name: 'Last Updated',
    render: (item: AnyFCO) => {
      return <>{TectonDateTimeFormat(moment(item[FCOFields.LAST_MODIFIED_DATE]))}</>;
    },
  },
  {
    name: <>Owner</>,
    render: (item: AnyFCO) => {
      return <AvatarAndName maxWidth="150px" name={item[FCOFields.OWNER]} />;
    },
  },
  {
    name: 'Last Updated',
    render: (item: AnyFCO) => {
      return <>{TectonDateTimeFormat(moment(item[FCOFields.CREATED_DATE]))}</>;
    },
  },
];

export const NameColumn = [
  {
    name: 'Name',
    field: FCOFields.NAME,
    sortable: true,
    render: (_: string, item: AnyFCO) => {
      // We need to escape the workspace name since we can have unfriendly names for FCOS for example "samantha:parallel_dataset:V1:3"
      const urlEncodedFcoName = encodeURIComponent(item?.name ?? '');

      // We can't rely on index to be redirected since our integration test require a specific url.
      // The integration test clicks on the link and waits for the URL to change
      const indexRouteName =
        item[FCOFields.FCO_TYPE] === 'featureView' || item[FCOFields.FCO_TYPE] === 'featureService'
          ? 'dataflow'
          : 'overview';

      return (
        <>
          <Link to={`${urlEncodedFcoName}/${indexRouteName}`}>
            <FCOIconAndName
              maxWidthName="30vw"
              name={item.name ?? ''}
              type={item[FCOFields.FCO_TYPE]}
              description={item.description}
            />
          </Link>
        </>
      );
    },
  },
];

export const TagsColumn = [
  {
    name: 'Tags',
    key: 'Tags',
    field: FCOFields.TAGS,
    sortable: true,
    render: (_: unknown, fco: AnyFCO) => {
      const tags = fco[FCOFields.TAGS];
      return <RelatedTags tags={tags} />;
    },
  },
];
