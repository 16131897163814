import { FeatureViewFCO } from '../../../../core/types/fcoTypes';
import {
  DataFlowEdge,
  DataFlowNode,
  NodeSizeFunctionSignature,
  NodeSizeLookupRecord,
  NodesMapType,
} from './dataFlowTypes';

export const CARD_WIDTH = 300;
export const COLUMN_GAP = 100;

const NODE_HEIGHT = 70;
const DENSE_NODE_HEIGHT = 28;
const DATA_SOURCE_INITIAL_HEIGHT = 220;
const FEATURE_SERVICE_INITIAL_HEIGHT = 260;
const DEFAULT_NODE_WIDTH = 300;
export const ANCHOR_NODE_WIDTH = 400;
const WIDTH_ADJUSTMENT_FOR_SUB_FV_NODE_SPACING = 100;
const ICON_SIZE = 20;

const AUXILARY_NODE_SIZE = 36;

export const STORE_PANEL_HEIGHT = 220;
export const STORE_PANEL_WIDTH = 140;

export const anchorTypes: DataFlowNode['type'][] = ['data_source', 'feature_view', 'odfv', 'feature_service'];

const anchorSizes: Record<string, [number, number]> = {
  feature_view: [NODE_HEIGHT, 1],
  odfv: [NODE_HEIGHT, 1],
  data_source: [DATA_SOURCE_INITIAL_HEIGHT, ANCHOR_NODE_WIDTH],
  feature_service: [FEATURE_SERVICE_INITIAL_HEIGHT, ANCHOR_NODE_WIDTH],
};

const nodeSizes: Record<DataFlowNode['type'], [number, number]> = {
  raw_batch_node: [AUXILARY_NODE_SIZE, AUXILARY_NODE_SIZE],
  raw_stream_node: [AUXILARY_NODE_SIZE, AUXILARY_NODE_SIZE],
  data_source: [NODE_HEIGHT, DEFAULT_NODE_WIDTH],
  store_wrapper: [STORE_PANEL_HEIGHT, STORE_PANEL_WIDTH],
  feature_view: [NODE_HEIGHT, DEFAULT_NODE_WIDTH],
  transformation: [NODE_HEIGHT, DEFAULT_NODE_WIDTH],
  embeddingModel: [NODE_HEIGHT, DEFAULT_NODE_WIDTH],
  aggregation: [NODE_HEIGHT, DEFAULT_NODE_WIDTH],
  store_input: [ICON_SIZE, ICON_SIZE],
  store_output: [ICON_SIZE, ICON_SIZE],
  online_store: [ICON_SIZE, ICON_SIZE],
  offline_store: [ICON_SIZE, ICON_SIZE],
  odfv: [NODE_HEIGHT, DEFAULT_NODE_WIDTH],
  request_data_source: [NODE_HEIGHT, DEFAULT_NODE_WIDTH],
  feature_service: [NODE_HEIGHT, DEFAULT_NODE_WIDTH],
  model_inference: [AUXILARY_NODE_SIZE, AUXILARY_NODE_SIZE],
  model_trainer: [AUXILARY_NODE_SIZE, AUXILARY_NODE_SIZE],
};

const denseNodeSizes: Record<DataFlowNode['type'], [number, number]> = {
  ...nodeSizes,
  data_source: [DENSE_NODE_HEIGHT, DEFAULT_NODE_WIDTH],
  feature_view: [DENSE_NODE_HEIGHT, DEFAULT_NODE_WIDTH],
};

export const getDefaultNodeSize = (node: DataFlowNode, configs?: { denseMode?: boolean }) => {
  if (node.isAnchor) {
    if (!anchorTypes.includes(node.type)) {
      throw new Error(`Node type '${node.type}' is not a valid anchor node.`);
    }

    return anchorSizes[node.type];
  } else {
    if (configs?.denseMode) {
      return denseNodeSizes[node.type];
    } else {
      return nodeSizes[node.type];
    }
  }
};

export const createGetNodeSizeFunction = (
  initializedNodeSizes: NodeSizeLookupRecord | undefined,
  nodesMap: NodesMapType,
  configs?: {
    denseMode?: boolean;
  }
): NodeSizeFunctionSignature => {
  return !initializedNodeSizes
    ? (node: DataFlowNode) => {
        return getDefaultNodeSize(node, configs);
      }
    : (node: DataFlowNode) => {
        const entry = initializedNodeSizes[node.id];

        let widthAdjustment = 0;

        if (nodesMap[node.id].type === 'transformation' || nodesMap[node.id].type === 'aggregation') {
          widthAdjustment = WIDTH_ADJUSTMENT_FOR_SUB_FV_NODE_SPACING;
        }

        if (entry) {
          return [entry.height, entry.width + widthAdjustment];
        } else {
          return getDefaultNodeSize(node, configs);
        }
      };
};

export const createNodesMap = (nodesList: DataFlowNode[]): NodesMapType => {
  const nodesMap: NodesMapType = {};
  nodesList.forEach((n) => {
    nodesMap[n.id] = n;
  });

  return nodesMap;
};

export const getMaterializationConfig = (fv: FeatureViewFCO, type: 'online' | 'offline'): boolean => {
  return type === 'online'
    ? !!fv.materializationParams.writes_to_online_store
    : !!fv.materializationParams.writes_to_offline_store;
};

export const computeNodeHasStreamAncestor = (nodesMap: NodesMapType, edgesList: DataFlowEdge[]) => {
  /**
   * This function computes whether a node has a stream data source among its ancestors.
   * This gets used when deciding whether to render an edge when we are in the
   * stream data flow mode.
   */

  const result: Record<string, boolean> = {};

  /**
   * Prep work: Build a list of targets per source ID.
   * We do not want to unnecessarily recurse, nor loop through things too many times.
   * So this map allows us to visit a list of targets given a particular source ID.
   */
  const edgesMapBySource: Record<string, string[]> = {};
  edgesList.map((e) => {
    if (edgesMapBySource[e.source]) {
      edgesMapBySource[e.source].push(e.target);
    } else {
      edgesMapBySource[e.source] = [e.target];
    }
  });

  /**
   * Prep work: Set up the recursive function
   * The ID we are given has a stream data source among its ancestors (or, in the
   * initial case, is itself a stream data source). By definition, all of its targets
   * will also have a stream data source ancestor.
   */
  const recurse = (sourceId: string) => {
    // Mark this ID as having a stream data source ancestor in the result.
    result[sourceId] = true;

    if (!edgesMapBySource[sourceId]) {
      return;
    }

    edgesMapBySource[sourceId].map((target) => {
      if (result[target] === undefined) {
        // If we haven't already marked this ID as having a stream ancestor
        // we recurse from this ID.
        recurse(target);
      }
    });
  };

  /**
   * Get all of the stream data sources.
   */
  const sourceNodes = Object.entries(nodesMap)
    .filter(([_key, node]) => {
      if (node.type === 'data_source' && !node.dataSourceType) {
        throw new Error(`Data Source Type is not specified for node '${node.id}`);
      }

      // return node.type === 'data_source' && node.dataSourceType === 'stream'; // old
      return node.type === 'raw_stream_node';
    })
    .map(([_key, node]) => node);

  /**
   * Recurse starting from each stream data source.
   */
  sourceNodes.forEach((sn) => {
    result[sn.id] = true;

    recurse(sn.id);
  });

  return result;
};

export type LinkableFCOTypes = 'data_source' | 'transformation' | 'feature_view' | 'odfv' | 'feature_service';

const linkableFcoTypes = new Set(['data_source', 'transformation', 'feature_view', 'odfv', 'feature_service']);

export const isLinkableFCOType = (type: DataFlowNode['type']) => {
  return linkableFcoTypes.has(type);
};

export const interactedWithDiagram = (function () {
  let hasInteracted = false;

  // Passing in LogEvent here because importing it directly into this file breaks storybook
  return (logEvent: (arg0: string) => void) => {
    if (hasInteracted) {
      return;
    } else {
      logEvent(`Dataflow Diagram Panned or Zoomed`);
      hasInteracted = true;
    }
  };
})();

export const detailCardTypeAndNames = (node: DataFlowNode) => {
  let name: string = node.name;
  let type: string = node.type;

  if (node.type === 'data_source') {
    if (node.dataSourceType === 'Stream') {
      type = 'Stream Data Source';
    } else if (node.dataSourceType === 'Batch') {
      type = 'Batch Data Source';
    }
  }

  if (node.type === 'feature_view') {
    if (node.featureViewType === 'batch') {
      type = 'Batch Feature View';
    } else if (node.featureViewType === 'stream') {
      type = 'Stream Feature View';
    } else if (node.featureViewType === 'feature table') {
      type = 'Feature Table';
    }
  }

  if (node.type === 'feature_service') {
    type = 'Feature Service';
  }

  if (node.type === 'request_data_source') {
    type = 'Request Data Source';
  }

  if (node.type === 'odfv') {
    type = 'Realtime Feature View';
  }

  if (node.type === 'store_wrapper') {
    type = 'Tecton';
    name = 'Feature Store';
  }

  if (node.type === 'online_store') {
    type = 'Tecton';
    name = 'Online Store';
  }

  if (node.type === 'offline_store') {
    type = 'Tecton';
    name = 'Offline Store';
  }

  if (node.type === 'raw_batch_node') {
    type = 'Raw Batch Source';
  }

  if (node.type === 'raw_stream_node') {
    type = 'Raw Stream Source';
  }

  if (node.type === 'transformation') {
    type = 'Transformation';
  }

  if (node.type === 'embeddingModel') {
    type = node.isCustomModel ? 'Custom Model' : 'Embedding Model';
  }

  if (node.type === 'aggregation') {
    type = 'Aggregation';
  }

  if (node.type === 'model_trainer' || node.type === 'model_inference') {
    type = 'Consumer';
  }

  return { type, name };
};
