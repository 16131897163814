import React from 'react';
import { Position } from '@xyflow/react';
import { DataflowCard } from './VisualPrimitives/CardTypography';
import { STORE_PANEL_HEIGHT, STORE_PANEL_WIDTH } from './helpers';
import { DataflowCardPropType, StoreWrapperNode } from './dataFlowTypes';
import DataFlowHandle from './DataFlowHandle';

const DataFlowStorageNode = ({
  data,
  onMouseEnter,
  onMouseLeave,
  onClick,
  hasFocus,
  isFaded,
}: {
  data: StoreWrapperNode;
} & DataflowCardPropType) => {
  const panelHeight = data.height !== undefined ? data.height : STORE_PANEL_HEIGHT;
  const panelMidpoint = panelHeight / 2 - 3;

  return (
    <>
      <DataflowCard
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={onClick}
        hasFocus={hasFocus}
        isFaded={isFaded}
        height={panelHeight}
        width={STORE_PANEL_WIDTH}
        background="white"
      ></DataflowCard>
      <DataFlowHandle position={Position.Left} top={`${panelMidpoint}px`} />
      <DataFlowHandle position={Position.Right} top={`${panelMidpoint}px`} />
    </>
  );
};

export default DataFlowStorageNode;
