import {
  Breadcrumbs,
  EmptyPrompt,
  FlexGroup,
  FlexItem,
  HeaderLayout,
  Tabs,
  ViewLayout,
} from '@tecton/ComponentRedesign';
import { useState } from 'react';
import { Navigate, Outlet, Route, Routes, useLocation, useNavigate } from 'react-router';
import { useGetFcos } from '../../../api/fcos';
import { default as DataflowChart } from '../../../components/@tecton/ComponentRedesign/Dataflow/DataflowContainer';
import { useUpdateLog } from '../../../feature/dashboard/query';
import { useUserSettings } from '../../context/UserSettingsContext';
import { dataflowSpecSelector } from '../../dataflow/workspaceDataflowSpecSelector';
import WorkspaceRootBreadcrumb from '../WorkspaceRootBreadcrumb';
import ApplyLogTable from './ApplyLogTable';

type DataFlowRoutes = 'Dataflow' | 'Apply Log';

export const getDataFlowRoutes = (): Record<DataFlowRoutes, string> => {
  return {
    Dataflow: 'dataflow',
    'Apply Log': 'apply-log',
  };
};

export const getDataFlowRouteName = (pathname: string): DataFlowRoutes => {
  const pathSegments = pathname.split('/');
  const lastPathSegment = pathSegments[pathSegments.length - 1];
  const routes = getDataFlowRoutes();
  const record = Object?.entries(routes)?.find((record) => {
    return record[1] === lastPathSegment;
  });

  return (record?.[0] ?? 'Dataflow') as DataFlowRoutes;
};

const DataflowContainerOutlet = () => {
  const navigation = useNavigate();
  const { workspaceDetails } = useUserSettings();

  const location = useLocation();
  const featureRoutes = getDataFlowRouteName(location.pathname);
  const [selectedTab, setSelectedTab] = useState<DataFlowRoutes>(featureRoutes);

  const breadcrumbs = (
    <Breadcrumbs
      workspace={<WorkspaceRootBreadcrumb workspace={workspaceDetails} />}
      crumbs={[{ label: 'Dataflow' }]}
    />
  );
  const routeNames = Object.keys(getDataFlowRoutes());

  const tabs = (
    <Tabs
      selectedTab={selectedTab}
      tabs={routeNames}
      setSelectedTab={(tab: DataFlowRoutes) => {
        const routes = getDataFlowRoutes();
        navigation(routes[tab]);
        setSelectedTab(tab);
      }}
    />
  );

  const header = <HeaderLayout breadcrumbs={breadcrumbs} tabs={tabs} />;

  return <ViewLayout header={header} body={<Outlet />} />;
};

const DataflowDataWrapper = () => {
  const { workspace } = useUserSettings();

  const { data, isLoading } = useGetFcos(workspace ?? '', {});

  if (isLoading) {
    return (
      <FlexGroup direction="column" alignItems="center">
        <FlexItem>
          <EmptyPrompt variant="loading" title={<>Loading Data</>} body={undefined} orientation="vertical" />
        </FlexItem>
      </FlexGroup>
    );
  }

  const dataAsFlowData = dataflowSpecSelector(data, workspace ?? 'no-workspace');

  return <DataflowChart data={dataAsFlowData} />;
};

const ApplyLogWrapper = () => {
  const { workspace } = useUserSettings();
  const { data, isLoading } = useUpdateLog(workspace);

  if (isLoading) {
    return (
      <FlexGroup direction="column" alignItems="center">
        <FlexItem>
          <EmptyPrompt
            variant="loading"
            title={<>Loading Workspace Commits</>}
            body={undefined}
            orientation="vertical"
          />
        </FlexItem>
      </FlexGroup>
    );
  }

  return <ApplyLogTable items={data} />;
};

const DataFlowContainer = () => {
  const dataFlowRoutes = getDataFlowRoutes();

  return (
    <Routes>
      <Route element={<DataflowContainerOutlet />}>
        <Route index element={<Navigate to={dataFlowRoutes[`Dataflow`]} replace />} />
        <Route path={dataFlowRoutes[`Dataflow`]} element={<DataflowDataWrapper />} />
        <Route path={dataFlowRoutes[`Apply Log`]} element={<ApplyLogWrapper />} />
      </Route>
    </Routes>
  );
};

export default DataFlowContainer;
