import React, { FC } from 'react';
import { FlexGroup, FlexItem, Text, Icon, useTectonTheme } from '.';
import styled from '@emotion/styled';

type CheckboxSize = 's' | 'm';

interface CheckboxProps {
  state: boolean;
  onClick: () => void;
  label: React.ReactNode;
  showBox?: boolean;
  size?: CheckboxSize;
  disabled?: boolean;
}

const CheckboxWrapper = styled.div<{ disabled?: boolean; active: boolean }>`
  background-color: ${({ theme }) => theme.v1.colors.background.empty};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  border-radius: ${({ theme }) => `${theme.v1.size['1']}px`};
  pointer-events: ${({ disabled }) => (disabled ? 'not-allowed' : 'cursor')};
  user-select: none;

  ${({ disabled, theme }) =>
    !disabled &&
    `
      &:hover {
        background-color:${theme.v1.colors.background.hover};
            * {
      color: ${disabled ? theme.v1.colors.text.disabledText : theme.v1.colors.text.title};
      font-weight:${theme.font.weight.medium};
    }
      }
    `}
`;

const Box = styled.span<{ visible?: boolean; active?: boolean; disabled?: boolean }>`
  border: 1px solid
    ${({ theme, visible, disabled }) => {
      if (disabled && visible) return theme.v1.colors.text.disabledText;
      return visible ? theme.v1.colors.border.primary : theme.v1.colors.background.transparent;
    }};
  border-radius: ${({ theme }) => `${theme.v1.size['1']}px`};
  line-height: 0;
  background-color: ${({ theme, active }) =>
    active ? theme.v1.colors.border.primary : theme.v1.colors.background.transparent};

  transition: ${({ theme }) => `background-color ${theme.v1.motion.duration.fast} ${theme.v1.motion.easing.exit}`};
  * {
    opacity: ${({ active }) => (active ? 1 : 0)};
  }
`;

const Checkbox: FC<CheckboxProps> = ({ state, onClick, label, showBox, size = 'm', disabled = false }) => {
  const { theme } = useTectonTheme();

  return (
    <CheckboxWrapper
      onClick={() => {
        if (!disabled) onClick();
      }}
      disabled={disabled}
      active={state}
    >
      <FlexGroup alignItems="center">
        <FlexItem grow={0}>
          <FlexGroup alignItems="center" padding="0">
            <Box visible={showBox} active={state} disabled={disabled}>
              <Icon
                icon="Checkmark"
                type="mono"
                size={size === 'm' ? 's' : 'xs'}
                color={theme.v1.colors.text.invertedText}
              />
            </Box>
          </FlexGroup>
        </FlexItem>
        <Text small={size === 's'} weight={state && !disabled ? 'medium' : 'regular'}>
          {label}
        </Text>
      </FlexGroup>
    </CheckboxWrapper>
  );
};

export default Checkbox;
