import React from 'react';
import styled from '@emotion/styled';
import { Title, Link } from '@tecton';
import DetailsPanelAccordionSection from './DetailsPanelAccordionSection';
import { NodeClickCallbackCreator } from './detailsPanelTypes';

const borderStyle = `
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-right: 8px;
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 50% calc(50% - 1px);

  ${borderStyle}
`;

const Panel = styled.section`
  padding: 16px;
  margin: 0;

  &:not(:last-of-type) {
    border-right: 1px solid #ddd;
  }
`;

const List = styled.ul`
  list-style: disc;
  padding-left: 1em;
  .euiLink {
    line-break: anywhere;
  }
`;

const NoneSpan = styled.span`
  color: #aaa;
  font-style: italic;
`;

const NoneDisplay = () => {
  return <NoneSpan>None</NoneSpan>;
};

type NodeIdentifier = {
  id: string;
  name: string;
};

const NodeListDisplay = ({
  nodeIds,
  onNodeClick,
}: {
  nodeIds: NodeIdentifier[];
  onNodeClick: (nodeId: string) => () => void;
}) => {
  return (
    <List>
      {nodeIds.map((n) => {
        const onClick = onNodeClick(n.id);

        return (
          <li key={n.id}>
            <Link onClick={onClick}>{n.name}</Link>
          </li>
        );
      })}
    </List>
  );
};

const UpstreamDownstreamTable = ({
  upstreamNodes,
  downstreamNodes,
  onNodeClick,
}: {
  upstreamNodes?: NodeIdentifier[];
  downstreamNodes?: NodeIdentifier[];
  onNodeClick: NodeClickCallbackCreator;
}) => {
  return (
    <DetailsPanelAccordionSection
      id="connections"
      title={
        <Title size="xxs">
          <h4>Connections</h4>
        </Title>
      }
    >
      <Wrapper>
        <Panel>
          <Title size="xxxs">
            <h5>Upstream</h5>
          </Title>
          {!upstreamNodes || upstreamNodes.length === 0 ? (
            <NoneDisplay />
          ) : (
            <NodeListDisplay nodeIds={upstreamNodes} onNodeClick={onNodeClick} />
          )}
        </Panel>
        <Panel>
          <Title size="xxxs">
            <h5>Downstream</h5>
          </Title>
          {!downstreamNodes || downstreamNodes.length === 0 ? (
            <NoneDisplay />
          ) : (
            <NodeListDisplay nodeIds={downstreamNodes} onNodeClick={onNodeClick} />
          )}
        </Panel>
      </Wrapper>
    </DetailsPanelAccordionSection>
  );
};

export default UpstreamDownstreamTable;
