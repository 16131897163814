import React from 'react';
import { Position } from '@xyflow/react';
import styled from '@emotion/styled';
import { DataflowCardPropType } from './dataFlowTypes';
import { SvgWithGlowWrapper } from './VisualPrimitives/CardTypography';
import DataFlowHandle from './DataFlowHandle';
import { AggregationNode } from '../../../dataflow/dataFlowTypes';
import { useTectonTheme } from '@tecton/ComponentRedesign';
import { keyframes } from '@emotion/react';

const animationOne = keyframes`
  0% {
    transform: translate(-60px, 0);
  }

  27% {
    transform: translate(0, 0);
  }

  94% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(40px, 0);
  }
`;

const animationTwo = keyframes`
  0% {
    transform: translate(-60px, 0);
  }

  27% {
    transform: translate(-60px, 0);
  }

  54% {
    transform: translate(0, 0);
  }

  94% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(40px, 0);
  }
`;

const animationThree = keyframes`
  0% {
    transform: translate(-60px, 0);
  }

  54% {
    transform: translate(-60px, 0);
  }

  81% {
    transform: translate(0, 0);
  }

  94% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(40px, 0);
  }
`;

const duration = '2s';

const AnimatedCircleOne = styled.circle`
  fill: white;
  animation-name: ${animationOne};
  animation-duration: ${duration};
  animation-timing-function: linear;
  animation-iteration-count: infinite;
`;

const AnimatedCircleTwo = styled.circle`
  fill: white;
  animation-name: ${animationTwo};
  animation-duration: ${duration};
  animation-timing-function: linear;
  animation-iteration-count: infinite;
`;

const AnimatedCircleThree = styled.circle`
  fill: white;
  animation-name: ${animationThree};
  animation-duration: ${duration};
  animation-timing-function: linear;
  animation-iteration-count: infinite;
`;

const AggregationNodeSvg = () => {
  const { theme } = useTectonTheme();

  return (
    <svg>
      <defs>
        <clipPath id={'clipAggregationNode'}>
          <path d="M 11 2 L 44 2 A 9 9 0 0 1 44 20 L 11 20 A 9 9 0 0 1 11 2 Z" fill="black" />
        </clipPath>
      </defs>

      <path
        d="M 11 2 L 44 2 A 9 9 0 0 1 44 20 L 11 20 A 9 9 0 0 1 11 2 Z"
        strokeWidth="2"
        fill={theme.FCOTintColors.transformation}
        stroke={theme.FCOColors.transformation}
      />
      <g clipPath={`url(#clipAggregationNode)`}>
        <AnimatedCircleOne cx={44} cy={11} r={6} />
        <AnimatedCircleTwo cx={32} cy={11} r={6} />
        <AnimatedCircleThree cx={20} cy={11} r={6} />
      </g>
    </svg>
  );
};

const WIDTH = 150;
const HEIGHT = 60;

const Wrapper = styled.div<{ isFaded?: boolean }>`
  width: ${WIDTH}px;
  position: relative;
  opacity: ${(props) => (props.isFaded ? 0.15 : 1)};
  cursor: pointer;
  overflow: visible;
  background: rgba(0, 0 255, 0.5);
  height: ${HEIGHT}px;
  transform: translateY(-${HEIGHT / 2}px);
  padding: 0px 16px;
  border: ${({ theme }) => theme.border.thin};
  background: ${({ theme }) => theme.colors.emptyShade};
`;

const TruncateName = styled.div`
  width: ${WIDTH};
  overflow: hidden;
  text-overflow: ellipsis;
`;

const DataFlowAggregationNode = ({
  data,
  onMouseEnter,
  onMouseLeave,
  onClick,
  hasFocus,
  isFaded,
}: { data: AggregationNode } & DataflowCardPropType) => {
  return (
    <>
      <DataFlowHandle position={Position.Left} top={`0px`} />
      <Wrapper onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onClick={onClick} isFaded={isFaded}>
        <TruncateName>{data.name}</TruncateName>
        <SvgWithGlowWrapper hasFocus={hasFocus} inset={[4, 2]}>
          <AggregationNodeSvg />
        </SvgWithGlowWrapper>
      </Wrapper>
      <DataFlowHandle position={Position.Right} top={`0px`} />
    </>
  );
};

export default DataFlowAggregationNode;
