import React, { FC } from 'react';
import PrometheusCount from './PrometheusCount';
import { MonitoringDateRange } from '../../monitoring/DateRange-Dropdown-util';

interface LatencySLO30DaysProps {
  monitoringDateRange: MonitoringDateRange;
}

const LatencySLO30Days: FC<LatencySLO30DaysProps> = ({ monitoringDateRange }) => {
  const chartTitle = 'Latency SLO (30 Days)';

  const queries = [
    {
      label: 'Total',
      query: `1 - (sum(rate(feature_service_latency_slo_count{latency_slo_eligible="true", latency_slo_met="false", tecton_cluster=~'.*',  aws_region=~'.*'}[30d])) or on() vector(0)) / sum(rate(feature_service_latency_slo_count{tecton_cluster=~'.*',  aws_region=~'.*'}[30d]))`,
    },
  ];

  return (
    <>
      <PrometheusCount
        chartTitle={chartTitle}
        monitoringDateRange={monitoringDateRange}
        queries={queries}
        parseCallback={(arg: string) => {
          return parseFloat(arg) * 1000;
        }}
        calculateAverage={(average: number) => {
          return average / 1000;
        }}
        unit="%"
      />
    </>
  );
};

export default LatencySLO30Days;
