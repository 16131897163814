import React, { FC } from 'react';
import PrometheusCount from './PrometheusCount';
import { MonitoringDateRange } from '../../monitoring/DateRange-Dropdown-util';

interface SLOReliability30DaysProps {
  monitoringDateRange: MonitoringDateRange;
}

const SLOReliability30Days: FC<SLOReliability30DaysProps> = ({ monitoringDateRange }) => {
  const chartTitle = 'Reliability SLO (30 days)';

  const queries = [
    {
      label: 'Total',
      query: `1 - (sum(rate(feature_service_query_count{status=~"Unknown|Unimplemented|Internal|Unavailable|DataLoss", tecton_cluster=~'.*',  aws_region=~'.*'}[30d])) or on() vector(0)) / sum(rate(feature_service_query_count{tecton_cluster=~'.*',  aws_region=~'.*'}[30d]))`,
    },
  ];

  return (
    <>
      <PrometheusCount
        chartTitle={chartTitle}
        monitoringDateRange={monitoringDateRange}
        queries={queries}
        parseCallback={(arg: string) => {
          return Math.round(parseFloat(arg) * 100);
        }}
        unit="%"
      />
    </>
  );
};

export default SLOReliability30Days;
