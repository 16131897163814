import { useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DataflowCardPropType, DataFlowNode } from './dataFlowTypes';
import { DetailsPanelContext } from './DetailsPanelFocusProvider';
import { DataFlowFocusContext } from './DataFlowFocusProvider';

const useDataflowNodeProps = (data: DataFlowNode) => {
  const navigate = useNavigate();
  const { workspace } = useParams();

  const { shouldNodeBeVisible, setFocusedNodeId } = useContext(DataFlowFocusContext);
  const {
    DEBUG_MODE,
    denseMode,
    nodeIsCurrentlyClicked,
    detailsPanelHoverNodeId,
    detailsPanelClearHover,
    detailsPanelClickNodeId,
  } = useContext(DetailsPanelContext);
  const isFaded = !shouldNodeBeVisible(data.id);
  const hasFocus = nodeIsCurrentlyClicked(data.id);

  const linkType =
    data.type === 'data_source' ||
    data.type === 'feature_view' ||
    data.type === 'odfv' ||
    data.type === 'transformation' ||
    data.type === 'feature_service'
      ? data.type
      : null;

  const onClick = (event: React.MouseEvent) => {};

  const onMouseEnter = () => {
    setFocusedNodeId(data.id);
    detailsPanelHoverNodeId(data.id);
  };

  const onMouseLeave = () => {
    setFocusedNodeId();
    detailsPanelClearHover();
  };

  const props: DataflowCardPropType = {
    denseMode,
    onMouseEnter,
    onMouseLeave,
    onClick,
    hasFocus,
    isFaded,
    DEBUG_MODE,
    workspace,
  };

  return props;
};

export { useDataflowNodeProps };
