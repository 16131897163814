import { Metadata_Service } from './';
import { useQuery } from '@tanstack/react-query';
import { GetMaterializingFeatureViewsInLiveWorkspacesResponse } from '../types/tecton_proto/metadataservice/metadata_service';

const fetchAllMaterializingFeatureViews = async (): Promise<GetMaterializingFeatureViewsInLiveWorkspacesResponse> => {
  const { data } = await Metadata_Service('get-materializing-features-in-live-workspace', {
    method: 'POST',
  });

  return data;
};

export const useListAllMaterializingFeatureViews = () => {
  return useQuery(['list-all-featureViews'], () => fetchAllMaterializingFeatureViews(), {
    select: (data) => {
      if (data?.feature_views?.length) {
        return [
          ...new Set(data.feature_views.filter((fv) => fv?.fco_metadata?.name).map((fv) => fv?.fco_metadata?.name)),
        ];
      }
      return [];
    },
  });
};
