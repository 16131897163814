import styled from '@emotion/styled';

export const ResponsiveContainer = styled.div`
  container: inline-size;
  container-type: inline-size;
  display: block;
  overflow: hidden;
  height: 100%;
  width: 100%;
`;
