import { Box } from '@mui/material';
import React, { FC } from 'react';
import { NewWorkspaceSelectorAutocompleteOption } from './NewWorkspaceSelector';
import style from './style.module.scss';

interface NewWorkspaceSelectorGroupHeadingProperties {
  option: NewWorkspaceSelectorAutocompleteOption;
}

const NewWorkspaceSelectorGroupHeading: FC<NewWorkspaceSelectorGroupHeadingProperties> = ({ option }) => {
  return (
    <Box display="flex" sx={{ justifyContent: 'center' }}>
      <Box className={style.workspaceSelectorGroupHeading}>{option.label}</Box>
    </Box>
  );
};

export default NewWorkspaceSelectorGroupHeading;
