import styled from '@emotion/styled';
import React, { FC, useState } from 'react';
import { Button, FlexGroup, Icon, Text } from './';
import { css, keyframes, Theme } from '@emotion/react';
import { useTectonTheme } from '../..';

type BannerStatus = 'info' | 'success' | 'danger' | 'warning';

interface BannerProps {
  title?: string;
  status?: BannerStatus;
  closeable?: boolean;
  description: React.ReactNode;
  primaryAction?: React.ReactNode;
  secondaryAction?: React.ReactNode;
  setIsClosed?: React.Dispatch<React.SetStateAction<boolean>>;
}

const closingAnimation = keyframes`
  to {
    height:0px;
    opacity:0;
  }
`;

const getBackgroundColor = (theme: Theme, status: BannerStatus) => {
  const statusKeyMap: Record<BannerStatus, string> = {
    info: theme.v1.colors.background.default,
    success: theme.v1.colors.background.success,
    danger: theme.v1.colors.background.dangerSecondary,
    warning: theme.v1.colors.background.warning,
  };

  return statusKeyMap[status];
};

const Container = styled.div<{ status: BannerStatus; closing: boolean }>`
  background-color: ${({ theme, status }) => {
    return getBackgroundColor(theme, status);
  }};
  padding: ${({ theme }) => theme.v1.size['2']}px ${({ theme }) => theme.v1.size['5']}px;

  color: ${({ theme, status }) => {
    const colorMap: Record<BannerStatus, string> = {
      info: theme.v1.colors.text.text,
      success: theme.v1.colors.text.successText,
      danger: theme.v1.colors.text.dangerText,
      warning: theme.v1.colors.text.warningText,
    };
    return colorMap[status];
  }};

  height: ${({ theme }) => theme.v1.size['12']}px;
  overflow: hidden;
  animation: ${({ theme, closing }) => {
    if (!closing) {
      return 'none';
    }
    return css`
      ${closingAnimation} ${theme.v1.motion.duration.xfast} ${theme.v1.motion.easing.exit} forwards
    `;
  }};
`;

const BannerDescription = styled.div`
  color: ${({ theme }) => theme.v1.colors.text.text};
`;

const BannerInternal: FC<BannerProps> = ({
  status = 'info',
  closeable = false,
  description,
  primaryAction,
  secondaryAction,
  title,
  setIsClosed,
}) => {
  const [isClosing, setIsClosing] = useState<boolean>(false);

  const { theme } = useTectonTheme();

  const IconStatusMap: Record<BannerStatus, React.ReactNode> = {
    info: (
      <>
        <Icon icon="Information" type={'mono'} size={'s'} color={theme.v1.colors.icon.primary} />
        <Text weight="medium">{title ?? <>Info</>}</Text>
      </>
    ),
    success: (
      <>
        <Icon icon="CheckCircle" type={'mono'} size={'s'} color={theme.v1.colors.icon.success} />{' '}
        <Text weight="medium">{title ?? <>Success</>}</Text>
      </>
    ),
    danger: (
      <>
        <Icon icon="CheckCircle" type={'mono'} size={'s'} color={theme.v1.colors.icon.danger} />{' '}
        <Text weight="medium">{title ?? <>Error</>}</Text>
      </>
    ),
    warning: (
      <>
        <Icon icon="Warning" type={'mono'} size={'s'} color={theme.v1.colors.icon.warning} />{' '}
        <Text weight="medium">{title ?? <>Warning</>}</Text>
      </>
    ),
  };

  return (
    <Container
      status={status}
      closing={isClosing}
      onAnimationEnd={() => {
        setIsClosed && setIsClosed(true);
      }}
    >
      <FlexGroup
        justifyContent="space-between"
        alignItems="center"
        direction="row"
        gapSize="2"
        css={{ height: '100%' }}
      >
        <FlexGroup alignItems="center" gapSize="2">
          {IconStatusMap[status]}
          <BannerDescription>{description}</BannerDescription>
          {secondaryAction && secondaryAction}
          {primaryAction && primaryAction}
        </FlexGroup>
        {closeable && (
          <Button
            onClick={() => {
              setIsClosing(true);
            }}
            size="s"
            type="ghost"
            icon={'Exit'}
          />
        )}
      </FlexGroup>
    </Container>
  );
};

const Banner: FC<BannerProps> = ({
  status = 'info',
  closeable = false,
  description,
  primaryAction,
  secondaryAction,
  title,
}) => {
  const [isClosed, setIsClosed] = useState<boolean>(false);

  if (isClosed) {
    return <></>;
  }

  return (
    <BannerInternal
      status={status}
      title={title}
      closeable={closeable}
      description={description}
      primaryAction={primaryAction}
      secondaryAction={secondaryAction}
      setIsClosed={closeable ? setIsClosed : undefined}
    />
  );
};

export default Banner;
