import { Callout, FlexGroupWrapper, FlexItem } from '@tecton/ComponentRedesign';
import { FC } from 'react';
import { TRANSFORMATION_SERVER_GROUPS } from '../../../feature/graph-configuration';
import { ServerGroupStatus } from '../../../types/tecton_proto/common/server_group_status';

import { MonitoringDateRange } from '../../monitoring/DateRange-Dropdown-util';
import { ServerGroupSelectable } from './FeatureServerGroupLists';
import MonitoringGraphs from './MonitoringGraphs';
import { ServerGroupStatusType } from './RealtimeServerMonitoring';

const TransformationServerGroup: FC<{
  selectedServerGroup?: ServerGroupSelectable;
  serverGroupStatusMap: ServerGroupStatusType;
  monitoringDateRange: MonitoringDateRange;
  selectedWorkspace: string;
}> = ({ selectedServerGroup, serverGroupStatusMap, monitoringDateRange, selectedWorkspace }) => {
  return (
    <>
      <FlexGroupWrapper>
        <FlexItem>
          {selectedServerGroup?.status && (
            <Callout
              mode={
                serverGroupStatusMap[selectedServerGroup?.status ?? ServerGroupStatus.SERVER_GROUP_STATUS_UNSPECIFIED]
                  .status
              }
              title={`Status: ${
                serverGroupStatusMap[selectedServerGroup?.status ?? ServerGroupStatus.SERVER_GROUP_STATUS_UNSPECIFIED]
                  .title
              }`}
            >
              {selectedServerGroup.description}
            </Callout>
          )}
        </FlexItem>
        <FlexItem>
          <MonitoringGraphs
            monitoringDateRange={monitoringDateRange}
            graphsData={TRANSFORMATION_SERVER_GROUPS}
            customGraphRecord={{}}
            workspace={selectedWorkspace ?? ''}
            serverGroupId={selectedServerGroup?.serverGroupId}
          />
        </FlexItem>
      </FlexGroupWrapper>
    </>
  );
};

export default TransformationServerGroup;
