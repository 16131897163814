import React, { FC } from 'react';
import { FlexGroup, FlexItem, useTectonTheme } from '.';
import styled from '@emotion/styled';

type RadioSize = 's' | 'm';
interface RadioProps {
  items: React.ReactNode[];
  selectedItem: React.ReactNode;
  setSelectedItem: (item: React.ReactNode) => void;
  size?: RadioSize;
}

const StyledCircle = styled.circle<{ selected: boolean }>`
  transform: scale(${({ selected }) => (selected ? 1 : 0)})
    ${({ selected }) => (selected ? 'translate(10,10)' : 'translate(0,0)')};
  transition: transform ${({ theme }) => theme.v1.motion.duration.xfast}
    ${({ theme }) => theme.v1.motion.easing.transition};
`;

const RadioButton: FC<{ selected: boolean; size: RadioSize }> = ({ selected, size }) => {
  const { theme } = useTectonTheme();

  const baseSize = size === 'm' ? theme.v1.size['4'] : theme.v1.size['3'];

  return (
    <svg height={baseSize} width={baseSize} style={{ overflow: 'visible' }}>
      <circle
        r={baseSize / 2}
        cx={baseSize / 2}
        cy={baseSize / 2}
        stroke={theme.v1.colors.border.default}
        stroke-width="1"
        fill="none"
      />
      <g transform={`translate(${baseSize / 2},${baseSize / 2})`}>
        <StyledCircle r={baseSize / 2 - 1} fill={theme.v1.colors.background.primary} selected={selected} />
      </g>
    </svg>
  );
};

const FlexWrap = styled.div<{ selected: boolean; size: RadioSize }>`
  cursor: pointer;

  font-size: ${({ size }) => (size === 'm' ? '14px' : '12px')}; // TODO: Don't use literals
  font-weight: ${({ theme, selected }) => (selected ? theme.font.weight.medium : theme.font.weight.regular)};
  color: ${({ theme, selected }) => (selected ? theme.v1.colors.text.title : theme.v1.colors.text.text)};

  &:hover {
    font-weight: ${({ theme }) => theme.font.weight.medium};
    background-color: ${({ theme }) => theme.v1.colors.background.hover};
  }
`;

const Radio: FC<RadioProps> = ({ items, selectedItem, setSelectedItem, size = 'm' }) => {
  return (
    <FlexGroup direction="column" padding="0">
      {items.map((item) => {
        return (
          <FlexWrap
            size={size}
            selected={selectedItem === item}
            onClick={() => {
              setSelectedItem(item);
            }}
          >
            <FlexGroup padding="0" alignItems="middle" gapSize="1">
              <FlexItem grow="0">
                <FlexGroup padding="0" alignItems="center">
                  <RadioButton selected={selectedItem === item} size={size} />
                </FlexGroup>
              </FlexItem>
              <FlexItem grow="0">{item}</FlexItem>
            </FlexGroup>
          </FlexWrap>
        );
      })}
    </FlexGroup>
  );
};

export default Radio;
