import React, { FC } from 'react';
import moment from 'moment';

import TitleValueLayout from './TitleValueLayout';

// generated
import { ServiceAccountDetail } from '../../../api/gql/graphql';

// @tecton
import {
  Button,
  Card,
  FlexItem,
  Text,
  FlexGroupWrapper,
  FlexGrid,
  TitledItemEditable,
  EditableInlineField,
  ActiveInActiveBadge,
  RequestCallbackType,
  CardContentTopPadding,
} from '@tecton/ComponentRedesign';
import { TectonDateFormat } from '@tecton/ComponentRedesign/utils';

import PrincipalDetailsAccountType from './PrincipalDetailsAccountType';

// svg
import { ReactComponent as Trash } from '@tecton/ComponentRedesign/svg/trash.svg';
import { ReactComponent as Check } from '@tecton/ComponentRedesign/svg/check.svg';

export interface ACLServiceAccountDetailsCardProps {
  serviceAccountDetail?: ServiceAccountDetail;
  isAdminView: boolean;
  onActivateServiceAccountClicked: VoidFunction;
  onDeActivateServiceAccountClicked: VoidFunction;
  onDeleteServiceAccountClicked: VoidFunction;
  onFieldSaveConfirm: (filedName: string, newValue: string, callback: RequestCallbackType) => void;
  onEditAccountType: VoidFunction;
}

const ACLServiceAccountDetailsCard: FC<ACLServiceAccountDetailsCardProps> = ({
  serviceAccountDetail,
  isAdminView,
  onActivateServiceAccountClicked,
  onDeActivateServiceAccountClicked,
  onDeleteServiceAccountClicked,
  onFieldSaveConfirm,
  onEditAccountType,
}) => {
  const serviceAccount = serviceAccountDetail?.serviceAccount;

  const createdAt = serviceAccount?.createdAt ? TectonDateFormat(moment(serviceAccount.createdAt)) : '-';

  const serviceAccountInformationLists = [
    {
      title: 'Name',
      name: 'name',
      isEditable: true,
      content: serviceAccount?.name,
      renderField: (
        fieldTitle: string,
        fieldName: string,
        fieldValue: string,
        onFieldSaveConfirm: (filedName: string, newValue: string, callback: RequestCallbackType) => void
      ) => {
        const valueContent = isAdminView ? (
          <>
            <EditableInlineField
              fieldTitle={fieldTitle}
              fieldName={fieldName}
              fieldValue={fieldValue}
              onFieldSaveConfirm={onFieldSaveConfirm}
            />
          </>
        ) : (
          <>{fieldValue}</>
        );
        return (
          <>
            <TitleValueLayout title={fieldTitle} valueContent={valueContent} />
          </>
        );
      },
    },
    {
      title: 'Description',
      name: 'description',
      content: serviceAccount?.description,
      isEditable: true,
      renderField: (
        fieldTitle: string,
        fieldName: string,
        fieldValue: string,
        onFieldSaveConfirm: (filedName: string, newValue: string, callback: RequestCallbackType) => void
      ) => {
        const valueContent = isAdminView ? (
          <>
            <EditableInlineField
              fieldTitle={fieldTitle}
              fieldName={fieldName}
              fieldValue={fieldValue}
              onFieldSaveConfirm={onFieldSaveConfirm}
            />
          </>
        ) : (
          <>{fieldValue}</>
        );
        return (
          <>
            <TitleValueLayout title={fieldTitle} valueContent={valueContent} />
          </>
        );
      },
    },
    ...(isAdminView
      ? [
          {
            title: 'Account Type',
            name: 'accountType',
            isEditable: true,
            content: serviceAccountDetail?.accountTypeWithInheritance.effectiveAccountType ?? '',
            renderField: () => {
              const groups =
                serviceAccountDetail?.accountTypeWithInheritance?.inheritedAccountTypes?.map((inheritedAccountType) => {
                  return {
                    groupName: inheritedAccountType?.groupName ?? '',
                    groupId: inheritedAccountType?.groupId ?? '',
                  };
                }) ?? [];

              const accountType =
                serviceAccountDetail?.accountTypeWithInheritance.assignedAccountType === 'Admin' ? 'Admin' : 'Regular';
              return (
                <>
                  <PrincipalDetailsAccountType
                    accountType={accountType}
                    groups={groups}
                    onEditAccountType={onEditAccountType}
                  />
                </>
              );
            },
          },
        ]
      : []),
    {
      title: 'ID',
      name: 'id',
      content: serviceAccount?.id,
      isEditable: true,
      renderField: (fieldTitle: string, fieldName: string, fieldValue: string) => {
        return (
          <>
            <TitleValueLayout title={fieldTitle} valueContent={<>{fieldValue}</>} />
          </>
        );
      },
    },
    {
      title: 'Status',
      name: 'status',
      isEditable: true,
      content: serviceAccount?.isActive ? 'Active' : 'InActive',
      renderField: (fieldTitle: string, fieldName: string, fieldValue: string) => {
        return (
          <>
            <TitleValueLayout
              title={fieldTitle}
              valueContent={
                <>
                  <ActiveInActiveBadge enabled={fieldValue === 'Active'} />
                </>
              }
            />
          </>
        );
      },
    },
    {
      title: 'Created',
      name: 'createdAt',
      isEditable: true,
      content: createdAt,
      renderField: (fieldTitle: string, fieldName: string, fieldValue: string) => {
        return (
          <>
            <TitleValueLayout title={fieldTitle} valueContent={<>{fieldValue}</>} />
          </>
        );
      },
    },
  ];

  const adminActionCard =
    serviceAccount?.isActive === true ? (
      <Card title={`Deactivate Service Account`} showOverlay={false}>
        <FlexGroupWrapper direction="column" gap="l">
          <FlexItem>This disables the Service Account but does not permanently delete it.</FlexItem>
          <FlexItem>
            <FlexGroupWrapper direction="row" justifyContent="flexEnd">
              <FlexItem grow={false}>
                <Button
                  label={`Deactivate Service Account`}
                  variant="dangerousAction"
                  iconLeft={Trash}
                  onClick={() => {
                    onDeActivateServiceAccountClicked();
                  }}
                />
              </FlexItem>
            </FlexGroupWrapper>
          </FlexItem>
        </FlexGroupWrapper>
      </Card>
    ) : (
      <>
        <FlexGroupWrapper gap="l" direction="column">
          <FlexItem>
            <Card showOverlay={false}>
              <CardContentTopPadding>
                <FlexGroupWrapper direction="column" gap="l">
                  <FlexItem>
                    <Text>Activate Service Account</Text>
                  </FlexItem>
                  <FlexItem>
                    Activating a service account restores the account’s access to what it was before deactivated.
                  </FlexItem>
                  <FlexItem>
                    <FlexGroupWrapper direction="row" justifyContent="flexEnd">
                      <FlexItem grow={false}>
                        <Button
                          label={`Activate Service Account`}
                          variant="primaryAction"
                          iconLeft={Check}
                          onClick={() => {
                            onActivateServiceAccountClicked();
                          }}
                        />
                      </FlexItem>
                    </FlexGroupWrapper>
                  </FlexItem>
                </FlexGroupWrapper>
              </CardContentTopPadding>
            </Card>
          </FlexItem>
          <FlexItem>
            <Card showOverlay={false}>
              <CardContentTopPadding>
                <FlexGroupWrapper direction="column" gap="s">
                  <FlexItem>
                    <Text>Delete Service Account</Text>
                  </FlexItem>
                  <FlexItem> Deleting a service account removes the account from Tecton permanently.</FlexItem>
                  <FlexItem>
                    <FlexGroupWrapper direction="row" justifyContent="flexEnd">
                      <FlexItem grow={false}>
                        <Button
                          label={`Delete Service Account`}
                          variant="dangerousAction"
                          iconLeft={Trash}
                          onClick={() => {
                            onDeleteServiceAccountClicked();
                          }}
                        />
                      </FlexItem>
                    </FlexGroupWrapper>
                  </FlexItem>
                </FlexGroupWrapper>
              </CardContentTopPadding>
            </Card>
          </FlexItem>
        </FlexGroupWrapper>
      </>
    );

  return (
    <>
      <FlexGroupWrapper direction="column" gap="xl">
        <FlexItem>
          <Card noScroll title={`Overview`}>
            <FlexGrid columns={1} gutterSize="m">
              {serviceAccountInformationLists.map((item, key) => {
                return (
                  <>
                    <TitledItemEditable
                      key={key}
                      fieldTitle={item.title ?? ''}
                      fieldName={item.name ?? ''}
                      fieldValue={item.content ?? ''}
                      isEditable={item?.isEditable ?? false}
                      onFieldSaveConfirm={onFieldSaveConfirm}
                      renderField={item.renderField}
                      orientation="vertical"
                    />
                  </>
                );
              })}
            </FlexGrid>
          </Card>
        </FlexItem>
        <FlexItem>
          <FlexGroupWrapper direction="column" gap="s">
            {isAdminView && <FlexItem>{adminActionCard}</FlexItem>}
          </FlexGroupWrapper>
        </FlexItem>
      </FlexGroupWrapper>
    </>
  );
};

export default ACLServiceAccountDetailsCard;
