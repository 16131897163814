import { FeatureColumnSummaryType } from 'src/components/featureViews/FeatureColumnSummaryPanel';
import {
  buildHashToIdMap,
  buildMapWithNameAsKey,
  collectSchemaFromRequestDataSources,
  getDependentFeatureViewIds,
  getRequestDataSourceFromODFV,
  idFormatter,
} from './workspaceDataflowSpecSelector';
import {
  DataSourceDetailsPanelData,
  FeatureServiceDetailsPanelData,
  FeatureServiceFeatureGroup,
  FeatureViewDetailsPanelData,
  ODFVDetailsPanelData,
  ODFVSchemaRow,
  RequestDataSourceDetailsPanelData,
  TransformationAccordionEntry,
  WorkspaceStoreDetailsPanelData,
} from './DetailsPanel/detailsPanelQueryTypes';
import { NodeIdentifier } from './DetailsPanel/detailsPanelTypes';
import {
  DataSourceFCO,
  FCO,
  FeatureForFCO,
  FeatureServiceFCO,
  FeatureViewFCO,
  TransformationFCO,
  WorkspaceFCOContainer,
} from '../../../../core/types/fcoTypes';
import { SparkField } from '../../../../types/tecton_proto/common/spark_schema';
import { TectonFeatureDataTypes } from '../../../shared/utils/TectonFeatureDataTypes';

const NO_DESCRIPTION = 'No Description';

const getMaterializationConfig = (fv: FeatureViewFCO, type: 'online' | 'offline'): boolean => {
  return type === 'online'
    ? !!fv.materializationParams.writes_to_online_store
    : !!fv.materializationParams.writes_to_offline_store;
};

const useDataSourceDetailsPanelData = (dsId: string) => {
  //   return useGetFcos(workspace as string, {
  //     select: (data: WorkspaceFCOContainer) => {
  //       const idToFco = buildMapWithNameAsKey(data.idToFcoMap);
  //       const hashToId = buildHashToIdMap(data.idToFcoMap);
  //       const ds = idToFco[dsId] as DataSourceFCO;
  //       const batchConfig: DataSourceDetailsPanelData['batchConfig'] = ds.underlyingBatchSourceDataType
  //         ? {
  //             type: ds.underlyingBatchSourceDataType,
  //             properties: ds.batchProperties || [],
  //           }
  //         : undefined;
  //       const streamConfig: DataSourceDetailsPanelData['streamConfig'] = ds.underlyingStreamDataSourceType
  //         ? {
  //             type: ds.underlyingStreamDataSourceType,
  //             properties: ds.streamProperties || [],
  //           }
  //         : undefined;
  //       const sparkSchema: SparkField[] | undefined = ds.batchDataSource?.spark_schema?.fields;
  //       const result: DataSourceDetailsPanelData = {
  //         type: 'data_source',
  //         dataSourceType: ds.dataSourceType === 'Batch' ? 'batch' : 'stream',
  //         name: ds.name || dsId,
  //         description: ds.description || NO_DESCRIPTION,
  //         batchConfig,
  //         streamConfig,
  //         sparkSchema,
  //         downstreamNodes: ds.dependentFeatureViews.map((fvHash) => {
  //           const fvId = hashToId[fvHash];
  //           const fv = idToFco[fvId] as FeatureViewFCO;
  //           return {
  //             id: fvId,
  //             name: fv.name || fvId,
  //           };
  //         }),
  //       };
  //       return result;
  //     },
  //   });
};

const featureDataTypesMap: Record<string, TectonFeatureDataTypes> = {
  Bool: 'Bool',
  Int64: 'Int64',
  Float64: 'Float64',
  String: 'String',
  aggregation: 'aggregation',
};

const parseFeatures = (f: FeatureForFCO): FeatureColumnSummaryType => {
  // All this just to satisfy the Typescript Gods.
  // ... it's worth it.

  return {
    name: f.name,
    type: f.type !== null ? featureDataTypesMap[f.type] : 'String',
    aggregateAttributes:
      f.aggregateAttributes?.map((a) => {
        return {
          key: a.key || 'Unknown Key',
          value: a.value || 'Unknown Value',
        };
      }) || [],
  };
};

const parseTransformation = (transformation: TransformationFCO): TransformationAccordionEntry => {
  return {
    id: transformation.id,
    name: transformation.name,
    mode: transformation.mode,
    code: transformation.code,
  };
};

const useFeatureViewDetailsPanelData = (fvId: string) => {
  //   const { workspace } = useParams();
  //   return useGetFcos(workspace as string, {
  //     select: (data: WorkspaceFCOContainer) => {
  //       const idToFco = buildMapWithNameAsKey(data.idToFcoMap);
  //       const hashToId = buildHashToIdMap(data.idToFcoMap);
  //       const fv = idToFco[fvId] as FeatureViewFCO;
  //       const features: FeatureColumnSummaryType[] = fv.features.map(parseFeatures);
  //       const transformations: TransformationAccordionEntry[] = fv.allTransformations.map((transformationHash) => {
  //         const transformationId = hashToId[transformationHash];
  //         const transformation = idToFco[transformationId] as TransformationFCO;
  //         return parseTransformation(transformation);
  //       });
  //       const downstreamODFV: NodeIdentifier[] = data.featureViews
  //         .filter((n) => {
  //           if (!n.isRealtime) {
  //             return false;
  //           }
  //           const usesThisFV = n.dependentFeatureViews.find((inputNode) => {
  //             const innerNodeHash = inputNode.node.innerNodeId;
  //             if (!innerNodeHash) {
  //               return false;
  //             }
  //             const innerNodeId = hashToId[innerNodeHash];
  //             return innerNodeId === fvId;
  //           });
  //           return !!usesThisFV;
  //         })
  //         .map((odfv) => {
  //           const odfvId = idFormatter(odfv);
  //           return {
  //             id: odfvId,
  //             name: odfv.name || odfvId,
  //           };
  //         });
  //       const downstreamFS: NodeIdentifier[] = fv.featureServices.map((fsHash) => {
  //         const fsId = hashToId[fsHash];
  //         const fs = idToFco[fsId] as FeatureServiceFCO;
  //         return {
  //           id: fsId,
  //           name: fs.name || fsId,
  //         };
  //       });
  //       const downstreamNodes: NodeIdentifier[] = downstreamODFV.concat(downstreamFS);
  //       const result: FeatureViewDetailsPanelData = {
  //         type: 'feature_view',
  //         featureViewType: FeatureViewFCOType.BATCH,
  //         name: fv.name || fvId,
  //         onlineMaterialization: getMaterializationConfig(fv, 'online'),
  //         offlineMaterialization: getMaterializationConfig(fv, 'offline'),
  //         description: fv.description || NO_DESCRIPTION,
  //         features,
  //         transformations,
  //         upstreamNodes: fv.dataSourceIds.map((dsHash) => {
  //           const dsId = hashToId[dsHash];
  //           const ds = idToFco[dsId] as DataSourceFCO;
  //           return {
  //             id: dsId,
  //             name: ds.name || dsId,
  //           };
  //         }),
  //         downstreamNodes,
  //       };
  //       return result;
  //     },
  //   });
};

const useODFVDetailsPanelData = (fvId: string) => {
  //   const { workspace } = useParams();
  //   return useGetFcos(workspace as string, {
  //     select: (data: WorkspaceFCOContainer) => {
  //       const idToFco = buildMapWithNameAsKey(data.idToFcoMap);
  //       const hashToId = buildHashToIdMap(data.idToFcoMap);
  //       const fv = idToFco[fvId] as FeatureViewFCO;
  //       const features: FeatureColumnSummaryType[] = fv.features.map(parseFeatures);
  //       const transformations: TransformationAccordionEntry[] = fv.allTransformations.map((transformationHash) => {
  //         const transformationId = hashToId[transformationHash];
  //         const transformation = idToFco[transformationId] as TransformationFCO;
  //         return parseTransformation(transformation);
  //       });
  //       const requestDataSource = getRequestDataSourceFromODFV(fv);
  //       const upstreamNodes: NodeIdentifier[] = [];
  //       getDependentFeatureViewIds(fv, hashToId).forEach((id) => {
  //         const dependentFV = idToFco[id] as FeatureViewFCO;
  //         upstreamNodes.push({
  //           id,
  //           name: dependentFV.name || id,
  //         });
  //       });
  //       let schema: ODFVSchemaRow[] = [];
  //       if (requestDataSource) {
  //         fv.featureServices.forEach((fs) => {
  //           upstreamNodes.push({
  //             id: `REQ_` + fs,
  //             name: 'Request Data Source',
  //           });
  //         });
  //         const requestDataSource = collectSchemaFromRequestDataSources(fvId, idToFco);
  //         if (requestDataSource.schema) {
  //           schema = requestDataSource.schema;
  //         }
  //       }
  //       const result: ODFVDetailsPanelData = {
  //         type: 'odfv',
  //         name: fv.name || fvId,
  //         features,
  //         schema,
  //         transformations,
  //         description: fv.description || NO_DESCRIPTION,
  //         upstreamNodes,
  //         downstreamNodes: fv.featureServices.map((fsHash) => {
  //           const fsId = hashToId[fsHash];
  //           const fs = idToFco[fsId] as FeatureServiceFCO;
  //           return {
  //             id: fsId,
  //             name: fs.name || fsId,
  //           };
  //         }),
  //       };
  //       return result;
  //     },
  //   });
};

const collectOnDemandSchemaForFeatureService = (
  fs: FeatureServiceFCO,
  idToFcoMap: Record<string, FCO>,
  hashToId: Record<string, string>
) => {
  if (!fs) {
    throw new Error(`fs is undefined. This should not happen...`);
  }

  const odfvWithRequestDataSource = fs.allFeatureViews
    .map((fvHash) => hashToId[fvHash])
    .filter((fvId) => {
      const fv = idToFcoMap[fvId] as FeatureViewFCO;

      const requestDataSource = getRequestDataSourceFromODFV(fv);

      return !!requestDataSource;
    });

  return odfvWithRequestDataSource.map((fvId) => {
    return collectSchemaFromRequestDataSources(fvId, idToFcoMap);
  });
};

const useFeatureServiceContextData = (id: string) => {
  //const { workspace } = useParams();
  //   return useGetFcos(workspace as string, {
  //     select: (data: WorkspaceFCOContainer) => {
  //       const idToFco = buildMapWithNameAsKey(data.idToFcoMap);
  //       const hashToId = buildHashToIdMap(data.idToFcoMap);
  //       const fs = idToFco[id] as FeatureServiceFCO;
  //       const featureGroups: FeatureServiceFeatureGroup[] = fs.allFeatureViews.map((fvHash) => {
  //         const fvId = hashToId[fvHash];
  //         const fv = idToFco[fvId] as FeatureViewFCO;
  //         return {
  //           id: fvId,
  //           name: fv.name || fvId,
  //           features: fv.features.map(parseFeatures),
  //         };
  //       });
  //       const schemaList = collectOnDemandSchemaForFeatureService(fs, idToFco, hashToId);
  //       const result: FeatureServiceDetailsPanelData = {
  //         type: 'feature_service',
  //         name: fs.name || fs.id,
  //         description: fs.description || NO_DESCRIPTION,
  //         isOnlineServingEnabled: !!fs.isOnlineServingEnabled,
  //         featureGroups: featureGroups,
  //         schemaList,
  //         upstreamNodes: fs.allFeatureViews.map((fvHash) => {
  //           const fvId = hashToId[fvHash];
  //           const fv = idToFco[fvId] as FeatureViewFCO;
  //           return {
  //             id: fvId,
  //             name: fv.name || fvId,
  //           };
  //         }),
  //       };
  //       return result;
  //     },
  //   });
};

const useRequestDataSourceDetailsPanelData = (id: string) => {
  //   const { workspace } = useParams();

  if (id.indexOf('REQ_') !== 0) {
    throw new Error(
      `This ID '${id}' is not a Request Data Source ID. Expecting an ID that begins with 'REQ_' and ends with the Feature Service ID that it is embedded in.`
    );
  }

  const fsId = id.replace('REQ_', ''); // TODO: This is not terribly robust.

  //   return useGetFcos(workspace as string, {
  //     select: (data: WorkspaceFCOContainer) => {
  //       const idToFco = buildMapWithNameAsKey(data.idToFcoMap);
  //       const hashToId = buildHashToIdMap(data.idToFcoMap);

  //       const fs = idToFco[fsId] as FeatureServiceFCO;

  //       const downstreamNodes: NodeIdentifier[] = fs.allFeatureViews
  //         .map((fvHash) => hashToId[fvHash])
  //         .filter((fvId) => {
  //           const fv = idToFco[fvId] as FeatureViewFCO;

  //           const requestDataSource = getRequestDataSourceFromODFV(fv);

  //           return fv.isRealtime && !!requestDataSource;
  //         })
  //         .map((fvId) => {
  //           const fv = idToFco[fvId] as FeatureViewFCO;

  //           return {
  //             id: fvId,
  //             name: fv.name || fvId,
  //           };
  //         });

  //       const schemaList = collectOnDemandSchemaForFeatureService(fs, idToFco, hashToId);

  //       const result: RequestDataSourceDetailsPanelData = {
  //         type: 'request_data_source',
  //         featureServiceId: fs.id,
  //         featureServiceName: fs.name || fs.id,
  //         schemaList,
  //         downstreamNodes,
  //       };

  //       return result;
  //     },
  //   });
};

const useWorkspaceStoreDetailsPanelData = () => {
  //   const { workspace } = useParams();
  //   return useGetFcos(workspace as string, {
  //     select: (data: WorkspaceFCOContainer) => {
  //       const idToFco = buildMapWithNameAsKey(data.idToFcoMap);
  //       const hashToId = buildHashToIdMap(data.idToFcoMap);
  //       const featureViews = data.featureViews
  //         .filter((fv) => {
  //           return (
  //             fv.isRealtime === false &&
  //             (getMaterializationConfig(fv, 'online') || getMaterializationConfig(fv, 'offline'))
  //           );
  //         })
  //         .map((fv) => {
  //           const fvId = idFormatter(fv);
  //           return {
  //             id: fvId,
  //             name: fv.name || fvId,
  //             type: fv.featureViewType,
  //             isOnlineMaterializationEnabled: getMaterializationConfig(fv, 'online'),
  //             isOfflineMaterializationEnabled: getMaterializationConfig(fv, 'offline'),
  //           };
  //         });
  //       const featureServices = data.featureServices
  //         .filter((fs) => {
  //           const atLeastOneStoreConnection = fs.allFeatureViews.find((fvHash) => {
  //             const fvId = hashToId[fvHash];
  //             const fv = idToFco[fvId] as FeatureViewFCO;
  //             const isNotOnDemand = !fv.isRealtime;
  //             const hasDependentFvs = fv.hasDependentFvs;
  //             return isNotOnDemand || hasDependentFvs;
  //           });
  //           return !!atLeastOneStoreConnection;
  //         })
  //         .map((fs) => {
  //           const fsId = idFormatter(fs);
  //           return {
  //             id: fsId,
  //             name: fs.name || fsId,
  //           };
  //         });
  //       const ODFVs = data.featureViews
  //         .filter((fv) => {
  //           return fv.isRealtime && fv.dependentFeatureViews.length > 0;
  //         })
  //         .map((odfv) => {
  //           const odfvId = idFormatter(odfv);
  //           return {
  //             id: odfvId,
  //             name: odfv.name || odfvId,
  //           };
  //         });
  //       const result: WorkspaceStoreDetailsPanelData = {
  //         type: 'store',
  //         featureViews,
  //         featureServices,
  //         ODFVs,
  //       };
  //       return result;
  //     },
  //   });
};

export {
  useDataSourceDetailsPanelData,
  useFeatureViewDetailsPanelData,
  useODFVDetailsPanelData,
  useFeatureServiceContextData,
  useRequestDataSourceDetailsPanelData,
  useWorkspaceStoreDetailsPanelData,
};

export default false;
