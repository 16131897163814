import { AttributesList, Title } from '@tecton';
import React from 'react';
import { DataSourceDetailsPanelData } from './detailsPanelQueryTypes';
import { DataFlowSidePanel, DescriptionSection } from './StyledPanelComponents';
import DetailsPanelAccordionSection from './DetailsPanelAccordionSection';

const RawBatchSourceContextPanel = ({
  id,
  name,
  detailsPanelClearAll,
  zoomToNode,
  sourceType,
  data,
}: {
  id: string;
  name: string;
  detailsPanelClearAll: () => void;
  zoomToNode?: () => void;
  sourceType: string;
  data?: DataSourceDetailsPanelData;
}) => {
  return (
    <DataFlowSidePanel
      name={name}
      prehead={sourceType}
      nodeId={id}
      detailsPanelClearAll={detailsPanelClearAll}
      zoomToNode={zoomToNode}
    >
      {data && (
        <>
          <DescriptionSection description={`Batch source used in the ${data.name} data source.`} />

          <DetailsPanelAccordionSection
            id="batch-source-configurations"
            title={
              <Title size="xxs">
                <h4>{sourceType} Configurations</h4>
              </Title>
            }
            initialIsOpen
          >
            {data.batchConfig && data.batchConfig.properties.length > 0 ? (
              <AttributesList
                compressed
                listItems={data.batchConfig.properties.map(({ name, value }) => {
                  return {
                    title: name,
                    description: value || 'n/a',
                  };
                })}
              />
            ) : (
              <p>No Configurations</p>
            )}
          </DetailsPanelAccordionSection>
        </>
      )}
    </DataFlowSidePanel>
  );
};

export default RawBatchSourceContextPanel;
