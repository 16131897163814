import { FC, useState } from 'react';
import AccountTypeRadioOption from './AccountTypeRadioOption';
import CapabilityList from './CapabilityList';
import { RadioGroupOption } from './acl-types';
import { getRoleRadioGroupOptions } from './acl-util';

// generated
import { Option, Permission, ServiceAccount } from '../../../api/gql/graphql';

// @tecton
import { ComboBox, FlexGroupWrapper, FlexItem } from '@tecton/ComponentRedesign';
import { ACLModalFormWrapper } from './StyledComponents';

interface AddServiceAccountToWorkspaceProps {
  roles: RadioGroupOption[];
  roleDescriptionMap?: Record<string, Permission[]>;
  onChange: (roleName?: string, selectedServiceAccount?: ServiceAccount) => void;
  assignableServiceAccounts: ServiceAccount[];
}

const AddServiceAccountToWorkspace: FC<AddServiceAccountToWorkspaceProps> = ({
  roles,
  roleDescriptionMap,
  onChange,
  assignableServiceAccounts,
}) => {
  const permissions = roleDescriptionMap ? roleDescriptionMap[``] : [];

  // general state
  const [roleDescription, setRoleDescription] = useState<Permission[]>(permissions);
  const [options, setOptions] = useState<RadioGroupOption[]>(getRoleRadioGroupOptions(roles));
  const [selectedServiceAccount, setSelectedServiceAccount] = useState<ServiceAccount | undefined>();
  const [selectedRole, setSelectedRole] = useState<string | undefined>();
  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);

  if (assignableServiceAccounts.length === 0) {
    return <>To add another service account go to ‘Accounts & Access’ settings to create a new service account.</>;
  }

  const serviceAccountOptions =
    assignableServiceAccounts?.map((serviceAccount) => {
      return {
        label: serviceAccount.name ?? '',
        value: serviceAccount,
      };
    }) ?? [];

  return (
    <>
      <FlexGroupWrapper gap="xl" direction="column">
        <FlexItem>
          <ComboBox
            key="test-id-service-account-list"
            data-testid="test-id-service-account-list"
            placeholder="Select a ServiceAccount"
            singleSelection={{ asPlainText: true }}
            options={serviceAccountOptions}
            fullWidth={true}
            selectedOptions={selectedOptions}
            onChange={(selectedOptions) => {
              setSelectedOptions(selectedOptions);
              setSelectedServiceAccount(selectedOptions[0].value);
              onChange(selectedRole, selectedOptions[0].value);
            }}
          />
        </FlexItem>

        <FlexItem>
          <ACLModalFormWrapper>
            <FlexGroupWrapper gap="xl" direction="row">
              <FlexItem grow={8}>
                <AccountTypeRadioOption
                  options={options ?? []}
                  onSelection={(selectedOption) => {
                    const permissions = roleDescriptionMap ? roleDescriptionMap[`${selectedOption?.label}`] : [];
                    setOptions(getRoleRadioGroupOptions(roles, selectedOption?.label ?? ''));
                    setRoleDescription(permissions);
                    setSelectedRole(selectedOption?.label);
                    onChange(selectedOption?.label ?? '', selectedServiceAccount);
                  }}
                />
              </FlexItem>
              <FlexItem grow={6}>
                <CapabilityList roleDescriptions={roleDescription ?? []} />
              </FlexItem>
            </FlexGroupWrapper>
          </ACLModalFormWrapper>
        </FlexItem>
      </FlexGroupWrapper>
    </>
  );
};

export default AddServiceAccountToWorkspace;
