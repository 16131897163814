import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setPathStateAction } from '../../shared/actions';
import { getPathStateAction } from '../../shared/selectors';

/*
  setPathStateAction value is set in Redux. This is needed by WorkspaceSelector.js
  The value looks like this.
  {
    path(:"/repo/:workspace/transformations"
    params:"prod"
  }
  The workspace selector tries to be smart so when you change the workspace, it keeps you in the
  same URL and just changed the workspace. For example
  From
  http://localhost:3000/app/repo/prod/features
  to
  http://localhost:3000/app/repo/my-workpsace/features
*/

const useUpdatePatchState = (workspace: string | undefined) => {
  const dispatch = useDispatch();
  const pathState = useSelector((state) => getPathStateAction(state));
  const pathname = window.location.pathname;

  useEffect(() => {
    // General workspace route
    // Handle for any workspace routes
    if (workspace && !pathState.null) {
      const computedMatchPath = pathname.replace('/app', '').replace(workspace, ':workspace');
      // Needed to update workspace in URL from Components
      dispatch(setPathStateAction({ path: computedMatchPath, params: workspace }));
    }
  }, [workspace, pathname]);
};

export default useUpdatePatchState;
