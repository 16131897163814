import React from 'react';
import { CustomGraph, GraphPanel, GraphRenderType, GraphType } from './graph-types';
import PrometheusContainer from './PrometheusContainer';
import { MonitoringDateRange } from '../../components/monitoring/DateRange-Dropdown-util';
import GraphContainer from '../../components/monitoring/GraphContainer';
import MonitoringErrorBoundary from '../../components/monitoring/MonitoringErrorBoundary';
import GraphiteContainer from '../../components/monitoring/GraphiteContainer';
import _chunk from 'lodash/chunk';

const renderGraphByType = (
  graph: GraphType,
  customGraphRecord: Record<string, React.ReactNode>,
  monitoringDateRange: MonitoringDateRange,
  workspace: string,
  fcoId?: string,
  height?: number
) => {
  switch (graph?.renderType) {
    case GraphRenderType.CUSTOM:
      // Since customGraph can potentially be not there, we have a fall back to Unknown
      return customGraphRecord[`${graph?.customGraph ?? CustomGraph.UNKNOWN}`] ?? <></>;
    case GraphRenderType.PROMETHEUS:
      return (
        <div style={{ textAlign: 'center', padding: 10 }}>
          <MonitoringErrorBoundary>
            <PrometheusContainer
              monitoringDateRange={monitoringDateRange}
              graphMetadata={graph}
              workspace={workspace ?? ''}
              featureViewId={fcoId ?? ''}
              parseValue={graph.parseValue}
              height={height}
            />
          </MonitoringErrorBoundary>
        </div>
      );
    case GraphRenderType.GRAPHITE:
      return (
        <div style={{ textAlign: 'center', padding: 10 }}>
          <MonitoringErrorBoundary>
            <GraphiteContainer
              monitoringDateRange={monitoringDateRange}
              graphMetadata={graph}
              workspace={workspace ?? ''}
              featureViewId={fcoId ?? ''}
              height={height}
            />
          </MonitoringErrorBoundary>
        </div>
      );
  }
};

const MonitoringGraphs = ({
  monitoringDateRange,
  graphsData,
  customGraphRecord,
  workspace,
  fcoId,
}: {
  monitoringDateRange: MonitoringDateRange;
  graphsData: GraphPanel[];
  customGraphRecord: Record<string, React.ReactNode>;
  workspace: string;
  fcoId?: string;
}) => {
  return (
    <>
      {graphsData.map((graphPanel) => {
        const label = graphPanel.panelLabel.replaceAll('::workspace::', workspace ?? '');
        const graphChunks = _chunk(graphPanel.graphs, 2);
        const content = graphChunks?.map((graphChunk) => {
          if (graphChunk?.length === 2) {
            return (
              <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', padding: 0 }}>
                {graphChunk.map((graph) => {
                  return renderGraphByType(graph, customGraphRecord, monitoringDateRange, workspace, fcoId);
                }) ?? <></>}
              </div>
            );
          } else {
            return (
              <div style={{ padding: 20 }}>
                {graphChunk.map((graph) => {
                  return renderGraphByType(graph, customGraphRecord, monitoringDateRange, workspace, fcoId, 80);
                }) ?? <></>}
              </div>
            );
          }
        });
        return (
          <>
            <GraphContainer headerLabel={label}>{content}</GraphContainer>
          </>
        );
      })}
    </>
  );
};

export default MonitoringGraphs;
