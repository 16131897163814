import styled from '@emotion/styled';
import { Accordion, CodeBlock, Title, useTectonTheme } from '@tecton';
import React from 'react';
import { TransformationAccordionEntry } from './detailsPanelQueryTypes';
import DetailsPanelAccordionSection from './DetailsPanelAccordionSection';

const TransformationAccordion = ({ transformations }: { transformations: TransformationAccordionEntry[] }) => {
  const { theme } = useTectonTheme();
  const AccordionGroupWrapper = styled.div`
    .euiAccordion {
      padding: ${theme.size.xs} 0;
    }

    .euiAccordion:not(:last-of-type) {
      border-bottom: 1px solid ${theme.colors.lightShade};
    }

    .euiCodeBlock {
      margin: ${theme.size.s} 0 ${theme.size.m};
    }
  `;

  const TransformationMode = styled.span`
    color: ${theme.colors.mediumShade};
    text-decoration-color: ${theme.colors.mediumShade};
  `;

  return (
    <DetailsPanelAccordionSection
      id="transformations"
      title={
        <Title size="xxs">
          <h4>Transformations ({transformations.length})</h4>
        </Title>
      }
    >
      <AccordionGroupWrapper>
        {transformations.map(({ id, name, mode, code }) => {
          return (
            <Accordion
              id={id}
              arrowDisplay="right"
              buttonContent={
                <>
                  {name} <TransformationMode>{mode}</TransformationMode>
                </>
              }
            >
              <CodeBlock paddingSize="s">{code}</CodeBlock>
            </Accordion>
          );
        })}
      </AccordionGroupWrapper>
    </DetailsPanelAccordionSection>
  );
};

export default TransformationAccordion;
export type { TransformationAccordionEntry };
