import React, { createContext, useState, useContext } from 'react';
import {
  DataFlowEdge,
  DataFlowPathProperties,
  DataFlowTemplateType,
  NodesMapType,
  PathDataflowType,
} from './dataFlowTypes';

import edgeIdFn from './highlightLogic/edgeIdFn';
import { DetailsPanelContext } from './DetailsPanelFocusProvider';
import { getHighlightedNodesAndEdgesGivenFocusedNode, VisibleAnimationRecord } from './highlightLogic/highlight-utils';

type NodesInFocus = {
  legendInFocus: boolean;
  setLegendInFocus: (legendInFocus: boolean) => void;
  dataflowMode?: PathDataflowType[];
  setDataflowMode: (pathTypes: PathDataflowType[] | undefined) => void;
  hoveredNodeId?: string;
  linkedIds?: Set<string>;
  setFocusedNodeId: (id?: string) => void;
  shouldNodeBeVisible: (id: string, dataflowMode?: PathDataflowType) => boolean;
  shouldEdgeBeVisible: (
    source: string,
    target: string,
    pathProperties: DataFlowPathProperties,
    dataflowMode?: PathDataflowType
  ) => VisibleAnimationRecord | false;
};

const SHOW_ALL_ANIMATIONS: VisibleAnimationRecord = {
  showBatchMaterializationPath: true,
  showStreamMaterializationPath: true,
  showOfflineReadPath: true,
  showOnlineServingPath: true,
};

const DataFlowFocusContext = createContext<NodesInFocus>({
  legendInFocus: false,
  setLegendInFocus: (_legendInFocus: boolean) => {
    return;
  },
  dataflowMode: undefined,
  setDataflowMode: () => {
    return;
  },
  setFocusedNodeId: (_id?: string) => {
    return;
  },
  shouldNodeBeVisible: () => true,
  shouldEdgeBeVisible: () => {
    return SHOW_ALL_ANIMATIONS;
  },
  hoveredNodeId: undefined,
  linkedIds: undefined,
});

const shouldBeHighlighted = (
  dataflowMode: PathDataflowType[],
  pathProperties: DataFlowEdge['pathProperties'] | undefined
) => {
  if (!pathProperties) {
    return false;
  }

  return dataflowMode.find((mode) => {
    return pathProperties[mode];
  });
};

const getHighlightsForDataflowMode = (
  dataflowMode: PathDataflowType[],
  edgesList: DataFlowEdge[],
  nodesMap: NodesMapType
) => {
  const setOfNodes = new Set<string>();
  const setOfEdges = new Set<string>();

  edgesList.forEach((edge) => {
    if (shouldBeHighlighted(dataflowMode, edge.pathProperties)) {
      setOfNodes.add(edge.source);
      setOfNodes.add(edge.target);

      const target = nodesMap[edge.target];

      if (target.type === 'feature_service' && target.hasRequestDataSource) {
        setOfNodes.add(`REQ_${target.id}`);
      }

      setOfEdges.add(edgeIdFn(edge));
    }
  });

  return {
    nodes: setOfNodes,
    edges: setOfEdges,
  };
};

const defaultVisibility = (pathProperties: DataFlowPathProperties): VisibleAnimationRecord => {
  const result: VisibleAnimationRecord = {};

  if (pathProperties.isBatchMaterializationPath) {
    result.showBatchMaterializationPath = true;
  }

  if (pathProperties.isStreamMaterializationPath) {
    result.showStreamMaterializationPath = true;
  }

  if (pathProperties.isOnlineServingPath) {
    result.showOnlineServingPath = true;
  }

  if (pathProperties.isOfflineReadPath) {
    result.showOfflineReadPath = true;
  }

  return result;
};

const DISABLE_HIGHLIGHTING_IN_FV_LEVEL_TEMPLATES = true;

const DataFlowFocusProvider = ({
  templateType,
  children,
}: {
  templateType: DataFlowTemplateType;
  children: React.ReactNode;
}) => {
  const [focusObj, setFocusObj] = useState<
    | {
        hoveredNodeId: string;
        linkedIds: Set<string>;
        linkedEdges: Set<string>;
        animations?: Record<string, VisibleAnimationRecord>;
      }
    | undefined
  >();

  const [legendInFocus, setLegendInFocus] = useState<boolean>(false);

  const [dataflowMode, setDataflowMode] = useState<PathDataflowType[] | undefined>();

  const { nodesMap, edgesList } = useContext(DetailsPanelContext);

  const highlightedObjectsGivenMode = dataflowMode
    ? getHighlightsForDataflowMode(dataflowMode, edgesList, nodesMap)
    : undefined;

  /**
   * Given the ID of a node that's being hovered over,
   * what set of related nodes need to be highlighted?
   *
   * There is a lot of special casing here unfortunately.
   */
  const setFocusedNodeId = (id?: string) => {
    if (id === undefined) {
      setFocusObj(undefined);
      setDataflowMode(undefined);
      return;
    }

    if (
      DISABLE_HIGHLIGHTING_IN_FV_LEVEL_TEMPLATES &&
      (templateType === 'materializing_fv' || templateType === 'ondemand_fv')
    ) {
      // If we are in the materializing_fv or ondemand_fv template, we don't want to highlight anything
      // for now. We can revisit this later.
      setFocusObj(undefined);
      setDataflowMode(undefined);
      return;
    }

    const focusedNode = nodesMap[id];

    const { linkedIds, linkedEdges, dataflowModes, animations } = getHighlightedNodesAndEdgesGivenFocusedNode(
      focusedNode,
      nodesMap,
      edgesList
    );

    setFocusObj({
      hoveredNodeId: id,
      linkedIds,
      linkedEdges,
      animations,
    });

    setDataflowMode(dataflowModes);
  };

  const shouldNodeBeVisible = (id: string) => {
    if (
      DISABLE_HIGHLIGHTING_IN_FV_LEVEL_TEMPLATES &&
      (templateType === 'materializing_fv' || templateType === 'ondemand_fv')
    ) {
      // If we are in the materializing_fv or ondemand_fv template, we don't want to highlight anything
      // for now. We can revisit this later.
      return true;
    }

    if (!focusObj) {
      if (!highlightedObjectsGivenMode) {
        return true;
      } else {
        return highlightedObjectsGivenMode.nodes.has(id);
      }
    }

    const { hoveredNodeId, linkedIds } = focusObj;

    return !hoveredNodeId || hoveredNodeId === id || linkedIds?.has(id);
  };

  const shouldEdgeBeVisible = (
    source: string,
    target: string,
    pathProperties: DataFlowPathProperties
  ): VisibleAnimationRecord | false => {
    if (
      DISABLE_HIGHLIGHTING_IN_FV_LEVEL_TEMPLATES &&
      (templateType === 'materializing_fv' || templateType === 'ondemand_fv')
    ) {
      // If we are in the materializing_fv or ondemand_fv template, we just do the default visibility
      // for now. We can revisit this later.
      return defaultVisibility(pathProperties);
    }

    const edgeId = edgeIdFn({ source, target });

    /**
     * If there is a particular data flow mode being highlighted
     * then we simply check the highlighted set of objects
     */
    if (highlightedObjectsGivenMode && !focusObj) {
      const animations: VisibleAnimationRecord = {};

      dataflowMode?.forEach((key) => {
        if (key === 'isOnlineServingPath') {
          animations.showOnlineServingPath = true;
        }

        if (key === 'isOfflineReadPath') {
          animations.showOfflineReadPath = true;
        }

        if (key === 'isBatchMaterializationPath') {
          animations.showBatchMaterializationPath = true;
        }

        if (key === 'isStreamMaterializationPath') {
          animations.showStreamMaterializationPath = true;
        }
      });

      return highlightedObjectsGivenMode.edges.has(edgeId) ? animations : false;
    }

    /**
     * If there is no focusObj, and there isn't a particular
     * dataflow mode being highlighted, then what's visible is
     * determined by the aggregated pathProperties
     */
    if (!focusObj) {
      return defaultVisibility(pathProperties);
    }

    const { animations } = focusObj;

    return animations ? animations[edgeId] : false;
  };

  return (
    <DataFlowFocusContext.Provider
      value={{
        ...focusObj,
        legendInFocus,
        setLegendInFocus,
        dataflowMode,
        setDataflowMode,
        setFocusedNodeId,
        shouldNodeBeVisible,
        shouldEdgeBeVisible,
      }}
    >
      {children}
    </DataFlowFocusContext.Provider>
  );
};

export default DataFlowFocusProvider;
export { DataFlowFocusContext };
