import React, { FC } from 'react';
import { TableContainer, Table, TableBody } from '@mui/material';
import { WorkspaceWithPermissions } from '../../../../components/context/types';
import WorkspaceSelectionTableRow from './WorkspaceSelectionTableRow';

interface WorkspaceSelectionTableProperties {
  data: WorkspaceWithPermissions[];
}

const WorkspaceSelectionTable: FC<WorkspaceSelectionTableProperties> = ({ data }) => {
  return (
    <>
      <TableContainer sx={{ marginBottom: '24px', marginTop: '36px' }}>
        <Table>
          <TableBody>
            {data.map((workspace: WorkspaceWithPermissions, index: number) => {
              return <WorkspaceSelectionTableRow key={index} workspace={workspace} />;
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default WorkspaceSelectionTable;
