import React, { useEffect } from 'react';
import { logEvent } from '../../../utils/analytics-utils';
import { NavLink, useParams } from 'react-router-dom';
import { LoadingIndicator } from '../../../components/LoadingIndicator';
import { replaceWorkspace } from '../../../utils/route-utils';
import FeaturesTableView from './FeaturesTableView';
import Title from '../../../utils/title-utils';
import { extractFeatures, fcosToMapByKey } from '../../../utils/filter-utils';
import { Routes } from '../../../core/routes';
import { useFcoContainer } from '../../query';
import { isNavigationActiveCallback } from '../../../utils/NavLink-Utils';
import { ErrorBoundary } from '@tecton';

const Features = () => {
  const { workspace } = useParams();
  const fcoContainer = useFcoContainer(workspace);

  useEffect(() => {
    logEvent('View Features');
  }, []);

  if (fcoContainer.isLoading) {
    return <LoadingIndicator />;
  }

  const featureViews = fcoContainer.data.allFeatureViews;
  const entities = fcoContainer.data.allEntities;

  const idToEntityMap = fcosToMapByKey(entities, 'id');
  const features = extractFeatures(featureViews, idToEntityMap);

  return (
    <div id="content" className="content-shadow overflow-y-scroll h-100">
      <Title title="All Features" />
      <div className="content-head">
        <div>
          <div className="f3 fw7 mb3">Features</div>
          <div className="tabs" data-testid="tabs">
            <NavLink
              to={replaceWorkspace(Routes.featureViews, workspace)}
              end={true}
              className={isNavigationActiveCallback}
            >
              Feature Views and Tables
            </NavLink>
            <NavLink
              to={replaceWorkspace(Routes.features, workspace)}
              end={true}
              className={isNavigationActiveCallback}
            >
              All Features
            </NavLink>
            <NavLink
              to={replaceWorkspace(Routes.featureMonitoringSummary, workspace)}
              end={true}
              className={isNavigationActiveCallback}
            >
              Monitoring Summary
            </NavLink>
          </div>
        </div>
      </div>
      <div className="pa5 " data-testid="tabs-content">
        <ErrorBoundary>
          <FeaturesTableView features={features} title={null} workspace={workspace} />
        </ErrorBoundary>
      </div>
    </div>
  );
};

export default Features;
