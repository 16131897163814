import { min } from 'd3-array';
import moment from 'moment';
import { FC } from 'react';

import { useGetFcos } from '../../../api/fcos';
import { FCOFields, FeatureViewFCO } from '../../../core/types/fcoTypes';
import { useUserSettings } from '../../context/UserSettingsContext';
import ValidationsFilterProvider from './FiltersContext';
import { ValidationsContextProvider } from './ValidationsContext';
import ValidationsControls from './ValidationsControls';
import ValidationsTable from './ValidationsTable';

import { Breadcrumbs, HeaderLayout, Loading, Spacer, ViewLayout } from '@tecton/ComponentRedesign';
import WorkspaceRootBreadcrumb from '../WorkspaceRootBreadcrumb';

const ValidationsContainer: FC = () => {
  const { workspace, workspaceDetails } = useUserSettings();

  const { data, isLoading } = useGetFcos(workspace ?? '', {});

  if (isLoading) {
    return <Loading />;
  }

  const earliestStartDate = min(
    data.featureViews.map((featureView: FeatureViewFCO) => {
      return new Date(featureView[FCOFields.CREATED_DATE]!);
    })
  );

  const endDate = moment();

  const header = (
    <HeaderLayout
      breadcrumbs={
        <Breadcrumbs
          workspace={<WorkspaceRootBreadcrumb workspace={workspaceDetails} />}
          crumbs={[{ label: 'Data Quality' }]}
        />
      }
    />
  );

  return (
    <ViewLayout
      header={header}
      body={
        <ValidationsContextProvider dateRange={[moment(earliestStartDate), endDate]}>
          <ValidationsFilterProvider>
            <ValidationsControls />
            <Spacer size="m" />
            <ValidationsTable />
          </ValidationsFilterProvider>
        </ValidationsContextProvider>
      }
    />
  );
};

export default ValidationsContainer;
