import { FC, useState } from 'react';
import AccountTypeRadioOption from './AccountTypeRadioOption';
import CapabilityList from './CapabilityList';
import { RadioGroupOption } from './acl-types';
import { getRoleRadioGroupOptions } from './acl-util';

// generated
import { Option, Permission, User } from '../../../api/gql/graphql';

// @tecton
import { ComboBox, FlexGroupWrapper, FlexItem } from '@tecton/ComponentRedesign';
import { ACLModalFormWrapper } from './StyledComponents';

interface AddUserToWorkspaceProps {
  roles: RadioGroupOption[];
  roleDescriptionMap?: Record<string, Permission[]>;
  onChange: (roleName?: string, selectedUser?: User) => void;
  assignableUsers: User[];
}

const AddUserToWorkspace: FC<AddUserToWorkspaceProps> = ({ roles, roleDescriptionMap, onChange, assignableUsers }) => {
  const permissions = roleDescriptionMap ? roleDescriptionMap[``] : [];

  // general state
  const [roleDescription, setRoleDescription] = useState<Permission[]>(permissions);
  const [options, setOptions] = useState<RadioGroupOption[]>(getRoleRadioGroupOptions(roles));
  const [selectedUser, setSelectedUser] = useState<User | undefined>();
  const [selectedRole, setSelectedRole] = useState<string | undefined>();
  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);

  if (assignableUsers.length === 0) {
    return <>To add another user go to ‘Accounts & Access’ settings to create a new user.</>;
  }

  const userOptions = assignableUsers?.map((user) => {
    return {
      label: user.loginEmail ?? '',
      value: user,
    };
  });

  return (
    <>
      <FlexGroupWrapper gap="xl" direction="column">
        <FlexItem>
          <ComboBox
            key="test-id-service-account-list"
            data-testid="test-id-service-account-list"
            placeholder="Select a User"
            singleSelection={{ asPlainText: true }}
            options={userOptions}
            fullWidth={true}
            selectedOptions={selectedOptions}
            onChange={(selectedOptions) => {
              setSelectedOptions(selectedOptions);
              setSelectedUser(selectedOptions[0].value);
              onChange(selectedRole, selectedOptions[0].value);
            }}
          />
        </FlexItem>
        <FlexItem>
          <ACLModalFormWrapper>
            <FlexGroupWrapper gutterSize="xl" direction="row">
              <FlexItem grow={8}>
                <AccountTypeRadioOption
                  options={options ?? []}
                  onSelection={(selectedOption) => {
                    const permissions = roleDescriptionMap ? roleDescriptionMap[`${selectedOption?.label}`] : [];
                    setOptions(getRoleRadioGroupOptions(roles, selectedOption?.label ?? ''));
                    setRoleDescription(permissions);
                    setSelectedRole(selectedOption?.label);
                    onChange(selectedOption?.label ?? '', selectedUser);
                  }}
                />
              </FlexItem>
              <FlexItem grow={6}>
                <CapabilityList roleDescriptions={roleDescription ?? []} />
              </FlexItem>
            </FlexGroupWrapper>
          </ACLModalFormWrapper>
        </FlexItem>
      </FlexGroupWrapper>
    </>
  );
};

export default AddUserToWorkspace;
