import { Grid } from '@mui/material';
import React, { FC, PropsWithChildren } from 'react';

interface CenterContentProps {
  minHeight: number;
  minWidth: number;
}

const CenterContent: FC<PropsWithChildren<CenterContentProps>> = ({ minHeight, minWidth, children }) => {
  return (
    <div className="pa4" style={{ height: '100%', width: '100%', minHeight: minHeight, minWidth: minHeight }}>
      <Grid container direction="column" alignItems="center" justifyContent="center" style={{ height: 228 }}>
        {children}
      </Grid>
    </div>
  );
};

export default CenterContent;
