import React, { FC } from 'react';
import { Position } from '@xyflow/react';
import {
  DataSourceNode,
  EmbeddingModelNode,
  FeatureServiceNode,
  FeatureViewNode,
  ODFVNode,
  TransformationNode,
} from './dataFlowTypes';
import DataFlowFCONodeTitle from './DataflowFCONodeTitle';
import DataFlowFCONodeName from './DataFlowFCONodeName';
import DataFlowFCONodeContainer from './DataFlowFCONodeContainer';
import DataFlowFCOCountBadge from './DataFlowFCOCountBadge';
import { ColoredFCOTypes } from './DataFlowConstants';
import { DataFlowOpacityWrapper } from './DataFlowStyledComponents';
import styled from '@emotion/styled';
import DataFlowHandle from './DataFlowHandle';
import { Icons, IconTypes, useTectonTheme } from '@tecton/ComponentRedesign';
import { Routes as AppRoutes } from '../../../../core/routes';
import { Link } from 'react-router-dom';

const routeMap: Record<ColoredFCOTypes, string> = {
  data_source: AppRoutes.dataSource,
  feature_view: AppRoutes.featureView,
  feature_service: AppRoutes.featureService,
  request_data_source: AppRoutes.dataSource, // This might be transformation
  odfv: AppRoutes.featureView,
  transformation: AppRoutes.transformation,
  embedding: AppRoutes.featureView,
};

const getUrlForFcoNode = (fcoType: ColoredFCOTypes, name: string, workspace: string) => {
  return routeMap[fcoType]
    .replace(':workspace', workspace ?? '')
    .replace(':name', name)
    .replace('/*', '');
};

const CardIconDiv = styled.div<{
  fcoType?: ColoredFCOTypes;
  isUnused?: boolean;
  denseMode?: boolean;
}>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IconWrapper = styled.div<{
  fcoType?: ColoredFCOTypes;
  isUnused?: boolean;
  denseMode?: boolean;
  backgroundColorMap: Record<string, string>;
  foregroundColorMap: Record<string, string>;
}>`
  background-color: ${({ fcoType, backgroundColorMap, theme }) =>
    fcoType ? backgroundColorMap[fcoType] : theme.colors.lightestShade};
  height: ${(props) => (props.denseMode ? '24px' : '36px')};
  width: ${(props) => (props.denseMode ? '24px' : '36px')};
  border-radius: ${({ theme }) => theme.border.radius.small};
  display: flex;
  align-items: center;
  justify-content: center;

  ellipse,
  path,
  circle,
  rect {
    stroke: ${({ fcoType, foregroundColorMap, theme }) =>
      fcoType ? foregroundColorMap[fcoType] : theme.colors.fullShade};
  }
`;

export const DataFlowFCOIcon = ({
  fcoType,
  isUnused,
  denseMode,
}: {
  fcoType?: ColoredFCOTypes;
  isUnused?: boolean;
  denseMode?: boolean;
}) => {
  const { theme } = useTectonTheme();

  const backgroundColorMap: Record<ColoredFCOTypes, string> = {
    feature_view: theme.FCOTintColors.featureView,
    request_data_source: theme.FCOTintColors.source,
    feature_service: theme.FCOTintColors.featureService,
    odfv: theme.FCOTintColors.featureView,
    data_source: theme.FCOTintColors.source,
    transformation: theme.FCOTintColors.transformation,
    embedding: theme.FCOTintColors.transformation,
  };

  const foregroundColorMap: Record<ColoredFCOTypes, string> = {
    feature_view: theme.FCOColors.featureView,
    request_data_source: theme.FCOColors.source,
    feature_service: theme.FCOColors.featureService,
    odfv: theme.FCOColors.featureView,
    data_source: theme.FCOColors.source,
    transformation: theme.FCOColors.transformation,
    embedding: theme.FCOColors.transformation,
  };

  const fcoTypeMap: Record<ColoredFCOTypes, IconTypes> = {
    data_source: IconTypes.SOURCES,
    request_data_source: IconTypes.SOURCES,
    feature_view: IconTypes.FEATURE_VIEWS,
    odfv: IconTypes.FEATURE_VIEWS,
    feature_service: IconTypes.FEATURE_SERVICE,
    transformation: IconTypes.TRANSFORMATIONS,
    embedding: IconTypes.EMBEDDING_MODEL,
  };

  return (
    <CardIconDiv fcoType={fcoType} isUnused={isUnused} denseMode={denseMode}>
      <IconWrapper
        backgroundColorMap={backgroundColorMap}
        foregroundColorMap={foregroundColorMap}
        fcoType={fcoType}
        denseMode={denseMode}
      >
        {fcoType && Icons[fcoTypeMap[fcoType]]}
      </IconWrapper>
    </CardIconDiv>
  );
};

interface DataFlowFCONodeProps {
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  onClick: (event: React.MouseEvent) => void;
  hasFocus: boolean;
  isFaded: boolean;
  DEBUG_MODE?: boolean;
  data: DataSourceNode | FeatureViewNode | ODFVNode | FeatureServiceNode | TransformationNode | EmbeddingModelNode;
  fcoType: ColoredFCOTypes;
  denseMode?: boolean;
  workspace?: string;
}

const DataFlowNameTitleWrapper = styled.div`
  display: flex;
  align-items: space-around;
  justify-content: center;
  padding: 0px ${({ theme }) => theme.padding.s};
  flex-direction: column;
  width: 147px;
`;

const DataFlowFCONode: FC<DataFlowFCONodeProps> = ({
  onMouseEnter,
  onMouseLeave,
  hasFocus,
  isFaded,
  DEBUG_MODE,
  data,
  fcoType,
  denseMode,
  workspace,
}) => {
  if (denseMode) {
    return (
      <>
        <Link to={getUrlForFcoNode(fcoType, data.name, workspace ?? '')}>
          <DataFlowOpacityWrapper isFaded={isFaded} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <DataFlowFCONodeContainer
              hasFocus={hasFocus}
              fcoType={fcoType}
              hasRequestDataSource={(data as FeatureServiceNode).hasRequestDataSource || false}
              denseMode
            >
              <DataFlowHandle position={Position.Left} top={'18px'} />

              <div>
                <DataFlowFCOIcon fcoType={fcoType} denseMode={denseMode} />
              </div>
              <DataFlowNameTitleWrapper>
                <DataFlowFCONodeName denseMode name={DEBUG_MODE ? data.id : data.name} />
              </DataFlowNameTitleWrapper>
              <div>
                <DataFlowFCOCountBadge data={data} />
              </div>
              <DataFlowHandle position={Position.Right} top={'18px'} />
            </DataFlowFCONodeContainer>
          </DataFlowOpacityWrapper>
        </Link>
      </>
    );
  }

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <Link to={getUrlForFcoNode(fcoType, data.name, workspace ?? '')}>
        <DataFlowOpacityWrapper isFaded={isFaded}>
          <DataFlowFCONodeContainer
            hasFocus={hasFocus}
            isFaded={isFaded}
            fcoType={fcoType}
            hasRequestDataSource={(data as FeatureServiceNode).hasRequestDataSource || false}
          >
            <div>
              <DataFlowFCOIcon fcoType={fcoType} />
            </div>
            <DataFlowNameTitleWrapper>
              <DataFlowFCONodeTitle data={data} />
              <DataFlowFCONodeName name={DEBUG_MODE ? data.id : data.name} />
            </DataFlowNameTitleWrapper>
            <div>
              <DataFlowFCOCountBadge data={data} />
            </div>
            <DataFlowHandle position={Position.Left} top={'32px'} />
            <DataFlowHandle position={Position.Right} top={'32px'} />
          </DataFlowFCONodeContainer>
        </DataFlowOpacityWrapper>
      </Link>
    </div>
  );
};

export default DataFlowFCONode;
