import MetadataService from '../../../service/MetadataService';
import { useQuery } from '@tanstack/react-query';

export function useFeatureService(workspace, name) {
  const state = useQuery(
    workspace ? ['workspace', workspace, 'featureService', name] : ['featureService', name],
    () => {
      return MetadataService.sharedInstance().getFeatureService(workspace, name);
    }
  );
  if (state.error) {
    throw state.error;
  }
  return state;
}

export function useDebugFeatureServiceById(workspace, id) {
  const state = useQuery(workspace ? ['workspace', workspace, 'featureService', id] : ['featureService', id], () => {
    return MetadataService.sharedInstance().getFeatureService(workspace, undefined, id);
  });
  if (state.error) {
    throw state.error;
  }
  return state;
}

export function useFeatureServiceSummary(workspace, name) {
  const state = useQuery(['workspace', workspace, 'featureServiceSummary', name], () => {
    const data = MetadataService.sharedInstance().getFeatureServiceSummary(name);
    return data;
  });
  if (state.error) {
    throw state.error;
  }
  return state;
}

export function useFeatureServiceServingStatus(id, page, per_page) {
  const state = useQuery(['featureViewServingStatus', id, page, per_page], () => {
    return MetadataService.sharedInstance().getFeatureServiceServingStatus(id, page, per_page);
  });
  if (state.error) {
    throw state.error;
  }
  return state;
}

export function useMDSConfigs() {
  const state = useQuery(['mdsConfigs'], () => {
    const data = MetadataService.sharedInstance().getConfigs();

    return data;
  });
  if (state.error) {
    throw state.error;
  }

  return state;
}
