import { FC } from 'react';
import { FeatureViewFCO, FeatureViewFCOFields } from '../../../core/types/fcoTypes';
import { NewClusterConfig } from '../../../types/tecton_proto/args/feature_view';
import { convertValueToHumanReadableValue } from '../../featureViews/featureViewUtil';

import { ClusterConfigType } from '../../../utils/feature-view/feature-view-util';

import { EmptyValue, FCOCard, ViewDefinitionFlyout } from '@tecton/ComponentRedesign';
import TectonFCOCard, { TectonFCOListItem } from '@tecton/ComponentRedesign/FCOCard';
import { prettifyJson } from '@tecton/ComponentRedesign/utils';
import { useUserSettings } from '../../context/UserSettingsContext';
import SparkConfigFlyout from './SparkConfigFlyout';

interface MaterializationStreamConfigurationProps {
  fco: FeatureViewFCO;
}

const ExistingClusterCard: FC<MaterializationStreamConfigurationProps> = () => {
  return <></>;
};

const NewDatabricksClusterCard: FC<MaterializationStreamConfigurationProps> = ({ fco }) => {
  const config = fco[FeatureViewFCOFields.STREAM_CLUSTER_CONFIG]?.clusterConfig as NewClusterConfig;

  const availability =
    config?.instance_availability && convertValueToHumanReadableValue(config?.instance_availability) ? (
      convertValueToHumanReadableValue(config?.instance_availability)
    ) : (
      <EmptyValue />
    );

  const items: TectonFCOListItem[] = [
    {
      title: 'Instance Type',
      content: config.instance_type ?? <EmptyValue />,
    },
    {
      title: 'Instance Availability',
      content: availability,
    },
    {
      title: 'Number of Workers',
      content: config?.number_of_workers ?? <EmptyValue />,
    },
    {
      title: 'First On-Demand',
      content: config?.first_on_demand ?? <EmptyValue />,
    },
    {
      title: 'Root Volume Size in (gb)',
      content: config?.root_volume_size_in_gb ?? <EmptyValue />,
    },
    {
      // Spark Config is only for batch. We don't show any other key/value when we have
      // This show the flyout for originalJson vs outputJSON
      // example: https://depop-production.tecton.ai/app/repo/prod/features/user_stripe_info/materialization
      title: 'Extra pip Dependencies',
      content: config?.extra_pip_dependencies ? (
        <ViewDefinitionFlyout
          type={'Extra pip Dependencies'}
          definition={config?.extra_pip_dependencies?.join('\n') ?? ''}
        />
      ) : (
        <EmptyValue />
      ),
    },
    {
      // https://movjd-cashapp.tecton.ai/app/repo/weaver/features/discover_qab_sender_candidates_view:v1/materialization
      title: 'Spark Config',
      content: config?.spark_config ? (
        <ViewDefinitionFlyout type="Spark Config" definition={prettifyJson(config?.spark_config)} />
      ) : (
        <EmptyValue />
      ),
    },
    {
      title: 'Pinned Spark Version',
      content: config?.pinned_spark_version ?? <EmptyValue />,
    },
  ];

  return <FCOCard title="Stream Configuration" items={items} />;
};

const NewEmrClusterCard: FC<MaterializationStreamConfigurationProps> = ({ fco }) => {
  const config = fco[FeatureViewFCOFields.STREAM_CLUSTER_CONFIG]?.clusterConfig as NewClusterConfig;

  const availability =
    config?.instance_availability && convertValueToHumanReadableValue(config?.instance_availability) ? (
      convertValueToHumanReadableValue(config?.instance_availability)
    ) : (
      <EmptyValue />
    );

  const items: TectonFCOListItem[] = [
    {
      title: 'Instance Type',
      content: config.instance_type ?? <EmptyValue />,
    },
    {
      title: 'Instance Availability',
      content: availability,
    },
    {
      title: 'Number of Workers',
      content: config?.number_of_workers ?? <EmptyValue />,
    },
    {
      title: 'First On-Demand',
      content: config?.first_on_demand ?? <EmptyValue />,
    },
    {
      title: 'Root Volume Size in (gb)',
      content: config?.root_volume_size_in_gb ?? <EmptyValue />,
    },
    {
      // Spark Config is only for batch. We don't show any other key/value when we have
      // This show the flyout for originalJson vs outputJSON
      // example: https://depop-production.tecton.ai/app/repo/prod/features/user_stripe_info/materialization
      title: 'Extra pip Dependencies',
      content: config?.extra_pip_dependencies ? (
        <ViewDefinitionFlyout
          type={'Extra pip Dependencies'}
          definition={config?.extra_pip_dependencies?.join('\n') ?? ''}
        />
      ) : (
        <EmptyValue />
      ),
    },
    {
      // https://movjd-cashapp.tecton.ai/app/repo/weaver/features/discover_qab_sender_candidates_view:v1/materialization
      title: 'Spark Config',
      content: config?.spark_config ? (
        <ViewDefinitionFlyout type="Spark Config" definition={prettifyJson(config?.spark_config)} />
      ) : (
        <EmptyValue />
      ),
    },
    {
      title: 'EMR Version',
      content: config?.pinned_spark_version ?? <EmptyValue />,
    },
  ];

  return <FCOCard title="Stream Configuration" items={items} />;
};

const ImplicitConfigClusterCard: FC<MaterializationStreamConfigurationProps> = () => {
  return <></>;
};

const JsonDatabricksClusterCard: FC<MaterializationStreamConfigurationProps> = ({ fco }) => {
  const { workspace } = useUserSettings();
  const items = [
    {
      title: 'Spark Config',
      content: (
        <>
          <SparkConfigFlyout workspace={workspace ?? 'prod'} fco={fco} configType="stream" />
        </>
      ),
    },
  ];
  return <TectonFCOCard title="Stream Configuration" items={items} />;
};

const JsonEmrClusterCard: FC<MaterializationStreamConfigurationProps> = ({ fco }) => {
  return <></>;
};

const JsonDataProcClusterCard: FC<MaterializationStreamConfigurationProps> = () => {
  return <></>;
};

const TectonBatchComputeClusterCard: FC<MaterializationStreamConfigurationProps> = () => {
  return <></>;
};

const MaterializationStreamConfigurationCard: FC<MaterializationStreamConfigurationProps> = ({ fco }) => {
  // Based on this notion doc: https://www.notion.so/tecton/Feature-View-Materialization-Card-f2d4de1e58414decb5d6a2e237371f75
  const configuration = fco[FeatureViewFCOFields.STREAM_CLUSTER_CONFIG];
  switch (configuration?.type) {
    case ClusterConfigType.EXISTING_CLUSTER:
      return <ExistingClusterCard fco={fco} />;
    case ClusterConfigType.NEW_DATABRICKS:
      return <NewDatabricksClusterCard fco={fco} />;
    case ClusterConfigType.NEW_EMR:
      return <NewEmrClusterCard fco={fco} />;
    case ClusterConfigType.IMPLICIT_CONFIG:
      return <ImplicitConfigClusterCard fco={fco} />;
    case ClusterConfigType.JSON_DATABRICKS:
      return <JsonDatabricksClusterCard fco={fco} />;
    case ClusterConfigType.JSON_EMR:
      return <JsonEmrClusterCard fco={fco} />;
    case ClusterConfigType.JSON_DATAPROC:
      return <JsonDataProcClusterCard fco={fco} />;
    case ClusterConfigType.RIFT:
      return <TectonBatchComputeClusterCard fco={fco} />;
  }

  return <></>;
};

export default MaterializationStreamConfigurationCard;
