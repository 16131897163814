import { getVisibleAnimationsOnAllEdgesGivenAnchors } from './highlight-utils';
import highlightDownstream from './highlightDownstream';
import { EdgeShouldBeSkippedFunctionSignature, HighlightFunctionType } from './highlightFunctionType';

const highlightRawStreamNode: HighlightFunctionType = (id, nodesMap, edgesList) => {
  const focusedNode = nodesMap[id];

  if (focusedNode.type !== 'raw_stream_node') {
    throw new Error(
      'This should not happen. We are in the highlightRawStreamNode function but the node is not a raw_stream_node.'
    );
  }

  const skipDependenciesThatCrossesTheStore: EdgeShouldBeSkippedFunctionSignature = (sourceNode, targetNode) => {
    if (targetNode.type === 'aggregation' || targetNode.type === 'odfv' || targetNode.type === 'feature_service') {
      return true;
    }

    return false;
  };

  /**
   * Special Case: Push Stream Sources
   * A push source can write to both the online store
   * and the offline store.
   */
  const hideOfflineStore = !focusedNode.pushesToOfflineStore;

  const { linkedIds, linkedEdges } = highlightDownstream(id, nodesMap, edgesList, {
    hideOfflineStore,
    edgeShouldBeSkipped: skipDependenciesThatCrossesTheStore,
  });

  const animations = getVisibleAnimationsOnAllEdgesGivenAnchors(linkedIds, edgesList, linkedEdges, {
    showStreamMaterializationPath: true,
  });

  return {
    linkedIds,
    linkedEdges,
    animations,
  };
};

export default highlightRawStreamNode;
