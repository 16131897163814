import React, { FC, useState } from 'react';
import { Avatar, ListGroup, ListGroupItem, Popover } from '@tecton';
import useGlobalState from '../../shared/useGlobalState';
import { logout } from '../../utils/okta';

const UserAvatar: FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { loggedInUser, auth } = useGlobalState();

  return (
    <Popover
      isOpen={isOpen}
      closePopover={() => {
        setIsOpen(false);
      }}
      panelPaddingSize="s"
      button={
        <Avatar
          style={{ cursor: 'pointer' }}
          name={loggedInUser.name}
          color={'#263549'}
          onClick={() => {
            setIsOpen(true);
          }}
          size={'l'}
        />
      }
    >
      <ListGroup flush={true} bordered={false} style={{ minWidth: '12em' }}>
        <ListGroupItem
          onClick={() => {
            logout(auth);
          }}
          label={'Sign Out'}
        />
      </ListGroup>
    </Popover>
  );
};

export default UserAvatar;
