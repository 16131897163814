import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import { FC, useState } from 'react';
import { MaterializationAttemptStatus } from '../../../types/tecton_proto/data/materialization_status';
import { stageTypesToNiceNames } from './JobsUtils';
import TectonManageJobStatusBadge from './TectonManageJobStatusBadge';
import { StageEntryInterface } from './types';

import { ReactComponent as DocumentFileDoListIcon } from '@svg/document-file-do-list.svg';

import styled from '@emotion/styled';
import {
  ButtonIcon,
  Code,
  EmptyPrompt,
  ErrorPromptButtons,
  EuiBasicTableColumn,
  FlexGroupWrapper,
  FlexItem,
  Table,
  DeprecatedText,
} from '@tecton/ComponentRedesign';
import { FlyoutContentProps } from '@tecton/ComponentRedesign/lib/ViewLayoutWithFlyoutPush';

TimeAgo.addLocale(en);
const timeAgo = new TimeAgo('en-US');

interface JobStagesProps {
  materializationAttempts: StageEntryInterface[];
  materializationStatus?: MaterializationAttemptStatus;
  updateFlyoutContent: (content?: FlyoutContentProps) => void;
}

const CodeWrapper = styled.div`
  .euiCodeBlock {
    height: calc(100vh - 168px);
    overflow: auto;
  }
`;

const JobStages: FC<JobStagesProps> = ({
  materializationAttempts = [],
  materializationStatus,
  updateFlyoutContent,
}) => {
  const [, setSelectedStage] = useState<StageEntryInterface | undefined>();

  const createdAt = materializationStatus?.attempt_created_at
    ? new Date(materializationStatus.attempt_created_at)
    : new Date();

  const emptyPrompt = (
    <EmptyPrompt
      orientation="vertical"
      variant="storm"
      title={<>Error Loading Job</>}
      body={
        <>
          <p>We ran into an error while loading Job.</p>
        </>
      }
      actions={<ErrorPromptButtons />}
    />
  );

  const columns: EuiBasicTableColumn<StageEntryInterface>[] = [
    {
      name: 'Status',
      render: (stage: StageEntryInterface) => {
        const asStatus = stage.state;
        return (
          <>
            <TectonManageJobStatusBadge status={asStatus} />
          </>
        );
      },
    },
    {
      name: 'Stage',
      render: (stage: StageEntryInterface) => {
        return <>{stageTypesToNiceNames[stage?.type]}</>;
      },
    },
    {
      name: 'Details',
      render: (stage: StageEntryInterface) => {
        return <>{stage.description}</>;
      },
    },
    {
      name: 'Actions',
      width: '5em',
      actions: [
        {
          render: (stage: StageEntryInterface) => {
            const action = stage?.errorDetail ? (
              <>
                <ButtonIcon
                  size="s"
                  iconType={DocumentFileDoListIcon}
                  onClick={() => {
                    setSelectedStage(stage);
                    const headerContent = (
                      <>
                        {' '}
                        <DeprecatedText fontSize="l" fontWeight="medium" lineHeight="m">
                          {stageTypesToNiceNames[stage?.type ?? 'PYTHON']}
                        </DeprecatedText>{' '}
                        {stage?.state && <TectonManageJobStatusBadge status={stage.state} />}
                      </>
                    );

                    const bodyContent = (
                      <>
                        <FlexGroupWrapper gap="l" direction="column">
                          <FlexItem grow={false}>{stage?.description}</FlexItem>
                          <FlexItem grow={false}>
                            <CodeWrapper>
                              <Code code={stage?.errorDetail ?? ''} />
                            </CodeWrapper>
                          </FlexItem>
                        </FlexGroupWrapper>
                      </>
                    );

                    const onCloseClicked = () => {
                      updateFlyoutContent(undefined);
                    };

                    updateFlyoutContent({ headerContent: headerContent, bodyContent: bodyContent, onCloseClicked });
                  }}
                />
              </>
            ) : (
              <></>
            );
            return action;
          },
        },
      ],
    },
  ];

  return (
    <>
      {materializationAttempts.length === 0 ? (
        <EmptyPrompt title={<h2>No Job Stages Available</h2>} body={<p>Please try again later.</p>} />
      ) : (
        <>
          <FlexGroupWrapper gap="l">
            <FlexItem>Last Updated {timeAgo.format(createdAt)}</FlexItem>
            <FlexItem>
              <>
                <Table items={materializationAttempts} columns={columns} emptyPrompt={emptyPrompt} />
              </>
            </FlexItem>
          </FlexGroupWrapper>
        </>
      )}
    </>
  );
};

export default JobStages;
