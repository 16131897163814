import React, { FC, useState } from 'react';
import { AnyFCO, DataSourceFCO, DataSourceFCOFields, FCOType } from '../../../core/types/fcoTypes';

// @tecton
import { ComboFilterSelector, ComboSelectorChecklistOption, EmptyValue, RelatedFCOs } from '@tecton/ComponentRedesign';
import { compact, startCase, uniq } from 'lodash';
import {
  FCOFilterStateType,
  FCOFilterType,
  FCOHistoryFilterState,
  FCOHistoryFilterTypes,
  FCOHistoryTableColumns,
  FCOListView,
  NameColumn,
  RelatedFCOCountFilterLogic,
  RelatedFCOCountFilterType,
  RelatedFCOFilterLogic,
  RelatedFCOFilterType,
  SharedFCOHistoryFilterLogic,
  SimpleChecklistFilterLogic,
  SimpleChecklistFilterType,
  TagFilterLogic,
  TagsColumn,
  TagsFilterType,
  structureTagsForFilter,
  valuesForFCOCount,
} from '../fcoListView';

interface DataSourcesListProps {
  items: DataSourceFCO[];
  idToFcoMap: Record<string, AnyFCO>;
  workspace: string;
}

const DataSourcesList: FC<DataSourcesListProps> = ({ items, idToFcoMap, workspace }) => {
  type SourceFilterKeys =
    | FCOFilterType
    | 'Type'
    | 'Batch Source'
    | 'Stream Source'
    | '# of Feature Views'
    | 'Feature Views'
    | '# of Feature Services'
    | 'Feature Services';

  interface SourceFilterStateType extends FCOFilterStateType {
    Type: ComboSelectorChecklistOption[];
    'Batch Source': ComboSelectorChecklistOption[];
    'Stream Source': ComboSelectorChecklistOption[];
    '# of Feature Views': ComboSelectorChecklistOption[];
    'Feature Views': ComboSelectorChecklistOption[];
    '# of Feature Services': ComboSelectorChecklistOption[];
    'Feature Services': ComboSelectorChecklistOption[];
  }

  const featureViews = uniq(compact(items.map((item) => item.dependentFeatureViews).flat())).map(
    (fvId) => idToFcoMap[fvId]
  );

  const featureServices = uniq(compact(items.map((item) => item.dependentFeatureServices).flat())).map(
    (fvId) => idToFcoMap[fvId]
  );

  const batchSources = uniq(compact(items.map((item) => item[DataSourceFCOFields.BATCH_SOURCE])));
  const streamSources = uniq(compact(items.map((item) => item[DataSourceFCOFields.STREAM_SOURCE])));
  const types = uniq(compact(items.map((item) => item[DataSourceFCOFields.SOURCE_TYPE])));

  const defaultFilterState: SourceFilterStateType = {
    Tags: structureTagsForFilter(items),
    Type: types.map((type) => {
      return { label: type, value: type };
    }),
    'Batch Source': batchSources.map((source) => {
      return { label: source ?? 'n/a', value: source?.toLocaleUpperCase() };
    }),
    'Stream Source': streamSources.map((source) => {
      return { label: source ?? 'n/a', value: source?.toLocaleUpperCase() };
    }),
    '# of Feature Views': valuesForFCOCount,
    'Feature Views': featureViews.map((featureView) => {
      return { label: featureView.name ?? '', value: featureView.id };
    }),
    '# of Feature Services': valuesForFCOCount,
    'Feature Services': featureServices.map((featureService) => {
      return { label: featureService.name ?? '', value: featureService.id };
    }),
    ...FCOHistoryFilterState(items as AnyFCO[]),
  };

  const [filterState, setFilterState] = useState<SourceFilterStateType>(defaultFilterState);

  const filterTypes: Record<SourceFilterKeys, ComboFilterSelector> = {
    Tags: TagsFilterType(filterState, setFilterState as React.Dispatch<React.SetStateAction<FCOFilterStateType>>),
    Type: SimpleChecklistFilterType(filterState, setFilterState, 'Type'),
    'Batch Source': SimpleChecklistFilterType(filterState, setFilterState, 'Batch Source'),
    'Stream Source': SimpleChecklistFilterType(filterState, setFilterState, 'Stream Source'),
    '# of Feature Views': RelatedFCOCountFilterType(filterState, setFilterState, '# of Feature Views'),
    'Feature Views': RelatedFCOFilterType(filterState, setFilterState, 'Feature Views', FCOType.FEATURE_VIEW),
    '# of Feature Services': RelatedFCOCountFilterType(filterState, setFilterState, '# of Feature Services'),
    'Feature Services': RelatedFCOFilterType(filterState, setFilterState, 'Feature Services', FCOType.FEATURE_SERVICE),
    ...FCOHistoryFilterTypes(filterState, setFilterState as React.Dispatch<React.SetStateAction<FCOFilterStateType>>),
  };

  const columns = [
    ...NameColumn,
    ...TagsColumn,
    {
      name: 'Type',
      key: 'Type',
      field: DataSourceFCOFields.SOURCE_TYPE,
      sortable: true,
      render: (_: unknown, source: DataSourceFCO) => {
        return source[DataSourceFCOFields.SOURCE_TYPE];
      },
    },
    {
      name: 'Batch Source',
      key: 'Batch Source',
      field: DataSourceFCOFields.BATCH_SOURCE,
      render: (_: unknown, source: DataSourceFCO) => {
        return source[DataSourceFCOFields.BATCH_SOURCE] ? (
          startCase(source[DataSourceFCOFields.BATCH_SOURCE])
        ) : (
          <EmptyValue />
        );
      },
      sortable: true,
    },
    {
      name: 'Stream Source',
      key: 'Stream Source',
      field: DataSourceFCOFields.STREAM_SOURCE,
      render: (_: unknown, source: DataSourceFCO) => {
        return source[DataSourceFCOFields.STREAM_SOURCE] ? (
          startCase(source[DataSourceFCOFields.STREAM_SOURCE])
        ) : (
          <EmptyValue />
        );
      },
      sortable: true,
    },
    {
      name: 'Feature Views',
      key: 'Feature Views',
      field: DataSourceFCOFields.DEPENDENT_FEATURE_VIEWS,
      render: (_: unknown, source: DataSourceFCO) => {
        const items = source[DataSourceFCOFields.DEPENDENT_FEATURE_VIEWS]
          .map((featureViewId) => idToFcoMap[featureViewId])
          .map((fco) => {
            return { name: fco.name ?? '', description: fco.description };
          });
        return <RelatedFCOs fcos={items} type={FCOType.FEATURE_VIEW} workspace={workspace} />;
      },
      sortable: true,
    },
    {
      name: 'Feature Services',
      key: 'Feature Services',
      field: DataSourceFCOFields.DEPENDENT_FEATURE_SERVICES,
      render: (_: unknown, source: DataSourceFCO) => {
        const items = source[DataSourceFCOFields.DEPENDENT_FEATURE_SERVICES]
          .map((featureViewId) => idToFcoMap[featureViewId])
          .map((fco) => {
            return { name: fco.name ?? '', description: fco.description };
          });
        return <RelatedFCOs fcos={items} type={FCOType.FEATURE_SERVICE} workspace={workspace} />;
      },
      sortable: true,
    },
    ...FCOHistoryTableColumns,
  ];

  const filterFunctionsByFilterKey: Record<SourceFilterKeys, (fco: DataSourceFCO) => boolean> = {
    Tags: TagFilterLogic(filterState['Tags']),
    ...SharedFCOHistoryFilterLogic(filterState),
    Type: SimpleChecklistFilterLogic(filterState, 'Type', DataSourceFCOFields.SOURCE_TYPE),
    'Batch Source': SimpleChecklistFilterLogic(filterState, 'Batch Source', DataSourceFCOFields.BATCH_SOURCE),
    'Stream Source': SimpleChecklistFilterLogic(filterState, 'Stream Source', DataSourceFCOFields.STREAM_SOURCE),
    '# of Feature Views': RelatedFCOCountFilterLogic(
      filterState,
      '# of Feature Views',
      DataSourceFCOFields.DEPENDENT_FEATURE_VIEWS
    ),
    'Feature Views': RelatedFCOFilterLogic(filterState, 'Feature Views', DataSourceFCOFields.DEPENDENT_FEATURE_VIEWS),
    '# of Feature Services': RelatedFCOCountFilterLogic(
      filterState,
      '# of Feature Services',
      DataSourceFCOFields.DEPENDENT_FEATURE_SERVICES
    ),
    'Feature Services': RelatedFCOFilterLogic(
      filterState,
      'Feature Services',
      DataSourceFCOFields.DEPENDENT_FEATURE_SERVICES
    ),
  };

  return (
    <FCOListView
      unfilteredFCOItems={items}
      filters={filterTypes}
      columns={columns}
      filterState={filterState}
      setFilterState={setFilterState}
      defaultFilterState={defaultFilterState}
      filterFunctionsByFilterKey={filterFunctionsByFilterKey}
      searchPlaceholder={'Search Sources'}
    />
  );
};

export default DataSourcesList;
