import _sortBy from 'lodash/sortBy';
import moment from 'moment';
import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { hiddenEditLinkRowCss } from './acl-util';
import PrincipalListAccountType from './PrincipalListAccountType';

// generated
import { ServiceAccount } from '../../../api/gql/graphql';

// @tecton
import {
  AvatarAndName,
  Badge,
  ButtonIcon,
  EmptyPrompt,
  ErrorPromptButtons,
  EuiBasicTableColumn,
  FlexGroupWrapper,
  FlexItem,
  Icon,
  SearchAndActionButton,
  Table,
  Tooltip,
} from '@tecton/ComponentRedesign';

import { TectonDateFormat } from '@tecton/ComponentRedesign/utils';

// icons
import { ReactComponent as CheckmarkIcon } from '@svg/checkmark.svg';
import { ReactComponent as ExitIcon } from '@svg/exit.svg';
import { ReactComponent as RobotIcon } from '@svg/robot.svg';
import { ReactComponent as TrashIcon } from '@svg/trash.svg';
import { TableFieldWrapper } from './StyledComponents';

export interface ACLServiceAccountListProps {
  serviceAccounts: ServiceAccount[];
  isAdminView: boolean;
  isError?: boolean;
  isLoading?: boolean;
  onCreateServiceAccountClick: VoidFunction;
  onAccountTypeRowClicked: (serviceAccount: ServiceAccount) => void;
  onDeactivateServiceAccountRowClicked: (serviceAccount: ServiceAccount) => void;
  onDeleteServiceAccountRowClicked: (serviceAccount: ServiceAccount) => void;
  onActivateServiceAccountRowClicked: (serviceAccount: ServiceAccount) => void;
}

const ACLServiceAccountList: FC<ACLServiceAccountListProps> = ({
  serviceAccounts,
  isAdminView,
  isError,
  isLoading,
  onCreateServiceAccountClick,
  onAccountTypeRowClicked,
  onDeactivateServiceAccountRowClicked,
  onDeleteServiceAccountRowClicked,
  onActivateServiceAccountRowClicked,
}) => {
  const [searchText, setSearchText] = useState<string | undefined>(undefined);

  const filteredServiceAccounts = searchText
    ? serviceAccounts?.filter((serviceAccount: ServiceAccount) => {
        const { name, id } = serviceAccount;
        return [name, id].join('-')?.toLowerCase()?.includes(searchText?.toLowerCase());
      })
    : serviceAccounts;

  const sortedServiceAccounts = isLoading ? undefined : _sortBy(filteredServiceAccounts, ['name']);

  const columns: EuiBasicTableColumn<ServiceAccount>[] = [
    {
      name: 'Name',
      render: (serviceAccount: ServiceAccount) => {
        return (
          <TableFieldWrapper>
            <Link to={`./${serviceAccount.id}`}>
              <AvatarAndName
                maxWidth="320px"
                name={serviceAccount.name ?? ''}
                icon={<Icon type={RobotIcon} />}
                type="icon"
              />
            </Link>
          </TableFieldWrapper>
        );
      },
    },
    {
      name: 'Description',
      render: (serviceAccount: ServiceAccount) => {
        return <TableFieldWrapper>{serviceAccount.description}</TableFieldWrapper>;
      },
    },
    ...(isAdminView
      ? [
          {
            name: 'Account Type',
            render: (serviceAccount: ServiceAccount) => {
              return (
                <PrincipalListAccountType
                  rolesGranted={serviceAccount.rolesGranted ?? []}
                  accountType={serviceAccount.accountType ?? ''}
                  principalId={serviceAccount?.id ?? ''}
                  onAccountTypeRowClicked={() => {
                    onAccountTypeRowClicked(serviceAccount);
                  }}
                />
              );
            },
          },
        ]
      : []),
    {
      name: 'Status',
      render: (serviceAccount: ServiceAccount) => {
        return (
          <Badge
            variant={`${serviceAccount?.isActive ? 'success' : 'disabled'}`}
            label={`${serviceAccount?.isActive ? 'Active' : 'Inactive'}`}
          />
        );
      },
    },
    {
      name: 'Created At',
      render: (serviceAccount: ServiceAccount) => {
        const createdAt = serviceAccount?.createdAt ? TectonDateFormat(moment(serviceAccount.createdAt)) : '-';
        return <>{createdAt}</>;
      },
    },
    {
      name: 'Actions',
      actions: [
        {
          render: (serviceAccount: ServiceAccount) => {
            const isEnabled = serviceAccount.isOwner ?? isAdminView;
            return (
              <>
                {/* Deactivate */}
                {isEnabled ? (
                  <ButtonIcon
                    variant={!isEnabled ? 'disabledAction' : 'dangerousAction'}
                    iconType={ExitIcon}
                    isDisabled={!isEnabled}
                    size="xs"
                    onClick={() => {
                      onDeactivateServiceAccountRowClicked(serviceAccount);
                    }}
                  />
                ) : (
                  <Tooltip
                    trigger={
                      <ButtonIcon
                        variant={!isEnabled ? 'disabledAction' : 'dangerousAction'}
                        iconType={ExitIcon}
                        isDisabled={!isEnabled}
                        size="xs"
                        onClick={() => {
                          onDeactivateServiceAccountRowClicked(serviceAccount);
                        }}
                      />
                    }
                    content="Administrator Privileges Required."
                  />
                )}
              </>
            );
          },
          available: (serviceAccount: ServiceAccount) => {
            return serviceAccount?.isActive ?? false;
          },
        },
        {
          render: (serviceAccount: ServiceAccount) => {
            const isEnabled = serviceAccount.isOwner ?? isAdminView;
            return (
              <>
                {/* Reactivate */}
                {isEnabled ? (
                  <ButtonIcon
                    variant={!isEnabled ? 'disabledAction' : 'dangerousAction'}
                    iconType={CheckmarkIcon}
                    isDisabled={!isEnabled}
                    size="xs"
                    onClick={() => {
                      onActivateServiceAccountRowClicked(serviceAccount);
                    }}
                  />
                ) : (
                  <Tooltip
                    trigger={
                      <ButtonIcon
                        variant={!isEnabled ? 'disabledAction' : 'dangerousAction'}
                        iconType={CheckmarkIcon}
                        isDisabled={!isEnabled}
                        size="xs"
                        onClick={() => {
                          onActivateServiceAccountRowClicked(serviceAccount);
                        }}
                      />
                    }
                    content="Administrator Privileges Required."
                  />
                )}
              </>
            );
          },
          available: (serviceAccount: ServiceAccount) => {
            return !serviceAccount?.isActive ?? false;
          },
        },
        {
          render: (serviceAccount: ServiceAccount) => {
            const isEnabled = serviceAccount.isOwner ?? isAdminView;
            return (
              <>
                {/* Delete */}
                {isEnabled ? (
                  <ButtonIcon
                    variant={!isEnabled ? 'disabledAction' : 'dangerousAction'}
                    iconType={TrashIcon}
                    isDisabled={!isEnabled}
                    size="xs"
                    onClick={() => {
                      onDeleteServiceAccountRowClicked(serviceAccount);
                    }}
                  />
                ) : (
                  <Tooltip
                    trigger={
                      <ButtonIcon
                        variant={!isEnabled ? 'disabledAction' : 'dangerousAction'}
                        iconType={TrashIcon}
                        isDisabled={!isEnabled}
                        size="xs"
                        onClick={() => {
                          onDeleteServiceAccountRowClicked(serviceAccount);
                        }}
                      />
                    }
                    content="Administrator Privileges Required."
                  />
                )}
              </>
            );
          },
          available: (serviceAccount: ServiceAccount) => {
            return !serviceAccount?.isActive ?? false;
          },
        },
      ],
    },
  ];

  if (isError) {
    const emptyPrompt = (
      <EmptyPrompt
        orientation="horizontal"
        variant="storm"
        title={<>Error Loading Service Accounts</>}
        body={
          <>
            <p>We ran into an error while loading Service Accounts.</p>
          </>
        }
        actions={<ErrorPromptButtons />}
      />
    );
    return <Table items={[]} columns={columns} emptyPrompt={emptyPrompt} />;
  }

  return (
    <FlexGroupWrapper gap="s" direction="column">
      <FlexItem>
        <SearchAndActionButton
          searchPlaceHolder={'Search for Service Account'}
          actionButtonLabel={'Create Service Account'}
          actionButtonVariant={'primaryAction'}
          onActionButtonClick={onCreateServiceAccountClick}
          onSearchInputChange={function (searchString: string): void {
            setSearchText(searchString);
          }}
        />
      </FlexItem>
      <FlexItem>
        {isLoading ? (
          <Table
            items={[]}
            columns={columns}
            emptyPrompt={<EmptyPrompt title={<>Loading Service Accounts</>} body={<></>} variant="loading" />}
          />
        ) : (
          <Table
            items={sortedServiceAccounts ?? []}
            columns={columns}
            allowWordBreak={false}
            rowProps={{ className: hiddenEditLinkRowCss }}
          />
        )}
      </FlexItem>
    </FlexGroupWrapper>
  );
};

export default ACLServiceAccountList;
