import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AclRoleWithInheritance } from '../../../api/gql/graphql';
import _sortBy from 'lodash/sortBy';
import { Routes } from '../../../core/routes';

import { ButtonIcon, FlexGroup, FlexItem, FlexGroupWrapper, Popover, ButtonEmpty } from '@tecton/ComponentRedesign';
import RoleIconName from './RoleIconName';

import GroupIcon from '@svg/group.svg';

interface PrincipalListAccountTypeProps {
  aclRole?: AclRoleWithInheritance;
  onClick?: VoidFunction;
}

const PrincipalRoles: FC<PrincipalListAccountTypeProps> = ({ aclRole, onClick }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const popoverContent =
    aclRole?.roleSources
      ?.filter((roleSource) => roleSource.type !== 'direct')
      .map((roleSource, index) => {
        return (
          <>
            <FlexGroup justifyContent="spaceBetween" gutterSize="s" alignItems="center" key={index}>
              <FlexItem grow={false}>
                <ButtonEmpty
                  iconType={GroupIcon}
                  onClick={() => {
                    navigate(`${Routes.accountsAndAccessGroups}/${roleSource.groupId}`);
                  }}
                >
                  {roleSource.groupName}
                </ButtonEmpty>
              </FlexItem>
            </FlexGroup>
          </>
        );
      }) ?? [];

  return (
    <>
      <FlexGroupWrapper gap="xs" direction="row" alignItems="center">
        <FlexItem grow={false}>
          <RoleIconName role={aclRole?.effectiveWorkspaceRole ?? ''} onClick={onClick} />
        </FlexItem>
        <FlexItem grow={false}>
          {popoverContent.length > 0 && (
            <Popover
              data-testid="applied-details-items-popover"
              title={'Role applied via group'}
              button={
                <>
                  {popoverContent.length > 0 && (
                    <FlexItem
                      onClick={(event) => {
                        event.stopPropagation();
                        setIsOpen(!isOpen);
                      }}
                    >
                      <ButtonIcon iconType={GroupIcon} size="xs" />
                    </FlexItem>
                  )}
                </>
              }
              isOpen={isOpen}
              body={<>{popoverContent}</>}
              closePopover={() => {
                setIsOpen(false);
              }}
            />
          )}
        </FlexItem>
      </FlexGroupWrapper>
    </>
  );
};

export default PrincipalRoles;
