import { DatabricksRequestInterface } from '../../../components';
import { UpdateUserDeploymentSettingsRequestInterface } from '../../data-platform-configuration/databricks/setup/DatabricksCredentialsForm';
import { useMutateUserDeploymentSettings } from '../../data-platform-configuration/query';

interface MetadataServiceRequestInterface {
  validateDatabricksConnectionRequest: DatabricksRequestInterface;
}

export const useDatabricksMetadataService = (): MetadataServiceRequestInterface => {
  const validateDatabricksConnectionMutation = useMutateUserDeploymentSettings();

  const validateDatabricksConnectionMutationRequest = (
    updateUserDeploymentSetting: UpdateUserDeploymentSettingsRequestInterface,
    onSuccess?: (response: any) => void
  ) => {
    if (onSuccess) {
      validateDatabricksConnectionMutation.mutate(updateUserDeploymentSetting, {
        onSuccess: (response: any) => {
          onSuccess(response);
        },
      });
    } else {
      validateDatabricksConnectionMutation.mutate(updateUserDeploymentSetting);
    }
  };

  return {
    validateDatabricksConnectionRequest: {
      sendRequest: validateDatabricksConnectionMutationRequest,
      isIdle: validateDatabricksConnectionMutation.isIdle,
      isLoading: validateDatabricksConnectionMutation.isLoading,
      isError: validateDatabricksConnectionMutation.isError,
      isSuccess: validateDatabricksConnectionMutation.isSuccess,
    },
  };
};
