import { GetJobsResponse as TransformedJobsResponse } from '../../api/gql/graphql';
import { convertKeysToCamelCase } from '../../api/utils';
import { MaterializationAttemptStatus } from '../../types/tecton_proto/data/materialization_status';
import { GetJobsResponse, TaskWithAttempts } from '../../types/tecton_proto/metadataservice/metadata_service';
import IDUtils from '../../utils/id-utils';
import { getTaskTypeForDisplay } from '../redesign/jobs/JobsUtils';
import { getJobStatus, getTaskType } from './JobsUtils';
import { TransformedMaterializationAttemptStatus } from './types';

export const transformAttempt = (input: MaterializationAttemptStatus): TransformedMaterializationAttemptStatus => {
  const materializationState = getJobStatus(input.materialization_state, 'label');
  return {
    ...input,
    materializationState: materializationState,
    ...(input.tecton_managed_attempt_id
      ? { tectonManagedAttemptId: IDUtils.toStringId(input.tecton_managed_attempt_id) }
      : {}),
  };
};

export const transformJobs = (data: GetJobsResponse): TransformedJobsResponse => {
  const transformedJobsData =
    data.tasksWithAttempts?.map((job: TaskWithAttempts) => {
      let jobAttempts: TransformedMaterializationAttemptStatus[] = [];
      let latestAttempt: TransformedMaterializationAttemptStatus = {};
      if (job.materialization_status?.materialization_attempts?.length) {
        jobAttempts = job.materialization_status.materialization_attempts
          .map(
            (attempt: MaterializationAttemptStatus): TransformedMaterializationAttemptStatus =>
              transformAttempt(attempt)
          )
          .sort(
            (a: TransformedMaterializationAttemptStatus, b: TransformedMaterializationAttemptStatus) =>
              (b.attempt_number ?? Infinity) - (a.attempt_number ?? Infinity)
          );
        latestAttempt = jobAttempts[0];
      }

      return {
        ...job,
        fullId: job.task_id,
        id: IDUtils.toStringId(job.task_id),
        workspace: job?.fco_locator?.workspace,
        featureView: job?.feature_view_name,
        fv_type: getTaskType(job?.task_type),
        fv_task_type_for_display: getTaskTypeForDisplay(job?.task_type_for_display),
        latest_status: getJobStatus(job?.taskState),
        duration: latestAttempt?.duration,
        attempts: jobAttempts,
        num_attempts: jobAttempts.length,
        manually_triggered: job?.manually_triggered ? 'manual' : 'automatic',
        writesOnline: latestAttempt?.write_to_online_feature_store,
        writesOffline: latestAttempt?.write_to_offline_feature_store,
        consumption_info: latestAttempt?.attempt_consumption,
        allow_retry: latestAttempt.allow_forced_retry,
        allow_cancel: latestAttempt.allow_cancel,
        allow_overwrite: latestAttempt.allow_overwrite_retry,
        ...(job?.materialization_status?.schedule_error_message
          ? { schedule_error_message: job?.materialization_status?.schedule_error_message }
          : {}),
      };
    }) ?? [];

  return convertKeysToCamelCase({
    jobs: transformedJobsData,
    pagination: data.pagination,
  });
};
