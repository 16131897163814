import React, { FC, useEffect } from 'react';
import { Stack, Box, Button, Card, CardContent, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import { Routes } from '../../../core/routes';
import { ContentAndDocsTwoColumnLayout } from '../../../components';
import { ReactComponent as DatabricksIcon } from '../../../assets/image/tecton-ui/ui-icon/databricks-icon.svg';
import { ReactComponent as SnowflakeIcon } from '../../../assets/image/tecton-ui/icon/snowflake.svg';
import { logEvent } from '../../../utils/analytics-utils';

const ChoosePlatform: FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    logEvent('Choose Platform Page Loaded');
  }, []);

  const onConfigureDatabricksClicked = () => {
    navigate(Routes.dataPlatformConfiguration);
  };

  return (
    <ContentAndDocsTwoColumnLayout
      mainColumn={
        <Stack spacing={2}>
          <Typography variant="h4">Connect to your Data</Typography>

          <Typography variant="body1">
            <span className="fw6">Tecton runs on top your data platform. </span>
            Tecton orchestrates operational ML feature pipelines on top of your existing data platform. Feature
            pipelines can be defined using Tecton&apos;s Framework. Once connected to your data platform, Tecton will
            manage compute and storage needed to keep your feature data up-to-date.
            <a target="_blank" href={'https://docs.tecton.ai/docs/introduction/tecton-concepts'} rel="noreferrer">
              <span> Read more about Tecton&apos;s architecture.</span>
            </a>
          </Typography>

          <Typography variant="body1">Choose a data platform to begin:</Typography>

          <Stack direction="row" spacing={2}>
            <Card sx={{ width: '50%' }}>
              <CardContent>
                <Grid container direction="column" justifyContent={'center'} alignItems={'center'}>
                  <DatabricksIcon style={{ height: 50, width: 50 }} />
                  <Typography variant="h5">Databricks</Typography>
                  <Typography variant="body1">Connect a Databricks workspace</Typography>
                  <div className="mt4">
                    <Button variant="contained" className="mt4" onClick={onConfigureDatabricksClicked}>
                      Use Databricks
                    </Button>
                  </div>
                </Grid>
              </CardContent>
            </Card>
            <Card sx={{ width: '50%' }}>
              <CardContent>
                <Grid container direction="column" justifyContent={'center'} alignItems={'center'}>
                  <SnowflakeIcon style={{ height: 50, width: 50 }} />
                  <Typography variant="h5">Snowflake</Typography>
                  <Typography variant="body1">Connect a Snowflake data warehouse</Typography>
                  <div className="mt4">
                    <Button variant="contained" disabled={true} className="mt4">
                      Coming Soon!
                    </Button>
                  </div>
                </Grid>
              </CardContent>
            </Card>
          </Stack>
          <Typography variant="body2">
            Self-serve connections are only supported for Databricks at this time. If you&apos;re not a Databricks user,
            please contact <a href="mailto:support@tecton.ai">support@tecton.ai</a>.
          </Typography>
        </Stack>
      }
      docsColumn={
        <Stack spacing={2} sx={{ m: 2 }}>
          <Box>
            <Typography variant="h5">Onboarding in 3 Steps</Typography>
          </Box>

          <Stack component="section" spacing={2}>
            <Typography variant="body1">
              <span className="fw6">1. Data Platform Setup (this step)</span>
            </Typography>
            <Typography variant="body1">Connect to your existing data platform.</Typography>
          </Stack>

          <Stack component="section" spacing={2}>
            <Typography variant="body1">
              <span className="fw6">2. Create a feature repository</span>
            </Typography>
            <Typography variant="body1">
              Once connected to a data platform, use the guided walkthrough to author your first Tecton feature
              pipeline.
            </Typography>
          </Stack>

          <Stack component="section" spacing={2}>
            <Typography variant="body1">
              <span className="fw6">3. Query feature values</span>
            </Typography>
            <Typography variant="body1">Query feature values from Tecton&apos;s online and offline store.</Typography>
          </Stack>
        </Stack>
      }
    />
  );
};

export default ChoosePlatform;
