import React, { FC, useState } from 'react';
import _debounce from 'lodash/debounce';

// @tecton
import { FlexItem, Text, TextArea, FormRow, FormInput, Callout, FlexGroupWrapper } from '@tecton/ComponentRedesign';

interface CreateServiceAccountProps {
  hasDuplicateServiceAccountName: boolean;
  onChange: (serviceAccountName: string, description: string) => void;
}

const CreateServiceAccount: FC<CreateServiceAccountProps> = ({ hasDuplicateServiceAccountName = false, onChange }) => {
  const [serviceAccountName, setServiceAccountName] = useState<string>('');
  const [description, setDescription] = useState<string>('');

  const onServiceAccountNameChange = (event: any) => {
    const value = event?.target?.value?.trim();

    setServiceAccountName(value);
    onChange(value, description);
  };

  const onDescriptionOnChange = (event: any) => {
    const value = event?.target?.value?.trim();
    setDescription(value);
    onChange(serviceAccountName, value);
  };

  return (
    <>
      <FlexGroupWrapper gap="l" direction="column">
        <FlexItem grow={false}>
          <Text>Service accounts provide automated access to data and configurations for Tecton workspaces.</Text>
        </FlexItem>

        <FormInput
          label={'Account Name'}
          error={hasDuplicateServiceAccountName}
          errorText={
            hasDuplicateServiceAccountName && `Service Account with name '${serviceAccountName}' already exists.`
          }
          onChange={_debounce(onServiceAccountNameChange, 250)}
          fullWidth
        />
        <FormRow label="Description" fullWidth>
          <TextArea fullWidth data-testid={'description'} onChange={_debounce(onDescriptionOnChange, 250)} />
        </FormRow>

        <Callout mode="neutral" title={'Note'}>
          <Text>
            You will only be able to access this key once, so make sure that you are prepared to securely store the key
            before proceeding.
          </Text>
        </Callout>
      </FlexGroupWrapper>
    </>
  );
};

export default CreateServiceAccount;
