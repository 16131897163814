import React, { FC, useState } from 'react';

// generated
import { Group } from '../../../api/gql/graphql';

// @tecton
import { ComboBox, EuiComboBoxOptionOption, FlexGroupWrapper } from '@tecton/ComponentRedesign';
import styled from '@emotion/styled';
interface AssignGroupProps {
  assignableGroups: Group[];
  onAssignGroupConfirm: (group?: Group) => void;
}

const ComboBoxWrapper = styled.div`
  width: 450px;
`;

const AssignGroup: FC<AssignGroupProps> = ({ assignableGroups, onAssignGroupConfirm }) => {
  const [selectedOptions, setSelectedOptions] = useState<EuiComboBoxOptionOption<Group>[]>([]);
  const options: EuiComboBoxOptionOption<Group>[] = assignableGroups?.map((group) => {
    return {
      label: group.name,
      value: group,
    };
  });

  return (
    <>
      <FlexGroupWrapper gap="xl" direction="column">
        <ComboBoxWrapper>
          <ComboBox
            placeholder="Select a Group"
            singleSelection={{ asPlainText: true }}
            options={options}
            fullWidth={true}
            selectedOptions={selectedOptions}
            onChange={(selectedOptions: EuiComboBoxOptionOption<Group>[]) => {
              setSelectedOptions(selectedOptions);
              const selected = selectedOptions[0]?.value;
              onAssignGroupConfirm(selected);
            }}
          />
        </ComboBoxWrapper>
      </FlexGroupWrapper>
    </>
  );
};

export default AssignGroup;
