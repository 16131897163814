import { Breadcrumbs, HeaderLayout, Subtitle, ViewLayout } from '@tecton/ComponentRedesign';
import { useFeatureAnalytics } from '../../../api/featureAnalytics';
import { useUserSettings } from '../../context/UserSettingsContext';
import WorkspaceRootBreadcrumb from '../WorkspaceRootBreadcrumb';
import FeatureAnalyticsList from './FeatureAnalyticsList';

export const FeatureAnalyticsContainer = () => {
  const { workspaceDetails } = useUserSettings();

  const { data, isError, isLoading } = useFeatureAnalytics();

  const subtitle = <Subtitle description="Groups of similar Feature Views." />;

  const header = (
    <HeaderLayout
      breadcrumbs={
        <Breadcrumbs
          workspace={<WorkspaceRootBreadcrumb workspace={workspaceDetails} />}
          crumbs={[{ label: 'Feature Analytics' }]}
        />
      }
      subtitle={subtitle}
    />
  );
  return (
    <ViewLayout
      header={header}
      body={<FeatureAnalyticsList isError={isError} isLoading={isLoading} featureAnalytics={data?.feature_analytics} />}
    />
  );
};

export default FeatureAnalyticsContainer;
