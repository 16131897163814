import React, { FC } from 'react';
import { Background } from '@xyflow/react';
import { useTectonTheme } from '@tecton/ComponentRedesign';

interface DataFlowBackgroundProps {}

const DataFlowBackground: FC<DataFlowBackgroundProps> = ({}) => {
  // Created custom component to allow for use of theme and feature flags in the future
  // For the time being, this is redundant

  const { theme } = useTectonTheme();

  return (
    <Background
      style={{
        background: theme.colors.body,
      }}
    />
  );
};

export default DataFlowBackground;
