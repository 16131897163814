import React, { FC } from 'react';

import { Avatar, AvatarAndName, TruncateText } from '@tecton/ComponentRedesign';
import { TectonDateTimeFormat } from '../../../components/@tecton/ComponentRedesign/utils';
import moment from 'moment-timezone';
import { Table } from '@tecton/ComponentRedesign';
import { ApplyLogTableDataRow } from './ApplyLogTypes';
import UpdateLogEntry from '../../../service/UpdateLogEntry';
import { Link } from 'react-router-dom';

export interface ApplyLogItem {
  commit_id: string;
  appliedBy: string;
  applyTimestamp: Date;
  sdkVersion: string;
}

interface ApplyLogTableProps {
  items: ApplyLogItem[];
}

const ApplyLogTable: FC<ApplyLogTableProps> = ({ items }) => {
  const parseUpdateLogData = (data: unknown[]) => {
    const results: ApplyLogTableDataRow[] = data.map((e) => {
      const entry = e as UpdateLogEntry;

      return {
        applyId: entry.applyId,
        user: entry.owner,
        date: entry.appliedAt,
        link: `./${entry.applyId}`,
        sdkVersion: entry.sdkVersion,
      };
    });

    return results;
  };

  const applyLogColumns = [
    {
      name: 'Commit ID',
      render: (item: ApplyLogTableDataRow) => {
        return <Link to={item.link}>{item.applyId}</Link>;
      },
    },
    {
      name: 'Applied By',
      render: (item: ApplyLogTableDataRow) => <AvatarAndName name={item.user} />,
    },
    {
      name: 'Apply Time',
      render: (item: ApplyLogTableDataRow) => TectonDateTimeFormat(moment(item.date)),
    },
    {
      name: 'SDK Version',
      render: (item: ApplyLogTableDataRow) => item.sdkVersion,
    },
  ];

  return <Table items={parseUpdateLogData(items)} columns={applyLogColumns} />;
};

export default ApplyLogTable;
