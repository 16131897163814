import React, { FC, useState } from 'react';
import _compact from 'lodash/compact';

// generated
import { ServiceAccount } from '../../../api/gql/graphql';

// @tecton
import { ComboBox, EuiComboBoxOptionOption, FlexItem, FlexGroupWrapper } from '@tecton/ComponentRedesign';
interface AssignServiceAccountProps {
  assignableServiceAccounts: ServiceAccount[];
  onAssignServiceAccountChange: (serviceAccount: ServiceAccount[]) => void;
}

const AssignServiceAccount: FC<AssignServiceAccountProps> = ({
  assignableServiceAccounts,
  onAssignServiceAccountChange,
}) => {
  const [selectedOptions, setSelectedOptions] = useState<EuiComboBoxOptionOption<ServiceAccount>[]>([]);

  const options =
    assignableServiceAccounts?.map((serviceAccount) => {
      return {
        label: serviceAccount?.name ?? '',
        value: serviceAccount,
      };
    }) ?? [];

  return (
    <>
      <FlexGroupWrapper>
        <FlexItem grow={false}>
          <ComboBox
            placeholder="Select a Service Account"
            options={options}
            fullWidth={true}
            selectedOptions={selectedOptions}
            onChange={(selectedOptions: EuiComboBoxOptionOption<ServiceAccount>[]) => {
              setSelectedOptions(selectedOptions);
              const serviceAccounts = selectedOptions?.map((option) => option.value) ?? [];
              onAssignServiceAccountChange(_compact(serviceAccounts));
            }}
          />
        </FlexItem>
      </FlexGroupWrapper>
    </>
  );
};

export default AssignServiceAccount;
