import React, { FC } from 'react';
import {
  DataSourceNode,
  DataflowCardPropType,
  EmbeddingModelNode,
  FeatureServiceNode,
  FeatureViewNode,
  ODFVNode,
} from './dataFlowTypes';
import styled from '@emotion/styled';
import DataFlowHandle from './DataFlowHandle';
import { Position } from '@xyflow/react';
import DataFlowFCOCountBadge from './DataFlowFCOCountBadge';

interface DataFlowObjectLevelCardOutletProps {
  data: DataSourceNode | FeatureViewNode | ODFVNode | FeatureServiceNode | EmbeddingModelNode;
}

const Wrapper = styled.div`
  position: relative;
  left: -20px;
  top: 20px;
  width: 40px;
  top: 18px;
`;

const DataFlowObjectLevelCardOutlet: FC<DataFlowObjectLevelCardOutletProps & DataflowCardPropType> = ({ data }) => {
  return (
    <Wrapper>
      <DataFlowFCOCountBadge data={data} />
      <DataFlowHandle position={Position.Left} top={'14px'} />
      <DataFlowHandle position={Position.Right} top={'14px'} />
    </Wrapper>
  );
};

export default DataFlowObjectLevelCardOutlet;
