enum NODE_TYPES {
  'feature_view' = 'feature_view',
  'feature_view/batch_feature_view' = 'feature_view/batch_feature_view',
  'feature_view/on_demand_feature_view' = 'feature_view/on_demand_feature_view',
  'feature_view/stream_window_aggregate_feature_view' = 'feature_view/stream_window_aggregate_feature_view',
  'feature_view/stream_feature_view' = 'feature_view/stream_feature_view',
  'feature_view/feature_view_group' = 'feature_view/feature_view_group',
  'feature_table' = 'feature_table',
  'feature_service' = 'feature_service',
  'data_source/batch' = 'data_source/batch',
  'data_source/stream' = 'data_source/stream',
  'request_data_source' = 'request_data_source',
  'tecton_store/offline_store' = 'tecton_store/offline_store',
  'tecton_store/online_store' = 'tecton_store/online_store',
  'external_data_batch/snowflake' = 'external_data_batch/snowflake',
  'external_data_batch/s3' = 'external_data_batch/s3',
  'external_data_batch/hive' = 'external_data_batch/hive',
  'external_data_batch/redshift' = 'external_data_batch/redshift',
  'external_data_stream/kinesis' = 'external_data_stream/kinesis',
  'external_data_stream/kafka' = 'external_data_stream/kafka',
  'external_model' = 'external_model',
  'external_model_trainer' = 'external_model_trainer',
  'initials' = 'initials',
}

const NodeTypeMap = Object.entries(NODE_TYPES).reduce((nodeTypeMap: any, entry: any, index: number) => {
  nodeTypeMap[`${entry[0]}`] = entry[1];
  return nodeTypeMap;
}, {});

interface NodeDataType {
  type: string;
  subtype?: string;
  name: string;
}

export { NODE_TYPES, NodeTypeMap };
export type { NodeDataType };
