import { FC, useContext, useState } from 'react';
import { Navigate, Route, Routes, useNavigate, useParams } from 'react-router';
import { Routes as AppRoutes } from '../../../core/routes';
import ACLMembershipOutlet from './ACLMembershipOutlet';
import AddWorkspaceAccess from './AddWorkspaceAccess';
import ModifyWorkspaceAccess from './ModifyWorkspaceAccess';

import { useUserSettings } from '../../context/UserSettingsContext';
import ACLPrincipalGroupMembershipList from './ACLPrincipalGroupMembershipList';
import ACLPrincipalWorkspaceRoleList from './ACLPrincipalWorkspaceRoleList';
import ACLUserDetailsCard from './ACLUserDetailsCard';
import AssignGroup from './AssignGroup';
import Delete from './Delete';
import ModifyUserAccountType from './ModifyUserAccountType';

// mutation hooks
import {
  useAddPrincipalGroupMembersMutation,
  useAssignRolesMutation,
  useClusterUserActionMutation,
  useDeleteUserMutation,
  useRemovePrincipalGroupMembersMutation,
} from '../../../api/acl';

// generated code
import { AclWorkspaceServiceRelationship, Group } from '../../../api/gql/graphql';
import { useGetACLUserDetails } from '../../../api/queries/aclGraphql';
import { PrincipalType } from '../../../types/tecton_proto/auth/principal';
import { ResourceType } from '../../../types/tecton_proto/auth/resource';

// @tecton
import {
  AsymmetricBodyLayout,
  EmptyPrompt,
  ErrorPromptButtons,
  Modal,
  getToastComponent,
} from '@tecton/ComponentRedesign';
import { ToastContext, addToast } from '@tecton/ToastContext';

// utils
import { logEvent } from '../../../utils/analytics-utils';
import { getLegacyRoleIdGQL } from '../../acl/aclUtils';
import { getACLAccountRoutes, getRoleDescriptionMap, getRoleOptions, getRoleToPermissionMap } from './acl-util';
import UnlockUser from './UnlockUser';

const ACLUserProfileContainer: FC = () => {
  const { isAdmin } = useUserSettings();
  const { user: email } = useParams();
  const toastContext = useContext(ToastContext);
  const navigate = useNavigate();

  // CRUD (Mutation) hooks
  const { data, isLoading, isError } = useGetACLUserDetails({ email: email ?? '', isAdmin: isAdmin ?? false });
  const deleteUserMutation = useDeleteUserMutation();
  const assignRolesMutation = useAssignRolesMutation();
  const removePrincipalGroupMembersMutation = useRemovePrincipalGroupMembersMutation();
  const addPrincipalGroupMembersMutation = useAddPrincipalGroupMembersMutation();
  const clusterUserActionMutation = useClusterUserActionMutation();

  const user = data?.GetUserDetails?.userDetails.user;
  const userDetails = data?.GetUserDetails?.userDetails;

  const editableRoles = getRoleOptions(
    data?.GetRoles?.roles ?? [],
    data?.GetUserDetails?.userDetails?.editWorkspaceRoleOptionsById ?? [],
    true
  );

  const addWorkspaceRoles = getRoleOptions(
    data?.GetRoles?.roles ?? [],
    data?.GetUserDetails?.userDetails?.addNewWorkspaceRoleOptionsById ?? [],
    false
  );

  const tabCountRecord: Record<string, number> = {
    Workspaces:
      data?.GetUserDetails.userDetails.assignedWorkspacesWithRoles?.filter((assignedWorkspacesWithRole) => {
        if (assignedWorkspacesWithRole.workspaceId === 'All Workspaces') {
          // We don't count "All Workspaces" when it's not set.
          return !(assignedWorkspacesWithRole?.role?.effectiveWorkspaceRole === 'none');
        }
        return true;
      })?.length ?? 0,
    Groups: data?.GetGroups?.assignedGroups?.length ?? 0,
  };

  const roleDescriptionMap = getRoleToPermissionMap(data?.GetRoles?.roles ?? []);
  const accountTypeDescriptions = getRoleDescriptionMap();

  // General states
  const [originalRole, setOriginalRole] = useState<string | undefined>();
  // -- row selection
  const [selectedWorkspaceRole, setSelectedWorkspaceRole] = useState<AclWorkspaceServiceRelationship | undefined>(
    undefined
  );
  // -- radio button selection from modal
  const [selectedRole, setSelectedRole] = useState<string | undefined>();
  const [workspaceSelected, setWorkspaceSelected] = useState<string | undefined>();
  const [selectedGroup, setSelectedGroup] = useState<Group | undefined>();
  const [selectedAccountType, setSelectedAccountType] = useState<string | undefined>(undefined);

  // Modal states
  const [showDeleteUserModal, setShowDeleteUserModal] = useState<boolean>(false);
  const [showUnlockUser, setShowUnlockUser] = useState<boolean>(false);
  const [showEditWorkspaceRoleModal, setShowEditWorkspaceRoleModal] = useState<boolean>();
  const [showAssignWorkspaceAccessModal, setShowAssignWorkspaceAccessModal] = useState<boolean>();
  const [showDeleteGroupAccessModal, setShowDeleteGroupAccessModal] = useState<boolean>();
  const [showAssignGroupAccessModal, setShowAssignGroupAccessModal] = useState<boolean>();
  const [showAccountTypeModal, setShowAccountTypeModal] = useState<boolean>(false);

  if (isError) {
    const emptyPrompt = (
      <EmptyPrompt
        orientation="vertical"
        variant="storm"
        title={<>Error Loading User</>}
        body={
          <>
            <p>We ran into an error while loading this user.</p>
          </>
        }
        actions={<ErrorPromptButtons />}
      />
    );
    return emptyPrompt;
  }

  const reset = () => {
    setShowDeleteUserModal(false);
    setShowEditWorkspaceRoleModal(false);
    setShowDeleteGroupAccessModal(false);
    setShowAssignGroupAccessModal(false);
    setSelectedRole(undefined);
    setWorkspaceSelected(undefined);
    setSelectedGroup(undefined);
    setSelectedWorkspaceRole(undefined);
    setOriginalRole(undefined);
    setShowAssignWorkspaceAccessModal(false);
    setShowAccountTypeModal(false);
  };

  // --------------------------------------- Start: Actions hooks ---------------------------------
  const onDeleteUserClicked = () => {
    setShowDeleteUserModal(true);
  };

  const onUnlockUserClicked = () => {
    setShowUnlockUser(true);
  };

  const onDeleteUserConfirmClicked = () => {
    const payload = {
      okta_id: user?.oktaId ?? '',
    };

    deleteUserMutation.mutate(payload, {
      onSuccess: () => {
        reset();
        logEvent(`Account & Access: User Deleted`, '', {
          email,
        });
        const toastContent = getToastComponent({
          variant: 'success',
          title: `User Deleted`,
          children: <>{email} Deleted</>,
          showIcon: true,
        });

        toastContext?.dispatchToast(
          addToast({
            text: toastContent,
          })
        );

        navigate(`../accounts-and-access/users`);
      },
      onError: () => {
        const toastContent = getToastComponent({
          variant: 'danger',
          title: `Failed to deleted user`,
          children: <>Failed to delete user {email}</>,
          showIcon: true,
        });

        toastContext?.dispatchToast(
          addToast({
            text: toastContent,
          })
        );
      },
    });
  };

  const onUnlockUserConfirmClicked = () => {
    const payload = {
      okta_id: user?.oktaId,
      unlock_user: true,
    };
    clusterUserActionMutation.mutate(payload, {
      onSuccess: () => {
        const toastContent = getToastComponent({
          variant: 'success',
          title: `User Unlock`,
          children: <>{user?.loginEmail} unlock</>,
          showIcon: true,
        });

        toastContext?.dispatchToast(
          addToast({
            text: toastContent,
          })
        );

        logEvent('Unlock User', '', { email: user?.loginEmail });

        setShowUnlockUser(false);
      },
      onError: () => {
        const toastContent = getToastComponent({
          variant: 'danger',
          title: `Failed to unlock user`,
          children: <>Failed to unlock user {user?.loginEmail}</>,
          showIcon: true,
        });

        toastContext?.dispatchToast(
          addToast({
            text: toastContent,
          })
        );
      },
    });
  };

  const onEditRole = (workspaceRole: AclWorkspaceServiceRelationship) => {
    setShowEditWorkspaceRoleModal(true);
    setSelectedWorkspaceRole(workspaceRole);
    setOriginalRole(workspaceRole?.role?.effectiveWorkspaceRole);
    setSelectedRole(workspaceRole?.role?.effectiveWorkspaceRole);
  };

  const onRoleChange = (roleName: string) => {
    setSelectedRole(roleName);
  };

  const onModifyWorkspaceRole = () => {
    const removingRole = selectedRole === 'None';
    const principal_type = PrincipalType.PRINCIPAL_TYPE_USER;

    const role = getLegacyRoleIdGQL(data?.GetRoles?.roles ?? [], selectedRole ?? ''); // fix this.
    const roles = removingRole ? [] : [role ?? ''];
    const resource_id = selectedWorkspaceRole?.workspaceId;
    const isAllWorkspaces = resource_id === 'All Workspaces';

    const resource_type = isAllWorkspaces
      ? ResourceType.RESOURCE_TYPE_ORGANIZATION
      : ResourceType.RESOURCE_TYPE_WORKSPACE;

    if (
      isAllWorkspaces &&
      data?.GetUserDetails?.userDetails?.accountTypeWithInheritance?.effectiveAccountType === 'Admin'
    ) {
      roles.unshift('admin_role');
    }

    const payload = {
      principal_id: user?.oktaId,
      roles,
      ...(!isAllWorkspaces && { resource_id }),
      principal_type,
      resource_type,
    };

    assignRolesMutation.mutate(payload, {
      onSuccess: () => {
        reset();
        logEvent(`Account & Access: User ${removingRole ? 'Workspace Removed' : 'Workspace Role Updated'}`, '', {
          email: user?.loginEmail,
          workspace: selectedWorkspaceRole?.workspaceId,
          role: selectedRole,
        });
        const toastContent = getToastComponent({
          variant: 'success',
          title: removingRole ? `Workspace Removed` : `Workspace Role Updated`,
          children: removingRole ? (
            <>Workspace {selectedWorkspaceRole?.workspaceId} removed.</>
          ) : (
            <>Workspace Role Updated to {selectedRole}</>
          ),
          showIcon: true,
        });

        toastContext?.dispatchToast(
          addToast({
            text: toastContent,
          })
        );
      },
      onError: () => {
        const toastContent = getToastComponent({
          variant: 'danger',
          title: `Failed to Updated Role`,
          children: <>Failed to update role. Please try again.</>,
          showIcon: true,
        });

        toastContext?.dispatchToast(
          addToast({
            text: toastContent,
          })
        );
      },
    });
  };

  const onAddUserToWorkspace = () => {
    const principal_type = PrincipalType.PRINCIPAL_TYPE_USER;
    const role = getLegacyRoleIdGQL(data?.GetRoles?.roles ?? [], selectedRole ?? '');
    const roles = [role ?? ''];
    const resource_id = workspaceSelected;

    const resource_type = ResourceType.RESOURCE_TYPE_WORKSPACE;

    const payload = {
      principal_id: user?.oktaId,
      roles,
      resource_id,
      principal_type,
      resource_type,
    };

    assignRolesMutation.mutate(payload, {
      onSuccess: () => {
        reset();
        logEvent('Account & Access: User added to workspace', '', {
          email: user?.loginEmail,
          workspace: workspaceSelected,
          role: selectedRole,
        });
        const toastContent = getToastComponent({
          variant: 'success',
          title: `User added to workspace`,
          children: (
            <>
              User {user?.loginEmail} was added to workspace {workspaceSelected}
            </>
          ),
          showIcon: true,
        });

        toastContext?.dispatchToast(
          addToast({
            text: toastContent,
          })
        );
      },
      onError: () => {
        const toastContent = getToastComponent({
          variant: 'danger',
          title: `Failed to add user`,
          children: (
            <>
              Failed to add User {user?.loginEmail} to workspace {workspaceSelected}. Please try again.
            </>
          ),
          showIcon: true,
        });

        toastContext?.dispatchToast(
          addToast({
            text: toastContent,
          })
        );
      },
    });
  };

  const onAssignWorkspaceAccess = () => {
    setShowAssignWorkspaceAccessModal(true);
  };

  const onWorkspaceSelected = (workspace: string) => {
    setWorkspaceSelected(workspace);
  };

  const onEditGroup = (group: Group) => {
    setShowDeleteGroupAccessModal(true);
    setSelectedGroup(group);
  };

  const onDeleteGroupConfirmClicked = () => {
    const payload = {
      id: `${selectedGroup?.id}`,
      members: [{ principal_type: PrincipalType.PRINCIPAL_TYPE_USER, principal_id: `${user?.oktaId}` }],
    };
    removePrincipalGroupMembersMutation.mutate(payload, {
      onSuccess: () => {
        reset();
        logEvent('Account & Access: User removed from group', '', {
          email: user?.loginEmail,
          group_id: selectedGroup?.id,
          group_name: selectedGroup?.name,
        });
        const toastContent = getToastComponent({
          variant: 'success',
          title: `User removed from group`,
          children: (
            <>
              {user?.oktaId} was removed from group {selectedGroup?.name}.
            </>
          ),
          showIcon: true,
        });

        toastContext?.dispatchToast(
          addToast({
            text: toastContent,
          })
        );
      },

      onError: () => {
        const toastContent = getToastComponent({
          variant: 'danger',
          title: `Failed to remove user from group`,
          children: (
            <>
              Failed to remove {user?.oktaId} from group {selectedGroup?.name}. Please try again.
            </>
          ),
          showIcon: true,
        });

        toastContext?.dispatchToast(
          addToast({
            text: toastContent,
          })
        );
      },
    });
  };

  const onAssignGroupAccess = () => {
    setShowAssignGroupAccessModal(true);
  };

  const onAssignGroupConfirm = (group?: Group) => {
    setSelectedGroup(group);
  };

  const onConfirmAssignGroup = () => {
    const userMembersToAdd = [{ principal_type: PrincipalType.PRINCIPAL_TYPE_USER, principal_id: `${user?.oktaId}` }];
    const payload = {
      principal_group_id: `${selectedGroup?.id}`,
      members: userMembersToAdd,
    };

    addPrincipalGroupMembersMutation.mutate(payload, {
      onSuccess: () => {
        reset();

        logEvent('Account & Access: User added to group', '', {
          email: user?.loginEmail,
          group_id: selectedGroup?.id,
          group_name: selectedGroup?.name,
        });

        const toastContent = getToastComponent({
          variant: 'success',
          title: `User added to group`,
          children: (
            <>
              {user?.loginEmail} was added to group {selectedGroup?.name}.
            </>
          ),
          showIcon: true,
        });

        toastContext?.dispatchToast(
          addToast({
            text: toastContent,
          })
        );
      },
      onError: () => {
        const toastContent = getToastComponent({
          variant: 'danger',
          title: `Failed to add user to group`,
          children: (
            <>
              Failed to add {user?.loginEmail} to group {selectedGroup?.name}. Please try again.
            </>
          ),
          showIcon: true,
        });

        toastContext?.dispatchToast(
          addToast({
            text: toastContent,
          })
        );
      },
    });
  };

  const onSelectedAccountType = (selectedAccountType: string) => {
    setSelectedAccountType(selectedAccountType);
  };

  const onConfirmAccountTypeChange = () => {
    const payload =
      selectedAccountType === 'Admin'
        ? { okta_id: `${data?.GetUserDetails?.userDetails.user?.oktaId}`, grant_admin: true }
        : { okta_id: `${data?.GetUserDetails?.userDetails.user?.oktaId}`, revoke_admin: true };

    clusterUserActionMutation.mutate(payload, {
      onSuccess: () => {
        reset();

        logEvent('Account & Access: User Account Type Updated', '', {
          account_type: selectedAccountType,
          email: email,
        });

        const toastContent = getToastComponent({
          variant: 'success',
          title: `Account Type Updated`,
          children: (
            <>
              {data?.GetUserDetails?.userDetails.user?.loginEmail} account type has been updated to{' '}
              {selectedAccountType}
            </>
          ),
          showIcon: true,
        });

        toastContext?.dispatchToast(
          addToast({
            text: toastContent,
          })
        );
      },
      onError: () => {
        const toastContent = getToastComponent({
          variant: 'danger',
          title: `Updated Failed`,
          children: <>Update to {data?.GetUserDetails?.userDetails.user?.loginEmail} has failed. Please try again.</>,
          showIcon: true,
        });

        toastContext?.dispatchToast(
          addToast({
            text: toastContent,
          })
        );
      },
    });
  };

  // --------------------------------------- Start: Actions hooks ---------------------------------
  const hasRoleFromOtherSources = (selectedWorkspaceRole?.role?.roleSources.length ?? 0) > 0;
  const roleAssignmentSources =
    selectedWorkspaceRole?.role?.roleSources?.map((roleSource) => {
      return {
        principalGroupId: roleSource.groupId,
        principalGroupName: roleSource?.groupName,
      };
    }) ?? [];
  const leftSideUserDetailsCard = (
    <>
      {showDeleteUserModal && (
        <Modal
          title={`Delete ${user?.loginEmail}`}
          body={<Delete />}
          onCancel={() => {
            setShowDeleteUserModal(false);
          }}
          confirmLabel="Delete"
          onConfirm={onDeleteUserConfirmClicked}
          variant="negative"
          isConfirmButtonLoading={deleteUserMutation.isLoading}
        />
      )}

      {showEditWorkspaceRoleModal && (
        <Modal
          title={`Modify role of ${user?.loginEmail} in ${selectedWorkspaceRole?.workspaceId} `}
          body={
            <>
              <ModifyWorkspaceAccess
                id={email}
                roles={editableRoles}
                workspaceRole={selectedWorkspaceRole?.role?.effectiveWorkspaceRole}
                roleDescriptionMap={roleDescriptionMap}
                onRoleChange={onRoleChange}
                hasRoleFromOtherSources={hasRoleFromOtherSources}
                roleAssignmentSources={roleAssignmentSources}
              />
            </>
          }
          onCancel={() => {
            setShowEditWorkspaceRoleModal(false);
            setOriginalRole(undefined);
            setSelectedWorkspaceRole(undefined);
            setWorkspaceSelected(undefined);
          }}
          confirmLabel="Change Access"
          onConfirm={() => {
            onModifyWorkspaceRole();
          }}
          variant="positive"
          confirmVariant={originalRole !== selectedRole ? 'primaryAction' : 'disabledAction'}
          isConfirmButtonLoading={assignRolesMutation.isLoading}
          maxWidth={1000}
        />
      )}

      {showAssignWorkspaceAccessModal && (
        <Modal
          title={`Add User to Workspace`}
          body={
            <>
              <AddWorkspaceAccess
                roles={addWorkspaceRoles}
                assignableWorkspaces={data?.GetUserDetails?.userDetails?.assignableWorkspaces ?? []}
                workspaceRole={selectedWorkspaceRole}
                roleDescriptionMap={roleDescriptionMap}
                onRoleChange={onRoleChange}
                onWorkspaceSelected={onWorkspaceSelected}
              />
            </>
          }
          onCancel={() => {
            setShowAssignWorkspaceAccessModal(false);

            setSelectedWorkspaceRole(undefined);
            setSelectedRole(undefined);
            setWorkspaceSelected(undefined);
          }}
          confirmLabel="Add User"
          onConfirm={() => {
            onAddUserToWorkspace();
          }}
          variant="positive"
          confirmVariant={selectedRole !== undefined && workspaceSelected ? 'primaryAction' : 'disabledAction'}
          isConfirmButtonLoading={assignRolesMutation.isLoading}
          maxWidth={1000}
        />
      )}

      {showDeleteGroupAccessModal && (
        <Modal
          title={`Delete ${user?.loginEmail} from ${selectedGroup?.name}`}
          body={
            <Delete message={`Are you sure you want to delete ${user?.loginEmail} from  ${selectedGroup?.name}?`} />
          }
          onCancel={() => {
            setSelectedGroup(undefined);
            setShowDeleteGroupAccessModal(false);
          }}
          confirmLabel="Delete"
          onConfirm={onDeleteGroupConfirmClicked}
          variant="negative"
          isConfirmButtonLoading={removePrincipalGroupMembersMutation.isLoading}
        />
      )}

      {showAssignGroupAccessModal && (
        <Modal
          title={`Add User to Group`}
          body={
            <>
              <AssignGroup
                assignableGroups={data?.GetGroups.assignableGroups ?? []}
                onAssignGroupConfirm={onAssignGroupConfirm}
              />
            </>
          }
          onCancel={() => {
            setSelectedGroup(undefined);
            setShowAssignGroupAccessModal(false);
          }}
          confirmLabel="Add Group"
          onConfirm={() => {
            onConfirmAssignGroup();
          }}
          variant="positive"
          confirmVariant={selectedGroup !== undefined ? 'primaryAction' : 'disabledAction'}
          isConfirmButtonLoading={addPrincipalGroupMembersMutation.isLoading}
        />
      )}

      {showAccountTypeModal && (
        <Modal
          title={`Modify Account Type of ${data?.GetUserDetails?.userDetails.user?.loginEmail}`}
          body={
            <ModifyUserAccountType
              user={data?.GetUserDetails?.userDetails.user}
              selectedAccountType={data?.GetUserDetails?.userDetails?.accountTypeWithInheritance?.effectiveAccountType}
              roleDescriptionMap={accountTypeDescriptions}
              onSelectionClicked={onSelectedAccountType}
            />
          }
          onCancel={() => {
            reset();
          }}
          confirmLabel="Change assigned Account Type to Admin"
          onConfirm={onConfirmAccountTypeChange}
          variant="positive"
          confirmVariant={
            data?.GetUserDetails?.userDetails.user?.accountType === selectedAccountType
              ? 'disabledAction'
              : 'primaryAction'
          }
          isConfirmButtonLoading={clusterUserActionMutation.isLoading}
        />
      )}

      {showUnlockUser && (
        <Modal
          title={`Unlock ${user?.loginEmail}`}
          body={<UnlockUser message={`Are you sure you want to unlock ${user?.loginEmail}?`} />}
          onCancel={() => {
            setShowUnlockUser(false);
          }}
          confirmLabel="Unlock"
          onConfirm={onUnlockUserConfirmClicked}
          variant="negative"
          isConfirmButtonLoading={clusterUserActionMutation.isLoading}
        />
      )}

      <ACLUserDetailsCard
        userDetail={userDetails}
        isAdminView={isAdmin ?? false}
        onDeleteUserClicked={onDeleteUserClicked}
        onUnlockUserClicked={onUnlockUserClicked}
        onEditAccountTypeClicked={() => {
          setShowAccountTypeModal(true);
        }}
        assignedGroups={data?.GetGroups?.assignedGroups ?? []}
      />
    </>
  );
  const aclUserRoutes = getACLAccountRoutes();

  const tabBodySelected = (
    <>
      <Routes>
        <Route
          element={
            <ACLMembershipOutlet path={`${AppRoutes.accountsAndAccessUsers}/:users`} tabCountRecord={tabCountRecord} />
          }
        >
          <Route index element={<Navigate to={`${aclUserRoutes[`Workspaces`]}`} replace />} />
          <Route
            path={aclUserRoutes[`Workspaces`]}
            element={
              <>
                <ACLPrincipalWorkspaceRoleList
                  assignedWorkspaceRoles={data?.GetUserDetails.userDetails?.assignedWorkspacesWithRoles ?? []}
                  isAdminView={isAdmin ?? false}
                  onEditRole={onEditRole}
                  isLoading={isLoading ?? false}
                  onAssignWorkspaceAccess={onAssignWorkspaceAccess}
                />
              </>
            }
          />
          <Route
            path={aclUserRoutes[`Groups`]}
            element={
              <>
                <ACLPrincipalGroupMembershipList
                  assignedGroups={data?.GetGroups.assignedGroups ?? []}
                  principalName={user?.loginEmail ?? ''}
                  isAdminView={isAdmin ?? false}
                  onEditGroup={onEditGroup}
                  isLoading={isLoading ?? false}
                  onAssignGroupAccess={onAssignGroupAccess}
                />
              </>
            }
          />
        </Route>
      </Routes>
    </>
  );

  const body = isLoading ? (
    <EmptyPrompt variant="loading" title={<>Loading User</>} body={<></>} orientation="vertical" />
  ) : (
    <AsymmetricBodyLayout leftSide={leftSideUserDetailsCard} rightSide={tabBodySelected} />
  );

  return <>{body}</>;
};

export default ACLUserProfileContainer;
