import React from 'react';
import styled from '@emotion/styled';
import { Accordion } from '@tecton';

const AccordionSection = styled.section`
  border-top: 1px solid rgb(211, 218, 230);

  .euiAccordion__triggerWrapper {
    padding: 4px 0;

    .euiAccordion__button {
      margin-left: 2px;
      text-decoration: none;

      .euiTitle {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 14px;
        letter-spacing: 0.1em;
        color: #5c5e70;
      }

      &:hover {
        text-decoration: none;
      }
    }

    button.euiButtonIcon {
      margin-left: 8px;

      path {
        fill: #6b707c;
      }

      &:hover {
        background: none;
      }
    }

    &:hover {
      background: rgba(211, 218, 230, 0.2);
    }
  }

  .euiAccordion__childWrapper > div {
    border-top: 1px solid #ddd;
  }
`;

const DetailsPanelAccordionSection = ({
  id,
  title,
  children,
  initialIsOpen,
}: {
  id: string;
  title: React.ReactNode;
  children: React.ReactNode;
  initialIsOpen?: boolean;
}) => {
  return (
    <AccordionSection>
      <Accordion id={id} buttonContent={title} initialIsOpen={initialIsOpen} paddingSize="m">
        {children}
      </Accordion>
    </AccordionSection>
  );
};

export default DetailsPanelAccordionSection;
