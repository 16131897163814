import React, { useEffect, VFC } from 'react';
import { Divider, Stack, Typography } from '@mui/material';
import { ConfigureDatabricks, ContentAndDocsTwoColumnLayout } from '../../../components';
import Title from '../../../utils/title-utils';
import ConfigureDatabricksHelp from './ConfigureDatabricksHelp';
import { useDatabricksMetadataService } from './useConfigureDatabricksMetadataService';
import { logEvent } from '../../../utils/analytics-utils';
import AdminsOnlyPage from '../utils/AdminsOnly/AdminsOnlyPage';

const ConfigureDatabricksPage: VFC<{}> = () => {
  const { validateDatabricksConnectionRequest } = useDatabricksMetadataService();

  useEffect(() => {
    logEvent('Configure Databricks');
  }, []);
  return (
    <AdminsOnlyPage>
      <Title title={'Configure Databricks'} />
      <ContentAndDocsTwoColumnLayout
        mainColumn={
          <Stack spacing={2}>
            <Typography variant="h1" style={{ fontWeight: 'bold' }}>
              Configure Databricks
            </Typography>
            <Typography variant="body1">
              Tecton integrates with your existing Databricks workspace to transform and materialize data. Follow the
              steps below to connect Tecton to Databricks. If you need help, reach out to us at{' '}
              <a href="mail:support@tecton.ai">support@tecton.ai</a>.
            </Typography>
            <Divider />
            <ConfigureDatabricks validateDatabricksConnectionRequest={validateDatabricksConnectionRequest} />
          </Stack>
        }
        docsColumn={<ConfigureDatabricksHelp />}
      />
    </AdminsOnlyPage>
  );
};

export default ConfigureDatabricksPage;
