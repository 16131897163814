import InfoIcon from '@mui/icons-material/Info';
import { ChartLoading } from '@tecton';
import { EmptyPrompt } from '@tecton/ComponentRedesign';
import _ from 'lodash';
import { FC } from 'react';
import { Line } from 'react-chartjs-2';
import { getChartOptions, transformGraphiteResponse } from '../../feature/graph-util';
import { useGraphite } from '../../feature/query';
import HtmlTooltip from '../shared/components/HtmlTooltip/HtmlTooltip';
import { MonitoringDateRange } from './DateRange-Dropdown-util';
import styles from './monitoring.module.scss';
import useChartJsRegister from './useChartJsRegister';

interface GraphiteGraphProps {
  chartTitle: string;
  monitoringDateRange: MonitoringDateRange;
  parameters: string[][];
  xAxisLabel: string;
  yAxisLabel: string;
  yTicksCallback: any;
  yGrace: string | number;
  yOverride?: any;
  pluginLegendOverride?: any;
  toolTipLabelCallback: any;
  graphInfoTooltip?: string;
  height?: number;
}

const GraphiteGraph: FC<GraphiteGraphProps> = ({
  chartTitle,
  monitoringDateRange,
  parameters,
  xAxisLabel,
  yAxisLabel,
  yGrace,
  yTicksCallback,
  yOverride,
  pluginLegendOverride,
  toolTipLabelCallback,
  graphInfoTooltip,
  height,
}) => {
  const state = useGraphite(parameters, monitoringDateRange);
  useChartJsRegister();

  if (state.isLoading) {
    return <ChartLoading />;
  }

  const dataset = transformGraphiteResponse(_.clone(state.data));

  if (dataset.length === 0) {
    return (
      <>
        <EmptyPrompt title={<>No Data Available</>} body={<></>} orientation="vertical" variant="search" />
      </>
    );
  }

  const data = {
    datasets: dataset,
  };

  const chartYOverride = yOverride
    ? yOverride
    : {
        title: {
          display: true,
          text: yAxisLabel,
          font: {
            size: 12,
          },
        },
        min: 0,
        ticks: {
          callback: yTicksCallback,
        },
      };

  const chartOption: any = getChartOptions({
    title: chartTitle,
    xTitle: xAxisLabel,
    yTitle: yAxisLabel,
    yGrace,
    yTicksCallback,
    toolTipLabelCallback,
    unit: monitoringDateRange.unit,
    yOverride: chartYOverride,
    pluginLegendOverride,
  });

  return (
    <>
      {graphInfoTooltip && (
        <div className={styles['tooltipWrapper']}>
          <div className={styles['tooltipContainer']}>
            <HtmlTooltip title={graphInfoTooltip}>
              <InfoIcon style={{ fontSize: 18 }} />
            </HtmlTooltip>
          </div>
        </div>
      )}
      <Line options={chartOption} data={data} height={height} />
    </>
  );
};

export default GraphiteGraph;
