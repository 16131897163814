import React, { FC, useState } from 'react';
import _debounce from 'lodash/debounce';

// @tecton
import { FlexGroup, Text, TextArea, FormRow, FlexGroupWrapper } from '@tecton/ComponentRedesign';
import FormInput from '@tecton/ComponentRedesign/lib/FormInput';

interface CreateGroupAccountProps {
  hasDuplicateGroupName: boolean;
  onChange: (groupName: string, description: string) => void;
}

const CreateGroup: FC<CreateGroupAccountProps> = ({ hasDuplicateGroupName = false, onChange }) => {
  const [groupName, setGroupName] = useState<string>('');
  const [description, setDescription] = useState<string>('');

  const onGroupNameChange = (event: any) => {
    const value = event?.target?.value?.trim();
    setGroupName(value);
    onChange(value, description);
  };

  const onDescriptionOnChange = (event: any) => {
    const value = event?.target?.value?.trim();
    setDescription(value);
    onChange(groupName, value);
  };

  return (
    <>
      <FlexGroupWrapper gap="l" direction="column">
        <FormInput
          label={'Group Name'}
          onChange={_debounce(onGroupNameChange, 250)}
          error={hasDuplicateGroupName}
          errorText={hasDuplicateGroupName && `A group with the ID "${groupName}" already exists.`}
          fullWidth
        />
        <FormRow label="Description" fullWidth>
          <TextArea fullWidth data-testid={'description'} onChange={_debounce(onDescriptionOnChange, 250)} />
        </FormRow>

        <Text>
          You can add users & service accounts, assign workspace access, and configure identity provider attributes once
          the group has been created.
        </Text>
      </FlexGroupWrapper>
    </>
  );
};

export default CreateGroup;
