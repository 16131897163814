import MetadataService from '../service/MetadataService';
import { useQuery } from '@tanstack/react-query';
import { MonitoringDateRange } from '../components/monitoring/DateRange-Dropdown-util';

const REFETCH_INTERVAL = 1000 * 60;

export function useFcoContainer(workspace: string) {
  const state = useQuery(['workspace', workspace, 'fcoContainer'], () => {
    return MetadataService.sharedInstance().getFcoContainer();
  });
  if (state.error) {
    throw state.error;
  }
  return state;
}

export const usePrometheus = (
  queries: { label: string; query: string }[],
  monitoringDateRange: MonitoringDateRange
) => {
  /*
    This is the place we were used the requestId. We had chose to call the startTime and endTime function,
    we will end up in an infinite loop.
  */
  const state = useQuery(
    ['query', queries.map((e) => e.query).join(''), monitoringDateRange.requestId, 'prometheus'],
    () => {
      return MetadataService.sharedInstance().getPrometheusQueryResult(queries, monitoringDateRange);
    },
    {
      // Refetch the data every 1 minute
      refetchInterval: REFETCH_INTERVAL,
      cacheTime: 0,
    }
  );
  if (state.error) {
    throw state.error;
  }
  return state;
};

export const useGraphite = (params: string[][], monitoringDateRange: MonitoringDateRange) => {
  const encodedBody = params.map((paramAndValue) => {
    return `${encodeURIComponent(paramAndValue[0])}=${encodeURIComponent(paramAndValue[1])}`;
  });
  // We do this to create part of the cache key
  const formBodyJoined = encodedBody.join('&');

  const state = useQuery(
    ['query', formBodyJoined, monitoringDateRange.requestId, 'graphite'],
    () => {
      return MetadataService.sharedInstance().getGraphiteQueryResult(params, monitoringDateRange);
    },
    {
      // Refetch the data every 1 minute
      refetchInterval: REFETCH_INTERVAL,
    }
  );
  if (state.error) {
    throw state.error;
  }
  return state;
};
