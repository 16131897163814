import React from 'react';
import { Position } from '@xyflow/react';
import styled from '@emotion/styled';
import { TransformationNode, DataflowCardPropType } from './dataFlowTypes';
import { SvgWithGlowWrapper } from './VisualPrimitives/CardTypography';
import DataFlowHandle from './DataFlowHandle';
import { Icons, IconTypes } from '@tecton/ComponentRedesign';
import { Link } from 'react-router-dom';
import { Routes as AppRoutes } from '../../../../core/routes';

const WIDTH = 150;
const HEIGHT = 60;

const Wrapper = styled.div<{ isFaded?: boolean }>`
  width: ${WIDTH}px;
  position: relative;
  opacity: ${(props) => (props.isFaded ? 0.15 : 1)};
  cursor: pointer;
  overflow: visible;
  background: rgba(0, 0 255, 0.5);
  height: ${HEIGHT}px;
  transform: translateY(-${HEIGHT / 2 - 5}px);
  padding: 0px 16px;
  border: 1px solid #eee;
  background-color: ${({ theme }) => theme.colors.emptyShade};

  path,
  ellipse,
  rect,
  circle {
    stroke: ${({ theme }) => theme.FCOColors.transformation};
  }
`;

const TruncateName = styled.div`
  width: ${WIDTH};
  overflow: hidden;
  text-overflow: ellipsis;
`;

const DataFlowTransformation = ({
  data,
  onMouseEnter,
  onMouseLeave,
  onClick,
  hasFocus,
  isFaded,
  workspace,
}: { data: TransformationNode } & DataflowCardPropType) => {
  const url = AppRoutes.transformation
    .replace(':workspace', workspace ?? '')
    .replace(':name', data.name)
    .replace('/*', '');

  return (
    <>
      <Link to={url}>
        <Wrapper onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onClick={onClick} isFaded={isFaded}>
          <TruncateName>{data.name}</TruncateName>
          <SvgWithGlowWrapper hasFocus={hasFocus} inset={[4, 2]}>
            {Icons[IconTypes.TRANSFORMATIONS]}
          </SvgWithGlowWrapper>
        </Wrapper>
        <DataFlowHandle position={Position.Left} top={`6px`} />
        <DataFlowHandle position={Position.Right} top={`6px`} />
      </Link>
    </>
  );
};

export default DataFlowTransformation;
