/* eslint-disable */
import { StartJobRequest } from "../spark_api/jobs";

export const protobufPackage = "tecton_proto.materialization";

export enum TaskType {
  /** UNKNOWN - protolint:disable:this ENUM_FIELD_NAMES_ZERO_VALUE_END_WITH */
  UNKNOWN = "UNKNOWN",
  BATCH = "BATCH",
  STREAMING = "STREAMING",
  INGEST = "INGEST",
  DELETION = "DELETION",
  DELTA_MAINTENANCE = "DELTA_MAINTENANCE",
  FEATURE_EXPORT = "FEATURE_EXPORT",
  DATASET_GENERATION = "DATASET_GENERATION",
  PLAN_INTEGRATION_TEST_BATCH = "PLAN_INTEGRATION_TEST_BATCH",
  PLAN_INTEGRATION_TEST_STREAM = "PLAN_INTEGRATION_TEST_STREAM",
}

export enum TaskTypeForDisplay {
  /** UNKNOWN_JOB - protolint:disable:this ENUM_FIELD_NAMES_ZERO_VALUE_END_WITH */
  UNKNOWN_JOB = "UNKNOWN_JOB",
  BATCH_JOB = "BATCH_JOB",
  STREAMING_JOB = "STREAMING_JOB",
  INGEST_JOB = "INGEST_JOB",
  DELETION_JOB = "DELETION_JOB",
  DELTA_MAINTENANCE_JOB = "DELTA_MAINTENANCE_JOB",
  FEATURE_EXPORT_JOB = "FEATURE_EXPORT_JOB",
  DATASET_GENERATION_JOB = "DATASET_GENERATION_JOB",
  PLAN_INTEGRATION_TEST_BATCH_JOB = "PLAN_INTEGRATION_TEST_BATCH_JOB",
  PLAN_INTEGRATION_TEST_STREAM_JOB = "PLAN_INTEGRATION_TEST_STREAM_JOB",
  COMPACTION_JOB = "COMPACTION_JOB",
}

export enum SparkExecutionEnvironment {
  ENV_UNSPECIFIED = "ENV_UNSPECIFIED",
  ENV_DATABRICKS_NOTEBOOK = "ENV_DATABRICKS_NOTEBOOK",
  ENV_EMR = "ENV_EMR",
  ENV_DATAPROC = "ENV_DATAPROC",
}

/** Next ID: 12 */
export interface SparkClusterEnvironment {
  /**
   * Version number (primary key) automatically incremented by PostgreSQL serial
   * sequencing. The highest version number is used for creating a new
   * SparkCluster.
   */
  spark_cluster_environment_version?: number | undefined;
  job_request_templates?:
    | JobRequestTemplates
    | undefined;
  /**
   * The version number of the user deployment settings that was merged into
   * this cluster environment.
   */
  merged_user_deployment_settings_version?: number | undefined;
}

export interface JobRequestTemplates {
  /** Arguments for run submission templates per SparkExecutionEnvironment name */
  databricks_template?: StartJobRequest | undefined;
  emr_template?: StartJobRequest | undefined;
}
