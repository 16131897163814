import React, { FC } from 'react';
import { Text } from '..';
import { useTectonTheme } from '../../Theme/ThemeProvider';
import styled from '@emotion/styled';

interface KeyValueListItem {
  title: React.ReactNode;
  content: React.ReactNode;
}

interface KeyValueListProps {
  items: KeyValueListItem[];
}

const ListWrapper = styled.div(({ theme }) => {
  const singleLineStyle = {
    div: {
      '.innerCell:first-of-type': {
        marginTop: theme.v1.size['3'],
        padding: theme.v1.size['0'],
        'padding-bottom': `${theme.v1.size['2']}px`,
      },
      '.innerCell:last-of-type': {
        marginBottom: theme.v1.size['3'],
        padding: theme.v1.size['0'],
      },
    },
  };
  return {
    display: 'block',
    'container-type': 'inline-size',
    [`@container (max-width: ${theme.v1.viewports.narrow / 2}px)`]: {
      '& > div:first-of-type': {
        gridTemplateColumns: '1fr',
        gap: 0,
      },
      ...singleLineStyle,
    },

    // Regular breakpoint
    [`@media (min-width: ${theme.v1.viewports.regular}px)`]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
      [`@container (max-width: ${theme.v1.viewports.regular / 2}px)`]: {
        '& > div:first-of-type': {
          gridTemplateColumns: '1fr',
          gap: 0,
        },
        ...singleLineStyle,
      },
    },

    // Wide breakpoint
    [`@media (min-width: ${theme.v1.viewports.wide}px)`]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
      [`@container (max-width: ${theme.v1.viewports.wide / 2}px)`]: {
        '& > div:first-of-type': {
          gridTemplateColumns: '1fr',
          gap: 0,
        },
        ...singleLineStyle,
      },
    },
  };
});

const List = styled.div`
  display: grid;
  grid-template-columns: auto 1fr; // Default two-row layout
  gap: ${({ theme }) => theme.v1.size['2']}px;
`;

const Cell = styled.div`
  padding: ${({ theme }) => theme.v1.size['2']}px;
`;

const Row = styled.div`
  display: contents; // Rows don't actually affect the layout, they're only there to make handle responsiveness
`;

const KeyValueList: FC<KeyValueListProps> = ({ items }) => {
  const { theme } = useTectonTheme();

  return (
    <ListWrapper>
      <List>
        {items.map((item) => {
          return (
            <>
              <Row>
                <Cell className="innerCell">
                  <Text color={theme.v1.colors.text.subduedText} weight="light">
                    {item.title}
                  </Text>
                </Cell>
                <Cell className="innerCell">
                  <Text weight="medium" color={theme.v1.colors.text.title}>
                    {item.content}
                  </Text>
                </Cell>
              </Row>
            </>
          );
        })}
      </List>
    </ListWrapper>
  );
};

export default KeyValueList;
