import styled from '@emotion/styled';
import _debounce from 'lodash/debounce';
import React, { useEffect, useState } from 'react';

import {
  Breadcrumbs,
  Button,
  Callout,
  EnabledDisabledBadge,
  FieldText,
  FlexGrid,
  FlexGroupWrapper,
  FlexItem,
  Form,
  FormRow,
  HeaderLayout,
  Health,
  HorizontalRule,
  LoadingChart,
  LoadingSpinner,
  SplitPanel,
  Switch,
  Text,
  ViewLayout,
} from '@tecton/ComponentRedesign';

import { useGetOnboardingStatus } from '../../../api/onboarding';
import { useGetInternalSparkClusterStatusQuery } from '../../../api/sparkClusterStatus';
import {
  USER_SETTINGS_RESPONSE,
  useGetDataPlatformSetupStatusQuery,
  useGetUserDeploymentSettingsQuery,
  useUpdateUserDeploymentSettingsMutation,
} from '../../../api/userDeploymentSettings';
import { updateObjectProperties } from '../../../utils/object-util';
import { useUserSettings } from '../../context/UserSettingsContext';
import {
  dataPlatformConfigFormSchema,
  getFieldMaskValues,
  mapDPOSubmission,
} from '../../data-platform-configuration/DPOUtils';
import { ErrorsInterface, SubmissionInterface } from '../../data-platform-configuration/types';

import { scrollIntoViewByElementId } from '../../../utils/scrollIntoViewCallback';
import {
  ConfigurationStatusType,
  sparkClusterStatusToHealthMap,
  sparkClusterStatusToResultMood,
} from '../../data-platform-configuration/DataPlatformTypes';
import { SPARK_STATUS } from './consts';
import DataPlatformHelpColumn from './DataPlatformHelpColumn';
import FormErrorFeedback from './FormErrorFeedback';
import FormProgressFeedback from './FormProgressFeedback';
import { ConfigurationCautionWrapper } from './StyledComponents';

// generated code
import { OnboardingStatusEnum } from '../../../types/tecton_proto/data/onboarding';
import {
  GetDataPlatformSetupStatusResponse,
  GetInternalSparkClusterStatusResponse,
} from '../../../types/tecton_proto/metadataservice/metadata_service';

// icons
import { ReactComponent as ArrowTopRight } from '@svg/arrow-top-right.svg';
import { ReactComponent as Edit } from '@svg/edit.svg';
import { InternalSparkClusterStatusEnum } from '../../../types/tecton_proto/data/internal_spark_cluster_status';

// @types
interface SparkStatusProps {
  data?: GetInternalSparkClusterStatusResponse;
  isLoading?: boolean;
  error?: boolean;
}

type SparkConfigProps = SparkStatusProps &
  USER_SETTINGS_RESPONSE & {
    setIsEditing: (isEditing: boolean) => void;
    feedback?: React.ReactElement;
    canDisableFormButton?: boolean;
  };

const defaultErrors = {
  submission: null,
  api_token: [],
  workspace_url: [],
  instance_profile_arn: [],
  glueCatalogId: [],
};

const INITIAL_DATA: SubmissionInterface = {
  workspace_url: '',
  api_token: '',
  instance_profile_arn: '',
  glueCatalogEnabled: false,
  glueCatalogId: '',
};

const DataPlatformWrapper = styled.div`
  font-weight: ${({ theme }) => theme.font.weight.medium};
  line-height: ${({ theme }) => theme.font.lineHeights.s};
`;

// Main Entry Component
// @DataPlatform Config Edit
const DataPlatformConfigEdit = () => {
  const { isAdmin, workspace } = useUserSettings();
  //  State
  const [formData, setFormData] = useState(INITIAL_DATA);
  const [savedData, setSavedData] = useState(INITIAL_DATA);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [canDisableFormButton, setCanDisableFormButton] = useState(!isAdmin);
  const [isEditing, setIsEditing] = useState(false);
  const [isOnboarding, setIsOnboarding] = useState(false);
  const [onboardingReceived, setOnboardingReceived] = useState(false);
  const [formValidationErrors, setFormValidationErrors] = useState<ErrorsInterface>(defaultErrors);
  const [entryWithFocus, setEntryWithFocus] = useState<string | undefined>();

  // DATA
  const { data: onboardingStatus, isLoading: onboardingStatusIsLoading } = useGetOnboardingStatus(workspace ?? 'prod');
  const { data: serverData, isLoading: serverLoading, error: serverError } = useGetUserDeploymentSettingsQuery();
  const { data: sparkData, isLoading: sparkLoading, error: sparkError } = useGetInternalSparkClusterStatusQuery(3000);
  const { mutate, error: UpdateError, data: updateData } = useUpdateUserDeploymentSettingsMutation();
  const SUBMISSION_ERROR = UpdateError?.message || updateData?.error_message;

  const {
    data: statusData,
    isLoading: statusLoading,
    error: statusError,
  } = useGetDataPlatformSetupStatusQuery(canDisableFormButton, 3000);

  // From POC Scroll to
  const createFocusHandler = (entryId: string) => {
    return () => {
      scrollIntoViewByElementId(entryId);
      setEntryWithFocus(entryId);
    };
  };

  // Form Utils
  const schema = dataPlatformConfigFormSchema;

  // Update Form Properties
  const updateForm = (key: keyof SubmissionInterface, value: string | boolean) => {
    setFormData((prev) => {
      return updateObjectProperties(prev, { [key]: value });
    });
  };

  // Form Submission
  const submitForm = (e: any) => {
    e.preventDefault();
    const field_mask = getFieldMaskValues(formData, savedData);
    const submission = mapDPOSubmission(formData, field_mask);
    mutate(submission, {
      onSuccess: (data) => {
        if (data.success) {
          setCanDisableFormButton(true);
          setIsSubmitted(true);
          setIsEditing(false);
        }
      },
    });
  };

  // Cancel Form Editing
  const cancelForm = () => {
    setFormData(savedData);
    validateForm();
    setIsEditing(false);
  };

  // Form Validation
  const validateForm = _debounce(async () => {
    setFormValidationErrors(defaultErrors);
    try {
      await schema.validate(formData, { abortEarly: false });
    } catch (err: any) {
      err.inner.forEach((err: any) => {
        if (formData[err.path as keyof SubmissionInterface] !== savedData[err.path as keyof SubmissionInterface]) {
          setFormValidationErrors((prev) => {
            return updateObjectProperties(prev, { [err.path]: err.errors });
          });
        }
      });
    }
  }, 100);

  // UPDATE SAVED DATA FROM RESPONSE
  useEffect(() => {
    if (serverData) {
      const {
        workspace_url,
        api_token,
        instance_profile_arn,
        glueCatalogEnabled,
        glueCatalogId,
      }: USER_SETTINGS_RESPONSE = serverData || {};
      const dataMap = {
        workspace_url,
        api_token: api_token?.value || api_token?.encrypted_value || api_token?.redacted_value || '',
        instance_profile_arn,
        glueCatalogEnabled,
        glueCatalogId,
      };
      setFormData(dataMap);
      setSavedData(dataMap);
    }
  }, [serverData]);

  // Check For Onboarding
  useEffect(() => {
    if (onboardingStatus && !onboardingReceived) {
      const onboarding = onboardingStatus?.setup_platform === OnboardingStatusEnum.ONBOARDING_STATUS_INCOMPLETE;
      if (onboarding && !isEditing) {
        setIsEditing(true);
      }
      setOnboardingReceived(true);
      setIsOnboarding(onboarding);
    }
  }, [isEditing, onboardingReceived, onboardingStatus]);

  // Check SparkUpdateStatus
  useEffect(() => {
    if (canDisableFormButton && statusData?.setupCompleted) {
      setCanDisableFormButton(false);
    }
  }, [canDisableFormButton, statusData?.setupCompleted]);

  // FORM ERRORS
  const WORKSPACE_URL_ERROR =
    !!formValidationErrors?.workspace_url?.length && formData.workspace_url !== savedData.workspace_url;
  const API_TOKEN_ERROR = !!formValidationErrors?.api_token?.length && formData.api_token !== savedData.api_token;
  const INSTANCE_PROFILE_ERROR =
    !!formValidationErrors?.instance_profile_arn?.length &&
    formData.instance_profile_arn !== savedData.instance_profile_arn;
  const GLUE_ID_ERROR =
    !!formValidationErrors?.glueCatalogId?.length &&
    formData.glueCatalogId !== savedData.glueCatalogId &&
    formData.glueCatalogEnabled;
  const IS_FORM_ERROR = WORKSPACE_URL_ERROR || API_TOKEN_ERROR || INSTANCE_PROFILE_ERROR || GLUE_ID_ERROR;

  if (serverLoading || onboardingStatusIsLoading) {
    return <LoadingChart size="l" mono />;
  }

  if (serverError) {
    return <div>there was an error loading your data.</div>;
  }

  // We only show the breadcrumb on the form when guided onboarding is completed
  const breadcrumbs =
    onboardingStatus?.finish_onboarding === OnboardingStatusEnum.ONBOARDING_STATUS_COMPLETED ? (
      <Breadcrumbs workspace={`${workspace}`} crumbs={[{ label: 'Compute' }]} />
    ) : (
      <></>
    );

  const header = <HeaderLayout breadcrumbs={breadcrumbs} tabs={[]} />;

  return (
    <>
      <ViewLayout
        header={header}
        body={
          <>
            <SplitPanel
              leftContent={
                !isEditing ? (
                  <DataPlatformWrapper>
                    <FlexGroupWrapper direction="column" gap="m">
                      {isSubmitted ? (
                        <Feedback
                          isSubmitted={isSubmitted}
                          data={statusData}
                          isLoading={statusLoading}
                          error={statusError}
                        />
                      ) : (
                        <>
                          <Callout
                            mode={
                              sparkClusterStatusToResultMood[
                                sparkData?.status?.status ??
                                  InternalSparkClusterStatusEnum.INTERNAL_SPARK_CLUSTER_STATUS_UNHEALTHY
                              ]
                            }
                            title="System Status"
                          >
                            <SparkStatus data={sparkData} isLoading={sparkLoading} error={!!sparkError} />
                          </Callout>
                          <HorizontalRule />
                        </>
                      )}
                      <DataPlatformConfig
                        {...serverData}
                        data={sparkData}
                        setIsEditing={setIsEditing}
                        canDisableFormButton={!isAdmin}
                      />
                    </FlexGroupWrapper>
                  </DataPlatformWrapper>
                ) : (
                  <>
                    <FlexGroupWrapper gap="xxl" direction="column">
                      <FlexItem>
                        <Text>
                          <p>
                            Update your Databricks cluster configurations. Note that you will need Databricks admin
                            access to get some of the required information.
                          </p>
                        </Text>
                      </FlexItem>
                      <FlexItem>
                        <Form
                          component="form"
                          fullWidth
                          isInvalid={!!SUBMISSION_ERROR}
                          error={SUBMISSION_ERROR}
                          invalidCallout="none"
                          onBlur={() => {
                            setEntryWithFocus(undefined);
                            validateForm();
                          }}
                        >
                          <FlexGroupWrapper gap="l" direction="column">
                            <FlexItem>
                              {' '}
                              <FormRow
                                label="Databricks Workspace URL"
                                isDisabled={!isOnboarding}
                                isInvalid={WORKSPACE_URL_ERROR}
                                errorText={<>{formValidationErrors.workspace_url}</>}
                                onFocus={createFocusHandler('docs-workspace-url')}
                              >
                                <FieldText
                                  name="workspace-url"
                                  isInvalid={WORKSPACE_URL_ERROR}
                                  value={formData.workspace_url}
                                  onChange={(e: any) => updateForm('workspace_url', e.target.value)}
                                />
                              </FormRow>
                            </FlexItem>
                            <FlexItem>
                              <FormRow
                                label="Databricks API Token"
                                errorText={<>{formValidationErrors.api_token}</>}
                                isInvalid={API_TOKEN_ERROR}
                                onFocus={createFocusHandler('docs-api-token')}
                              >
                                <FieldText
                                  name="api-token"
                                  isInvalid={API_TOKEN_ERROR}
                                  value={formData.api_token}
                                  onChange={(e: any) => updateForm('api_token', e.target.value)}
                                  type="password"
                                />
                              </FormRow>
                            </FlexItem>
                            <FlexItem>
                              <FormRow label="API Token">
                                <>{formValidationErrors.api_token}</>
                              </FormRow>
                            </FlexItem>
                            <FlexItem>
                              {SUBMISSION_ERROR && (
                                <FormErrorFeedback
                                  title="Form Submission Error"
                                  errors={[
                                    {
                                      message: SUBMISSION_ERROR,
                                    },
                                  ]}
                                />
                              )}
                            </FlexItem>
                            <FlexItem>
                              <FormRow
                                label="Instance Profile"
                                onFocus={createFocusHandler('docs-instance-profile')}
                                isInvalid={INSTANCE_PROFILE_ERROR}
                                errorText={<>{formValidationErrors.instance_profile_arn}</>}
                                message={<>Optional</>}
                              >
                                <FieldText
                                  name="instance-profile"
                                  isInvalid={INSTANCE_PROFILE_ERROR}
                                  value={formData.instance_profile_arn}
                                  onChange={(e: any) => updateForm('instance_profile_arn', e.target.value)}
                                />
                              </FormRow>
                            </FlexItem>
                            <FlexItem>
                              <FormRow
                                label="AWS Glue Catalog Access"
                                message={<>Optional</>}
                                onFocus={createFocusHandler('docs-glue-catalog')}
                              >
                                <Switch
                                  label={`Catalog Access ${isOnboarding ? 'Enabled' : 'Disabled'}`}
                                  checked={!!formData.glueCatalogEnabled}
                                  setChecked={() => {}}
                                  onChange={() => {
                                    updateForm('glueCatalogEnabled', !formData.glueCatalogEnabled);
                                    updateForm('glueCatalogId', '');
                                  }}
                                />
                              </FormRow>
                            </FlexItem>
                            <FlexItem>
                              {formData.glueCatalogEnabled && (
                                <FormRow
                                  label="AWS Glue Catalog ID"
                                  isInvalid={GLUE_ID_ERROR}
                                  errorText={<>{formValidationErrors.glueCatalogId}</>}
                                  onFocus={createFocusHandler('docs-glue-catalog')}
                                >
                                  <FieldText
                                    name="glue-catalog-id"
                                    isInvalid={GLUE_ID_ERROR}
                                    value={formData.glueCatalogId}
                                    onChange={(e: any) => updateForm('glueCatalogId', e.target.value)}
                                  />
                                </FormRow>
                              )}
                            </FlexItem>
                          </FlexGroupWrapper>
                        </Form>
                      </FlexItem>
                      <FlexItem>
                        <ConfigurationCautionWrapper>
                          Caution: Updating the Databricks configuration will cause any plan/apply to fail while the
                          Tecton Internal Spark Cluster restarts, and any running materialization jobs may be
                          interrupted.
                        </ConfigurationCautionWrapper>
                      </FlexItem>
                      <FlexItem style={{ alignSelf: 'end' }}>
                        <FlexGroupWrapper direction="row" gap="s">
                          <FlexItem grow={false}>
                            <Button variant="emptyAction" onClick={() => cancelForm()} label={'Cancel'} />
                          </FlexItem>
                          <FlexItem grow={false}>
                            <Button
                              onClick={(e) => {
                                submitForm(e);
                              }}
                              label={'Update Cluster Configs'}
                              variant={IS_FORM_ERROR || canDisableFormButton ? 'disabledAction' : 'primaryAction'}
                            />
                          </FlexItem>
                        </FlexGroupWrapper>
                      </FlexItem>
                    </FlexGroupWrapper>
                  </>
                )
              }
              rightContent={
                <>
                  <DataPlatformHelpColumn entryWithFocus={entryWithFocus} />
                </>
              }
            />
          </>
        }
      />
    </>
  );
};

export default DataPlatformConfigEdit;

// @Status Component
const SparkStatus = ({ data, isLoading, error }: SparkStatusProps) => {
  if (isLoading) return <LoadingSpinner />;
  if (error) return <></>;
  const status =
    data?.status?.status != undefined
      ? ConfigurationStatusType[data?.status?.status as unknown as keyof typeof ConfigurationStatusType]
      : '';

  const { sparkClusterStatus } = SPARK_STATUS[status as unknown as keyof typeof SPARK_STATUS];
  return (
    <Health
      variant={
        sparkClusterStatusToHealthMap[
          data?.status?.status ?? InternalSparkClusterStatusEnum.INTERNAL_SPARK_CLUSTER_STATUS_UNSPECIFIED
        ]
      }
      label={sparkClusterStatus}
    />
  );
};

// @ PLATFORM CONFIG - READONLY
const DataPlatformConfig = ({
  workspace_url,
  api_token,
  instance_profile_arn,
  glueCatalogEnabled,
  glueCatalogId,
  setIsEditing,
  canDisableFormButton,
  data,
}: SparkConfigProps) => {
  const dataPlatformConfigList = [
    {
      title: 'Platform Type',
      description: (
        <>
          <div style={{ alignSelf: 'flex-start' }}>
            <Button
              label={'Go to Databricks'}
              variant="emptyAction"
              iconRight={ArrowTopRight}
              onClick={() => {
                window.open(data?.status?.cluster_url, '_blank', 'rel=noopener noreferrer');
              }}
            />
          </div>
        </>
      ),
    },
    {
      title: 'Databricks Workspace URL',
      description: workspace_url ?? '',
    },
    {
      title: 'Databricks API Token',
      description: <>{api_token?.value || api_token?.encrypted_value || api_token?.redacted_value}</>,
    },
    {
      title: 'Instance Profile ARN',
      description: instance_profile_arn ?? '',
    },
    {
      title: 'AWS Glue Catalog Access',
      description: (
        <div>
          <EnabledDisabledBadge enabled={glueCatalogEnabled ?? false} />
        </div>
      ),
    },
    {
      title: 'AWS Glue Catalog ID (Optional)',
      description: glueCatalogId ?? 'None',
    },
  ];

  return (
    <>
      <FlexItem>
        <FlexGrid columns={2} gutterSize="m">
          {dataPlatformConfigList.map((item) => {
            return (
              <>
                <FlexItem>{item.title}</FlexItem>
                <FlexItem grow={false}>{item.description}</FlexItem>
              </>
            );
          })}
        </FlexGrid>
      </FlexItem>
      <FlexItem>
        <HorizontalRule />
      </FlexItem>
      <FlexItem style={{ alignSelf: 'end' }}>
        <Button
          variant={canDisableFormButton ? 'disabledAction' : 'emptyAction'}
          onClick={() => {
            setIsEditing(true);
          }}
          label="Edit Databricks Configuration Settings"
          iconRight={Edit}
        />
      </FlexItem>
    </>
  );
};

const Feedback = ({
  isSubmitted,
  data,
  isLoading,
  error,
}: {
  isSubmitted: boolean;
  data: GetDataPlatformSetupStatusResponse | undefined;
  isLoading: boolean;
  error: any;
}) => {
  const tasks = data?.tasks || [];
  const setupCompleted = data?.setupCompleted;

  if (isLoading && isSubmitted) {
    return <LoadingSpinner size="l" />;
  }

  if (error) {
    return (
      <FormErrorFeedback
        title="Databricks Configurations Invalid"
        errors={[
          {
            title: 'We encountered an unexpected error.',
            message: 'This may be transient, try refreshing the browser',
          },
        ]}
      />
    );
  } else if (data && isSubmitted) {
    return <FormProgressFeedback tasks={tasks} setupCompleted={setupCompleted} />;
  }

  return <></>;
};
