import React, { FC } from 'react';
import { ControlButton, Controls } from '@xyflow/react';
import DiagramConfigsPopover from './DiagramConfigsPopover';
import { Tooltip, Icon } from '@tecton/ComponentRedesign';
import styled from '@emotion/styled';
import DataflowIcon from '@svg/dataflowSummary.svg';

interface DataFlowControlsProps {
  setShowSummarizedView?: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  showSummarizedView?: boolean;
  setReduceAnimation?: React.Dispatch<React.SetStateAction<boolean>>;
  configPopoverRef: any;
  reduceAnimation: boolean;
  isFcoLevelDiagram?: boolean;
}

// This is a workaround to a Reactflow-specific issue
const OverrideButtonStroke = styled(Controls)`
  path {
    fill: black;
  }
`;

const DataFlowControls: FC<DataFlowControlsProps> = ({
  setShowSummarizedView,
  configPopoverRef,
  reduceAnimation,
  setReduceAnimation,
  showSummarizedView,
  isFcoLevelDiagram,
}) => {
  return (
    <OverrideButtonStroke showInteractive={false} fitViewOptions={{ duration: 500 }} position={'top-left'}>
      {setShowSummarizedView && setShowSummarizedView && !isFcoLevelDiagram && (
        <div>
          <Tooltip
            trigger={
              <ControlButton
                onClick={() => {
                  setShowSummarizedView(!showSummarizedView);
                }}
              >
                <Icon type={DataflowIcon} />
              </ControlButton>
            }
            content={
              showSummarizedView
                ? `View the full dataflow for this workspace. Performance may be degraded when loading large diagrams.`
                : 'View a summarized dataflow for this workspace'
            }
          />
        </div>
      )}

      {setReduceAnimation && (
        <DiagramConfigsPopover
          ref={configPopoverRef}
          menuItems={[
            {
              icon: reduceAnimation ? 'playFilled' : 'pause',
              label: `${reduceAnimation ? 'Enable' : 'Disable'} Animation`,
              onClick: () => {
                setReduceAnimation(!reduceAnimation);
              },
            },
          ]}
        />
      )}
    </OverrideButtonStroke>
  );
};

export default DataFlowControls;
