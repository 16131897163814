/* eslint-disable prefer-rest-params */
import styled from '@emotion/styled';
import {
  EmptyPrompt,
  FlexGroup,
  FlexGroupWrapper,
  FlexItem,
  Icons,
  IconTypes,
  SkeletonText,
  Text,
} from '@tecton/ComponentRedesign';
import React, { FC, useEffect } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { logEvent } from '../../utils/analytics-utils';
import { getGlobalSearchFCOProps, hasMetCriteriaThreshold, useSearchModalContext } from './GlobalSearch';
import SearchResultGroup from './SearchResultGroup';
import SearchResultSelected, { breakPointForHidingFCODetails } from './SearchResultSelected';

interface SearchResultProps {
  setShowSearchModal: React.Dispatch<React.SetStateAction<boolean>>;
  workspace: string;
}

export const searchPreferredWidth = `940px`;

const SearchResultsContainer = styled.div`
  font-size: ${({ theme }) => theme.font.fontSizes.xs};
  background-color: ${({ theme }) => theme.colors.emptyShade};
  border-radius: ${({ theme }) => theme.border.radius.small};
  box-shadow: ${({ theme }) => theme.shadow.l};
  padding: 0px ${({ theme }) => theme.padding.s};
  width: clamp(
    calc(${({ theme }) => theme.breakpoint.m}px + 6px),
    ${searchPreferredWidth},
    85vw
  ); // The browser choose which size to choose depending on the size
  height: 50vh;
  min-height: 10vh;
  position: absolute;
  top: 54px;
  left: 0;
  gap: 10px;
  overflow: auto;
  display: flex;
`;

const SearchResultItemsWrapper = styled.div`
  padding: ${({ theme }) => theme.padding.s} ${({ theme }) => theme.padding.s} 0px 0px;
  width: 100%;
  max-height: 48vh;
  min-height: 9vh;
  overflow: auto;
`;

const SearchResultsFlexItem = styled(FlexItem)<{ isLoading: boolean; hasEmptyResults: boolean }>`
  padding: ${({ theme, isLoading }) =>
    isLoading ? `${theme.padding.s} ${theme.padding.s} ${theme.padding.s} 0px` : `0`};

  // center when we don't have a result.
  ${({ hasEmptyResults }) => {
    if (hasEmptyResults) {
      return `align-items: center;
          justify-content: center;`;
    }
  }}
`;

const FcoDetailFlexItem = styled(FlexItem)<{ isLoading: boolean }>`
  padding: ${({ theme, isLoading }) =>
    isLoading ? `${theme.padding.s} 0px ${theme.padding.s} ${theme.padding.s}` : `0px`};
  border-left: 1px solid ${({ theme }) => theme.colors.lightShade};
  // we hide the details portion of the search result when the screen is small (biggy).
  @media only screen and (max-width: calc(${({ theme }) => theme.breakpoint.m}px + 1px)) {
    display: none;
  }

  @media (max-width: calc(${breakPointForHidingFCODetails} + 64px)) {
    min-width: 150px;
  }
`;

const IconWrapper = styled.div`
  padding: ${({ theme }) => theme.padding.xs};
`;

const NoResultWrapper = styled.div`
  color: ${({ theme }) => theme.colors.text};
`;

const NoResultDetailWrapper = styled.div`
  width: 189px; // fix width from Figma
  color: ${({ theme }) => theme.colors.subduedText};
`;

const NoResultsFound = (
  <FlexGroup justifyContent="center">
    <FlexItem grow={0}>
      <FlexGroupWrapper direction="column" gap="m" justifyContent="center" alignItems="center">
        <FlexItem>
          <NoResultWrapper>
            <Text element="h4">No Results Found</Text>
          </NoResultWrapper>
        </FlexItem>
        <FlexItem>
          <FlexGroupWrapper gap="xs" direction="row" responsive={false}>
            <FlexItem grow={false}>
              <IconWrapper>{Icons[IconTypes.LIGHTBULB]}</IconWrapper>
            </FlexItem>
            <FlexItem>
              <NoResultDetailWrapper>
                <Text element="h5">Tip: you can also search by description, owner, tag or ID.</Text>
              </NoResultDetailWrapper>
            </FlexItem>
          </FlexGroupWrapper>
        </FlexItem>
      </FlexGroupWrapper>
    </FlexItem>
  </FlexGroup>
);

const SearchResults: FC<SearchResultProps> = ({ workspace, setShowSearchModal }) => {
  const {
    searchResults,
    filterValue,
    itemRefs,
    navigateToFco,
    focusedIndex,
    setFocusedIndex,
    setFocusedFco,
    fcosLoading,
    fcosError,
  } = useSearchModalContext();

  const globalSearchResults = getGlobalSearchFCOProps(searchResults);

  const prevItem = (event: KeyboardEvent) => {
    logEvent('Search', '', {
      action: 'shift+tab pressed',
    });
    event.preventDefault();
    setFocusedIndex((prevIndex) => {
      const newIndex = (prevIndex - 1 + globalSearchResults.length) % globalSearchResults.length;
      logEvent('Search', '', {
        action: 'up key pressed',
      });

      itemRefs?.current[newIndex]?.focus();
      setFocusedFco(globalSearchResults[newIndex]);
      return newIndex;
    });
  };
  const nextItem = (event: KeyboardEvent) => {
    event.preventDefault();
    setFocusedIndex((prevIndex) => {
      const newIndex = (prevIndex + 1) % globalSearchResults.length;
      itemRefs?.current[newIndex]?.focus();

      setFocusedFco(globalSearchResults[newIndex]);

      logEvent('Search', '', {
        action: 'down key pressed',
      });

      return newIndex;
    });
  };

  // search hotkeys
  useHotkeys(
    'tab',
    (event: KeyboardEvent) => {
      logEvent('Search', '', {
        action: 'tab pressed',
        description: `User is navigating through the search result.`,
      });
      nextItem.call(this, event);
    },
    { preventDefault: true }
  );

  useHotkeys(
    'shift+tab',
    (event: KeyboardEvent) => {
      prevItem.call(this, event);
    },
    { preventDefault: true }
  );

  useHotkeys(
    'down',
    (event: KeyboardEvent) => {
      nextItem.call(this, event);
    },
    { preventDefault: true }
  );

  useHotkeys(
    'up',
    (event: KeyboardEvent) => {
      prevItem?.call(this, event);
    },
    { preventDefault: true }
  );

  useHotkeys('enter', () => {
    navigateToFco(globalSearchResults[focusedIndex]);
    logEvent('Search', '', {
      action: 'enter key pressed',
      description: `User navigating from: ${workspace} to: ${globalSearchResults[focusedIndex].workspace?.name}`,
    });
  });

  // Set first item in focus for selection after sort
  useEffect(() => {
    if (!fcosLoading && globalSearchResults.length > 0) {
      setFocusedFco(globalSearchResults[0]);
    }

    if (fcosLoading) {
      // new search is happening, reset fcoFocused
      setFocusedFco(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fcosLoading]);

  if (!filterValue) return <></>;

  const fco = globalSearchResults[focusedIndex];
  const hasEmptyResults = globalSearchResults.length === 0;

  if (fcosError) {
    return (
      <SearchResultsContainer>
        <FlexGroup justifyContent="center">
          <FlexItem grow={0}>
            <EmptyPrompt
              title={<>Error Loading Search Results</>}
              body={<>There was an error loading search results. Please try again.</>}
              variant="storm"
              orientation="vertical"
            />
          </FlexItem>
        </FlexGroup>
      </SearchResultsContainer>
    );
  }

  return (
    <>
      {filterValue && hasMetCriteriaThreshold(filterValue) && (
        <SearchResultsContainer>
          <FlexGroupWrapper direction="row" gap="none">
            <SearchResultsFlexItem grow={6} isLoading={fcosLoading} hasEmptyResults={hasEmptyResults}>
              <>
                {/* Left side of search results */}
                <SkeletonText isLoading={fcosLoading} lines={10}>
                  {!fcosLoading && hasEmptyResults ? (
                    <>{NoResultsFound}</>
                  ) : (
                    <>
                      <SearchResultItemsWrapper>
                        {searchResults.map((searchResult) => {
                          return (
                            <>
                              <SearchResultGroup
                                groupName={searchResult.groupName}
                                searchResults={searchResult.results ?? []}
                                focusedIndex={focusedIndex}
                                setFocusedIndex={setFocusedIndex}
                                itemRefs={itemRefs}
                                navigateToFco={navigateToFco}
                                filterValue={filterValue}
                              />
                            </>
                          );
                        })}
                      </SearchResultItemsWrapper>
                    </>
                  )}
                </SkeletonText>
              </>
            </SearchResultsFlexItem>
            <FcoDetailFlexItem grow={4} isLoading={fcosLoading}>
              <SkeletonText isLoading={fcosLoading} lines={4}>
                {/* Right side of search results*/}
                {fco && <SearchResultSelected fco={fco} setShowSearchModal={setShowSearchModal} />}
              </SkeletonText>
            </FcoDetailFlexItem>
          </FlexGroupWrapper>
        </SearchResultsContainer>
      )}
    </>
  );
};

export default SearchResults;
