import React, { FC } from 'react';
import { FeatureViewFCO, FeatureViewFCOFields } from '../../../core/types/fcoTypes';
import { ErrorBoundary, FCOCard } from '@tecton';
import { TimezoneContextProvider } from '../../context/TimezoneContext';

import { Jobs, FilterProvider } from '../jobs/JobsContainer';
import { JobsContextProvider } from '../jobs/JobsContext';
import { useParams } from 'react-router';

interface MaterializationJobsProps {
  fco: FeatureViewFCO;
}

const FeatureViewJobs: FC<MaterializationJobsProps> = ({ fco }) => {
  const { workspace } = useParams(); // Don't use userSettings to get the workspace here since we need to be on workspace routed URL.

  // FEATURE TABLE VERSION
  if (fco[FeatureViewFCOFields.IS_MATERIALIZATION_ENABLED] && fco[FeatureViewFCOFields.IS_FEATURE_TABLE]) {
    return (
      <ErrorBoundary>
        <TimezoneContextProvider>
          <JobsContextProvider feature_view={fco.name} workspace={workspace}>
            <FilterProvider isFeatureView={true}>
              <Jobs />
            </FilterProvider>{' '}
          </JobsContextProvider>
        </TimezoneContextProvider>
      </ErrorBoundary>
    );
  }

  return (
    <>
      {fco[FeatureViewFCOFields.IS_MATERIALIZATION_ENABLED] && !fco[FeatureViewFCOFields.IS_FEATURE_TABLE] && (
        <ErrorBoundary>
          <TimezoneContextProvider>
            <JobsContextProvider feature_view={fco.name} workspace={workspace}>
              <FilterProvider isFeatureView={true}>
                <Jobs />
              </FilterProvider>{' '}
            </JobsContextProvider>
          </TimezoneContextProvider>
        </ErrorBoundary>
      )}
    </>
  );
};

export default FeatureViewJobs;
