import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  useExpanded,
  usePagination,
  useSortBy,
} from 'react-table';
import React, { useState } from 'react';
import SortAscIcon from '../assets/image/tecton-ui/ui-icon/sort-asc.svg';
import SortDescIcon from '../assets/image/tecton-ui/ui-icon/sort-des.svg';
import { Box, Grid } from '@mui/material';

export const wrapFirstClassObjectForSearch = (fco) => {
  return {
    name: fco.name,
    itemTitle: fco.itemTitle,
    description: fco.description || '',
    tags: fco.tags || {},
    id: fco.id,
    // Only used for FeatureView
    featureType: fco.featureType,
    toString: () => {
      return (
        fco.name +
        ' ' +
        fco.id +
        ' ' +
        fco.description +
        Object.entries(fco.tags || {})
          .flat()
          .join(' ')
      );
    },
  };
};

const TableView = ({
  columns,
  data,
  title,
  showSearch = true,
  searchPlaceHolder = 'Search...',
  headerRightComponent,
  renderRowSubComponent,
  useColWidths = false,
  baseSize = 10,
  headerButton,
  disableSortBy = false,
}) => {
  const defaultColumn = {
    sortType: (rowA, rowB, columnId) => {
      let a = rowA.values[columnId];
      let b = rowB.values[columnId];

      // deal with nulls (and undefined)
      a = a || '';
      b = b || '';

      if (typeof a !== typeof b) {
        // if they are not of the same type then try to convert both
        //   to number or string
        if (a.valueOf && b.valueOf) {
          a = a.valueOf();
          b = b.valueOf();
        } else {
          a = String(a);
          b = String(b);
        }
      } else if (typeof a === 'object') {
        // if they are both objects then try to convert both
        //   to number or string
        if (a.sortKey && b.sortKey) {
          a = a.sortKey;
          b = b.sortKey;
        } else if (a.valueOf) {
          a = a.valueOf();
          b = b.valueOf();
        } else if (a.toString) {
          a = a.toString();
          b = b.toString();
        }
      }

      // at this point, they should be boolean, number, or string...
      return a === b ? 0 : a > b ? 1 : -1;
    },

    Cell: ({ value }) => {
      //todo: we should replace the N/A upstream with null
      //      and handle this at the display layer
      if (value && value !== 'N/A') {
        if (Array.isArray(value)) {
          return value.join(', ');
        } else {
          return value;
        }
      } else {
        return '-';
      }
    },
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        pageSize: baseSize,
      },
      disableSortBy: disableSortBy,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination
  );

  const [searchString, setSearchString] = useState('');

  const onChangeSearchString = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  const extraHeaderProps = (column) => {
    if (useColWidths && column.width) {
      return {
        width: column.width,
        maxWidth: column.width,
      };
    } else {
      return null;
    }
  };

  const tableSizeOptions = [1, 2, 3, 4, 5, 10].map((i) => baseSize * i);

  const header = (
    <Grid container justify="flex-end">
      <Grid item xs={6}>
        <input
          type="text"
          className="text-input mw7 ml3"
          placeholder={searchPlaceHolder}
          value={searchString}
          onChange={(e) => {
            setSearchString(e.target.value);
            onChangeSearchString(e.target.value);
          }}
        />
      </Grid>
      <Grid item xs={6} justifyContent="flex-end">
        {headerRightComponent}
      </Grid>
    </Grid>
  );

  return (
    <>
      {title || showSearch ? (
        <div className="card pa3">
          <Box display="flex">
            {title ? <div className="f4 fw6 mb2 ma-auto">{title}</div> : null}
            {showSearch ? header : null}
            <Box flex={1} />
            {headerButton}
          </Box>
        </div>
      ) : null}
      <table className="table mb3" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr key={index} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, index) => (
                <th
                  key={index}
                  className={disableSortBy ? 'nowrap' : 'has-hover nowrap'}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  {...extraHeaderProps(column)}
                >
                  <span className="">{column.render('Header')}</span>
                  <span className="">
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <img alt="sort descending" className="sort-icon sort-icon--desc" src={SortDescIcon} />
                      ) : (
                        <img alt="sort ascending" className="sort-icon sort-icon--asc" src={SortAscIcon} />
                      )
                    ) : (
                      ''
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            const { key, ...rowProps } = row.getRowProps();
            return (
              <React.Fragment key={key}>
                <tr {...rowProps}>
                  {row.cells.map((cell, index) => {
                    return (
                      <td key={index} {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
                {row.isExpanded ? (
                  <tr {...rowProps}>
                    <td colSpan={columns.length}>{renderRowSubComponent({ row })}</td>
                  </tr>
                ) : null}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
      {pageCount > 1 ? (
        <div className="page">
          <button className="page-button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            {'<<'}
          </button>{' '}
          <button className="page-button" onClick={() => previousPage()} disabled={!canPreviousPage}>
            {'<'}
          </button>{' '}
          <span className="page-text">
            Page {pageIndex + 1} of {pageOptions.length}
          </span>
          <button className="page-button" onClick={() => nextPage()} disabled={!canNextPage}>
            {'>'}
          </button>{' '}
          <button className="page-button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
            {'>>'}
          </button>{' '}
          <select
            className="select"
            aria-label="table pagination selector"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {tableSizeOptions.map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize} rows per page
              </option>
            ))}
          </select>
        </div>
      ) : null}
    </>
  );
};
export default TableView;
