import React from 'react';

function HiveIcon() {
  return (
    <g transform="translate(-12,-12) scale(0.05)">
      <path d="M18.5 113.5L242.5 1.5L459 113.5V362L242.5 480.5L18.5 362V113.5Z" fill="#5294CF" />
      <path
        d="M91.9492 316.403L93.6382 369.527L152.43 398.292L198.976 381.431L214.022 361.128L145.213 356.865L91.9492 316.403Z"
        fill="#0C2636"
      />
      <path d="M157.794 432.952L117.583 423.348L184.599 468.241L191.511 405.538L157.794 432.952Z" fill="#0C2636" />
      <path
        d="M108.52 267.981L91.9492 316.403L144.292 358.815L214.021 361.128L236.273 344.066L158.206 321.061L108.52 267.981Z"
        fill="#C7C032"
      />
      <path
        d="M187.725 188.837L108.52 267.981L158.206 321.061L236.684 344.681L257.284 329.586L243.158 245.194L187.725 188.837Z"
        fill="#0C2636"
      />
      <path
        d="M117.583 423.348L93.8424 368.501L151.813 396.036L198.976 381.431L191.511 405.538L158.82 433.156L117.583 423.348Z"
        fill="#C7C032"
      />
      <path
        d="M305.056 72.0963L271.741 107.463L286.837 292.362L372.207 366.198L396.673 320.904L380.637 282.65L389.592 253.488L365.126 218.122L393.029 168.269L372.207 96.915L305.056 72.0963Z"
        fill="#E3D81C"
        stroke="#C7C032"
      />
      <path
        d="M307.659 137.245L261.85 92.5717L110.973 3.57532L34.4519 68.1039L3 162.064L19.6406 251.832L60.8969 289.314L123.896 261.582L172.673 270.268V321.767L244.421 412.971L282.789 428L277.08 372.208L337.426 395.58L388.384 390.559L441.493 339.207L458.888 290.11L469 249.348L468.49 208.586L447.689 196.446L424.675 202.759L414.938 277.08L377.676 315.94L358.673 302.91L346.696 296.705L358.673 264.441L331.604 225.972L307.659 137.245Z"
        fill="#FDEE21"
      />
      <path d="M228.528 150.41L177.19 119.818L196.663 197.026L228.528 150.41Z" fill="#C8C037" />
      <path d="M300.108 254.362L246.335 266.199V292.238H310.065L300.108 254.362Z" fill="#0C2636" />
      <path d="M315.5 387L282 420L264 411.5L261 395.5L270 376.5V366L315.5 387Z" fill="#0C2636" />
    </g>
  );
}

export default HiveIcon;
