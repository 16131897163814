import styled from '@emotion/styled';
import React, { FC, useState } from 'react';
import { Text } from '@tecton/ComponentRedesign';
import { Button, ComponentLifecycleState } from '.';
import { default as OverlayMask } from './OverlayMask';
import { default as FlexGroup } from './FlexGroup';
import {
  useFloating,
  useClick,
  useDismiss,
  useRole,
  useInteractions,
  FloatingFocusManager,
  FloatingOverlay,
  FloatingPortal,
} from '@floating-ui/react';
import { css, keyframes } from '@emotion/react';
import { ButtonProps } from './Button';

interface ModalProps {
  title: string;
  body: React.ReactNode;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  buttons?: ButtonProps[];
}

const slideIn = keyframes`
   from {
      opacity:0;
      transform:translateY(5%);
   }
   to {
      opacity:1;
      transform:translateY(0%);
   }
`;

const slideOut = keyframes`
   from {
      opacity:1;
      transform:translateY(0%);
   }
   to {
      opacity:0;
      transform:translateY(5%);
   }
`;

const ModalContainer = styled.div<{ lifecycleState: ComponentLifecycleState }>`
  width: auto;
  min-width: ${({ theme }) => theme.v1.size['96']}px;
  max-width: ${({ theme }) => theme.v1.viewports.narrow}px;
  background-color: ${({ theme }) => theme.v1.colors.background.empty};
  padding: ${({ theme }) => theme.v1.size[8]}px;
  border-radius: ${({ theme }) => theme.v1.radius[8]};
  box-shadow: ${({ theme }) => theme.v1.shadow.l};
  color: ${({ theme }) => theme.v1.colors.text.text};
  z-index: 2;
  transform: translateY(0%);
  opacity: 1;
  animation: ${({ theme, lifecycleState }) => {
    if (lifecycleState === 'entering') {
      return css`
        ${slideIn} ${theme.v1.motion.duration.normal} ${theme.v1.motion.easing.enter} forwards
      `;
    }

    if (lifecycleState === 'exiting') {
      return css`
        ${slideOut} ${theme.v1.motion.duration.fast} ${theme.v1.motion.easing.exit} forwards
      `;
    }
  }};
`;

const BodyWrapper = styled.div`
  overflow: scroll;
  max-height: 50vh; // TODO: Needs to be confirmed by UX
`;

const ModalInternal: FC<ModalProps> = ({ title, body, buttons, isOpen, setIsOpen }) => {
  const [lifecycleState, setLifecycleState] = useState<ComponentLifecycleState>('entering');
  const { refs, context } = useFloating({
    open: isOpen,
    onOpenChange: (value) => {
      if (!value) {
        setLifecycleState('exiting');
      }
    },
  });

  const click = useClick(context);
  const dismiss = useDismiss(context, {
    outsidePressEvent: 'mousedown',
  });
  const role = useRole(context);

  const { getFloatingProps } = useInteractions([click, dismiss, role]);

  return (
    <FloatingPortal>
      {isOpen && (
        <FloatingOverlay
          style={{
            zIndex: 9999,
            overflow: 'hidden',
          }}
        >
          <OverlayMask lifecycleState={lifecycleState} />
          <FloatingFocusManager context={context}>
            <div ref={refs.setFloating} {...getFloatingProps()}>
              <FlexGroup css={{ width: '100vw', height: '100vh' }} alignItems="center" justifyContent="center">
                <ModalContainer
                  lifecycleState={lifecycleState}
                  onAnimationEnd={() => {
                    if (lifecycleState === 'entering') {
                      setLifecycleState('entered');
                    }

                    if (lifecycleState === 'exiting') {
                      setLifecycleState('exited');
                      setIsOpen(false);
                    }
                  }}
                >
                  <FlexGroup direction="column" gapSize={'4'}>
                    <FlexGroup justifyContent="space-between">
                      <Text element="h4">{title}</Text>
                      <Button
                        type="ghost"
                        icon={'Exit'}
                        onClick={() => {
                          setLifecycleState('exiting');
                        }}
                      />
                    </FlexGroup>
                    <BodyWrapper>{body}</BodyWrapper>
                    <div>
                      <FlexGroup alignItems="center" justifyContent="flex-end" gapSize="4">
                        {buttons?.map((buttonProps) => {
                          return (
                            <Button
                              {...buttonProps}
                              onClick={() => {
                                setLifecycleState('exiting');
                                buttonProps.onClick && buttonProps.onClick();
                              }}
                            />
                          );
                        })}
                        <Button
                          label="Done"
                          onClick={() => {
                            setLifecycleState('exiting');
                          }}
                        />
                      </FlexGroup>
                    </div>
                  </FlexGroup>
                </ModalContainer>
              </FlexGroup>
            </div>
          </FloatingFocusManager>
        </FloatingOverlay>
      )}
    </FloatingPortal>
  );
};

export const Modal: FC<ModalProps> = ({ title, body, buttons, isOpen, setIsOpen }) => {
  if (!isOpen) {
    return <></>;
  }

  return <ModalInternal title={title} body={body} buttons={buttons} isOpen={isOpen} setIsOpen={setIsOpen} />;
};

export default Modal;
