import { DataFlowNode } from '../dataFlowTypes';
import {
  getFeatureServiceIdGivenConsumer,
  getVisibleAnimationsOnAllEdgesGivenAnchors,
  VisibleAnimationRecord,
} from './highlight-utils';
import { HighlightFunctionType } from './highlightFunctionType';
import highlightUpstream from './highlightUpstream';

const highlightModelTrainer: HighlightFunctionType = (id, nodesMap, edgesList) => {
  const featureServiceId = getFeatureServiceIdGivenConsumer(id, nodesMap);
  const featureService = nodesMap[featureServiceId];

  const edgeShouldBeSkipped = (sourceNode: DataFlowNode, _targetNode: DataFlowNode) => {
    if (sourceNode.type === 'request_data_source') {
      /**
       * If we encounter a request data source, and
       * it is not embedded in the same feature service
       * that this online consumer is connected to,
       * then skip.
       */
      if (sourceNode.upstreamNodes && sourceNode.upstreamNodes[0] !== featureServiceId) {
        return true;
      }
    }

    if (sourceNode.type === 'raw_stream_node' && !sourceNode.pushesToOfflineStore) {
      return true;
    }

    return false;
  };

  const { linkedIds, linkedEdges } = highlightUpstream(id, nodesMap, edgesList, {
    hideOnlineStore: true,
    edgeShouldBeSkipped,
  });

  const anchors = new Set<string>(featureService.upstreamNodes);
  anchors.add(featureServiceId);

  const animations = getVisibleAnimationsOnAllEdgesGivenAnchors(anchors, edgesList, linkedEdges, {
    showBatchMaterializationPath: true,
    showOfflineReadPath: true,
  });

  return {
    linkedIds,
    linkedEdges,
    animations,
  };
};

export default highlightModelTrainer;
