import React from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import {
  BACKGROUND_PATH_CLASSNAME,
  BATCH_MATERIALIZATION_PATH_CLASSNAME,
  OFFLINE_READ_PATH_CLASSNAME,
  ONLINE_REQUEST_PATH_CLASSNAME,
  ONLINE_SERVING_PATH_CLASSNAME,
  STREAM_MATERIALIZATION_PATH_CLASSNAME,
  fcoColors,
} from './DataFlowConstants';

const DEFAULT_EDGE_COLOR = '#aaa';
const HIGHLIGHTED_EDGE_COLOR = '#000';
const FADED_EDGE_COLOR = '#f1f1f1';

const DEFAULT_ANIMATED_STROKE_WIDTH = '4px';
const FOCUSED_ANIMATED_STROKE_WIDTH = '6px';
const STATIC_OFFLINE_READ_STROKE_WIDTH = '6px';

type EdgeVisibilityState = 'default' | 'in_focus' | 'out_of_focus';
type EdgeDisplayProps = {
  displayMode: EdgeVisibilityState;
  reduceAnimation?: boolean;
};

const PathGroup = styled.g<{ displayMode: EdgeVisibilityState }>`
  opacity: ${(props) => (props.displayMode === 'out_of_focus' ? 0 : 1)};
  transition: opacity 0.3s;
`;

const BackgroundPath = styled.path<EdgeDisplayProps>`
  stroke: ${(props) => (props.displayMode === 'in_focus' ? 'rgb(170, 170, 170)' : 'rgb(200, 220, 220)')};
  stroke-width: ${(props) => (props.displayMode === 'in_focus' ? '2px' : '1px')};
  fill: none;
`;

const batchDashArray = {
  dash: 60,
  gap: 100,
};

const batchAnimatedPathsKeyframes = keyframes`
  0% { stroke-dashoffset: ${batchDashArray.dash + batchDashArray.gap}px; }
  100% { stroke-dashoffset: 0; }
`;

const BatchMaterializationPath = styled.path<EdgeDisplayProps>`
  stroke: ${fcoColors.data_source.glow};
  fill: none;
  stroke-width: ${(props) =>
    props.displayMode === 'in_focus' ? FOCUSED_ANIMATED_STROKE_WIDTH : DEFAULT_ANIMATED_STROKE_WIDTH};
  stroke-dasharray: ${batchDashArray.dash}px
    ${(props) => (props.reduceAnimation ? batchDashArray.gap / 2 : batchDashArray.gap)}px;
  stroke-linecap: round;
  animation-name: ${batchAnimatedPathsKeyframes};
  animation-duration: ${(props) => (props.reduceAnimation ? '0' : '2.5s')};
  animation-timing-function: linear;
  animation-iteration-count: infinite;
`;

const streamDashArray = {
  dash: 5,
  gap: 10,
};

const streamAnimatedPathsKeyframes = keyframes`
  0% { stroke-dashoffset: ${streamDashArray.dash + streamDashArray.gap}px; }
  100% { stroke-dashoffset: 0; }
`;

const StreamMaterializationPath = styled.path<EdgeDisplayProps>`
  stroke: ${fcoColors.data_source.background};
  fill: none;
  stroke-width: ${(props) =>
    props.displayMode === 'in_focus' ? FOCUSED_ANIMATED_STROKE_WIDTH : DEFAULT_ANIMATED_STROKE_WIDTH};
  stroke-dasharray: ${streamDashArray.dash}px ${streamDashArray.gap}px;
  stroke-linecap: round;
  animation-name: ${streamAnimatedPathsKeyframes};
  animation-duration: ${(props) => (props.reduceAnimation ? '0' : '0.2s')};
  animation-timing-function: linear;
  animation-iteration-count: infinite;
`;

const requestDashArray = {
  dash: 24,
  gap: 3200,
};

const responseDashArray = {
  dash: 24,
  gap: 3200,
};

const requestResponseAnimationDuration = `8s`;

const requestPathKeyframes = keyframes`
  0% { stroke-dashoffset: ${requestDashArray.dash}; }
  10% { stroke-dashoffset: ${requestDashArray.dash}; }
  53% { stroke-dashoffset: ${requestDashArray.gap + requestDashArray.dash * 3}; }
  100% { stroke-dashoffset: ${requestDashArray.gap + requestDashArray.dash * 3} ; }
`;

const responsePathKeyframes = keyframes`
  0% { stroke-dashoffset: ${responseDashArray.dash}px; }
  57% { stroke-dashoffset: ${responseDashArray.dash}px; }
  100% { stroke-dashoffset: ${-responseDashArray.gap * 1}px; }
`;

const OnlineRequestPath = styled.path<EdgeDisplayProps & { reverseAnimationDirection?: boolean }>`
  stroke: ${fcoColors.feature_service.fontColor};
  fill: none;
  stroke-width: ${(props) =>
    props.displayMode === 'in_focus' ? FOCUSED_ANIMATED_STROKE_WIDTH : DEFAULT_ANIMATED_STROKE_WIDTH};
  stroke-dasharray: ${requestDashArray.dash}px ${requestDashArray.gap}px;
  stroke-linecap: round;
  animation-name: ${requestPathKeyframes};
  animation-duration: ${(props) => (props.reduceAnimation ? '0' : requestResponseAnimationDuration)};
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: ${(props) => (props.reverseAnimationDirection ? 'reverse' : 'normal')};
`;

const OnlineResponsePath = styled.path<EdgeDisplayProps>`
  stroke: ${fcoColors.feature_service.fontColor};
  fill: none;
  stroke-width: ${(props) =>
    props.displayMode === 'in_focus' ? FOCUSED_ANIMATED_STROKE_WIDTH : DEFAULT_ANIMATED_STROKE_WIDTH};
  stroke-dasharray: ${responseDashArray.dash}px
    ${(props) => (props.reduceAnimation ? responseDashArray.dash : responseDashArray.gap)}px;
  stroke-linecap: round;
  animation-name: ${responsePathKeyframes};
  animation-duration: ${(props) => (props.reduceAnimation ? '0' : requestResponseAnimationDuration)};
  animation-timing-function: linear;
  animation-iteration-count: infinite;
`;

const offlineReadDashArray = {
  dash: 2000,
  gap: 4000,
};

const offlineReadPathKeyframes = keyframes`
  0% { stroke-dashoffset: ${-offlineReadDashArray.dash}px; }
  70% { stroke-dashoffset: ${-offlineReadDashArray.dash}px; }
  90% { stroke-dashoffset: ${-offlineReadDashArray.dash - offlineReadDashArray.gap}px; }
  100% { stroke-dashoffset: ${-offlineReadDashArray.dash * 2 - offlineReadDashArray.gap}px; }
`;

const OfflineReadPath = styled.path<EdgeDisplayProps>`
  stroke: ${fcoColors.feature_service.glow};
  fill: none;
  opacity: ${(props) => (props.displayMode === 'out_of_focus' ? 0.3 : 1)};
  stroke-width: ${(props) =>
    props.reduceAnimation
      ? STATIC_OFFLINE_READ_STROKE_WIDTH
      : props.displayMode === 'in_focus'
      ? FOCUSED_ANIMATED_STROKE_WIDTH
      : DEFAULT_ANIMATED_STROKE_WIDTH};
  stroke-dasharray: ${(props) =>
    props.reduceAnimation ? 'inherit' : `${offlineReadDashArray.dash}px ${offlineReadDashArray.gap}px`};
  stroke-linecap: round;
  animation-name: ${offlineReadPathKeyframes};
  animation-duration: ${(props) => (props.reduceAnimation ? '0' : '12s')};
  animation-timing-function: linear;
  animation-iteration-count: infinite;
`;

const DFBasicEdge = ({
  path,
  displayMode,
  reduceAnimation,
  showOfflineReadPath,
  showOnlineServingPath,
  showBatchMaterializationPath,
  showStreamMaterializationPath,
}: {
  path: string;
  displayMode: EdgeVisibilityState;
  reduceAnimation: boolean;
  showOfflineReadPath?: boolean;
  showOnlineServingPath?: boolean;
  showBatchMaterializationPath?: boolean;
  showStreamMaterializationPath?: boolean;
}) => {
  return (
    <>
      <BackgroundPath className={BACKGROUND_PATH_CLASSNAME} d={path} displayMode={displayMode} />
      <PathGroup displayMode={displayMode}>
        {showOfflineReadPath && (
          <OfflineReadPath
            d={path}
            className={OFFLINE_READ_PATH_CLASSNAME}
            displayMode={displayMode}
            reduceAnimation={reduceAnimation}
          />
        )}
        {showOnlineServingPath && (
          <>
            <OnlineRequestPath
              d={path}
              className={ONLINE_REQUEST_PATH_CLASSNAME}
              displayMode={displayMode}
              reduceAnimation={reduceAnimation}
            />
            <OnlineResponsePath
              d={path}
              className={ONLINE_SERVING_PATH_CLASSNAME}
              displayMode={displayMode}
              reduceAnimation={reduceAnimation}
            />
          </>
        )}
        {showBatchMaterializationPath && (
          <BatchMaterializationPath
            d={path}
            className={BATCH_MATERIALIZATION_PATH_CLASSNAME}
            displayMode={displayMode}
            reduceAnimation={reduceAnimation}
          />
        )}
        {showStreamMaterializationPath && (
          <StreamMaterializationPath
            d={path}
            className={STREAM_MATERIALIZATION_PATH_CLASSNAME}
            displayMode={displayMode}
            reduceAnimation={reduceAnimation}
          />
        )}
      </PathGroup>
    </>
  );
};

export default DFBasicEdge;
export type { EdgeVisibilityState };
export {
  DEFAULT_EDGE_COLOR,
  HIGHLIGHTED_EDGE_COLOR,
  FADED_EDGE_COLOR,
  BackgroundPath,
  BatchMaterializationPath,
  StreamMaterializationPath,
  OfflineReadPath,
  OnlineRequestPath,
  OnlineResponsePath,
};
