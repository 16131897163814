import { Paper, Typography } from '@mui/material';
import React, { FC, PropsWithChildren } from 'react';

interface GraphContainerProps {
  headerLabel: string;
}

export const X_AXIS_LABEL = 'Time (Local)';

const GraphContainer: FC<PropsWithChildren<GraphContainerProps>> = ({ headerLabel, children }) => {
  return (
    <Paper square>
      <div className="pa3 bb b-gray-300">
        <div>
          <Typography variant="h5">{headerLabel}</Typography>
        </div>
      </div>
      <div>{children}</div>
    </Paper>
  );
};

export default GraphContainer;
