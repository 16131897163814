import { EmptyValue, Monospace, RelatedFCOLink, TitledItem, useTectonTheme } from '@tecton/ComponentRedesign';
import React, { FC } from 'react';
import {
  EntityFCO,
  FCOFields,
  FCOType,
  FeatureServiceFCO,
  FeatureServiceFCOFields,
  FeatureViewFCO,
  FeatureViewFCOFields,
  WorkspaceFCOContainer,
} from '../../../core/types/fcoTypes';
import TectonCard from '../../@tecton/ComponentRedesign/lib/Card';
import { useUserSettings } from '../../context/UserSettingsContext';

import styled from '@emotion/styled';
import { FeatureServiceSummary } from '../../../core/types/featureServiceSummary';
import { EnabledDisabledBadge } from '../../@tecton/ComponentRedesign';

interface FeatureServiceConfigCardProps {
  fco: FeatureServiceFCO;
  fcos: WorkspaceFCOContainer;
  summary: FeatureServiceSummary;
}

const FeatureServiceConfigCard: FC<FeatureServiceConfigCardProps> = ({ fco, fcos, summary }) => {
  const { theme } = useTectonTheme();
  const { workspace } = useUserSettings();
  const dependentEntityIds = fcos.featureViews
    .filter((featureView) => fco[FeatureServiceFCOFields.ALL_FEATURE_VIEWS].includes(featureView[FCOFields.ID]))
    .map((featureView: FeatureViewFCO) => {
      return featureView[FeatureViewFCOFields.ALL_ENTITIES];
    })
    .flat();

  const dependentEntities = fcos.entities.filter((entity: EntityFCO) => {
    return dependentEntityIds.includes(entity[FCOFields.ID]);
  });

  const dependentFVJoinKeys = fcos.featureViews
    .filter((featureView) => fco[FeatureServiceFCOFields.ALL_FEATURE_VIEWS].includes(featureView[FCOFields.ID]))
    .map((featureView: FeatureViewFCO) => featureView[FeatureViewFCOFields.JOIN_KEYS])
    .flat();

  // Feature Service which has "Realtime" dependant FVs can have onlineJoinKeys which we need to show if they have it.
  const onlineJoinKeys = summary?.onlineJoinKeys?.split(',') ?? [];
  const trimmedOnlineJoinKeys = onlineJoinKeys.map((key) => key.trim());

  const dependentJoinKeyAndOnlineJoinKeys = [...trimmedOnlineJoinKeys, ...dependentFVJoinKeys];
  const noDuplicateJoinKeys = Array.from(new Set(dependentJoinKeyAndOnlineJoinKeys));
  const joinKeysList = noDuplicateJoinKeys.join(', ');

  const list: { title: React.ReactNode; content: React.ReactNode }[] = [
    {
      title: <>Entities</>,
      content: (
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {dependentEntities.length === 0 && <EmptyValue />}
          {dependentEntities.map((entity: EntityFCO, index: number) => {
            return (
              <>
                <RelatedFCOLink
                  name={entity.name ?? ''}
                  type={FCOType.ENTITY}
                  description={entity.description}
                  workspace={workspace ?? ''}
                />
                {index + 1 !== dependentEntities.length && <>,&nbsp;&nbsp;&nbsp;&nbsp;</>}
              </>
            );
          })}
        </div>
      ),
    },
    {
      title: <>Join Keys</>,
      content: dependentJoinKeyAndOnlineJoinKeys.length === 0 ? <EmptyValue /> : <Monospace>{joinKeysList}</Monospace>,
    },
    {
      title: <>Request Data Source Keys</>,
      content: !summary?.requestContextKeys ? <EmptyValue /> : <Monospace>{summary?.requestContextKeys}</Monospace>,
    },
    {
      title: <>Online Serving</>,
      content: <EnabledDisabledBadge enabled={fco[FeatureServiceFCOFields.IS_ONLINE_SERVING_ENABLED]} />,
    },
    {
      title: <>On-Demand Environment</>,
      content: fco[FeatureServiceFCOFields.REALTIME_ENVIRONMENT] ? (
        <Monospace>{fco[FeatureServiceFCOFields.REALTIME_ENVIRONMENT]}</Monospace>
      ) : (
        <EmptyValue />
      ),
    },
    {
      title: <>Logging Enabled</>,
      content: <EnabledDisabledBadge enabled={summary?.isLoggingEnabled} />,
    },
    {
      title: <>Logging Sample Rate</>,
      content: !summary?.loggingSampleRate ? <EmptyValue /> : <Monospace>{summary?.loggingSampleRate}</Monospace>,
    },
    {
      title: <>Log Effective Times</>,
      content: !summary?.logEffectiveTimes ? (
        <EmptyValue />
      ) : (
        <EnabledDisabledBadge enabled={summary?.logEffectiveTimes} />
      ),
    },
    {
      title: <>Caching</>,
      content: <EnabledDisabledBadge enabled={fco[FeatureServiceFCOFields.IS_CACHING_ENABLED]} />,
    },
  ];

  const ListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${theme.padding.m};
  `;

  return (
    <TectonCard title="Configuration">
      <ListWrapper>
        {list.map((item) => (
          <TitledItem {...item} />
        ))}
      </ListWrapper>
    </TectonCard>
  );
};

export default FeatureServiceConfigCard;
