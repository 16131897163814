import MetadataService from '../../../service/MetadataService';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

export function useFeatureView(workspace, name) {
  const state = useQuery(['workspace', workspace, 'featureView', name], () => {
    return MetadataService.sharedInstance().getFeatureView(workspace, name);
  });
  if (state.error) {
    throw state.error;
  }
  return state;
}

export function useDebugFeatureViewById(workspace, id) {
  const state = useQuery(['workspace', workspace, 'featureView', id], () => {
    return MetadataService.sharedInstance().getFeatureView(workspace, undefined, id);
  });
  if (state.error) {
    throw state.error;
  }
  return state;
}

export function useFeatureViewMaterializationStatus(id, workspace, includeDeleted = false) {
  const state = useQuery(['featureViewMaterializationStatus', id], () => {
    return MetadataService.sharedInstance().getMaterializationStatus(id, workspace, includeDeleted);
  });
  if (state.error) {
    throw state.error;
  }
  return state;
}

export function useFeatureViewServingStatus(id) {
  const state = useQuery(['featureViewServingStatus', id], () => {
    return MetadataService.sharedInstance().getServingStatus(id);
  });
  if (state.error) {
    throw state.error;
  }
  return state;
}

export function useMutateForceRetryMaterializationTask() {
  const queryClient = useQueryClient();

  const state = useMutation(
    ({ materializationTaskId, allowOverwrite }) => {
      return MetadataService.sharedInstance()
        .forceRetryMaterializationTask(materializationTaskId, allowOverwrite)
        .then((result) => {
          return result;
        });
    },
    {
      onSuccess: (_, { featureViewId }) => {
        // On success, refresh status of the materialization jobs in the feature view
        queryClient.invalidateQueries(['featureViewMaterializationStatus', featureViewId]);
      },
    }
  );
  if (state.error) {
    throw state.error;
  }
  return state;
}

export function useMutateCancelMaterializationJob() {
  const queryClient = useQueryClient();

  const state = useMutation(
    ({ id, featureViewName, workspaceName }) => {
      return MetadataService.sharedInstance()
        .cancelMaterializationJob(id, featureViewName, workspaceName)
        .then((result) => {
          return result;
        });
    },
    {
      onSuccess: (_, { featureViewId }) => {
        // On success, refresh status of the materialization jobs in the feature view
        queryClient.invalidateQueries(['featureViewMaterializationStatus', featureViewId]);
      },
    }
  );
  if (state.error) {
    throw state.error;
  }
  return state;
}
