import React, { FC } from 'react';
import { Position } from '@xyflow/react';
import { EmbeddingModelNode } from './dataFlowTypes';
import DataFlowFCONodeTitle from './DataflowFCONodeTitle';
import DataFlowFCONodeName from './DataFlowFCONodeName';
import DataFlowFCONodeContainer from './DataFlowFCONodeContainer';
import DataFlowFCOCountBadge from './DataFlowFCOCountBadge';
import { DataFlowOpacityWrapper } from './DataFlowStyledComponents';
import styled from '@emotion/styled';
import DataFlowHandle from './DataFlowHandle';
import { Icons, IconTypes } from '@tecton/ComponentRedesign';

const CardIconDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IconWrapper = styled.div<{
  denseMode?: boolean;
}>`
  background-color: ${({ theme }) => theme.FCOTintColors.transformation};
  height: ${({ denseMode }) => (denseMode ? '24px' : '36px')};
  width: ${({ denseMode }) => (denseMode ? '24px' : '36px')};
  border-radius: ${({ theme }) => theme.border.radius.small};
  display: flex;
  align-items: center;
  justify-content: center;

  ellipse,
  path,
  circle,
  rect {
    stroke: ${({ theme }) => theme.FCOColors.transformation};
  }
`;

export const DataFlowFCOIcon = ({ isCustomModel, denseMode }: { isCustomModel: boolean; denseMode?: boolean }) => {
  const iconType = isCustomModel ? IconTypes.EMBEDDING_CUSTOM_MODEL : IconTypes.EMBEDDING_MODEL;

  return (
    <CardIconDiv>
      <IconWrapper denseMode={denseMode}>{Icons[iconType]}</IconWrapper>
    </CardIconDiv>
  );
};

interface DataFlowFCONodeProps {
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  onClick: (event: React.MouseEvent) => void;
  hasFocus: boolean;
  isFaded: boolean;
  DEBUG_MODE?: boolean;
  data: EmbeddingModelNode;
  denseMode?: boolean;
  workspace?: string;
}

const DataFlowNameTitleWrapper = styled.div`
  display: flex;
  align-items: space-around;
  justify-content: center;
  padding: 0px ${({ theme }) => theme.padding.s};
  flex-direction: column;
  width: 147px;
`;

const DataFlowFCONode: FC<DataFlowFCONodeProps> = ({
  onMouseEnter,
  onMouseLeave,
  hasFocus,
  isFaded,
  DEBUG_MODE,
  data,
  denseMode,
}) => {
  return (
    <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <DataFlowOpacityWrapper isFaded={isFaded}>
        <DataFlowFCONodeContainer
          hasFocus={hasFocus}
          isFaded={isFaded}
          fcoType="embedding"
          hasRequestDataSource={false}
        >
          <div>
            <DataFlowFCOIcon isCustomModel={data.isCustomModel} denseMode={denseMode} />
          </div>
          <DataFlowNameTitleWrapper>
            <DataFlowFCONodeTitle data={data} />
            <DataFlowFCONodeName name={DEBUG_MODE ? data.id : data.name} />
          </DataFlowNameTitleWrapper>
          <div>
            <DataFlowFCOCountBadge data={data} />
          </div>
          <DataFlowHandle position={Position.Left} top={'32px'} />
          <DataFlowHandle position={Position.Right} top={'32px'} />
        </DataFlowFCONodeContainer>
      </DataFlowOpacityWrapper>
    </div>
  );
};

export default DataFlowFCONode;
