import React, { FC, PropsWithChildren } from 'react';
import { Grid, Typography } from '@mui/material';

import StandardPage from '../../../../components/page/StandardPage';
import { useUserSettings } from '../../../../components/context/UserSettingsContext';

interface AdminsOnlyPageProps {
  checkListAccess?: boolean;
}

const AdminsOnlyPage: FC<PropsWithChildren<AdminsOnlyPageProps>> = ({ checkListAccess = false, children }) => {
  // This component makes sure you are an administrator to see the page.
  const { isAdmin, hasOrganizationListAccess } = useUserSettings();
  const allowAccess = checkListAccess ? hasOrganizationListAccess : isAdmin;
  let content = children;

  if (allowAccess) {
    // User has access to the page
    content = children;
  } else {
    // No access to the page
    content = (
      <StandardPage pageTitle="Administration Only" canShowTitle={false}>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: '100vh' }}
        >
          <Typography className="center" variant="body1">
            This action can only be completed by Tecton admins. Learn more about admin users in
            <a
              href="https://docs.tecton.ai/docs/setting-up-tecton/administration-setup/creating-a-workspace-and-adding-users-to-the-workspace"
              target="_blank"
              rel="noopener noreferrer"
            >
              {' '}
              User Management documentation
            </a>
          </Typography>
        </Grid>
      </StandardPage>
    );
  }

  return <>{content}</>;
};

export default AdminsOnlyPage;
