import { FC } from 'react';
import { Link } from 'react-router-dom';

import { FlexGroupWrapper, FlexItem, Table } from '@tecton/ComponentRedesign';
import NoData from './NoData';

interface HealthResult {
  feature_package_id: string;
  feature_view_name: string;
  workspace_name: string;
}

interface FeatureViewIdsTableProps {
  healthResults: HealthResult[];
}

const FeatureViewHealthTable: FC<FeatureViewIdsTableProps> = ({ healthResults }) => {
  // empty state
  if (healthResults.length === 0) {
    return (
      <div>
        <NoData />
      </div>
    );
  }

  const columns = [
    {
      name: ``,
      render: (item: HealthResult) => (
        <>
          <Link to={`/repo/${item.workspace_name}/features/${item.feature_view_name}`} title={item.feature_view_name}>
            {item.feature_view_name}
          </Link>
        </>
      ),
    },
  ];

  return (
    <>
      <FlexGroupWrapper gap="s">
        <FlexItem>
          <Table columns={columns} compressed items={healthResults} />
        </FlexItem>
      </FlexGroupWrapper>
    </>
  );
};

export default FeatureViewHealthTable;
