import { Outlet, useNavigate, useParams } from 'react-router';
import { useUserSettings } from '../../components/context/UserSettingsContext';
import { Routes } from '../routes';
import useUpdatePatchState from './useUpdatePatchState';

const Workspace = () => {
  const { workspace } = useParams();
  const { updateWorkspace, allWorkspaces } = useUserSettings();
  const navigate = useNavigate();

  const workspaceNames = allWorkspaces?.map((ws) => ws.name) ?? [];

  if (!workspaceNames?.includes(workspace ?? '')) {
    // The workspace doesn't exist or deleted, so we redirect them to prod workspace.
    navigate(`${Routes.defaultProdHome}`);
  }

  // Update computedMatch Routes for workspace selector
  // This use to happen in PrivateRoute
  useUpdatePatchState(workspace);

  if (workspace) {
    updateWorkspace(workspace);
  }
  return <Outlet />;
};

export default Workspace;
