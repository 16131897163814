import { ReactComponent } from 'react-hotkeys';
import {
  AggregationNode,
  DataFlowNode,
  DataFlowTemplateType,
  DataSourceNode,
  EmbeddingModelNode,
  FeatureServiceNode,
  FeatureViewNode,
  ModelInferenceNode,
  ModelTrainerNode,
  ODFVNode,
  OfflineStoreNode,
  OnlineStoreNode,
  RawBatchNode,
  RawStreamNode,
  RequestNode,
  StoreWrapperNode,
  TransformationNode,
} from './dataFlowTypes';
import { anchorTypes } from './helpers';
import { useDataflowNodeProps } from './useDataflowNodeProps';
import DataFlowFCONode from './DataFlowFCONode';
import DataFlowIconNode from './DataFlowIconNode';

import DataFlowStorageNode from './DataFlowStorageNode';
import DataFlowIndividualStoreNode from './DataflowIndividualStoreNode';
import DataFlowHandle from './DataFlowHandle';
import { Position } from '@xyflow/react';
import DataFlowAggregationNode from './DataFlowAggregationNode';
import DataFlowEmbeddingModelNode from './DataFlowEmbeddingModelNode';
import DataFlowObjectLevelCardOutlet from './DataFlowObjectLevelCardOutlet';
import DataflowRequestDataSource from './DataflowRequestDataSource';
import DataFlowFeatureServiceAnchorCard from './DataFlowFeatureServiceAnchorCard';
import DataFlowFeatureViewAnchorCard from './DataFlowFeatureViewAnchorNode';

const RenderingNodeKeys = [
  'SourceIconByType',
  'DataSourceAnchor',
  'DataSourceLarge',
  'RequestDataSourceLarge',
  'RequestDataSourceIcon',
  'FeatureViewAnchor',
  'FeatureViewAnchorOutput',
  'FeatureViewLarge',
  'FeatureServiceAnchor',
  'FeatureServiceLarge',
  'Transformation',
  'EmbeddingModel',
  'Aggregation',
  'OnlineStore',
  'OfflineStore',
  'StorageGroup',
  'StoreInput',
  'StoreOutput',
  'OnlineConsumerIcon',
  'OfflineConsumerIcon',
] as const;

type RenderingNodeKeysType = typeof RenderingNodeKeys[number];

type NodeRendererMapType = Record<RenderingNodeKeysType, ReactComponent>;

const DFIconByTypeWithContainer = ({ data }: { data: RawBatchNode | RawStreamNode }) => {
  const props = useDataflowNodeProps(data);

  return <DataFlowIconNode iconType={data.type === 'raw_stream_node' ? 'stream' : 'batch'} data={data} {...props} />;
};

const FeatureViewAnchorWithContainer = ({ data }: { data: FeatureViewNode | ODFVNode }) => {
  const props = useDataflowNodeProps(data);

  return <DataFlowFeatureViewAnchorCard data={data} {...props} />;
};

const DFDataSourceCardLargeWithContainer = ({ data }: { data: DataSourceNode }) => {
  const props = useDataflowNodeProps(data);

  return <DataFlowFCONode fcoType="data_source" data={data} {...props} />;
};

const DFDataSourceAnchorCardWithContainer = () => {
  return <></>;
  // Unused currently, but will be used when Data source is added
  // return <DataFlowFeatureViewAnchorCard data={data} {...props} />;
};

const DFRequestDataSourceLargeWithContainer = ({ data }: { data: RequestNode }) => {
  const props = useDataflowNodeProps(data);

  return <DataflowRequestDataSource data={data} {...props} isLarge />;
};

const RDSIconWithContainer = ({ data }: { data: RequestNode }) => {
  const props = useDataflowNodeProps(data);

  return <DataflowRequestDataSource data={data} {...props} />;
};

const DFFeatureViewLargeWithContainer = ({ data }: { data: FeatureViewNode }) => {
  const props = useDataflowNodeProps(data);
  return <DataFlowFCONode fcoType="feature_view" data={data} {...props} />;
};

const DFFeatureServiceAnchorCardWithContainer = ({ data }: { data: FeatureServiceNode }) => {
  const props = useDataflowNodeProps(data);

  return <DataFlowFeatureServiceAnchorCard data={data} {...props} />;
};

const DFFeatureServiceLargeWithContainer = ({ data }: { data: FeatureServiceNode }) => {
  const props = useDataflowNodeProps(data);
  return <DataFlowFCONode fcoType="feature_service" data={data} {...props} />;
};

const DFFeatureViewAnchorOutputWithContainer = ({ data }: { data: FeatureViewNode }) => {
  const props = useDataflowNodeProps(data);

  return <DataFlowObjectLevelCardOutlet data={data} {...props} />;
};

const OnlineStoreIconWithContainer = ({ data }: { data: OnlineStoreNode }) => {
  const props = useDataflowNodeProps(data);

  return <DataFlowIndividualStoreNode storeType="online" data={data} {...props} />;
};

const OfflineStoreIconWithContainer = ({ data }: { data: OfflineStoreNode }) => {
  const props = useDataflowNodeProps(data);

  return <DataFlowIndividualStoreNode storeType="offline" data={data} {...props} />;
};

const DFTransformationNodeWithContainer = ({ data }: { data: TransformationNode }) => {
  const props = useDataflowNodeProps(data);

  return <DataFlowFCONode fcoType="transformation" data={data} {...props} />;
};

const DFAggregationNodeWithContainer = ({ data }: { data: AggregationNode }) => {
  const props = useDataflowNodeProps(data);

  return <DataFlowAggregationNode data={data} {...props} />;
};

const DFEmbeddingModelNodeContainer = ({ data }: { data: EmbeddingModelNode }) => {
  const props = useDataflowNodeProps(data);

  return <DataFlowEmbeddingModelNode data={data} {...props} />;
};

const FVStorePanelNodeWithContainer = ({ data }: { data: StoreWrapperNode }) => {
  const props = useDataflowNodeProps(data);

  return <DataFlowStorageNode data={data} {...props} />;
};

const InputHandlePlaceholderWithContainer = () => {
  return <DataFlowHandle position={Position.Left} top="0" />;
};

const OutputHandlePlaceholderWithContainer = () => {
  return <DataFlowHandle position={Position.Left} top="0" />;
};

const DFOnlineConsumerIconWithContainer = ({ data }: { data: ModelInferenceNode }) => {
  const props = useDataflowNodeProps(data);

  return <DataFlowIconNode iconType="modelInference" data={data} {...props} />;
};

const DFOfflineConsumerIconWithContainer = ({ data }: { data: ModelTrainerNode }) => {
  const props = useDataflowNodeProps(data);

  return <DataFlowIconNode iconType="modelTrainer" data={data} {...props} />;
};

export const nodeTypeMap: NodeRendererMapType = {
  SourceIconByType: DFIconByTypeWithContainer,
  FeatureViewAnchor: FeatureViewAnchorWithContainer,
  DataSourceAnchor: DFDataSourceAnchorCardWithContainer,
  DataSourceLarge: DFDataSourceCardLargeWithContainer,
  RequestDataSourceLarge: DFRequestDataSourceLargeWithContainer,
  RequestDataSourceIcon: RDSIconWithContainer,
  FeatureViewLarge: DFFeatureViewLargeWithContainer,
  FeatureServiceAnchor: DFFeatureServiceAnchorCardWithContainer,
  FeatureServiceLarge: DFFeatureServiceLargeWithContainer,
  FeatureViewAnchorOutput: DFFeatureViewAnchorOutputWithContainer,
  OnlineStore: OnlineStoreIconWithContainer,
  OfflineStore: OfflineStoreIconWithContainer,
  Transformation: DFTransformationNodeWithContainer,
  EmbeddingModel: DFEmbeddingModelNodeContainer,
  Aggregation: DFAggregationNodeWithContainer,
  StorageGroup: FVStorePanelNodeWithContainer,
  StoreInput: InputHandlePlaceholderWithContainer,
  StoreOutput: OutputHandlePlaceholderWithContainer,
  OnlineConsumerIcon: DFOnlineConsumerIconWithContainer,
  OfflineConsumerIcon: DFOfflineConsumerIconWithContainer,
};

export const getTemplateDisplayType = (node: DataFlowNode, templateType: DataFlowTemplateType) => {
  // If the nodes is the anchor node ...
  if (node.isAnchor) {
    const anchorComponents: Record<string, RenderingNodeKeysType> = {
      data_source: 'DataSourceAnchor',
      feature_view: 'FeatureViewAnchorOutput',
      odfv: 'FeatureViewAnchorOutput',
      feature_service: 'FeatureServiceAnchor',
    };

    if (!anchorTypes.includes(node.type)) {
      throw new Error(`There is not anchor component designed for node type '${node.type}'.`);
    }

    return anchorComponents[node.type];
  }

  const rdsUsesIconRendering = templateType === 'materializing_fv' || templateType === 'ondemand_fv';

  const componentNames: Record<DataFlowNode['type'], RenderingNodeKeysType> = {
    raw_batch_node: 'SourceIconByType',
    raw_stream_node: 'SourceIconByType',
    transformation: 'Transformation',
    embeddingModel: 'EmbeddingModel',
    data_source: 'DataSourceLarge',
    store_wrapper: 'StorageGroup',
    feature_view: 'FeatureViewLarge',
    aggregation: 'Aggregation',
    store_input: 'StoreInput',
    store_output: 'StoreOutput',
    online_store: 'OnlineStore',
    offline_store: 'OfflineStore',
    odfv: 'FeatureViewLarge',
    request_data_source: rdsUsesIconRendering ? 'RequestDataSourceIcon' : 'RequestDataSourceLarge',
    feature_service: 'FeatureServiceLarge',
    model_inference: 'OnlineConsumerIcon',
    model_trainer: 'OfflineConsumerIcon',
  };

  if (!componentNames[node.type]) {
    throw new Error(`Node Type ${node.type} has no matching rendering component.`);
  }

  return componentNames[node.type];
};
