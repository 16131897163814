import React, { FC } from 'react';
import { Grid } from '@mui/material';
import { WorkspaceWithPermissions } from '../../../components/context/types';
import WorkspaceTypeSelectionBox from './WorkspaceTypeSelectionBox';
import { useUserSettings } from '../../../components/context/UserSettingsContext';

interface FilteredWorkspacesToSelectProperties {}

const FilteredWorkspacesToSelect: FC<FilteredWorkspacesToSelectProperties> = () => {
  const { allWorkspaces: workspaces } = useUserSettings();

  const filterLiveWorkspaces = (workspaces: WorkspaceWithPermissions[]) => {
    return workspaces
      .filter((workspace: any) => {
        return workspace.capabilities.materializable;
      })
      .sort((b: any, a: any) => {
        return Number(a.isAccessible) - Number(b.isAccessible);
      });
  };

  const filterDevWorkspaces = (workspaces: WorkspaceWithPermissions[]) => {
    return workspaces
      .filter((workspace: any) => {
        return !workspace.capabilities.materializable;
      })
      .sort((b: any, a: any) => {
        return Number(a.isAccessible) - Number(b.isAccessible);
      });
  };

  // This helps simplify the rest of the code, since it allows us to avoid having to repeatedly cast workspaces below
  if (workspaces === undefined) {
    return <></>;
  }

  return (
    <>
      <Grid
        container
        className="mb4"
        sx={{
          width: '50em',
          margin: 'auto',
          overflowY: 'hidden',
          marginBottom: '48px',
        }}
      >
        <WorkspaceTypeSelectionBox
          headline="Live Workspaces"
          workspaces={filterLiveWorkspaces(workspaces)}
          description={
            <>
              <span className="fw7">Live Workspaces</span> support materialization and online feature serving. They are
              typically used in production.
            </>
          }
        />

        <WorkspaceTypeSelectionBox
          headline="Dev Workspaces"
          workspaces={filterDevWorkspaces(workspaces)}
          description={
            <>
              <span className="fw7">Dev Workspaces</span> allow you to perform experimental and exploratory development
              without incurring any materialization costs.
            </>
          }
        />
      </Grid>
    </>
  );
};

export default FilteredWorkspacesToSelect;
