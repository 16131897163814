import React from 'react';
import { TectonBasicTable } from '@shared';
import styled from '@emotion/styled';
import { isBoolean } from 'lodash';
import { SparkField } from '../../../../../types/tecton_proto/common/spark_schema';

const Wrapper = styled.div`
  .euiTableCellContent {
    padding-left: 0;
  }
`;

type SparkTableEntry = {
  name: string;
  type: string;
  nullable: boolean | 'n/a';
};

const SparkSchemaTable = ({ sparkSchema }: { sparkSchema: SparkField[] }) => {
  const items = sparkSchema.map((entry) => {
    const json = entry.structfield_json ? JSON.parse(entry.structfield_json) : undefined;

    const result: SparkTableEntry = {
      name: entry.name || 'n/a',
      type: json && json.type ? json.type : 'n/a',
      nullable: json && isBoolean(json.nullable) ? json.nullable : 'n/a',
    };

    return result;
  });

  const columns = [
    {
      field: 'name',
      name: 'Name',
    },
    {
      field: 'type',
      name: 'Type',
    },
    {
      field: 'nullable',
      name: 'Nullable',
    },
  ];

  return (
    <Wrapper>
      <TectonBasicTable items={items} columns={columns} pageIndex={0} pageSize={0} totalItemCount={0} />
    </Wrapper>
  );
};

export default SparkSchemaTable;
