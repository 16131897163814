import styled from '@emotion/styled';
import { Panel } from '../../@tecton/ComponentRedesign';

export const DataPlatformHeaderHelpWrapper = styled.div`
  margin-block-end: ${({ theme }) => theme.size.s};
  font-size: 16px;
  font-weight: ${({ theme }) => theme.font.weight.medium};
  line-height: ${({ theme }) => theme.font.lineHeights.s};
`;

export const ConfigurationCautionWrapper = styled.div`
  font-size: ${({ theme }) => theme.font.fontSizes.xs};
  color: ${({ theme }) => theme.colors.subduedText};
`;

export const StatusListItem = styled(Panel)`
  display: grid;
  grid-template-columns: ${({ theme }) => theme.size.m} 1fr;
  gap: ${({ theme }) => theme.size.l};

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.border.color};
  }

  .euiLoadingSpinner {
    margin-top: 3px;
  }
`;
