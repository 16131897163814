export enum SparkConfigurationMapKey {
  GLUE_CATALOG_ID = 'spark.hadoop.hive.metastore.glue.catalogid',
  GLUE_CATALOG_ID_ENABLED = 'spark.databricks.hive.metastore.glueCatalog.enabled',
}

export enum FieldMaskValues {
  workspace_url = 'databricksConfig.workspaceUrl',
  api_token = 'databricksConfig.apiToken.value',
  instance_profile_arn = 'userSparkSettings.instanceProfileArn',
  glueCatalogEnabled = 'userSparkSettings.sparkConf',
  glueCatalogId = 'userSparkSettings.sparkConf',
}

export interface DatabricksConfigInterface {
  workspace_url?: string;
  api_token?: { value: string };
}

export interface KeyPairInterface {
  [key: string]: string;
}

export interface UserSparkSettingsInterface {
  instance_profile_arn?: string;
  spark_conf?: KeyPairInterface;
}

export interface UserDeploymentSettingInterface {
  databricks_config: DatabricksConfigInterface;
  user_spark_settings?: UserSparkSettingsInterface;
}

export interface UpdateUserDeploymentSettingsRequestInterface {
  user_deployment_settings?: UserDeploymentSettingInterface;
  field_mask: string;
}

export interface SubmissionInterface {
  api_token: string;
  workspace_url?: string;
  glueCatalogId?: string;
  instance_profile_arn?: string;
  glueCatalogEnabled?: boolean;
}

export interface ErrorsInterface {
  api_token?: string[];
  workspace_url?: string[];
  instance_profile_arn?: string[];
  glueCatalogId?: string[];
}
