import React, { FC } from 'react';
import styled, { CSSObject } from '@emotion/styled';

type FlexGrow = React.CSSProperties['flexGrow'];
type FlexShrink = React.CSSProperties['flexShrink'];
type FlexBasis = React.CSSProperties['flexBasis'];
type CSSOverflow = React.CSSProperties['overflow'];

interface FlexItemProps {
  children: React.ReactNode;
  grow?: FlexGrow;
  shrink?: FlexShrink;
  basis?: FlexBasis;
  overflow?: CSSOverflow;
  css?: CSSObject;
}

const FlexItemWrapper = styled.div<FlexItemProps>`
  flex-grow: ${({ grow }) => grow};
  ${({ css }) => css};
  flex-basis: ${({ basis }) => basis};
  flex-shrink: ${({ shrink }) => shrink};
  overflow: ${({ overflow }) => overflow};
`;

const FlexItem: FC<FlexItemProps> = ({
  children,
  overflow = 'auto',
  grow = '1',
  shrink = '1',
  basis = 'auto',
  css = {},
}) => {
  return (
    <FlexItemWrapper grow={grow} shrink={shrink} basis={basis} css={css} overflow={overflow}>
      {children}
    </FlexItemWrapper>
  );
};

export default FlexItem;
