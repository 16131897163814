import React from 'react';
import { css } from '@emotion/css';
import { FlexItem, useTectonTheme } from '@tecton/ComponentRedesign';
import { DataPlatformHeaderHelpWrapper } from './StyledComponents';

type ExplanationEntry = {
  title: string;
  id: string;
  children: React.ReactNode;
};

const FormExplanation = ({ entries, entryWithFocus }: { entries: ExplanationEntry[]; entryWithFocus?: string }) => {
  const { theme } = useTectonTheme();

  const wrapperCss = css`
    border-top: 1px solid ${theme.border.color};
    margin-top: ${theme.size.m};
    margin-bottom: 80vh;
  `;

  const highlightBorderSize = '0px';
  const formEntryCss = css`
    padding: 0 ${theme.size.l} 0 calc(${theme.size.l} - ${highlightBorderSize});
    border-left: ${highlightBorderSize} solid transparent;
    margin: 0 -${theme.size.l};
    border-radius: 2px;

    &.step-has-focus {
      border-left-color: ${theme.colors.primary};
      border-radius: 0;
      h4 {
        color: ${theme.colors.primary};
      }
    }
  `;

  const scrollTargetCss = css`
    padding-top: ${theme.size.xl};
  `;

  const matchFocusClass = (entryId: string) => {
    return entryWithFocus === entryId ? 'step-has-focus' : '';
  };

  return (
    <span className={wrapperCss}>
      {entries.map((entry) => {
        return (
          <FlexItem key={entry.id} id={entry.id} className={scrollTargetCss}>
            <div className={formEntryCss + ' ' + matchFocusClass(entry.id)}>
              <DataPlatformHeaderHelpWrapper>{entry.title}</DataPlatformHeaderHelpWrapper>
              {entry.children}
            </div>
          </FlexItem>
        );
      })}
    </span>
  );
};

export default FormExplanation;
