import React, { ChangeEventHandler, FC, useState } from 'react';
import moment from 'moment';
import _debounce from 'lodash/debounce';
import styled from '@emotion/styled';

// generated
import { Group } from '../../../api/gql/graphql';

import TitleValueLayout from './TitleValueLayout';

// @tecton
import {
  TitledItemEditable,
  Button,
  Card,
  FlexGrid,
  FlexGroup,
  FlexItem,
  FormInput,
  Text,
  EditableInlineField,
  RequestCallbackType,
  Icon,
  Tag,
  EditableFieldWrapper,
} from '@tecton/ComponentRedesign';
import { TectonDateFormat } from '@tecton/ComponentRedesign/utils';

// svg
import { ReactComponent as Trash } from '@tecton/ComponentRedesign/svg/trash.svg';
import { ReactComponent as Plus } from '@tecton/ComponentRedesign/svg/plus.svg';
import Edit from '@svg/edit.svg';
import AccountTypeIconName from './AccountTypeIconName';

export interface ACLGroupDetailsCardProps {
  group?: Group;
  isAdminView: boolean;
  attributeValue?: string;
  isSavingAttributeName: boolean;
  onDeleteGroupClicked: VoidFunction;
  onChangeAddAttributeName: ChangeEventHandler<HTMLInputElement>;
  onAddIDPAttributeClick: (successCallBack: VoidFunction) => void;
  onDeleteAttribute: (idpName: string) => void;
  onFieldSaveConfirm: (filedName: string, newValue: string, callback: RequestCallbackType) => void;
  onEditAccountType: VoidFunction;
}

export const AccountTypeWrapper = styled.div`
  font-size: ${({ theme }) => theme.font.fontSizes.s};
  cursor: pointer;
`;

export const FormInputWrapper = styled.div`
  justify-items: 'center';

  .euiFormRow__labelWrapper {
    margin-bottom: 0px;
  }
`;

const ACLGroupDetailsCard: FC<ACLGroupDetailsCardProps> = ({
  group,
  isAdminView,
  attributeValue,
  isSavingAttributeName,
  onDeleteGroupClicked,
  onChangeAddAttributeName,
  onAddIDPAttributeClick,
  onDeleteAttribute,
  onFieldSaveConfirm,
  onEditAccountType,
}) => {
  const [canShowAttributeInputField, setCanShowAttributeInputField] = useState(false);
  const isAddAttributeButtonEnabled =
    !!attributeValue || !canShowAttributeInputField || (canShowAttributeInputField && !!attributeValue);

  const serviceAccountInformationLists = [
    {
      title: 'Name',
      name: 'name',
      content: group?.name ?? '',
      isEditable: true,
      renderField: (
        fieldTitle: string,
        fieldName: string,
        fieldValue: string,
        onFieldSaveConfirm: (filedName: string, newValue: string, callback: RequestCallbackType) => void
      ) => {
        const valueContent = isAdminView ? (
          <>
            <EditableInlineField
              fieldTitle={fieldTitle}
              fieldName={fieldName}
              fieldValue={fieldValue}
              onFieldSaveConfirm={onFieldSaveConfirm}
            />
          </>
        ) : (
          <>{fieldValue}</>
        );
        return (
          <>
            <TitleValueLayout title={fieldTitle} valueContent={valueContent} />
          </>
        );
      },
    },
    ...(isAdminView
      ? [
          {
            title: 'Account Type',
            name: 'accountType',
            content: group?.accountType ?? '',
            isEditable: true,
            renderField: (fieldTitle: string, fieldName: string, fieldValue: string) => {
              const accountType = fieldValue === 'Admin' ? 'Admin' : 'Regular';
              return (
                <>
                  <TitleValueLayout
                    title={`Account Type`}
                    valueContent={
                      <>
                        <EditableFieldWrapper onClick={onEditAccountType} paddingTop={'0'}>
                          <FlexGroup justifyContent="spaceBetween" gutterSize="s">
                            <FlexItem grow={false}>
                              <AccountTypeIconName accountType={accountType} />
                            </FlexItem>
                            <FlexItem grow={false} style={{ alignSelf: 'center' }}>
                              <Icon type={Edit} />
                            </FlexItem>
                          </FlexGroup>
                        </EditableFieldWrapper>
                      </>
                    }
                  />
                </>
              );
            },
          },
        ]
      : []),

    {
      title: 'Description',
      name: 'description',
      content: group?.description ?? '',
      isEditable: true,
      renderField: (
        fieldTitle: string,
        fieldName: string,
        fieldValue: string,
        onFieldSaveConfirm: (filedName: string, newValue: string, callback: RequestCallbackType) => void
      ) => {
        const valueContent = isAdminView ? (
          <>
            <EditableInlineField
              fieldTitle={fieldTitle}
              fieldName={fieldName}
              fieldValue={fieldValue}
              onFieldSaveConfirm={onFieldSaveConfirm}
            />
          </>
        ) : (
          <>{fieldValue}</>
        );
        return (
          <>
            <TitleValueLayout title={fieldTitle} valueContent={valueContent} />
          </>
        );
      },
    },
    {
      title: 'Group Id',
      name: 'id',
      content: group?.id ?? '',
      isEditable: false,
      renderField: undefined,
    },
    {
      title: 'Group Created',
      name: 'created_at',
      content: group?.createdAt ? TectonDateFormat(moment(group?.createdAt)) : '-',
      isEditable: false,
      renderField: undefined,
    },
  ];

  const adminActionCard = (
    <Card title={`Delete Group`} showOverlay={false}>
      <FlexGroup direction="column" gutterSize="s">
        <FlexItem>
          Deleting a group will cause group members to lose access to Workspaces that were granted by this group.
        </FlexItem>
        <FlexItem>
          <FlexGroup direction="row" justifyContent="flexEnd">
            <FlexItem grow={false}>
              <Button
                label={`Delete Group`}
                variant="dangerousAction"
                iconLeft={Trash}
                onClick={() => {
                  onDeleteGroupClicked();
                }}
              />
            </FlexItem>
          </FlexGroup>
        </FlexItem>
      </FlexGroup>
    </Card>
  );

  return (
    <>
      <FlexGroup direction="column" gutterSize="l">
        <FlexItem>
          <Card title={`Overview`} noScroll showOverlay={false}>
            <FlexGrid columns={1} gutterSize="m">
              {/* ------------- editable field names for groups ---------------*/}
              {serviceAccountInformationLists.map((item, key) => {
                return (
                  <>
                    <TitledItemEditable
                      key={key}
                      fieldTitle={item.title ?? ''}
                      fieldName={item.name ?? ''}
                      fieldValue={item.content ?? ''}
                      isEditable={item?.isEditable ?? false}
                      onFieldSaveConfirm={onFieldSaveConfirm}
                      renderField={item.renderField}
                      orientation="vertical"
                    />
                  </>
                );
              })}
              {isAdminView && (
                <>
                  <FlexGroup direction="column" gutterSize="m" style={{ marginTop: 24 }}>
                    <FlexItem>
                      <Text element="h4">Identity Provider Groups Mapping</Text>
                    </FlexItem>
                    <FlexItem>
                      <Text>
                        Tecton can automatically add users to groups based on their identity provider (IdP) attributes.
                        There are no attributes set up for this group.
                      </Text>
                    </FlexItem>
                  </FlexGroup>
                  {group?.idpMappingNames && (
                    <FlexGroup direction="row" gutterSize="m" wrap>
                      {group?.idpMappingNames.map((name) => {
                        return (
                          <FlexItem grow={false}>
                            <Tag
                              label={name ?? ''}
                              onClick={() => {
                                onDeleteAttribute(name ?? '');
                              }}
                            />
                          </FlexItem>
                        );
                      })}
                    </FlexGroup>
                  )}

                  <FlexGroup direction="row" gutterSize="m" justifyContent="center" alignItems="center">
                    <FlexItem grow={true}>
                      <FormInputWrapper>
                        {canShowAttributeInputField && (
                          <FormInput
                            value={attributeValue ?? ''}
                            placeholder="Attribute name"
                            onChange={onChangeAddAttributeName}
                            fullWidth
                            compressed
                          />
                        )}
                      </FormInputWrapper>
                    </FlexItem>

                    <FlexItem grow={false} style={{ justifyItems: 'center' }}>
                      <FormInputWrapper>
                        <Button
                          label={`Add`}
                          iconLeft={Plus}
                          variant={isAddAttributeButtonEnabled ? 'primaryAction' : 'disabledAction'}
                          isLoading={isSavingAttributeName}
                          onClick={() => {
                            if (canShowAttributeInputField && !!attributeValue) {
                              const successCallback = () => {
                                // Success saving the attribute and we hide the input field again.
                                setCanShowAttributeInputField(false);
                              };

                              onAddIDPAttributeClick(successCallback);
                            } else {
                              // we show the input field.
                              setCanShowAttributeInputField(true);
                            }
                          }}
                        />
                      </FormInputWrapper>
                    </FlexItem>
                  </FlexGroup>
                </>
              )}
            </FlexGrid>
          </Card>
        </FlexItem>

        {isAdminView && (
          <FlexItem>
            <FlexGroup direction="column" gutterSize="s">
              <FlexItem>
                <Text element="h5">Admin Actions</Text>
              </FlexItem>
              <FlexItem>{adminActionCard}</FlexItem>
            </FlexGroup>
          </FlexItem>
        )}
      </FlexGroup>
    </>
  );
};

export default ACLGroupDetailsCard;
