import styled from '@emotion/styled';
import React, { FC } from 'react';
import FlexGroup from './FlexGroup';
import { useTectonTheme } from '../../Theme/ThemeProvider';

interface TableViewLayoutProps {}

const Wrapper = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  border: 1px solid black;
  min-height: 100%;
  width: 100%;
`;

// ALL LAYOUTS ARE WiP and pending final design
const TableViewLayout: FC<TableViewLayoutProps> = ({}) => {
  const { theme } = useTectonTheme();

  return (
    <Wrapper>
      <FlexGroup alignItems="center" justifyContent="space-between" direction="row" gapSize="4" padding="4">
        <div style={{ border: `1px dashed ${theme.v1.colors.border.primary}` }}>[Search Input]</div>
        <div style={{ border: `1px dashed ${theme.v1.colors.border.primary}` }}>[Filter and Column Controls]</div>
      </FlexGroup>
      <div style={{ height: '100%', backgroundColor: theme.v1.colors.background.active }}>TABLE AREA</div>
    </Wrapper>
  );
};

export default TableViewLayout;
