import styled from '@emotion/styled';
import React, { FC } from 'react';
import { FlexGroup, FlexItem, Text, useTectonTheme } from '.';

type SwitchSize = 's' | 'm';

interface SwitchProps {
  state: boolean;
  onClick: () => void;
  label: React.ReactNode;
  size?: SwitchSize;
  disabled?: boolean;
}

const SwitchWrapper = styled.div<{ disabled?: boolean }>`
  background-color: ${({ theme }) => theme.v1.colors.background.empty};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  border-radius: ${({ theme }) => `${theme.v1.size['1']}px`};
  pointer-events: ${({ disabled }) => (disabled ? 'not-allowed' : 'cursor')};
  user-select: none;

  ${({ disabled, theme }) =>
    !disabled &&
    `
      &:hover {
        background-color:${theme.v1.colors.background.hover};
            * {
      color: ${theme.v1.colors.text.title};
      font-weight:${theme.font.weight.medium};
    }
      }
    `}
`;

const GroupContainer = styled.g<{ active: boolean }>`
  transform: ${({ theme, active }) =>
    `translate(${active ? theme.v1.size['2'] : theme.v1.size['8']}px,${theme.v1.size['2']}px)`};

  transition: transform ${({ theme }) => theme.v1.motion.duration.xfast}; /* Define transition for the transform property */
`;

const SwitchLarge: FC<{ active: boolean; size: SwitchSize; disabled: boolean }> = ({ active, size, disabled }) => {
  const { theme } = useTectonTheme();

  return (
    <svg
      viewBox={`0 0 ${theme.v1.size['12']} ${theme.v1.size['6']}`}
      width={size === 'm' ? theme.v1.size['12'] : theme.v1.size['6']}
      height={size === 'm' ? theme.v1.size['6'] : theme.v1.size['3']}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ overflow: 'visible', opacity: disabled ? 0.5 : 1 }}
    >
      <g transform={`translate(${theme.v1.size['0.5']},${theme.v1.size['0.5']})`}>
        <rect
          width={theme.v1.size['8']}
          height={theme.v1.size['4']}
          rx={theme.v1.size['2']}
          fill={active ? theme.v1.colors.background.activeFocus : theme.v1.colors.background.active}
        />
        <GroupContainer active={active}>
          <circle
            r={theme.v1.size['2']}
            fill={active ? theme.v1.colors.background.primary : theme.v1.colors.background.empty}
            stroke={active ? theme.v1.colors.text.title : theme.v1.colors.text.invertedText}
            strokeWidth={'1'}
          />

          {size === 'm' && active && (
            <path
              transform="scale(0.5) translate(-12,-12)"
              d="M5.5 11L10.5 16L19.5 7"
              stroke={theme.v1.colors.text.invertedText}
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          )}

          {size === 'm' && !active && (
            <g transform="scale(1) translate(-8,-8)">
              <path
                d="M4.6665 4.66797L11.3332 11.3346"
                stroke={theme.v1.colors.text.title}
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M4.6665 11.3346L11.3332 4.66797"
                stroke={theme.v1.colors.text.title}
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
          )}
        </GroupContainer>
      </g>
    </svg>
  );
};

const Switch: FC<SwitchProps> = ({ state, onClick, label, size = 'm', disabled = false }) => {
  const { theme } = useTectonTheme();

  return (
    <SwitchWrapper
      onClick={() => {
        if (!disabled) onClick();
      }}
      disabled={disabled}
    >
      <FlexGroup alignItems="center">
        <FlexItem grow={0}>
          <FlexGroup alignItems="center" padding="0" css={{ overflow: 'visible' }}>
            <SwitchLarge active={state} size={size} disabled={disabled} />
          </FlexGroup>
        </FlexItem>
        <Text
          small={size === 's'}
          weight={state && !disabled ? 'medium' : 'regular'}
          color={disabled ? theme.v1.colors.text.disabledText : theme.v1.colors.text.link}
        >
          {label}
        </Text>
      </FlexGroup>
    </SwitchWrapper>
  );
};

export default Switch;
