import React from 'react';
import { Callout } from '@tecton/ComponentRedesign';
import { css } from '@emotion/css';

const FormErrorFeedback = ({
  title,
  errors,
}: {
  title?: string;
  errors: {
    title?: string;
    message: string;
  }[];
}) => {
  const errorListCss = css``;

  return (
    <Callout title={title} mode="danger">
      <ul className={errorListCss}>
        {errors.map((entry) => {
          return (
            <li key={entry.title}>
              {entry?.title && <h4>{entry.title}</h4>}
              <p>{entry.message}</p>
            </li>
          );
        })}
      </ul>
    </Callout>
  );
};

export default FormErrorFeedback;
