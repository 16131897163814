import { FC, useContext, useState } from 'react';
import { Navigate, Route, Routes, useNavigate, useParams } from 'react-router';

import { Routes as AppRoutes } from '../../../core/routes';
import { useUserSettings } from '../../context/UserSettingsContext';
import { SimpleRequestStatus } from './acl-types';
import ACLMembershipOutlet from './ACLMembershipOutlet';
import ACLPrincipalGroupMembershipList from './ACLPrincipalGroupMembershipList';
import ACLPrincipalWorkspaceRoleList from './ACLPrincipalWorkspaceRoleList';
import ACLServiceAccountDetailsCard from './ACLServiceAccountDetailsCard';
import ActivateAccount from './ActivateAccount';
import AddWorkspaceAccess from './AddWorkspaceAccess';
import AssignGroup from './AssignGroup';
import Delete from './Delete';
import ModifyServiceAccountAccountType from './ModifyServiceAccountAccountType';
import ModifyWorkspaceAccess from './ModifyWorkspaceAccess';

// CRUD hooks
import {
  useAddPrincipalGroupMembersMutation,
  useAssignRoleMutation,
  useAssignRolesMutation,
  useDeleteServiceAccount,
  useMutateServiceAccount,
  useRemovePrincipalGroupMembersMutation,
  useUpdateAccountTypeMutation,
} from '../../../api/acl';
import { useGetACLServiceAccountDetails } from '../../../api/queries/aclGraphql';

// utils
import { getLegacyRoleIdGQL } from '../../acl/aclUtils';
import { getACLAccountRoutes, getRoleDescriptionMap, getRoleOptions, getRoleToPermissionMap } from './acl-util';

// generated
import { AclWorkspaceServiceRelationship, Group } from '../../../api/gql/graphql';
import { AssignRolesRequest } from '../../../types/tecton_proto/auth/authorization_service';
import { PrincipalType } from '../../../types/tecton_proto/auth/principal';
import { ResourceType } from '../../../types/tecton_proto/auth/resource';

// @tecton
import {
  AsymmetricBodyLayout,
  EmptyPrompt,
  ErrorPromptButtons,
  Modal,
  getToastComponent,
} from '@tecton/ComponentRedesign';
import { ToastContext, addToast } from '@tecton/ToastContext';
import { logEvent } from '../../../utils/analytics-utils';

const ACLServiceAccountContainer: FC = () => {
  const { serviceAccount: serviceAccountParam } = useParams();
  const { user, isAdmin } = useUserSettings();
  const navigate = useNavigate();
  const toastContext = useContext(ToastContext);

  // CRUD
  const { data, isLoading, isError } = useGetACLServiceAccountDetails({
    principalId: serviceAccountParam ?? '',
    oktaId: user?.sub ?? '',
    isAdmin: isAdmin ?? false,
  });
  const assignRolesMutation = useAssignRolesMutation();
  const mutateServiceAccount = useMutateServiceAccount();
  const deleteServiceAccountMutation = useDeleteServiceAccount();
  const addPrincipalGroupMembersMutation = useAddPrincipalGroupMembersMutation();
  const removePrincipalGroupMembersMutation = useRemovePrincipalGroupMembersMutation();
  const assignRoleMutation = useAssignRoleMutation();
  const updateAccountTypeToRegularMutation = useUpdateAccountTypeMutation();

  const serviceAccount = data?.GetServiceAccountDetails?.serviceAccountDetails?.serviceAccount;
  const editableRoles = getRoleOptions(
    data?.GetRoles?.roles ?? [],
    data?.GetServiceAccountDetails?.serviceAccountDetails?.editWorkspaceRoleOptionsById ?? [],
    true
  );

  const addWorkspaceRoles = getRoleOptions(
    data?.GetRoles?.roles ?? [],
    data?.GetServiceAccountDetails?.serviceAccountDetails?.addNewWorkspaceRoleOptionsById ?? [],
    false
  );

  const tabCountRecord: Record<string, number> = {
    Workspaces:
      data?.GetServiceAccountDetails?.serviceAccountDetails.assignedWorkspacesWithRoles?.filter(
        (assignedWorkspacesWithRole) => {
          if (assignedWorkspacesWithRole.workspaceId === 'All Workspaces') {
            return !(assignedWorkspacesWithRole?.role?.effectiveWorkspaceRole === 'none');
          }
          return true;
        }
      )?.length ?? 0,
    Groups: data?.GetGroups?.assignedGroups?.length ?? 0,
  };

  const roleDescriptionMap = getRoleToPermissionMap(data?.GetRoles?.roles ?? []);

  // General State
  const [originalRole, setOriginalRole] = useState<string | undefined>();
  const [selectedWorkspaceRole, setSelectedWorkspaceRole] = useState<AclWorkspaceServiceRelationship | undefined>(
    undefined
  );
  const [selectedAccountType, setSelectedAccountType] = useState<string | undefined>(undefined);
  // -- radio button selection from modal
  const [selectedRole, setSelectedRole] = useState<string | undefined>();
  const [workspaceSelected, setWorkspaceSelected] = useState<string | undefined>();
  const [selectedGroup, setSelectedGroup] = useState<Group | undefined>();

  // Modal States
  const [showActivateServiceAccountModal, setShowActivateServiceAccountModal] = useState<boolean>(false);
  const [showDeactivateServiceAccountModal, setShowDeactivateServiceAccountModal] = useState<boolean>(false);
  const [showDeleteServiceAccountModal, setShowDeleteServiceAccountModal] = useState<boolean>(false);
  const [showEditWorkspaceRoleModal, setShowEditWorkspaceRoleModal] = useState<boolean>();
  const [showAssignWorkspaceAccessModal, setShowAssignWorkspaceAccessModal] = useState<boolean>();
  const [showDeleteGroupAccessModal, setShowDeleteGroupAccessModal] = useState<boolean>();
  const [showAssignGroupAccessModal, setShowAssignGroupAccessModal] = useState<boolean>();
  const [showAccountTypeModal, setShowAccountTypeModal] = useState<boolean>(false);

  const assignedWorkspacesWithRoles =
    data?.GetServiceAccountDetails?.serviceAccountDetails?.assignedWorkspacesWithRoles;

  if (isError) {
    const emptyPrompt = (
      <EmptyPrompt
        orientation="vertical"
        variant="storm"
        title={<>Error Loading Service Account</>}
        body={
          <>
            <p>We ran into an error while loading this service account.</p>
          </>
        }
        actions={<ErrorPromptButtons />}
      />
    );
    return emptyPrompt;
  }

  const reset = () => {
    setShowActivateServiceAccountModal(false);
    setShowDeactivateServiceAccountModal(false);
    setShowDeleteServiceAccountModal(false);
    setShowEditWorkspaceRoleModal(false);
    setShowAssignWorkspaceAccessModal(false);
    setShowDeleteGroupAccessModal(false);
    setShowAssignGroupAccessModal(false);
    setShowAccountTypeModal(false);
    setSelectedRole(undefined);
    setWorkspaceSelected(undefined);
    setSelectedGroup(undefined);
    setSelectedWorkspaceRole(undefined);
    setOriginalRole(undefined);
  };

  // --------------------------------------- Start: Actions hooks ---------------------------------

  const onActivateUserClicked = () => {
    setShowActivateServiceAccountModal(true);
  };

  const onDeActivateServiceAccountClicked = () => {
    setShowDeactivateServiceAccountModal(true);
  };

  const onDeleteServiceAccountClicked = () => {
    setShowDeleteServiceAccountModal(true);
  };

  const onActivateServiceAccountConfirm = () => {
    if (serviceAccount !== undefined) {
      const { createdAt: created_at, description, id, name } = serviceAccount;
      const deprovisionServiceAccount = {
        created_at: created_at ?? '',
        description: description ?? '',
        id: id ?? '',
        name: name ?? '',
        is_active: true,
      };
      mutateServiceAccount.mutate(deprovisionServiceAccount, {
        onSuccess: () => {
          reset();
          logEvent('Account & Access: Service Account Activated', '', {
            name: serviceAccount?.name,
            id: serviceAccount?.id,
          });
          const toastContent = getToastComponent({
            variant: 'success',
            title: `Service Account Activated`,
            children: <>Service Account {serviceAccount?.name} as been activated.</>,
            showIcon: true,
          });

          toastContext?.dispatchToast(
            addToast({
              text: toastContent,
            })
          );
        },
        onError: () => {
          const toastContent = getToastComponent({
            variant: 'danger',
            title: `Failed Activate Service Account`,
            children: <>Failed to activate Service Account {serviceAccount?.name}. Please try again.</>,
            showIcon: true,
          });

          toastContext?.dispatchToast(
            addToast({
              text: toastContent,
            })
          );
        },
      });
    }
  };

  const onDeactivateServiceAccountConfirm = () => {
    const deprovisionServiceAccount = { id: serviceAccount?.id ?? '', is_active: false };
    mutateServiceAccount.mutate(deprovisionServiceAccount, {
      onSuccess: () => {
        reset();
        logEvent('Account & Access: Service Account Deactivated', '', {
          name: serviceAccount?.name,
          id: serviceAccount?.id,
        });
        const toastContent = getToastComponent({
          variant: 'success',
          title: `Service Account Deactivated`,
          children: <>Service Account {serviceAccount?.name} as been deactivated.</>,
          showIcon: true,
        });

        toastContext?.dispatchToast(
          addToast({
            text: toastContent,
          })
        );
      },
      onError: () => {
        const toastContent = getToastComponent({
          variant: 'danger',
          title: `Failed Deactivate Service Account`,
          children: <>Failed to deactivate Service Account {serviceAccount?.name}. Please try again.</>,
          showIcon: true,
        });

        toastContext?.dispatchToast(
          addToast({
            text: toastContent,
          })
        );
      },
    });
  };

  const onDeleteServiceAccountConfirm = () => {
    const id = serviceAccount?.id ?? '';
    deleteServiceAccountMutation.mutate(
      { id },
      {
        onSuccess: () => {
          reset();
          logEvent('Account & Access: Service Account Deleted', '', {
            name: serviceAccount?.name,
            id: serviceAccount?.id,
          });
          const toastContent = getToastComponent({
            variant: 'success',
            title: `Service Account Deleted`,
            children: <>Service Account {serviceAccount?.name} as been deleted.</>,
            showIcon: true,
          });

          toastContext?.dispatchToast(
            addToast({
              text: toastContent,
            })
          );

          navigate(`../accounts-and-access/service-accounts`);
        },
        onError: () => {
          const toastContent = getToastComponent({
            variant: 'danger',
            title: `Failed Delete Service Account`,
            children: <>Failed to delete Service Account {serviceAccount?.name}. Please try again.</>,
            showIcon: true,
          });

          toastContext?.dispatchToast(
            addToast({
              text: toastContent,
            })
          );
        },
      }
    );
  };

  const onEditRole = (workspaceRole: AclWorkspaceServiceRelationship) => {
    setShowEditWorkspaceRoleModal(true);
    setSelectedWorkspaceRole(workspaceRole);
    setOriginalRole(workspaceRole?.role?.effectiveWorkspaceRole);
    setSelectedRole(workspaceRole?.role?.effectiveWorkspaceRole);
  };

  const onRoleChange = (roleName: string) => {
    setSelectedRole(roleName);
  };

  const onModifyWorkspaceRole = () => {
    const removingRole = selectedRole === 'None';
    const principal_type = PrincipalType.PRINCIPAL_TYPE_SERVICE_ACCOUNT;

    const role = getLegacyRoleIdGQL(data?.GetRoles?.roles ?? [], selectedRole ?? ''); // fix this.
    const roles = removingRole ? [] : [role ?? ''];
    const resource_id = selectedWorkspaceRole?.workspaceId;
    const isAllWorkspaces = resource_id === 'All Workspaces';

    const resource_type = isAllWorkspaces
      ? ResourceType.RESOURCE_TYPE_ORGANIZATION
      : ResourceType.RESOURCE_TYPE_WORKSPACE;

    if (
      isAllWorkspaces &&
      data?.GetServiceAccountDetails?.serviceAccountDetails?.accountTypeWithInheritance?.effectiveAccountType ===
        'Admin'
    ) {
      roles.unshift('admin_role');
    }

    const payload = {
      principal_id: serviceAccount?.id ?? '',
      roles,
      ...(!isAllWorkspaces && { resource_id }),
      principal_type,
      resource_type,
    };

    assignRolesMutation.mutate(payload, {
      onSuccess: () => {
        reset();
        logEvent(
          `Account & Access: Service Account ${removingRole ? 'Workspace Removed' : 'Workspace Role Update'}`,
          '',
          {
            service_account_name: serviceAccount?.name,
            service_account_id: serviceAccount?.id,
            workspace: selectedWorkspaceRole?.workspaceId,
            role: selectedRole,
          }
        );
        const toastContent = getToastComponent({
          variant: 'success',
          title: removingRole ? `Workspace Removed` : `Service Account Workspace Role Update`,
          children: removingRole ? (
            <>Workspace {selectedWorkspaceRole?.workspaceId} role was removed.</>
          ) : (
            <>
              Workspace {selectedWorkspaceRole?.workspaceId} role updated to {selectedRole} for Service Account{' '}
              {serviceAccount?.name}
            </>
          ),
          showIcon: true,
        });

        toastContext?.dispatchToast(
          addToast({
            text: toastContent,
          })
        );
      },
      onError: () => {
        const toastContent = getToastComponent({
          variant: 'danger',
          title: `Failed Failed to modify role`,
          children: <>Failed to modify role. Please try again.</>,
          showIcon: true,
        });

        toastContext?.dispatchToast(
          addToast({
            text: toastContent,
          })
        );
      },
    });
  };

  const onAddServiceAccountToWorkspace = () => {
    const principal_type = PrincipalType.PRINCIPAL_TYPE_SERVICE_ACCOUNT;
    const role = getLegacyRoleIdGQL(data?.GetRoles?.roles ?? [], selectedRole ?? '');
    const roles = [role ?? ''];
    const resource_id = workspaceSelected;

    const resource_type = ResourceType.RESOURCE_TYPE_WORKSPACE;

    const payload = {
      principal_id: serviceAccount?.id ?? '',
      roles,
      resource_id,
      principal_type,
      resource_type,
    };

    assignRolesMutation.mutate(payload, {
      onSuccess: () => {
        reset();
        logEvent('Account & Access: Service Account Added to Workspace', '', {
          name: serviceAccount?.name,
          service_account_id: serviceAccount?.id,
          workspace: workspaceSelected,
        });
        const toastContent = getToastComponent({
          variant: 'success',
          title: `Service Account Added to Workspace`,
          children: (
            <>
              Service Account {serviceAccount?.name} was added to workspace {workspaceSelected}
            </>
          ),
          showIcon: true,
        });

        toastContext?.dispatchToast(
          addToast({
            text: toastContent,
          })
        );
      },
      onError: () => {
        const toastContent = getToastComponent({
          variant: 'danger',
          title: `Failed to Add Service Account to Workspace`,
          children: (
            <>
              Failed to add Service Account {serviceAccount?.name} to workspace {workspaceSelected}
            </>
          ),
          showIcon: true,
        });

        toastContext?.dispatchToast(
          addToast({
            text: toastContent,
          })
        );
      },
    });
  };

  const onWorkspaceSelected = (workspace: string) => {
    setWorkspaceSelected(workspace);
  };

  const onAssignGroupAccess = () => {
    setShowAssignGroupAccessModal(true);
  };

  const onEditGroup = (group: Group) => {
    setShowDeleteGroupAccessModal(true);
    setSelectedGroup(group);
  };

  const onAssignGroupConfirm = (group?: Group) => {
    setSelectedGroup(group);
  };

  const onConfirmAssignGroup = () => {
    const serviceAccountMembersToAdd = [
      { principal_type: PrincipalType.PRINCIPAL_TYPE_SERVICE_ACCOUNT, principal_id: `${serviceAccount?.id}` },
    ];

    const payload = {
      principal_group_id: `${selectedGroup?.id}`,
      members: serviceAccountMembersToAdd,
    };

    addPrincipalGroupMembersMutation.mutate(payload, {
      onSuccess: () => {
        reset();
        logEvent('Account & Access: Service Account Added to Group', '', {
          service_account_name: serviceAccount?.name,
          service_account_id: serviceAccountParam,
          group_name: selectedGroup?.name,
        });
        const toastContent = getToastComponent({
          variant: 'success',
          title: `Service Account Added to Group`,
          children: (
            <>
              Service Account ${serviceAccountParam} was added top group {selectedGroup?.name}.
            </>
          ),
          showIcon: true,
        });

        toastContext?.dispatchToast(
          addToast({
            text: toastContent,
          })
        );
      },
      onError: () => {
        const toastContent = getToastComponent({
          variant: 'danger',
          title: `Failed to remove Group`,
          children: <>Failed to remove user from group {selectedGroup?.name}. Please try again.</>,
          showIcon: true,
        });

        toastContext?.dispatchToast(
          addToast({
            text: toastContent,
          })
        );
      },
    });
  };

  const onAssignWorkspaceAccess = () => {
    setShowAssignWorkspaceAccessModal(true);
  };

  const onDeleteGroupConfirmClicked = () => {
    const payload = {
      id: `${selectedGroup?.id}`,
      members: [
        { principal_type: PrincipalType.PRINCIPAL_TYPE_SERVICE_ACCOUNT, principal_id: `${serviceAccount?.id}` },
      ],
    };
    removePrincipalGroupMembersMutation.mutate(payload, {
      onSuccess: () => {
        reset();
        logEvent('Account & Access: Service Account Removed From Group', '', {
          service_account_name: serviceAccount?.name,
          service_account_id: serviceAccountParam,
          group_name: selectedGroup?.name,
        });
        const toastContent = getToastComponent({
          variant: 'success',
          title: `Service Account Removed From Group`,
          children: (
            <>
              Service Account {serviceAccount?.name} removed from group {selectedGroup?.name}.
            </>
          ),
          showIcon: true,
        });

        toastContext?.dispatchToast(
          addToast({
            text: toastContent,
          })
        );
      },

      onError: () => {
        const toastContent = getToastComponent({
          variant: 'danger',
          title: `Failed to Remove Service Account From Group`,
          children: <>Failed to remove Service Account from group {selectedGroup?.name}. Please try again.</>,
          showIcon: true,
        });

        toastContext?.dispatchToast(
          addToast({
            text: toastContent,
          })
        );
      },
    });
  };

  const onFieldSaveConfirm = (
    fieldName: string,
    newValue: string,
    updateCallBack: (status: SimpleRequestStatus) => void
  ) => {
    if (data?.GetServiceAccountDetails.serviceAccountDetails) {
      if (serviceAccount !== undefined) {
        const { id, isActive, name, description } = serviceAccount;

        const deprovisionServiceAccount: any = {
          id: id ?? '',
          isActive: isActive ?? false,
          description: description ?? '',
          name: name ?? '',
        };

        deprovisionServiceAccount[fieldName] = newValue;

        mutateServiceAccount.mutate(deprovisionServiceAccount, {
          onSuccess: () => {
            updateCallBack('success');
            reset();
            logEvent('Account & Access: Service Account Name Updated', '', {
              service_account_id: id,
              old_name: fieldName,
              new_name: newValue,
            });
            const toastContent = getToastComponent({
              variant: 'success',
              title: `Service Account Updated`,
              children: (
                <>
                  Service Account {fieldName} updated to {newValue}
                </>
              ),
              showIcon: true,
            });

            toastContext?.dispatchToast(
              addToast({
                text: toastContent,
              })
            );
          },
          onError: () => {
            updateCallBack('failed');
            const toastContent = getToastComponent({
              variant: 'danger',
              title: `Failed to Updated Service account`,
              children: <>Failed to update {fieldName}. Please try again.</>,
              showIcon: true,
            });

            toastContext?.dispatchToast(
              addToast({
                text: toastContent,
              })
            );
          },
        });
      }
    }
  };

  const onEditAccountType = () => {
    setSelectedAccountType(
      data?.GetServiceAccountDetails?.serviceAccountDetails?.accountTypeWithInheritance?.effectiveAccountType
    );
    setShowAccountTypeModal(true);
  };

  const onSelectedAccountType = (selectedAccountType: string) => {
    setSelectedAccountType(selectedAccountType);
  };

  const onConfirmAccountTypeChange = () => {
    const role = 'admin_role';

    const payload: AssignRolesRequest = {
      assignments: [
        {
          principal_id: serviceAccount?.id ?? '',
          role,
          principal_type: PrincipalType.PRINCIPAL_TYPE_SERVICE_ACCOUNT,
          resource_type: ResourceType.RESOURCE_TYPE_ORGANIZATION,
        },
      ],
    };

    if (selectedAccountType === 'Admin') {
      assignRoleMutation.mutate(payload, {
        onSuccess: () => {
          reset();
          logEvent('Account & Access: Service Account Account Type Updated', '', {
            service_account_name: serviceAccount?.name,
            service_account_id: serviceAccount?.id,
            account_type: 'Admin',
          });
          const toastContent = getToastComponent({
            variant: 'success',
            title: `Service Account Account Type Updated`,
            children: <>Account type updated to Admin</>,
            showIcon: true,
          });

          toastContext?.dispatchToast(
            addToast({
              text: toastContent,
            })
          );
        },
        onError: () => {
          const toastContent = getToastComponent({
            variant: 'danger',
            title: `Account Type Updated Failed`,
            children: <>Failed to update account type to admin.</>,
            showIcon: true,
          });

          toastContext?.dispatchToast(
            addToast({
              text: toastContent,
            })
          );
        },
      });
    } else {
      updateAccountTypeToRegularMutation.mutate(payload, {
        onSuccess: () => {
          reset();
          logEvent('Account & Access: Service Account Account Type Updated', '', {
            service_account_name: serviceAccount?.name,
            service_account_id: serviceAccount?.id,
            account_type: 'Regular',
          });
          const toastContent = getToastComponent({
            variant: 'success',
            title: `Service Account Account Type Updated`,
            children: <>Account type updated to Regular</>,
            showIcon: true,
          });

          toastContext?.dispatchToast(
            addToast({
              text: toastContent,
            })
          );
        },
        onError: () => {
          const toastContent = getToastComponent({
            variant: 'danger',
            title: `Account Type Updated Failed`,
            children: <>Failed to update account type to regular.</>,
            showIcon: true,
          });

          toastContext?.dispatchToast(
            addToast({
              text: toastContent,
            })
          );
        },
      });
    }
  };

  // --------------------------------------- End: Actions hooks ---------------------------------

  const aclServiceAccountsRoutes = getACLAccountRoutes();
  const hasRoleFromOtherSources = (selectedWorkspaceRole?.role?.roleSources.length ?? 0) > 0;
  const roleAssignmentSources =
    selectedWorkspaceRole?.role?.roleSources?.map((roleSource) => {
      return {
        principalGroupId: roleSource.groupId,
        principalGroupName: roleSource?.groupName,
      };
    }) ?? [];

  const tabBodySelected = (
    <>
      <Routes>
        <Route
          element={
            <ACLMembershipOutlet
              path={`${AppRoutes.accountsAndAccessServiceAccounts}/:serviceAccount`}
              tabCountRecord={tabCountRecord}
            />
          }
        >
          <Route index element={<Navigate to={aclServiceAccountsRoutes[`Workspaces`]} replace />} />
          <Route
            path={aclServiceAccountsRoutes[`Workspaces`]}
            element={
              <>
                <ACLPrincipalWorkspaceRoleList
                  assignedWorkspaceRoles={assignedWorkspacesWithRoles ?? []}
                  isAdminView={isAdmin ?? false}
                  onEditRole={onEditRole}
                  onAssignWorkspaceAccess={onAssignWorkspaceAccess}
                />
              </>
            }
          />
          <Route
            path={aclServiceAccountsRoutes[`Groups`]}
            element={
              <>
                <ACLPrincipalGroupMembershipList
                  assignedGroups={data?.GetGroups.assignedGroups ?? []}
                  principalName={serviceAccount?.name ?? ''}
                  isAdminView={isAdmin ?? false}
                  onEditGroup={onEditGroup}
                  isLoading={isLoading ?? false}
                  onAssignGroupAccess={onAssignGroupAccess}
                />
              </>
            }
          />
        </Route>
      </Routes>
    </>
  );

  const leftSideServiceAccountDetailsCard = (
    <>
      {showActivateServiceAccountModal && (
        <Modal
          title={`Activate ${serviceAccount?.name}`}
          body={<ActivateAccount serviceAccountName={serviceAccount?.name ?? ''} />}
          onCancel={() => {
            setShowActivateServiceAccountModal(false);
          }}
          confirmLabel="Activate"
          onConfirm={onActivateServiceAccountConfirm}
          variant="negative"
          isConfirmButtonLoading={mutateServiceAccount.isLoading}
        />
      )}

      {showDeactivateServiceAccountModal && (
        <Modal
          title={`Deactivate ${serviceAccount?.name}`}
          body={<Delete message="Are you sure you want to Deactivate this account?" />}
          onCancel={() => {
            setShowDeactivateServiceAccountModal(false);
          }}
          confirmLabel="Deactivate"
          onConfirm={onDeactivateServiceAccountConfirm}
          variant="negative"
          isConfirmButtonLoading={mutateServiceAccount.isLoading}
        />
      )}

      {showDeleteServiceAccountModal && (
        <Modal
          title={`Delete ${serviceAccount?.name}`}
          body={<Delete />}
          onCancel={() => {
            setShowDeleteServiceAccountModal(false);
          }}
          confirmLabel="Delete"
          onConfirm={onDeleteServiceAccountConfirm}
          variant="negative"
          isConfirmButtonLoading={deleteServiceAccountMutation.isLoading}
        />
      )}

      {showAssignWorkspaceAccessModal && (
        <Modal
          title={`Add Service Account to this Workspace`}
          body={
            <>
              <AddWorkspaceAccess
                roles={addWorkspaceRoles}
                assignableWorkspaces={data?.GetServiceAccountDetails?.serviceAccountDetails.assignableWorkspaces ?? []}
                workspaceRole={selectedWorkspaceRole}
                roleDescriptionMap={roleDescriptionMap}
                onRoleChange={onRoleChange}
                onWorkspaceSelected={onWorkspaceSelected}
              />
            </>
          }
          onCancel={() => {
            setShowAssignWorkspaceAccessModal(false);

            setSelectedWorkspaceRole(undefined);
            setSelectedRole(undefined);
            setWorkspaceSelected(undefined);
          }}
          confirmLabel="Add Service Account"
          onConfirm={() => {
            onAddServiceAccountToWorkspace();
          }}
          variant="positive"
          confirmVariant={selectedRole !== undefined && workspaceSelected ? 'primaryAction' : 'disabledAction'}
          isConfirmButtonLoading={assignRolesMutation.isLoading}
          maxWidth={1000}
        />
      )}

      {showEditWorkspaceRoleModal && (
        <Modal
          title={`Modify role of ${serviceAccount?.name} in ${selectedWorkspaceRole?.workspaceId} `}
          body={
            <>
              <ModifyWorkspaceAccess
                roles={editableRoles}
                workspaceRole={selectedWorkspaceRole?.role?.effectiveWorkspaceRole}
                roleDescriptionMap={roleDescriptionMap}
                onRoleChange={onRoleChange}
                hasRoleFromOtherSources={hasRoleFromOtherSources}
                roleAssignmentSources={roleAssignmentSources}
              />
            </>
          }
          onCancel={() => {
            setShowEditWorkspaceRoleModal(false);
            setOriginalRole(undefined);
            setSelectedWorkspaceRole(undefined);
            setWorkspaceSelected(undefined);
          }}
          confirmLabel="Change Access"
          onConfirm={() => {
            onModifyWorkspaceRole();
          }}
          variant="positive"
          confirmVariant={originalRole !== selectedRole ? 'primaryAction' : 'disabledAction'}
          isConfirmButtonLoading={assignRolesMutation.isLoading}
          maxWidth={1000}
        />
      )}

      {showAssignGroupAccessModal && (
        <Modal
          title={`Add Service Account to this Group`}
          body={
            <>
              <AssignGroup
                assignableGroups={data?.GetGroups.assignableGroups ?? []}
                onAssignGroupConfirm={onAssignGroupConfirm}
              />
            </>
          }
          onCancel={() => {
            setSelectedGroup(undefined);
            setShowAssignGroupAccessModal(false);
          }}
          confirmLabel="Add Group"
          onConfirm={() => {
            onConfirmAssignGroup();
          }}
          variant="positive"
          confirmVariant={selectedGroup !== undefined ? 'primaryAction' : 'disabledAction'}
          isConfirmButtonLoading={addPrincipalGroupMembersMutation.isLoading}
        />
      )}

      {showDeleteGroupAccessModal && (
        <Modal
          title={`Delete ${serviceAccount?.name} from ${selectedGroup?.name}`}
          body={
            <Delete message={`Are you sure you want to delete ${serviceAccount?.name} from  ${selectedGroup?.name}?`} />
          }
          onCancel={() => {
            setSelectedGroup(undefined);
            setShowDeleteGroupAccessModal(false);
          }}
          confirmLabel="Delete"
          onConfirm={onDeleteGroupConfirmClicked}
          variant="negative"
          isConfirmButtonLoading={removePrincipalGroupMembersMutation.isLoading}
        />
      )}

      {showAccountTypeModal && (
        <Modal
          title={`Modify Account Type of ${serviceAccount?.name}`}
          body={
            <ModifyServiceAccountAccountType
              serviceAccount={serviceAccount}
              selectedAccountType={selectedAccountType}
              roleDescriptionMap={getRoleDescriptionMap()}
              onSelectionClicked={onSelectedAccountType}
            />
          }
          onCancel={() => {
            setShowAccountTypeModal(false);
            setSelectedAccountType(undefined);
          }}
          confirmLabel="Change assigned Account Type to Admin"
          onConfirm={onConfirmAccountTypeChange}
          variant="positive"
          confirmVariant={
            data?.GetServiceAccountDetails?.serviceAccountDetails?.accountTypeWithInheritance?.effectiveAccountType ===
            selectedAccountType
              ? 'disabledAction'
              : 'primaryAction'
          }
          isConfirmButtonLoading={assignRoleMutation.isLoading || updateAccountTypeToRegularMutation?.isLoading}
        />
      )}

      <ACLServiceAccountDetailsCard
        serviceAccountDetail={data?.GetServiceAccountDetails?.serviceAccountDetails}
        isAdminView={isAdmin ?? false}
        onActivateServiceAccountClicked={onActivateUserClicked}
        onDeActivateServiceAccountClicked={onDeActivateServiceAccountClicked}
        onDeleteServiceAccountClicked={onDeleteServiceAccountClicked}
        onFieldSaveConfirm={onFieldSaveConfirm}
        onEditAccountType={onEditAccountType}
      />
    </>
  );

  const body = isLoading ? (
    <EmptyPrompt variant="loading" title={<>Loading Service Account</>} body={<></>} orientation="vertical" />
  ) : (
    <AsymmetricBodyLayout leftSide={leftSideServiceAccountDetailsCard} rightSide={tabBodySelected} />
  );

  return <>{body}</>;
};

export default ACLServiceAccountContainer;
