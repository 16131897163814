export type TransformationRoutes = 'Metadata' | 'Feature Views';

export const getTransformationRoutes = (): Record<TransformationRoutes, string> => {
  return {
    Metadata: 'overview',
    'Feature Views': 'feature-views',
  };
};

export const getTransformationRouteName = (pathname: string): TransformationRoutes => {
  const pathSegments = pathname.split('/');
  const lastPathSegment = pathSegments[pathSegments.length - 1];
  const routes = getTransformationRoutes();
  const record = Object?.entries(routes)?.find((record) => {
    return record[1] === lastPathSegment;
  });

  return (record?.[0] ?? 'Metadata') as TransformationRoutes;
};
