import { Node, Edge } from '@xyflow/react';
import {
  DataFlowEdge,
  DataFlowNode,
  DataFlowPathProperties,
  DataFlowTemplateType,
  EdgeTuple,
  NodeSizeFunctionSignature,
  NodeSizeLookupRecord,
  NodesMapType,
  RequestNode,
} from './dataFlowTypes';

import { createGetNodeSizeFunction, createNodesMap, STORE_PANEL_HEIGHT, STORE_PANEL_WIDTH } from './helpers';
import { getTemplateDisplayType } from './nodeToRendererMap';
import { HARDCODED_STORE_INTERNAL_POSITIONS, layoutNodePositions } from './layoutNodePositions';
import { useQuery } from '@tanstack/react-query';

const WRAPPER_INNER_PADDING = 36;
const REQ_DATA_SOURCE_LEFT_OFFSET = 25;
const REQ_DATA_SOURCE_BOTTOM_OFFSET = 58;

const computeWrapperDimensions = (
  layoutCoordinates: Record<string, { x: number; y: number }>,
  nodesMap: Record<string, DataFlowNode>,
  templateType: DataFlowTemplateType,
  getNodeSize: (node: DataFlowNode) => number[]
) => {
  /**
   * This creates a wrapper around all transformation and aggregation nodes.
   *
   * ... and it assumes that all the transformations and aggregations belong
   * together because currently that's our design -- only one node can be the
   * "anchor" where we expose the internal transformation and aggregations.
   *
   * If we ever want to show transformations/aggregations of multiple FV and ODFV
   * at the same time, this function needs to be modified so that is only loops
   * through the transformations/aggregations for a single FV/ODFV
   *
   * ... we'd also have to change how we represent the storage node, and that's
   * pretty hairy.
   */
  let minTransformX = Infinity;
  let minY = Infinity;
  let maxY = -Infinity;
  let maxX = -Infinity;

  Object.values(nodesMap).forEach((dataflowNode) => {
    // The anchor node is the node that we use
    // to determine the right edge of the wrapper
    if (dataflowNode.isAnchor === true) {
      maxX = layoutCoordinates[dataflowNode.id].x;

      return;
    }

    if (templateType === 'materializing_fv' && dataflowNode.type === 'store_wrapper') {
      const storeWrapperCoordinates = layoutCoordinates[dataflowNode.id];

      const panelTop = storeWrapperCoordinates.y;
      const panelBottom = storeWrapperCoordinates.y + STORE_PANEL_HEIGHT;
      const panelRight = storeWrapperCoordinates.x + STORE_PANEL_WIDTH;

      maxY = panelBottom > maxY ? panelBottom : maxY;
      minY = panelTop < minY ? panelTop : minY;
      maxX = panelRight > maxX ? panelRight : maxX;
    }

    const shouldIncludeRequestDataSource =
      dataflowNode.type !== 'request_data_source' ||
      !(templateType === 'materializing_fv' || templateType === 'ondemand_fv');

    // If the node is not a transformation or aggregation node,
    // we don't care about it.
    // If the node is a request data source, we only care about it
    // if we are in a feature view template
    if (
      dataflowNode.type !== 'transformation' &&
      dataflowNode.type !== 'aggregation' &&
      shouldIncludeRequestDataSource
    ) {
      return;
    }

    const position = layoutCoordinates[dataflowNode.id];
    const nodeSize = getNodeSize(dataflowNode);

    if (!position) {
      console.warn(`Could not find position for node with id: '${dataflowNode.id}'`);
    }

    const x = position.x;
    const yTop = position.y;
    const yBottom = position.y + nodeSize[0];

    maxY = yBottom > maxY ? yBottom : maxY;
    minY = yTop < minY ? yTop : minY;
    minTransformX = x - nodeSize[0] < minTransformX ? x - nodeSize[0] : minTransformX;
  });

  if (maxX === undefined) {
    throw new Error('Could not find an anchor node. Cannot compute a wrapper dimension without an anchor node.');
  }

  return {
    minTransformX,
    maxX,
    minY,
    maxY,
    width: maxX - minTransformX,
    height: maxY - minY,
  };
};

const createWrapperNode = (
  fvNode: DataFlowNode,
  layoutCoordinates: Record<string, { x: number; y: number }>,
  nodesMap: Record<string, DataFlowNode>,
  templateType: DataFlowTemplateType,
  getNodeSize: (node: DataFlowNode) => number[]
): Node => {
  // ## Special Case for Feature Views
  // In the Feature View template design, the Feature View
  // wraps around other nodes that are internal to it, e.g.
  // Transformations, Stores, and Aggregations

  // We compute the wrapper's dimension based on the layout.
  // Pulled into a helper function computeWrapperDimensions
  // const wrapperDimensions = computeWrapperDimensions(dag, nodesMap, templateType);

  const wrapperDimensions = computeWrapperDimensions(layoutCoordinates, nodesMap, templateType, getNodeSize);

  const position = {
    x: wrapperDimensions.minTransformX,
    y: wrapperDimensions.minY - 1 * WRAPPER_INNER_PADDING,
  };

  return {
    id: 'FV_WRAPPER',
    position,
    type: 'FeatureViewAnchor',
    data: {
      ...fvNode,
      width: wrapperDimensions.width,
      height: wrapperDimensions.height + 2 * WRAPPER_INNER_PADDING,
    },
  };
};

const createRequestDataSource = (
  requestDataSource: RequestNode,
  nodesMap: NodesMapType,
  getNodeSize: NodeSizeFunctionSignature
) => {
  const fsId = requestDataSource.id.replace('REQ_', '');
  const parentNode = nodesMap[fsId];

  if (!parentNode || parentNode.type !== 'feature_service') {
    throw new Error(
      `Expected node with id: '${fsId}' to exist in node map and for it to be a FeatureServiceNode. Check if nodesMap is constructed properly`
    );
  }

  const parentNodeSize = getNodeSize(parentNode);
  const parentNodeHeight = parentNodeSize[0];

  const position = {
    x: REQ_DATA_SOURCE_LEFT_OFFSET,
    y: parentNodeHeight - REQ_DATA_SOURCE_BOTTOM_OFFSET,
  };

  const nodeDisplayType = getTemplateDisplayType(requestDataSource, 'feature_service');

  const rdsNode: Node = {
    id: requestDataSource.id,
    type: nodeDisplayType,
    data: requestDataSource,
    position,
    parentId: fsId,
  };

  // Two extra edges for cosmetic reasons
  // i.e. when you hover over a RDS
  // it looks like it is disconnected
  // if we don't add these two cosmetic
  // paths between the RDS and its
  // parent Feature Service
  const rdsInputEdge: Edge = {
    id: `edge:${requestDataSource.id}==>${fsId}`,
    source: requestDataSource.id,
    target: fsId,
    data: {
      isCosmeticRequestPath: true,
      isOnlineServingPath: parentNode.isOnlineServingEnabled,
      isOfflineReadPath: true,
    },
  };

  const rdsOutputEdge: Edge = {
    id: `edge:${fsId}==>${requestDataSource.id}`,
    source: fsId,
    target: requestDataSource.id,
    data: {
      isCosmeticRequestPath: true,
      isOnlineServingPath: parentNode.isOnlineServingEnabled, // TODO: This isn't necessarily correct
      isOfflineReadPath: true,
    },
  };

  return {
    rdsNode,
    rdsInputEdge,
    rdsOutputEdge,
  };
};

export const processDataForReactFlow = (
  nodesList: DataFlowNode[],
  edgesArray: DataFlowEdge[],
  templateType: DataFlowTemplateType,
  initializedNodeSizes: NodeSizeLookupRecord | undefined,
  configs?: {
    denseMode?: boolean;
  }
) => {
  /**
   * # Pre-processor for React Flow: Outline
   *
   * Step 0: Set Up
   * Step 1: Use the Sugiyama Algorithm in d3-dag to generate a layout
   * Step 2: Shape data for React Flow
   */

  // Set Up: Create a map of nodes by ID
  // for easier access later.
  const nodesMap: NodesMapType = createNodesMap(nodesList);

  // Set Up: Set up the node size function
  const getNodeSize: NodeSizeFunctionSignature = createGetNodeSizeFunction(initializedNodeSizes, nodesMap, {
    denseMode: configs?.denseMode,
  });

  // Step 1: Compute the Layout using D3-Dag and Sugiyama
  const layoutCoordinates = layoutNodePositions(nodesMap, edgesArray, getNodeSize, {
    denseMode: configs?.denseMode,
    templateType,
  });

  // Step 2: Shape data for React-Flow

  // Initialize Array of React Flow Nodes
  const nodes: Node[] = [];

  // Step 2 Set Up: Create a position map so we can pan
  // to a node dynamically
  const positionMap: Record<string, { x: number; y: number }> = {};

  // Step 2 Set Up: Helper function for adding nodes
  // Make sure we collect the node position
  // at the same time.

  const addNode = (n: Node) => {
    nodes.push(n);

    if (!n.parentId) {
      if (n.type === 'FeatureViewAnchorOutput') {
        /**
         * We manually set the position for the Feature View Anchor
         * Thus we skip this in order to not overwrite it.
         */
        return;
      }

      positionMap[n.id] = { ...n.position };
    } else {
      const parentPosition = positionMap[n.parentId];

      if (!parentPosition) {
        throw new Error(`Cannot find position of '${n.parentId}' in positionMap`);
      }

      const childPosition = {
        x: parentPosition.x + n.position.x,
        y: parentPosition.y + n.position.y,
      };

      positionMap[n.id] = childPosition;
    }
  };

  /**
   * Step 2 really starts here.
   *
   * 2.1: Create Wrapper Node if the Template calls for it.
   * 2.2: Create Edges for React Flow
   * 2.3: Create Nodes for React Flow
   */

  if (templateType === 'materializing_fv' || templateType === 'ondemand_fv') {
    // 2.1 Create Wrapper Node
    // In the Feature View template design, the Feature View
    // wraps around other nodes that are internal to it, e.g.
    // Transformations, Stores, and Aggregations

    // Looking for the anchor node
    const fvNode = nodesList.find((n) => {
      return n.isAnchor === true;
    });

    if (!fvNode) {
      throw new Error('In a feature view template, we expect an anchor node with the property isAnchor = true.');
    }

    const wrapperNode = createWrapperNode(fvNode, layoutCoordinates, nodesMap, templateType, getNodeSize);

    // Shove it into the list of nodes.
    // React-flow requires "parent" nodes to
    // appear before its "children"
    addNode(wrapperNode);

    positionMap[fvNode.id] = wrapperNode.position;
  }

  // 2.2. Create a React Flow Edge
  // for each edge that needs to be rendered
  const edges: Edge<DataFlowPathProperties>[] = edgesArray.map((e) => {
    const targetId = e.target;
    const sourceId = e.source;

    return {
      id: `edge:${sourceId}==>${targetId}`,
      source: sourceId,
      target: targetId,
      data: e.pathProperties,
    };
  });

  /**
   * 2.3 Create a React Flow Node for
   * each node.
   */
  Object.values(nodesMap).forEach((nodeData) => {
    const nodeDisplayType = getTemplateDisplayType(nodeData, templateType);

    const position: { x: number; y: number } =
      HARDCODED_STORE_INTERNAL_POSITIONS[nodeData.type] || layoutCoordinates[nodeData.id];

    if (position) {
      // If the node is a store internal node,
      // it is positioned relative to its parent
      // and thus needs the parentNode parameter
      // in React Flow
      const parentNode = HARDCODED_STORE_INTERNAL_POSITIONS[nodeData.type] ? 'STORE' : undefined;

      const ReactFlowNode: Node = {
        id: nodeData.id,
        type: nodeDisplayType,
        data: nodeData,
        position,
        parentId: parentNode,
      };

      addNode(ReactFlowNode);
    } else {
      /**
       * If there isn't a position for this node in layoutCoordinates, then there
       * is a couple possibilities.
       */

      if (nodeData.type === 'request_data_source') {
        /**
         * Special Case: Request Data Sources
         *
         * Request data sources are embedded inside of the Feature
         * Services they are a part of. We need to look for
         * its parent Feature Service node in order to know
         * what its height is, and position it accordingly.
         */

        if (templateType === 'feature_service' && !configs?.denseMode) {
          // We don't render request data sources in dense mode
          const { rdsNode, rdsInputEdge, rdsOutputEdge } = createRequestDataSource(nodeData, nodesMap, getNodeSize);

          addNode(rdsNode);
          edges.push(rdsInputEdge);
          edges.push(rdsOutputEdge);
        }
      } else {
        /**
         * If the node is not a request data source, and yet
         * there's not position for it in layoutCoordinates,
         * then the most likely scenario is that it is a
         * node that is not connected to any other node, e.g.
         *
         * - A ODFV that doesn't depend on a FV, and also is not used in a FS
         *
         * TODO: Handle this case
         */
        console.warn(`No position for ${JSON.stringify(nodeData)}`);
      }
    }
  });

  // ... and we're done! Return the nodes and edges
  // array so React-Flow can render them.
  return {
    nodes,
    edges,
    positionMap,
    nodesMap,
  };
};

export type ProcessDataForReactFlowPromiseReturnType = ReturnType<typeof processDataForReactFlow>;

const processDataForReactFlowPromise = (
  nodesList: DataFlowNode[],
  edgesArray: DataFlowEdge[],
  templateType: DataFlowTemplateType,
  initializedNodeSizes: NodeSizeLookupRecord | undefined,
  configs?: {
    denseMode?: boolean;
  }
) => {
  return new Promise<ProcessDataForReactFlowPromiseReturnType>((resolve) => {
    resolve(processDataForReactFlow(nodesList, edgesArray, templateType, initializedNodeSizes, configs));
  });
};

export const useProcessDataForReactFlowAsQuery = (
  workspace: string,
  nodesList: DataFlowNode[],
  edgesArray: DataFlowEdge[],
  templateType: DataFlowTemplateType,
  initializedNodeSizes: NodeSizeLookupRecord | undefined,
  configs?: {
    denseMode?: boolean;
  }
) => {
  const isDenseMode = configs?.denseMode;
  const hasInitialized = initializedNodeSizes !== undefined;

  return useQuery({
    queryKey: ['dataflow-diagram', workspace, isDenseMode, hasInitialized],
    queryFn: () => {
      return processDataForReactFlowPromise(nodesList, edgesArray, templateType, initializedNodeSizes, configs);
    },
    enabled: !!workspace,
  });
};

export type { EdgeTuple, NodeSizeLookupRecord };
