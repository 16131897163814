import { FC } from 'react';
import { useParams } from 'react-router';

// api
import { useGetJobDetails } from '../../../api/jobs';

//utils
import JobStages from './JobStages';
import { getStages } from './JobsUtils';

import { EmptyPrompt, FlexGroupWrapper, FlexItem } from '@tecton/ComponentRedesign';
import { FlyoutContentProps } from '@tecton/ComponentRedesign/lib/ViewLayoutWithFlyoutPush';

interface JobDetailStagesProps {
  updateFlyoutContent: (content?: FlyoutContentProps) => void;
}

const JobDetailStages: FC<JobDetailStagesProps> = ({ updateFlyoutContent }) => {
  const { jobId } = useParams();

  const { data: jobDetailData, isSuccess: materializationStatusIsSuccess } = useGetJobDetails(jobId ?? '');

  if (!materializationStatusIsSuccess) {
    return (
      <>
        <EmptyPrompt variant="loading" title={'Loading Job'} body={<></>} />
      </>
    );
  }
  const stages = jobDetailData?.attempt_details?.run_details?.stages ?? [];
  const attemptStatus = jobDetailData?.attempt_details?.attempt_status;
  const materializationAttempts = getStages(stages);

  return (
    <>
      <FlexGroupWrapper gap="l" direction="column">
        <FlexItem>
          <JobStages
            materializationAttempts={materializationAttempts ?? [{}]}
            materializationStatus={attemptStatus}
            updateFlyoutContent={updateFlyoutContent}
          />
        </FlexItem>
      </FlexGroupWrapper>
    </>
  );
};

export default JobDetailStages;
