import styled from '@emotion/styled';
import { Icons, IconTypes, Popover } from '@tecton/ComponentRedesign';
import { FC, ReactNode, useState } from 'react';
import { useUserSettings } from '../../context/UserSettingsContext';

// Putting this for now since the old Icons doesn't support size.
// TODO: Once Icon v1 is done, move this over.
import { ReactComponent as DevelopmentWorkspace } from '@svg/development-workspace.svg';
import { ReactComponent as LiveWorkspace } from '@svg/live-workspace.svg';
import TectonWorkspaceSelector from '../../@tecton/SideNavigation/TectonWorkspaceSelector';
import { getSideNavigationWorkspaces } from '../util';

interface SelectorButtonProps {
  label: ReactNode;
  workspaceType?: string;
  onClick: () => void;
  disabled?: boolean;
}

interface RealtimeMonitoringWorkspaceSelectorProps {
  onWorkspaceSelected: (workspace?: string) => void;
  selectedWorkspace?: string;
}

const ButtonWrapper = styled.div<{ disabled?: boolean }>`
  display: grid;
  grid-template-columns: ${({ theme }) => theme.spacing.lg} 1fr ${({ theme }) => theme.spacing['2xl']};
  align-items: center;
  justify-content: center;
  align-self: stretch;
  flex-shrink: 0;
  gap: ${({ theme }) => theme.gap.m};
  padding: ${({ theme }) => theme.padding.xs} ${({ theme }) => theme.padding.s};
  border-radius: ${({ theme }) => theme.border.radius.medium};
  border: ${({ theme }) => theme.border.thin};
  background: ${({ theme }) => theme.colors.emptyShade};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  color: ${({ theme, disabled }) => (disabled ? theme.colors.disabledText : theme.colors.text)};
`;

const LabelWrapper = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: keep-all;
  white-space: nowrap;
  font-size: ${({ theme }) => theme.font.fontSizes.xs};
`;

const WorkspaceIconWrapper = styled.div`
  height: 16px;
`;

export const WorkspaceIcon: FC<{ workspaceType?: string }> = ({ workspaceType }) => {
  return (
    <WorkspaceIconWrapper>
      {workspaceType === 'Live' ? <LiveWorkspace height={16} /> : <DevelopmentWorkspace height={16} />}
    </WorkspaceIconWrapper>
  );
};

export const SelectorButton: FC<SelectorButtonProps> = ({ label, workspaceType, onClick, disabled }) => {
  return (
    <ButtonWrapper disabled={disabled} onClick={onClick} data-testid="combo-selector-button">
      <WorkspaceIcon workspaceType={workspaceType} />
      <LabelWrapper>{label}</LabelWrapper>
      {Icons[IconTypes.CHEVRON_DOWN]}
    </ButtonWrapper>
  );
};

const RealtimeMonitoringWorkspaceSelector: FC<RealtimeMonitoringWorkspaceSelectorProps> = ({
  onWorkspaceSelected,
  selectedWorkspace,
}) => {
  const { allWorkspaces } = useUserSettings();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const foundSelectedWorkspace = allWorkspaces?.find((ws) => {
    return ws.name === selectedWorkspace;
  });

  const workspaces = getSideNavigationWorkspaces(
    allWorkspaces?.filter((ws) => {
      return ws.workspaceType === 'Live';
    }) ?? []
  );

  return (
    <Popover
      data-testid="feature-service-group-selector"
      button={
        <SelectorButton
          label={`${selectedWorkspace}`}
          workspaceType={foundSelectedWorkspace?.workspaceType}
          onClick={() => {
            setIsOpen(true);
          }}
        />
      }
      maxWidth="400px"
      isOpen={isOpen}
      body={
        <TectonWorkspaceSelector
          workspaces={workspaces}
          onWorkspaceSelected={(ws) => {
            setIsOpen(false);
            onWorkspaceSelected(ws.name);
          }}
          hideDevelopmentSection={true}
        />
      }
      closePopover={() => {
        setIsOpen(false);
      }}
      canScrollBody={false}
    />
  );
};

export default RealtimeMonitoringWorkspaceSelector;
