import React, { FC } from 'react';
import { useParams } from 'react-router';
import { MonitoringDateRange } from '../../../../components/monitoring/DateRange-Dropdown-util';
import PrometheusCount from '../../../../components/monitoring/PrometheusCount';

interface NumberOfFeatureViewsAndTablesTotalProps {
  monitoringDateRange: MonitoringDateRange;
}

const NumberOfFeatureViewsAndTablesTotal: FC<NumberOfFeatureViewsAndTablesTotalProps> = ({ monitoringDateRange }) => {
  const chartTitle = 'Total';
  const { workspace } = useParams();

  const queries = [
    {
      label: 'Total',
      query: `count  by (tecton_cluster) (feature_package_materialization_enabled{workspace=~'${workspace}',tecton_cluster=~'.*', aws_region=~'.*'}) OR on() vector(0)`,
    },
  ];

  return (
    <div>
      <PrometheusCount
        chartTitle={chartTitle}
        monitoringDateRange={monitoringDateRange}
        queries={queries}
        parseCallback={parseInt}
      />
    </div>
  );
};

export default NumberOfFeatureViewsAndTablesTotal;
