import styled from '@emotion/styled';
import React, { FC } from 'react';

interface DataFlowWrapperProps {
  height: string;
  children: React.ReactNode;
}

// Can be refactored as a standalone component

const Wrapper = styled.div<{ height: string }>`
  height: ${({ height }) => height};
  border: 1px solid #d3dae6;
  border-radius: 3px;
  position: relative;
  overflow: hidden;

  .react-flow__handle {
    opacity: 0;
  }
`;

const DataFlowWrapper: FC<DataFlowWrapperProps> = ({ height, children }) => {
  return <Wrapper height={height}>{children}</Wrapper>;
};

export default DataFlowWrapper;
