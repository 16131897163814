import React, { FC, useState } from 'react';
import { Outlet, useMatch, useNavigate } from 'react-router';

import { ACLMembershipRoutes, getACLAccountRoutes } from './acl-util';

// @tecton
import { Tabs } from '@tecton/ComponentRedesign';

interface ACLMembershipOutletProps {
  path: string;
  tabCountRecord: Record<string, number>;
}

const ACLMembershipOutlet: FC<ACLMembershipOutletProps> = ({ path, tabCountRecord }) => {
  const navigation = useNavigate();

  const indexRoute = {
    pathName: 'Workspaces',
    isMatch: !!useMatch({
      path: `${path}/workspaces`,
      end: false,
    }),
    count: tabCountRecord[`Workspaces`],
  };

  const pages = [
    indexRoute,
    {
      pathName: 'Groups',
      isMatch: !!useMatch({
        path: `${path}/groups`,
        end: false,
      }),
      count: tabCountRecord[`Groups`],
    },
  ];

  const selectedPage = pages.find((page) => page.isMatch) ?? indexRoute;

  const [selectedTab, setSelectedTab] = useState<ACLMembershipRoutes>(selectedPage?.pathName as ACLMembershipRoutes);
  return (
    <>
      <Tabs
        selectedTab={`${selectedTab}`}
        tabsWithCount={pages?.map((page) => {
          return { label: page.pathName, count: page.count };
        })}
        setSelectedTab={(tab: ACLMembershipRoutes) => {
          const routes = getACLAccountRoutes();
          navigation(routes[tab] ?? indexRoute?.pathName);
          setSelectedTab(tab);
        }}
      />
      <Outlet />
    </>
  );
};

export default ACLMembershipOutlet;
