import React, { FC } from 'react';

import styles from '../style.module.scss';
import BoltIcon from '@mui/icons-material/Bolt';
import ConstructionIcon from '@mui/icons-material/Construction';
import { Box } from '@mui/material';
import { AccessControlsWorkspace } from '../types';

interface AccessControlWorkspaceNameSpanProps {
  workspace: AccessControlsWorkspace;
  isLive?: boolean;
  isDevelopment?: boolean;
  isInaccessible?: boolean;
  showIcon?: boolean;
}

// Stylized, consistent method for differentiating the display of workspace names.
// This is a component instead of a CSS class to allow for more elaborate interactions in the future.

const AccessControlWorkspaceNameSpan: FC<AccessControlWorkspaceNameSpanProps> = ({
  workspace,
  isLive,
  isDevelopment,
  isInaccessible,
  showIcon,
}) => {
  const opacity = isInaccessible === true ? 0.5 : 1;

  const isLiveFromWorkspace = workspace?.capabilities?.materializable === true;
  const isDevelopmentFromWorkspace = workspace?.capabilities?.materializable === false;

  if (!showIcon === true) {
    return (
      <Box className={styles['workspace-name']} style={{ opacity: opacity, verticalAlign: 'baseline' }}>
        <Box style={{ alignItems: 'bottom' }}>
          <span>{workspace?.name}</span>
        </Box>
      </Box>
    );
  }

  return (
    <Box className={styles['workspace-name']} style={{ opacity: opacity, verticalAlign: 'baseline' }}>
      <Box style={{ alignItems: 'bottom' }}>
        <span>
          {(isLiveFromWorkspace || isLive) && <BoltIcon fontSize="inherit" />}
          {(isDevelopmentFromWorkspace || isDevelopment) && <ConstructionIcon fontSize="inherit" />}
        </span>
        <span style={{ paddingLeft: '6px' }} className="pl4">
          {workspace?.name}
        </span>
      </Box>
    </Box>
  );
};

export default AccessControlWorkspaceNameSpan;
