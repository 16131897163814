import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { Routes } from '../../../core/routes';

import AccountTypeRadioOption from './AccountTypeRadioOption';
import CapabilityList from './CapabilityList';
import { RadioGroupOption } from './acl-types';
import { getRoleRadioGroupOptions } from './acl-util';

// generated
import { Permission, RoleAssignmentSource } from '../../../api/gql/graphql';

// @tecton
import { Callout, FlexItem, FlexGroupWrapper } from '@tecton/ComponentRedesign';
import { ACLModalFormWrapper } from './StyledComponents';

interface ModifyWorkspaceAccessProps {
  workspaceRole?: string;
  roles: RadioGroupOption[];
  roleDescriptionMap?: Record<string, Permission[]>;
  onRoleChange: (roleName: string /** undefined means the selection didn't change */) => void;
  hasRoleFromOtherSources?: boolean;
  roleAssignmentSources?: RoleAssignmentSource[];
  id?: string;
}

const ModifyWorkspaceAccess: FC<ModifyWorkspaceAccessProps> = ({
  workspaceRole,
  roles,
  roleDescriptionMap,
  onRoleChange,
  hasRoleFromOtherSources,
  roleAssignmentSources,
  id,
}) => {
  const permissions = roleDescriptionMap ? roleDescriptionMap[`${workspaceRole}`] : [];
  const optionsDefault = getRoleRadioGroupOptions(roles, workspaceRole);
  // general state
  const [roleDescription, setRoleDescription] = useState<Permission[]>(permissions);
  const [options, setOptions] = useState<RadioGroupOption[]>(optionsDefault);

  const groupsList = roleAssignmentSources?.map((roleAssignmentSource, index) => {
    let separator: React.ReactNode = <></>;

    if (index > 0) {
      if (index === roleAssignmentSources.length - 1) {
        separator = <> and </>;
      } else {
        separator = <>, </>;
      }
    }

    return (
      <>
        {separator}
        <Link
          to={`${Routes.accountsAndAccessGroups}/${roleAssignmentSource.principalGroupName}`}
          key={roleAssignmentSource.principalGroupId}
        >
          {` `}
          {roleAssignmentSource.principalGroupName}
        </Link>
      </>
    );
  });

  const listOfGroups = <span>{groupsList}</span>;

  return (
    <>
      <FlexGroupWrapper gap="xl" direction="column">
        {hasRoleFromOtherSources && (
          <FlexItem>
            <Callout mode="neutral" title={<>{id} has other roles in this workspace.</>}>
              Other roles come via group membership in
              {listOfGroups}. Those cannot be changed in this modal. This modal can only change their directly assigned
              role.
            </Callout>
          </FlexItem>
        )}
        <FlexItem>
          <ACLModalFormWrapper>
            <FlexGroupWrapper gap="xl" direction="row">
              <FlexItem grow={6}>
                <AccountTypeRadioOption
                  options={options ?? []}
                  onSelection={(selectedOption) => {
                    const permissions = roleDescriptionMap ? roleDescriptionMap[`${selectedOption?.label}`] : [];
                    setOptions(getRoleRadioGroupOptions(roles, selectedOption?.label ?? ''));
                    setRoleDescription(permissions);
                    onRoleChange(selectedOption?.label ?? '');
                  }}
                />
              </FlexItem>
              <FlexItem grow={8}>
                <CapabilityList roleDescriptions={roleDescription ?? []} maxHeight={600} />
              </FlexItem>
            </FlexGroupWrapper>
          </ACLModalFormWrapper>
        </FlexItem>
      </FlexGroupWrapper>
    </>
  );
};

export default ModifyWorkspaceAccess;
