import styled from '@emotion/styled';
import { FlexGroup, Text } from '@tecton/ComponentRedesign';
import { FC, ReactNode } from 'react';
import { Icon } from './v1';

type SelectableListItemVariant = 'normal' | 'group';
interface SelectableListItemProps {
  children: ReactNode;
  isSelected?: boolean;
  isSelectable?: boolean;
  variant?: SelectableListItemVariant;
  onClick?: () => void;
}

const SelectableListItemWrapper = styled.div<{ isSelected: boolean; isSelectable: boolean }>`
  display: flex;
  width: 100%;
  ${({ isSelectable }) => isSelectable && `align-items: center;`}
  ${({ isSelectable }) => isSelectable && `cursor: pointer;`}
  padding: ${({ theme }) => theme.padding.xs} ${({ theme }) => theme.padding.m};
  gap: ${({ theme }) => theme.gap.s};

  ${({ isSelectable, theme }) =>
    isSelectable &&
    `:hover {
    font-weight: ${theme.font.weight.medium};
    background-color: ${theme.colors.lightestShade};
  }`}
`;

const GroupSelectableListItemWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 7px ${({ theme }) => theme.padding.m};
  gap: ${({ theme }) => theme.gap.s};
  background: #ffffff03;
  box-shadow: 0px -1px 0px 0px rgba(211, 218, 230, 0.5) inset;
`;

const SelectableListItem: FC<SelectableListItemProps> = ({
  variant = 'normal',
  isSelected = false,
  isSelectable = true,
  onClick,
  children,
}) => {
  if (variant === 'group') {
    return (
      <GroupSelectableListItemWrapper>
        <FlexGroup>
          <Text element="h4">{children}</Text>
        </FlexGroup>
      </GroupSelectableListItemWrapper>
    );
  }

  return (
    <SelectableListItemWrapper isSelected={isSelected} onClick={onClick} isSelectable={isSelectable}>
      {isSelected ? (
        <Icon type={'mono'} size="s" icon={'Checkmark'} />
      ) : (
        <>{isSelectable && <div style={{ width: 16, height: 16 }} />}</>
      )}
      {children}
    </SelectableListItemWrapper>
  );
};

export default SelectableListItem;
