import React, { FC, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  FeatureSimilarityAnalysisResult,
  FeatureViewSimilarityGroup,
  FeatureViewSimilarityPair,
} from '../../../types/tecton_proto/feature_analytics/feature_analytics';

import { FCOType } from '../../../core/types/fcoTypes';

// @tecton
import { useTableRowExpansionState } from '@tecton/ComponentRedesign';
import {
  ColorIcon,
  FlexGrid,
  FlexGroup,
  FlexItem,
  Text,
  Table,
  Flyout,
  Code,
  Card,
  FCOIconAndName,
  EmptyPrompt,
  FilterableSortableTable,
} from '@tecton/ComponentRedesign';
import styled from '@emotion/styled';
import {
  FilterableTableColumn,
  FilterableTableFilterType,
  FilterableTableSort,
} from '../../@tecton/ComponentRedesign/FilterableSortableTable/types';

const CompareFlyout: FC<{ fvDetailsFirst: string; fvPair: FeatureViewSimilarityPair }> = ({
  fvDetailsFirst,
  fvPair,
}) => {
  const [isFlyoutOpen, setIsFlyoutOpen] = useState<boolean>(false);

  return (
    <>
      <a
        onClick={() => {
          setIsFlyoutOpen(true);
        }}
        style={{ cursor: 'pointer' }}
      >
        Compare
      </a>
      {isFlyoutOpen && (
        // TODO swap to TabbedFlyout once it is ready
        <Flyout
          title={'Comparison of Feature View definitions'}
          cancel={() => {
            setIsFlyoutOpen(false);
          }}
          onClose={() => {
            setIsFlyoutOpen(false);
          }}
        >
          <Card>
            <Code code={fvPair.similarity_description ?? ''} language={'diff'} />
          </Card>

          <br />

          <FlexGrid gutterSize="l" direction="row" columns={2} alignItems="start">
            <FlexItem>
              <Card title="First FV definition">
                <Code code={fvDetailsFirst} />
              </Card>
            </FlexItem>
            <FlexItem>
              <Card title="Second FV definition">
                <Code code={fvPair.fv_details_second ?? ''} />
              </Card>
            </FlexItem>
          </FlexGrid>
        </Flyout>
      )}
    </>
  );
};

const Wrapper = styled.div<{ wrapperRef: React.RefObject<HTMLDivElement> }>`
  background: ${(props) => props.theme.colors.body};
  padding: ${(props) => props.theme.padding.l};
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.padding.l};
  align-self: stretch;

  width: ${(props) => props.wrapperRef.current?.getBoundingClientRect().width}px;

  td {
    padding: ${(props) => props.theme.padding.l} !important;
  }
`;

const RowExpansionInteriorTable = styled.div`
  background-color: ${(props) => props.theme.colors.emptyShade};
`;

const FvGroupRowExpansion: FC<{ fvGroup: FeatureViewSimilarityGroup; wrapperRef: React.RefObject<HTMLDivElement> }> = ({
  fvGroup,
  wrapperRef,
}) => {
  const columns = [
    {
      name: 'Feature View Name',
      render: (fvPair: FeatureViewSimilarityPair) => {
        return (
          <Link to={`../repo/${fvPair.fv_locator_second!.workspace}/features/${fvPair.fv_locator_second!.name!}`}>
            <FCOIconAndName name={fvPair.fv_locator_second!.name!} type={FCOType.FEATURE_VIEW} />
          </Link>
        );
      },
    },
    {
      name: 'Similarity',
      render: (fvPair: FeatureViewSimilarityPair) => {
        return fvPair.similarity;
      },
    },
    {
      name: 'Workspace',
      render: (fvPair: FeatureViewSimilarityPair) => {
        const workspace = fvPair.fv_locator_second!.workspace;
        return <Link to={`/repo/${workspace}/home`}>{workspace}</Link>;
      },
    },
    {
      name: 'Details',
      render: (fvPair: FeatureViewSimilarityPair) => {
        if (fvPair.similarity == 1) {
          return 'Exact Duplicate';
        } else {
          return <CompareFlyout fvDetailsFirst={fvGroup.fv_details_first ?? ''} fvPair={fvPair} />;
        }
      },
    },
  ];

  return (
    <Wrapper wrapperRef={wrapperRef}>
      <RowExpansionInteriorTable>
        <Table items={fvGroup.pairs as any[]} columns={columns} compressed={false} />
      </RowExpansionInteriorTable>
    </Wrapper>
  );
};

// Helper class in order to populate the compound workspace+name id into a single object property
// as required by FilterableSortableTable
interface FeatureViewSimilarityGroupWithID extends FeatureViewSimilarityGroup {
  id: string;
}

function addIds(groups: FeatureViewSimilarityGroup[]): FeatureViewSimilarityGroupWithID[] {
  if (!groups) {
    return [];
  }
  return groups.map((group) => {
    let groupWithId: FeatureViewSimilarityGroupWithID = group as FeatureViewSimilarityGroupWithID;
    groupWithId.id = group.fv_locator_first!.workspace + ':' + group.fv_locator_first!.name;
    return groupWithId;
  });
}

interface FeatureAnalyticsProps {
  featureAnalytics?: FeatureSimilarityAnalysisResult;
  isError?: boolean;
  isLoading?: boolean;
}

const FeatureAnalyticsList: FC<FeatureAnalyticsProps> = ({ featureAnalytics, isError, isLoading }) => {
  const [sortParams, setSortParams] = useState<FilterableTableSort>({ key: undefined, order: 'asc', type: undefined });

  const wrapperRef = useRef<HTMLDivElement>(null);

  const { itemIdToExpandedRowMap, toggleRowExpansion } = useTableRowExpansionState(
    (row: FeatureViewSimilarityGroup) => {
      return row.fv_locator_first!.workspace + ':' + row.fv_locator_first!.name;
    },
    (row: FeatureViewSimilarityGroup) => {
      return <FvGroupRowExpansion fvGroup={row} wrapperRef={wrapperRef} />;
    }
  );

  const columns: FilterableTableColumn[] = [
    {
      name: <>Feature View Name</>,
      label: 'Feature View Name',
      key: 'fv_locator_first.name',
      isAlwaysActive: true,
      tableCellRender: (name: string, fvGroup: FeatureViewSimilarityGroup) => {
        return (
          <Link to={`../repo/${fvGroup.fv_locator_first!.workspace}/features/${name}`}>
            <FCOIconAndName name={name} type={FCOType.FEATURE_VIEW} />
          </Link>
        );
      },
    },
    {
      name: <># of Similar Views</>,
      label: '# of Similar Views',
      key: 'pairs',
      filterType: FilterableTableFilterType.COUNT,
      tableCellRender: (pairs: string, fvGroup: FeatureViewSimilarityGroup) => {
        return pairs.length;
      },
    },
    {
      name: <>Workspace</>,
      label: 'Workspace',
      key: 'fv_locator_first.workspace',
      tableCellRender: (workspace: string, fvGroup: FeatureViewSimilarityGroup) => {
        return <Link to={`/repo/${workspace}/home`}>{workspace}</Link>;
      },
    },
  ];

  let emptyPrompt;
  if (isLoading) {
    emptyPrompt = <EmptyPrompt orientation="horizontal" variant="loading" title={<>Loading ...</>} body={<></>} />;
  } else {
    emptyPrompt = (
      <EmptyPrompt
        orientation="horizontal"
        title={<>No Data Found</>}
        body={
          <>
            <p>This deployment does not have feature analytics data.</p>
          </>
        }
      />
    );
  }

  return (
    <div ref={wrapperRef}>
      <FilterableSortableTable
        name={'feature-analytics-table'}
        items={addIds(featureAnalytics?.fv_groups ?? [])}
        columns={columns}
        emptyPrompt={emptyPrompt}
        searchPlaceholder="Search Feature Views"
        itemIdToExpandedRowMap={itemIdToExpandedRowMap}
        itemId={'id'}
        isExpandable
        rowProps={(row: FeatureViewSimilarityGroup) => {
          return {
            onClick: () => {
              toggleRowExpansion(row);
            },
          };
        }}
      />
    </div>
  );
};

export default FeatureAnalyticsList;
