import { Code, FlexGroupWrapper, FlexItem, IconAndTooltip } from '@tecton/ComponentRedesign';

import styled from '@emotion/styled';
import { ReactComponent as QuestionInCircle } from '@svg/question-in-circle.svg';

const CodeWrapper = styled.div`
  height: calc(41vh);
  overflow: auto;
`;

const ClusterConfigFlyout = ({ originalJson, outputJson }: { originalJson?: string; outputJson?: string }) => {
  // Beautifying the JSON
  const prettyOriginalJson = JSON.stringify(JSON.parse(originalJson ?? '{}'), null, 2);
  const prettyFinalJson = JSON.stringify(JSON.parse(outputJson ?? '{}'), null, 2);

  return (
    <>
      <FlexGroupWrapper direction="column" gap="xl">
        {originalJson && (
          <FlexItem>
            <FlexGroupWrapper gap="m">
              <>
                <FlexItem grow={false}>
                  <div>
                    <IconAndTooltip
                      icon={<QuestionInCircle />}
                      name={'Original JSON'}
                      description="Original JSON string as specified in your feature repository."
                    />
                  </div>
                </FlexItem>
                <FlexItem>
                  <CodeWrapper>
                    <Code code={prettyOriginalJson} language="json" />
                  </CodeWrapper>
                </FlexItem>
              </>
            </FlexGroupWrapper>
          </FlexItem>
        )}

        <FlexItem>
          <FlexGroupWrapper gap="m">
            <FlexItem grow={false}>
              {originalJson ? (
                <div>
                  <IconAndTooltip
                    icon={<QuestionInCircle />}
                    name={'Final Output'}
                    description="Final JSON output sent to your data platform, combining your original config and Tecton custom overrides."
                  />
                </div>
              ) : (
                <div>
                  <IconAndTooltip
                    icon={<QuestionInCircle />}
                    name={'Config JSON'}
                    description="JSON config sent to your data platform for configuring materialization jobs."
                  />
                </div>
              )}
            </FlexItem>
            <FlexItem>
              <CodeWrapper>
                <Code code={prettyFinalJson} language="json" />
              </CodeWrapper>
            </FlexItem>
          </FlexGroupWrapper>
        </FlexItem>
      </FlexGroupWrapper>
    </>
  );
};

export default ClusterConfigFlyout;
