import { useQuery } from '@tanstack/react-query';
import { Metadata_Service } from '.';
import { DatasetFCO } from '../core/types/fcoTypes';
import { transformDataSetProtoToDatasetFCO } from './fcoUtils/dataSetFcoUtils';
import { SavedFeatureDataFrame } from '../types/tecton_proto/data/saved_feature_data_frame';

const fetchDatasets = async (args: string): Promise<{ data: DatasetFCO[] }> => {
  const response = await Metadata_Service('get-all-saved-feature-dataframes', {
    data: { workspace: args },
    method: 'POST',
  });

  const transformedDatasets: DatasetFCO[] = [];

  response?.data?.saved_feature_dataframes?.forEach((rawDataframe: SavedFeatureDataFrame) => {
    transformedDatasets.push(transformDataSetProtoToDatasetFCO(rawDataframe));
  });

  response.data = transformedDatasets;

  return response;
};

export const useGetDatasets = (workspace: string) => {
  return useQuery(['get-all-saved-feature-dataframes', workspace], () => {
    return fetchDatasets(workspace);
  });
};
