import React, { FC } from 'react';
import _ from 'lodash';
import { NewWorkspaceSelectorAutocompleteOption, NewWorkspaceSelectorDropdownType } from './NewWorkspaceSelector';
import NewWorkspaceSelectorOptionInaccessible from './NewWorkspaceSelectorOptionInaccessible';
import NewWorkspaceSelectorOptionAccessible from './NewWorkspaceSelectorOptionAccessible';
import NewWorkspaceSelectorGroupHeading from './NewWorkspaceSelectorGroupHeading';

interface NewWorkspaceSelectorOptionProperties {
  props: any;
  option: NewWorkspaceSelectorAutocompleteOption;
}

const NewWorkspaceSelectorOption: FC<NewWorkspaceSelectorOptionProperties> = ({ props, option }) => {
  const propsToPass = _.cloneDeep(props);
  delete propsToPass.className;

  if (option.dropdownType === NewWorkspaceSelectorDropdownType.GROUP_HEADING) {
    return <NewWorkspaceSelectorGroupHeading option={option} key={option.label} />;
  }

  if (option.isAccessible) {
    return (
      <NewWorkspaceSelectorOptionAccessible
        propsToPass={propsToPass}
        workspace={{
          name: option.label,
          capabilities: { materializable: option.capabilities!.materializable },
        }}
        key={option.label}
      />
    );
  }

  return (
    <NewWorkspaceSelectorOptionInaccessible
      workspace={{ name: option.label, capabilities: { materializable: option.capabilities!.materializable } }}
      key={option.label}
    />
  );
};

export default NewWorkspaceSelectorOption;
