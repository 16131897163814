import React from 'react';
import { AggregationNode } from '../dataFlowTypes';
import { DataFlowSidePanel } from './StyledPanelComponents';

const AggregationContextPanel = ({
  node,
  name,
  detailsPanelClearAll,
  zoomToNode,
}: {
  node: AggregationNode;
  name: string;
  detailsPanelClearAll: () => void;
  zoomToNode?: () => void;
}) => {
  return (
    <DataFlowSidePanel
      name={name}
      prehead={`Aggregation`}
      nodeId={node.id}
      detailsPanelClearAll={detailsPanelClearAll}
      zoomToNode={zoomToNode}
    >
      <p>TODO</p>
    </DataFlowSidePanel>
  );
};

export default AggregationContextPanel;
