import React, { FC, PropsWithChildren } from 'react';
import styled from '@emotion/styled';
import { TitleText } from './StyledComponents';
import { FlexGroupWrapper, FlexItem } from '../../@tecton/ComponentRedesign';

interface GraphContainerProps {
  headerLabel: string;
}

export const X_AXIS_LABEL = 'Time (Local)';
const ChartContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
`;

const GraphContainer: FC<PropsWithChildren<GraphContainerProps>> = ({ headerLabel, children }) => {
  return (
    <FlexGroupWrapper gap="l">
      <FlexItem>
        <TitleText>{headerLabel}</TitleText>
      </FlexItem>
      <FlexItem>
        <ChartContainer>{children}</ChartContainer>
      </FlexItem>
    </FlexGroupWrapper>
  );
};

export default GraphContainer;
