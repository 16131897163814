export type ColoredFCOTypes =
  | 'data_source'
  | 'request_data_source'
  | 'feature_view'
  | 'odfv'
  | 'feature_service'
  | 'transformation'
  | 'embedding';
export type Shades = Record<'light' | 'medium' | 'dark', string>;
export const PURPLE: Shades = {
  dark: '#6257DC',
  medium: '#6A5EEE',
  light: '#C0BCED',
};
export const BLUE: Shades = {
  dark: '#0FA9DA',
  medium: '#1BB8EA',
  light: '#8AD3EA',
};
export const GREEN: Shades = {
  dark: '#26B17F',
  medium: '#28C68D',
  light: '#86E1C0',
};

export const fcoColors: Record<ColoredFCOTypes, { background: string; fontColor: string; glow: string }> = {
  data_source: {
    background: GREEN.medium,
    fontColor: GREEN.dark,
    glow: GREEN.light,
  },
  request_data_source: {
    background: GREEN.medium,
    fontColor: GREEN.dark,
    glow: GREEN.light,
  },
  feature_view: {
    background: BLUE.medium,
    fontColor: BLUE.dark,
    glow: BLUE.light,
  },
  transformation: {
    background: BLUE.medium,
    fontColor: BLUE.dark,
    glow: BLUE.light,
  },
  odfv: {
    background: BLUE.medium,
    fontColor: BLUE.dark,
    glow: BLUE.light,
  },
  feature_service: {
    background: PURPLE.medium,
    fontColor: PURPLE.dark,
    glow: PURPLE.light,
  },
  embedding: {
    background: PURPLE.medium,
    fontColor: PURPLE.dark,
    glow: PURPLE.light,
  },
};

export const defaultBorderRadius = '6px';
export const ICON_BLOCK_WIDTH = `1.75em`;

export const BACKGROUND_PATH_CLASSNAME = 'edge-is-background-path';
export const ONLINE_REQUEST_PATH_CLASSNAME = 'edge-is-online-request-path';
export const ONLINE_SERVING_PATH_CLASSNAME = 'edge-is-online-serving-path';
export const OFFLINE_READ_PATH_CLASSNAME = 'edge-is-offline-read-path';
export const BATCH_MATERIALIZATION_PATH_CLASSNAME = 'edge-is-batch-materialization-path';
export const STREAM_MATERIALIZATION_PATH_CLASSNAME = 'edge-is-stream-materialization-path';
