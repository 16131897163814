import moment from 'moment-timezone';
import {
  BackfillMaterializationTaskDiff,
  StateUpdatePlanSummaryDiff,
} from '../../../types/tecton_proto/data/state_update';

type BackfillSummaryObject = {
  name?: string;
  fco_type?: string;
} & BackfillMaterializationTaskDiff;

const duration = (backfill: BackfillSummaryObject) => {
  return moment(backfill.feature_end_time).diff(moment(backfill.feature_start_time));
};

const processMaterializationInfo = (diffItems: StateUpdatePlanSummaryDiff[]) => {
  const batchMaterialization = diffItems.filter((item) => {
    const hasBatchMaterialization = !!item?.materialization_info?.batch_task_diff?.display_string;

    return hasBatchMaterialization;
  });

  const streamMaterialization = diffItems.filter((item) => {
    const hasStreamMaterialization = !!item?.materialization_info?.stream_task_diff?.display_string;

    return hasStreamMaterialization;
  });

  const backfillJobs: BackfillSummaryObject[] = diffItems
    .reduce<BackfillSummaryObject[]>((memo, current) => {
      if (current.materialization_info?.backfill_task_diffs) {
        const results = current.materialization_info.backfill_task_diffs.map((item) => {
          return {
            name: current.name,
            fco_type: current.fco_type,
            ...item,
          };
        });

        return memo.concat(results);
      }

      return memo;
    }, [])
    .sort((a, b) => {
      return duration(b) - duration(a);
    });

  return {
    batchMaterialization,
    streamMaterialization,
    backfillJobs,
  };
};

export default processMaterializationInfo;
