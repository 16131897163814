import React, { FC, useState } from 'react';
import style from './style.module.scss';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import { IconButton, Tooltip } from '@mui/material';

interface NoWorkspacesAvailableTerminalCommandProperties {
  code: string;
}

const NoWorkspacesAvailableTerminalCommand: FC<NoWorkspacesAvailableTerminalCommandProperties> = ({ code }) => {
  const [tooltipText, setTooltipText] = useState('Copy to Clipboard');

  const copyCodeToClipboard = () => {
    navigator.clipboard.writeText(code);
    setTooltipText('Copied!');
  };

  return (
    <div style={{ marginBottom: '12px' }}>
      <div className="flex items-baseline ph2" style={{ justifyContent: 'space-between', lineHeight: '16px' }}>
        <div className={style.terminalHeading}>In Terminal</div>
        <div>
          <Tooltip
            arrow
            title={<>{tooltipText}</>}
            TransitionProps={{ timeout: 0 }}
            onClose={() => {
              setTooltipText('Copy to Clipboard');
            }}
          >
            <IconButton onClick={copyCodeToClipboard} className={style.terminalCommandCopyButton} size="small">
              <span style={{ fontSize: '12px' }}>
                <ContentPasteIcon fontSize="inherit" />
              </span>
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <div className={style.terminalCommand}>{code}</div>
    </div>
  );
};

export default NoWorkspacesAvailableTerminalCommand;
