import { FC } from 'react';

import { ReactComponent as Information } from '@svg/information.svg';
import { IconAndTooltip } from '@tecton/ComponentRedesign';

export interface ACLWorkspaceRoleTableColumnProps {
  workspaceName: string;
}

const ACLWorkspaceRoleTableColumn: FC<ACLWorkspaceRoleTableColumnProps> = ({ workspaceName }) => {
  /* This component is needed since we need to treat "All Workspaces" differently.
  We need a tooltip explaining what is via a tooltip. */
  const toolTipContent = 'The user’s role for all workspaces in Tecton. This will set the role in every workspace.';

  const allWorkspaceIconTooltip = (
    <div>
      <IconAndTooltip name={'All Workspaces'} description={toolTipContent} icon={<Information />} />
    </div>
  );
  const workspaceNameDisplay = workspaceName === 'All Workspaces' ? allWorkspaceIconTooltip : <>{workspaceName}</>;

  return <>{workspaceNameDisplay}</>;
};

export default ACLWorkspaceRoleTableColumn;
