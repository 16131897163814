export type JobDetailRoutes = 'Stages' | 'Metadata' | 'Logs' | 'Metrics';

export const getJobDetailRoutes = (): Record<JobDetailRoutes, string> => {
  return {
    Stages: 'stages',
    Metadata: 'metadata',
    Metrics: 'metrics',
    Logs: 'logs',
  };
};

export const getJobDetailRouteName = (pathname: string): JobDetailRoutes => {
  const pathSegments = pathname.split('/');
  const lastPathSegment = pathSegments[pathSegments.length - 1];
  const routes = getJobDetailRoutes();
  const record = Object?.entries(routes)?.find((record) => {
    return record[1] === lastPathSegment;
  });

  return (record?.[0] ?? 'Stages') as JobDetailRoutes;
};
