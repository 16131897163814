import { useQueryClient } from '@tanstack/react-query';
import { FilterOptionType } from '@tecton/CategoricalSelector';
import moment, { Moment } from 'moment';
import { createContext, useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useListAllMaterializingFeatureViews } from '../../../api/featureViews';
import { Job, SortDirection } from '../../../api/gql/graphql';
import { useGetInfiniteJobs } from '../../../api/queries/jobsGraphql';
import { useListWorkspaces } from '../../../api/workspaces';
import { useTimezoneSettings } from '../../../components/context/TimezoneContext';
import { Workspace } from '../../../types/tecton_proto/data/workspace';
import { DateTimeRange, ListWorkspacesResponse } from '../../../types/tecton_proto/metadataservice/metadata_service';
import { logEvent } from '../../../utils/analytics-utils';
import { useColumnsDisplayControlsState } from './ColumnDisplayControls';
import {
  activeDateFilter,
  arrayToFilterOptions,
  categoricalSelectorPayloads,
  dateRangeFilterPayloads,
  jobsStateFromParams,
  jobsTableColumnLabels,
  numericRangePayloads,
  resetFilterDefaults,
  TableSortType,
  updateDefaultEndDate,
  updateSelected,
} from './JobsUtils';
import {
  FilterProp,
  JobsContextProviderProps,
  JobsDefaultProp,
  JobsFilterStateProps,
  MomentDateTimeKeys,
  PayloadProp,
  updateCategoricalSelectorStateProps,
  updateCountsSelectorStateProps,
} from './types';

const defaultData = {
  tasksWithAttempts: [],
  pagination: {
    perPage: 0,
    total: 0,
    nextPageToken: '',
    sortDirection: 'SORT_UNKNOWN' as SortDirection,
    sortKey: 'last_task_state_change',
  },
};

const defaultInfinite = {
  pages: [defaultData] as Job[],
  pageParams: [''],
};

// TODO(JM) add actual Type
export const JobsContext = createContext<any>(null);

export const JobsContextProvider = ({ children, feature_view, workspace }: JobsContextProviderProps) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { timezone } = useTimezoneSettings();
  const queryClient = useQueryClient();

  // Make Copies
  const ColumnLabels = { ...jobsTableColumnLabels };
  const jobsFilterDefaults = resetFilterDefaults();

  // FV MATERIALIZATION TAB
  if (feature_view) {
    if (jobsFilterDefaults.queryState.featureViews) jobsFilterDefaults.queryState.featureViews = [feature_view];
    if (workspace && jobsFilterDefaults.queryState.workspaces) jobsFilterDefaults.queryState.workspaces = [workspace];

    delete ColumnLabels.featureView;
    delete ColumnLabels.workspace;
  } else {
    // delete ColumnLabels.actions;
  }

  //Query states and selector states
  const [jobsFilterState, setJobsFilterState] = useState<JobsFilterStateProps>(jobsFilterDefaults);
  const [keepPreviousData, setKeepPreviousData] = useState<boolean>(false);

  //Action States
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showRetryModal, setShowRetryModal] = useState(false);
  const [currentJobAction, setCurrentJobAction] = useState<Job | null | undefined>(null);

  //Table Columns Control State
  const forcedColumns = ['actions'];
  const editableColumns = Object.entries(ColumnLabels).filter(([key]) => !forcedColumns.includes(key));
  const ControlsState = useColumnsDisplayControlsState(
    'jobs-page-columns-settings',
    editableColumns.map(([key, label]) => {
      return {
        label,
        columnKey: key,
        checked: key === 'manuallyTriggered' ? undefined : 'on',
      };
    }),
    // DISABLE FILTER STORAGE IF FV MATERIALIZATION TAB
    !!feature_view,
    forcedColumns
  );

  const resetActiveFilters = useCallback(() => {
    // NEED CREATE THIS ABILITY
    console.log('reset Active');
  }, []);

  // GET Sort Params from URL
  const getSortParams = useCallback(() => {
    const sortKeyParam = searchParams.get('sortKey');
    const sortDirectionParam = searchParams.get('sortDirection');
    return { sortKeyParam, sortDirectionParam };
  }, [searchParams]);

  // RESET Sort Params
  const clearSortParams = useCallback(() => {
    const { sortKeyParam, sortDirectionParam } = getSortParams();
    if (sortKeyParam) searchParams.delete('sortKey');
    if (sortDirectionParam) searchParams.delete('sortDirection');
  }, [getSortParams, searchParams]);

  // RESET Sort State
  const clearSort = useCallback(() => {
    clearSortParams();
    setJobsFilterState((state) => {
      return {
        ...state,
        queryState: {
          ...state.queryState,
          pagination: {
            ...state.queryState.pagination,
            sortDirection: jobsFilterDefaults.queryState.pagination?.sortDirection,
            sortKey: jobsFilterDefaults.queryState.pagination?.sortKey,
          },
        },
      };
    });
  }, [setJobsFilterState, clearSortParams]);

  // UPDATE FILTER STATE AND QUERY STATE
  const updateJobsFilterState = useCallback(
    (
      prop: FilterProp,
      selectorPayload: PayloadProp,
      queryPayload: PayloadProp,
      isolate?: boolean,
      preservePageToken = false
    ) => {
      if (isInitialized) {
        clearSort();
      }

      setJobsFilterState((state) => {
        if (!preservePageToken && state.queryState.pagination?.pageToken) delete state.queryState.pagination?.pageToken;
        const Q = isolate ? jobsFilterDefaults.queryState : state.queryState;
        const S = isolate ? jobsFilterDefaults.selectorState : state.selectorState;

        return {
          queryState: {
            ...Q,
            [prop]: queryPayload,
          },
          selectorState: {
            ...S,
            [prop]: selectorPayload,
          },
        };
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [clearSort]
  );

  // UPDATE PARAMS
  const updateJobsFilterParams = useCallback(
    (prop: FilterProp, params: string | boolean, isolate?: boolean) => {
      if (params) {
        if (isolate) searchParams.delete(prop as string);
        searchParams.set(prop as string, params.toString());
        setSearchParams(searchParams, { replace: true });
      } else if (searchParams.get(prop as string)) {
        searchParams.delete(prop as string);
        setSearchParams(searchParams, { replace: true });
      }
    },
    [searchParams, setSearchParams]
  );

  // Setting Date Fileters
  const updateDateFilter = useCallback(
    (prop: MomentDateTimeKeys, start?: Moment, end?: Moment) => {
      start = start
        ? start
        : jobsFilterState?.selectorState?.[prop]?.start ?? jobsFilterDefaults.selectorState[prop].start;
      end = end ? end : jobsFilterState?.selectorState?.[prop]?.end ?? jobsFilterDefaults.selectorState[prop].end;
      const { params, query } = dateRangeFilterPayloads(start, end);
      const queryObj: DateTimeRange = query as DateTimeRange;
      const latestParams = activeDateFilter(queryObj, undefined, 'seconds', timezone) ? params : undefined;
      updateJobsFilterParams(prop, latestParams!);
    },
    [jobsFilterDefaults.selectorState, jobsFilterState?.selectorState, timezone, updateJobsFilterParams]
  );

  // Used to refresh "NOW" end date in main date selector
  const resetNowDate = useCallback(() => {
    const start = jobsFilterState.selectorState.lastTaskStateChange.start;
    const end = moment();
    const { query, selector } = dateRangeFilterPayloads(start, end);

    updateDefaultEndDate(end);
    updateJobsFilterState('lastTaskStateChange', selector, query, false, true);
  }, [jobsFilterState.selectorState.lastTaskStateChange.start, updateJobsFilterState]);

  const updateSortParams = useCallback(
    ({ sort }: TableSortType) => {
      const direction =
        sort.direction === 'asc' ? 'SORT_ASC' : sort.direction === 'desc' ? ('SORT_DESC' as SortDirection) : undefined;
      const field = sort.field ?? '';
      if (field) searchParams.set('sortKey', field);
      if (direction) searchParams.set('sortDirection', direction as string);
      setSearchParams(searchParams, { replace: true });
    },
    [searchParams, setSearchParams]
  );

  const resetFilterArgs = useCallback(
    (prop: JobsDefaultProp) => {
      searchParams.delete(prop);
      setSearchParams(searchParams, { replace: true });
    },
    [searchParams, setSearchParams]
  );

  const updateCountsFilter = useCallback(
    ({ prop, items, range }: updateCountsSelectorStateProps) => {
      const { params } = numericRangePayloads(items, range);
      updateJobsFilterParams(prop, params);
    },
    [updateJobsFilterParams]
  );

  const updateCategoricalSelectorState = useCallback(
    ({ prop, items, isolate, stateOnly }: updateCategoricalSelectorStateProps) => {
      const isStatus = ['statuses', 'taskType', 'taskTypeForDisplays'].includes(prop);
      const { params, selector, query } = categoricalSelectorPayloads(prop, items, isStatus);

      if (stateOnly) {
        updateJobsFilterState(prop, selector, query, isolate);
      } else {
        updateJobsFilterParams(prop, params, isolate);
      }
    },
    [updateJobsFilterParams, updateJobsFilterState]
  );

  const { data: workspaceList } = useListWorkspaces({
    select: (data: ListWorkspacesResponse) =>
      (data?.workspaces
        ?.filter((ws: Workspace) => ws?.capabilities?.materializable && ws?.name)
        .map((ws: Workspace) => ws.name) as string[]) || [],
  });

  const { data: featureViewList } = useListAllMaterializingFeatureViews();

  const {
    data = defaultInfinite,
    isLoading,
    isFetching,
    isError,
    fetchNextPage,
    isFetchingNextPage,
  } = useGetInfiniteJobs(jobsFilterState?.queryState, keepPreviousData);

  const resetAllFilters = useCallback(() => {
    const lastTaskStateChangeParam = searchParams.get('lastTaskStateChange');
    const { sortKeyParam, sortDirectionParam } = getSortParams();
    const newUrlParams = new URLSearchParams();
    if (lastTaskStateChangeParam) newUrlParams.set('lastTaskStateChange', lastTaskStateChangeParam);
    if (sortKeyParam) newUrlParams.set('sortKey', sortKeyParam);
    if (sortDirectionParam) newUrlParams.set('sortDirection', sortDirectionParam);
    setSearchParams(newUrlParams);
  }, [getSortParams, searchParams, setSearchParams]);

  const loadMoreData = () => {
    setKeepPreviousData(true);
    fetchNextPage();
    setKeepPreviousData(false);
  };

  const updateFilterList = useCallback(
    (list: string[], prop: 'featureViews' | 'workspaces') => {
      if (list && list.length) {
        const options = arrayToFilterOptions(list, jobsFilterDefaults.queryState[prop] as string[]);
        //Check params for filters
        const on = searchParams.get(prop)?.split(',');
        const { selector } = categoricalSelectorPayloads(prop, options);
        const selectorOptions: FilterOptionType[] = selector as FilterOptionType[];
        if (on) {
          updateSelected(selectorOptions, on);
        }

        updateCategoricalSelectorState({ prop, items: selectorOptions, stateOnly: true });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const setStateFromparams = useCallback(() => {
    jobsStateFromParams({
      searchParams,
      setSearchParams,
      jobsFilterState,
      setJobsFilterState,
      isFeatureView: !!feature_view,
    });
  }, [searchParams, setSearchParams, jobsFilterState, feature_view]);

  // Update Filters When Query Params Change
  // FV Materialzation Tab is not filterable
  useEffect(() => {
    setStateFromparams();
    logEvent('jobs-filters', undefined, Object.fromEntries(searchParams.entries()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  // SET Workspaces Filters
  // NA for FV Materialization Tab
  useEffect(() => {
    if (workspaceList && !feature_view) {
      updateFilterList(workspaceList as string[], 'workspaces');
    }
  }, [feature_view, updateFilterList, workspaceList]);

  // SET FV List Filters
  // NA for FV Materialization Tab
  useEffect(() => {
    if (featureViewList && !feature_view) {
      updateFilterList(featureViewList as string[], 'featureViews');
    }
  }, [updateFilterList, featureViewList, feature_view]);

  // initialize and clear cache when unmounting
  useEffect(() => {
    // We Don't want to clear params on initial load
    setIsInitialized(true);

    // Reset NOW on return to page if no params
    if (!searchParams.get('lastTaskStateChange')) {
      resetNowDate();
    }

    return () => {
      queryClient.invalidateQueries({
        queryKey: ['infinite-jobs'],
        exact: true,
        refetchType: 'none',
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <JobsContext.Provider
      value={{
        data,
        isLoading,
        isFetching,
        isError,
        isFetchingNextPage,
        jobsFilterState,
        loadMoreData,
        updateDateFilter,
        updateCategoricalSelectorState,
        resetFilterArgs,
        resetAllFilters,
        updateSortParams,
        updateCountsFilter,
        searchParams,
        ControlsState,
        feature_view,
        showCancelModal,
        setShowCancelModal,
        showRetryModal,
        setShowRetryModal,
        currentJobAction,
        setCurrentJobAction,
        resetNowDate,
        resetActiveFilters,
        isFeatureView: !!feature_view,
      }}
    >
      {children}
    </JobsContext.Provider>
  );
};
