import { Global } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useEffect } from 'react';

import { Modal } from '@tecton';
import { FC } from 'react';
import { logEvent } from '../../utils/analytics-utils';
import { searchPreferredWidth } from './SearchResults';

const SearchModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${({ theme }) => theme.padding.xl};
  align-self: stretch;
  color: ${({ theme }) => theme.colors.mediumShade};
  font-size: ${({ theme }) => theme.font.fontSizes.s};
`;

interface GlobalSearchModalProps {
  body: React.ReactNode;
  onCancel: () => void;
  onConfirm: () => void;
  ref?: React.RefObject<HTMLDivElement> | null;
}

const GlobalSearchModalContainer = styled(Modal)(({ theme }) => ({
  display: 'flex',
  minWidth: `calc(${theme.breakpoint.m}px + 6px)`,
  maxWidth: searchPreferredWidth,
  width: '85vw',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.padding.m,
  flexShrink: 0,
  borderRadius: theme.border.radius.small,
  background: theme.colors.emptyShade,
  boxShadow: theme.shadow.xl,
  marginTop: '20vh',
  inlineSize: '85vw',
  maxInlineSize: searchPreferredWidth,
  height: '52px',
  '.euiModal__closeIcon': {
    display: 'none',
  },
  [`@media (max-width: ${theme.breakpoint.m}px)`]: {
    position: 'relative',
    // Yes we need this. The rounded corners go away when we hit the breakpoint.
    borderRadius: theme.border.radius.small,
    background: theme.colors.emptyShade,
    boxShadow: theme.shadow.xl,
    paddingBottom: theme.padding.l,
  },
}));

const GlobalSearchModal: FC<GlobalSearchModalProps> = ({ body, onCancel, ref }) => {
  useEffect(() => {
    logEvent('Search', '', {
      event: 'Search Started',
      description: `User clicks the search field in the sidebar or enters a keyboard shortcut`,
    });
  }, []);
  return (
    <>
      <Global
        styles={{
          '.euiOverlayMask': {
            alignItems: 'flex-start',
          },
        }}
      />
      <GlobalSearchModalContainer onClose={onCancel}>
        <SearchModalContent ref={ref}>{body}</SearchModalContent>
      </GlobalSearchModalContainer>
    </>
  );
};

export default GlobalSearchModal;
