import { FC } from 'react';
import { MonitoringDateRange } from '../../components/monitoring/DateRange-Dropdown-util';
import { X_AXIS_LABEL } from '../../components/monitoring/GraphContainer';
import PrometheusMonitoring from '../../components/monitoring/PrometheusMonitoring';
import useGlobalState from '../../shared/useGlobalState';
import { GraphType } from './graph-types';
interface PrometheusContainerProps {
  monitoringDateRange: MonitoringDateRange;
  workspace: string;
  graphMetadata: GraphType;
  featureViewId: string;
  height?: number;
  parseValue?: (a: string) => number; // default is parseInt
}

const PrometheusContainer: FC<PrometheusContainerProps> = ({
  monitoringDateRange,
  workspace,
  graphMetadata,
  featureViewId,
  height,
  parseValue,
}) => {
  const { config } = useGlobalState();
  const chartTitle = graphMetadata.chartTitle ?? '';
  const yAxisLabel = graphMetadata?.yAxisLabel ?? '';

  const queries =
    graphMetadata?.queries?.map((query) => {
      // TODO: Delete this logic when migration to ELB is done.
      const ingressType: string = config?.FEATURE_SERVER_INGRESS_TYPE ?? 'nginx';
      return {
        label: query.label,
        query: query?.query.apply(this, [
          { workspace, fcoId: featureViewId, step: monitoringDateRange?.step, ingressType },
        ]),
      };
    }) ?? [];

  const yTicksCallback = graphMetadata.yTickCallBack;
  const toolTipLabelCallback = graphMetadata.toolTipLabelCallback;
  const getMetricLabelCallback = graphMetadata?.getMetricLabelCallback;
  const graphInfoTooltip = graphMetadata?.graphInfoTooltip?.replaceAll('::step::', `${monitoringDateRange?.step}`);

  const yOverride = {
    title: {
      display: true,
      text: yAxisLabel,
      font: {
        size: 12,
      },
    },
    min: 0,
    ticks: {
      callback: yTicksCallback,
    },
  };

  const hideLegendOverride = {
    display: true,
    labels: {
      display: false,
      generateLabels: () => {
        return '';
      },
    },
  };

  return (
    <>
      <PrometheusMonitoring
        chartTitle={chartTitle}
        monitoringDateRange={monitoringDateRange}
        queries={queries}
        xAxisLabel={X_AXIS_LABEL}
        yAxisLabel={yAxisLabel}
        yTicksCallback={yTicksCallback}
        yGrace={3}
        yOverride={yOverride}
        toolTipLabelCallback={toolTipLabelCallback}
        getMetricLabelCallback={getMetricLabelCallback}
        pluginLegendOverride={graphMetadata?.showLegend ? undefined : hideLegendOverride}
        graphInfoTooltip={graphInfoTooltip}
        parseValue={parseValue}
        height={height}
      />
    </>
  );
};

export default PrometheusContainer;
