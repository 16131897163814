export type SourceRoutes = 'Metadata' | 'Feature Views';

export const getSourceRoutes = (): Record<SourceRoutes, string> => {
  return {
    Metadata: 'overview',
    'Feature Views': 'feature-views',
  };
};

export const getSourceRouteName = (pathname: string): SourceRoutes => {
  const pathSegments = pathname.split('/');
  const lastPathSegment = pathSegments[pathSegments.length - 1];
  const routes = getSourceRoutes();
  const record = Object?.entries(routes)?.find((record) => {
    return record[1] === lastPathSegment;
  });

  return (record?.[0] ?? 'Metadata') as SourceRoutes;
};
