import { useGetFcos } from '../../../api/fcos';
import { WorkspaceFCOContainer } from '../../../core/types/fcoTypes';
import { FeatureFlags, useUserSettings } from '../../context/UserSettingsContext';
import FeatureServicesList from './FeatureServicesList';

// @tecton
import { Breadcrumbs, EmptyPrompt, FlexGroup, FlexItem, Subtitle, ViewLayout } from '@tecton/ComponentRedesign';
import HeaderLayout from '@tecton/ComponentRedesign/lib/HeaderLayout';
import { useListServerGroups } from '../../../api/serverGroup';
import WorkspaceRootBreadcrumb from '../../redesign/WorkspaceRootBreadcrumb';

export const FeatureServicesContainer = () => {
  const { workspace, workspaceDetails } = useUserSettings();

  const { data, isError, isLoading } = useGetFcos<WorkspaceFCOContainer>(workspace as string, {
    select: (data: WorkspaceFCOContainer) => {
      return {
        featureServices: data?.featureServices ?? [],
        idToFcoMap: data.idToFcoMap,
      };
    },
  });

  const { featureEnabled } = useUserSettings();
  const isFeatureServerGroupEnabled = featureEnabled(FeatureFlags.FEATURE_SERVER_GROUP);
  // Don't make the list-server-groups call when the flag is not on.
  const { data: serverGroupsList, isLoading: isServerGroupsListLoading } = useListServerGroups(
    workspace ?? 'prod',
    isFeatureServerGroupEnabled
  );

  const subtitle = (
    <>
      <Subtitle
        description="Feature Services group features from Feature Views together for training and serving."
        learnMoreUrl={'https://docs.tecton.ai/docs/beta/defining-features/feature-services'}
      />
    </>
  );

  const header = (
    <HeaderLayout
      breadcrumbs={
        <Breadcrumbs
          workspace={<WorkspaceRootBreadcrumb workspace={workspaceDetails} />}
          crumbs={[{ label: 'Services' }]}
        />
      }
      subtitle={subtitle}
    />
  );

  if (isLoading && isServerGroupsListLoading) {
    return (
      <ViewLayout
        header={header}
        body={
          <FlexGroup direction="column" alignItems="center">
            <FlexItem>
              <EmptyPrompt
                title={<>Loading Feature Services</>}
                body={<>Feature Services are loading.</>}
                variant="loading"
                orientation="vertical"
              />
            </FlexItem>
          </FlexGroup>
        }
      />
    );
  }

  if (isError) {
    return (
      <ViewLayout
        header={header}
        body={
          <EmptyPrompt
            title={<>Error Loading Feature Services</>}
            body={<>There was an error loading Feature Services for this workspace. Please try again.</>}
            variant="storm"
            orientation="vertical"
          />
        }
      />
    );
  }

  return (
    <ViewLayout
      header={header}
      body={
        <FeatureServicesList
          items={data?.featureServices ?? []}
          idToFcoMap={data?.idToFcoMap}
          workspace={workspace ?? ''}
          serverGroupsList={serverGroupsList ?? []}
          isFeatureServerGroupEnabled={isFeatureServerGroupEnabled}
        />
      }
    />
  );
};

export default FeatureServicesContainer;
