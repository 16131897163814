import { timeWeek } from 'd3-time';
import React, { createContext, useCallback, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { ValidationRecord, ValidationsContextProviderProps, ValidationsFilterState } from './types';
import { parseURLParametersForFilterState } from './utils';
import { Moment } from 'moment';
import moment from 'moment';
import { ColumnsOptionType } from '../../@tecton/ComponentRedesign/FilterableSortableTable/ColumnDisplayControls'; // These will be replaced by imports directly from the component library once created
import { useExpectationDefinitionQuery, useValidationsDataQuery } from '../../../api/validation';
import { useFcoContainer } from '../../../feature/query';

interface ValidationsContextProps {
  activeFilterCount: number;
  allFeatureViews: { label: string; dataInterval: string }[];
  allExpectations: string[];
  expectationOptions: { label: string; value: string }[];
  filterState: ValidationsFilterState;
  data: ValidationRecord[];
  dateRange: [Moment, Moment];
  isLoading: boolean;
  isError: boolean;
  isReady: boolean;
  isFetching: boolean;
  isFetchingNextPage: boolean;
  filtersAreActive: boolean;
  updateFilters: (newState: ValidationsFilterState) => void;
  resetFilters: () => void;
  loadMore: () => void;
  hasMore: boolean;
  workspace: string;
  wasEndDatePassedViaParameters: boolean;
  columns: ColumnsOptionType[];
  setColumns: React.Dispatch<React.SetStateAction<ColumnsOptionType[]>>;
  setDateRange: React.Dispatch<React.SetStateAction<[Moment, Moment]>>;
}

const defaultFilterState: ValidationsFilterState = {
  featureViews: [],
  expectations: [],
  statuses: [],
  startDate: timeWeek.offset(new Date(), -2),
  endDate: new Date(),
};

const defaultInfinite = {
  pages: [] as ValidationRecord[],
  pageParams: [''],
};

export const ValidationsContext = createContext<ValidationsContextProps>({
  activeFilterCount: 0,
  allFeatureViews: [],
  allExpectations: [],
  expectationOptions: [],
  filterState: defaultFilterState,
  data: [],
  dateRange: [moment(), moment()],
  isLoading: false,
  isError: false,
  isReady: false,
  isFetching: false,
  isFetchingNextPage: false,
  filtersAreActive: false,
  updateFilters: () => undefined,
  resetFilters: () => undefined,
  loadMore: () => undefined,
  hasMore: false,
  workspace: '',
  wasEndDatePassedViaParameters: false,
  columns: [],
  setColumns: () => undefined,
  setDateRange: () => undefined,
});

export const ValidationsContextProvider = ({ children, dateRange }: ValidationsContextProviderProps) => {
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const wasEndDatePassedViaParameters = searchParams.get('endDate') !== null;

  const [filterState, setFilterState] = useState<ValidationsFilterState>(
    parseURLParametersForFilterState(searchParams)
  );

  const [keepPreviousData, setKeepPreviousData] = useState<boolean>(false);

  const [expectationsOptions, setExpectationOptions] = useState<{ label: string; value: string }[]>([]);

  const [filtersAreActive, setFiltersAreActive] = useState(Array.from(searchParams.entries()).length > 0);
  const [isReady, setIsReady] = useState(false);

  const {
    data = defaultInfinite,
    isLoading,
    isFetching,
    isError,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useValidationsDataQuery(params.workspace as string, filterState, keepPreviousData);
  const { data: workspaceData, isLoading: workspaceIsLoading } = useFcoContainer(useParams().workspace as string);

  const allFeatureViews = workspaceData?.allFeatureViews
    .map((fv: { name: string; proto: any }) => {
      return { label: fv.name, dataInterval: fv.proto?.materialization_params?.schedule_interval };
    })
    .sort((a: { label: string }, b: { label: string }) => a.label.localeCompare(b.label));

  useEffect(() => {
    if (!isLoading && !workspaceIsLoading) {
      setIsReady(true);
    }
  }, [isLoading, workspaceIsLoading]);

  const { data: expectationSummaryData } = useExpectationDefinitionQuery(
    params.workspace as string,
    filterState.featureViews[0]
  );

  useEffect(() => {
    if (!isLoading && !workspaceIsLoading) {
      setIsReady(true);
    }
  }, [isLoading, workspaceIsLoading]);

  useEffect(() => {
    if (expectationSummaryData?.metric_expectations) {
      setExpectationOptions(
        expectationSummaryData.metric_expectations.map((datum) => {
          const displayLabel = datum.display_name ?? datum.name;
          const actualValue = datum.name ?? '';
          return { label: displayLabel || '', value: actualValue };
        })
      );
    }
  }, [filterState.featureViews, expectationSummaryData]);

  const updateSearchParams = useCallback(
    (newState: ValidationsFilterState) => {
      const asUrlParams = {
        startDate: newState.startDate.getTime().toString(),
        endDate: newState.endDate.getTime().toString(),
        featureViews: JSON.stringify(newState.featureViews),
        expectations: JSON.stringify(newState.expectations),
        statuses: JSON.stringify(newState.statuses),
      };

      setSearchParams(asUrlParams);
    },
    [setSearchParams]
  );

  const updateFilters = useCallback(
    (newState: ValidationsFilterState) => {
      setFilterState(newState);
      setFiltersAreActive(
        newState.expectations.length !== 0 || newState.featureViews.length !== 0 || newState.statuses.length !== 0
      );
      updateSearchParams(newState);
    },
    [updateSearchParams]
  );

  const resetFilters = useCallback(() => {
    updateFilters(defaultFilterState);
    setFiltersAreActive(false);
    setSearchParams({});
  }, [setSearchParams, updateFilters]);

  const loadMoreData = () => {
    setKeepPreviousData(true);
    fetchNextPage();
    setKeepPreviousData(false);
  };

  const location = useLocation();
  const [initialPath, setInitialPath] = useState<string>(location.pathname);

  const [selectedDateRange, setSelectedDateRange] = useState<[Moment, Moment]>(dateRange);

  useEffect(() => {
    if (initialPath !== location.pathname) {
      resetFilters();
      setInitialPath(location.pathname);
    }
  }, [initialPath, location.pathname, resetFilters]);

  const [columns, setColumns] = useState<ColumnsOptionType[]>([
    {
      label: 'Status',
      columnKey: 'status',
      checked: 'on',
      isGroupLabel: false,
    },
    {
      label: 'Validation Parameters',
      columnKey: 'validationParameters',
      checked: 'on',
      isGroupLabel: false,
    },
    {
      label: 'Feature View',
      columnKey: 'featureView',
      checked: 'on',
      isGroupLabel: false,
    },
    {
      label: 'Details',
      columnKey: 'details',
      checked: 'on',
      isGroupLabel: false,
    },
    {
      label: 'Completed At',
      columnKey: 'completedAt',
      checked: 'on',
      isGroupLabel: false,
    },
  ]);

  let activeFilterCount = 0;
  if (filterState.featureViews.length > 0) {
    activeFilterCount += 1;
  }
  if (filterState.statuses.length > 0) {
    activeFilterCount += 1;
  }

  return (
    <ValidationsContext.Provider
      value={{
        activeFilterCount: activeFilterCount,
        allFeatureViews: allFeatureViews,
        allExpectations: [],
        dateRange: selectedDateRange,
        expectationOptions: expectationsOptions,
        filterState: filterState,
        data: (data as { pages: ValidationRecord[] })?.pages ?? [],
        isLoading: isLoading,
        isError: isError,
        filtersAreActive: filtersAreActive,
        isReady: isReady,
        updateFilters: updateFilters,
        resetFilters: resetFilters,
        isFetching: isFetching,
        isFetchingNextPage: isFetchingNextPage,
        loadMore: loadMoreData,
        hasMore: hasNextPage ?? false,
        workspace: params.workspace ?? '',
        wasEndDatePassedViaParameters: wasEndDatePassedViaParameters,
        columns: columns,
        setColumns: setColumns,
        setDateRange: setSelectedDateRange,
      }}
    >
      {children}
    </ValidationsContext.Provider>
  );
};
