import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Handle, Position } from '@xyflow/react';

interface DataFlowHandleProps {
  position: Position;
  top: string;
  left?: string;
}

// TODO REMOVE ABSOLUTE POSITION TO GET INITIALIZATION WORKING
// BREAKS SVG HANDLES
const Wrapper = styled.div<{ position: Position; left?: string; top: string }>`
  position: absolute;
  top: ${({ top }) => top};
  width: 16px;
  height: 16px;
  ${({ position }) => position === Position.Left && `left: -8px;`};
  ${({ position }) => position === Position.Right && `right: -8px;`};
  ${({ left }) => left && `left: ${left}`};
`;

const DataFlowHandle: FC<DataFlowHandleProps> = ({ position, top, left }) => {
  const handleType = position === Position.Left ? 'target' : 'source';

  const ReactFlowHandleWrapper = (
    <div style={{ position: 'absolute', top: '0px' }}>
      <Handle isConnectable={false} type={handleType} position={position} style={{ opacity: 0 }} />
    </div>
  ); // Including the default ReactFlow Handle to allow edges to connect, but setting opacity to 0 because we have a custom display type

  return (
    <Wrapper position={position} left={left} top={top}>
      {ReactFlowHandleWrapper}
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ overflow: 'visible' }}
      >
        <g transform={'translate(0,-8)'}>
          <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" fill="white" />
          <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" stroke="#E2E4E9" />
          <circle cx="8" cy="8" r="3.5" fill="white" stroke="#E2E4E9" />
        </g>
      </svg>
    </Wrapper>
  );
};

export default DataFlowHandle;
