import styled from '@emotion/styled';
import React, { FC } from 'react';
import { useTectonTheme } from '@tecton/ComponentRedesign';
import { OfflineStoreNode, OnlineStoreNode } from './dataFlowTypes';
import { StoreIconWrapper } from './VisualPrimitives/CardTypography';

interface DataFlowIndividualStoreNodeProps {
  storeType: 'online' | 'offline';
  data: OnlineStoreNode | OfflineStoreNode;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  onClick: (event: React.MouseEvent) => void;
  hasFocus: boolean;
  isFaded: boolean;
}

export const OfflineStoreIcon = () => {
  const { theme } = useTectonTheme();

  const stroke = theme.colors.accent;
  return (
    <svg width={34} height={34}>
      {' '}
      <rect width={34} height={34} rx={8} ry={8} fill={theme.FCOTintColors.transformation} />
      <g transform="translate(4.5,4.5) scale(0.75)">
        <path
          d={'M 4 7 L 4 19 A 12 6 0 0 0 28 19 L 28 7 A 12 6 0 0 0 4 7 Z'}
          fill={'none'}
          stroke={stroke}
          strokeWidth="2"
        />
        <path
          d={'M 4 7 L 4 25 A 12 6 0 0 0 28 25 L 28 7 A 12 6 0 0 0 4 7 Z'}
          fill={'none'}
          stroke={stroke}
          strokeWidth="2"
        />
        <path d={'M 4 13 A 12 6 0 0 0 28 13 M 4 19 A 12 6 0 0 0 28 19'} fill={'none'} stroke={stroke} strokeWidth="2" />
      </g>
    </svg>
  );
};

export const OnlineStoreIcon = () => {
  const { theme } = useTectonTheme();

  const stroke = theme.colors.accent;
  return (
    <svg width={34} height={34}>
      <rect width={34} height={34} rx={8} ry={8} fill={theme.FCOTintColors.transformation} />
      <g transform="translate(4.5,7.5) scale(0.75)">
        <path
          d={'M 4 7 L 4 19 A 12 6 0 0 0 28 19 L 28 7 A 12 6 0 0 0 4 7 Z'}
          fill={'none'}
          stroke={stroke}
          strokeWidth="2"
        />
        <path d={'M 4 7 A 12 6 0 0 0 28 7 A 12 6 0 0 0 4 7 Z'} fill={'none'} stroke={stroke} strokeWidth="2" />
      </g>
    </svg>
  );
};

const TypeDisplay = styled.div`
  color: ${({ theme }) => theme.colors.subduedText};
  font-size: 9px;
  font-style: normal;
  font-weight: ${({ theme }) => theme.font.weight.regular};
  line-height: 15px; /* 166.667% */
  text-transform: uppercase;
`;

const Wrapper = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const DataFlowIndividualStoreNode: FC<DataFlowIndividualStoreNodeProps> = ({
  storeType,
  onMouseEnter,
  onMouseLeave,
  onClick,
  hasFocus,
  isFaded,
}) => {
  const label = storeType === 'offline' ? 'Offline Store' : 'Online Store';
  const icon = storeType === 'offline' ? <OfflineStoreIcon /> : <OnlineStoreIcon />;

  return (
    <Wrapper>
      <StoreIconWrapper
        hasFocus={hasFocus}
        isFaded={isFaded}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={onClick}
      >
        <div>{icon}</div>
        <TypeDisplay>{label}</TypeDisplay>
      </StoreIconWrapper>
    </Wrapper>
  );
};

export default DataFlowIndividualStoreNode;
