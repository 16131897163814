import React from 'react';
import { DataFlowSidePanel, DescriptionSection } from './StyledPanelComponents';
import UpstreamDownstreamTable from './UpstreamDownstreamTable';
import { RequestDataSourceDetailsPanelData } from './detailsPanelQueryTypes';
import OnDemandInputSchemaSection from './OnDemandInputSchemaSection';
import { NodeClickCallbackCreator } from './detailsPanelTypes';

const RequestDataSourceContextPanel = ({
  id,
  data,
  zoomToNode,
  detailsPanelClearAll,
  onNodeClick,
}: {
  id: string;
  data: RequestDataSourceDetailsPanelData;
  zoomToNode?: () => void;
  detailsPanelClearAll: () => void;
  onNodeClick: NodeClickCallbackCreator;
}) => {
  return (
    <DataFlowSidePanel
      name={'Request Data Source'}
      prehead={`Data Source`}
      nodeId={id}
      detailsPanelClearAll={detailsPanelClearAll}
      zoomToNode={zoomToNode}
    >
      {data && (
        <>
          <DescriptionSection description={`Request Data Source for ${data.featureServiceName}.`} />
          <OnDemandInputSchemaSection schemaList={data.schemaList} />
          <UpstreamDownstreamTable
            onNodeClick={onNodeClick}
            upstreamNodes={[]}
            downstreamNodes={data.downstreamNodes}
          />
        </>
      )}
    </DataFlowSidePanel>
  );
};

export default RequestDataSourceContextPanel;
