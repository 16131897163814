import { Outlet, useMatch, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { Routes } from '../../../core/routes';
import { getPermissionRoutes } from './permissions-util';

import { Breadcrumbs, HeaderLayout, LinkTextWrapper, Tabs, ViewLayout } from '@tecton/ComponentRedesign';
import { useGetPermissions } from '../../../api/queries/aclGraphql';
import { useUserSettings } from '../../context/UserSettingsContext';
import WorkspaceRootBreadcrumb from '../WorkspaceRootBreadcrumb';

const PermissionsOutlet = () => {
  const navigation = useNavigate();

  const { workspaceDetails, workspace } = useUserSettings();
  const { data } = useGetPermissions(workspace ?? '');

  const indexRoute = {
    pathName: 'Users',
    path: `${Routes.workspaceHome}/configurations/${Routes.accessUsers}`,
    isMatch: !!useMatch({
      path: `${Routes.workspaceHome}/configurations/${Routes.configurationAccessUsers}`,
      end: false,
    }),
    count: data?.users.length ?? 0,
  };

  const pages = [
    indexRoute,
    {
      pathName: 'Service Accounts',
      path: `${Routes.workspaceHome}/configurations/${Routes.accessServiceAccounts}`,
      isMatch: !!useMatch({
        path: `${Routes.workspaceHome}/configurations/${Routes.accessServiceAccounts}`,
        end: false,
      }),
      count: data?.serviceAccounts.length ?? 0,
    },
    {
      pathName: 'Groups',
      path: `${Routes.workspaceHome}/configurations/${Routes.accessGroups}`,
      isMatch: !!useMatch({
        path: `${Routes.workspaceHome}/configurations/${Routes.accessGroups}`,
        end: false,
      }),
      count: data?.groups.length ?? 0,
    },
  ];

  const selectedPage = pages.find((page) => page.isMatch) ?? indexRoute;
  const breadcrumbs = (
    <Breadcrumbs
      workspace={<WorkspaceRootBreadcrumb workspace={workspaceDetails} />}
      crumbs={[
        {
          label: (
            <LinkTextWrapper>
              <Link to={indexRoute.path.replaceAll(':workspace', workspaceDetails?.name ?? '')}>Permissions</Link>{' '}
            </LinkTextWrapper>
          ),
        },
        { label: selectedPage?.pathName },
      ]}
    />
  );

  const tabs = (
    <Tabs
      selectedTab={`${selectedPage.pathName}`}
      tabsWithCount={pages?.map((page) => {
        return { label: page.pathName, count: page.count };
      })}
      setSelectedTab={(tab: string) => {
        const routes = getPermissionRoutes();
        navigation(routes[tab]);
      }}
    />
  );
  const header = <HeaderLayout breadcrumbs={breadcrumbs} tabs={tabs} />;
  return <ViewLayout header={header} body={<Outlet />} />;
};

export default PermissionsOutlet;
