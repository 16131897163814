import styled from '@emotion/styled';
import React, { FC, useContext } from 'react';
import { FlexGroup, Toast, ToastContext, ToastProps } from '.';

const Wrapper = styled.div`
  position: fixed;
  bottom: ${({ theme }) => `${theme.v1.size['6']}px`};
  right: ${({ theme }) => `${theme.v1.size['6']}px`};
  width: auto;
  height: auto;
`;

const ToastStack: FC = () => {
  const { toasts } = useContext(ToastContext);

  return (
    <Wrapper>
      <FlexGroup direction="column" alignItems="flex-end" justifyContent="flex-start" gapSize="3" padding="0">
        {toasts.map((props: ToastProps) => {
          return <Toast {...props} />;
        })}
      </FlexGroup>
    </Wrapper>
  );
};

export default ToastStack;
