import React, { MouseEvent, useRef } from 'react';
import { Criteria } from '@tecton';
import { Table, Button, TableLoadMoreFlex, useTableRowExpansionState, EmptyPrompt } from '@tecton/ComponentRedesign';
import JobsRowExpansion from './JobsRowExpansion';
import { useGetJobsColumns } from './jobsConfig';
import { JobsTableColumnKeys } from './JobsUtils';
import { Job } from '../../../api/gql/graphql';
import { Pagination } from '../../../api/gql/graphql';
import { Id } from '../../../types/tecton_proto/common/id';
import _camelcase from 'lodash/camelCase';

type SortObjectType = {
  field: string;
  direction: 'asc' | 'desc';
};
interface SortingProps {
  sort: SortObjectType;
}

export const JobsTable = ({
  displayedColumns,
  items,
  pagination,
  onChange,
  workspaceFilterCallback,
  fvFilterCallback,
  isLoading,
  isFetching,
  error,
  isFetchingNextPage,
  hasMore = false,
  loadMore,
  disableSorting,
  setShowCancelModal,
  setShowRetryModal,
  setShowOverwriteModal,
  setCurrentJobAction,
}: {
  displayedColumns?: JobsTableColumnKeys[];
  items: Job[];
  totalItemCount: number;
  pagination?: Pagination;
  isFetching?: boolean;
  isFetchingNextPage?: boolean;
  isLoading?: boolean;
  onChange: (s: Criteria<Job>) => void;
  loadMore: () => void;
  error: string | undefined;
  hasMore?: boolean;
  disableSorting?: boolean;
  workspaceFilterCallback: (workspace: string) => void;
  fvFilterCallback: (fv: string) => void;
  setShowCancelModal: () => void;
  setShowRetryModal: () => void;
  setShowOverwriteModal: () => void;
  setCurrentJobAction: (jobId: Id) => void;
}) => {
  const field = pagination?.sortKey || 'lastTaskStateChanged';
  const direction = pagination?.sortDirection === 'SORT_ASC' ? 'asc' : 'desc';
  const sorting: SortingProps = {
    sort: {
      field,
      direction,
    },
  };

  const { displayedColumnObjects } = useGetJobsColumns({
    displayedColumns,
    workspaceFilterCallback,
    fvFilterCallback,
    disableSorting,
    setShowCancelModal,
    setShowOverwriteModal,
    setShowRetryModal,
    setCurrentJobAction,
  });

  const wrapperRef = useRef<HTMLDivElement>(null);
  const { itemIdToExpandedRowMap, toggleRowExpansion } = useTableRowExpansionState(
    (row: Job) => {
      return row.id ?? '';
    },
    (row: Job) => {
      return <JobsRowExpansion job={row} wrapperRef={wrapperRef} />;
    }
  );

  return (
    <div ref={wrapperRef}>
      <Table
        layout="auto"
        items={items}
        columns={displayedColumnObjects}
        emptyPrompt={<EmptyPrompt title="No Jobs Available" variant="search" body={''} />}
        error={error}
        onChange={onChange}
        sorting={sorting}
        loading={(isLoading || isFetching) && !isFetchingNextPage}
        fetching={isFetching || isFetchingNextPage}
        itemIdToExpandedRowMap={itemIdToExpandedRowMap}
        scrollable
        rowProps={(row) => {
          return {
            onClick: (e: MouseEvent) => {
              if (!e.defaultPrevented) {
                toggleRowExpansion(row);
              }
            },
          };
        }}
        isExpandable
        itemId={'id'}
      />
      {!!loadMore && hasMore && !((isLoading || isFetching) && !isFetchingNextPage) && (
        <TableLoadMoreFlex>
          <Button label={'Load More'} variant={'emptyAction'} onClick={loadMore} isLoading={isLoading || isFetching} />
        </TableLoadMoreFlex>
      )}
    </div>
  );
};

export default JobsTable;
