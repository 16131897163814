import { TimeComboOptionType } from '@tecton/ComponentRedesign/lib/TimeComboSelector';
import moment, { Moment } from 'moment-timezone';
import { TimeRangeType } from '../shared/GraphComboTimeRangeDropdown';

export enum DateRangeUnitEnum {
  millisecond = 'millisecond',
  second = 'second',
  minute = 'minute',
  hour = 'hour',
  day = 'day',
  quarter = 'quarter',
}

export type MonitoringDateRangeOption = {
  label: string;
  startTime: () => number;
  endTime: () => number;
  step: number;
  unit: DateRangeUnitEnum;
};

export const dropdownOptions: MonitoringDateRangeOption[] = [
  {
    label: 'Custom',
    startTime: () => moment().local().subtract(5, `minutes`).toDate().valueOf(),
    endTime: () => moment().local().toDate().valueOf(),
    step: 30,
    unit: DateRangeUnitEnum.second,
  },
  {
    label: 'Last 5 minutes',
    startTime: () => moment().local().subtract(5, `minutes`).toDate().valueOf(),
    endTime: () => moment().local().toDate().valueOf(),
    step: 30,
    unit: DateRangeUnitEnum.second,
  },
  {
    label: 'Last 15 minutes',
    startTime: () => {
      return moment().local().subtract(15, `minutes`).toDate().valueOf();
    },
    endTime: () => moment().local().toDate().valueOf(),
    step: 30,
    unit: DateRangeUnitEnum.second,
  },
  {
    label: 'Last 30 minutes',
    startTime: () => moment().local().subtract(30, `minutes`).toDate().valueOf(),
    endTime: () => moment().local().toDate().valueOf(),
    step: 40,
    unit: DateRangeUnitEnum.second,
  },
  {
    label: 'Last 1 hour',
    startTime: () => moment().local().subtract(1, `hours`).toDate().valueOf(),
    endTime: () => moment().local().toDate().valueOf(),
    step: 60,
    unit: DateRangeUnitEnum.second,
  },
  {
    label: 'Last 3 hours',
    startTime: () => moment().local().subtract(3, `hours`).toDate().valueOf(),
    endTime: () => moment().local().toDate().valueOf(),
    step: 240,
    unit: DateRangeUnitEnum.second,
  },
  {
    label: 'Last 6 hours',
    startTime: () => moment().local().subtract(6, `hours`).toDate().valueOf(),
    endTime: () => moment().local().toDate().valueOf(),
    step: 600,
    unit: DateRangeUnitEnum.minute,
  },
  {
    label: 'Last 12 hours',
    startTime: () => moment().local().subtract(12, `hours`).toDate().valueOf(),
    endTime: () => moment().local().toDate().valueOf(),
    step: 600,
    unit: DateRangeUnitEnum.minute,
  },
  {
    label: 'Last 24 hours',
    startTime: () => moment().local().subtract(24, `hours`).toDate().valueOf(),
    endTime: () => moment().local().toDate().valueOf(),
    step: 1800,
    unit: DateRangeUnitEnum.minute,
  },
  {
    label: 'Last 2 days',
    startTime: () => moment().local().subtract(2, `days`).toDate().valueOf(),
    endTime: () => moment().local().toDate().valueOf(),
    step: 1740,
    unit: DateRangeUnitEnum.hour,
  },
  {
    label: 'Last 3 days',
    startTime: () => moment().local().subtract(3, `days`).toDate().valueOf(),
    endTime: () => moment().local().toDate().valueOf(),
    step: 2610,
    unit: DateRangeUnitEnum.hour,
  },
  {
    label: 'Last 7 days',
    startTime: () => moment().local().subtract(7, `days`).toDate().valueOf(),
    endTime: () => moment().local().toDate().valueOf(),
    step: 14400,
    unit: DateRangeUnitEnum.hour,
  },
  {
    label: 'Last 30 days',
    startTime: () => moment().local().subtract(30, `days`).toDate().valueOf(),
    endTime: () => moment().local().toDate().valueOf(),
    step: 27000,
    unit: DateRangeUnitEnum.day,
  },
  {
    label: 'Last 60 days',
    startTime: () => moment().local().subtract(60, `days`).toDate().valueOf(),
    endTime: () => moment().local().toDate().valueOf(),
    step: 27000,
    unit: DateRangeUnitEnum.day,
  },
  {
    label: 'Previous week',
    startTime: () => moment().local().subtract(1, `weeks`).toDate().valueOf(),
    endTime: () => moment().local().toDate().valueOf(),
    step: 14400,
    unit: DateRangeUnitEnum.hour,
  },
  {
    label: 'Previous 2 weeks',
    startTime: () => moment().local().subtract(2, `weeks`).toDate().valueOf(),
    endTime: () => moment().local().toDate().valueOf(),
    step: 14400,
    unit: DateRangeUnitEnum.hour,
  },
  {
    label: 'Previous Month',
    startTime: () => moment().local().subtract(1, `months`).toDate().valueOf(),
    endTime: () => moment().local().toDate().valueOf(),
    step: 27000,
    unit: DateRangeUnitEnum.day,
  },
  {
    label: 'Previous 2 Months',
    startTime: () => moment().local().subtract(2, `months`).toDate().valueOf(),
    endTime: () => moment().local().toDate().valueOf(),
    step: 27000,
    unit: DateRangeUnitEnum.day,
  },
  {
    label: 'Previous 6 Months',
    startTime: () => moment().local().subtract(6, `months`).toDate().valueOf(),
    endTime: () => moment().local().toDate().valueOf(),
    step: 172800,
    unit: DateRangeUnitEnum.day,
  },
  {
    label: 'Yesterday',
    startTime: () => moment().local().subtract(1, `days`).startOf('day').toDate().valueOf(),
    endTime: () => moment().local().subtract(1, `days`).endOf('day').toDate().valueOf(),
    step: 1224,
    unit: DateRangeUnitEnum.minute,
  },
];

// A Map so I can use the legacy values
export const dropDownOptionsMap: Record<TimeComboOptionType, string> = {
  custom: 'Custom',
  '5Minutes': 'Last 5 minutes',
  '15Minutes': 'Last 15 minutes',
  '30Minutes': 'Last 30 minutes',
  '1Hour': 'Last 1 hour',
  '6Hours': 'Last 6 hours',
  '12Hours': 'Last 12 hours',
  '1Day': 'Last 24 hours',
  '2Days': 'Last 2 days',
  '3Days': 'Last 3 days',
  '1Week': 'Previous week',
  '2Weeks': 'Previous 2 weeks',
  '1Month': 'Previous Month',
  '2Months': 'Previous 2 Months',
  '6Months': 'Previous 6 Months',
};

export const getMonitoringDateRangeByTimeComboOption = (redesignValue: TimeComboOptionType): MonitoringDateRange => {
  const mappedValue = dropDownOptionsMap[redesignValue];

  const monitoringDateRange = dropdownOptions?.find((option) => {
    return option.label === mappedValue;
  });

  const dateRange = monitoringDateRange ?? dropdownOptions[0];
  return getDateRangeForMonitoringGraph(dateRange);
};

export type MonitoringDateRange = {
  /*
  Why did we make startTime and endTime an function?
  When we set refetchInterval(REFETCH_INTERVAL), a relative time
  is always given (last 5 min, last 1 day, etc...). On the Metadata service side, we will always get the rolling(last 5 min, last day, etc...) time.
*/
  startTime: () => number;
  endTime: () => number;
  step: number;
  /*
  RequestId is for generating react-query.
  Basically we need to generate an ID instead of calling the startTime and endTime function
  in query.ts file. The ID needs to be generate here so that it doesn't change every time react-query
  tries to cache the request. Check the comment in the query.ts file.

  src/feature/query.ts -> usePrometheus
*/
  requestId: string;
  unit: DateRangeUnitEnum;
};

export const getDateRangeForMonitoringGraph = (dateRangeOption: MonitoringDateRangeOption): MonitoringDateRange => {
  const { startTime, endTime, step } = dateRangeOption;

  // RequestId is generated here.
  return {
    startTime: startTime,
    endTime: endTime,
    step: step,
    requestId: `${startTime()}${endTime()}`,
    unit: dateRangeOption.unit,
  };
};

export const getDefaultMonitoringDateRange = (): MonitoringDateRange => {
  const startTime = () => moment().local().subtract(24, `hours`).startOf('hour').toDate().valueOf();
  const endTime = () => moment().local().startOf('hour').toDate().valueOf();

  return {
    startTime: startTime,
    endTime: endTime,
    requestId: `${startTime()}${endTime()}`,
    step: 1800,
    unit: DateRangeUnitEnum.second,
  };
};

export const getMonitoringDateRange = (timeRangeState: TimeRangeType): MonitoringDateRange => {
  const startTime = () => timeRangeState?.startDate?.getTime() ?? new Date().getTime();
  const endTime = () => timeRangeState?.endDate?.getTime() ?? new Date().getTime();
  const startMoment = moment(timeRangeState?.startDate);
  const endMoment = moment(timeRangeState?.endDate);

  const step = timeRangeState?.option === 'custom' ? calculateStep(startMoment, endMoment) : timeRangeState.step ?? 30;
  const unit =
    timeRangeState?.option === 'custom'
      ? calculateUnit(startMoment, endMoment)
      : timeRangeState.unit ?? DateRangeUnitEnum.second;

  return {
    startTime: startTime,
    endTime: endTime,
    requestId: `${startTime()}${endTime()}`,
    step,
    unit,
  };
};

export const calculateStep = (startTime: Moment, endTime: Moment): number => {
  const months = endTime.diff(startTime, 'months');
  const days = endTime.diff(startTime, 'days');
  const hours = endTime.diff(startTime, 'hours');

  const minutes = endTime.diff(startTime, 'minutes');

  if (months > 0) {
    return 43200;
  } else if (days > 0) {
    if (days > 7) {
      return 21600;
    } else if (days < 7 && days > 2) {
      return 14400;
    }
    return 3600;
  } else if (hours > 0) {
    return 120 * (24 - hours);
  } else if (minutes < 60) {
    return 60;
  }

  // default
  return 1224;
};

export const calculateUnit = (startTime: Moment, endTime: Moment): DateRangeUnitEnum => {
  const days = endTime.diff(startTime, 'days');
  const hours = endTime.diff(startTime, 'hours');
  const minutes = endTime.diff(startTime, 'minutes');
  const seconds = endTime.diff(startTime, 'seconds');

  if (days > 0) {
    return DateRangeUnitEnum.day;
  } else if (hours > 0) {
    return DateRangeUnitEnum.hour;
  } else if (minutes > 0) {
    return DateRangeUnitEnum.minute;
  } else if (seconds > 0) {
    return DateRangeUnitEnum.second;
  }

  return DateRangeUnitEnum.millisecond;
};
