import { FC, useState } from 'react';
import { FCOFields, FeatureViewFCO, FeatureViewFCOFields, FeatureViewFCOType } from '../../../core/types/fcoTypes';
import { MonitoringDateRange } from '../../monitoring/DateRange-Dropdown-util';

import DynamoDBP99Latency from './DynamoDBP99Latency';
import DynamoDBP99ResponseSize from './DynamoDBP99ResponseSize';
import DynamoDBP99RowCount from './DynamoDBP99RowCount';
import DynamoDBQPSPerTable from './DynamoDBQPSPerTable';

import { Card, EuiSelectableOption, FlexGrid, FlexGroup, FlexItem, Select } from '@tecton/ComponentRedesign';

import { ReactComponent as FeatureViewIcon } from '@svg/featureView.svg';
import { PipelineNodeTypes } from '../../../service/Pipeline';

interface OnlineStoreLatencyProps {
  relatedFvs: FeatureViewFCO[];
  monitoringDateRange: MonitoringDateRange;
}

export const getRealTimeFeatureViewLabel = (fv: FeatureViewFCO) => {
  const underlyingFeatureView = fv[FeatureViewFCOFields.PIPELINE]?.allLeafNodes?.find((node) => {
    return node?.nodeType === PipelineNodeTypes.FEATURE_VIEW;
  });

  return underlyingFeatureView?.node?.input_name ?? 'Unknown';
};

export const getMetricLabel = (
  result: any,
  relatedFvs: FeatureViewFCO[],
  label?: string,
  isAllFeatures?: FeatureViewFCO[]
) => {
  if (isAllFeatures ?? false) {
    const foundFV = relatedFvs?.find((fv) => {
      const rowLabel = result?.metric.table ?? '';
      if (fv[FeatureViewFCOFields.FEATURE_VIEW_TYPE] === FeatureViewFCOType.REALTIME) {
        const underlyingFeatureView = fv[FeatureViewFCOFields.PIPELINE]?.allLeafNodes?.find((node) => {
          return node?.nodeType === PipelineNodeTypes.FEATURE_VIEW;
        });
        return !!underlyingFeatureView;
      }
      return rowLabel?.indexOf(fv[FCOFields.ID]) > -1;
    });

    if (foundFV && foundFV[FeatureViewFCOFields.FEATURE_VIEW_TYPE] === FeatureViewFCOType.REALTIME) {
      return getRealTimeFeatureViewLabel(foundFV);
    }

    const labelText = `${label}: ${foundFV?.[FCOFields.NAME]}`;
    return labelText;
  }
  return `${label}:  ${result.metric.table}`;
};

const OnlineStoreLatency: FC<OnlineStoreLatencyProps> = ({ relatedFvs, monitoringDateRange }) => {
  const featureViewOptions =
    relatedFvs?.map((fv) => {
      return {
        label: fv[FCOFields.NAME] ?? '',
        data: {
          isAllFeatures: false,
          featureViewIds: [fv[FCOFields.ID]] ?? [],
        },
      };
    }) ?? [];

  const fvIds = relatedFvs.map((fv) => {
    return `${fv[FCOFields.ID]}`;
  });

  const defaultOption = {
    label: 'All Feature Views',
    data: {
      isAllFeatures: true,
      featureViewIds: fvIds,
    },
  };

  const defaultGraphType = {
    label: 'p99',
    data: {
      value: 99,
    },
  };

  // All Feature Views is default
  featureViewOptions.unshift(defaultOption);
  const graphTypeOptions = [
    ...[defaultGraphType],
    {
      label: 'p95',
      data: {
        value: 95,
      },
    },
    {
      label: 'p50',
      data: {
        value: 50,
      },
    },
  ];

  const [selectedOption, setSelectedOption] = useState<EuiSelectableOption<{ option?: FeatureViewFCO }>>(defaultOption);
  const [selectedGraphType, setSelectedGraphType] =
    useState<EuiSelectableOption<{ option?: string }>>(defaultGraphType);

  return (
    <>
      <FlexGroup direction="column" gutterSize="l">
        <FlexItem grow={false}>
          <FlexGroup direction="row" gutterSize="l">
            <FlexItem grow={false}>
              <Select
                options={featureViewOptions}
                label={selectedOption.label}
                icon={FeatureViewIcon}
                onChange={(selectedOptions: EuiSelectableOption[]) => {
                  if (selectedOptions) {
                    const foundChecked = selectedOptions?.find((option) => {
                      return option?.checked === 'on';
                    });
                    if (foundChecked) {
                      setSelectedOption(foundChecked);
                    }
                  }
                }}
              />
            </FlexItem>
            <FlexItem grow={false}>
              <Select
                options={graphTypeOptions}
                label={selectedGraphType.label}
                onChange={(selectedOptions: EuiSelectableOption[]) => {
                  if (selectedOption) {
                    const foundChecked = selectedOptions?.find((option) => {
                      return option?.checked === 'on';
                    });
                    if (foundChecked) {
                      setSelectedGraphType(foundChecked);
                    }
                  }
                }}
              />
            </FlexItem>
          </FlexGroup>
        </FlexItem>
        <FlexItem>
          <FlexGrid columns={2} direction="row">
            <FlexItem grow={false}>
              <Card title="DynamoDB Latency" noScroll>
                <DynamoDBP99Latency
                  selectedGraphType={selectedGraphType}
                  monitoringDateRange={monitoringDateRange}
                  selectedOption={selectedOption}
                  relatedFvs={relatedFvs}
                />
              </Card>
            </FlexItem>
            <FlexItem grow={false}>
              <Card title="DynamoDB response size" noScroll>
                <DynamoDBP99ResponseSize
                  monitoringDateRange={monitoringDateRange}
                  selectedGraphType={selectedGraphType}
                  selectedOption={selectedOption}
                  relatedFvs={relatedFvs}
                />
              </Card>
            </FlexItem>

            <FlexItem grow={false}>
              <Card title="DynamoDB QPS Per Feature View" noScroll>
                <DynamoDBQPSPerTable
                  monitoringDateRange={monitoringDateRange}
                  selectedOption={selectedOption}
                  relatedFvs={relatedFvs}
                />
              </Card>
            </FlexItem>

            <FlexItem grow={false}>
              <Card title="DynamoDB Row Count" noScroll>
                <DynamoDBP99RowCount
                  selectedGraphType={selectedGraphType}
                  monitoringDateRange={monitoringDateRange}
                  selectedOption={selectedOption}
                  relatedFvs={relatedFvs}
                />
              </Card>
            </FlexItem>
          </FlexGrid>
        </FlexItem>
      </FlexGroup>
    </>
  );
};

export default OnlineStoreLatency;
