import { useUserSettings } from '../../../components/context/UserSettingsContext';
import { useClusterAdminInfo } from '../query';

const usePrincipalInformation = () => {
  // Delete this file once we migrate to the new redesign.
  const clusterAdminInfoState = useClusterAdminInfo();
  const { allWorkspaces, hasNoWorkspaceAssigned } = useUserSettings();
  const isAdmin = clusterAdminInfoState?.data?.isAdmin;

  const principalAccessibleWorkspaces = allWorkspaces?.filter((workspace) => workspace.isAccessible);

  return {
    isAdmin, // Is the logged in user an Admin?
    hasZeroWorkspaceAccess: hasNoWorkspaceAssigned, // Does logged in user have not workspace assigned to it?
    principalAccessibleWorkspaces, // All workspaces the logged in user has access to
  };
};

export default usePrincipalInformation;
