import React, { FC, useState } from 'react';
import _compact from 'lodash/compact';

// generated
import { User } from '../../../api/gql/graphql';

// @tecton
import { ComboBox, EuiComboBoxOptionOption, FlexGroupWrapper, FlexItem } from '@tecton/ComponentRedesign';

interface AssignGroupProps {
  assignableUser: User[];
  onAssignUserConfirm: (user: User[]) => void;
}

const AssignUser: FC<AssignGroupProps> = ({ assignableUser, onAssignUserConfirm }) => {
  const [selectedOptions, setSelectedOptions] = useState<EuiComboBoxOptionOption<User>[] | undefined>();

  const options: EuiComboBoxOptionOption<User>[] =
    assignableUser?.map((user) => {
      return {
        label: user?.loginEmail ?? '',
        value: user,
      };
    }) ?? [];

  return (
    <>
      <FlexGroupWrapper gap="xl" direction="column">
        <FlexItem grow={true}>
          <ComboBox
            placeholder="Select a User"
            options={options}
            fullWidth={true}
            selectedOptions={selectedOptions}
            onChange={(selectedOptions: EuiComboBoxOptionOption<User>[]) => {
              setSelectedOptions(selectedOptions);
              const users = selectedOptions?.map((option) => option.value) ?? [];
              onAssignUserConfirm(_compact(users));
            }}
          />
        </FlexItem>
      </FlexGroupWrapper>
    </>
  );
};

export default AssignUser;
