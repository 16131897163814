import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { OnboardingSidebarState } from '../../utils/onboarding/onboarding-util';
import onboardingStateToRoutesMapping from './utils/onboardingStateToRouteMapping';

const OnboardingRootRedirectPage = () => {
  const navigate = useNavigate();
  const onBoardingState: OnboardingSidebarState[] | undefined = useSelector((state: any) => {
    return state?.onBoarding?.onBoardingStates;
  });

  useEffect(() => {
    if (onBoardingState) {
      const currentState = onBoardingState.find((step) => step.isCurrentState === true);

      if (currentState) {
        const route = onboardingStateToRoutesMapping(currentState);

        if (route) {
          navigate(route);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onBoardingState]);

  return <Outlet />;
};

export default OnboardingRootRedirectPage;
