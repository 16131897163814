import { DataSourceFCOType } from '../../../core/types/fcoTypes';
import edgeIdFn from './edgeIdFn';
import { getVisibleAnimationsOnAllEdgesGivenAnchors } from './highlight-utils';
import highlightDownstream from './highlightDownstream';
import { HighlightFunctionType, EdgeShouldBeSkippedFunctionSignature } from './highlightFunctionType';

const skipDependenciesThatCrossesTheStore: EdgeShouldBeSkippedFunctionSignature = (sourceNode, targetNode) => {
  if (targetNode.type === 'aggregation' || targetNode.type === 'odfv') {
    return true;
  }

  /**
   * Basically: don't trace the graph
   * past the store.
   */
  if (
    sourceNode.type === 'feature_view' &&
    sourceNode.isOfflineMaterializationEnabled &&
    targetNode.type === 'feature_service'
  ) {
    return true;
  }

  if (
    targetNode.type === 'model_inference' &&
    sourceNode.type === 'feature_service' &&
    sourceNode.isOnlineServingEnabled
  ) {
    return true;
  }

  return false;
};

const highlightRawBatchNode: HighlightFunctionType = (id, nodesMap, edgesList) => {
  const focusedNode = nodesMap[id];

  if (focusedNode.type !== 'raw_batch_node') {
    throw new Error(
      `This should not happen. We are in highlightRawBatchNode but the focused node's type is not raw_batch_node.`
    );
  }

  const hasDownstreamBatchDS = focusedNode.downstreamNodes.find((n) => {
    const dn = nodesMap[n];

    return dn.type === 'data_source' && dn.dataSourceType === DataSourceFCOType.BATCH;
  });

  /**
   * Data from the Raw Batch Node doesn't get written to
   * the online store unless it is part of a Batch FV
   */
  const hideOnlineStore = !hasDownstreamBatchDS;

  const { linkedIds, linkedEdges } = highlightDownstream(id, nodesMap, edgesList, {
    edgeShouldBeSkipped: skipDependenciesThatCrossesTheStore,
    hideOnlineStore,
  });

  edgesList.forEach((edge) => {
    if (edge.source === 'STORE' || edge.target === 'store-output') {
      linkedEdges.delete(edgeIdFn(edge));
    }
  });

  const anchors = new Set<string>(linkedIds);

  const animations = getVisibleAnimationsOnAllEdgesGivenAnchors(anchors, edgesList, linkedEdges, {
    showBatchMaterializationPath: true,
    showOfflineReadPath: true,
  });

  return {
    linkedIds,
    linkedEdges,
    animations,
  };
};

export default highlightRawBatchNode;
