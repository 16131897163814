import React, { FC, ReactNode } from 'react';
import { ContentVertical, FlexGroupWrapper, FlexItem, TitleVertical } from '@tecton/ComponentRedesign';

const TitleValueLayout: FC<{ title: string; valueContent: ReactNode }> = ({ title, valueContent }) => {
  return (
    <>
      <FlexGroupWrapper direction="column" gap="s">
        <FlexItem>
          <TitleVertical>{title}</TitleVertical>
        </FlexItem>
        <FlexItem>
          <ContentVertical>{valueContent}</ContentVertical>
        </FlexItem>
      </FlexGroupWrapper>
    </>
  );
};

export default TitleValueLayout;
