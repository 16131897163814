import { Breadcrumbs, ErrorBoundary, HeaderLayout, Tabs, ViewLayoutWithFlyoutPush } from '@tecton/ComponentRedesign';
import { FC, useState } from 'react';
import { Navigate, Route, Routes, useNavigate, useParams } from 'react-router';

import JobDetailMetadata from './JobDetailMetadata';
// types
import { FlyoutContentProps } from '@tecton/ComponentRedesign/lib/ViewLayoutWithFlyoutPush';
import JobDetailStages from './JobDetailStages';
import JobLogs from './JobLogs';
import { getJobDetailRouteName, getJobDetailRoutes, JobDetailRoutes } from './jobUtils';
import JobMetrics from './JobMetrics';
import { FeatureFlags, useUserSettings } from '../../context/UserSettingsContext';

export interface JobDetailsProp {
  updateFlyoutContent: (content?: FlyoutContentProps) => void;
}

const JobDetails: FC<JobDetailsProp> = ({ updateFlyoutContent }) => {
  return (
    <Routes>
      <Route>
        <Route index element={<Navigate to={'stages'} replace />} />
        <Route path={`stages`} element={<JobDetailStages updateFlyoutContent={updateFlyoutContent} />} />
        <Route
          path={`metadata`}
          element={
            <>
              <JobDetailMetadata />
            </>
          }
        />
        <Route
          path={`metrics`}
          element={
            <>
              <JobMetrics />
            </>
          }
        />
        <Route
          path={`logs`}
          element={
            <>
              <JobLogs />
            </>
          }
        />
      </Route>
    </Routes>
  );
};

const JobDetailsContainer = () => {
  const { jobId } = useParams();
  const navigation = useNavigate();
  const { featureEnabled } = useUserSettings();
  const canShowMetrics = featureEnabled(FeatureFlags.SHOW_RIFT_METRICS);
  const jobRoutes = getJobDetailRouteName(location.pathname);
  const [selectedTab, setSelectedTab] = useState<JobDetailRoutes>(jobRoutes);
  const [flyoutContent, setFlyoutContent] = useState<FlyoutContentProps | undefined>();

  const jobDetailRoutes = getJobDetailRoutes();
  const metricsRoute: JobDetailRoutes = 'Metrics';
  const routeNames = Object.keys(jobDetailRoutes).filter((route) => {
    if (route === metricsRoute) {
      return canShowMetrics;
    }
    return true;
  });

  const tabs = (
    <Tabs
      selectedTab={selectedTab}
      tabs={routeNames}
      setSelectedTab={(tab: JobDetailRoutes) => {
        navigation(jobDetailRoutes[tab]);
        setSelectedTab(tab);
        // hide the flyout when you change tabs
        setFlyoutContent(undefined);
      }}
    />
  );

  const breadCrumbs = <Breadcrumbs root={`Jobs`} crumbs={[{ label: jobId }]} />;
  const header = <HeaderLayout breadcrumbs={breadCrumbs} tabs={tabs} />;

  return (
    <ErrorBoundary>
      <ViewLayoutWithFlyoutPush
        header={header}
        body={
          <JobDetails
            updateFlyoutContent={(content) => {
              setFlyoutContent(content);
            }}
          />
        }
        flyoutContent={flyoutContent}
      />
    </ErrorBoundary>
  );
};

export default JobDetailsContainer;
