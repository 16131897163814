import React from 'react';
// import SchemaTable, { sampleSchema } from './SchemaTable';
import { Title } from '@tecton';
import { ContextPanelConfigurationList, DataFlowSidePanel, DescriptionSection } from './StyledPanelComponents';
import DetailsPanelAccordionSection from './DetailsPanelAccordionSection';
import UpstreamDownstreamTable from './UpstreamDownstreamTable';
import SparkSchemaTable from './SparkSchemaTable';
import { DataSourceDetailsPanelData } from './detailsPanelQueryTypes';
import { NodeClickCallbackCreator } from './detailsPanelTypes';
import { Loading } from '@tecton/ComponentRedesign';

const DataSourceContextPanel = ({
  id,
  name,
  url,
  description,
  detailsPanelClearAll,
  zoomToNode,
  onNodeClick,
  data,
}: {
  id: string;
  name: string;
  url: string;
  description?: string;
  detailsPanelClearAll: () => void;
  zoomToNode?: () => void;
  onNodeClick: NodeClickCallbackCreator;
  data?: DataSourceDetailsPanelData;
}) => {
  return (
    <DataFlowSidePanel
      name={name}
      prehead={`Data Source`}
      nodeId={id}
      url={url}
      detailsPanelClearAll={detailsPanelClearAll}
      zoomToNode={zoomToNode}
    >
      <DescriptionSection description={description || 'No Description'} />

      {!data && <Loading />}
      {data && (
        <>
          {data.batchConfig && (
            <DetailsPanelAccordionSection
              id="features-list"
              title={
                <Title size="xxs">
                  <h4>Batch Source: {data.batchConfig.type}</h4>
                </Title>
              }
            >
              <ContextPanelConfigurationList
                listItems={data.batchConfig.properties.map((entry) => {
                  return { title: entry.name, description: entry.value || 'n/a' };
                })}
              />
            </DetailsPanelAccordionSection>
          )}
          {data.streamConfig && (
            <DetailsPanelAccordionSection
              id="features-list"
              title={
                <Title size="xxs">
                  <h4>Stream Source: {data.streamConfig.type}</h4>
                </Title>
              }
            >
              <ContextPanelConfigurationList
                listItems={data.streamConfig.properties.map((entry) => {
                  return { title: entry.name, description: entry.value || 'n/a' };
                })}
              />
            </DetailsPanelAccordionSection>
          )}
          {data.sparkSchema && (
            <DetailsPanelAccordionSection
              id="features-list"
              title={
                <Title size="xxs">
                  <h4>Spark Schema</h4>
                </Title>
              }
            >
              <SparkSchemaTable sparkSchema={data.sparkSchema} />
            </DetailsPanelAccordionSection>
          )}
          <UpstreamDownstreamTable
            onNodeClick={onNodeClick}
            upstreamNodes={[]}
            downstreamNodes={data.downstreamNodes}
          />
        </>
      )}
    </DataFlowSidePanel>
  );
};

export default DataSourceContextPanel;
