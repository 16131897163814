import { copyToClipboard, FormInput, FlexItem, IconTip } from '@tecton/ComponentRedesign';
import styled from '@emotion/styled';
import { useState, type FC } from 'react';

type KeyInputProps = {
  label: string;
  value?: string;
  tooltipMessage: string;
};

const StyledButton = styled.button`
  align-items: center;
  bottom: ${({ theme }) => theme.v1.size['3']}px;
  display: flex;
  font-size: 0;
  justify-content: center;
  position: absolute;
  right: ${({ theme }) => theme.v1.size['3']}px;
`;

const StyledWrapper = styled(FlexItem)`
  position: relative;
  max-width: ${({ theme }) => theme.v1.size['80']}px;
`;

const TOOLTIP_DELAY = 1500;
const TOOLTIP_COPIED_MESSAGE = 'Copied!';

export const KeyInput: FC<KeyInputProps> = ({ label, value, tooltipMessage }) => {
  const [message, setMessage] = useState<string>(tooltipMessage);

  const handleCopy = () => {
    if (!value) return;

    copyToClipboard(value);
    setMessage(TOOLTIP_COPIED_MESSAGE);

    setTimeout(() => {
      setMessage(tooltipMessage);
    }, TOOLTIP_DELAY);
  };

  return (
    <StyledWrapper>
      <FormInput label={label} compressed value={value} />
      <StyledButton onClick={handleCopy}>
        <IconTip content={message} type={'copyClipboard'} color="text" />
      </StyledButton>
    </StyledWrapper>
  );
};
