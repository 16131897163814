// This is a copy of the old version
export const SPARK_STATUS = {
  HEALTHY: {
    variant: 'healthy',
    sparkClusterStatus: 'Healthy',
  },
  CREATING_CLUSTER: {
    variant: 'active',
    sparkClusterStatus: 'Creating new cluster',
  },
  WAITING_FOR_CLUSTER_TO_START: {
    variant: 'active',
    sparkClusterStatus: 'New cluster created. Waiting for cluster to start.',
  },
  UNKNOWN: {
    variant: 'failure',
    sparkClusterStatus: 'Unknown status',
  },
  NO_CLUSTER: {
    variant: 'failure',
    sparkClusterStatus: 'No cluster',
  },
  UNHEALTHY: {
    variant: 'failure',
    sparkClusterStatus: 'Unhealthy',
  },
};
