import edgeIdFn from './edgeIdFn';
import {
  getVisibleAnimationsOnAllEdgesGivenAnchors,
  mergeEdgeAnimationsRecords,
  VisibleAnimationRecord,
} from './highlight-utils';
import highlightDownstream from './highlightDownstream';
import { HighlightFunctionType } from './highlightFunctionType';
import highlightUpstream from './highlightUpstream';

const highlightODFV: HighlightFunctionType = (id, nodesMap, edgesList) => {
  const node = nodesMap[id];

  const upstream = highlightUpstream(id, nodesMap, edgesList);

  const internalNodes = new Set<string>();
  const internalEdges = new Set<string>();
  if (node.isAnchor) {
    Object.entries(nodesMap).forEach(([key, node]) => {
      if (node.type === 'transformation' || node.type === 'aggregation') {
        internalNodes.add(key);
      }
    });

    edgesList.forEach((edge) => {
      if (internalNodes.has(edge.source) || internalNodes.has(edge.target)) {
        internalEdges.add(edgeIdFn(edge));
      }
    });
  }

  const downstreamToStore = highlightDownstream(id, nodesMap, edgesList);

  const linkedIds = new Set([...upstream.linkedIds, ...downstreamToStore.linkedIds, ...internalNodes]);
  const linkedEdges = new Set([...upstream.linkedEdges, ...downstreamToStore.linkedEdges, ...internalEdges]);

  const animations: Record<string, VisibleAnimationRecord> = getVisibleAnimationsOnAllEdgesGivenAnchors(
    new Set<string>([id, ...downstreamToStore.linkedIds, ...upstream.linkedIds]),
    edgesList,
    linkedEdges
  );

  return {
    linkedIds,
    linkedEdges,
    animations,
  };
};

export default highlightODFV;
