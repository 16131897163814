import { format } from 'd3-format';
import { FC } from 'react';

import { MonitoringDateRange } from '../../monitoring/DateRange-Dropdown-util';

import { interval } from '../../../feature/feature-services/feature-service/component/ChartDefaultParams';
import { X_AXIS_LABEL } from '../../monitoring/GraphContainer';

// generated
import { FeatureViewFCO } from '../../../core/types/fcoTypes';

// @tecton
import { EuiSelectableOption } from '@tecton/ComponentRedesign';
import PrometheusMonitoring from '@tecton/ComponentRedesign/Charts/PrometheusMonitoring';
import { getTableQuery } from './DynamoDBP99Latency';
import { getMetricLabel } from './OnlineStoreLatency';

interface FeatureViewsBelowFreshnessThresholdProps {
  monitoringDateRange: MonitoringDateRange;
  selectedOption: EuiSelectableOption<{ option?: FeatureViewFCO }>;
  selectedGraphType: EuiSelectableOption<{ option?: string }>;
  relatedFvs: FeatureViewFCO[];
}

const DynamoDBP99RowCount: FC<FeatureViewsBelowFreshnessThresholdProps> = ({
  monitoringDateRange,
  selectedOption,
  selectedGraphType,
  relatedFvs,
}) => {
  const chartTitle = '';
  const yAxisLabel = 'Row Count';
  const featureViewIds = selectedOption?.data?.featureViewIds ?? [];
  const tableQuery = getTableQuery(featureViewIds, relatedFvs);

  const queries = [
    {
      label: `P${selectedGraphType?.data?.value}`,
      query: `histogram_quantile(0.${selectedGraphType?.data?.value}, sum(rate(dynamodb_rows_per_query_bucket_rollup_no_pod{tecton_cluster=~'.*',  aws_region=~'.*', table=~'${tableQuery}'}[${interval}])) by(table,le)) `,
    },
  ];

  const yTicksCallback = (value: any) => {
    const formatter = format('.2~f');
    return `${formatter(value)}`;
  };

  const toolTipLabelCallback = (context: any) => {
    const { y } = context.parsed;
    const formatter = format('.2~f');
    const { label } = context.dataset;
    const value = formatter(y);

    const labelText = `${label}: ${value}`;
    return labelText;
  };

  const getMetricLabelCallback = (result: any, label?: string) => {
    return getMetricLabel(result, relatedFvs, label, selectedOption?.data?.isAllFeatures);
  };

  const yOverride = {
    title: {
      display: true,
      text: yAxisLabel,
      font: {
        size: 12,
      },
    },
    min: 0,

    ticks: {
      callback: yTicksCallback,
    },
  };

  return (
    <>
      <PrometheusMonitoring
        chartTitle={chartTitle}
        monitoringDateRange={monitoringDateRange}
        queries={queries}
        xAxisLabel={X_AXIS_LABEL}
        yAxisLabel={yAxisLabel}
        yTicksCallback={yTicksCallback}
        yGrace={0}
        toolTipLabelCallback={toolTipLabelCallback}
        yOverride={yOverride}
        parseValue={parseFloat}
        getMetricLabelCallback={selectedOption?.data?.isAllFeatures ? getMetricLabelCallback : undefined}
        height={160}
      />
    </>
  );
};

export default DynamoDBP99RowCount;
