import React, { FC, useContext, useState } from 'react';
import { ValidationsContext } from './ValidationsContext';
import { FlexGroup, FlexItem, ColumnDisplayControls, ComboFilter } from '@tecton/ComponentRedesign';
import { ValidationsFilterContext, useGetValidationsFilterTypes } from './FiltersContext';
import moment from 'moment';
import TectonTimeComboSelector from '../../@tecton/ComponentRedesign/lib/TimeComboSelector';

const ValidationsControls: FC = () => {
  const context = useContext(ValidationsContext);

  const [isColumnsOpen, setIsColumnsOpen] = useState<boolean>(false);

  const { isOpen, setIsOpen, selectedFilter, setSelectedFilter } = useContext(ValidationsFilterContext);

  const { filterTypes } = useGetValidationsFilterTypes();

  return (
    <FlexGroup alignItems="center">
      <FlexItem grow={false}>
        <TectonTimeComboSelector
          dateRange={{
            startDate: moment(context.filterState.startDate),
            endDate: moment(context.filterState.endDate),
          }}
          setDateRange={(dateRange) => {
            context.updateFilters({
              ...context.filterState,
              startDate: dateRange.startDate.toDate(),
              endDate: dateRange.endDate.toDate(),
            });
          }}
          initialOption={'2Weeks'}
        />
      </FlexItem>
      <FlexItem grow={true}>
        <FlexGroup justifyContent="flexEnd" gutterSize="s">
          <FlexItem grow={false}>
            <ComboFilter
              buttonLabel={'Filters'}
              filterTypes={filterTypes}
              isOpen={isOpen}
              selectedFilter={selectedFilter}
              setIsOpen={setIsOpen}
              setSelectedFilter={setSelectedFilter}
              numberOfActiveFilters={context.activeFilterCount}
              clearAllFilterCallback={() => {
                context.resetFilters();
              }}
              resetActiveFilter={(key) => {
                context.updateFilters({
                  ...context.filterState,
                  [key]: [],
                });
              }}
            />
          </FlexItem>
          <FlexItem grow={false}>
            <ColumnDisplayControls
              isColumnControlsOpen={isColumnsOpen}
              setIsColumnControlsOpen={setIsColumnsOpen}
              columnOptions={context.columns}
              numberOfSelectedColumnsOptions={0}
              setColumnOptions={(newOptions) => {
                context.setColumns(newOptions);
              }}
            />
          </FlexItem>
        </FlexGroup>
      </FlexItem>
    </FlexGroup>
  );
};

export default ValidationsControls;
