/* eslint-disable prefer-rest-params */
import { Theme } from '@emotion/react';
import { ClickAway, SearchInput } from '@tecton/ComponentRedesign';
import _debounce from 'lodash/debounce';
import moment from 'moment';
import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { ReactComponent } from 'react-hotkeys';
import { HotkeysProvider } from 'react-hotkeys-hook';
import { useNavigate } from 'react-router';
import { useGlobalSearch } from '../../api/fcos';
import {
  FcoType,
  GlobalSearchResponse,
  MaterializationEnabledSearchFilter,
} from '../../types/tecton_proto/metadataservice/metadata_service';
import { getWorkspaceDetails, useUserSettings } from '../context/UserSettingsContext';

import { Workspace } from '../@tecton/ComponentRedesign/SharedComponentTypes';
import { TectonDateTimeFormat } from '../@tecton/ComponentRedesign/utils';

import styled from '@emotion/styled';
import { truncateDescription } from '../../api/fcoUtils/fcoUtils';
import { fcoTypeRecord } from '../../core/types/fcoTypes';
import { logEvent } from '../../utils/analytics-utils';
import { typeIconMap } from '../@tecton/ComponentRedesign/FilterableSortableTable/filterComponents';
import GlobalSearchModal from './GlobalSearchModal';
import { SearchResultGroupNames } from './SearchResultGroup';
import SearchResults from './SearchResults';

interface TectonSearchModalProps {
  setShowSearchModal: React.Dispatch<React.SetStateAction<boolean>>;
  workspace: Workspace;
  SearchRef: React.RefObject<HTMLElement>;
}

interface ComponentProps {
  children: React.ReactNode;
  workspace: Workspace;
  setShowSearchModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export type GlobalSearchFCOProps = {
  tabIndex: number;
  id: string;
  name: string;
  owner?: string;
  workspace?: Workspace;
  description?: string | undefined;
  lastUpdated?: string;
  materializationOffline: boolean;
  materializationOnline: boolean;
  tags?: { key: string; value: string }[];
  type: FcoType;
  group?: string;
};

export const hasMetCriteriaThreshold = (filterValue: string): boolean => {
  return !!(filterValue && filterValue.length > 2);
};

interface FcoDataType {
  icon: ReactComponent;
  typeUrl: string;
  themeColor: keyof Theme['FCOColors'];
}
// FCO Mapping
export const getFcoData = (type: FcoType): FcoDataType => {
  const fcoType = fcoTypeRecord[type];
  const icon = typeIconMap[fcoType];

  const fcoTypeMap: Record<FcoType, FcoDataType> = {
    [FcoType.FCO_TYPE_VIRTUAL_DATA_SOURCE]: {
      icon,
      typeUrl: 'data-sources',
      themeColor: 'source',
    },
    [FcoType.FCO_TYPE_TRANSFORMATION]: {
      icon,
      typeUrl: 'transformations',
      themeColor: 'transformation',
    },
    [FcoType.FCO_TYPE_FEATURE_VIEW]: {
      icon,
      typeUrl: 'features',
      themeColor: 'featureView',
    },
    [FcoType.FCO_TYPE_FEATURE_SERVICE]: {
      icon,
      typeUrl: 'feature-services',
      themeColor: 'featureService',
    },
    [FcoType.FCO_TYPE_ENTITY]: {
      icon,
      typeUrl: 'entities',
      themeColor: 'entity',
    },
    [FcoType.FCO_TYPE_UNSPECIFIED]: {
      icon,
      typeUrl: 'features',
      themeColor: 'featureView',
    },
  };

  return fcoTypeMap[type];
};

const SearchPlaceholder = styled.div<{ left: string; filterValue?: string; isDisabled?: boolean }>`
  position: absolute;
  left: ${({ left }) => left};
  top: 15px;
  pointer-events: none; /* Makes the overlay not clickable */
  color: ${({ theme, isDisabled }) => (isDisabled ? theme.colors.disabledText : theme.colors.subduedText)};
  font-weight: ${({ theme }) => theme.font.weight.medium};
  ${({ filterValue }) => (filterValue && filterValue.length > 0 ? 'display: none;' : '')}  }
`;

// FCO Search Input
const GlobalSearchInput = () => {
  const { setFilterValue, workspace, filterValue, itemRefs, navigateToFco, focusedFco, fcosLoading } =
    useSearchModalContext();

  const inputRef = useRef<HTMLInputElement>(null);

  const handleInputRef = useCallback(() => {
    // We don't debounce here since the input value never changes
    setFilterValue(inputRef?.current?.value);
  }, [setFilterValue]);

  // Input HotKey
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'ArrowDown') {
      event.preventDefault();
      if (itemRefs?.current[0]) {
        itemRefs.current[0].focus();
      }
    } else if (event.key === 'Enter') {
      event.preventDefault();

      if (focusedFco) {
        navigateToFco(focusedFco);
      }
    }
  };

  return (
    <>
      <SearchInput
        variant="large"
        isLoading={fcosLoading && hasMetCriteriaThreshold(filterValue ?? '')}
        autoFocus
        placeholder={``}
        disabled={!workspace?.name}
        value={filterValue || ''}
        onChange={handleInputRef}
        onKeyDown={handleKeyDown}
        inputRef={inputRef}
      />
      <SearchPlaceholder left="44px" isDisabled={false} filterValue={filterValue}>
        <>Search across all workspaces</>
      </SearchPlaceholder>
      <SearchPlaceholder left="248px" isDisabled={true} filterValue={filterValue}>
        <>by name, description, tag, owner or id</>
      </SearchPlaceholder>
    </>
  );
};

export type GlobalSearchResult = { groupName: SearchResultGroupNames; results: GlobalSearchFCOProps[] };

const emptySearchResults: GlobalSearchResult[] = [
  { groupName: 'Name', results: [] },
  { groupName: 'Description', results: [] },
  { groupName: 'Tag', results: [] },
  { groupName: 'Owner', results: [] },
  { groupName: 'ID', results: [] },
];

export const getGlobalSearchFCOProps = (globalSearchResult: GlobalSearchResult[]): GlobalSearchFCOProps[] => {
  return globalSearchResult?.flatMap((result) => result.results) ?? [];
};

// Context for search Modal
const GlobalSearchModalContext = React.createContext<{
  filterValue: string | undefined;
  setFilterValue: React.Dispatch<React.SetStateAction<string | undefined>>;
  workspace: Workspace;
  searchResults: GlobalSearchResult[];
  fcosLoading: boolean;
  fcosError: boolean;
  itemRefs: React.MutableRefObject<(HTMLAnchorElement | HTMLButtonElement | null)[]>;
  navigateToFco: (fco: GlobalSearchFCOProps) => void;
  focusedIndex: number;
  setFocusedIndex: React.Dispatch<React.SetStateAction<number>>;
  focusedFco: GlobalSearchFCOProps | undefined;
  setFocusedFco: React.Dispatch<React.SetStateAction<GlobalSearchFCOProps | undefined>>;
}>({
  filterValue: undefined,
  setFilterValue: () => {
    return;
  },
  workspace: { name: '', workspaceType: 'Live', isAccessible: false },
  searchResults: emptySearchResults,
  fcosLoading: false,
  fcosError: false,
  itemRefs: { current: [] },
  navigateToFco: () => {},
  focusedIndex: 0,
  setFocusedIndex: () => {},
  focusedFco: undefined,
  setFocusedFco: () => {},
});

const debouncedSearchCriteria = _debounce((callback) => {
  callback?.apply(this);
}, 250);

const SearchModalContextProvider: React.FC<ComponentProps> = ({ children, workspace, setShowSearchModal }) => {
  const [filterValue, setFilterValue] = useState<string | undefined>(undefined);
  const [searchCriteria, setSearchCriteria] = useState<string | undefined>(undefined);
  const [focusedIndex, setFocusedIndex] = useState(0);
  const [focusedFco, setFocusedFco] = useState<GlobalSearchFCOProps | undefined>(undefined);
  const { allWorkspaces } = useUserSettings();
  const itemRefs = useRef<(HTMLAnchorElement | HTMLButtonElement | null)[]>([]);
  const navigate = useNavigate();
  let requestStartTime = moment();
  let numberOfResults = 0;

  useEffect(() => {
    // debounce so we don't make a ton of calls
    debouncedSearchCriteria(() => {
      setSearchCriteria(filterValue);
    });
  }, [filterValue]);

  const {
    data: fcos,
    isLoading: fcosLoading,
    isError: fcosError,
  } = useGlobalSearch(searchCriteria, {
    enabled: hasMetCriteriaThreshold(filterValue ?? ''),
    select: (data: GlobalSearchResponse): GlobalSearchResult[] => {
      const nameResults: GlobalSearchFCOProps[] = [];
      const descriptionResults: GlobalSearchFCOProps[] = [];
      const tagResults: GlobalSearchFCOProps[] = [];
      const ownerResults: GlobalSearchFCOProps[] = [];
      const idResults: GlobalSearchFCOProps[] = [];

      requestStartTime = moment();
      numberOfResults = data?.results?.length ?? 0;

      data?.results?.forEach((result, index) => {
        const foundWorkspace = allWorkspaces?.find((workspace) => {
          return workspace.name === result?.fco_result?.workspace;
        });

        // Very cool util
        const description = truncateDescription(result?.fco_result?.description);

        const fco: GlobalSearchFCOProps = {
          tabIndex: index,
          id: result?.fco_result?.fco_id ?? '',
          name: result?.fco_result?.name ?? '',
          owner: result?.fco_result?.owner,
          workspace: getWorkspaceDetails(foundWorkspace),
          description,
          lastUpdated: TectonDateTimeFormat(moment(result?.fco_result?.last_updated)),
          materializationOffline:
            result.fco_result?.materialization_offline ===
            MaterializationEnabledSearchFilter.MATERIALIZATION_ENABLED_SEARCH_FILTER_ENABLED,
          materializationOnline:
            result.fco_result?.materialization_online ===
            MaterializationEnabledSearchFilter.MATERIALIZATION_ENABLED_SEARCH_FILTER_ENABLED,
          type: result?.fco_result?.fco_type ?? FcoType.FCO_TYPE_UNSPECIFIED,
          tags: Object.keys(result?.fco_result?.tags ?? [])?.map((key: string) => {
            const tag = result?.fco_result?.tags ?? {};
            return { key: key, value: tag[key] };
          }),
        };

        const tagFlat = Object.keys(result?.fco_result?.tags ?? [])
          ?.map((key: string) => {
            const tag = result?.fco_result?.tags ?? {};
            return `${key}:${tag[key]}`;
          })
          .join('');

        const isIncludedInNames = filterValue && result?.fco_result?.name?.includes(filterValue);

        // name
        if (isIncludedInNames) {
          fco.group = 'Name';
          nameResults.push(fco);
        }

        // description: If it exists in names, don't include it here
        if (!isIncludedInNames && filterValue && result?.fco_result?.description?.includes(filterValue)) {
          fco.group = 'Description';
          descriptionResults.push(fco);
        }

        // tag: // If it exists in names, don't include it here
        if (!isIncludedInNames && filterValue && tagFlat.includes(filterValue)) {
          fco.group = 'Tag';
          tagResults.push(fco);
        }

        // owner: // If it exists in names, don't include it here
        if (!isIncludedInNames && filterValue && result?.fco_result?.owner?.includes(filterValue)) {
          fco.group = 'Owner';
          ownerResults.push(fco);
        }

        // ID: // If it exists in names, don't include it here
        if (!isIncludedInNames && filterValue && result?.fco_result?.fco_id?.includes(filterValue)) {
          fco.group = 'ID';
          idResults.push(fco);
        }
      });

      const orderedResults = [...nameResults, ...descriptionResults, ...tagResults, ...ownerResults, ...idResults];

      //Update tab index so up and down navigation works correctly
      orderedResults.forEach((fco, index) => {
        fco.tabIndex = index;
      });

      const globalSearchResult: GlobalSearchResult[] = [
        { groupName: 'Name', results: nameResults },
        { groupName: 'Description', results: descriptionResults },
        { groupName: 'Tag', results: tagResults },
        { groupName: 'Owner', results: ownerResults },
        { groupName: 'ID', results: idResults },
      ];

      return globalSearchResult;
    },
  });

  useEffect(() => {
    logEvent('Search', '', {
      event: `Search results`,
      description: 'User triggers search and results are served',
      numberOfResults: `${numberOfResults}`,
      loadTime: `${moment().diff(requestStartTime, 'milliseconds')}`,
    });
    // reset the selected index when search text criteria changes
    setFocusedIndex(0);
  }, [filterValue]);

  const navigateToFco = (fco: GlobalSearchFCOProps) => {
    if (fco?.type && fco?.name) {
      const uri = `repo/${fco?.workspace?.name}/${getFcoData(fco.type).typeUrl}/${fco.name}/overview`;
      setShowSearchModal(false);
      logEvent('Search', '', {
        action: 'modal closed',
        description: 'User selected a search result.',
      });
      navigate(uri);
    }
  };

  return (
    <GlobalSearchModalContext.Provider
      value={{
        filterValue,
        setFilterValue,
        workspace,
        searchResults: fcos ?? emptySearchResults,
        fcosLoading,
        fcosError,
        itemRefs,
        navigateToFco,
        focusedIndex,
        setFocusedIndex,
        focusedFco,
        setFocusedFco,
      }}
    >
      {children}
    </GlobalSearchModalContext.Provider>
  );
};

export const useSearchModalContext = () => {
  const context = useContext(GlobalSearchModalContext);
  if (!context) {
    throw new Error('useSearchModalContext must be used within a SearchModalContextProvider');
  }

  return context;
};

const GlobalSearch: FC<TectonSearchModalProps> = ({ setShowSearchModal, workspace, SearchRef }) => {
  const ModalRef = useRef(null);

  const closeSearchModal = () => {
    logEvent('Search', '', {
      event: 'Search abandoned',
      exitSearch: 'clicked outside modal',
    });
    setShowSearchModal((showSearchModal: boolean) => {
      return showSearchModal ? false : true;
    });
  };

  return (
    <HotkeysProvider>
      <div ref={ModalRef}>
        <GlobalSearchModal
          ref={ModalRef}
          body={
            <>
              <SearchModalContextProvider workspace={workspace} setShowSearchModal={setShowSearchModal}>
                <ClickAway onClick={closeSearchModal} excludeRefs={[SearchRef, ModalRef]} capture={true}>
                  <GlobalSearchInput />
                  <SearchResults setShowSearchModal={setShowSearchModal} workspace={workspace.name ?? ''} />
                </ClickAway>
              </SearchModalContextProvider>
            </>
          }
          onCancel={() => {
            setShowSearchModal(false);
          }}
          onConfirm={() => {
            return;
          }}
        />
      </div>
    </HotkeysProvider>
  );
};

export default GlobalSearch;
