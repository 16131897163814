import styled from '@emotion/styled';
import { Panel } from '@tecton';
import React, { useContext } from 'react';
import { DetailsPanelContext } from './DetailsPanelFocusProvider';

import {
  DataSourceDetailsPanelData,
  FeatureServiceDetailsPanelData,
  FeatureViewDetailsPanelData,
  ODFVDetailsPanelData,
  RequestDataSourceDetailsPanelData,
  WorkspaceStoreDetailsPanelData,
} from './DetailsPanel/detailsPanelQueryTypes';

import AggregationDetailsPanel from './DetailsPanel/AggregationDetailsPanel';
import DataSourceDetailsPanel from './DetailsPanel/DataSourceDetailsPanel';
import FeatureServiceDetailsPanel from './DetailsPanel/FeatureServiceDetailsPanel';
import FeatureViewDetailsPanel from './DetailsPanel/FeatureViewDetailsPanel';
import ModelInferenceDetailsPanel from './DetailsPanel/ModelInferenceDetailsPanel';
import ModelTrainerDetailsPanel from './DetailsPanel/ModelTrainerDetailsPanel';
import ODFVDetailsPanel from './DetailsPanel/ODFVDetailsPanel';
import OfflineStoreDetailsPanel from './DetailsPanel/OfflineStoreDetailsPanel';
import OnlineStoreDetailsPanel from './DetailsPanel/OnlineStoreDetailsPanel';
import RawBatchSourceDetailsPanel from './DetailsPanel/RawBatchSourceDetailsPanel';
import RawStreamSourceDetailsPanel from './DetailsPanel/RawStreamSourceDetailsPanel';
import RequestDataSourceDetailsPanel from './DetailsPanel/RequestDataSourceDetailsPanel';
import TransformationDetailsPanel from './DetailsPanel/TransformationDetailsPanel';
import WorkspaceStoreDetailsPanel from './DetailsPanel/WorkspaceStoreDetailsPanel';

import { HotKeys } from 'react-hotkeys';
import HoverContent from './DetailsPanel/HoverContent';
import { DetailsPanelHeaderArea, DetailsPanelPrehead, DetailsPanelTitle } from './DetailsPanel/StyledPanelComponents';
import { NodeClickCallbackCreator } from './DetailsPanel/detailsPanelTypes';
import { DataFlowNode, FeatureViewNode, NodesMapType } from './dataFlowTypes';
import {
  useDataSourceDetailsPanelData,
  useFeatureServiceContextData,
  useFeatureViewDetailsPanelData,
  useODFVDetailsPanelData,
  useRequestDataSourceDetailsPanelData,
  useWorkspaceStoreDetailsPanelData,
} from './detailsPanelDataTranslation';
import { detailCardTypeAndNames } from './helpers';

const DetailsCard = styled.div<{ isActive?: boolean }>`
  position: absolute;
  top: 20px;
  right: 20px;
  width: 45em;
  max-width: 30%;
  opacity: ${(props) => (props.isActive ? 1 : 0)};
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out, height 0.3s ease-in-out;
  transition-delay: ${(props) => (props.isActive ? '0.2s' : '0s')};
  transform: ${(props) => (props.isActive ? 'translateY(0px) scale(1)' : 'translateY(-6px) scale(0.95)')};
`;

const RawBatchSourceContextContainer = ({
  id,
  name,
  sourceType,
  dataSourceId,
  detailsPanelClearAll,
  zoomToNode,
}: {
  id: string;
  name: string;
  sourceType: string;
  dataSourceId: string;
  detailsPanelClearAll: () => void;
  zoomToNode?: () => void;
}) => {
  const query = useDataSourceDetailsPanelData(dataSourceId);

  // const data = query.data as DataSourceDetailsPanelData;

  const data: DataSourceDetailsPanelData = {
    type: 'data_source',
    dataSourceType: 'batch',
    name: '',
    description: '',
    downstreamNodes: [],
  };

  return (
    <RawBatchSourceDetailsPanel
      id={id}
      name={name}
      sourceType={sourceType}
      data={data}
      detailsPanelClearAll={detailsPanelClearAll}
      zoomToNode={zoomToNode}
    />
  );
};

const RawStreamSourceContextContainer = ({
  id,
  name,
  sourceType,
  dataSourceId,
  detailsPanelClearAll,
  zoomToNode,
}: {
  id: string;
  name: string;
  sourceType: string;
  dataSourceId: string;
  detailsPanelClearAll: () => void;
  zoomToNode?: () => void;
}) => {
  const query = useDataSourceDetailsPanelData(dataSourceId);

  // const data = query.data as DataSourceDetailsPanelData;
  const data: DataSourceDetailsPanelData = {
    type: 'data_source',
    dataSourceType: 'batch',
    name: '',
    description: '',
    downstreamNodes: [],
  };

  return (
    <RawStreamSourceDetailsPanel
      id={id}
      name={name}
      sourceType={sourceType}
      data={data}
      detailsPanelClearAll={detailsPanelClearAll}
      zoomToNode={zoomToNode}
    />
  );
};

const DataSourceContextContainer = ({
  id,
  name,
  description,
  detailsPanelClearAll,
  zoomToNode,
  onNodeClick,
}: {
  id: string;
  name: string;
  description?: string;
  detailsPanelClearAll: () => void;
  zoomToNode?: () => void;
  onNodeClick: NodeClickCallbackCreator;
}) => {
  const query = useDataSourceDetailsPanelData(id);

  // const data = query.data as DataSourceDetailsPanelData;
  const data: DataSourceDetailsPanelData = {
    type: 'data_source',
    dataSourceType: 'batch',
    name: '',
    description: '',
    downstreamNodes: [],
  };

  return (
    <DataSourceDetailsPanel
      id={id}
      name={name}
      description={description}
      data={data}
      detailsPanelClearAll={detailsPanelClearAll}
      zoomToNode={zoomToNode}
      onNodeClick={onNodeClick}
      url={''}
    />
  );
};

const FeatureViewContextContainer = ({
  id,
  name,
  featureViewType,
  description,
  detailsPanelClearAll,
  zoomToNode,
  onNodeClick,
}: {
  id: string;
  name: string;
  featureViewType: FeatureViewNode['featureViewType'];
  description?: string;
  detailsPanelClearAll: () => void;
  zoomToNode?: () => void;
  onNodeClick: NodeClickCallbackCreator;
}) => {
  const query = useFeatureViewDetailsPanelData(id);

  // const data = query.data as FeatureViewDetailsPanelData;
  const data: FeatureViewDetailsPanelData = {
    type: 'feature_view',
    featureViewType: undefined,
    name: '',
    onlineMaterialization: false,
    offlineMaterialization: false,
    features: [],
    transformations: [],
    description: '',
    upstreamNodes: [],
    downstreamNodes: [],
  };

  return (
    <FeatureViewDetailsPanel
      id={id}
      name={name}
      featureViewType={featureViewType}
      description={description}
      data={data}
      url={''}
      detailsPanelClearAll={detailsPanelClearAll}
      zoomToNode={zoomToNode}
      onNodeClick={onNodeClick}
    />
  );
};

const FeatureServiceContextContainer = ({
  id,
  name,
  description,
  detailsPanelClearAll,
  zoomToNode,
  onNodeClick,
}: {
  id: string;
  name: string;
  description?: string;
  detailsPanelClearAll: () => void;
  zoomToNode?: () => void;
  onNodeClick: NodeClickCallbackCreator;
}) => {
  const query = useFeatureServiceContextData(id);

  // const data = query.data as FeatureServiceDetailsPanelData;
  const data: FeatureServiceDetailsPanelData = {
    type: 'feature_service',
    name: '',
    description: '',
    isOnlineServingEnabled: false,
    featureGroups: [],
    schemaList: [],
    upstreamNodes: [],
  };

  return (
    <FeatureServiceDetailsPanel
      id={id}
      name={name}
      description={description}
      data={data}
      url={''}
      detailsPanelClearAll={detailsPanelClearAll}
      zoomToNode={zoomToNode}
      onNodeClick={onNodeClick}
    />
  );
};

const RequestDataSourceContextContainer = ({
  id,
  detailsPanelClearAll,
  zoomToNode,
  onNodeClick,
}: {
  id: string;
  detailsPanelClearAll: () => void;
  zoomToNode?: () => void;
  onNodeClick: NodeClickCallbackCreator;
}) => {
  const query = useRequestDataSourceDetailsPanelData(id);

  // const data = query.data as RequestDataSourceDetailsPanelData;
  const data: RequestDataSourceDetailsPanelData = {
    type: 'request_data_source',
    featureServiceId: '',
    featureServiceName: '',
    schemaList: [],
    downstreamNodes: [],
  };

  return (
    <RequestDataSourceDetailsPanel
      id={id}
      data={data}
      detailsPanelClearAll={detailsPanelClearAll}
      zoomToNode={zoomToNode}
      onNodeClick={onNodeClick}
    />
  );
};

const ODFVContextContainer = ({
  id,
  name,
  description,
  detailsPanelClearAll,
  zoomToNode,
  onNodeClick,
}: {
  id: string;
  name: string;
  description?: string;
  detailsPanelClearAll: () => void;
  zoomToNode?: () => void;
  onNodeClick: NodeClickCallbackCreator;
}) => {
  const query = useODFVDetailsPanelData(id);

  // const data = query.data as ODFVDetailsPanelData;

  const data: ODFVDetailsPanelData = {
    type: 'odfv',
    name: '',
    schema: [],
    features: [],
    transformations: [],
    description: '',
    upstreamNodes: [],
    downstreamNodes: [],
  };

  return (
    <ODFVDetailsPanel
      id={id}
      name={name}
      description={description}
      data={data}
      url={''}
      detailsPanelClearAll={detailsPanelClearAll}
      zoomToNode={zoomToNode}
      onNodeClick={onNodeClick}
    />
  );
};

const WorkspaceStoreContextContainer = ({
  id,
  detailsPanelClearAll,
  zoomToNode,
}: {
  id: string;
  detailsPanelClearAll: () => void;
  zoomToNode?: () => void;
}) => {
  const query = useWorkspaceStoreDetailsPanelData();

  // const data = query.data as WorkspaceStoreDetailsPanelData;
  const data: WorkspaceStoreDetailsPanelData = {
    type: 'store',
    featureViews: [],
    featureServices: [],
    ODFVs: [],
  };
  return (
    <WorkspaceStoreDetailsPanel
      id={id}
      data={data}
      detailsPanelClearAll={detailsPanelClearAll}
      zoomToNode={zoomToNode}
    />
  );
};

const OnlineStoreContextContainer = ({
  id,
  detailsPanelClearAll,
  zoomToNode,
}: {
  id: string;
  detailsPanelClearAll: () => void;
  zoomToNode?: () => void;
}) => {
  const query = useWorkspaceStoreDetailsPanelData();

  // const data = query.data as WorkspaceStoreDetailsPanelData;

  const data: WorkspaceStoreDetailsPanelData = {
    type: 'store',
    featureViews: [],
    featureServices: [],
    ODFVs: [],
  };
  return (
    <OnlineStoreDetailsPanel id={id} data={data} detailsPanelClearAll={detailsPanelClearAll} zoomToNode={zoomToNode} />
  );
};

const OfflineStoreContextContainer = ({
  id,
  detailsPanelClearAll,
  zoomToNode,
}: {
  id: string;
  detailsPanelClearAll: () => void;
  zoomToNode?: () => void;
}) => {
  const query = useWorkspaceStoreDetailsPanelData();

  // const data = query.data as WorkspaceStoreDetailsPanelData;
  const data: WorkspaceStoreDetailsPanelData = {
    type: 'store',
    featureViews: [],
    featureServices: [],
    ODFVs: [],
  };

  return (
    <OfflineStoreDetailsPanel id={id} data={data} detailsPanelClearAll={detailsPanelClearAll} zoomToNode={zoomToNode} />
  );
};

const PanelSwitcher = ({ node }: { node: DataFlowNode }) => {
  const { detailsPanelClearAll, zoomToNode, detailsPanelClickNodeId } = useContext(DetailsPanelContext);

  const zoom = zoomToNode
    ? () => {
        zoomToNode(node.id);
      }
    : undefined;

  const onNodeClick = (nodeId: string) => {
    return () => {
      if (zoomToNode) {
        zoomToNode(nodeId);
      }

      detailsPanelClickNodeId(nodeId);
    };
  };

  switch (node.type) {
    case 'raw_batch_node':
      return (
        <RawBatchSourceContextContainer
          id={node.id}
          name={node.name}
          sourceType={node.sourceType}
          dataSourceId={node.dataSourceId}
          detailsPanelClearAll={detailsPanelClearAll}
          zoomToNode={zoom}
        />
      );

    case 'raw_stream_node':
      return (
        <RawStreamSourceContextContainer
          id={node.id}
          name={node.name}
          sourceType={node.sourceType}
          dataSourceId={node.dataSourceId}
          detailsPanelClearAll={detailsPanelClearAll}
          zoomToNode={zoom}
        />
      );

    case 'data_source':
      return (
        <DataSourceContextContainer
          id={node.id}
          name={node.name}
          description={node.description}
          detailsPanelClearAll={detailsPanelClearAll}
          zoomToNode={zoom}
          onNodeClick={onNodeClick}
        />
      );

    case 'feature_view':
      return (
        <FeatureViewContextContainer
          id={node.id}
          name={node.name}
          description={node.description}
          featureViewType={node.featureViewType}
          detailsPanelClearAll={detailsPanelClearAll}
          zoomToNode={zoom}
          onNodeClick={onNodeClick}
        />
      );

    case 'transformation':
      return (
        <TransformationDetailsPanel
          node={node}
          name={node.name}
          detailsPanelClearAll={detailsPanelClearAll}
          zoomToNode={zoom}
        />
      );

    case 'aggregation':
      return (
        <AggregationDetailsPanel
          node={node}
          name={node.name}
          detailsPanelClearAll={detailsPanelClearAll}
          zoomToNode={zoom}
        />
      );

    case 'feature_service':
      return (
        <FeatureServiceContextContainer
          id={node.id}
          name={node.name}
          description={node.description}
          detailsPanelClearAll={detailsPanelClearAll}
          zoomToNode={zoom}
          onNodeClick={onNodeClick}
        />
      );

    case 'request_data_source':
      return (
        <RequestDataSourceContextContainer
          id={node.id}
          detailsPanelClearAll={detailsPanelClearAll}
          zoomToNode={zoom}
          onNodeClick={onNodeClick}
        />
      );

    case 'odfv':
      return (
        <ODFVContextContainer
          id={node.id}
          name={node.name}
          description={node.description}
          detailsPanelClearAll={detailsPanelClearAll}
          zoomToNode={zoom}
          onNodeClick={onNodeClick}
        />
      );

    case 'store_wrapper':
      return (
        <WorkspaceStoreContextContainer id={node.id} detailsPanelClearAll={detailsPanelClearAll} zoomToNode={zoom} />
      );

    case 'online_store':
      return <OnlineStoreContextContainer id={node.id} detailsPanelClearAll={detailsPanelClearAll} zoomToNode={zoom} />;

    case 'offline_store':
      return (
        <OfflineStoreContextContainer id={node.id} detailsPanelClearAll={detailsPanelClearAll} zoomToNode={zoom} />
      );

    case 'model_trainer':
      return (
        <ModelTrainerDetailsPanel
          id={node.id}
          name={node.featureServiceName}
          detailsPanelClearAll={detailsPanelClearAll}
          zoomToNode={zoom}
        />
      );

    case 'model_inference':
      return (
        <ModelInferenceDetailsPanel
          id={node.id}
          name={node.featureServiceName}
          detailsPanelClearAll={detailsPanelClearAll}
          zoomToNode={zoom}
        />
      );

    default:
      throw new Error(`No Context Panel component defined for '${node.type}'.`);
  }
};

const DetailsPanel = ({ nodesMap }: { nodesMap: NodesMapType }) => {
  const { nodeId, focusType } = useContext(DetailsPanelContext);

  const isInternalStoreNode = nodeId === 'online-store' || nodeId === 'offline-store';
  const focusedNode = !nodeId ? null : isInternalStoreNode ? nodesMap['STORE'] : nodesMap[nodeId];

  const { name, type } = focusedNode ? detailCardTypeAndNames(focusedNode) : { name: '', type: '' };

  return (
    <DetailsCard isActive={!!nodeId}>
      {focusType === 'hover' && (
        <Panel paddingSize="none">
          <DetailsPanelHeaderArea>
            <DetailsPanelPrehead>{type}</DetailsPanelPrehead>
            <DetailsPanelTitle>
              <>{name}</>
            </DetailsPanelTitle>
            {focusedNode !== null && <HoverContent node={focusedNode} />}
          </DetailsPanelHeaderArea>
        </Panel>
      )}
      {focusType === 'clicked' && focusedNode !== null && <PanelSwitcher node={focusedNode} />}
    </DetailsCard>
  );
};

export const DetailsPanelHotkey = ({ children }: { children: React.ReactNode }) => {
  const { detailsPanelClearAll } = useContext(DetailsPanelContext);

  return (
    <HotKeys
      keyMap={{
        closePanel: 'esc',
      }}
      handlers={{
        closePanel: detailsPanelClearAll,
      }}
    >
      {children}
    </HotKeys>
  );
};

export default DetailsPanel;
