import { FC, useContext, useState } from 'react';
import { useUserSettings } from '../../context/UserSettingsContext';
import { getRoleDescriptionMap } from './acl-util';
import ACLUserList from './ACLUserList';
import Delete from './Delete';
import InviteNewUser from './InviteNewUser';
import ModifyUserAccountType from './ModifyUserAccountType';

// Types
import { User } from '../../../api/gql/graphql';

// hooks
import { useClusterUserActionMutation, useDeleteUserMutation, useInviteUserMutation } from '../../../api/acl';
import { useGetACLUserTable } from '../../../api/queries/aclGraphql';

// @tecton
import { Modal, getToastComponent } from '@tecton/ComponentRedesign';
import { ToastContext, addToast } from '@tecton/ToastContext';
import { logEvent } from '../../../utils/analytics-utils';

const ACLUserListContainer: FC = () => {
  const { user, isAdmin } = useUserSettings();
  const toastContext = useContext(ToastContext);

  // CRUD (Mutation) hooks
  const clusterUserActionMutation = useClusterUserActionMutation();
  const inviteUserMutation = useInviteUserMutation();
  const { data, isLoading, isError, isRefetching } = useGetACLUserTable(user?.sub ?? '', isAdmin ?? false);
  const deleteUserMutation = useDeleteUserMutation();

  // General states
  const [selectedUser, setSelectedUser] = useState<User | undefined>(undefined);
  const [selectedAccountType, setSelectedAccountType] = useState<string | undefined>(undefined);
  const [inviteEmail, setInviteEmail] = useState<string | undefined>(undefined);

  // Validation States
  const [hasDuplicateEmail, setHasDuplicateEmail] = useState<boolean>(false);

  // Modal states
  const [showAccountTypeModal, setShowAccountTypeModal] = useState<boolean>(false);
  const [showInviteNewUserModal, setShowInviteNewUserModal] = useState<boolean>(false);
  const [showDeleteUserModal, setShowDeleteUserModal] = useState<boolean>(false);

  // Account Type descriptions
  const roleDescriptionMap = getRoleDescriptionMap();

  if (selectedAccountType === undefined && selectedUser?.accountType) {
    setSelectedAccountType(selectedUser?.accountType);
  }

  const reset = () => {
    setShowAccountTypeModal(false);
    setSelectedAccountType(undefined);
    setSelectedUser(undefined);
    setShowDeleteUserModal(false);
    setShowInviteNewUserModal(false);
    setInviteEmail(undefined);
    setHasDuplicateEmail(false);
  };

  // --------------------------------------- Start: Actions hooks ---------------------------------
  const onSelectedAccountType = (selectedAccountType: string) => {
    setSelectedAccountType(selectedAccountType);
  };

  const onConfirmAccountTypeChange = () => {
    const payload =
      selectedAccountType === 'Admin'
        ? { okta_id: `${selectedUser?.oktaId}`, grant_admin: true }
        : { okta_id: `${selectedUser?.oktaId}`, revoke_admin: true };

    clusterUserActionMutation.mutate(payload, {
      onSuccess: () => {
        reset();
        logEvent('Account & Access: Change Account Type', '', {
          email: selectedUser?.loginEmail,
          selectedAccountType,
        });
        const toastContent = getToastComponent({
          variant: 'success',
          title: `Account Type Updated`,
          children: (
            <>
              {selectedUser?.loginEmail} account type has been updated to {selectedAccountType}
            </>
          ),
          showIcon: true,
        });

        toastContext?.dispatchToast(
          addToast({
            text: toastContent,
          })
        );
      },
      onError: () => {
        const toastContent = getToastComponent({
          variant: 'danger',
          title: `Updated Failed`,
          children: <>Update to {selectedUser?.loginEmail} has failed. Please try again.</>,
          showIcon: true,
        });

        toastContext?.dispatchToast(
          addToast({
            text: toastContent,
          })
        );
      },
    });
  };

  const onInviteUserClicked = () => {
    const payload = {
      login_email: inviteEmail,
    };
    inviteUserMutation.mutate(payload, {
      onSuccess: () => {
        reset();
        logEvent('Account & Access: Invite User', '', { inviteEmail });
        const toastContent = getToastComponent({
          variant: 'success',
          title: `User Invited`,
          children: <>{inviteEmail} has been invited.</>,
          showIcon: true,
        });

        toastContext?.dispatchToast(
          addToast({
            text: toastContent,
          })
        );
      },
      onError: () => {
        reset();
        const toastContent = getToastComponent({
          variant: 'danger',
          title: `User Invitation Failed`,
          children: <>Failed to invite {selectedUser?.loginEmail}. Please try again.</>,
          showIcon: true,
        });

        toastContext?.dispatchToast(
          addToast({
            text: toastContent,
          })
        );
      },
    });
  };

  const onDeleteUserConfirmClicked = () => {
    const payload = {
      okta_id: selectedUser?.oktaId ?? '',
    };

    deleteUserMutation.mutate(payload, {
      onSuccess: () => {
        reset();
        logEvent('Account & Access: Delete User', '', { email: selectedUser?.loginEmail });
        const toastContent = getToastComponent({
          variant: 'success',
          title: `User Deleted`,
          children: <>{selectedUser?.loginEmail} has been deleted.</>,
          showIcon: true,
        });

        toastContext?.dispatchToast(
          addToast({
            text: toastContent,
          })
        );
      },
      onError: () => {
        reset();
        const toastContent = getToastComponent({
          variant: 'danger',
          title: `User Deletion Failed`,
          children: <>Failed to delete {selectedUser?.loginEmail}. Please try again.</>,
          showIcon: true,
        });

        toastContext?.dispatchToast(
          addToast({
            text: toastContent,
          })
        );
      },
    });
  };

  // --------------------------------------- End Actions hooks ---------------------------------

  return (
    <>
      {showAccountTypeModal && (
        <Modal
          title={`Modify Account Type of ${selectedUser?.loginEmail}`}
          body={
            <ModifyUserAccountType
              user={selectedUser}
              selectedAccountType={selectedAccountType}
              roleDescriptionMap={roleDescriptionMap}
              onSelectionClicked={onSelectedAccountType}
            />
          }
          onCancel={() => {
            reset();
          }}
          confirmLabel="Change assigned Account Type to Admin"
          onConfirm={onConfirmAccountTypeChange}
          variant="positive"
          confirmVariant={selectedUser?.accountType === selectedAccountType ? 'disabledAction' : 'primaryAction'}
          isConfirmButtonLoading={clusterUserActionMutation.isLoading}
        />
      )}

      {showInviteNewUserModal && (
        <Modal
          title={`Invite new Member`}
          body={
            <InviteNewUser
              hasDuplicateEmail={hasDuplicateEmail}
              onValidEmailChange={(email) => {
                setInviteEmail(email);
                const emails = data?.users?.map((user) => user.loginEmail?.toLowerCase()) ?? [];
                setHasDuplicateEmail(emails?.includes(email));
              }}
            />
          }
          onCancel={() => {
            reset();
          }}
          confirmLabel="Invite User"
          onConfirm={onInviteUserClicked}
          variant="positive"
          confirmVariant={inviteEmail === undefined || hasDuplicateEmail ? 'disabledAction' : undefined}
          isConfirmButtonLoading={inviteUserMutation.isLoading}
        />
      )}

      {showDeleteUserModal && (
        <Modal
          title={`Delete ${selectedUser?.loginEmail}`}
          body={<Delete />}
          onCancel={() => {
            reset();
          }}
          confirmLabel="Delete"
          onConfirm={onDeleteUserConfirmClicked}
          variant="negative"
          isConfirmButtonLoading={deleteUserMutation.isLoading}
        />
      )}

      <ACLUserList
        users={data?.users ?? []}
        isAdminView={isAdmin ?? false}
        isLoading={isLoading || isRefetching}
        isError={isError}
        onInviteUserClick={() => {
          setShowInviteNewUserModal(true);
        }}
        onAccountTypeRowClicked={(user: User) => {
          setSelectedUser(user);
          setShowAccountTypeModal(true);
        }}
        onDeleteUserRowClicked={(user: User) => {
          setSelectedUser(user);
          setShowDeleteUserModal(true);
        }}
      />
    </>
  );
};

export default ACLUserListContainer;
