import _ from 'lodash';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import UrlUtils from '../utils/url-utils';
import { setWorkspaceAction } from '../shared/actions';
import { getPathStateAction } from '../shared/selectors';
import { useWorkspaceList } from '../shared/query';
import Select, { components } from 'react-select';
import { ReactComponent as WorkspaceIconDark } from '../assets/image/tecton-ui/workspace-selector/grid-dark.svg';
import { ReactComponent as WorkspaceIconActive } from '../assets/image/tecton-ui/workspace-selector/grid-active.svg';
import { useNavigate } from 'react-router';
import { useUserSettings } from './context/UserSettingsContext';

const WorkspaceSelector = () => {
  const { workspace } = useUserSettings();

  const pathState = useSelector((state) => getPathStateAction(state));
  const navigate = useNavigate();

  const workspacesListState = useWorkspaceList();
  let workspacesList = undefined;
  if (workspacesListState.isLoading) {
    workspacesList = [];
  } else {
    workspacesList = !_.isEmpty(workspacesListState.data) ? workspacesListState.data.workspaces : [];
  }

  const dispatch = useDispatch();
  const setWorkspace = (w) => dispatch(setWorkspaceAction(w));

  const updateWorkspace = (w) => {
    if (pathState.path && pathState.path.indexOf(':workspace') >= 0) {
      let newPathName = UrlUtils.buildPath(pathState.path, { ...pathState.params, workspace: w });
      navigate(newPathName);
      // Will automatically trigger refresh for queries that are keyed by workspace
      setWorkspace(w);
    } else {
      // Fixed for non workspace routes. This basically will take you to "home" of the workspace.
      let newPathName = UrlUtils.buildPath(`/repo/${w}/home`, { ...pathState.params, workspace: w });
      navigate(newPathName);
    }
  };

  const handleChange = (option) => {
    updateWorkspace(option.value);
    document.activeElement.blur();
  };

  const customStyles = {
    input: (provided, state) => ({
      ...provided,
      color: '#022738',
      zIndex: 999,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: 'b6c5cf',
    }),
    option: (provided, { data, isDisabled, isFocused, isSelected }) => {
      let activeBackground = '#b6c5cf';
      let backgroundColor = 'white';

      if (isFocused) {
        backgroundColor = '#F4F9FE';
      } else if (isSelected) {
        backgroundColor = '#f2f7fa';
      } else if (isDisabled) {
        backgroundColor = '#f2f7fa';
      } else {
        //shouldn't get here
      }

      return {
        ...provided,
        color: 'rgb(2, 39, 56)',
        backgroundColor: backgroundColor,
        '&:active': {
          ...provided[':active'],
          backgroundColor: activeBackground,
        },
      };
    },
    indicatorSeparator: (provided, state) => ({
      border: 'none',
    }),
    control: (provided, { data, isDisabled, isFocused, isSelected }) => ({
      width: '325px',
      height: '47px',
      flex: 'none',
      display: 'flex',
      'align-items': 'center',
      cursor: 'pointer',
      background: isFocused ? 'white' : '#263549',
      'margin-right': '12px',
      color: isFocused ? '#022738' : '#b6c5cf',
      'padding-left': '12px',
      'padding-right': '12px',
      'font-size': '16px',
      'font-family': 'Inter',
    }),
    placeholder: (provided, { isFocused }) => ({
      ...provided,
      color: isFocused ? '#022738' : '#b6c5cf',
    }),
    input: (provided, { isFocused }) => ({
      ...provided,
      color: isFocused ? '#022738' : '#b6c5cf',
    }),
    groupHeading: (provided, state) => ({
      ...provided,
      color: '#8f9cac',
      backgroundColor: '#f2f7fa',
    }),
    menu: (provided, state) => ({
      ...provided,
      backgroundColor: '#f2f7fa',
      color: '#022738',
      fontSize: '13px',
      borderRadius: 0,
      'font-family': 'Inter',
      width: '325px',
      top: '38px',
      zIndex: 999,
    }),
  };

  const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    textAlign: 'center',
    top: '50%',
    left: '0px',
    transform: 'translate(-0%,-40%)',
    fontWeight: '800',
  };

  const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
  };

  const formatGroupLabel = (data) => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  );

  const options = [
    {
      label: 'Live Workspaces',
      options: workspacesList
        .filter((workspace, index) => {
          return workspace.capabilities && workspace.capabilities.materializable;
        })
        .map((workspace, index) => ({
          value: workspace.name,
          label: workspace.name,
          accessible: workspace.isAccessible,
        })),
    },
    {
      label: 'My Workspaces',
      options: [],
    },
    {
      label: 'Development Workspaces',
      options: workspacesList
        .filter((workspace, index) => {
          return !workspace.capabilities || !workspace.capabilities.materializable;
        })
        .map((workspace, index) => ({
          value: workspace.name,
          label: workspace.name,
          accessible: workspace.isAccessible,
        })),
    },
  ];

  const ControlComponent = (props) => {
    if (props.isFocused) {
      return (
        <components.Control {...props}>
          <WorkspaceIconActive />
          <components.ValueContainer {...props} />
        </components.Control>
      );
    } else {
      return (
        <components.Control {...props}>
          <WorkspaceIconDark />
          <components.ValueContainer {...props} />
        </components.Control>
      );
    }
  };

  const selectComponent = (
    <Select
      value={{ label: workspace, value: workspace }}
      isSearchable={true}
      options={options}
      styles={customStyles}
      onChange={handleChange}
      formatGroupLabel={formatGroupLabel}
      components={{ Control: ControlComponent }}
      aria-label={'workspace selector search bar'}
    />
  );

  return selectComponent;
};

export default WorkspaceSelector;
