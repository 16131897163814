import styled from '@emotion/styled';
import { Card, NameWrapper, Table, Tooltip } from '@tecton/ComponentRedesign';

import { FC } from 'react';
import { FeatureForFCO, FeatureViewFCO, FeatureViewFCOFields } from '../../../core/types/fcoTypes';

interface FeatureViewFeaturesCardProps {
  featureView: FeatureViewFCO;
}

const NameDisplay = styled.div`
  color: ${({ theme }) => theme.colors.fullShade};
  font-weight: $ ${({ theme }) => theme.font.weight.medium};
  line-height: 20px; /* 142.857% */
`;

const FeatureViewFeaturesCard: FC<FeatureViewFeaturesCardProps> = ({ featureView }) => {
  const items = featureView[FeatureViewFCOFields.FEATURES];
  const canShowModelColumn = Object.keys(featureView[FeatureViewFCOFields.MODEL_MAP] ?? {}).length > 0;

  const modelColumn = canShowModelColumn
    ? [
        {
          name: 'Model',
          render: (feature: FeatureForFCO) => {
            return (
              <>
                <Tooltip
                  title={feature?.modelName}
                  content={''}
                  data-testid="model-tooltip-content"
                  trigger={<NameWrapper isFaded={false}>{feature?.modelName ?? 'N/A'}</NameWrapper>}
                />
              </>
            );
          },
        },
      ]
    : [];
  const columns = [
    {
      name: 'Name',
      render: (feature: FeatureForFCO) => {
        return <NameDisplay>{feature.name}</NameDisplay>;
      },
    },
    {
      name: 'Type',
      render: (feature: FeatureForFCO) => {
        return feature.type;
      },
    },
    ...[...modelColumn],
  ];

  return (
    <Card title={`Feature View Features (${featureView[FeatureViewFCOFields.FEATURES].length})`}>
      <Table items={items} columns={columns} />
    </Card>
  );
};

export default FeatureViewFeaturesCard;
