import React, { ComponentProps, useImperativeHandle } from 'react';
import { ContextMenuPanel, Popover, Icon, ContextMenuItem } from '@tecton';
import { ControlButton } from '@xyflow/react';

type ConfigItem = {
  label: string;
  icon: ComponentProps<typeof Icon>['type'];
  onClick: () => void;
};

type DiagramConfigsPopoverHandle = {
  closePopover: () => void;
  openPopover: () => void;
};

const DiagramConfigsPopover = React.forwardRef<DiagramConfigsPopoverHandle, { menuItems: ConfigItem[] }>(
  (props, ref) => {
    const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);

    useImperativeHandle(ref, () => ({
      closePopover: () => {
        setIsPopoverOpen(false);
      },
      openPopover: () => {
        setIsPopoverOpen(true);
      },
    }));

    const items = props.menuItems.map((item) => (
      <ContextMenuItem
        size="s"
        icon={<Icon type={item.icon} size="s" />}
        key={item.label}
        onClick={() => {
          item.onClick();
          setIsPopoverOpen(false);
        }}
      >
        {item.label}
      </ContextMenuItem>
    ));

    return (
      <Popover
        id="diagram-settings"
        isOpen={isPopoverOpen}
        anchorPosition="rightCenter"
        closePopover={() => {
          setIsPopoverOpen(false);
        }}
        panelPaddingSize="xs"
        button={
          <ControlButton
            onClick={() => {
              setIsPopoverOpen(!isPopoverOpen);
            }}
          >
            <Icon type="gear" size="m" />
          </ControlButton>
        }
      >
        <ContextMenuPanel size="s" items={items} />
      </Popover>
    );
  }
);

export default DiagramConfigsPopover;
export type { DiagramConfigsPopoverHandle };
