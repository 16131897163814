import React, { FC } from 'react';
import { Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { Link } from '@tecton';
import { useNavigate } from 'react-router';

interface FeatureViewIdsTableProps {
  columnLabel: string;
  healthResults: {
    alertname: string;
    feature_package_id: string;
    feature_view_name: string;
    workspace_name: string;
  }[];
}

const FeatureViewHealthTable: FC<FeatureViewIdsTableProps> = ({ columnLabel, healthResults }) => {
  const navigate = useNavigate();
  // empty state
  if (healthResults.length === 0) {
    return (
      <Paper square style={{ minHeight: 250 }} className="pa4">
        <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" textAlign={'center'}>
          No data to show.
        </Grid>
      </Paper>
    );
  }

  return (
    <div style={{ overflow: 'auto' }}>
      <Table>
        <TableHead>
          <TableRow
            style={{
              backgroundColor: '#f5f5f5',
              height: '35px',
            }}
          >
            <TableCell>
              {columnLabel} ({healthResults.length})
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
      </Table>
      <div style={{ overflow: 'auto', height: 250 }}>
        <Table style={{ tableLayout: 'fixed' }}>
          <TableBody>
            {healthResults.map((n, i) => {
              return (
                <TableRow key={i}>
                  <TableCell>
                    <Link
                      onClick={() => {
                        navigate(`/repo/${n.workspace_name}/features/${n.feature_view_name}`);
                      }}
                    >
                      {n.feature_package_id}
                    </Link>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default FeatureViewHealthTable;
