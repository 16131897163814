import React, { FC, forwardRef } from 'react';
import styled from '@emotion/styled';
import { Link, useMatch } from 'react-router-dom';

import Icons, { IconTypes, useIconColors } from '../ComponentRedesign/Icons/Icon';
import TectonTooltip from '../ComponentRedesign/lib/Tooltip';
import { useUserSettings } from '../../context/UserSettingsContext';
import { LinkTextWrapper } from '@tecton/ComponentRedesign';
import _kebabCase from 'lodash/kebabCase';

export interface TectonSideNavigationItemProps {
  label: string | any;
  route?: string;
  onClick?: () => void;
  icon: IconTypes;
  isCollapsed?: boolean;
  track?: boolean;
}

const ItemWrapper = styled.div<{ isActive?: boolean; color?: any }>`
  display: flex;
  padding: 8px 12px;
  justify-content: start;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: ${({ theme }) => theme.border.radius.medium};
  cursor: pointer;

  font-weight: ${({ theme, isActive }) => (isActive ? theme.font.weight.medium : theme.font.weight.regular)};
  color: ${({ theme, isActive }) => (isActive ? theme.colors.title : theme.colors.text)};

  background-color: ${({ theme, isActive }) => (isActive ? theme.colors.emptyShade : 'none')};

  path,
  circle,
  rect,
  ellipse {
    stroke: ${({ theme, isActive, color }) => (isActive ? color : theme.colors.text)};
  }

  :hover {
    background-color: ${({ theme, isActive }) => (isActive ? theme.colors.emptyShade : theme.colors.lightestShade)};
    text-decoration: none;
    path,
    circle,
    rect,
    ellipse {
      stroke: ${({ color }) => color};
    }
  }
`;

const IconWrapper = styled.div`
  width: 20px;
  height: 20px;
`;

const IconWrapperCollapsed = styled.div<{ color: any; isActive?: boolean }>`
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.body};
  cursor: pointer;

  ellipse,
  path,
  rect,
  circle {
    stroke: ${({ color, isActive }) => (isActive ? color : 'unset')};
  }

  :hover {
    ellipse,
    path,
    rect,
    circle {
      stroke: ${({ color }) => color};
    }
  }
`;

const IconContainer = styled.div`
  width: 20px;
  height: 20px;
`;

const CollapsedNavigationIcon: FC<TectonSideNavigationItemProps> = ({ label, icon, route, onClick }) => {
  const colors = useIconColors();
  const { workspace } = useUserSettings();

  const isActive = !!useMatch({
    path: route ?? '',
    end: false,
  });

  const to = route?.replace(`:workspace`, workspace ?? 'prod').replace('/*', '');

  const WrappedIcon = (
    <IconWrapperCollapsed
      isActive={isActive}
      color={colors[icon]}
      onClick={() => {
        onClick?.call(this);
      }}
    >
      <IconContainer>{Icons[icon]}</IconContainer>
    </IconWrapperCollapsed>
  );

  if (to) {
    return (
      <Link to={to}>
        <TectonTooltip position="right" trigger={WrappedIcon} title={undefined} content={label} />
      </Link>
    );
  }

  return <TectonTooltip position="right" trigger={WrappedIcon} title={undefined} content={label} />;
};
const TectonSideNavigationItem = forwardRef<HTMLDivElement, TectonSideNavigationItemProps>(
  ({ label, icon, isCollapsed, route, onClick }, ref) => {
    const { workspace } = useUserSettings();

    const colors = useIconColors();
    const isActive = !!useMatch({
      path: route ?? '',
      end: false,
    });

    const to = route?.replace(`:workspace`, workspace ?? 'prod').replace('/*', '');

    if (isCollapsed) {
      return <CollapsedNavigationIcon label={label} onClick={onClick} icon={icon} route={to} />;
    }

    if (to) {
      return (
        <LinkTextWrapper>
          <Link to={to}>
            <ItemWrapper
              data-testid={_kebabCase(`sidenav-${label}`)}
              onClick={onClick}
              isActive={isActive}
              color={colors[icon]}
            >
              <IconWrapper>{Icons[icon]}</IconWrapper>
              <>{label}</>
            </ItemWrapper>
          </Link>
        </LinkTextWrapper>
      );
    }

    return (
      <div ref={ref}>
        <ItemWrapper onClick={onClick} color={colors[icon]}>
          <IconWrapper>{Icons[icon]}</IconWrapper>
          <>{label}</>
        </ItemWrapper>
      </div>
    );
  }
);

export default TectonSideNavigationItem;
