import styled from '@emotion/styled';
import { usePolling } from '@shared';
import { TimezoneSelector } from '@shared/redesign/TimeZoneSelector';
import { ReactComponent as Refresh } from '@svg/refresh.svg';
import { FilterOptionType } from '@tecton/CategoricalSelector';
import {
  Breadcrumbs,
  ButtonIcon,
  ColumnDisplayControls,
  ComboFilter,
  ErrorBoundary,
  FlexGroup,
  FlexItem,
  HeaderLayout,
  Spacer,
  ViewLayout,
} from '@tecton/ComponentRedesign';
import moment from 'moment';
import React, { createContext, useCallback, useContext, useState } from 'react';
import { GetJobsResponse } from '../../../api/gql/graphql';
import TectonTimeComboSelector from '../../@tecton/ComponentRedesign/lib/TimeComboSelector';
import { TimezoneContextProvider, useTimezoneSettings } from '../../context/TimezoneContext';
import { useUserSettings } from '../../context/UserSettingsContext';
import { useGetJobsFilterTypes } from './jobsConfig';
import { JobsContext, JobsContextProvider } from './JobsContext';
import JobsTable from './JobsTable';
import { activeDateFilter, numberOfActiveItems } from './JobsUtils';
import { CancelConfirmationModal, RetryConfirmationModal } from './Modals';
import { JobsFilterStateProps } from './types';

const FlexBottom = styled(FlexItem)`
  justify-content: end;
`;

const FlexGroupRefresh = styled(FlexGroup)`
  font-size: 12px;
`;

const header = <HeaderLayout breadcrumbs={<Breadcrumbs root="Jobs" crumbs={[]} />} />;

const JobsContainer = () => {
  return (
    <ViewLayout
      header={header}
      body={
        <ErrorBoundary size="l">
          <TimezoneContextProvider>
            <JobsContextProvider>
              <FilterProvider>
                <Jobs />
              </FilterProvider>
            </JobsContextProvider>
          </TimezoneContextProvider>
        </ErrorBoundary>
      }
    />
  );
};
interface ComponentProps {
  children: React.ReactNode;
  isFeatureView?: boolean;
}

interface FilterContextType {
  selectedFilter: string | undefined;
  setSelectedFilter: React.Dispatch<React.SetStateAction<string | undefined>>;
}

interface DropDownContextType {
  isOpen: boolean | undefined;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean | undefined>>;
}

const FilterContext = createContext<FilterContextType | undefined>(undefined);
const DropdownContext = createContext<DropDownContextType | undefined>(undefined);

const LayoutWrapper = ({ children }: ComponentProps) => {
  const { updateDateFilter, jobsFilterState, resetNowDate } = useContext(JobsContext);

  const { selectorState } = jobsFilterState as JobsFilterStateProps;
  const { timezone } = useTimezoneSettings();

  const getStaleTime = useCallback(() => {
    const now = moment();
    const then = jobsFilterState.selectorState.lastTaskStateChange.end;
    return now.diff(then, 'minutes');
  }, [jobsFilterState.selectorState.lastTaskStateChange.end]);

  const initStaleTime = getStaleTime();
  const [staleTime, setStaleTime] = useState(initStaleTime);

  const updateStaleTime = useCallback(() => {
    const staleTime = getStaleTime();
    setStaleTime(staleTime);
  }, [getStaleTime]);

  usePolling(updateStaleTime);

  return (
    <>
      <FlexGroup gutterSize="s" alignItems="center">
        <FlexItem grow={false}>
          <TectonTimeComboSelector
            dateRange={{
              startDate: selectorState?.lastTaskStateChange.start,
              endDate: selectorState?.lastTaskStateChange.end,
            }}
            setDateRange={(date) => {
              updateDateFilter('lastTaskStateChange', date.startDate, date.endDate);
            }}
            initialOption={'2Weeks'}
          />
        </FlexItem>
        <FlexItem grow={false}>
          <TimezoneSelector />
        </FlexItem>

        <FlexBottom grow={false}>
          <FlexGroupRefresh alignItems="center" gutterSize="none">
            <FlexItem grow={false}> Data from {staleTime > 0 ? `${staleTime}min` : '< 1min'} ago</FlexItem>
            <FlexItem grow={false}>
              {' '}
              <ButtonIcon
                display="empty"
                size="s"
                iconType={Refresh}
                onClick={() => {
                  resetNowDate();
                }}
              />
            </FlexItem>
          </FlexGroupRefresh>
        </FlexBottom>

        <DropdownProvider>
          <ComboFilterDropdownContainer />
        </DropdownProvider>
      </FlexGroup>
      <Spacer size="m" />
      {children}
    </>
  );
};

const ComboFilterDropdownContainer = () => {
  const { selectedFilter, setSelectedFilter } = useCompboFilterSelected();
  const { isOpen, setIsOpen } = useDropdownSelected();

  const {
    jobsFilterState,
    resetAllFilters,
    ControlsState: {
      isColumnControlsOpen,
      setIsColumnControlsOpen,
      columnOptions,
      numberOfSelectedColumnsOptions,
      setColumnOptions,
    },
  } = useContext(JobsContext);

  const { selectorState } = jobsFilterState as JobsFilterStateProps;
  const allActiveFiltersCount =
    numberOfActiveItems(selectorState?.statuses) +
    numberOfActiveItems(selectorState?.workspaces) +
    numberOfActiveItems(selectorState?.taskTypeForDisplays) +
    numberOfActiveItems(selectorState?.manuallyTriggered) +
    numberOfActiveItems(selectorState?.duration) +
    numberOfActiveItems(selectorState?.featureViews) +
    numberOfActiveItems(selectorState?.numAttempts) +
    (activeDateFilter(selectorState?.featureViewStart) ? 1 : 0) +
    (activeDateFilter(selectorState?.featureViewEnd) ? 1 : 0);

  const { filterTypes } = useGetJobsFilterTypes();

  return (
    <FlexGroup justifyContent="flexEnd" gutterSize="s">
      <FlexItem grow={false}>
        <ComboFilter
          buttonLabel={'Filters'}
          filterTypes={filterTypes}
          isOpen={isOpen}
          selectedFilter={selectedFilter}
          setIsOpen={setIsOpen}
          setSelectedFilter={setSelectedFilter}
          numberOfActiveFilters={allActiveFiltersCount}
          clearAllFilterCallback={() => {
            setSelectedFilter('status');
            resetAllFilters();
          }}
          resetActiveFilter={resetAllFilters}
        />
      </FlexItem>
      <FlexItem grow={false}>
        <ColumnDisplayControls
          isColumnControlsOpen={isColumnControlsOpen}
          setIsColumnControlsOpen={setIsColumnControlsOpen}
          columnOptions={columnOptions}
          numberOfSelectedColumnsOptions={numberOfSelectedColumnsOptions}
          setColumnOptions={setColumnOptions}
        />
      </FlexItem>
    </FlexGroup>
  );
};

export const Jobs = () => {
  const {
    data,
    isLoading,
    isError,
    isFetching,
    loadMoreData,
    isFetchingNextPage,
    updateCategoricalSelectorState,
    updateSortParams,
    jobsFilterState,
    setShowCancelModal,
    setShowRetryModal,
    setShowOverwriteModal,
    setCurrentJobAction,
    ControlsState: { activeColumns },

    showCancelModal,
    showRetryModal,
    currentJobAction,
  } = useContext(JobsContext);
  const { setSelectedFilter } = useCompboFilterSelected();
  const { workspace } = useUserSettings();
  // InfinteQuery Loas
  const jobs = data.pages.flatMap((x: GetJobsResponse) => x.jobs);
  const pagination = data?.pages[data?.pages?.length - 1]?.pagination;
  const error = isError ? 'We encountered an error fetching data. Please update filters and try again.' : undefined;

  return (
    <LayoutWrapper>
      <JobsTable
        displayedColumns={activeColumns}
        items={jobs}
        totalItemCount={pagination.total}
        pagination={pagination}
        onChange={updateSortParams}
        error={error}
        isFetching={isFetching}
        isFetchingNextPage={isFetchingNextPage}
        isLoading={isLoading}
        loadMore={loadMoreData}
        hasMore={pagination.nextPageToken}
        setShowCancelModal={setShowCancelModal}
        setShowRetryModal={setShowRetryModal}
        setShowOverwriteModal={setShowOverwriteModal}
        setCurrentJobAction={setCurrentJobAction}
        workspaceFilterCallback={(workspace) => {
          const items = jobsFilterState.selectorState.workspaces.map((ws: FilterOptionType) => {
            if (ws.label === workspace) {
              ws.checked = 'on';
            } else {
              delete ws.checked;
            }
            return ws;
          });
          updateCategoricalSelectorState({ prop: 'workspaces', items, isIsolate: true });
          setSelectedFilter('workspaces');
        }}
        fvFilterCallback={(fv) => {
          updateCategoricalSelectorState({
            prop: 'featureViews',
            items: [{ label: fv, checked: 'on', disabled: true }],
            isIsolate: true,
          });
          setSelectedFilter('featureViews');
        }}
      />
      {showCancelModal && (
        <CancelConfirmationModal
          confirmChange={() => {
            setShowCancelModal(false);
          }}
          job={currentJobAction}
          onClose={() => {
            setShowCancelModal(false);
          }}
        />
      )}
      {showRetryModal && (
        <RetryConfirmationModal
          confirmChange={() => {
            setShowRetryModal(false);
          }}
          job={currentJobAction}
          onClose={() => {
            setShowRetryModal(false);
          }}
        />
      )}
    </LayoutWrapper>
  );
};

// USING CONTEXT PREVENTS UNWANTED RERENDERS
export const FilterProvider: React.FC<ComponentProps> = ({ children, isFeatureView }) => {
  const DEFAULT_FILTER = isFeatureView ? 'status' : 'workspaces';
  const [selectedFilter, setSelectedFilter] = useState<string | undefined>(DEFAULT_FILTER);

  return <FilterContext.Provider value={{ selectedFilter, setSelectedFilter }}>{children}</FilterContext.Provider>;
};

const useCompboFilterSelected = () => {
  const context = useContext(FilterContext);
  if (!context) {
    throw new Error('useFilter must be used within a FilterProvider');
  }

  return context;
};

const DropdownProvider: React.FC<ComponentProps> = ({ children }) => {
  const [isOpen, setIsOpen] = useState<boolean | undefined>(false);

  return <DropdownContext.Provider value={{ isOpen, setIsOpen }}>{children}</DropdownContext.Provider>;
};

const useDropdownSelected = () => {
  const context = useContext(DropdownContext);
  if (!context) {
    throw new Error('useDropDown must be used within a FilterProvider');
  }

  return context;
};

export default JobsContainer;
