import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import './styles.scss';
import WorkspaceSelector from '../../components/WorkspaceSelector.js';
import { selectFeatureFlags } from '../feature-flag/selectors';
import { logout } from '../../utils/okta';
import GlobalSearch from '../../components/GlobalSearch.js';
import useGlobalState from '../../shared/useGlobalState';
import NewWorkspaceSelector from './workspace-selection/SelectorDropdown/NewWorkspaceSelector';
import { css } from '@emotion/css';
import usePrincipalInformation from '../../feature/access-control/Hooks/usePrincipalInformation';
import WorkspaceSelectorDropdown from '../../components/shared/WorkspaceSelectorDropdown';
import UserAvatar from '../../components/shared/UserAvatar';
import GlobalObjectSearch from '../../components/shared/GlobalObjectSearch';
import { useUserSettings, FeatureFlags } from '../../components/context/UserSettingsContext';

const UserProfile = () => {
  const [profileMenuOpen, setProfileMenuOpen] = useState(false);
  const { loggedInUser } = useGlobalState();
  const featureFlags = useSelector(selectFeatureFlags);
  const { auth } = useGlobalState();

  // Default values needed for local development when Okta is not enabled
  let userName = 'Tecton User';
  let userInitial = '?';
  if (featureFlags && featureFlags.oktaEnabled) {
    userName = loggedInUser.name;
    userInitial = userName ? userName.charAt(0) : null;
  }

  const toggleMenu = () => {
    setProfileMenuOpen(!profileMenuOpen);
  };

  return (
    <div className="relative cursor-pointer dib mr3">
      <div onClick={toggleMenu} className="avatar avatar--medium avatar--initial avatar--initial--3">
        {userInitial}
      </div>
      {profileMenuOpen ? (
        <div className="dropdown dropdown--profile">
          <div className="dropdown__item dropdown__item__disabled">
            <div className="dropdown__text">{userName}</div>
          </div>

          {featureFlags.oktaEnabled ? (
            <a onClick={() => logout(auth)}>
              <div className="dropdown__item" role="button">
                <div className="dropdown__text link-color">Sign out</div>
              </div>
            </a>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export const Header = () => {
  const location = useLocation();

  const isOnboardingRoute = location.pathname.startsWith('/onboarding');
  const { hasZeroWorkspaceAccess } = usePrincipalInformation();

  const { featureEnabled } = useUserSettings();

  if (isOnboardingRoute) {
    return null;
  }

  if (!hasZeroWorkspaceAccess) {
    if (featureEnabled(FeatureFlags.SHOW_NEW_TOP_NAV)) {
      const TopBarCss = css`
        position: sticky;
        top: 0;
        z-index: 900;
        padding: 20px 16px 20px 0px;
        background-color: #0c2636;
        display: grid;
        grid-template-columns: 3fr 7fr min-content;
        justify-content: space-between;
        grid-gap: 2em;
      `;
      return (
        <div className={TopBarCss}>
          <div>
            <WorkspaceSelectorDropdown />
          </div>
          <div>
            <GlobalObjectSearch />
          </div>
          <div>
            <UserAvatar />
          </div>
        </div>
      );
    }
    return (
      <div id="topbar" role="toolbar">
        <NewWorkspaceSelector />
        <div className="flex-1 mr4">
          <GlobalSearch />
        </div>
        <UserProfile />
      </div>
    );
  }
  // Disable Workspace selector and Global search since in Debug page we use explicit workspace that
  // may have been deleted, which would error out the above components.
  if (location.pathname.startsWith('/debug')) {
    return <div id="topbar" />;
  } else {
    return (
      <div id="topbar" role="toolbar">
        {!hasZeroWorkspaceAccess && <WorkspaceSelector />}
        <div className="flex-1 mr4">{!hasZeroWorkspaceAccess && <GlobalSearch />}</div>
        <UserProfile />
      </div>
    );
  }
};
