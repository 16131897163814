import React, { FC, PropsWithChildren } from 'react';
import Title from '../../utils/title-utils';

interface StandardPageInterface {
  pageTitle: string;
  canShowTitle?: boolean;
}

const StandardPage: FC<PropsWithChildren<StandardPageInterface>> = ({ pageTitle, canShowTitle = true, children }) => {
  let headerContent: any = (
    <div className="content-head">
      <div>
        <div className="f3 fw7 mb3">{pageTitle}</div>
      </div>
    </div>
  );

  if (!canShowTitle) {
    headerContent = '';
  }
  return (
    <div id="content" role="main" className="overflow-y-scroll h-100 content-shadow">
      <Title title={pageTitle} />
      {headerContent}
      <div className="pa5 card">{children}</div>
    </div>
  );
};

export default StandardPage;
