import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { WorkspaceWithPermissions } from '../../../components/context/types';
import WorkspaceSelectionTable from './Tables/WorkspaceSelectionTable';
import styles from './styles.module.scss';

interface WorkspaceTypeSelectionBoxProperties {
  headline: string;
  workspaces: WorkspaceWithPermissions[];
  description: React.ReactNode;
}

const WorkspaceTypeSelectionBox: FC<WorkspaceTypeSelectionBoxProperties> = ({ headline, workspaces, description }) => {
  const accessibleWorkspaceCount = workspaces.filter((workspace) => workspace.isAccessible).length;

  return (
    <Box className={`${styles.showScrollbar} ${styles.workspaceSelectionBox}`}>
      <Box className={styles.workspaceHeadline} sx={{}}>
        {headline} ({accessibleWorkspaceCount} / {workspaces.length})
      </Box>

      <Box className={styles.workspaceDescription}>
        <Typography variant="body1">{description}</Typography>
      </Box>

      <Box flex={1} className="w-50">
        <WorkspaceSelectionTable data={workspaces} />
      </Box>
    </Box>
  );
};

export default WorkspaceTypeSelectionBox;
