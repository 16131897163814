import React from 'react';
import { StoreIconWrapper, SvgWithGlowWrapper } from '../VisualPrimitives/CardTypography';
import { OfflineStoreIcon, OnlineStoreIcon } from '../DataflowIndividualStoreNode';
import { Handle, Position } from '@xyflow/react';
import styled from '@emotion/styled';

const ICON_SIZE = 32;

const TypeDisplay = styled.div`
  color: ${({ theme }) => theme.colors.subduedText};
  font-size: 9px;
  font-style: normal;
  font-weight: ${({ theme }) => theme.font.weight.regular};
  line-height: 15px; /* 166.667% */
  text-transform: uppercase;
`;

const SUMMARYStoreNode = ({ type, ..._props }: { type: string }) => {
  if (type !== 'SUMMARY_ONLINE_STORE' && type !== 'SUMMARY_OFFLINE_STORE') {
    throw new Error('SUMMARYStoreNode should only be used for SUMMARY_ONLINE_STORE and SUMMARY_OFFLINE_STORE');
  }

  return (
    <>
      <Handle isConnectable={false} type="target" position={Position.Left} />
      <StoreIconWrapper isNotClickable>
        <SvgWithGlowWrapper inset={[5, 5]}>
          <svg width={ICON_SIZE} height={ICON_SIZE} style={{ display: 'block' }}>
            {type === 'SUMMARY_ONLINE_STORE' && <OnlineStoreIcon />}
            {type === 'SUMMARY_OFFLINE_STORE' && <OfflineStoreIcon />}
          </svg>
        </SvgWithGlowWrapper>
        <TypeDisplay>{type === 'SUMMARY_ONLINE_STORE' ? 'Online' : 'Offline'} Store</TypeDisplay>
      </StoreIconWrapper>
      <Handle isConnectable={false} type="source" position={Position.Right} />
    </>
  );
};

export default SUMMARYStoreNode;
