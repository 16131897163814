import { FeatureViewNode, ODFVNode } from '../dataFlowTypes';

const fvTypeToNameMapping = (node: FeatureViewNode | ODFVNode) => {
  const type = node.type === 'feature_view' ? node.featureViewType : 'ondemand';

  const mapping: Record<string, string> = {
    batch: 'Batch Feature View',
    stream: 'Stream Feature View',
    ondemand: 'Realtime Feature View',
    'feature table': 'Feature Table',
  };

  return type ? mapping[type] : 'Undefined Type Feature View';
};

export default fvTypeToNameMapping;
