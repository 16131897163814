import React, { FC } from 'react';
import { FeatureServiceFCO } from '../../../core/types/fcoTypes';
import { CardGridLayout, FullWidthCard, TwoCardRow, FCOMetadataCard, FCOTagsCard } from '@tecton/ComponentRedesign';
import FeatureServiceConfigCard from '../../featureServices/Redesign/FeatureServiceConfigCard';
import FeatureServiceCurlCard from '../../featureServices/Redesign/FeatureServiceCurlCard';
import FeatureServiceFeatureTable from '../../featureServices/Redesign/FeatureServiceFeatureTable';
import { FeatureServiceSummary } from '../../../core/types/featureServiceSummary';

interface FeatureServiceOverviewProps {
  service: FeatureServiceFCO;
  fcos: any;
  workspace: string;
  servingSummary: FeatureServiceSummary; // This comes from useGetFeatureServiceSummary
  curlSummary: any; // This comes from useFeatureServiceSummary
  // Unsure why these are so confusingly-named
}

const FeatureServiceOverview: FC<FeatureServiceOverviewProps> = ({
  service,
  fcos,
  workspace,
  servingSummary,
  curlSummary,
}) => {
  return (
    <CardGridLayout>
      <TwoCardRow>
        <FCOMetadataCard fco={service} />
        <FCOTagsCard fco={service} />
        <FeatureServiceConfigCard fco={service} fcos={fcos} summary={servingSummary} />
        <FeatureServiceCurlCard summary={curlSummary} />
      </TwoCardRow>
      <FullWidthCard>
        <FeatureServiceFeatureTable fco={service} fcos={fcos} workspace={workspace} />
      </FullWidthCard>
    </CardGridLayout>
  );
};

export default FeatureServiceOverview;
