import React, { FC, useState } from 'react';
import pull from 'lodash/pull';
import { format } from 'd3-format';

import { MonitoringDateRange } from '../../monitoring/DateRange-Dropdown-util';
import { usePrometheus } from '../../../feature/query';
import useChartJsRegister from '../../monitoring/useChartJsRegister';

import { Loading } from '@tecton/ComponentRedesign';

interface PrometheusCountProps {
  chartTitle: string;
  monitoringDateRange: MonitoringDateRange;
  queries: { label: string; query: string }[];
  parseCallback: (arg: string) => number;
  calculateAverage?: (arg: number) => number;
  unit?: string;
}

const PrometheusCount: FC<PrometheusCountProps> = ({
  monitoringDateRange,
  queries,
  parseCallback,
  calculateAverage,
  unit,
}) => {
  const state = usePrometheus(queries, monitoringDateRange);
  const [hasNoData, setHasNoData] = useState(false);
  const noDataMessage = 'No Data';

  useChartJsRegister();

  if (state.isLoading) {
    return <Loading size={'xxl'} />;
  }

  let dataset: any[] = [];

  if (state.data && Array.isArray(state.data)) {
    dataset = state.data.map((response) => {
      const dataRow = response.result.map((result: any) => {
        const values = result?.values;
        let average = 0;

        if (Array.isArray(values)) {
          // Get that values so we can do some calculations on it.
          const numberValues = values.map((values) => {
            return values[1];
          });

          // The backend can return "NaN". We need to remove it from the array to add up the total.
          const valuesWithOnlyNumbers = pull(numberValues, 'NaN');

          // Get the total from the values.
          const total = valuesWithOnlyNumbers.reduce((total, values) => {
            const parseValue = parseCallback(values);
            return total + parseValue;
          }, 0);

          if (valuesWithOnlyNumbers.length === 0 && !hasNoData) {
            // We let the component know that there were no values so the component can show the appropriate message.
            setHasNoData(true);
          }

          average = Math.round(total / values.length);
        }

        return calculateAverage ? calculateAverage(average) : average;
      });

      return dataRow;
    });
  }

  const dataFlat = dataset.flat();

  return (
    <>
      {dataFlat.map((value, index) => {
        const calculatedValue = calculateAverage ? format('.0%')(value) : `${value}${unit ?? ''}`;
        return (
          <span key={index}>{hasNoData ? noDataMessage : <div style={{ fontSize: 27 }}>{calculatedValue}</div>}</span>
        );
      })}
    </>
  );
};

export default PrometheusCount;
