import React, { FC, useState } from 'react';

import { AnyFCO, EntityFCO, EntityFCOFields, FCOType } from '../../../core/types/fcoTypes';

// @tecton
import { ComboFilterSelector, ComboSelectorChecklistOption, RelatedFCOs, Tooltip } from '@tecton/ComponentRedesign';

import { compact, uniq } from 'lodash';
import {
  FCOFilterStateType,
  FCOFilterType,
  FCOHistoryFilterState,
  FCOHistoryFilterTypes,
  FCOHistoryTableColumns,
  FCOListView,
  NameColumn,
  RelatedFCOCountFilterLogic,
  RelatedFCOCountFilterType,
  RelatedFCOFilterLogic,
  RelatedFCOFilterType,
  SharedFCOHistoryFilterLogic,
  structureTagsForFilter,
  TagFilterLogic,
  TagsColumn,
  TagsFilterType,
  valuesForFCOCount,
} from '../fcoListView';

interface EntitiesListProps {
  items: EntityFCO[];
  idToFcoMap: Record<string, AnyFCO>;
  workspace: string;
}

const EntitiesList: FC<EntitiesListProps> = ({ items, idToFcoMap, workspace }) => {
  type EntityFilterKeys = FCOFilterType | '# of Feature Views' | 'Feature Views' | 'Feature Services';

  interface EntityFilterStateType extends FCOFilterStateType {
    '# of Feature Views': ComboSelectorChecklistOption[];
    'Feature Views': ComboSelectorChecklistOption[];
    'Feature Services': ComboSelectorChecklistOption[];
  }

  const featureViews = uniq(
    compact(items.map((item) => item.dependentFeatureViews).flat()).map((fvId) => idToFcoMap[fvId])
  );

  const featureServices = uniq(compact(items.map((item) => item.dependentFeatureServices).flat())).map(
    (fvId) => idToFcoMap[fvId]
  );

  const defaultFilterState: EntityFilterStateType = {
    Tags: structureTagsForFilter(items),
    '# of Feature Views': valuesForFCOCount,
    'Feature Views': featureViews.map((featureView) => {
      return { label: featureView.name ?? '', value: featureView.id };
    }),
    'Feature Services': featureServices.map((featureService) => {
      return { label: featureService.name ?? '', value: featureService.id };
    }),
    ...FCOHistoryFilterState(items as AnyFCO[]),
  };

  const [filterState, setFilterState] = useState<EntityFilterStateType>(defaultFilterState);

  // FILTER CONTROLS
  const filterTypes: Record<EntityFilterKeys, ComboFilterSelector> = {
    Tags: TagsFilterType(filterState, setFilterState as React.Dispatch<React.SetStateAction<FCOFilterStateType>>),
    '# of Feature Views': RelatedFCOCountFilterType(filterState, setFilterState, '# of Feature Views'),
    'Feature Views': RelatedFCOFilterType(filterState, setFilterState, 'Feature Views', FCOType.FEATURE_VIEW),
    'Feature Services': RelatedFCOFilterType(filterState, setFilterState, 'Feature Services', FCOType.FEATURE_SERVICE),
    ...FCOHistoryFilterTypes(filterState, setFilterState as React.Dispatch<React.SetStateAction<FCOFilterStateType>>),
  };

  // TABLE COLUMNS
  const columns = [
    ...NameColumn,
    ...TagsColumn,
    {
      name: 'Join Keys',
      key: 'Join Keys',
      field: EntityFCOFields.JOIN_KEYS,
      sortable: true,
      render: (_: unknown, entity: EntityFCO) => {
        return entity[EntityFCOFields.JOIN_KEYS].join(', ');
      },
    },
    {
      name: (
        <>
          <Tooltip content={'The Feature Views that depend on each Entity.'} trigger={<>Feature Views</>}></Tooltip>
        </>
      ),
      key: 'Feature Views',
      sortable: true,
      field: EntityFCOFields.DEPENDENT_FEATURE_VIEWS,
      render: (_: unknown, entity: EntityFCO) => {
        const items = entity[EntityFCOFields.DEPENDENT_FEATURE_VIEWS]
          .map((featureViewId) => idToFcoMap[featureViewId])
          .map((fco) => {
            return { name: fco.name ?? '', description: fco.description };
          });
        return <RelatedFCOs fcos={items} type={FCOType.FEATURE_VIEW} workspace={workspace} />;
      },
    },
    {
      name: (
        <>
          <Tooltip
            content={'The Feature Services that depend on each Entity.'}
            trigger={<>Feature Services</>}
          ></Tooltip>
        </>
      ),
      key: 'Feature Services',
      sortable: true,
      field: EntityFCOFields.DEPENDENT_FEATURE_SERVICES,
      render: (_: unknown, entity: EntityFCO) => {
        const fcos = entity[EntityFCOFields.DEPENDENT_FEATURE_SERVICES]
          .map((featureServiceId) => idToFcoMap[featureServiceId])
          .map((fco) => {
            return { name: fco.name ?? '', description: fco.description };
          });
        return <RelatedFCOs fcos={fcos} type={FCOType.FEATURE_SERVICE} workspace={workspace} />;
      },
    },
    ...FCOHistoryTableColumns,
  ];

  // Defines the functions for each Filter Key type
  const filterFunctionsByFilterKey: Record<EntityFilterKeys, (fco: EntityFCO) => boolean> = {
    Tags: TagFilterLogic(filterState['Tags']),
    'Feature Views': RelatedFCOFilterLogic(filterState, 'Feature Views', EntityFCOFields.DEPENDENT_FEATURE_VIEWS),
    'Feature Services': RelatedFCOFilterLogic(
      filterState,
      'Feature Services',
      EntityFCOFields.DEPENDENT_FEATURE_SERVICES
    ),
    '# of Feature Views': RelatedFCOCountFilterLogic(
      filterState,
      '# of Feature Views',
      EntityFCOFields.DEPENDENT_FEATURE_VIEWS
    ),
    ...SharedFCOHistoryFilterLogic(filterState),
  };

  return (
    <FCOListView
      unfilteredFCOItems={items}
      filters={filterTypes}
      columns={columns}
      filterState={filterState}
      setFilterState={setFilterState}
      defaultFilterState={defaultFilterState}
      filterFunctionsByFilterKey={filterFunctionsByFilterKey}
      searchPlaceholder={'Search Entities'}
    />
  );
};

export default EntitiesList;
