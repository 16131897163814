import { HighlightFunctionType } from './highlightFunctionType';
import { getAddDependencyEdgeFn } from './highlight-utils';
import edgeIdFn from './edgeIdFn';

const highlightDownstream: HighlightFunctionType = (id, nodesMap, edgesList, config) => {
  const linkedIds = new Set<string>();
  const linkedEdges = new Set<string>();

  const addDependencyEdge = getAddDependencyEdgeFn(linkedIds, linkedEdges);

  const recurseDownstreamNodes = (currentId: string) => {
    if (linkedIds.has(currentId)) {
      return;
    }

    linkedIds.add(currentId);

    const currentNode = nodesMap[currentId];

    if (currentNode.downstreamNodes) {
      currentNode.downstreamNodes.forEach((downstream) => {
        const downstreamNode = nodesMap[downstream];

        if (!downstreamNode) {
          return;
        }

        if (downstreamNode.type === 'request_data_source') {
          // Request Data Source can produce cyclical dependencies
          // so we don't recurse down this path.
          addDependencyEdge(currentNode, downstreamNode, config);

          return;
        }

        if (config?.edgeShouldBeSkipped && config.edgeShouldBeSkipped(currentNode, downstreamNode)) {
          return;
        }

        addDependencyEdge(currentNode, downstreamNode, config);

        recurseDownstreamNodes(downstream);
      });
    }

    /**
     * If the current node is a feature view
     * we need to decide whether to connect to
     * and light up the online and offline store
     * based on whether it is materializing
     * to that store
     */
    if (currentNode.type === 'feature_view') {
      if (currentNode.isOnlineMaterializationEnabled && !config?.hideOnlineStore) {
        linkedEdges.add(edgeIdFn({ source: currentNode.id, target: 'STORE' }));
        linkedEdges.add(edgeIdFn({ source: 'store-input', target: 'ONLINE_STORE' }));
        linkedIds.add('ONLINE_STORE');
      }

      if (currentNode.isOfflineMaterializationEnabled && !config?.hideOfflineStore) {
        linkedEdges.add(edgeIdFn({ source: currentNode.id, target: 'STORE' }));
        linkedEdges.add(edgeIdFn({ source: 'store-input', target: 'OFFLINE_STORE' }));
        linkedIds.add('OFFLINE_STORE');
      }
    }
  };

  recurseDownstreamNodes(id);

  return {
    linkedIds,
    linkedEdges,
  };
};

export default highlightDownstream;
