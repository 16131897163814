import React, { FC } from 'react';
import NoWorkspacesAvailable from './NoWorkspacesAvailable/NoWorkspacesAvailable';

import SelectWorkspace from './SelectWorkspace';
import { useUserSettings } from '../../../components/context/UserSettingsContext';

const WorkspaceSelectionWorkflow: FC = () => {
  const { allWorkspaces } = useUserSettings();
  const hasAnyAccess = allWorkspaces!.filter((workspace) => workspace.isAccessible).length > 0;

  return (
    <>
      {/* If workspaces.data doesn't have 'workspaces', the user has no existing permissions, and they should see the corresponding component. */}
      {hasAnyAccess === false && <NoWorkspacesAvailable />}
      {/* If workspaces.data DOES have 'workspaces', the user has some existing permissions, and they should see the workspace selector. */}
      {hasAnyAccess === true && <SelectWorkspace />}
    </>
  );
};

export default WorkspaceSelectionWorkflow;
