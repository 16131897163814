import highlightDownstream from './highlightDownstream';
import { HighlightFunctionType } from './highlightFunctionType';
import highlightUpstream from './highlightUpstream';

const highlightTransformation: HighlightFunctionType = (id, nodesMap, edgesList) => {
  const upstream = highlightUpstream(id, nodesMap, edgesList);
  const downstreamToStore = highlightDownstream(id, nodesMap, edgesList);

  return {
    linkedIds: new Set([...upstream.linkedIds, ...downstreamToStore.linkedIds]),
    linkedEdges: new Set([...upstream.linkedEdges, ...downstreamToStore.linkedEdges]),
  };
};

export default highlightTransformation;
