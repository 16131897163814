import React, { FC } from 'react';
import { Card, Code } from '@tecton/ComponentRedesign';
import { FeatureServiceSummary } from '../../../core/types/featureServiceSummary';

interface FeatureServiceCurlCardProps {
  summary: FeatureServiceSummary;
}

const FeatureServiceCurlCard: FC<FeatureServiceCurlCardProps> = ({ summary }) => {
  if (summary) {
    const curlEndpoint = summary?.curlEndpoint;
    const curlHeader = summary?.curlHeader;
    const curlParamsJson = summary?.curlParamsJson ?? '';
    const curlParamsJsonFormatted = JSON.stringify(JSON.parse(curlParamsJson), null, 2);
    const curl =
      'curl -X POST ' + curlEndpoint + '\\\n     -H "' + curlHeader + '" -d\\\n\'' + curlParamsJsonFormatted + "'";

    return (
      <Card title="Example cURL">
        <Code language="bash" code={curl} />
      </Card>
    );
  }

  return (
    <Card title="Example CURL">
      <></>
    </Card>
  );
};

export default FeatureServiceCurlCard;
