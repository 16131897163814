import React, { useEffect } from 'react';
import { useUserSettings } from '../../context/UserSettingsContext';
import { useParams } from 'react-router';
import { useDebugFeatureViewById } from '../../../feature/feature-views/feature-view/query';
import { logEvent } from '../../../utils/analytics-utils';
import { Card, Code, EmptyPrompt } from '../../@tecton/ComponentRedesign';
import { useDebugFeatureServiceById } from '../../../feature/feature-services/feature-service/query';
import { DebugWrapper } from './FeatureViewDebug';

const FeatureServiceDebug = () => {
  const { updateWorkspace } = useUserSettings();
  const { workspace, id } = useParams();
  const fvState = useDebugFeatureViewById(workspace, id);

  useEffect(() => {
    logEvent('Redesign: View FeatureServiceDebug');
  }, []);

  if (workspace) {
    updateWorkspace(workspace);
  }

  const fsState = useDebugFeatureServiceById(workspace, id);

  if (fvState.isLoading) {
    return <EmptyPrompt title="Loading" body={''} variant="loading" />;
  }

  const fs = fsState.data;

  if (!fs) {
    return (
      <>
        <EmptyPrompt variant="search" title={`Feature Service '${id}' not found`} body={''} />
      </>
    );
  }

  const fsJson = JSON.stringify(fs._proto, null, 2);

  return (
    <DebugWrapper>
      <Card title={'Proto'}>
        <Code language="json" code={fsJson}></Code>
      </Card>
    </DebugWrapper>
  );
};

export default FeatureServiceDebug;
