/* eslint-disable prefer-rest-params */
import { Theme } from '@emotion/react';
import styled from '@emotion/styled';
import { FlexGroupWrapper, FlexItem, Icon, Icons, IconTypes, Link, Text, Tooltip } from '@tecton/ComponentRedesign';
import React, { FC } from 'react';

// @svg
import DevelopmentWorkspace from '@svg/development-workspace.svg';
import LiveWorkspace from '@svg/live-workspace.svg';
import { FcoType } from '../../types/tecton_proto/metadataservice/metadata_service';
import { logEvent } from '../../utils/analytics-utils';
import { useUserSettings } from '../context/UserSettingsContext';
import { replaceStringWithReactNode } from '../shared/utils/utils';
import { getFcoData, GlobalSearchFCOProps } from './GlobalSearch';
import { breakPointForHidingFCODetails } from './SearchResultSelected';

const SearchListItemLink = styled(Link)<{ focused: boolean }>`
  text-decoration: none;
  width: 100%;
  background: ${({ focused, theme }) => (focused ? theme.colors.lightestShade : 'none')};
  border-radius: ${({ theme }) => theme.border.radius.small};
  padding: ${({ theme }) => theme.padding.s};
  outline: none;
  color: ${({ theme }) => theme.colors.text};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &.euiButtonIcon,
  &.euiLink {
    text-decoration: none;
  }

  &:hover {
    background: ${({ theme }) => theme.colors.lightestShade};
  }

  // hide the arrow
  .selected-arrow {
    visibility: ${({ focused }) => (focused ? 'visible' : 'hidden')};
    align-content: center;
  }

  &:focus,
  &:focus:focus-visible {
    outline: none;
    outline-style: none;

    // show the arrow when selected
    .selected-arrow {
      visibility: visible;
    }
  }
`;

const SearchResultsIcon = styled(FlexItem)<{ type: FcoType; themeColor: keyof Theme['FCOColors'] }>`
  & path {
    stroke: ${({ theme, themeColor }) => theme.FCOColors[themeColor]};
  }
`;

const FCONameWrapper = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 296px;
  font-weight: ${({ theme }) => theme.font.weight.medium};
  font-size: ${({ theme }) => theme.font.fontSizes.s};

  @media (max-width: calc(${breakPointForHidingFCODetails} + 64px)) {
    max-width: 222px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.m}px) {
    max-width: 48vw;
  }
`;

const FCONameMatchStyle = styled.div`
  display: contents;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  color: ${({ theme }) => theme.colors.title};
`;

const WorkspaceWrapper = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 140px;

  @media (max-width: calc(${breakPointForHidingFCODetails} + 64px)) {
    max-width: 12vw;
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.m}px) {
    max-width: 34vw;
  }
`;

const LiveWorkspaceIcon = <Icon type={LiveWorkspace} />;
const DevelopmentWorkspaceIcon = <Icon type={DevelopmentWorkspace} />;

const FCOWorkspace: FC<{ globalSearchItem: GlobalSearchFCOProps }> = ({ globalSearchItem }) => {
  return (
    <FlexGroupWrapper gap="xs" direction="row" alignItems="center" responsive={false}>
      <FlexItem grow={false}>
        {globalSearchItem.workspace?.workspaceType === 'Live' ? LiveWorkspaceIcon : DevelopmentWorkspaceIcon}
      </FlexItem>
      <FlexItem>
        <Tooltip
          content={globalSearchItem?.workspace?.name ?? ''}
          trigger={
            <Text weight="regular" small={true}>
              <WorkspaceWrapper>{globalSearchItem?.workspace?.name}</WorkspaceWrapper>
            </Text>
          }
        />
      </FlexItem>
    </FlexGroupWrapper>
  );
};

const SearchListResult: FC<{
  searchResult: GlobalSearchFCOProps;
  index: number;
  focusedIndex: number;
  setFocusedIndex: (value: React.SetStateAction<number>) => void;
  itemRefs: React.MutableRefObject<(HTMLButtonElement | HTMLAnchorElement | null)[]>;
  navigateToFco: (fco: GlobalSearchFCOProps) => void;
  filterValue: string | undefined;
}> = ({ searchResult, index, focusedIndex, setFocusedIndex, itemRefs, navigateToFco, filterValue = '' }) => {
  const { workspace } = useUserSettings();
  return (
    <SearchListItemLink
      key={searchResult.tabIndex}
      focused={focusedIndex === searchResult.tabIndex}
      tabIndex={searchResult.tabIndex}
      ref={(el) => {
        itemRefs.current[searchResult.tabIndex!] = el;
      }}
      onClick={() => {
        logEvent('Search', '', {
          action: 'Search results clicked',
          workspace,
          fcoWorkspace: searchResult.workspace?.name ?? '',
          fcoType: searchResult.type,
          fcoName: searchResult.name,
          searchRanking: `${index}`,
        });
        navigateToFco(searchResult);
      }}
      onMouseOver={() => {
        // highlight selected FCO on hover
        setFocusedIndex(searchResult.tabIndex);
        itemRefs?.current[searchResult.tabIndex]?.focus();
        logEvent('Search', '', {
          action: 'Search result inspected',
          workspace,
          fcoWorkspace: searchResult.workspace?.name ?? '',
          fcoType: searchResult.type,
          fcoName: searchResult.name,
          searchRanking: `${index}`,
        });
      }}
    >
      <FlexGroupWrapper
        key={index}
        gap="none"
        direction="row"
        justifyContent="spaceBetween"
        alignItems="center"
        responsive={false}
      >
        <FlexItem>
          <FlexGroupWrapper alignItems="center" direction="row" gap="s" responsive={false}>
            <FlexItem grow={false}>
              <SearchResultsIcon
                type={searchResult.type}
                grow={0}
                themeColor={getFcoData(searchResult.type).themeColor}
              >
                {getFcoData(searchResult.type).icon}
              </SearchResultsIcon>
            </FlexItem>
            <FlexItem grow={false}>
              {/*
                          We couldn't use the FCONameDisplayWithIconAndTooltip since we are making part of the FCO name bold to show a match.
                          If we decide to do this elsewhere, we should turn this into a component */}
              <Tooltip
                content={searchResult.name}
                trigger={
                  <FCONameWrapper>
                    {replaceStringWithReactNode(
                      searchResult.name,
                      filterValue,
                      <FCONameMatchStyle>{filterValue}</FCONameMatchStyle>
                    )}
                  </FCONameWrapper>
                }
              />
            </FlexItem>
            <FlexItem grow={false}>
              <FCOWorkspace globalSearchItem={searchResult} />
            </FlexItem>
          </FlexGroupWrapper>
        </FlexItem>
        <FlexItem grow={false}>
          <div className="selected-arrow">{Icons[IconTypes.SELECTED_ARROW]}</div>
        </FlexItem>
      </FlexGroupWrapper>
    </SearchListItemLink>
  );
};

export default SearchListResult;
