import { WorkspaceWithPermissions } from '../../../../components/context/types';
import { NewWorkspaceSelectorAutocompleteOption, NewWorkspaceSelectorDropdownType } from './NewWorkspaceSelector';

export const generateAutocompleteList: (
  workspaces: WorkspaceWithPermissions[]
) => NewWorkspaceSelectorAutocompleteOption[] = (workspaces) => {
  let autoCompleteOptions: NewWorkspaceSelectorAutocompleteOption[] = [];
  const liveWorkspaces = workspaces
    ?.filter((workspace) => workspace.capabilities['materializable'])
    .sort((a, b) => {
      return Number(b.isAccessible) - Number(a.isAccessible);
    });

  const devWorkspaces = workspaces
    ?.filter((workspace) => !workspace.capabilities['materializable'])
    .sort((a, b) => {
      return Number(b.isAccessible) - Number(a.isAccessible);
    });

  autoCompleteOptions.push({
    dropdownType: NewWorkspaceSelectorDropdownType.GROUP_HEADING,
    label: `Live Workspaces (${liveWorkspaces.length})`,
  });
  liveWorkspaces?.forEach((workspace) =>
    autoCompleteOptions.push({
      dropdownType: NewWorkspaceSelectorDropdownType.OPTION,
      label: workspace.name,
      ...workspace,
    })
  );

  autoCompleteOptions.push({
    dropdownType: NewWorkspaceSelectorDropdownType.GROUP_HEADING,
    label: `Development Workspaces (${devWorkspaces.length})`,
  });
  devWorkspaces?.forEach((workspace) =>
    autoCompleteOptions.push({
      dropdownType: NewWorkspaceSelectorDropdownType.OPTION,
      label: workspace.name,
      ...workspace,
    })
  );

  return autoCompleteOptions;
};
