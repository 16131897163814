/* eslint-disable */

export const protobufPackage = "tecton_proto.common";

export enum ServerGroupStatus {
  SERVER_GROUP_STATUS_UNSPECIFIED = "SERVER_GROUP_STATUS_UNSPECIFIED",
  /** SERVER_GROUP_STATUS_CREATING - ServerGroup has been applied by the user and a ServerGroupState is being created */
  SERVER_GROUP_STATUS_CREATING = "SERVER_GROUP_STATUS_CREATING",
  /** SERVER_GROUP_STATUS_PENDING - ServerGroup has been applied by the user, but a ServerGroupState hasn't been created yet */
  SERVER_GROUP_STATUS_PENDING = "SERVER_GROUP_STATUS_PENDING",
  /** SERVER_GROUP_STATUS_READY - ServerGroup is ready for compute / online serving */
  SERVER_GROUP_STATUS_READY = "SERVER_GROUP_STATUS_READY",
  SERVER_GROUP_STATUS_UPDATING = "SERVER_GROUP_STATUS_UPDATING",
  SERVER_GROUP_STATUS_ERROR = "SERVER_GROUP_STATUS_ERROR",
  SERVER_GROUP_STATUS_DELETING = "SERVER_GROUP_STATUS_DELETING",
}
