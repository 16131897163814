import React from 'react';
import styled from '@emotion/styled';
import { CARD_WIDTH } from '../helpers';

const defaultBorderRadius = '6px';
const ICON_BLOCK_WIDTH = `1.75em`;

type ColoredFCOTypes = 'data_source' | 'request_data_source' | 'feature_view' | 'odfv' | 'feature_service';

type Shades = Record<'light' | 'medium' | 'dark', string>;

const PURPLE: Shades = {
  dark: '#6257DC',
  medium: '#6A5EEE',
  light: '#C0BCED',
};
const BLUE: Shades = {
  dark: '#0FA9DA',
  medium: '#1BB8EA',
  light: '#8AD3EA',
};
const GREEN: Shades = {
  dark: '#26B17F',
  medium: '#28C68D',
  light: '#86E1C0',
};

const fcoColors: Record<ColoredFCOTypes, { background: string; fontColor: string; glow: string }> = {
  data_source: {
    background: GREEN.medium,
    fontColor: GREEN.dark,
    glow: GREEN.light,
  },
  request_data_source: {
    background: GREEN.medium,
    fontColor: GREEN.dark,
    glow: GREEN.light,
  },
  feature_view: {
    background: BLUE.medium,
    fontColor: BLUE.dark,
    glow: BLUE.light,
  },
  odfv: {
    background: BLUE.medium,
    fontColor: BLUE.dark,
    glow: BLUE.light,
  },
  feature_service: {
    background: PURPLE.medium,
    fontColor: PURPLE.dark,
    glow: PURPLE.light,
  },
};

const glowColor = (fcoType?: ColoredFCOTypes) => {
  if (fcoType) {
    return fcoColors[fcoType].glow;
  } else {
    return '#56CCF2';
  }
};

const BACKGROUND_PATH_CLASSNAME = 'edge-is-background-path';
const ONLINE_REQUEST_PATH_CLASSNAME = 'edge-is-online-request-path';
const ONLINE_SERVING_PATH_CLASSNAME = 'edge-is-online-serving-path';
const OFFLINE_READ_PATH_CLASSNAME = 'edge-is-offline-read-path';
const BATCH_MATERIALIZATION_PATH_CLASSNAME = 'edge-is-batch-materialization-path';
const STREAM_MATERIALIZATION_PATH_CLASSNAME = 'edge-is-stream-materialization-path';

const DataFlowWrapper = styled.div<{ height: string }>`
  height: ${(props) => props.height};
  border: 1px solid #d3dae6;
  border-radius: 3px;
  position: relative;
  overflow: hidden;

  .react-flow__handle {
    opacity: 0;
  }
`;

const inputSidePadding = '1.75em';
const thickBorderWidth = '12px';
export const opacityTransitionDuration = '0.3s';

const CARD_BACKGROUND = '#FBFCFF';
const CARD_BORDER = '#98A2B3';
const CARD_TITLE_COLOR = '#69707D';

const cardHeight = (props: { denseMode?: boolean; height?: number }) => {
  if (props.height) {
    return `${props.height}px`; // If height is specified, use that.
  } else {
    return props.denseMode ? 'calc(2.5em - 6px)' : 'inherit'; // Otherwise, use 2.5em in dense mode, or inherit in regular mode.
  }
};

const cardDefaultBoxShadow = `0 1px 4px 2px rgba(0, 0, 50, 0.2)`;
const anchorCardBoxShadow = `0 2px 3px 1.5px rgba(0, 0, 50, 0.3), 0 3px 8px 5px rgba(0, 0, 50, 0.2)`;

const DataflowCard = styled.div<{
  width?: number;
  height?: number;
  denseMode?: boolean;
  fcoType?: ColoredFCOTypes;
  background?: string;
  isFaded?: boolean;
  hasFocus?: boolean;
  isRightToLeft?: boolean;
  isUnused?: boolean;
  isNotClickable?: boolean;
}>`
  background: ${(props) => {
    if (props.isUnused) {
      return '#E8E9ED';
    } else {
      return props.background !== undefined ? props.background : CARD_BACKGROUND;
    }
  }};
  padding: ${(props) => (props.denseMode ? '0' : '1em')} ${inputSidePadding};
  width: ${(props) => (props.width !== undefined ? `${props.width}px` : `${CARD_WIDTH}px`)};
  height: ${(props) => cardHeight(props)};
  overflow: ${(props) => (props.denseMode ? 'hidden' : 'inherit')};
  border-radius: ${defaultBorderRadius};
  position: relative;
  cursor: ${(props) => (props.isNotClickable ? 'default' : 'pointer')};
  opacity: ${(props) => (props.isFaded ? 0.15 : 1)};
  transition: opacity ${opacityTransitionDuration};
  box-shadow: ${(props) => (props.hasFocus ? `0 0 20px 10px ${glowColor(props.fcoType)}` : cardDefaultBoxShadow)}};
`;

const NodeSVG = styled.svg<{ width: number; height: number }>`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
`;

const OutputSchemaAnchor = styled.a<{
  standalone?: boolean;
  anchorLeftSide?: boolean;
  fcoType?: ColoredFCOTypes;
  isUnused?: boolean;
}>`
  ${(props) => {
    if (!props.standalone) {
      return `
      position: absolute;
      top: 50%;
      transform: translate(0, calc(-1.25em));
      `;
    } else {
      return '';
    }
  }}
  display: block;
  font-size: 10px;
  font-weight: bold;
  ${(props) => {
    if (props.anchorLeftSide) {
      return 'left: -1.25em;';
    } else {
      return 'right: -1.25em;';
    }
  }}
  background: ${(props) => {
    if (props.isUnused) {
      return '#69707D';
    } else {
      return props.fcoType && fcoColors[props.fcoType] ? fcoColors[props.fcoType].fontColor : 'black';
    }
  }};
  color: #eee;
  text-align: center;
  letter-spacing: -0.1em;
  width: 2.5em;
  height: 2.5em;
  border-radius: 1.25em;
  line-height: 2.5em;
  cursor: pointer;

  &:hover {
    color: white;
  }

  .euiToolTipAnchor {
    width: 100%;
  }
`;

const PreHead = styled.p<{
  fcoType?: ColoredFCOTypes;
}>`
  font-size: 11px;
  line-height: 0.95;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-weight: bold;
  color: ${CARD_TITLE_COLOR};
`;

const ObjectTitleMargins = (isDenseMode: boolean | undefined) => {
  if (isDenseMode) {
    return `
    margin-top: 0.35em;
    margin-left: 0.85em;
    `;
  } else {
    return `
    margin-top: 0.25em;
    margin-left: 0;
    `;
  }
};

const denseModeTextTruncationCss = `
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const ObjectTitle = styled.p<{ denseMode?: boolean }>`
  font-size: 16px;
  ${(props) => props.denseMode && denseModeTextTruncationCss}
  overflow-wrap: break-word;
  line-height: 1.1;
  ${(props) => ObjectTitleMargins(props.denseMode)}
`;

const AnchorWrapper = styled.div<{ isFaded?: boolean }>`
  cursor: pointer;
  opacity: ${(props) => (props.isFaded ? 0.15 : 1)};
`;

const AnchorDataflowCard = styled.div<{
  width?: number;
  height?: number;
  fcoType?: ColoredFCOTypes;
  isFaded?: boolean;
  hasFocus?: boolean;
  hasBackground?: boolean;
}>`
  width: ${(props) => (props.width !== undefined ? `${props.width}px` : `${CARD_WIDTH}px`)};
  height: ${(props) => (props.height !== undefined ? `${props.height}px` : 'inherit')};
  border-radius: ${defaultBorderRadius};
  position: relative;

  opacity: ${(props) => (props.isFaded ? 0.15 : 1)};
  transition: opacity ${opacityTransitionDuration};
  // box-shadow: ${(props) => (props.hasFocus ? `0 0 20px 10px ${glowColor(props.fcoType)}` : anchorCardBoxShadow)};
  background: ${(props) => (props.hasBackground ? 'white' : 'inherit')};
`;

const AnchorHeader = styled.div<{ fcoType?: ColoredFCOTypes }>`
  background: white;
  border-bottom: 1px solid #999;
  padding: 1.2em 1.75em 0.7em 1.75em;
  border-top-right-radius: ${defaultBorderRadius};
  border-top-left-radius: ${defaultBorderRadius};
`;

const AnchorTitle = styled.p`
  font-size: 16px;
  line-height: 1.1;
  overflow-wrap: break-word;
`;

const AnchorDataflowCardDescriptionWrapper = styled.div`
  padding: 0.7em 1.75em;
`;

const SvgWithGlowWrapper = styled.span<{
  isFaded?: boolean;
  hasFocus?: boolean;
  inset?: [number, number];
}>`
  position: relative;
  opacity: ${(props) => (props.isFaded ? 0.15 : 1)};
  transition: opacity ${opacityTransitionDuration};

  svg {
    display: block;
    position: relative;
    z-index: 2;
  }

  ${({ hasFocus, inset }) => {
    if (!hasFocus) {
      return '';
    }

    return `
    &:after {
      content: '';
      position: absolute;
      z-index: 1;
      top: ${inset ? inset[1] : 0}px;
      left: ${inset ? inset[0] : 0}px;
      width: calc(100% - ${inset ? inset[0] * 2 : 0}px);
      height: calc(100% - ${inset ? inset[1] * 2 : 0}px);

      box-shadow: 0 0 20px 10px ${fcoColors['feature_view'].glow};
    }
    `;
  }}
`;

const StoreIconWrapper = styled.div<{
  isFaded?: boolean;
  hasFocus?: boolean;
  isNotClickable?: boolean;
}>`
  text-align: center;
  width: 80px;
  line-height: 1;
  opacity: ${(props) => (props.isFaded ? 0.15 : 1)};
  transition: opacity ${opacityTransitionDuration};
  cursor: ${(props) => (props.isNotClickable ? 'default' : 'pointer')};
  position: relative;

  span:first-child {
    display: inline-block;
  }

  .tecton-store-type {
    position: relative;
    z-index: 10;
    font-size: 10px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 2px;
  }
`;

export {
  fcoColors,
  defaultBorderRadius,
  DataFlowWrapper,
  DataflowCard,
  PreHead,
  ObjectTitle,
  OutputSchemaAnchor,
  AnchorWrapper,
  AnchorDataflowCard,
  AnchorHeader,
  AnchorTitle,
  AnchorDataflowCardDescriptionWrapper,
  StoreIconWrapper,
  NodeSVG,
  SvgWithGlowWrapper,
  ICON_BLOCK_WIDTH,
  CARD_BORDER,
  BACKGROUND_PATH_CLASSNAME,
  ONLINE_SERVING_PATH_CLASSNAME,
  ONLINE_REQUEST_PATH_CLASSNAME,
  OFFLINE_READ_PATH_CLASSNAME,
  BATCH_MATERIALIZATION_PATH_CLASSNAME,
  STREAM_MATERIALIZATION_PATH_CLASSNAME,
};

export type { ColoredFCOTypes };
