import styled from '@emotion/styled';

export const DataFlowOpacityWrapper = styled.div<{
  isFaded: boolean;
}>`
  opacity: ${(props) => (props.isFaded ? 0.125 : 1)};
  transition: opacity 0.3s;
`;

export const DataFlowWrapper = styled.div<{ height: number }>`
  height: ${(props) => props.height};
  border: 1px solid #d3dae6;

  border-radius: 3px;
  position: relative;
  overflow: hidden;

  .react-flow__handle {
    opacity: 0;
  }
`;
