import styled from '@emotion/styled';
import React, { FC } from 'react';
import { ColoredFCOTypes, defaultBorderRadius, fcoColors } from './DataFlowConstants';
import { CARD_WIDTH } from './helpers';
import DataFlowFCONodeTitle from './DataflowFCONodeTitle';
import { DataSourceNode, FeatureServiceNode, FeatureViewNode } from './dataFlowTypes';

interface DataFlowObjectPipelineFCONodeProps {
  data: DataSourceNode | FeatureViewNode | FeatureServiceNode;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  onClick: (event: React.MouseEvent) => void;
  hasFocus: boolean;
  isFaded: boolean;
}

const glowColor = (fcoType?: ColoredFCOTypes) => {
  if (fcoType) {
    return fcoColors[fcoType].glow;
  } else {
    return '#56CCF2';
  }
};

const AnchorWrapper = styled.div<{ isFaded?: boolean }>`
  cursor: pointer;
  opacity: ${(props) => (props.isFaded ? 0.15 : 1)};
`;

const anchorCardBoxShadow = `0 2px 3px 1.5px rgba(0, 0, 50, 0.3), 0 3px 8px 5px rgba(0, 0, 50, 0.2)`;

const AnchorDataflowCard = styled.div<{
  width?: number;
  height?: number;
  fcoType?: ColoredFCOTypes;
  isFaded?: boolean;
  hasFocus?: boolean;
  hasBackground?: boolean;
}>`
  width: ${(props) => (props.width !== undefined ? `${props.width}px` : `${CARD_WIDTH}px`)};
  height: ${(props) => (props.height !== undefined ? `${props.height}px` : 'inherit')};
  border-radius: ${defaultBorderRadius};
  position: relative;

  opacity: ${(props) => (props.isFaded ? 0.15 : 1)};
  transition: opacity 0.3;
  box-shadow: ${(props) => (props.hasFocus ? `0 0 20px 10px ${glowColor(props.fcoType)}` : anchorCardBoxShadow)};
  background: ${(props) => (props.hasBackground ? 'white' : 'inherit')};
`;

const AnchorHeader = styled.div<{ fcoType?: ColoredFCOTypes }>`
  background: white;
  border-bottom: 1px solid #999;
  padding: 1.2em 1.75em 0.7em 1.75em;
  border-top-right-radius: ${defaultBorderRadius};
  border-top-left-radius: ${defaultBorderRadius};
`;

const AnchorTitle = styled.p`
  font-size: 24px;
  line-height: 1.1;
  overflow-wrap: break-word;
`;

const AnchorDataflowCardDescriptionWrapper = styled.div`
  padding: 0.7em 1.75em;
`;

const DataFlowObjectPipelineFCONode: FC<DataFlowObjectPipelineFCONodeProps> = ({
  data,
  onMouseEnter,
  onMouseLeave,
  onClick,
  hasFocus,
  isFaded,
}) => {
  return (
    <AnchorWrapper>
      <AnchorDataflowCard
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={onClick}
        hasFocus={hasFocus}
        isFaded={isFaded}
        fcoType="data_source"
        hasBackground
      >
        <AnchorHeader fcoType="data_source">
          <DataFlowFCONodeTitle data={data} />
          <AnchorTitle>{data.name}</AnchorTitle>
        </AnchorHeader>
        <AnchorDataflowCardDescriptionWrapper>
          <p>{data.description}</p>
        </AnchorDataflowCardDescriptionWrapper>
      </AnchorDataflowCard>
    </AnchorWrapper>
  );
};

export default DataFlowObjectPipelineFCONode;
