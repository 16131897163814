import { FC } from 'react';
import { BatchDataSourceType, DataSourceFCO, DataSourceFCOFields } from '../../../core/types/fcoTypes';

import {
  EmptyValue,
  FCOCard,
  Monospace,
  TimeIntervalDisplay,
  TruncateText,
  ViewDefinitionFlyout,
} from '@tecton/ComponentRedesign';

interface BatchConfigCardProps {
  source: DataSourceFCO;
}

const MonospaceOrEmpty: FC<{ property: unknown }> = ({ property }) => {
  if (property)
    return (
      <Monospace>
        <>{property}</>
      </Monospace>
    );
  return <EmptyValue />;
};

const postProcessorField = (fco: DataSourceFCO) => {
  if (fco.batchDataSource?.raw_batch_translator) {
    return {
      title: <>Post-Processor</>,
      content: (
        <Flyout
          type={fco.batchDataSource.raw_batch_translator.name ?? ''}
          definition={fco.batchDataSource.raw_batch_translator.body ?? ''}
        />
      ),
    };
  }
  return {
    title: <>Post-Processor</>,
    content: <>-</>,
  };
};

const Flyout: FC<{ type: any; definition: any }> = ({ type, definition }) => {
  return (
    <>
      <ViewDefinitionFlyout type={type} definition={definition.toString()} />
    </>
  );
};

const FileConfigCard: FC<BatchConfigCardProps> = ({ source }) => {
  const list = [
    {
      title: <>URI</>,
      content: <MonospaceOrEmpty property={source.batchDataSource?.file?.uri} />,
    },
    {
      title: <>File Format</>,
      content: <MonospaceOrEmpty property={source.batchDataSource?.file?.format} />,
    },
    {
      title: <>Timestamp Field</>,
      content: <MonospaceOrEmpty property={source.batchDataSource?.timestamp_column_properties?.column_name} />,
    },
    {
      title: <>Timestamp Format</>,
      content: <MonospaceOrEmpty property={source.batchDataSource?.timestamp_column_properties?.format} />,
    },
    postProcessorField(source),
    {
      title: <>Data Delay</>,
      content: <TimeIntervalDisplay interval={source[DataSourceFCOFields.BATCH_DATA_DELAY]} />,
    },
  ];

  return <FCOCard title="Batch Configuration" items={list} />;
};

const HiveConfigCard: FC<BatchConfigCardProps> = ({ source }) => {
  const list = [
    {
      title: <>Timestamp Field</>,
      content: <MonospaceOrEmpty property={source.batchDataSource?.timestamp_column_properties?.column_name} />,
    },
    {
      title: <>Timestamp Format</>,
      content: <MonospaceOrEmpty property={source.batchDataSource?.timestamp_column_properties?.format} />,
    },
    {
      title: <>Table</>,
      content: <MonospaceOrEmpty property={source.batchDataSource?.hive_table?.table} />,
    },
    {
      title: <>Database</>,
      content: <MonospaceOrEmpty property={source.batchDataSource?.hive_table?.database} />,
    },
    postProcessorField(source),
    {
      title: <>Data Delay</>,
      content: <TimeIntervalDisplay interval={source[DataSourceFCOFields.BATCH_DATA_DELAY]} />,
    },
  ];

  return <FCOCard title="Hive Configuration" items={list} />;
};

const RedshiftConfigCard: FC<BatchConfigCardProps> = ({ source }) => {
  const query = source.batchDataSource?.redshift_db?.query;

  const list = [
    {
      title: <>Table</>,
      content: <MonospaceOrEmpty property={source.batchDataSource?.redshift_db?.table} />,
    },
    {
      title: <>Database</>,
      content: <MonospaceOrEmpty property={source.batchDataSource?.redshift_db?.database} />,
    },
    {
      title: <>Timestamp Field</>,
      content: <MonospaceOrEmpty property={source.batchDataSource?.timestamp_column_properties?.column_name} />,
    },
    postProcessorField(source),
    {
      title: <>Data Delay</>,
      content: <TimeIntervalDisplay interval={source[DataSourceFCOFields.BATCH_DATA_DELAY]} />,
    },
    {
      title: <>Query</>,
      content: query ? (
        <>
          <Flyout type={'Redshift Query'} definition={query ?? ''} />
        </>
      ) : (
        <EmptyValue />
      ),
    },
    {
      title: <>Endpoint</>,
      content: (
        <TruncateText>
          <MonospaceOrEmpty property={source.batchDataSource?.redshift_db?.endpoint} />
        </TruncateText>
      ),
    },
  ];

  return <FCOCard title="Redshift Configuration" items={list} />;
};

const SnowflakeConfigCard: FC<BatchConfigCardProps> = ({ source }) => {
  const args = source.batchDataSource?.snowflake?.snowflakeArgs;

  const list = [
    {
      title: <>Database</>,
      content: <MonospaceOrEmpty property={args?.database} />,
    },
    {
      title: <>Schema</>,
      content: <MonospaceOrEmpty property={args?.schema} />,
    },
    {
      title: <>Warehouse</>,
      content: <MonospaceOrEmpty property={args?.warehouse} />,
    },
    {
      title: <>URL</>,
      content: <MonospaceOrEmpty property={args?.url} />,
    },
    {
      title: <>Role</>,
      content: <MonospaceOrEmpty property={args?.role} />,
    },
    {
      title: <>Table</>,
      content: <MonospaceOrEmpty property={args?.table} />,
    },

    {
      title: <>Query</>,
      content: args?.query ? (
        <>
          <Flyout type={'Snowflake Query'} definition={args?.query ?? ''} />
        </>
      ) : (
        <EmptyValue />
      ),
    },
    {
      title: <>Timestamp Field</>,
      content: <MonospaceOrEmpty property={source.batchDataSource?.timestamp_column_properties?.column_name} />,
    },

    postProcessorField(source),
    {
      title: <>Data Delay</>,
      content: <TimeIntervalDisplay interval={source[DataSourceFCOFields.BATCH_DATA_DELAY]} />,
    },
  ];

  return <FCOCard title="Batch Configuration" items={list} />;
};

const UnityConfigCard: FC<BatchConfigCardProps> = ({ source }) => {
  const list = [
    {
      title: <>Catalog</>,
      content: <MonospaceOrEmpty property={source.batchDataSource?.unity_table?.catalog} />,
    },
    {
      title: <>Schema</>,
      content: <MonospaceOrEmpty property={source.batchDataSource?.unity_table?.schema} />,
    },
    {
      title: <>Table</>,
      content: <MonospaceOrEmpty property={source.batchDataSource?.unity_table?.table} />,
    },

    {
      title: <>Timestamp Field</>,
      content: <MonospaceOrEmpty property={source.batchDataSource?.timestamp_column_properties?.column_name} />,
    },
    {
      title: <>Timestamp Format</>,
      content: <MonospaceOrEmpty property={source.batchDataSource?.timestamp_column_properties?.format} />,
    },

    postProcessorField(source),
    {
      title: <>Data Delay</>,
      content: <TimeIntervalDisplay interval={source[DataSourceFCOFields.BATCH_DATA_DELAY]} />,
    },
  ];

  return <FCOCard title="Batch Configuration" items={list} />;
};

const SparkConfigCard: FC<BatchConfigCardProps> = ({ source }) => {
  const list = [
    {
      title: <>Data Source Function</>,
      content: (
        <>
          <ViewDefinitionFlyout
            type={source.batchDataSource?.spark_data_source_function?.function?.name ?? ''}
            definition={source.batchDataSource?.spark_data_source_function?.function?.body ?? ''}
          />
        </>
      ),
    },
    {
      title: <>Data Delay</>,
      content: <TimeIntervalDisplay interval={source[DataSourceFCOFields.BATCH_DATA_DELAY]} />,
    },
    {
      title: <>Supports Time Filtering</>,
      content: (
        <Monospace>
          {source.batchDataSource?.spark_data_source_function?.supports_time_filtering ? 'true' : 'false'}
        </Monospace>
      ),
    },
  ];

  return <FCOCard title="Batch Configuration" items={list} />;
};

const PandasConfigCard: FC<BatchConfigCardProps> = ({ source }) => {
  const list = [
    {
      title: <>Data Source Function</>,
      content: (
        <>
          <Flyout
            type={source.batchDataSource?.pandas_data_source_function?.function?.name ?? ''}
            definition={source.batchDataSource?.pandas_data_source_function?.function?.body ?? ''}
          />
        </>
      ),
    },
    {
      title: <>Data Delay</>,
      content: <TimeIntervalDisplay interval={source[DataSourceFCOFields.BATCH_DATA_DELAY]} />,
    },
    {
      title: <>Supports Time Filtering</>,
      content: (
        <Monospace>
          {source.batchDataSource?.pandas_data_source_function?.supports_time_filtering ? 'true' : 'false'}
        </Monospace>
      ),
    },
  ];

  return <FCOCard title="Batch Configuration" items={list} />;
};

const BatchConfigCard: FC<BatchConfigCardProps> = ({ source }) => {
  switch (source[DataSourceFCOFields.BATCH_SOURCE]) {
    case BatchDataSourceType.FILE:
      return <FileConfigCard source={source} />;

    case BatchDataSourceType.HIVE:
      return <HiveConfigCard source={source} />;

    case BatchDataSourceType.SNOWFLAKE:
      return <SnowflakeConfigCard source={source} />;
      break;
    case BatchDataSourceType.REDSHIFT:
      return <RedshiftConfigCard source={source} />;
      break;
    case BatchDataSourceType.S3:
      return <></>; // This is legacy and means that batch is not valid and should not be included
    case BatchDataSourceType.DATA_SOURCE_FUNCTION:
      return <SparkConfigCard source={source} />;

    case BatchDataSourceType.UNITY:
      return <UnityConfigCard source={source} />;

    case BatchDataSourceType.PANDAS:
      return <PandasConfigCard source={source} />;

    case BatchDataSourceType.UNKNOWN:
      return <></>;
  }

  return <></>;
};

export default BatchConfigCard;
