/**
 * Having an interval higher than the widest Retention resolution in Chronosphere
 * (see https://tecton.chronosphere.io/settings/ingest-config) can result customer
 * confusion, so let's keep this to 5 minutes or less. Ideally, it'd be the narrowest
 * resolution (15s at the time of writing this), but that might be too busy.
 *
 * For details, see CS-3263. TL;DR using an Interval that's wider than the data's
 * resolution can lower the peak values of spikes in timeseries values. Furthermore, since
 * the Retention resolution widens over time as the data gets older, peaks go down over
 * time (even though the underlying data didn't change). We can avoid this by having a
 * narrow interval.
 */
export const interval = '5m';
