import React from 'react';
import { Link } from 'react-router-dom';
import { Outlet, useMatch, useNavigate, useParams } from 'react-router';

import { getACLRoutes } from './acl-util';
import { Routes } from '../../../core/routes';

// @tecton
import { HeaderLayout, ViewLayout, Tabs, Breadcrumbs } from '@tecton/ComponentRedesign';
import { useGetACLUserTable } from '../../../api/queries/aclGraphql';
import { useUserSettings } from '../../context/UserSettingsContext';

const ACLTabs = () => {
  const navigation = useNavigate();
  const { user, serviceAccount, group } = useParams();
  const { user: loggedInUser, isAdmin } = useUserSettings();
  const { data } = useGetACLUserTable(loggedInUser?.sub ?? '', isAdmin ?? false);

  const indexRoute = {
    pathName: 'Users',
    path: `./accounts-and-access/users`,
    paramsContext: user,
    isMatch: !!useMatch({
      path: `${Routes.accountsAndAccessUsers}`,
      end: false,
    }),
    count: data?.users?.length ?? 0,
  };

  const pages = [
    indexRoute,
    {
      pathName: 'Service Accounts',
      path: `./accounts-and-access/service-accounts`,
      paramsContext: serviceAccount,
      isMatch: !!useMatch({ path: Routes.accountsAndAccessServiceAccounts, end: false }),
      count: data?.serviceAccounts?.length ?? 0,
    },
    {
      pathName: 'Groups',
      path: `./accounts-and-access/groups`,
      paramsContext: group,
      isMatch: !!useMatch({ path: Routes.accountsAndAccessGroups, end: false }),
      count: data?.groups?.length ?? 0,
    },
  ];

  const selectedPage = pages.find((page) => page.isMatch) ?? indexRoute;

  return (
    <Tabs
      selectedTab={`${selectedPage?.pathName}`}
      tabsWithCount={pages.map((page) => {
        return { label: page.pathName, count: page.count };
      })}
      setSelectedTab={(tab: string) => {
        const routes = getACLRoutes();
        navigation(routes[tab]);
      }}
    />
  );
};

const AccountsAndAccessOutlet = () => {
  const { user, serviceAccount, group } = useParams();
  const BREAD_CRUMB_ROOT_PAGE = 'Accounts & Access';
  const indexRoute = {
    pathName: 'Users',
    path: `./accounts-and-access/users`,
    paramsContext: user,
    isMatch: !!useMatch({
      path: `${Routes.accountsAndAccessUsers}`,
      end: false,
    }),
  };

  const pages = [
    indexRoute,
    {
      pathName: 'Service Accounts',
      path: `./accounts-and-access/service-accounts`,
      paramsContext: serviceAccount,
      isMatch: !!useMatch({ path: Routes.accountsAndAccessServiceAccounts, end: false }),
    },
    {
      pathName: 'Groups',
      path: `./accounts-and-access/groups`,
      paramsContext: group,
      isMatch: !!useMatch({ path: Routes.accountsAndAccessGroups, end: false }),
    },
  ];

  const selectedPage = pages.find((page) => page.isMatch) ?? indexRoute;

  const breadcrumbs = (
    <Breadcrumbs
      workspace={<Link to={`${Routes.accountsAndAccessUsers}`}>{BREAD_CRUMB_ROOT_PAGE}</Link>}
      crumbs={[
        {
          label: selectedPage.paramsContext ? (
            <Link to={`./${selectedPage?.path}`}>{selectedPage?.pathName}</Link>
          ) : (
            selectedPage?.pathName
          ),
        },
        ...(selectedPage.paramsContext ? [{ label: selectedPage?.paramsContext }] : []),
      ]}
    />
  );

  const tabs = user || serviceAccount || group ? [] : <ACLTabs />;
  const header = <HeaderLayout breadcrumbs={breadcrumbs} tabs={tabs} />;
  return <ViewLayout header={header} body={<Outlet />} />;
};

export default AccountsAndAccessOutlet;
