import edgeIdFn from './edgeIdFn';
import {
  getVisibleAnimationsOnAllEdgesGivenAnchors,
  mergeEdgeAnimationsRecords,
  VisibleAnimationRecord,
} from './highlight-utils';
import highlightDownstream from './highlightDownstream';
import { HighlightFunctionType } from './highlightFunctionType';
import highlightUpstream from './highlightUpstream';

const highlightFeatureView: HighlightFunctionType = (id, nodesMap, edgesList) => {
  const node = nodesMap[id];

  const upstream = highlightUpstream(id, nodesMap, edgesList);

  const internalNodes = new Set<string>();
  const internalEdges = new Set<string>();
  if (node.isAnchor) {
    Object.entries(nodesMap).forEach(([key, node]) => {
      if (node.type === 'transformation' || node.type === 'aggregation') {
        internalNodes.add(key);
      }
    });

    edgesList.forEach((edge) => {
      if (internalNodes.has(edge.source) || internalNodes.has(edge.target)) {
        internalEdges.add(edgeIdFn(edge));
      }
    });
  }

  const downstreamToStore = highlightDownstream(id, nodesMap, edgesList);

  const linkedIds = new Set([...upstream.linkedIds, ...downstreamToStore.linkedIds, ...internalNodes]);
  const linkedEdges = new Set([...upstream.linkedEdges, ...downstreamToStore.linkedEdges, ...internalEdges]);

  let animations: Record<string, VisibleAnimationRecord> = {};

  const downstreamFeatureServices = [...downstreamToStore.linkedIds].filter(
    (id) => nodesMap[id].type === 'feature_service'
  );

  const featureViewAnimations = getVisibleAnimationsOnAllEdgesGivenAnchors(
    new Set<string>([id]),
    edgesList,
    linkedEdges
  );

  const featureServiceAnimations = getVisibleAnimationsOnAllEdgesGivenAnchors(
    new Set<string>(downstreamFeatureServices),
    edgesList.filter((edge) => {
      const source = nodesMap[edge.source];
      const target = nodesMap[edge.target];

      // Handling the case where the feature view is not offline materialized
      // See the dataflow-test-stream-materialization-variants workspace for an example
      if (node.type === 'feature_view' && !node.isOfflineMaterializationEnabled) {
        // If the source is a feature view, target is a feature service, and the feature view is not offline materialized
        // then we want to show offline read edges on the materialization size of the diagram
        return true;
      } else {
        // Otherwise, we filter out the edges that are on the materialization side of the diagram
        return !(source.type === 'feature_view' || target.type === 'feature_view' || target.type === 'data_source');
      }
    }),
    linkedEdges
  );

  animations = mergeEdgeAnimationsRecords([featureViewAnimations, featureServiceAnimations]);

  return {
    linkedIds,
    linkedEdges,
    animations,
  };
};

export default highlightFeatureView;
