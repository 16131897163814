import { Callout, FlexGrid, FlexGroup, FlexItem, Modal, Table, Text } from '@tecton/ComponentRedesign';
import { ToastContext, addToast } from '@tecton/ToastContext';
import { useContext, useState } from 'react';
import { Job } from '../../../api/gql/graphql';
import { useCancelJobMutation, useForceRetryMaterializationTaskMutation } from '../../../api/jobs';
import { useTimezoneSettings } from '../../../components/context/TimezoneContext';
import { Id } from '../../../types/tecton_proto/common/id';
import { ForceRetryMaterializationTaskResponse } from '../../../types/tecton_proto/metadataservice/metadata_service';
import { DateWithInvalidFallback } from './JobsUtils';
import { JobsTaskTypeValue } from './types';

interface ActionConfirmationModalProps {
  job: Job | undefined;
  onClose: () => void;
  confirmChange: (data: any) => void;
  workspace?: string;
  feature_view?: string;
}

const ModalTable = ({ job }: { job: Job | undefined }) => {
  const { timezone } = useTimezoneSettings();
  const store: string[] = [];
  if (job?.writesOnline) store.push('online');
  if (job?.writesOffline) store.push('offline');
  return (
    <Table<Job | undefined, null>
      items={[job]}
      columns={[
        {
          name: 'Type',
          field: 'fvType',
          width: '15%',
          render: (type: JobsTaskTypeValue) => <>{type.label}</>,
        },
        {
          name: 'Store',
          field: 'store',
          width: '20%',
          render: () => <>{store.join(', ')}</>,
        },
        {
          name: 'Feature Time Range',
          field: 'featureStartTime',
          width: '50%',
          render: (_, job: Job | undefined) => {
            return (
              <FlexGroup>
                <FlexItem grow={false}>
                  {job?.featureStartTime ? DateWithInvalidFallback(job?.featureStartTime, timezone) : ''}
                </FlexItem>
                <FlexItem grow={false}>&mdash;</FlexItem>
                <FlexItem grow={false}>
                  {job?.featureEndTime ? DateWithInvalidFallback(job?.featureEndTime as string, timezone) : ''}
                </FlexItem>
              </FlexGroup>
            );
          },
        },
      ]}
    />
  );
};

export const RetryConfirmationModal = ({ job, onClose, confirmChange }: ActionConfirmationModalProps) => {
  const forceRetryMaterializationMutation = useForceRetryMaterializationTaskMutation();
  const toastContext = useContext(ToastContext);
  const isOverwrite = job?.allowOverwrite;
  const title = isOverwrite ? 'Overwrite' : 'Retry';
  const titleAction = isOverwrite ? 'Overwriting' : 'Retrying';

  const confirm = () => {
    forceRetryMaterializationMutation.mutate(
      { materialization_task_id: job?.fullId as Id, allow_overwrite: job?.allowOverwrite as boolean },
      {
        onSuccess: (data: ForceRetryMaterializationTaskResponse) => {
          if (data?.error_message) {
            // Check for error. This should be really a 500 return not a 200.
            toastContext?.dispatchToast(
              addToast({
                title: `Retry Error`,
                text: `${data.error_message}`,
                iconType: 'alert',
                color: 'danger',
              })
            );
          } else {
            toastContext?.dispatchToast(
              addToast({
                title: `${title} scheduled`,
                text: `A new materialization job with the same parameters has been scheduled`,
                iconType: 'checkInCircleFilled',
                color: 'success',
              })
            );
          }
          // We call this so the modal closes and a new state for the refresh button gets updated
          // Since we invalidate the jobs query. This avoids users from spamming retries.
          confirmChange(data);
        },
      }
    );
  };
  return (
    <Modal
      title={`${title} ${job?.id ?? ''}?`}
      body={
        <FlexGrid>
          <FlexItem>A new materialization job with the same parameters will be scheduled:</FlexItem>
          <FlexItem>
            <ModalTable job={job} />
          </FlexItem>
          <FlexItem>
            <Text>
              For the offline stores, all previously materialized data between the start and end time will be dropped.
            </Text>
            <Text>For the online store, all previous data will remain, but may be overwritten by this job.</Text>
          </FlexItem>
          <FlexItem>
            <Callout mode="neutral" title={'Note'}>
              <Text>
                {titleAction} online store data can be a risky operation, we recommend consulting Tecton if you have
                questions{' '}
              </Text>
            </Callout>
          </FlexItem>
        </FlexGrid>
      }
      variant="positive"
      onCancel={onClose}
      onConfirm={confirm}
      isConfirmButtonLoading={forceRetryMaterializationMutation.isLoading}
    />
  );
};

export const CancelConfirmationModal = ({ job, onClose, confirmChange }: ActionConfirmationModalProps) => {
  const cancelJobMutation = useCancelJobMutation();
  const toastContext = useContext(ToastContext);
  const [errorMessage, setErrorMessage] = useState('');

  const job_id = job?.id || '';
  const feature_view = job?.featureView || undefined;
  const workspace = job?.workspace || undefined;

  const confirm = () => {
    cancelJobMutation.mutate(
      { workspace, job_id, feature_view },
      {
        onSuccess: (data: any) => {
          if (data?.error_message) {
            setErrorMessage(data.error_message);
            addToast({
              title: `Cancel Error`,
              text: `${data.error_message}`,
              iconType: 'alert',
              color: 'danger',
            });
          } else {
            setErrorMessage('');
            confirmChange(data);
            toastContext?.dispatchToast(
              addToast({
                title: `Materialization attempt cancelled`,
                text: `No new materialization attempts will be run for this job`,
                iconType: 'checkInCircleFilled',
                color: 'success',
              })
            );
          }
        },
        onError: (error) => {
          setErrorMessage(error.message);
        },
      }
    );
  };
  return (
    <Modal
      title={`Cancel ${job_id}?`}
      body={
        <FlexGrid>
          <FlexItem>
            Are you sure you want to cancel job? No new materialization attempts will be run for this job. You will be
            able to retry the materialization job manually after its been cancelled.
          </FlexItem>
          <FlexItem>
            <Callout mode={errorMessage ? 'danger' : 'neutral'} title={errorMessage ? 'Unable to Cancel' : 'Note'}>
              <Text>
                {errorMessage ? (
                  <>{errorMessage}</>
                ) : (
                  <>
                    To prevent future jobs from being scheduled, change your feature view definition to disable
                    materialization.
                  </>
                )}
              </Text>
            </Callout>
          </FlexItem>
        </FlexGrid>
      }
      onCancel={onClose}
      onConfirm={confirm}
      isConfirmButtonLoading={cancelJobMutation.isLoading}
      confirmVariant={errorMessage ? 'disabledAction' : 'dangerousAction'}
    />
  );
};
