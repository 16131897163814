import React from 'react';
import moment from 'moment';
import { Select, SelectableOption } from '@tecton/ComponentRedesign';
import { useTimezoneSettings } from '../../../components/context/TimezoneContext';
import { ReactComponent as Timezone } from '@svg/timezone.svg';

type TimezoneOptionProps = SelectableOption & {
  name: string;
};
const IANA_UTC = 'Etc/UTC';
const guessedTimezone = moment.tz.guess();

const timezones = [
  { name: IANA_UTC, label: moment.tz(IANA_UTC).zoneAbbr() },
  { name: guessedTimezone, label: moment.tz(guessedTimezone).zoneAbbr() },
];

export const TimezoneSelector: React.FC = () => {
  const { timezone, updateTimezone } = useTimezoneSettings();

  const handleChange = (options: TimezoneOptionProps[], e?: any, option?: TimezoneOptionProps) => {
    updateTimezone(option?.name || IANA_UTC);
  };

  return (
    <Select
      options={timezones}
      icon={Timezone}
      label={moment.tz(timezone).zoneAbbr()}
      onChange={(options, e, option) => handleChange(options, e, option)}
    />
  );
};

export default TimezoneSelector;
