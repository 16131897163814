import styled from '@emotion/styled';
import {
  Card,
  CardGridLayout,
  EmptyPrompt,
  ErrorPromptButtons,
  FCOIconAndName,
  Table,
  TitledItem,
  TwoCardRow,
} from '@tecton/ComponentRedesign';
import moment from 'moment-timezone';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { AnyFCO, DatasetFCO, DatasetFCOFields, FCOFields, FCOType } from '../../../core/types/fcoTypes';
import Monospace from '../../@tecton/ComponentRedesign/lib/Monospace';
import { TectonDateTimeFormat } from '../../@tecton/ComponentRedesign/utils';
import { useUserSettings } from '../../context/UserSettingsContext';

interface DatasetCardProps {
  fco: DatasetFCO;
  idToFcoMap: Record<string, AnyFCO>;
}

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.padding.m};
`;

const DatasetMetadataCard: FC<DatasetCardProps> = ({ fco, idToFcoMap }) => {
  const { workspace } = useUserSettings();
  let source = <></>;

  if (fco[DatasetFCOFields.FEATURE_SERVICE_ID] !== undefined) {
    if (idToFcoMap[fco[DatasetFCOFields.FEATURE_SERVICE_ID]]) {
      const featureService = idToFcoMap[fco[DatasetFCOFields.FEATURE_SERVICE_ID]];
      source = (
        <>
          <Link to={`/repo/${workspace}/feature-services/${featureService.name}/dataflow`}>
            <FCOIconAndName name={featureService.name ?? ''} type={FCOType.FEATURE_SERVICE} />
          </Link>
        </>
      );
    } else {
      source = (
        <FCOIconAndName
          name={fco[DatasetFCOFields.FEATURE_SERVICE_NAME] ?? ''}
          type={FCOType.FEATURE_SERVICE}
          isModified
        />
      );
    }
  }

  if (fco[DatasetFCOFields.FEATURE_VIEW_ID] !== undefined) {
    if (idToFcoMap[fco[DatasetFCOFields.FEATURE_VIEW_ID]]) {
      const featureView = idToFcoMap[fco[DatasetFCOFields.FEATURE_VIEW_ID]];
      source = (
        <>
          <Link to={`/repo/${workspace}/features/${featureView.name}/dataflow`}>
            <FCOIconAndName name={featureView.name ?? ''} type={FCOType.FEATURE_VIEW} />
          </Link>
        </>
      );
    } else {
      // TODO: NEED TO HANDLE AN EXAMPLE WITH MODIFIED
      source = <FCOIconAndName name={fco[DatasetFCOFields.FEATURE_VIEW_NAME] ?? ''} type={FCOType.FEATURE_VIEW} />;
    }
  }

  const items = [
    {
      title: <>Type</>,
      content: fco[DatasetFCOFields.TYPE],
    },
    {
      title: <>Source</>,
      content: source,
    },
    {
      title: <>Created</>,
      content: fco[FCOFields.CREATED_DATE] ? TectonDateTimeFormat(moment(fco[FCOFields.CREATED_DATE])) : <></>,
    },
    {
      title: <>Commit ID</>,
      content: <Monospace>{fco[DatasetFCOFields.STATE_UPDATE_ENTRY_COMMIT_ID]}</Monospace>,
    },
  ];

  return (
    <Card title="Metadata">
      <ListWrapper>
        {items.map((item) => (
          <TitledItem {...item} />
        ))}
      </ListWrapper>
    </Card>
  );
};

const DatasetSchemaCard: FC<DatasetCardProps> = ({ fco }) => {
  interface SchemaItem {
    title: React.ReactNode;
    content: React.ReactNode;
  }

  const columns = [
    {
      name: 'Name',
      render: (item: SchemaItem) => {
        return item.title;
      },
    },
    {
      name: 'Type',
      render: (item: SchemaItem) => {
        return (
          <Monospace>
            <>{item.content}</>
          </Monospace>
        );
      },
    },
  ];

  let items: SchemaItem[];

  if (fco[DatasetFCOFields.UNIFIED_SCHEMA]) {
    // New Schema, post Tecton 1.0
    items = fco[DatasetFCOFields.UNIFIED_SCHEMA].map((schemaItem) => {
      return {
        title: <>{schemaItem.name}</>,
        content: <Monospace>{schemaItem.type}</Monospace>,
      };
    });
  } else {
    // Legacy Schema
    items = fco[DatasetFCOFields.SCHEMA].map((schemaItem) => {
      return {
        title: <>{schemaItem.name}</>,
        content: <Monospace>{schemaItem.type}</Monospace>,
      };
    });
  }

  return (
    <Card title="Schema">
      <Table columns={columns} items={items} />
    </Card>
  );
};

interface DatasetOverviewProps {
  fco?: DatasetFCO;
  idToFcoMap: Record<string, AnyFCO>;
  isLoading?: boolean;
  isError?: boolean;
}

const DataSetOverview: FC<DatasetOverviewProps> = ({ fco, idToFcoMap, isError, isLoading }) => {
  if (isLoading) {
    return <EmptyPrompt variant="loading" title={'Dataset Loading'} body={<></>} />;
  }

  if (isError || !fco) {
    return (
      <EmptyPrompt
        variant="storm"
        title={'Error Loading Dataset'}
        body={<>We ran into an error loading this dataset.</>}
        actions={<ErrorPromptButtons />}
      />
    );
  }
  return (
    <CardGridLayout>
      <TwoCardRow>
        <DatasetMetadataCard fco={fco!} idToFcoMap={idToFcoMap} />
        <DatasetSchemaCard fco={fco!} idToFcoMap={idToFcoMap} />
      </TwoCardRow>
    </CardGridLayout>
  );
};

export default DataSetOverview;
