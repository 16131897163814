import React, { Component, ErrorInfo, ReactNode } from 'react';
import TectonEmptyPrompt from '@tecton/ComponentRedesign/EmptyPrompt';
import { Card } from '@tecton/ComponentRedesign';

// @tecton

type BoundarySize = 's' | 'm' | 'l';

interface ErrorBoundaryProps {
  children?: ReactNode;
  size?: BoundarySize;
}

interface State {
  hasError: boolean;
}

class ChartErrorBoundary extends Component<ErrorBoundaryProps, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return (
        <Card title="Error Loading Chart Data">
          <TectonEmptyPrompt variant="storm" orientation="vertical" title="" body="Error loading data" />
        </Card>
      );
    }

    return this.props.children;
  }
}

export default ChartErrorBoundary;
