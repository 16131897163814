import React from 'react';
import BoltIcon from '@mui/icons-material/Bolt';
import ConstructionIcon from '@mui/icons-material/Construction';

import style from './style.module.scss';
import { useUserSettings } from '../../../../components/context/UserSettingsContext';

const NoWorkspacesAvailableTable = () => {
  //const workspaceWithPermissions = useAllWorkspacesWithUserAccess();
  const { allWorkspaces } = useUserSettings();

  return (
    <div className={style.workspaceColumnWrapper}>
      <div className={style.workspaceColumns}>
        {allWorkspaces?.map((workspace, index) => {
          const isLiveFromWorkspace = workspace['capabilities'].materializable === true;
          return (
            <div key={index} className={style.ellipsisWorkspaceName} title={workspace.name}>
              {isLiveFromWorkspace ? <BoltIcon fontSize="inherit" /> : <ConstructionIcon fontSize="inherit" />}
              {workspace?.name}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default NoWorkspacesAvailableTable;
