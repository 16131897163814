import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Icon, IconType } from '.';
import { IconSize } from '../Icon';

type AvatarTypes = 'initials' | 'icon' | 'image';
type AvatarSizes = 's' | 'm' | 'l' | 'xl';

export interface AvatarProps {
  type?: AvatarTypes;
  name?: string;
  size?: AvatarSizes;
  square?: boolean;
  icon?: IconType;
  imageUrl?: string;
  backgroundColor?: string;
  color?: string;
}

const avatarDimensions: Record<AvatarSizes, number> = {
  s: 24,
  m: 32,
  l: 40,
  xl: 64,
};

const StyledAvatar = styled.div<{
  type: AvatarTypes;
  hashIndex: number;
  square?: boolean;
  size: AvatarSizes;
  imageUrl?: string;
  backgroundColor?: string;
  color?: string;
}>(({ theme, type, hashIndex, square, size, imageUrl, color, backgroundColor }) => {
  const borderRadius = square ? `${avatarDimensions[size] / 4}px` : `${avatarDimensions[size] / 2}px`;

  const avatarFontSizes: Record<AvatarSizes, string> = {
    s: theme.font.fontSizes.xxs,
    m: theme.font.fontSizes.xs,
    l: theme.font.fontSizes.s,
    xl: '20px', // This isn't aligned. Need to check with UX
  };

  const colorKeys = Object.keys(theme.v1.colors.viz) as Array<keyof typeof theme.v1.colors.viz>;
  const colorPair = theme.v1.colors.viz[colorKeys[hashIndex]];

  return {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: `${avatarDimensions[size]}px`,
    height: `${avatarDimensions[size]}px`,
    borderRadius: borderRadius,
    border: `2px solid ${theme.v1.colors.background.empty}`,
    color: color ? color : colorPair.text,
    fontSize: avatarFontSizes[size],
    fontWeight: theme.font.weight.medium,
    cursor: 'pointer',
    backgroundColor: backgroundColor ? backgroundColor : colorPair.behindText,
    backgroundImage: type === 'image' ? `url(${imageUrl})` : 'none',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    marginRight: '0.5em',

    path: {
      stroke: colorPair.text,
    },
  };
});

const hashForAvatars: (name: string) => number = (name: string) => {
  let hash = 0;
  for (let i = 0, len = name.length; i < len; i++) {
    const character = name.charCodeAt(i);
    hash = (hash << 5) - hash + character;
    hash |= 0;
  }

  const hashIndex = Math.abs(hash % 9);

  return hashIndex;
};

const getInitialsFromString = (name: string) => {
  const splitName = name
    .split(/[ _-]+/)
    .map((word) => word.charAt(0))
    .slice(0, 2)
    .join('')
    .toUpperCase();

  return splitName;
};

const Avatar: FC<AvatarProps> = ({
  type = 'initials',
  name = '',
  size = 's',
  square,
  icon,
  imageUrl,
  color,
  backgroundColor,
}) => {
  const hashIndex = hashForAvatars(name);
  const initials = getInitialsFromString(name);

  const iconSizeMap: Record<AvatarSizes, IconSize> = {
    s: 's',
    m: 's',
    l: 'm',
    xl: 'l',
  };

  return (
    <StyledAvatar
      data-testid="avatar"
      type={type}
      hashIndex={hashIndex}
      square={square}
      size={size}
      imageUrl={imageUrl}
      color={color}
      backgroundColor={backgroundColor}
    >
      {type === 'initials' && initials}
      {type === 'icon' && icon && <Icon icon={icon} size={iconSizeMap[size]} type="color" color={color} />}
    </StyledAvatar>
  );
};

export default Avatar;
