import React, { useRef } from 'react';
import { css } from '@emotion/css';
import { Selectable, Panel, Text, useTectonTheme, EuiSelectableOption, Badge } from '@tecton';
import { useWindowSize } from '../../feature/feature-views/feature-view/component/BatchMaterializationQualityMonitoringPanel/hooks';
import UrlUtils from '../../utils/url-utils';
import { Routes } from '../../core/routes';
import { useNavigate } from 'react-router';
import { useGetFcos } from '../../api/fcos';
import { FCO, FCOType, WorkspaceFCOContainer } from '../../core/types/fcoTypes';
import FCONameDisplayWithIconAndTooltip from '../@tecton/FCONameDisplayWithIconAndTooltip';
import WorkspaceUtils from '../../utils/workspace-utils';

const descriptionCss = css`
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const getPathForFCO = (fco: FCO) => {
  const routes: Record<FCOType, string> = {
    [FCOType.DATASET]: Routes.savedFeatureDataFrame,
    [FCOType.DATA_SOURCE]: Routes.dataSource,
    [FCOType.ENTITY]: Routes.entity,
    [FCOType.FEATURE_SERVICE]: Routes.featureService,
    [FCOType.FEATURE_VIEW]: Routes.featureView,
    [FCOType.TRANSFORMATION]: Routes.transformation,
    [FCOType.UNKNOWN]: '',
  };

  return routes[fco.fcoType];
};

type FCOOption = EuiSelectableOption<{
  data?: {
    label: React.ReactNode;
    searchableLabel: string;
    fco: FCO;
    append: React.ReactNode;
  };
}>;

const groupFcosByTypeForGlobalSearch = (fcos: FCO[], label: string) => {
  const restructuredFcos: FCOOption[] = fcos.map((fco) => {
    return {
      label: fco.name ?? '',
      append: (
        <Text size="xs" className={descriptionCss}>
          {fco.description}
        </Text>
      ),
      data: {
        label: (
          <div style={{ pointerEvents: 'none', backgroundColor: 'orange' }}>
            <FCONameDisplayWithIconAndTooltip fco={fco} />
          </div>
        ),
        append: (
          <Text size="xs" className={descriptionCss}>
            {fco.description}
          </Text>
        ),
        searchableLabel: fco.name ?? '',
        fco: fco,
      },
    };
  });

  return [
    {
      label: (
        <>
          {label} <Badge>{fcos.length}</Badge>
        </>
      ),
      searchableLabel: ' ',
      isGroupLabel: true,
    },
    ...restructuredFcos,
  ];
};

const GlobalObjectSearch = () => {
  const { theme } = useTectonTheme();
  const navigate = useNavigate();
  const workspace = WorkspaceUtils.getWorkspaceFromStore();

  const { data, isLoading: optionsAreLoading } = useGetFcos<WorkspaceFCOContainer>(workspace as string, {
    select: (data: WorkspaceFCOContainer) => {
      return [
        groupFcosByTypeForGlobalSearch(data.dataSources, 'Data Sources'),
        groupFcosByTypeForGlobalSearch(data.entities, 'Entities'),
        groupFcosByTypeForGlobalSearch(data.transformations, 'Transformations'),
        groupFcosByTypeForGlobalSearch(data.featureViews, 'Feature Views'),
        groupFcosByTypeForGlobalSearch(data.featureServices, 'Feature Services'),
      ].flat();
    },
  });

  const wrapperCss = css`
    position: relative;

    .euiFormControlLayout {
      height: 40px; // Hack for Fitting into old top bar
    }

    .tectonSearchOptionsPanel {
      display: none;
    }

    .euiFormControlLayoutIcons {
      color: ${theme.color.light.navigationText};
    }

    .euiSelectable:focus-within {
      .tectonSearchOptionsPanel {
        // Show options panel only
        // when the search wrapper is
        // has focus
        display: block;
      }
    }

    .euiSelectableListItem__content {
      display: grid;
      grid-template-columns: 25em 2fr 32px;
    }

    .euiSelectableList__list {
      // Hack to override <Selectable> height specification
      // Otherwise the dropdown will be a fixed size
      // even when there's only a few matching results
      height: inherit !important;
      max-height: 60vh;
    }

    .euiSelectableListItem__append {
      display: grid;
      grid-template-columns: 1fr min-content;
      flex-align: middle;
      align-items: center;

      .euiText {
        color: ${theme.colors.subduedText};
      }

      .euiSelectableListItem__onFocusBadge {
        margin-left: ${theme.size.s};
      }
    }

    .euiSelectableList__groupLabel {
      color: ${theme.colors.darkShade};
      justify-content: space-between;
    }

    .euiSelectableListItem {
      background: white;
    }

    .euiSelectableListItem-isFocused:not([aria-disabled='true']),
    .euiSelectableListItem:hover:not([aria-disabled='true']) {
      background-color: #fafbfd;
    }
  `;

  const optionsCss = css`
    position: absolute;
    width: 100%;
    top: 41px;
    left: 0;
    background: #f3f5f8;
  `;

  const searchBarOldTectonCustomizationCss = css`
    .euiFieldSearch {
      border-radius: 0;
      height: 40px; // Hack for Fitting into old top bar
    }

    .euiFieldSearch:not(:focus) {
      ::placeholder {
        color: ${theme.color.light.navigationText};
      }

      + .euiFormControlLayoutIcons {
        path {
          fill: ${theme.color.light.navigationText};
        }
      }

      background: ${theme.color.light.chromeBackground};
      border: ${theme.color.light.chromeBackground};
      width: calc(100%);
      text-overflow: ellipsis;
    }

    .euiFormControlLayout--group {
      background: transparent;
    }

    .euiFormControlLayout__childrenWrapper {
      overflow: hidden;

      &:hover:after {
        opacity: 1;
        transform: translate(0, 0);
      }

      &:focus-within:after {
        opacity: 0;
      }
    }
  `;

  const { height } = useWindowSize();

  const inputRef = useRef<HTMLInputElement | null>();
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const FALLBACK_HEIGHT = 32 * 7.5; // 7.5 rows of content

  const placeholder = `Search in ${workspace}`;

  return (
    <div className={wrapperCss} ref={wrapperRef}>
      <Selectable
        isLoading={optionsAreLoading}
        singleSelection
        className={searchBarOldTectonCustomizationCss}
        aria-label={placeholder}
        searchable
        options={data}
        onChange={(newOptions) => {
          const selectedOption = newOptions.find((o) => {
            return o.checked === 'on';
          });

          if (selectedOption as FCOOption) {
            const fco = (selectedOption as FCOOption).data!.fco;
            const path = getPathForFCO(fco);

            const navigateTo = UrlUtils.buildPath(path, {
              name: fco.name,
              workspace: workspace,
              tab: null,
            });

            navigate(navigateTo);

            // // HACK: Clearing the input field after an option is selected
            // // <Selectable> doesn't expose any methods of doing this
            // // programmatically, so *shrugface*
            const clearButton: HTMLButtonElement | null = document.querySelector(
              `.${wrapperCss} .euiFormControlLayoutClearButton`
            );
            if (clearButton) {
              clearButton.click();
            }
            // // END HACK

            // // Blur focus state when selecting an option
            // // using keyboard input
            if (inputRef.current) {
              inputRef.current.blur();
            }

            // // Blur focus state when selecting an option
            // // using mouse click
            const listElement: HTMLDivElement | null = document.querySelector('div.euiSelectableList__list');
            if (listElement) {
              listElement.blur();
            }
          }
        }}
        renderOption={(option: { fco: FCO }) => {
          return (
            <div style={{ pointerEvents: 'none' }}>
              <FCONameDisplayWithIconAndTooltip fco={option.fco} />
            </div>
          );
        }}
        listProps={{
          showIcons: false,
          bordered: true,
        }}
        height={height ? height * 0.6 : FALLBACK_HEIGHT}
        searchProps={{
          placeholder: placeholder,
          inputRef: (node) => {
            inputRef.current = node;
          },
          onKeyUp: (event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.code === 'Escape' && inputRef.current) {
              inputRef.current.blur();
            }
          },
        }}
      >
        {(list, search) => (
          <>
            {search}
            <Panel paddingSize="none" className={`tectonSearchOptionsPanel ${optionsCss}`}>
              {list}
            </Panel>
          </>
        )}
      </Selectable>
    </div>
  );
};

export default GlobalObjectSearch;
