import React from 'react';

function AmazonRedshiftIcon() {
  return (
    <g transform="translate(-12,-12) scale(0.05)">
      <path d="M60 71.5L26 88V391L60 408V71.5Z" fill="#205B98" />
      <path d="M125 441.5V38.5L193 4.5V475.5L125 441.5Z" fill="#205B98" />
      <path d="M125 87L60 71.5V408L125 392V87Z" fill="#5294CF" />
      <path d="M418 71.5L452 88V391L418 408V71.5Z" fill="#5294CF" />
      <path d="M353 441.5V38.5L285 4.5V475.5L353 441.5Z" fill="#5294CF" />
      <path d="M353 87L418 71.5V408L353 392V87Z" fill="#205B98" />
      <path d="M285 4.5H193V475.5H285V4.5Z" fill="#2D72B8" />
    </g>
  );
}

export default AmazonRedshiftIcon;
