import React from 'react';
import FeatureViewIcon from './FeatureViewIcon';
import FeatureServiceIcon from './FeatureServiceIcon';
import BatchSourceIcon from './BatchSourceIcon';
import StreamSourceIcon from './StreamSourceIcon';
import LettersIcon from './LettersIcon';
import DataSourceIcon from './DataSourceIcon';
import ExternalModelIcon from './ExternalModelIcon';
import ExternalTrainerIcon from './ExternalTrainerIcon';
import AmazonS3Icon from './AmazonS3Icon';
import AmazonKinesisIcon from './AmazonKinesisIcon';
import SnowflakeIcon from './SnowflakeIcon';
import { NODE_TYPES } from '../types/NodeDataType';
import KafkaIcon from './KafkaIcon';
import HiveIcon from './HiveIcon';
import AmazonRedshiftIcon from './AmazonRedshiftIcon';
import FeatureViewGroupIcon from './FeatureViewGroupIcon';
import RequestDataSourceIcon from './RequestDataSourceIcon';

interface IconProps {
  type: NODE_TYPES;
}

function Icon({ type }: IconProps) {
  switch (type) {
    case 'feature_view':
    case 'feature_view/batch_feature_view':
    case 'feature_view/on_demand_feature_view':
    case 'feature_view/stream_window_aggregate_feature_view':
    case 'feature_view/stream_feature_view':
    case 'feature_table':
      return <FeatureViewIcon />;

    case 'feature_view/feature_view_group':
      return <FeatureViewGroupIcon />;

    case 'feature_service':
      return <FeatureServiceIcon />;

    case 'data_source/batch':
    case 'data_source/stream':
      return <DataSourceIcon />;

    case 'request_data_source':
      return <RequestDataSourceIcon />;

    case 'tecton_store/offline_store':
      return <BatchSourceIcon />;

    case 'tecton_store/online_store':
      return <StreamSourceIcon />;

    case 'external_data_batch/snowflake':
      return <SnowflakeIcon />;

    case 'external_data_batch/s3':
      return <AmazonS3Icon />;

    case 'external_data_batch/hive':
      return <HiveIcon />;

    case 'external_data_batch/redshift':
      return <AmazonRedshiftIcon />;

    case 'external_data_stream/kinesis':
      return <AmazonKinesisIcon />;

    case 'external_data_stream/kafka':
      return <KafkaIcon />;

    case 'external_model':
      return <ExternalModelIcon />;

    case 'external_model_trainer':
      return <ExternalTrainerIcon />;

    default:
      return <LettersIcon />;
  }
}

export default Icon;
