import { useQuery } from '@tanstack/react-query';
import { fetchGetSparkConfig } from './fetchGetSparkConfig';

export const useGetSparkConfig = (workspace: string, feature_view_name: string) => {
  const payload = { workspace, feature_view_name };

  return useQuery(
    ['get-spark-config', workspace, feature_view_name],
    () => {
      return fetchGetSparkConfig(payload);
    },
    {
      retry: false,
    }
  );
};
