import styled from '@emotion/styled';
import { FC } from 'react';

// @tecton
import { FlexGrid, FlexGroup, FlexItem, Icon, Text } from '@tecton/ComponentRedesign';
import { useTectonTheme } from '@tecton/ComponentRedesign/Theme/ThemeProvider';

import { ReactComponent as Check } from '@svg/check.svg';
import { ReactComponent as Exit } from '@svg/exit.svg';
import { Permission } from '../../../api/gql/graphql';

interface CapabilityListProps {
  roleDescriptions: Permission[];
  maxHeight?: number;
}
const FlexGridStyled = styled(FlexGrid)<{ maxHeight?: number }>`
  overflow: auto;
  max-height: ${({ maxHeight }) => maxHeight}px;
`;
const FlexItemStyled = styled(FlexItem)`
  padding: ${({ theme }) => theme.padding.l};
  border-bottom: ${({ theme }) => theme.border.thin};
`;

const CapabilityList: FC<CapabilityListProps> = ({ roleDescriptions, maxHeight }) => {
  const { theme } = useTectonTheme();

  return (
    <FlexGridStyled direction="column" alignItems="center" gutterSize="none" maxHeight={maxHeight}>
      {roleDescriptions.map((role, index) => {
        return (
          <FlexItemStyled key={`role-option-${index}`}>
            <FlexGroup
              direction="row"
              gutterSize="s"
              justifyContent="spaceAround"
              alignItems="center"
              aria-label={role.description}
            >
              <FlexItem grow={false}>
                {role?.isAuthorized ? (
                  <Icon type={Check} stroke={theme.colors.success} />
                ) : (
                  <>
                    <Icon type={Exit} stroke={theme.colors.danger} />
                  </>
                )}
              </FlexItem>
              <FlexItem>
                <Text>{role.description}</Text>
              </FlexItem>
            </FlexGroup>
          </FlexItemStyled>
        );
      })}
    </FlexGridStyled>
  );
};

export default CapabilityList;
