import React from 'react';

import {
  CustomGraph,
  GraphPanel,
  GraphRenderType,
  GraphType,
  QueryParam,
} from '../../../feature/feature-views/graph-types';
import { MonitoringDateRange } from '../../monitoring/DateRange-Dropdown-util';
import ChartErrorBoundary from './ChartErrorBoundary';
import GraphContainer from './GraphContainer';
import GraphiteContainer from './GraphiteContainer';

// @tecton
import { FlexGroupWrapper, FlexItem } from '@tecton/ComponentRedesign';
import PrometheusContainer from '@tecton/ComponentRedesign/Charts/PrometheusContainer';
import { useUserSettings } from '../../../components/context/UserSettingsContext';
import { TECTON_FEATURE_SERVING_CACHE_TITLE } from '../../../feature/graph-configuration';

const renderGraphByType = (
  graph: GraphType,
  customGraphRecord: Record<string, React.ReactNode>,
  monitoringDateRange: MonitoringDateRange,
  workspace: string,
  serverGroupId?: string,
  serverGroupName?: string,
  fcoId?: string,
  height?: number
) => {
  switch (graph?.renderType) {
    case GraphRenderType.CUSTOM:
      // Since customGraph can potentially be not there, we have a fall back to Unknown
      return customGraphRecord[`${graph?.customGraph ?? CustomGraph.UNKNOWN}`] ?? <></>;
    case GraphRenderType.PROMETHEUS:
      return (
        <ChartErrorBoundary>
          <PrometheusContainer
            monitoringDateRange={monitoringDateRange}
            graphMetadata={graph}
            workspace={workspace ?? ''}
            featureViewId={fcoId ?? ''}
            parseValue={graph.parseValue}
            height={height}
            serverGroupId={serverGroupId}
            serverGroupName={serverGroupName}
          />
        </ChartErrorBoundary>
      );
    case GraphRenderType.GRAPHITE:
      return (
        <ChartErrorBoundary>
          <GraphiteContainer
            monitoringDateRange={monitoringDateRange}
            graphMetadata={graph}
            workspace={workspace ?? ''}
            featureViewId={fcoId ?? ''}
            height={height}
          />
        </ChartErrorBoundary>
      );
  }
};

interface MonitoringGraphParams extends QueryParam {
  monitoringDateRange: MonitoringDateRange;
  graphsData: GraphPanel[];
  customGraphRecord: Record<string, React.ReactNode>;
}

const MonitoringGraphs = ({
  monitoringDateRange,
  graphsData,
  customGraphRecord,
  workspace = 'prod',
  fcoId,
  serverGroupId,
  serverGroupName,
}: MonitoringGraphParams) => {
  const { isFeatureServerCachingEnabled } = useUserSettings();

  return (
    <FlexGroupWrapper gap="xxl">
      {graphsData.map((graphPanel) => {
        if (graphPanel.panelLabel === TECTON_FEATURE_SERVING_CACHE_TITLE && !isFeatureServerCachingEnabled)
          return false;

        const label = graphPanel.panelLabel.replaceAll('::workspace::', workspace ?? '');
        const content = graphPanel.graphs?.map((graph) => {
          return (
            <>
              {renderGraphByType(
                graph,
                customGraphRecord,
                monitoringDateRange,
                workspace,
                serverGroupId,
                serverGroupName,
                fcoId,
                160
              )}
            </>
          );
        });

        return (
          <FlexItem>
            <GraphContainer headerLabel={label}>{content}</GraphContainer>
          </FlexItem>
        );
      })}
    </FlexGroupWrapper>
  );
};

export default MonitoringGraphs;
