import { FC, useState } from 'react';

import { CreateServiceAccountResponse } from '../../../types/tecton_proto/metadataservice/metadata_service';

// @tecton
import { Callout, Checkbox, FlexGroupWrapper, FlexItem, Text } from '@tecton/ComponentRedesign';
import { KeyInput } from './KeyInput';

interface CreatedServiceAccountInformationProps {
  createServiceAccountResponse?: CreateServiceAccountResponse;
  onChange: (hasCopiedAPIKey: boolean) => void;
}

/* This is just a place holder. UX still needs to give me a design */

const CreatedServiceAccountInformation: FC<CreatedServiceAccountInformationProps> = ({
  createServiceAccountResponse,
  onChange,
}) => {
  const [hasCopiedAPIKeyConfirmed, setHasCopiedAPIKeyConfirmed] = useState<boolean>(false);

  return (
    <>
      <Callout mode="neutral" title={'Note'}>
        <Text>
          You will only be able to access this key once, so make sure that you are prepared to securely store the key
          before proceeding.
        </Text>
      </Callout>
      <FlexGroupWrapper gap="xl" direction="column">
        <FlexItem>
          <KeyInput
            label="Account ID"
            value={createServiceAccountResponse?.id}
            tooltipMessage="Click to copy Account ID."
          />
        </FlexItem>
        <FlexItem grow={true}>
          <FlexGroupWrapper gap="xs">
            <FlexItem>
              <KeyInput
                label="API Key"
                value={createServiceAccountResponse?.api_key}
                tooltipMessage="Click to copy API Key."
              />
            </FlexItem>
            <FlexItem>
              <Checkbox
                id="created-service-account-input"
                label={`I can confirm I have saved the key`}
                checked={hasCopiedAPIKeyConfirmed}
                onClick={() => {
                  setHasCopiedAPIKeyConfirmed(!hasCopiedAPIKeyConfirmed);
                  onChange(!hasCopiedAPIKeyConfirmed);
                }}
              />
            </FlexItem>
          </FlexGroupWrapper>
        </FlexItem>
      </FlexGroupWrapper>
    </>
  );
};

export default CreatedServiceAccountInformation;
