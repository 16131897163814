import TimeAgo from 'javascript-time-ago';
import React from 'react';
import en from 'javascript-time-ago/locale/en';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment-timezone';
import { Tooltip } from '@mui/material';

export default function CreatedAt({ date }) {
  if (date) {
    TimeAgo.addLocale(en);
    const timeAgo = new TimeAgo('en-US');

    let display = timeAgo.format(date);
    const id = uuidv4();

    return (
      <>
        <Tooltip
          arrow
          title={
            <>
              <div className="fw6">{moment(date).format('MMMM Do, YYYY')}</div>
              <div>{moment(date).format('h:mma')}</div>
            </>
          }
        >
          <span data-tip data-for={id} className="cursor-default">
            {display}
          </span>
        </Tooltip>
      </>
    );
  } else {
    return <span>-</span>;
  }
}
