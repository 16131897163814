import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import FilteredWorkspacesToSelect from './FilteredWorkspacesToSelect';

interface SelectWorkspaceMessageProperties {
  subHead?: React.ReactNode;
  headline: string;
  content: React.ReactNode;
}

const SelectWorkspaceMessage: FC<SelectWorkspaceMessageProperties> = ({ subHead, headline, content }) => {
  return (
    <div id="content" className="overflow-y-hidden h-100 content-shadow w-100 ">
      <Box sx={{ width: '100%', backgroundColor: 'white', px: 8, py: 6, minHeight: '100vh' }}>
        <Box sx={{ margin: '0 auto', width: '50em' }}>
          <Box className="pb4">
            {subHead}
            <Typography variant="h2">{headline}</Typography>
            <Box sx={{ fontSize: '14px' }}>{content}</Box>
          </Box>
        </Box>
        <FilteredWorkspacesToSelect />
      </Box>
    </div>
  );
};

export default SelectWorkspaceMessage;
