import { useState } from 'react';
import { AxiosError } from 'axios';
import { Metadata_Service, SimpleError } from './';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import {
  GetUserDeploymentSettingsResponse,
  UpdateUserDeploymentSettingsRequest,
  UpdateUserDeploymentSettingsResponse,
  GetDataPlatformSetupStatusResponse,
} from '../types/tecton_proto/metadataservice/metadata_service';
import { Secret } from '../types/tecton_proto/common/secret';

export interface USER_SETTINGS_RESPONSE {
  workspace_url?: string;
  api_token?: Secret;
  instance_profile_arn?: string;
  glueCatalogEnabled?: boolean;
  glueCatalogId?: string;
}

export type DPOConfigRequestType = Omit<UpdateUserDeploymentSettingsRequest, 'field_mask'> & {
  field_mask: string;
};

const fetchDeploymentSettings = async (): Promise<GetUserDeploymentSettingsResponse> => {
  const { data } = await Metadata_Service('get-user-deployment-settings', { method: 'POST' });
  return data;
};

const fetchDataPlatformSetupStatus = async (): Promise<GetDataPlatformSetupStatusResponse> => {
  const { data } = await Metadata_Service('get-data-platform-setup-status', { method: 'POST' });
  return data;
};

const updateDeploymentSettings = async (
  payload: DPOConfigRequestType
): Promise<UpdateUserDeploymentSettingsResponse> => {
  const response = await Metadata_Service.post('update-user-deployment-settings', payload);
  return response.data;
};

export const useGetUserDeploymentSettingsQuery = () => {
  return useQuery(['getUserDeploymentSettings', {}], () => fetchDeploymentSettings(), {
    select: (data: GetUserDeploymentSettingsResponse): USER_SETTINGS_RESPONSE => {
      const { user_deployment_settings } = data;
      const { databricks_config, user_spark_settings } = user_deployment_settings || {};
      const { spark_conf } = user_spark_settings || {};

      const settings = {
        workspace_url: databricks_config?.workspace_url,
        api_token: databricks_config?.api_token || {},
        instance_profile_arn: user_spark_settings?.instance_profile_arn,
        glueCatalogEnabled: spark_conf ? !!spark_conf['spark.databricks.hive.metastore.glueCatalog.enabled'] : false,
        glueCatalogId: spark_conf ? spark_conf['spark.hadoop.hive.metastore.glue.catalogid'] : '',
      };

      return settings;
    },
  });
};

export const useGetDataPlatformSetupStatusQuery = (enable = false, interval: number | false = false) => {
  const [isComplete, setIsComplete] = useState(false);
  const [isError, setIsError] = useState(false);
  const enabled = enable && !isComplete && !isError;

  return useQuery(['getDataPlatformSetupStatus', {}], () => fetchDataPlatformSetupStatus(), {
    enabled,
    onSuccess(data) {
      setIsComplete(!!data?.setupCompleted);
      return data;
    },
    onError() {
      setIsError(true);
    },
    refetchInterval: interval,
  });
};

export const useUpdateUserDeploymentSettingsMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (payload: DPOConfigRequestType) => {
      return await updateDeploymentSettings(payload);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['getUserDeploymentSettings']);
        queryClient.invalidateQueries(['getSparkClusterStatus']);
      },
      onError: (error: AxiosError) => {
        // transform error to custom type
        const customError: SimpleError = {
          message: 'An error occurred',
          code: 'UNKNOWN',
        };

        if (error.response) {
          customError.message = error?.message || customError.message;
          customError.code = error?.code as string;
        }

        throw customError;
      },
    }
  );
};
