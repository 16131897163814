import React, { useEffect, useMemo, useState, useContext, useLayoutEffect } from 'react';
import { ReactFlow, useNodesInitialized, useReactFlow, MiniMap, Node, Viewport } from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import DetailsPanelFocusProvider, { DetailsPanelContext, useReactFlowInstanceRef } from './DetailsPanelFocusProvider';
import { useUserSettings } from '../../../context/UserSettingsContext';
import DetailsPanel from './DetailsPanelContainer';
import {
  BATCH_MATERIALIZATION_PATH_CLASSNAME,
  DataFlowWrapper,
  OFFLINE_READ_PATH_CLASSNAME,
  ONLINE_REQUEST_PATH_CLASSNAME,
  ONLINE_SERVING_PATH_CLASSNAME,
  STREAM_MATERIALIZATION_PATH_CLASSNAME,
} from './VisualPrimitives/CardTypography';

import { HotKeys } from 'react-hotkeys';
import { nodeTypeMap } from './nodeToRendererMap';
import DFEdgeWithContext from './DFEdgeWithContext';
import LegendContainer from './LegendContainer';
import { DiagramConfigsPopoverHandle } from './DiagramConfigsPopover';
import styled from '@emotion/styled';
import { SkeletonRectangle, Spacer, Text } from '@tecton';
import { Loading } from '@tecton/ComponentRedesign';
import DataFlowControls from './DataFlowControls';
import DataFlowBackground from './DataFlowBackground';
import { NodeSizeLookupRecord, useProcessDataForReactFlowAsQuery } from './processDataForReactFlow';
import { DataFlowNode, DataFlowSpec } from './dataFlowTypes';
import DataFlowFocusProvider from './DataFlowFocusProvider';
// import { max, min } from 'd3-array';

const ReactFlowStyled = styled(ReactFlow)``;

const MiniMapStyled = styled(MiniMap)`
  // background-color: ${({ theme }) => theme.colors.lightShade};

  // .react-flow__minimap-mask {
  // }

  // .react-flow__minimap-node {
  //   stroke: none;
  // }
`;

export const MAX_NODES_SUMMARY_THRESHOLD = 140;

const synchronizeCssAnimation = (className: string) => {
  // https://stackoverflow.com/questions/4838972/how-to-sync-css-animations-across-multiple-elements
  document.querySelectorAll(`.${className}`).forEach((element) => {
    element.addEventListener('animationstart', (e: Event) => {
      if (e.target instanceof Element) {
        const animations = e.target.getAnimations();

        animations.map((a) => {
          a.startTime = 0;
        });
      }
    });
  });
};

// Get Coords based on top left node
export const getTopLeftNodeRelativeToViewport = ({
  nodes,
  viewType = 'default',
  zoomLevel = 1,
}: {
  nodes: Node[];
  viewType?: 'default' | 'summary' | 'fco';
  zoomLevel?: number;
}): Viewport => {
  if (!nodes || nodes.length === 0) return { x: 0, y: 0, zoom: 1 };

  const TopPadding = viewType === 'fco' ? 140 : 170;
  const LeftPadding = viewType === 'summary' ? 0 : viewType === 'fco' ? 75 : 50;
  const excludeTypes = ['StoreInput', 'StoreOutput'];

  // Find the leftmost node, excluding specified types
  const LeftNode = nodes.reduce((leftNode, node) => {
    // Skip nodes with types in the exclude list
    if (excludeTypes.includes(node.type as string)) {
      return leftNode;
    }

    // If the current node is farther left than the current leftNode
    if (node.position.x < leftNode.position.x) {
      return node;
    }

    // If the current node shares the same x position but is higher up (smaller y value)
    if (node.position.x === leftNode.position.x && node.position.y < leftNode.position.y) {
      return node;
    }

    // Otherwise, keep the current leftNode
    return leftNode;
  }, nodes[0]);

  const x = LeftNode.position.x > LeftPadding ? -(LeftNode.position.x - LeftPadding) : LeftPadding;
  const y = LeftNode.position.y > TopPadding ? -(LeftNode.position.y - TopPadding) : TopPadding;

  return { x, y, zoom: zoomLevel };
};

const Initialize = ({
  name,
  nodeSizes,
  setNodeSizes,
  setInteractionReady,
  isFcoLevelDiagram,
}: {
  name: string;
  nodeSizes: NodeSizeLookupRecord | undefined;
  setNodeSizes: (nodeSizes: NodeSizeLookupRecord | undefined) => void;
  setInteractionReady: (interactionReady: boolean) => void;
  isFcoLevelDiagram?: boolean;
}) => {
  const nodesInitialized = useNodesInitialized();
  const { setViewport, getNodes } = useReactFlow();
  const nodes = getNodes();

  useLayoutEffect(() => {
    const viewType = isFcoLevelDiagram ? 'fco' : 'default';
    const zoomLevel = isFcoLevelDiagram ? 0.75 : 1;
    const vp = getTopLeftNodeRelativeToViewport({ nodes, viewType, zoomLevel });
    setViewport(vp, { duration: 500 });

    synchronizeCssAnimation(BATCH_MATERIALIZATION_PATH_CLASSNAME);
    synchronizeCssAnimation(STREAM_MATERIALIZATION_PATH_CLASSNAME);
    synchronizeCssAnimation(OFFLINE_READ_PATH_CLASSNAME);
    synchronizeCssAnimation(ONLINE_REQUEST_PATH_CLASSNAME);
    synchronizeCssAnimation(ONLINE_SERVING_PATH_CLASSNAME);

    // NOT CURRENTLY BE INITIALIZED BECAUSE OF OR
    // RELATED TO THE ABSOLUTE POSITIONIN OF THE CUSTOM HANDLES
    if (nodesInitialized && !nodeSizes) {
      const record: NodeSizeLookupRecord = {};

      nodes.forEach((n) => {
        const data = n.data as DataFlowNode;

        if (data && data.id && n.width && n.height) {
          record[data.id] = { width: n.width, height: n.height };
        }
      });

      setNodeSizes(record);

      /**
       * This timeout is a hack to ensure that
       * any logging that happens comes from
       * user interactions, not from the
       * initial render.
       */
      setTimeout(() => {
        setInteractionReady(true);
      }, 1000);
    }
  }, [nodeSizes, nodes, nodesInitialized, getNodes, setNodeSizes, setInteractionReady, setViewport]);

  useEffect(() => {
    /**
     * This effect clears the nodeSizes record
     * if the input to the diagram has changed,
     * (as determined by the name).
     *
     * This was only ever a problem when we
     * were prototyping. Not sure if we still
     * need this.
     */

    if (name) {
      setNodeSizes(undefined);
    }
  }, [name, setNodeSizes]);

  return null;
};

const DetailsPanelHotkey = ({ children }: { children: React.ReactNode }) => {
  const { detailsPanelClearAll } = useContext(DetailsPanelContext);

  return (
    <HotKeys
      keyMap={{
        closePanel: 'esc',
      }}
      handlers={{
        closePanel: detailsPanelClearAll,
      }}
    >
      {children}
    </HotKeys>
  );
};

const denseModeCriteria = (nodesList: DataFlowNode[]) => {
  return (
    nodesList.filter((node) => {
      return node.type === 'feature_view';
    }).length > 16
  );
};

const LoadingWrapper = styled.div<{ width?: string; height?: string }>`
  position: relative;
`;

// TODO: Make this more general and reusable
const Positioner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoadingMessage = styled.div`
  text-align: center;
`;

const LoadingPanel = ({ nodesList }: { nodesList: DataFlowNode[] }) => {
  const dataSourceCount = nodesList.filter((node) => {
    return node.type === 'data_source';
  }).length;

  const featureViewCount = nodesList.filter((node) => {
    return node.type === 'feature_view' || node.type === 'odfv';
  }).length;

  const featureServiceCount = nodesList.filter((node) => {
    return node.type === 'feature_service';
  }).length;

  const sum = dataSourceCount + featureViewCount + featureServiceCount;
  const WARNING_THRESHOLD = 80;

  return (
    <LoadingWrapper>
      <SkeletonRectangle width={'100%'} height={'80vh'} borderRadius="m" />
      <Positioner>
        <LoadingMessage>
          <Loading />
          <Spacer size="m" />
          <Text>
            Rendering {dataSourceCount} data sources, {featureViewCount} feature views, and {featureServiceCount}{' '}
            feature services.
          </Text>
          {sum > WARNING_THRESHOLD && (
            <>
              <Spacer size="m" />
              <Text>That is a lot of objects. This might take some time.</Text>
            </>
          )}
        </LoadingMessage>
      </Positioner>
    </LoadingWrapper>
  );
};

const DataFlowDiagram = ({
  name,
  nodesList,
  edgesList,
  templateType,
  height,
  setShowSummarizedView,
  isFcoLevelDiagram,
}: DataFlowSpec & {
  height: string;
  setShowSummarizedView: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  isFcoLevelDiagram?: boolean;
}) => {
  const { reactFlowInstanceRef, setReactFlowInstance } = useReactFlowInstanceRef();
  const { isPublicFreeTrial } = useUserSettings();
  // Configurations
  const denseMode = denseModeCriteria(nodesList);

  const [reduceAnimation, setReduceAnimation] = useState<boolean>(edgesList.length > 100);

  // Rendering related States
  const [nodeSizes, setNodeSizes] = useState<NodeSizeLookupRecord | undefined>();
  const [interactionReady, setInteractionReady] = useState<boolean>(false); // This is used only for making sure we log properly

  // Data State
  const { data, isLoading } = useProcessDataForReactFlowAsQuery(name, nodesList, edgesList, templateType, nodeSizes, {
    denseMode,
  });

  const configPopoverRef = React.useRef<DiagramConfigsPopoverHandle>(null);

  // React Flow Set up
  const nodeTypes = useMemo(() => nodeTypeMap, []);

  const edgeTypes = useMemo(
    () => ({
      default: DFEdgeWithContext,
    }),
    []
  );

  if (isLoading || !data) {
    return <LoadingPanel nodesList={nodesList} />;
  }

  return (
    <>
      <DetailsPanelFocusProvider
        positionMap={data.positionMap}
        nodesMap={data.nodesMap}
        edgesList={edgesList}
        reactFlowInstanceRef={reactFlowInstanceRef}
        denseMode={denseMode}
        reduceAnimation={reduceAnimation}
      >
        <DetailsPanelHotkey>
          <DataFlowFocusProvider templateType={templateType}>
            <DataFlowWrapper height={height}>
              <ReactFlowStyled
                nodes={data.nodes}
                edges={data.edges}
                nodeTypes={nodeTypes}
                edgeTypes={edgeTypes}
                nodesDraggable={false}
                defaultNodes={data.nodes}
                defaultEdges={data.edges}
                minZoom={0.05}
                fitView={!!isPublicFreeTrial}
                onInit={setReactFlowInstance}
                panOnScroll
                onPaneClick={() => {
                  if (configPopoverRef.current) {
                    configPopoverRef.current.closePopover();
                  }
                }}
                onMove={() => {}}
              >
                <MiniMapStyled zoomable pannable />
                <DataFlowControls
                  setShowSummarizedView={setShowSummarizedView}
                  showSummarizedView={false}
                  configPopoverRef={configPopoverRef}
                  reduceAnimation={reduceAnimation}
                  setReduceAnimation={setReduceAnimation}
                  isFcoLevelDiagram={isFcoLevelDiagram}
                />
                <DataFlowBackground />
                <Initialize
                  name={name}
                  nodeSizes={nodeSizes}
                  setNodeSizes={setNodeSizes}
                  setInteractionReady={setInteractionReady}
                  isFcoLevelDiagram={isFcoLevelDiagram}
                />
              </ReactFlowStyled>

              <DetailsPanel nodesMap={data.nodesMap} />

              <LegendContainer
                edgesList={edgesList}
                isFcoLevelDiagram={isFcoLevelDiagram}
                templateType={templateType}
              />
            </DataFlowWrapper>
          </DataFlowFocusProvider>
        </DetailsPanelHotkey>
      </DetailsPanelFocusProvider>
    </>
  );
};

export default DataFlowDiagram;
