import { FC } from 'react';

// @tecton
import { FlexItem, Text } from '@tecton/ComponentRedesign';
import { FlexGroupWrapper } from '../../@tecton/ComponentRedesign/StyledComponents';

interface UnlockUserProps {
  message?: string;
}

const UnlockUser: FC<UnlockUserProps> = ({ message }) => {
  return (
    <>
      <FlexGroupWrapper gap="l" direction="column">
        <FlexItem grow={false}>
          <Text>{message}</Text>
        </FlexItem>
        <FlexItem grow={false}></FlexItem>
      </FlexGroupWrapper>
    </>
  );
};

export default UnlockUser;
