import _ from 'lodash';
import { FC } from 'react';
import { Line } from 'react-chartjs-2';

import { useGraphite } from '../../../feature/query';
import { MonitoringDateRange } from '../../monitoring/DateRange-Dropdown-util';
import useChartJsRegister from '../../monitoring/useChartJsRegister';

// @tecton
import { EmptyPrompt, Loading, useTectonTheme } from '@tecton/ComponentRedesign';
import { getChartOptions, transformGraphiteResponse } from '@tecton/ComponentRedesign/Charts/graph-util';

interface GraphiteGraphProps {
  chartTitle: string;
  monitoringDateRange: MonitoringDateRange;
  parameters: string[][];
  xAxisLabel: string;
  yAxisLabel: string;
  yTicksCallback: any;
  yGrace: string | number;
  yOverride?: any;
  pluginLegendOverride?: any;
  toolTipLabelCallback: any;
  height?: number;
}

const GraphiteGraph: FC<GraphiteGraphProps> = ({
  chartTitle,
  monitoringDateRange,
  parameters,
  xAxisLabel,
  yAxisLabel,
  yGrace,
  yTicksCallback,
  yOverride,
  pluginLegendOverride,
  toolTipLabelCallback,
  height,
}) => {
  const state = useGraphite(parameters, monitoringDateRange);
  const { theme } = useTectonTheme();
  useChartJsRegister();

  if (state.isLoading) {
    return <Loading size={'xxl'} />;
  }

  const dataset = transformGraphiteResponse(_.clone(state.data));

  if (dataset.length === 0) {
    return (
      <>
        <EmptyPrompt title={<>No Data Available</>} body={<></>} orientation="vertical" variant="search" />
      </>
    );
  }

  const data = {
    datasets: dataset,
  };

  const chartYOverride = yOverride
    ? yOverride
    : {
        title: {
          display: true,
          text: yAxisLabel,
          font: {
            size: 12,
          },
        },
        min: 0,
        ticks: {
          callback: yTicksCallback,
        },
      };

  const chartOption: any = getChartOptions({
    title: chartTitle,
    xTitle: xAxisLabel,
    yTitle: yAxisLabel,
    yGrace,
    yTicksCallback,
    toolTipLabelCallback,
    unit: monitoringDateRange.unit,
    yOverride: chartYOverride,
    pluginLegendOverride,
    fontFamily: theme.font.family,
  });

  return (
    <>
      <Line options={chartOption} data={data} height={height} />
    </>
  );
};

export default GraphiteGraph;
