import styled from '@emotion/styled';

// We will need to review this.
export const ACLModalFormWrapper = styled.div`
  max-height: 60vh;
  overflow: auto;
`;

export const TableFieldWrapper = styled.div`
  max-width: 60vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
