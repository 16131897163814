import { TectonBasicTable } from '@shared';
import { Title } from '@tecton';
import React from 'react';
import { WorkspaceStoreDetailsPanelData } from './detailsPanelQueryTypes';
import { DataFlowSidePanel, DescriptionSection } from './StyledPanelComponents';
import DetailsPanelAccordionSection from './DetailsPanelAccordionSection';

const OfflineStoreContextPanel = ({
  id,
  data,
  detailsPanelClearAll,
  zoomToNode,
}: {
  id: string;
  data?: WorkspaceStoreDetailsPanelData;
  detailsPanelClearAll: () => void;
  zoomToNode?: () => void;
}) => {
  return (
    <DataFlowSidePanel
      name={'Online Store'}
      prehead={`Store`}
      nodeId={id}
      detailsPanelClearAll={detailsPanelClearAll}
      zoomToNode={zoomToNode}
    >
      <DescriptionSection
        description={
          'An offline store in Tecton holds all historical values of features to be used for training or batch inference.'
        }
      />

      {data && (
        <>
          <DetailsPanelAccordionSection
            id="materializing-to-offline-store"
            title={
              <Title size="xxs">
                <h4>Feature Views Materializing to the Offline Store</h4>
              </Title>
            }
            initialIsOpen
          >
            {data.featureViews.length > 0 ? (
              <TectonBasicTable
                items={data.featureViews.filter((fv) => {
                  return fv.isOfflineMaterializationEnabled === true;
                })}
                columns={[
                  {
                    field: 'name',
                    name: 'Name',
                  },
                ]}
                pageIndex={0}
                pageSize={0}
                totalItemCount={0}
              />
            ) : (
              <p>No Feature Views materializing data to the offline store.</p>
            )}
          </DetailsPanelAccordionSection>
        </>
      )}
    </DataFlowSidePanel>
  );
};

export default OfflineStoreContextPanel;
