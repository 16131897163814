import React, { FC } from 'react';
import { MonitoringDateRange } from '../../monitoring/DateRange-Dropdown-util';
import PrometheusCount from '../monitoring/PrometheusCount';

import { Card } from '@tecton/ComponentRedesign';

interface ReliabilitySLO30DaysProps {
  monitoringDateRange: MonitoringDateRange;
  featureServiceId: string;
}

const ReliabilitySLO30Days: FC<ReliabilitySLO30DaysProps> = ({ monitoringDateRange, featureServiceId }) => {
  const chartTitle = 'Reliability SLO (30 days)';

  const queries = [
    {
      label: 'Total',
      query: `1 - (sum(rate(feature_service_query_count{feature_service_id="${featureServiceId}", status=~"Unknown|Unimplemented|Internal|Unavailable|DataLoss", tecton_cluster=~'.*',  aws_region=~'.*'}[30d])) or on() vector(0)) / sum(rate(feature_service_query_count{feature_service_id="${featureServiceId}", tecton_cluster=~'.*',  aws_region=~'.*'}[30d]))`,
    },
  ];

  return (
    <Card title={chartTitle} centerBody>
      <PrometheusCount
        chartTitle={chartTitle}
        monitoringDateRange={monitoringDateRange}
        queries={queries}
        parseCallback={(arg: string) => {
          return parseFloat(arg) * 1000;
        }}
        calculateAverage={(average: number) => {
          return average / 1000;
        }}
        unit="%"
      />
    </Card>
  );
};

export default ReliabilitySLO30Days;
