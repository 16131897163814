import { useQuery } from '@tanstack/react-query';

const WHATS_NEW_FEED_URL = 'https://www.tecton.ai/whats-new/feed';

interface announcementEntry {
  title: string;
  link: string;
  date: Date;
}

const useWhatsNewQuery = () => {
  const state = useQuery(['whats-new'], () =>
    fetch(WHATS_NEW_FEED_URL)
      .then((response) => {
        return response.text();
      })
      .then((str) => new window.DOMParser().parseFromString(str, 'text/xml'))
      .then((xml) => {
        const items = xml.querySelectorAll('item');

        const feedData = Array.from(items).reduce<announcementEntry[]>((memo, item) => {
          const title = item.querySelector('title');
          const titleString = title && typeof title.textContent === 'string' ? title.textContent : false;

          const pubDate = item.querySelector('pubDate');
          const dateString = pubDate ? pubDate.textContent : false;

          const linkNode = item.querySelector('link');
          const link = linkNode && typeof linkNode.textContent === 'string' ? linkNode.textContent : false;

          if (titleString && dateString && link) {
            memo.push({
              title: titleString,
              date: new Date(dateString),
              link: link,
            });
          }

          return memo;
        }, []);

        return feedData;
      })
  );

  return state;
};

export { useWhatsNewQuery };
