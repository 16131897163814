import { AnyFCO, FCOFields } from '../../../core/types/fcoTypes';
import { uniqBy } from 'lodash';

export type FeatureRoutes =
  | 'Dataflow'
  | 'Jobs'
  | 'Metadata'
  | 'Materialization'
  | 'Feature Services'
  | 'Monitoring'
  | 'Data Quality Metrics';

export const getFeatureViewRoutes = (): Record<FeatureRoutes, string> => {
  return {
    Dataflow: 'dataflow',
    Metadata: 'overview',
    Jobs: 'jobs',
    Materialization: 'materialization',
    'Feature Services': 'feature-services',
    Monitoring: 'monitoring',
    'Data Quality Metrics': 'data-quality-metrics',
  };
};

export const getFeatureViewRouteName = (pathname: string): FeatureRoutes => {
  const pathSegments = pathname.split('/');
  const lastPathSegment = pathSegments[pathSegments.length - 1];
  const routes = getFeatureViewRoutes();
  const record = Object?.entries(routes)?.find((record) => {
    return record[1] === lastPathSegment;
  });

  return (record?.[0] ?? 'Dataflow') as FeatureRoutes;
};

export const getRelatedFCOsComboFilterOptions = <T>(
  fcos: T[],
  getIdCallBack: (fco: T) => string[],
  idToFcoMap: Record<string, AnyFCO>
) => {
  return (
    uniqBy(
      fcos
        ?.map((item) => {
          //const fcoIds = uniq(item[FeatureViewFCOFields.ALL_TRANSFORMATIONS]) ?? [];
          const fcoIds = getIdCallBack(item);
          return (
            fcoIds?.map((id) => {
              const anyFCO: AnyFCO = idToFcoMap[id];
              return {
                label: anyFCO[FCOFields.NAME] ?? '',
                value: anyFCO[FCOFields.ID] ?? '',
              };
            }) ?? []
          );
        })
        ?.flat(),
      'value'
    ) ?? []
  );
};
