/* eslint-disable */
import { Duration } from "../../google/protobuf/duration";
import { AwsAvailability } from "../spark_common/clusters";

export const protobufPackage = "tecton_proto.materialization";

export enum OnlineStoreType {
  ONLINE_STORE_TYPE_UNSPECIFIED = "ONLINE_STORE_TYPE_UNSPECIFIED",
  ONLINE_STORE_TYPE_DYNAMO = "ONLINE_STORE_TYPE_DYNAMO",
  ONLINE_STORE_TYPE_REDIS = "ONLINE_STORE_TYPE_REDIS",
  ONLINE_STORE_TYPE_BIGTABLE = "ONLINE_STORE_TYPE_BIGTABLE",
}

export enum OfflineStoreType {
  OFFLINE_STORE_TYPE_UNSPECIFIED = "OFFLINE_STORE_TYPE_UNSPECIFIED",
  OFFLINE_STORE_TYPE_S3 = "OFFLINE_STORE_TYPE_S3",
  OFFLINE_STORE_TYPE_DBFS = "OFFLINE_STORE_TYPE_DBFS",
  OFFLINE_STORE_TYPE_GCS = "OFFLINE_STORE_TYPE_GCS",
}

export enum JobMetadataTableType {
  JOB_METADATA_TABLE_TYPE_UNSPECIFIED = "JOB_METADATA_TABLE_TYPE_UNSPECIFIED",
  JOB_METADATA_TABLE_TYPE_DYNAMO = "JOB_METADATA_TABLE_TYPE_DYNAMO",
  JOB_METADATA_TABLE_TYPE_GCS = "JOB_METADATA_TABLE_TYPE_GCS",
}

export interface JobMetadata {
  online_store_copier_execution_info?: OnlineStoreCopierExecutionInfo | undefined;
  spark_execution_info?: SparkJobExecutionInfo | undefined;
  tecton_managed_info?: TectonManagedInfo | undefined;
  materialization_consumption_info?: MaterializationConsumptionInfo | undefined;
}

export interface OnlineStoreCopierExecutionInfo {
  is_revoked?: boolean | undefined;
}

export interface SparkJobExecutionInfo {
  run_id?: string | undefined;
  is_revoked?: boolean | undefined;
  stream_handoff_synchronization_info?: StreamHandoffSynchronizationInfo | undefined;
}

export interface StreamHandoffSynchronizationInfo {
  /**
   * this is default false, and can be set to true by the Spark cluster,
   * but never unset. This indicates the Spark cluster is started and ready
   * to run a Spark query. Setting this will trigger the workflow to terminate
   * the previous query.
   */
  new_cluster_started?:
    | boolean
    | undefined;
  /**
   * This is default false if a previous stream query is running,
   * and can be set to true by the orchestrator only,
   * and never unset. This indicates this job is allowed to start the streaming
   * query which means that this is the only invoker of the query
   */
  stream_query_start_allowed?: boolean | undefined;
  query_cancellation_requested?: boolean | undefined;
  query_cancellation_complete?: boolean | undefined;
}

export interface MaterializationConsumptionInfo {
  offline_store_consumption?: OfflineStoreWriteConsumptionInfo | undefined;
  online_store_consumption?: OnlineStoreWriteConsumptionInfo | undefined;
  compute_consumption?: ComputeConsumptionInfo | undefined;
}

export interface OfflineStoreWriteConsumptionInfo {
  /** map key is epoch second start of hour */
  consumption_info?: { [key: string]: OfflineConsumptionBucket } | undefined;
  offline_store_type?: OfflineStoreType | undefined;
}

export interface OfflineStoreWriteConsumptionInfo_ConsumptionInfoEntry {
  key: string;
  value?: OfflineConsumptionBucket | undefined;
}

export interface OfflineConsumptionBucket {
  rows_written?: string | undefined;
  features_written?: string | undefined;
}

export interface OnlineStoreWriteConsumptionInfo {
  /** map key is epoch second start of hour */
  consumption_info?: { [key: string]: OnlineConsumptionBucket } | undefined;
  online_store_type?: OnlineStoreType | undefined;
}

export interface OnlineStoreWriteConsumptionInfo_ConsumptionInfoEntry {
  key: string;
  value?: OnlineConsumptionBucket | undefined;
}

export interface OnlineConsumptionBucket {
  rows_written?: string | undefined;
  features_written?: string | undefined;
}

export interface ComputeConsumptionInfo {
  duration?:
    | Duration
    | undefined;
  /** protolint:disable:this REPEATED_FIELD_NAMES_PLURALIZED */
  compute_usage?: ComputeUsage[] | undefined;
}

export interface ComputeUsage {
  instance_availability?: AwsAvailability | undefined;
  instance_type?: string | undefined;
  instance_count?: string | undefined;
}

/** Next ID: 9 */
export interface TectonManagedStage {
  stage_type?: TectonManagedStage_StageType | undefined;
  state?:
    | TectonManagedStage_State
    | undefined;
  /**
   * Present if the stage is associated with a job in an external system, e.g. executing a Snowflake Pipeline as a
   * Snowflake query.
   */
  external_link?:
    | string
    | undefined;
  /** In the range [0, 1]. May be absent if we don't measure incremental progress for a stage. */
  progress?:
    | number
    | undefined;
  /**
   * A human-readable description of what the stage is doing.
   *
   * Example: "Reading data for time range 2023-09-12 00:00 to 2023-09-13 00:00 from Snowflake table foo.bar.baz "
   */
  description?: string | undefined;
  error_type?:
    | TectonManagedStage_ErrorType
    | undefined;
  /**
   * A large, multi-line string with details of the failure
   * * Display in a monospace font with no syntax highlighting.
   */
  error_detail?:
    | string
    | undefined;
  /**
   * The "compiled" SQL query executing during this stage.
   * * Display in a context which explains the user query is part of the content, but that Tecton adds additional bits
   *   to the query.
   * * Display in a monospace font with SQL syntax highlighting
   */
  compiled_sql_query?:
    | string
    | undefined;
  /** Duration will be updated together with the progress */
  duration?:
    | Duration
    | undefined;
  /** Start of the stage execution (when it went into RUNNING state) */
  start_time?: string | undefined;
}

export enum TectonManagedStage_StageType {
  /** SNOWFLAKE - Executing a Snowflake query */
  SNOWFLAKE = "SNOWFLAKE",
  /** PYTHON - Executing a "user" Python function such as a pandas FeatureView or DataSource function */
  PYTHON = "PYTHON",
  /** AGGREGATE - Running tecton aggregations */
  AGGREGATE = "AGGREGATE",
  /** OFFLINE_STORE - Writing to the offline store */
  OFFLINE_STORE = "OFFLINE_STORE",
  /** ONLINE_STORE - Writing to the online store */
  ONLINE_STORE = "ONLINE_STORE",
  /** BIGQUERY - Executing a BigQuery query */
  BIGQUERY = "BIGQUERY",
  /** BULK_LOAD - Writing to the bulk load store */
  BULK_LOAD = "BULK_LOAD",
}

export enum TectonManagedStage_State {
  STATE_UNSPECIFIED = "STATE_UNSPECIFIED",
  PENDING = "PENDING",
  RUNNING = "RUNNING",
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
  CANCELLED = "CANCELLED",
}

export enum TectonManagedStage_ErrorType {
  ERROR_TYPE_UNSPECIFIED = "ERROR_TYPE_UNSPECIFIED",
  /** UNEXPECTED_ERROR - Anything not covered by any of the other values, e.g. an internal error or a Tecton bug */
  UNEXPECTED_ERROR = "UNEXPECTED_ERROR",
  /** USER_ERROR - An error in a user function/query */
  USER_ERROR = "USER_ERROR",
}

export interface TectonManagedInfo {
  /**
   * The stages making up this materialization job. These should be displayed in order. They are *not* guaranteed to
   * execute serially, i.e. multiple stages can be running at the same time.
   */
  stages?:
    | TectonManagedStage[]
    | undefined;
  /** The overall state of this job */
  state?: TectonManagedStage_State | undefined;
}
