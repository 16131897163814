import React, { FC, useState } from 'react';
import _sortBy from 'lodash/sortBy';
import ACLWorkspaceRoleTableColumn from './ACLWorkspaceRoleTableColumn';

// generated code
import { AclRoleWithInheritance, AclWorkspaceServiceRelationship } from '../../../api/gql/graphql';

// @tecton
import {
  SearchAndActionButton,
  EmptyPrompt,
  EuiBasicTableColumn,
  FlexGroup,
  FlexItem,
  Table,
  IconTip,
  FlexGroupWrapper,
} from '@tecton/ComponentRedesign';

// tecton icons
import { ReactComponent as QuestionInCircle } from '@svg/question-in-circle.svg';
import PrincipalRoles from './PrincipalRoles';

export interface ACLPrincipalWorkspaceRoleListProps {
  assignedWorkspaceRoles: AclWorkspaceServiceRelationship[];
  isAdminView: boolean;

  onEditRole: (workspaceRole: AclWorkspaceServiceRelationship) => void;
  onAssignWorkspaceAccess: () => void;
  isLoading?: boolean;
}

// Errors and loading state should handler out side if this components.
const ACLPrincipalWorkspaceRoleList: FC<ACLPrincipalWorkspaceRoleListProps> = ({
  assignedWorkspaceRoles,

  isAdminView,
  onEditRole,
  onAssignWorkspaceAccess,
  isLoading,
}) => {
  const [searchText, setSearchText] = useState<string | undefined>(undefined);

  const filteredWorkspaceRole = searchText
    ? assignedWorkspaceRoles?.filter((workspaceRole: AclWorkspaceServiceRelationship) => {
        return workspaceRole.workspaceId?.toLowerCase()?.includes(searchText?.toLowerCase());
      })
    : assignedWorkspaceRoles;

  const sortedUsers = isLoading ? undefined : _sortBy(filteredWorkspaceRole, ['workspaceId']);

  const columns: EuiBasicTableColumn<AclWorkspaceServiceRelationship>[] = [
    {
      name: 'Workspace',
      render: (row: AclWorkspaceServiceRelationship) => {
        return <ACLWorkspaceRoleTableColumn workspaceName={row.workspaceId} />;
      },
    },
    ...(isAdminView
      ? [
          {
            name: (
              <>
                <FlexGroup justifyContent="center" alignItems="center" direction="row" gutterSize="none">
                  <FlexItem>Role</FlexItem>
                  <FlexItem>
                    <IconTip
                      content={`A service account’s role can be
directly assigned, inherited
from a group, or set via their ‘All
Workspaces’ roles, Tecton will use
the highest role as the effective
role, as shown in this column.`}
                      type={QuestionInCircle}
                    />
                  </FlexItem>
                </FlexGroup>
              </>
            ),
            render: (row: AclWorkspaceServiceRelationship) => {
              const aclRole = row.role as AclRoleWithInheritance;
              return (
                <>
                  <PrincipalRoles
                    aclRole={aclRole}
                    onClick={() => {
                      onEditRole(row);
                    }}
                  />
                </>
              );
            },
          },
        ]
      : []),
  ];

  return (
    <FlexGroupWrapper gap="s" direction="column">
      <FlexItem>
        <SearchAndActionButton
          searchPlaceHolder={'Search for Workspace'}
          actionButtonLabel={'Assign Workspace Access'}
          actionButtonVariant={isAdminView ? 'primaryAction' : 'disabledAction'}
          onActionButtonClick={() => {
            onAssignWorkspaceAccess();
          }}
          onSearchInputChange={function (search: string): void {
            setSearchText(search ?? undefined);
          }}
        />
      </FlexItem>
      <FlexItem>
        {/* The list will never be empty */}
        <Table
          items={sortedUsers ?? []}
          columns={columns}
          emptyPrompt={
            <EmptyPrompt
              title={<>No Results</>}
              body={
                <>
                  <p>The search filter returned no results.</p>
                </>
              }
            />
          }
        />
      </FlexItem>
    </FlexGroupWrapper>
  );
};

export default ACLPrincipalWorkspaceRoleList;
