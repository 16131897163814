import React, { FC, useState } from 'react';
import _sortBy from 'lodash/sortBy';
import ACLWorkspaceRoleTableColumn from './ACLWorkspaceRoleTableColumn';

import { getRoleNameGQL } from '../../acl/aclUtils';
import RoleIconName from './RoleIconName';

// generated code
import { AclGroupWorkspaceType, RoleDefinition } from '../../../api/gql/graphql';

// @tecton
import {
  SearchAndActionButton,
  EmptyPrompt,
  EuiBasicTableColumn,
  Table,
  FlexGroup,
  FlexItem,
  FlexGroupWrapper,
} from '@tecton/ComponentRedesign';

export interface ACLGroupWorkspaceRoleListProps {
  assignedWorkspaceRoles: AclGroupWorkspaceType[];
  isAdminView: boolean;
  onEditRole: (workspaceRole: AclGroupWorkspaceType) => void;
  onAssignWorkspaceAccess: () => void;
  isLoading?: boolean;
  roles: RoleDefinition[];
}

// Errors and loading state should handler out side if this components.
const ACLGroupWorkspaceRoleList: FC<ACLGroupWorkspaceRoleListProps> = ({
  assignedWorkspaceRoles,
  isAdminView,
  onEditRole,
  onAssignWorkspaceAccess,
  isLoading,
  roles,
}) => {
  const [searchText, setSearchText] = useState<string | undefined>(undefined);

  const filteredWorkspaceRole = searchText
    ? assignedWorkspaceRoles?.filter((workspaceRole: AclGroupWorkspaceType) => {
        return workspaceRole.workspaceId?.toLowerCase()?.includes(searchText?.toLowerCase());
      })
    : assignedWorkspaceRoles;

  const sortedUsers = isLoading ? undefined : _sortBy(filteredWorkspaceRole, ['workspaceId']);

  const columns: EuiBasicTableColumn<AclGroupWorkspaceType>[] = [
    {
      name: 'Workspace',
      render: (row: AclGroupWorkspaceType) => {
        return <ACLWorkspaceRoleTableColumn workspaceName={row.workspaceId} />;
      },
    },
    ...(isAdminView
      ? [
          {
            name: (
              <>
                <FlexGroup justifyContent="center" alignItems="center" direction="row" gutterSize="none">
                  <FlexItem>Role</FlexItem>
                </FlexGroup>
              </>
            ),
            render: (row: AclGroupWorkspaceType) => {
              return (
                <>
                  <RoleIconName
                    role={getRoleNameGQL(roles, row.role ?? '')}
                    onClick={() => {
                      onEditRole(row);
                    }}
                  />
                </>
              );
            },
          },
        ]
      : []),
  ];

  return (
    <FlexGroupWrapper gap="s" direction="column">
      <FlexItem>
        <SearchAndActionButton
          searchPlaceHolder={'Search for Workspace'}
          actionButtonLabel={'Assign Workspace Access'}
          actionButtonVariant={isAdminView ? 'primaryAction' : 'disabledAction'}
          onActionButtonClick={() => {
            onAssignWorkspaceAccess();
          }}
          onSearchInputChange={function (search: string): void {
            setSearchText(search ?? undefined);
          }}
        />
      </FlexItem>
      <FlexItem>
        {/* The list will never be empty */}
        <Table
          items={sortedUsers ?? []}
          columns={columns}
          emptyPrompt={
            <EmptyPrompt
              title={<>No Results</>}
              body={
                <>
                  <p>The search filter returned no results.</p>
                </>
              }
            />
          }
        />
      </FlexItem>
    </FlexGroupWrapper>
  );
};

export default ACLGroupWorkspaceRoleList;
