import { FC, useContext, useState } from 'react';
import { useGetPermissions } from '../../../api/queries/aclGraphql';
import { useUserSettings } from '../../context/UserSettingsContext';
import PermissionsGroupList from './PermissionsGroupList';

import { useAssignRolesMutation } from '../../../api/acl';
import AddGroupToWorkspace from '../acl/AddGroupToWorkspace';
import ModifyWorkspaceAccess from '../acl/ModifyWorkspaceAccess';

// utils
import { getLegacyRoleIdGQL } from '../../acl/aclUtils';
import { getFilterRolesForServiceAccount, getRoleOptions, getRoleToPermissionMap } from '../acl/acl-util';

// generated
import { PermissionGroup, RoleAssignmentSource } from '../../../api/gql/graphql';
import { PrincipalType } from '../../../types/tecton_proto/auth/principal';
import { ResourceType } from '../../../types/tecton_proto/auth/resource';

import { Modal, getToastComponent } from '@tecton/ComponentRedesign';
import { ToastContext, addToast } from '@tecton/ToastContext';
import { logEvent } from '../../../utils/analytics-utils';

const PermissionsGroupsListContainer: FC = () => {
  const { hasWorkspaceManagerAccess, workspace, isAdmin } = useUserSettings();
  const toastContext = useContext(ToastContext);

  // CRUD (Mutation) hooks
  const { data, isLoading, isError } = useGetPermissions(workspace ?? '');
  const assignRoleMutation = useAssignRolesMutation();

  const [selectedGroup, setSelectedGroup] = useState<PermissionGroup | undefined>();

  // General states
  const [originalRole, setOriginalRole] = useState<string | undefined>();
  const [selectedRole, setSelectedRole] = useState<string | undefined>();
  const [roleAssignmentSource, setRoleAssignmentSource] = useState<RoleAssignmentSource[] | undefined>();

  // Modal state
  const [showEditWorkspaceRoleModal, setShowEditWorkspaceRoleModal] = useState<boolean>(false);
  const [showAddGroupModal, setShowAddGroupModal] = useState<boolean>(false);

  const filteredRoles = getFilterRolesForServiceAccount(data?.roles ?? [], PrincipalType.PRINCIPAL_TYPE_GROUP);

  const roleIds = filteredRoles?.map((role) => role.id) ?? [];
  const editableRoles = getRoleOptions(filteredRoles, [...roleIds, 'none'], true);
  const AddGroupRoles = getRoleOptions(filteredRoles, roleIds, false);

  const roleDescriptionMap = getRoleToPermissionMap(filteredRoles);

  const reset = () => {
    setSelectedGroup(undefined);
    setOriginalRole(undefined);
    setSelectedRole(undefined);
    setRoleAssignmentSource(undefined);
    setShowEditWorkspaceRoleModal(false);
    setShowAddGroupModal(false);
  };

  // --------------------------------------- Start: Actions ---------------------------------

  const onEditGroupRole = (group: PermissionGroup) => {
    const roleSource =
      group.rolesGranted?.flatMap((rolesGranted) => {
        return rolesGranted?.roleAssignmentSources?.map((roleAssignmentSource) => roleAssignmentSource) ?? [];
      }) ?? [];
    setSelectedGroup(group);
    setOriginalRole(selectedGroup?.workspaceRole ?? '');
    setSelectedRole(selectedGroup?.workspaceRole ?? '');
    setRoleAssignmentSource(roleSource?.filter((group) => !!group?.principalGroupId));
    setShowEditWorkspaceRoleModal(true);
  };

  const onRoleChange = (roleName: string) => {
    setSelectedRole(roleName);
  };

  const onModifyWorkspaceRole = () => {
    const removingRole = selectedRole === 'None';
    const principal_type = PrincipalType.PRINCIPAL_TYPE_GROUP;

    const role = getLegacyRoleIdGQL(data?.roles ?? [], selectedRole ?? ''); // fix this.
    const roles = removingRole ? [] : [role ?? ''];
    const resource_id = workspace;

    const resource_type = ResourceType.RESOURCE_TYPE_WORKSPACE;

    const payload = {
      principal_id: selectedGroup?.id,
      roles,
      resource_id,
      principal_type,
      resource_type,
    };

    assignRoleMutation.mutate(payload, {
      onSuccess: () => {
        reset();
        logEvent(`Permissions: ${removingRole ? `Group Removed From Workspace` : `Group Role Updated`}`, '', {
          workspace: workspace,
          group_id: selectedGroup?.id,
          group_name: selectedGroup?.name,
          role: selectedRole,
        });
        const toastContent = getToastComponent({
          variant: 'success',
          title: removingRole ? `Group Removed From Workspace` : `Group Role Updated`,
          children: removingRole ? (
            <>
              Group {selectedGroup?.name} removed from {workspace}.
            </>
          ) : (
            <>
              Role updated for {selectedGroup?.name} to {selectedRole}.
            </>
          ),
          showIcon: true,
        });

        toastContext?.dispatchToast(
          addToast({
            text: toastContent,
          })
        );
      },
      onError: () => {
        const toastContent = getToastComponent({
          variant: 'success',
          title: removingRole ? `Failed To Remove Group` : `Failed To Update Role`,
          children: removingRole ? (
            <>
              Failed To Remove Group {selectedGroup?.name} from workspace {workspace}.
            </>
          ) : (
            <>Failed to update group role. Please try again.</>
          ),
          showIcon: true,
        });

        toastContext?.dispatchToast(
          addToast({
            text: toastContent,
          })
        );
      },
    });
  };

  const onAddGroupToWorkspaceConfirm = () => {
    const principal_type = PrincipalType.PRINCIPAL_TYPE_GROUP;
    const role = getLegacyRoleIdGQL(data?.roles ?? [], selectedRole ?? '');
    const roles = [role ?? ''];
    const resource_id = workspace;
    const resource_type = ResourceType.RESOURCE_TYPE_WORKSPACE;

    const payload = {
      principal_id: selectedGroup?.id ?? '',
      roles,
      resource_id,
      principal_type,
      resource_type,
    };

    assignRoleMutation.mutate(payload, {
      onSuccess: () => {
        reset();
        logEvent(`Permissions: Group Added To Workspace`, '', {
          workspace: workspace,
          group_id: selectedGroup?.id,
          group_name: selectedGroup?.name,
          role: selectedRole,
        });
        const toastContent = getToastComponent({
          variant: 'success',
          title: `Group Added To Workspace`,
          children: (
            <>
              Group {selectedGroup?.name} was added to workspace {workspace} as {selectedRole}.
            </>
          ),
          showIcon: true,
        });

        toastContext?.dispatchToast(
          addToast({
            text: toastContent,
          })
        );
      },
      onError: () => {
        const toastContent = getToastComponent({
          variant: 'danger',
          title: `Failed To Add Group`,
          children: <>Failed to Add group to workspace {workspace}</>,
          showIcon: true,
        });

        toastContext?.dispatchToast(
          addToast({
            text: toastContent,
          })
        );
      },
    });
  };
  // ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ End: Actions  ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^

  return (
    <>
      {showEditWorkspaceRoleModal && (
        <Modal
          title={`Modify role of ${selectedGroup?.name} in ${workspace} `}
          body={
            <>
              <ModifyWorkspaceAccess
                roles={editableRoles}
                workspaceRole={selectedGroup?.workspaceRole ?? ''}
                roleDescriptionMap={roleDescriptionMap}
                onRoleChange={onRoleChange}
                roleAssignmentSources={roleAssignmentSource}
                id={selectedGroup?.id ?? ''}
              />
            </>
          }
          onCancel={() => {
            reset();
          }}
          confirmLabel="Change Access"
          onConfirm={() => {
            onModifyWorkspaceRole();
          }}
          variant="positive"
          confirmVariant={originalRole !== selectedRole ? 'primaryAction' : 'disabledAction'}
          isConfirmButtonLoading={assignRoleMutation.isLoading}
        />
      )}

      {showAddGroupModal && (
        <Modal
          title={data?.assignableGroups?.length === 0 ? 'All Groups have access' : `Add Group to ${workspace}`}
          body={
            <>
              <AddGroupToWorkspace
                roles={AddGroupRoles}
                roleDescriptionMap={roleDescriptionMap}
                onChange={(selectedRole?: string, group?: PermissionGroup) => {
                  setSelectedRole(selectedRole);
                  setSelectedGroup(group);
                }}
                assignableGroups={data?.assignableGroups ?? []}
              />
            </>
          }
          onCancel={() => {
            reset();
          }}
          confirmLabel="Change Access"
          onConfirm={() => {
            onAddGroupToWorkspaceConfirm();
          }}
          variant={data?.assignableGroups?.length === 0 ? 'negative' : 'positive'}
          confirmVariant={selectedGroup && selectedRole ? 'primaryAction' : 'disabledAction'}
          isConfirmButtonLoading={assignRoleMutation.isLoading}
        />
      )}
      <PermissionsGroupList
        groups={data?.groups ?? []}
        isLoading={isLoading}
        isError={isError}
        hasWorkspaceOwnerRole={hasWorkspaceManagerAccess ?? false}
        onCreateGroupClick={() => {
          setShowAddGroupModal(true);
        }}
        onEditGroupRole={onEditGroupRole}
        isAdmin={isAdmin ?? false}
      />
    </>
  );
};

export default PermissionsGroupsListContainer;
