import React, { FC, useState } from 'react';

import AccountTypeRadioOption from './AccountTypeRadioOption';
import CapabilityList from './CapabilityList';
import { RadioGroupOption } from './acl-types';
import { getRoleRadioGroupOptions } from './acl-util';

// generated
import { AclGroupWorkspaceType, Option, Permission } from '../../../api/gql/graphql';

// @tecton
import { ComboBox, FlexGroupWrapper, FlexItem } from '@tecton/ComponentRedesign';
import { ACLModalFormWrapper } from './StyledComponents';

interface AddWorkspaceToGroupProps {
  roles: RadioGroupOption[];
  assignableWorkspaces: Option[];
  workspaceRole?: AclGroupWorkspaceType;
  roleDescriptionMap?: Record<string, Permission[]>;
  onRoleChange: (roleName: string /** undefined means the selection didn't change */) => void;
  onWorkspaceSelected: (workspace: string) => void;
}

const AddWorkspaceToGroup: FC<AddWorkspaceToGroupProps> = ({
  roles,
  assignableWorkspaces,
  workspaceRole,
  roleDescriptionMap,
  onRoleChange,
  onWorkspaceSelected,
}) => {
  const permissions = roleDescriptionMap ? roleDescriptionMap[`${workspaceRole?.role}`] : [];

  // general state
  const [roleDescription, setRoleDescription] = useState<Permission[]>(permissions);
  const [options, setOptions] = useState<RadioGroupOption[]>(
    getRoleRadioGroupOptions(roles, workspaceRole?.role ?? '')
  );
  const [selectedOptions, setSelected] = useState<Option[]>([]);

  return (
    <>
      <FlexGroupWrapper gap="l" direction="column">
        <FlexItem grow={true}>
          <ComboBox
            placeholder="Select a Workspace"
            singleSelection={{ asPlainText: true }}
            options={assignableWorkspaces}
            fullWidth={true}
            selectedOptions={selectedOptions}
            onChange={(selectedOptions) => {
              const selected = selectedOptions[0]?.label;
              onWorkspaceSelected(selected);
              setSelected(selectedOptions);
            }}
          />
        </FlexItem>
        <FlexItem>
          <ACLModalFormWrapper>
            <FlexGroupWrapper gap="xl" direction="row">
              <FlexItem grow={8}>
                <AccountTypeRadioOption
                  options={options ?? []}
                  onSelection={(selectedOption) => {
                    const permissions = roleDescriptionMap ? roleDescriptionMap[`${selectedOption?.label}`] : [];
                    setOptions(getRoleRadioGroupOptions(roles, selectedOption?.label ?? ''));
                    setRoleDescription(permissions);
                    onRoleChange(selectedOption?.label ?? '');
                  }}
                />
              </FlexItem>
              <FlexItem grow={6}>
                <CapabilityList roleDescriptions={roleDescription ?? []} maxHeight={600} />
              </FlexItem>
            </FlexGroupWrapper>
          </ACLModalFormWrapper>
        </FlexItem>
      </FlexGroupWrapper>
    </>
  );
};

export default AddWorkspaceToGroup;
