import React, { useState } from 'react';

import { ExclusiveUnion } from '@tecton';
import {
  EuiSelectableGroupLabelOption,
  EuiSelectableLIOption,
} from '@elastic/eui/src/components/selectable/selectable_option';

interface SelectableOptionInterface {
  columnKey: string;
  label: string;
  checked?: 'on';
}

export type ColumnsOptionType = ExclusiveUnion<
  EuiSelectableGroupLabelOption<SelectableOptionInterface>,
  EuiSelectableLIOption<SelectableOptionInterface>
>;

const parseLocalStorageColumnOptions = (
  data: object,
  initialState: ColumnsOptionType[]
): ColumnsOptionType[] | undefined => {
  // Parse object and make sure it conforms
  // to ColumnsOptionType

  if (Array.isArray(data)) {
    const keys: Record<string, 'on' | undefined> = {};

    data.forEach((d: any) => {
      if (
        typeof d === 'object' &&
        d.columnKey &&
        typeof d.columnKey === 'string' &&
        (d.checked === 'on' || d.checked === undefined)
      ) {
        keys[d.columnKey] = d.checked;
      }
    });

    return initialState.map((d): ColumnsOptionType => {
      return {
        label: d.label,
        columnKey: d.columnKey,
        checked: keys[d.columnKey] || undefined,
      };
    });
  } else {
    return undefined;
  }
};

const fetchFromLocalStorage = (key: string, initialState: ColumnsOptionType[]): ColumnsOptionType[] | undefined => {
  const storedValue = window.localStorage.getItem(key);
  return storedValue !== null ? parseLocalStorageColumnOptions(JSON.parse(storedValue), initialState) : initialState;
};

const useColumnsDisplayControlsState = (
  tableKey: string,
  initialState?: ColumnsOptionType[],
  disable?: boolean,
  forcedEnabled: string[] = []
) => {
  const [isColumnControlsOpen, setIsColumnControlsOpen] = useState<boolean>(false);

  const localStorageState = disable ? null : fetchFromLocalStorage(tableKey, initialState || []);

  const [columnOptions, unwrappedSetColumnOptions] = useState<ColumnsOptionType[]>(
    localStorageState || initialState || []
  );

  const setColumnOptions = (options: ColumnsOptionType[]) => {
    window.localStorage.setItem(tableKey, JSON.stringify(options));

    unwrappedSetColumnOptions(options);
  };

  const numberOfSelectedColumnsOptions: number = columnOptions.reduce((memo, current) => {
    return current.checked === 'on' ? memo + 1 : memo;
  }, 0);

  const activeColumns: string[] = [
    ...columnOptions.filter((o) => o.checked === 'on').map((o) => o.columnKey),
    ...forcedEnabled,
  ];

  return {
    isColumnControlsOpen,
    setIsColumnControlsOpen,
    columnOptions,
    setColumnOptions,
    numberOfSelectedColumnsOptions,
    activeColumns,
  };
};

export { useColumnsDisplayControlsState };
