import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import SelectWorkspaceMessage from './SelectWorkspaceMessage';

import WorkspaceSelectionMockNavigation from './WorkspaceSelectionMockNavigation';

interface SelectWorkspaceInterface {}

const SelectWorkspace: FC<SelectWorkspaceInterface> = () => {
  return (
    <Box display="flex" sx={{ width: '100vw', height: '100vh' }}>
      <WorkspaceSelectionMockNavigation />

      <Box sx={{ flexGrow: 1 }}>
        <SelectWorkspaceMessage
          subHead={
            <Box sx={{ fontStyle: 'italic', fontSize: '14px', color: '#74808E', fontWeight: 400, lineHeight: '17px' }}>
              Before we start...
            </Box>
          }
          headline="Select a Workspace"
          content={
            <>
              <Typography variant="body1">
                Tecton organizes, data, features, and services into individual workspaces. Select one of the workspaces
                below to get started.
              </Typography>
            </>
          }
        />
      </Box>
    </Box>
  );
};

export default SelectWorkspace;
