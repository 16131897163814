import React from 'react';
import { AnchorDataflowCardDescriptionWrapper, AnchorWrapper } from './VisualPrimitives/CardTypography';
import { DataflowCardPropType, FeatureViewNode, ODFVNode } from './dataFlowTypes';
import { DataFlowFCOIcon } from './DataFlowFCONode';
import { FeatureViewFCOType } from '../../../../core/types/fcoTypes';
import styled from '@emotion/styled';

const VERTICAL_OFFSET = 100;

const AnchorDataflowCard = styled.div<{ data: FeatureViewNode | ODFVNode }>`
  position: relative;
  width: ${({ data }) => data.width}px;
  height: ${({ data }) => VERTICAL_OFFSET + data.height!}px;
  transition: opacity 0.3;
  box-shadow: ${({ theme }) => theme.shadow.xl};
  transform: translateY(-${VERTICAL_OFFSET}px);
`;

const AnchorHeader = styled.div`
  background: ${({ theme }) => theme.colors.emptyShade};
  border-bottom: 1px solid #999;
  padding: 1.2em 1.75em 0.7em 1.75em;
  border-top-right-radius: ${({ theme }) => theme.border.radius.medium};
  border-top-left-radius: ${({ theme }) => theme.border.radius.medium};
`;

const FeatureServiceTypeLabel = styled.div`
  font-size: ${({ theme }) => theme.font.fontSizes.xs};
  font-weight: ${({ theme }) => theme.font.weight.regular};
  color: ${({ theme }) => theme.colors.subduedText};
`;

const FeatureServiceNameLabel = styled.div`
  font-size: ${({ theme }) => theme.font.fontSizes.s};
  font-weight: ${({ theme }) => theme.font.weight.medium};
  color: ${({ theme }) => theme.colors.title};
`;

const TitleLayout = styled.div`
  display: grid;
  grid-template-columns: 48px 1fr;
`;

const DataFlowFeatureViewAnchorCard = ({
  data,
  onMouseEnter,
  onMouseLeave,
  onClick,
}: { data: FeatureViewNode | ODFVNode } & DataflowCardPropType) => {
  const description = data.description || 'No description';

  const featureViewTypeLabels: Record<FeatureViewFCOType, string> = {
    [FeatureViewFCOType.BATCH]: 'Batch',
    [FeatureViewFCOType.BATCH_WINDOW_AGGREGATE]: 'Batch',
    [FeatureViewFCOType.STREAM]: 'Stream',
    [FeatureViewFCOType.STREAM_WINDOW_AGGREGATE]: 'Stream',
    [FeatureViewFCOType.REALTIME]: 'Realtime',
    [FeatureViewFCOType.FEATURE_TABLE]: 'Feature Table',
    [FeatureViewFCOType.UNKNOWN]: 'Unknown',
    [FeatureViewFCOType.PUSH_WITH_BATCH]: 'Batch',
    [FeatureViewFCOType.PUSH_NO_BATCH]: 'OBatch',
  };

  return (
    <AnchorWrapper>
      <AnchorDataflowCard onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onClick={onClick} data={data}>
        <AnchorHeader>
          <TitleLayout>
            <div>
              <DataFlowFCOIcon fcoType={'feature_view'} />
            </div>
            <div>
              {data.type === 'feature_view' && (
                <FeatureServiceTypeLabel>
                  {featureViewTypeLabels[(data as FeatureViewNode).featureViewType!].toUpperCase()} FEATURE VIEW
                </FeatureServiceTypeLabel>
              )}
              {data.type === 'odfv' && <FeatureServiceTypeLabel>REALTIME FEATURE VIEW</FeatureServiceTypeLabel>}
              <FeatureServiceNameLabel>{data.name}</FeatureServiceNameLabel>
            </div>
          </TitleLayout>
        </AnchorHeader>
        <AnchorDataflowCardDescriptionWrapper>
          <p>{description}</p>
        </AnchorDataflowCardDescriptionWrapper>
      </AnchorDataflowCard>
    </AnchorWrapper>
  );
};

export default DataFlowFeatureViewAnchorCard;
