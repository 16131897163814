import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { Workspace } from '../@tecton/ComponentRedesign/SharedComponentTypes';
import { FlexGroupWrapper, LinkTextWrapper } from '../@tecton/ComponentRedesign/StyledComponents';

// @tecton
import { FlexItem, Icon } from '@tecton/ComponentRedesign';

// @svg
import styled from '@emotion/styled';
import DevelopmentWorkspace from '@svg/development-workspace.svg';
import LiveWorkspace from '@svg/live-workspace.svg';
import { breakPointForHidingFCODetails } from './SearchResultSelected';

type WorkspaceRootBreadcrumbVariant = 'standard' | 'small';

interface WorkspaceRootBreadcrumbProps {
  variant?: WorkspaceRootBreadcrumbVariant;
  workspace?: Workspace;
  maxWidth?: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
}

const LiveWorkspaceIcon = <Icon type={LiveWorkspace} />;
const DevelopmentWorkspaceIcon = <Icon type={DevelopmentWorkspace} />;

const WorkspaceWrapper = styled.div<{ variant: WorkspaceRootBreadcrumbVariant; maxWidth?: string }>`
  ${({ maxWidth }) => {
    return (
      maxWidth &&
      `max-width: ${maxWidth};
      // Change the max-width for workspace name
      @media (max-width: calc(${breakPointForHidingFCODetails} + 64px)) {
        max-width: 134px;
      }
    `
    );
  }};

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: ${({ theme, variant }) => (variant === 'small' ? theme.font.fontSizes.xs : theme.font.fontSizes.s)};
`;

const WorkspaceRootBreadcrumb: FC<WorkspaceRootBreadcrumbProps> = ({
  variant = 'standard',
  workspace,
  maxWidth,
  onClick,
}) => {
  const icon = workspace?.workspaceType === 'Live' ? LiveWorkspaceIcon : DevelopmentWorkspaceIcon;

  return (
    <LinkTextWrapper>
      <Link to={`/repo/${workspace?.name}/home/dataflow`} onClick={onClick}>
        <>
          <FlexGroupWrapper gap="xs" direction="row" alignItems="center">
            <FlexItem grow={false}>{icon}</FlexItem>
            <FlexItem grow={false}>
              <WorkspaceWrapper variant={variant} maxWidth={maxWidth}>
                {workspace?.name}
              </WorkspaceWrapper>
            </FlexItem>
          </FlexGroupWrapper>
        </>
      </Link>
    </LinkTextWrapper>
  );
};

export default WorkspaceRootBreadcrumb;
