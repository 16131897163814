import { ButtonEmpty, Flyout } from '@tecton/ComponentRedesign';
import { FC, useState } from 'react';
import { useGetSparkConfig } from '../../../api/spark-config';
import { SparkClusterConfig } from '../../../types/tecton_proto/metadataservice/metadata_service';

import CodeDocumentIcon from '@svg/code-document.svg';
import { FCOFields, FeatureViewFCO } from '../../../core/types/fcoTypes';
import ClusterConfigFlyout from './ClusterConfigFlyout';

interface SparkConfigFlyoutProps {
  workspace: string;
  fco: FeatureViewFCO;
  configType: 'batch' | 'stream';
}

const SparkConfigFlyout: FC<SparkConfigFlyoutProps> = ({ workspace, fco, configType }) => {
  const [IsFlyoutOpen, setIsFlyoutOpen] = useState<boolean>(false);
  const featureViewName = fco[FCOFields.NAME];

  const { data: dataSparkConfig, isLoading: isLoadingSparkConfig } = useGetSparkConfig(
    workspace ?? '',
    featureViewName ?? ''
  );

  if (isLoadingSparkConfig) {
    return <>N/A</>;
  }

  const batchData: Readonly<SparkClusterConfig | undefined> = dataSparkConfig?.batch_config;
  const streamData: Readonly<SparkClusterConfig | undefined> = dataSparkConfig?.stream_config;

  const originalJson = configType === 'batch' ? batchData?.original : streamData?.original;
  const finalJson = configType === 'batch' ? batchData?.final : streamData?.final;

  return (
    <>
      {IsFlyoutOpen && (
        <>
          <Flyout
            title={<>Spark JSON Config</>}
            onClose={() => {
              setIsFlyoutOpen(false);
            }}
          >
            <ClusterConfigFlyout originalJson={originalJson} outputJson={finalJson} />
          </Flyout>
        </>
      )}
      <ButtonEmpty
        onClick={() => {
          setIsFlyoutOpen(true);
        }}
        iconType={CodeDocumentIcon}
      >
        View Definition
      </ButtonEmpty>
    </>
  );
};

export default SparkConfigFlyout;
