export default () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.9838 6.746L12.5338 2.77467C12.4198 1.764 11.5645 1 10.5471 1H3.45247C2.43514 1 1.5798 1.764 1.46514 2.77467L1.01514 6.746"
        stroke="#525866"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11 9H3C1.89533 9 1 9.89533 1 11V11C1 12.1047 1.89533 13 3 13H11C12.1047 13 13 12.1047 13 11V11C13 9.89533 12.1047 9 11 9Z"
        stroke="#525866"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11 5H3C1.89533 5 1 5.89533 1 7V7C1 8.10467 1.89533 9 3 9H11C12.1047 9 13 8.10467 13 7V7C13 5.89533 12.1047 5 11 5Z"
        stroke="#525866"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.3335 6.99996H11.0002"
        stroke="#525866"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.02341 6.97639C3.03642 6.98941 3.03642 7.01051 3.02341 7.02353C3.01039 7.03655 2.98928 7.03655 2.97627 7.02353C2.96325 7.01051 2.96325 6.98941 2.97627 6.97639C2.98928 6.96337 3.01039 6.96337 3.02341 6.97639"
        stroke="#323232"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.37988 11H11.0465"
        stroke="#323232"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.06735 10.9764C3.08037 10.9894 3.08037 11.0105 3.06735 11.0235C3.05434 11.0365 3.03323 11.0365 3.02021 11.0235C3.00719 11.0105 3.00719 10.9894 3.02021 10.9764C3.03323 10.9634 3.05434 10.9634 3.06735 10.9764"
        stroke="#323232"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
