import { CenterContentLayout, FlexGroupWrapper, FlexItem, LinkIcon } from '@tecton/ComponentRedesign';
import { TECTON_DOCUMENTATION_LINKS } from '../application-links';

// icons
import styled from '@emotion/styled';
import Book from '@svg/book.svg';

const NoDataWrapper = styled.div`
  color: ${({ theme }) => theme.colors.fullShade};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
`;

const DescriptionWrapper = styled.div`
  color: ${({ theme }) => theme.colors.subduedText};
  font-size: ${({ theme }) => theme.font.fontSizes.s};
  max-width: 260px;
`;

const NoData = () => {
  return (
    <>
      <CenterContentLayout>
        <FlexGroupWrapper gap="l" direction={'column'} alignItems="center">
          <FlexItem grow>
            <NoDataWrapper>No Data Available</NoDataWrapper>
          </FlexItem>
          <FlexItem grow>
            <DescriptionWrapper>This workspace has not been configured or there is not data yet.</DescriptionWrapper>
          </FlexItem>
          <FlexItem grow>
            <LinkIcon
              linkName={'Learn More'}
              href={TECTON_DOCUMENTATION_LINKS.MONITORING_AND_DEBUGGING_ONLINE_SERVING}
              iconType={Book}
              target="_blank"
            />
          </FlexItem>
        </FlexGroupWrapper>
      </CenterContentLayout>
    </>
  );
};

export default NoData;
