import { getVisibleAnimationsOnAllEdgesGivenAnchors, mergeEdgeAnimationsRecords } from './highlight-utils';
import highlightDownstream from './highlightDownstream';
import { EdgeShouldBeSkippedFunctionSignature, HighlightFunctionType } from './highlightFunctionType';
import highlightUpstream from './highlightUpstream';

const highlightFeatureService: HighlightFunctionType = (id, nodesMap, edgesList) => {
  /**
   * Skip Request Data Source nodes that are part of other Feature Services
   */
  const skipOtherRequestDataSource: EdgeShouldBeSkippedFunctionSignature = (sourceNode, _targetNode) => {
    if (sourceNode.type === 'request_data_source') {
      if (!sourceNode.upstreamNodes || sourceNode.upstreamNodes.length !== 1 || !sourceNode.upstreamNodes[0]) {
        throw new Error(`Expecting Request Data Source '${id}' to have a Feature Service as its only upstreamNode.`);
      }

      const sourceFeatureServiceId = sourceNode.upstreamNodes[0];

      return sourceFeatureServiceId !== id;
    }
    return false;
  };

  const hoveredNode = nodesMap[id];

  const upstream = highlightUpstream(id, nodesMap, edgesList, {
    edgeShouldBeSkipped: skipOtherRequestDataSource,
    hideOnlineStore: hoveredNode.type === 'feature_service' && hoveredNode.isOnlineServingEnabled !== true,
  });

  const downstreamToStore = highlightDownstream(id, nodesMap, edgesList);

  const linkedIds = new Set([...upstream.linkedIds, ...downstreamToStore.linkedIds]);
  const linkedEdges = new Set([...upstream.linkedEdges, ...downstreamToStore.linkedEdges]);

  /**
   * Compute animations
   */

  const featureServiceHasOnlineServing = hoveredNode.type === 'feature_service' && hoveredNode.isOnlineServingEnabled;

  const featureViewIds = [...linkedIds].filter((fvId) => {
    const node = nodesMap[fvId];

    return node.type === 'feature_view';
  });

  /**
   * For each Feature View, get the relevant animations for the Feature Service
   */
  const featureViewAnimations = featureViewIds.map((fvId) => {
    const node = nodesMap[fvId];

    // Show batch materialization animations if the FV has offline materialization enabled
    const batchMaterializationToOfflineStore = node.type === 'feature_view' && node.isOfflineMaterializationEnabled;

    // If the FS has online serving, show batch materialization animations if the FV is NOT a Stream FV, and it has online materialization enabled
    const batchMaterializationToOnlineStore =
      featureServiceHasOnlineServing &&
      node.type === 'feature_view' &&
      node.featureViewType !== 'stream' &&
      node.isOnlineMaterializationEnabled;

    const showBatchMaterializationPath = batchMaterializationToOnlineStore || batchMaterializationToOfflineStore;

    const showStreamMaterializationPath =
      featureServiceHasOnlineServing &&
      node.type === 'feature_view' &&
      node.featureViewType === 'stream' &&
      node.isOnlineMaterializationEnabled;

    return getVisibleAnimationsOnAllEdgesGivenAnchors(new Set<string>([fvId]), edgesList, linkedEdges, {
      showOfflineReadPath: true,
      showBatchMaterializationPath,
      showStreamMaterializationPath,
    });
  });

  const mergedFVAnimations = mergeEdgeAnimationsRecords(featureViewAnimations);

  const featureServiceAnchor = new Set<string>([id]);
  const fsAnimations = getVisibleAnimationsOnAllEdgesGivenAnchors(featureServiceAnchor, edgesList, linkedEdges);
  const animations = mergeEdgeAnimationsRecords([fsAnimations, mergedFVAnimations]);

  return {
    linkedIds,
    linkedEdges,
    animations,
  };
};

export default highlightFeatureService;
