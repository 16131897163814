/* eslint-disable */
import { IdFcoLocator } from "../common/fco_locator";

export const protobufPackage = "tecton_proto.data";

export enum OnlineBackfillLoadType {
  ONLINE_BACKFILL_LOAD_TYPE_UNSPECIFIED = "ONLINE_BACKFILL_LOAD_TYPE_UNSPECIFIED",
  /** ONLINE_BACKFILL_LOAD_TYPE_TASK - Original version and default; each task writes to online store */
  ONLINE_BACKFILL_LOAD_TYPE_TASK = "ONLINE_BACKFILL_LOAD_TYPE_TASK",
  /**
   * ONLINE_BACKFILL_LOAD_TYPE_BULK - Each backfill job writes to offline store and then a separate job
   * writes to online store after all backfill jobs are complete. Forwardfill jobs use standard batch tasks.
   */
  ONLINE_BACKFILL_LOAD_TYPE_BULK = "ONLINE_BACKFILL_LOAD_TYPE_BULK",
  /**
   * ONLINE_BACKFILL_LOAD_TYPE_COMPACTION - Each backfill job writes to offline store and then a separate compaction job
   * writes to online store after all backfill jobs are complete. Forwardfill jobs use standard offline tasks and online compaction tasks.
   */
  ONLINE_BACKFILL_LOAD_TYPE_COMPACTION = "ONLINE_BACKFILL_LOAD_TYPE_COMPACTION",
}

export enum TableFormatVersion {
  TABLE_FORMAT_VERSION_DEFAULT_UNSPECIFIED = "TABLE_FORMAT_VERSION_DEFAULT_UNSPECIFIED",
  /**
   * TABLE_FORMAT_VERSION_V2 - Version 2 is for the batch compaction tables with the new DynamoDB data layout
   * the feature view needs to have batch compaction enabled to have this version
   */
  TABLE_FORMAT_VERSION_V2 = "TABLE_FORMAT_VERSION_V2",
  /**
   * TABLE_FORMAT_VERSION_V3 - Version 3 is for the batch compaction tables that are compatible with
   * stream compaction feature views by adding sawtooths.
   */
  TABLE_FORMAT_VERSION_V3 = "TABLE_FORMAT_VERSION_V3",
}

export enum OnlineDataTableState {
  DATA_TABLE_STATE_UNKNOWN_UNSPECIFIED = "DATA_TABLE_STATE_UNKNOWN_UNSPECIFIED",
  DATA_TABLE_STATE_NOT_READY = "DATA_TABLE_STATE_NOT_READY",
  DATA_TABLE_STATE_READY = "DATA_TABLE_STATE_READY",
  DATA_TABLE_STATE_PENDING_DELETION = "DATA_TABLE_STATE_PENDING_DELETION",
  DATA_TABLE_STATE_DELETED = "DATA_TABLE_STATE_DELETED",
}

export interface FileLocation {
  /**
   * May be adding other fields such as region here in the future
   * This could be an s3 or dbfs path
   */
  path?: string | undefined;
}

/**
 * Internal materialization state of the FeatureView
 * Important: should only contain state that is managed by the internal Tecton
 * processes, e.g., orchestrator, and *not* state managed declaratively - that
 * one should go into store/feature_view.proto
 */
export interface FvMaterialization {
  id_feature_view_locator?:
    | IdFcoLocator
    | undefined;
  /**
   * Serial ID of the latest materialization version of the FeatureView.
   * Upon enabling materialization it is set to 1 and is incremented every time
   * either online_materialization_enabled, offline_materialization_enabled,
   * or feature_start_time changes
   */
  materialization_serial_version?:
    | number
    | undefined;
  /** Offline Feature Store location */
  materialized_data_location?:
    | FileLocation
    | undefined;
  /** Streaming Jobs checkpoint location. Last in the list is used. */
  streaming_checkpoint_locations?: FileLocation[] | undefined;
  has_materialized_data?: boolean | undefined;
  feature_store_format_version?:
    | number
    | undefined;
  /**
   * offline data location for miscellaneous offline data besides the offline
   * store
   */
  misc_offline_data_location?:
    | FileLocation
    | undefined;
  /**
   * if true, materialization task will import online table instead of
   * TableCreator. These two fields are used for fvs with ONLINE_BACKFILL_LOAD_TYPE_BULK.
   */
  online_table_imported_by_materialization?: boolean | undefined;
  online_table_import_complete?:
    | boolean
    | undefined;
  /** storage location for exported features */
  feature_export_data_location?:
    | FileLocation
    | undefined;
  /** only populated for FVs with compaction */
  online_data_tables?:
    | OnlineDataTable[]
    | undefined;
  /**
   * the next two fields are one-time operation and will be reverted after clean up is done
   * Tracked at: https://tecton.atlassian.net/browse/TEC-16668
   * RFC: https://www.notion.so/Mini-RFC-Deleted-FV-Data-Cleanup-2f436816a19642a69ef78052a7022572
   * a flag to indicate CleanupUndeletedS3ObjectsPeriodicTask has re-assessed
   * this entry to make sure S3 objects is cleaned up.
   */
  has_been_revisted?:
    | boolean
    | undefined;
  /** timestamp when the undeleted s3 objects been soft deleted in CleanupUndeletedS3ObjectsPeriodicTask */
  last_revisited_ts?: string | undefined;
}

export interface OnlineDataTable {
  name?: string | undefined;
  feature_data_watermark?: string | undefined;
  state_transitions?: OnlineDataTableStateTransition[] | undefined;
  batch_table_format_version?: TableFormatVersion | undefined;
}

export interface OnlineDataTableStateTransition {
  state?: OnlineDataTableState | undefined;
  timestamp?: string | undefined;
}
