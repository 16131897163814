import React from 'react';

// Tecton
import { useGetFcos } from '../../../api/fcos';
import FeatureViewsList from './FeatureViewsList';
import { WorkspaceFCOContainer } from '../../../core/types/fcoTypes';
import { useUserSettings } from '../../context/UserSettingsContext';

// @tecton
import {
  ViewLayout,
  HeaderLayout,
  Breadcrumbs,
  Subtitle,
  EmptyPrompt,
  FlexGroup,
  FlexItem,
} from '@tecton/ComponentRedesign';
import { TECTON_DOCUMENTATION_LINKS } from '../application-links';
import WorkspaceRootBreadcrumb from '../WorkspaceRootBreadcrumb';

export const FeatureViewsContainer = () => {
  const { workspace, workspaceDetails } = useUserSettings();

  const { data, isError, isLoading } = useGetFcos<WorkspaceFCOContainer>(workspace as string, {
    select: (data: WorkspaceFCOContainer) => {
      return {
        featureViews: data.featureViews ?? [],
        idToFcoMap: data?.idToFcoMap ?? {},
        allTransformations: Array.from(
          new Set(data.featureViews.map((featureView) => featureView.allTransformations).flat())
        ),
      };
    },
  });

  const subtitle = (
    <Subtitle
      description="Feature Views define how transformations are run on data source inputs to create feature values."
      learnMoreUrl={TECTON_DOCUMENTATION_LINKS.FEATURE_VIEWS}
    />
  );

  const header = (
    <HeaderLayout
      breadcrumbs={
        <Breadcrumbs
          workspace={<WorkspaceRootBreadcrumb workspace={workspaceDetails} />}
          crumbs={[{ label: 'Features' }]}
        />
      }
      subtitle={subtitle}
    />
  );
  if (isLoading) {
    return (
      <ViewLayout
        header={header}
        body={
          <FlexGroup direction="column" alignItems="center">
            <FlexItem>
              <EmptyPrompt
                title={<>Loading Feature Views</>}
                body={<>Feature Views are loading.</>}
                variant="loading"
                orientation="vertical"
              />
            </FlexItem>
          </FlexGroup>
        }
      />
    );
  }

  if (isError) {
    return (
      <ViewLayout
        header={header}
        body={
          <EmptyPrompt
            title={<>Error Loading Feature Views</>}
            body={<>There was an error loading Feature Views for this workspace. Please try again.</>}
            variant="storm"
            orientation="vertical"
          />
        }
      />
    );
  }

  return (
    <ViewLayout
      header={header}
      body={
        <FeatureViewsList
          workspace={workspace ?? ''}
          items={data?.featureViews ?? []}
          idToFcoMap={data?.idToFcoMap ?? []}
        />
      }
    />
  );
};

export default FeatureViewsContainer;
