import {
  checkIfComboFilterChecklistHasActiveCategories,
  ComboFilterChecklist,
  ComboFilterSelector,
  ComboSelectorChecklistOption,
  countActiveSelectionsForComboFilterChecklist,
  EmptyValue,
  FCOIconAndName,
} from '@tecton/ComponentRedesign';
import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { AnyFCO, DatasetFCO, DatasetFCOFields, DatasetFCOType, FCOFields, FCOType } from '../../../core/types/fcoTypes';
import { useUserSettings } from '../../context/UserSettingsContext';
import { FCOListView, NameColumn } from '../fcoListView';

interface DataSetsListsProps {
  items: DatasetFCO[];
  idToFcoMap: Record<string, AnyFCO>;
}

export interface DatasetFilterStateType {
  Type: ComboSelectorChecklistOption[];
  'Feature Views': ComboSelectorChecklistOption[];
  'Feature Services': ComboSelectorChecklistOption[];
}

const DataSetsList: FC<DataSetsListsProps> = ({ items, idToFcoMap }) => {
  const { workspace } = useUserSettings();
  type DatasetFilterKeys = 'Type' | 'Feature Views' | 'Feature Services';

  const allFeatureServices = items
    .filter((item) => item[DatasetFCOFields.FEATURE_SERVICE_ID] !== undefined)
    .map((item) => {
      return {
        name: item[DatasetFCOFields.FEATURE_SERVICE_NAME] ?? '',
        id: item[DatasetFCOFields.FEATURE_SERVICE_ID],
      };
    });

  const allFeatureViews = items
    .filter((item) => item[DatasetFCOFields.FEATURE_VIEW_ID] !== undefined)
    .map((item) => {
      return {
        name: item[DatasetFCOFields.FEATURE_VIEW_NAME] ?? '',
        id: item[DatasetFCOFields.FEATURE_VIEW_ID],
      };
    });

  const defaultFilterState: DatasetFilterStateType = {
    Type: [
      {
        label: 'Saved Dataset',
        value: DatasetFCOType.SAVED,
      },
      {
        label: 'Logged Requests',
        value: DatasetFCOType.LOGGED,
      },
    ],
    'Feature Views': allFeatureViews.map((view) => {
      return { label: view.name, value: view.id };
    }),
    'Feature Services': allFeatureServices.map((view) => {
      return { label: view.name, value: view.id };
    }),
  };

  const [filterState, setFilterState] = useState(defaultFilterState);

  const columns = [
    ...NameColumn,
    {
      name: 'Type',
      key: 'Type',
      field: DatasetFCOFields.TYPE,
      sortable: true,
      render: (_: unknown, dataset: DatasetFCO) => {
        return dataset[DatasetFCOFields.TYPE];
      },
    },
    {
      name: 'Feature Views',
      key: 'Feature Views',
      field: DatasetFCOFields.FEATURE_VIEW_ID,
      sortable: true,
      render: (_: unknown, dataset: DatasetFCO) => {
        const featureViewId = dataset[DatasetFCOFields.FEATURE_VIEW_ID];

        if (!dataset[DatasetFCOFields.FEATURE_VIEW_ID]) {
          return <EmptyValue />;
        }

        const isModified = !Object.keys(idToFcoMap).includes(dataset[DatasetFCOFields.FEATURE_VIEW_ID]);
        const fco = idToFcoMap[featureViewId ?? ''];

        return (
          <>
            {isModified ? (
              <FCOIconAndName
                name={dataset[DatasetFCOFields.FEATURE_VIEW_NAME] ?? ''}
                type={FCOType.FEATURE_VIEW}
                isModified={isModified}
              />
            ) : (
              <Link to={`/repo/${workspace}/features/${encodeURIComponent(fco[FCOFields.NAME] ?? '')}`}>
                <FCOIconAndName name={fco[FCOFields.NAME] ?? ''} type={FCOType.FEATURE_VIEW} isModified={isModified} />
              </Link>
            )}
          </>
        );
      },
    },
    {
      name: 'Feature Services',
      key: 'Feature Views',
      field: DatasetFCOFields.FEATURE_SERVICE_ID,
      sortable: true,
      render: (_: unknown, dataset: DatasetFCO) => {
        if (!dataset[DatasetFCOFields.FEATURE_SERVICE_ID]) {
          return <EmptyValue />;
        }

        const isModified = !Object.keys(idToFcoMap).includes(dataset[DatasetFCOFields.FEATURE_SERVICE_ID]);
        // We need to escape the workspace name since we can have unfriendly names for FCOS for example "samantha:parallel_dataset:V1:3"
        const urlEncodedFcoName = encodeURIComponent(dataset[DatasetFCOFields.FEATURE_SERVICE_NAME] ?? '');

        return isModified ? (
          <FCOIconAndName
            name={dataset[DatasetFCOFields.FEATURE_SERVICE_NAME] ?? ''}
            type={FCOType.FEATURE_SERVICE}
            isModified={isModified}
          />
        ) : (
          <Link to={`/repo/${workspace}/feature-services/${urlEncodedFcoName}`}>
            <FCOIconAndName
              name={idToFcoMap[dataset[DatasetFCOFields.FEATURE_SERVICE_ID]][FCOFields.NAME] ?? ''}
              type={FCOType.FEATURE_SERVICE}
              isModified={isModified}
              description={idToFcoMap[dataset[DatasetFCOFields.FEATURE_SERVICE_ID]][FCOFields.DESCRIPTION] ?? ''}
            />
          </Link>
        );
      },
    },
  ];

  const filterTypes: Record<DatasetFilterKeys, ComboFilterSelector> = {
    Type: {
      hidden: undefined,
      label: 'Type',
      numFilters: undefined,
      hasActiveFilters: checkIfComboFilterChecklistHasActiveCategories(filterState['Type']),
      numActiveFilters: countActiveSelectionsForComboFilterChecklist(filterState['Type']),
      filterElement: (
        <ComboFilterChecklist
          items={filterState['Type']}
          setItems={(items) => setFilterState({ ...filterState, ['Type']: items })}
        />
      ),
    },
    'Feature Views': {
      hidden: undefined,
      label: 'Feature Views',
      numFilters: undefined,
      hasActiveFilters: checkIfComboFilterChecklistHasActiveCategories(filterState['Feature Views']),
      numActiveFilters: countActiveSelectionsForComboFilterChecklist(filterState['Feature Views']),
      filterElement: (
        <ComboFilterChecklist
          items={filterState['Feature Views']}
          setItems={(items) => {
            setFilterState({ ...filterState, ['Feature Views']: items });
          }}
          renderOption={(item) => {
            if (idToFcoMap[item.id ?? '']) {
              return (
                <div style={{ pointerEvents: 'none' }}>
                  <FCOIconAndName name={item.label} type={FCOType.FEATURE_VIEW} />
                </div>
              );
            }

            return (
              <div style={{ pointerEvents: 'none' }}>
                <FCOIconAndName name={item.label} type={FCOType.FEATURE_VIEW} isModified />
              </div>
            );
          }}
        />
      ),
    },
    'Feature Services': {
      hidden: undefined,
      label: 'Feature Services',
      numFilters: undefined,
      hasActiveFilters: checkIfComboFilterChecklistHasActiveCategories(filterState['Feature Services']),
      numActiveFilters: countActiveSelectionsForComboFilterChecklist(filterState['Feature Services']),
      filterElement: (
        <ComboFilterChecklist
          items={filterState['Feature Services']}
          setItems={(items) => {
            setFilterState({ ...filterState, ['Feature Services']: items });
          }}
          renderOption={(item) => {
            if (idToFcoMap[item.id ?? '']) {
              return (
                <div style={{ pointerEvents: 'none' }}>
                  <FCOIconAndName name={item.label} type={FCOType.FEATURE_SERVICE} />
                </div>
              );
            }

            return (
              <div style={{ pointerEvents: 'none' }}>
                <FCOIconAndName name={item.label} type={FCOType.FEATURE_SERVICE} isModified />
              </div>
            );
          }}
        />
      ),
    },
  };

  const filterFunctionsByFilterKey: Record<DatasetFilterKeys, (fco: DatasetFCO) => boolean> = {
    Type: function (fco: DatasetFCO): boolean {
      const activeTypes = filterState['Type'].filter((option) => option.checked === 'on').map((item) => item.value);

      return activeTypes.includes(fco[DatasetFCOFields.TYPE]);
    },
    'Feature Views': function (fco: DatasetFCO): boolean {
      const activeTypes = filterState['Feature Views']
        .filter((option) => option.checked === 'on')
        .map((item) => item.value);
      return activeTypes.includes(fco[DatasetFCOFields.FEATURE_VIEW_ID]);
    },
    'Feature Services': function (fco: DatasetFCO): boolean {
      const activeTypes = filterState['Feature Services']
        .filter((option) => option.checked === 'on')
        .map((item) => item.value);
      return activeTypes.includes(fco[DatasetFCOFields.FEATURE_SERVICE_ID]);
    },
  };

  return (
    <FCOListView
      unfilteredFCOItems={items}
      filters={filterTypes}
      columns={columns}
      filterState={filterState}
      setFilterState={setFilterState}
      defaultFilterState={defaultFilterState}
      filterFunctionsByFilterKey={filterFunctionsByFilterKey}
      searchPlaceholder={'Search Datasets'}
    />
  );
};

export default DataSetsList;
