import React, { FC, createContext, useContext, useState } from 'react';
import { ValidationsContext } from './ValidationsContext';
import { ExpectationResultEnum } from '../../../types/tecton_proto/dataobs/validation';
import { ComboFilterChecklist, ComboFilterSelector, FCOIconAndName } from '@tecton/ComponentRedesign';
import { FCOType } from '../../../core/types/fcoTypes';

const StatusNameMap: Record<ExpectationResultEnum, string> = {
  [ExpectationResultEnum.RESULT_ERROR]: 'Error',
  [ExpectationResultEnum.RESULT_FAILED]: 'Failed',
  [ExpectationResultEnum.RESULT_PASSED]: 'Passed',
  [ExpectationResultEnum.RESULT_UNKNOWN]: 'Unknown',
};

export const useGetValidationsFilterTypes = () => {
  const validationsContext = useContext(ValidationsContext);

  const filterTypes: Record<string, ComboFilterSelector> = {
    statuses: {
      numFilters: 0,
      hasActiveFilters: validationsContext.filterState.statuses.length > 0,
      numActiveFilters: validationsContext.filterState.statuses.length,
      label: 'Status',
      filterElement: (
        <ComboFilterChecklist
          items={[
            ExpectationResultEnum.RESULT_PASSED,
            ExpectationResultEnum.RESULT_ERROR,
            ExpectationResultEnum.RESULT_FAILED,
          ].map((item) => {
            if (validationsContext.filterState.statuses.includes(item)) {
              return {
                label: StatusNameMap[item],
                value: item,
                checked: 'on',
              };
            }

            return {
              label: StatusNameMap[item],
              value: item,
            };
          })}
          name="status"
          key="selector_statuses"
          setItems={(items) => {
            const newStatuses = items
              .filter((item) => {
                return item.checked === 'on';
              })
              .map((filteredItem: unknown) => {
                return (filteredItem as { value: ExpectationResultEnum }).value;
              });

            validationsContext.updateFilters({
              ...validationsContext.filterState,
              statuses: newStatuses,
            });
          }}
        />
      ),
    },
    featureViews: {
      numFilters: 0,
      label: 'Feature Views',
      hasActiveFilters: validationsContext.filterState.featureViews.length > 0,
      numActiveFilters: validationsContext.filterState.featureViews.length,
      filterElement: (
        <ComboFilterChecklist
          items={
            validationsContext.allFeatureViews
              ? validationsContext.allFeatureViews.map((featureView) => {
                  return {
                    label: featureView.label,
                    value: featureView.label,
                    checked: validationsContext.filterState.featureViews.includes(featureView.label) ? 'on' : undefined,
                  };
                })
              : []
          }
          name="status"
          key="selector_statuses"
          renderOption={(item) => {
            return <FCOIconAndName type={FCOType.FEATURE_VIEW} name={item.label} />;
          }}
          setItems={(items) => {
            const newFeatureViews = items
              .filter((item) => {
                return item.checked === 'on';
              })
              .map((filteredItem: unknown) => {
                return (filteredItem as { value: string }).value;
              });

            validationsContext.updateFilters({
              ...validationsContext.filterState,
              featureViews: newFeatureViews,
            });
          }}
        />
      ),
    },
  };

  return { filterTypes };
};

interface FilterContextType {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedFilter: string | undefined;
  setSelectedFilter: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export const ValidationsFilterContext = createContext<FilterContextType>({
  isOpen: false,
  setIsOpen: () => undefined,
  selectedFilter: undefined,
  setSelectedFilter: () => undefined,
});

const ValidationsFilterProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedFilter, setSelectedFilter] = useState<string | undefined>(undefined);

  return (
    <ValidationsFilterContext.Provider
      value={{
        isOpen: isOpen,
        setIsOpen: setIsOpen,
        selectedFilter: selectedFilter,
        setSelectedFilter: setSelectedFilter,
      }}
    >
      {children}
    </ValidationsFilterContext.Provider>
  );
};

export default ValidationsFilterProvider;
