import { Code, EmptyPrompt } from '@tecton/ComponentRedesign';
import { useParams } from 'react-router';
import { useGetJobLogs } from '../../../api/jobs';

const JobLogs = () => {
  const { jobId } = useParams();
  const { data: jobLogsData, isLoading } = useGetJobLogs(jobId ?? '', { enabled: true });

  if (isLoading) {
    return <EmptyPrompt variant="loading" title={'Job Log Loading'} body={<></>} />;
  }

  return (
    <>
      {!jobLogsData?.logs?.length ? (
        <div>
          <EmptyPrompt title={<>No Job Logs Available</>} body={<>Please try again later.</>} />
        </div>
      ) : (
        <>
          <Code code={jobLogsData?.logs ?? ''} />
        </>
      )}
    </>
  );
};

export default JobLogs;
