import React, { FC } from 'react';
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer';
import styled from '@emotion/styled';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { useTectonTheme } from '../../Theme/ThemeProvider';
import { CodeLanguage, customSyntaxTheme } from './CodeFormatting';
import { CopyButton, FlexGroup } from '.';

interface DiffViewerProps {
  codeBefore: string;
  leftTitle: React.ReactNode;
  codeAfter: string;
  rightTitle: React.ReactNode;
  language?: CodeLanguage;
}

const Wrapper = styled.div`
  width: 100%;
  overflow: auto;

  min-width: ${({ theme }) => theme.v1.size['180']}px;
  code {
    width: 50%;
  }

  // Remove borders and styles from general rows
  tr {
    background: none;
    border: 1px solid ${({ theme }) => theme.v1.colors.background.transparent};
  }

  // Remove borders from Diff viewer
  td {
    border: 1px solid ${({ theme }) => theme.v1.colors.background.transparent};
    padding: 0;
  }

  // Hide + or - markers, per design
  [class*='marker'] {
    display: none;
  }

  // Override borders in title row
  tbody {
    tr:first-child {
      border-collapse: collapse;

      td {
        border: 1px solid ${({ theme }) => theme.v1.colors.background.transparent};
      }
    }
  }

  [class$='line'] {
    background-color: ${({ theme }) => theme.v1.colors.background.default} !important;
  }

  // Line Numbers
  [class*='line-number'] {
    text-align: center;
    margin: auto;
    width: 2em;
    opacity: 1;
    cursor: default;
  }

  [class*='-gutter'] {
    width: 3em;
    text-align: right;
    min-width: 0;
    width: 0;
    padding: 0;
    cursor: default;
  }

  // Individual code lines
  td[class*='-content'] {
    padding: ${({ theme }) => theme.v1.size['0']}px ${({ theme }) => theme.v1.size['1']}px;
    width: calc(50% - 3em);
    code {
      span {
        white-space: pre-wrap !important; /* Ensure wrapping */
      }
    }
  }

  // The 'Expand n Lines' button (if used)
  [class*='code-fold'] {
    height: auto;
    * {
      white-space: nowrap;
      overflow: visible;
      display: block;
      width: 0px;
      padding: ${({ theme }) => theme.v1.size['0.5']}px ${({ theme }) => theme.v1.size['1']}px;
      height: auto;
      color: ${({ theme }) => theme.v1.colors.text.link};
    }
  }

  // Styling for new code

  // Override default borders for added / removed words
  [class*='word-diff'] {
    border: none;
    padding: 0;
  }

  [class*='diff-added'] {
    pre {
      color: ${({ theme }) => theme.v1.colors.text.successText};
      font-weight: bold;
    }

    [class*='line-number'] {
      opacity: 1;
      background-color: ${({ theme }) => theme.v1.colors.background.success};
      padding: 2px;
    }
  }

  [class*='word-added'] {
    * {
      color: ${({ theme }) => theme.v1.colors.text.successText};
      font-weight: ${({ theme }) => theme.font.weight.bold};
      background-color: ${({ theme }) => theme.v1.colors.background.success};
      border: none;
    }
  }

  // Styling for changed code
  [class*='diff-removed'] {
    pre {
      color: ${({ theme }) => theme.v1.colors.text.dangerText};
      font-weight: bold;
    }

    [class*='line-number'] {
      opacity: 1;
      background-color: ${({ theme }) => theme.v1.colors.background.dangerSecondary};
    }
  }

  [class*='word-removed'] {
    * {
      font-weight: ${({ theme }) => theme.font.weight.bold};
      color: ${({ theme }) => theme.v1.colors.text.dangerText};
      background-color: ${({ theme }) => theme.v1.colors.background.dangerSecondary};
    }
  }
`;

const TitleWithCopy: FC<{ children: React.ReactNode; code: string }> = ({ children, code }) => {
  return (
    <div style={{ marginRight: '2em' }}>
      <FlexGroup>
        {children}
        <CopyButton code={code} />
      </FlexGroup>
    </div>
  );
};

const DiffViewer: FC<DiffViewerProps> = ({ codeBefore, codeAfter, leftTitle, rightTitle, language = 'python' }) => {
  const { theme } = useTectonTheme();

  const customTheme = customSyntaxTheme(theme);

  const renderPrismContent = (content: string) => {
    return (
      <SyntaxHighlighter language={language} style={customTheme} showLineNumbers={false} wrapLines>
        {content}
      </SyntaxHighlighter>
    );
  };

  return (
    <Wrapper>
      <ReactDiffViewer
        oldValue={codeBefore}
        newValue={codeAfter}
        splitView={true}
        leftTitle={<TitleWithCopy code={codeBefore}>{leftTitle}</TitleWithCopy>}
        rightTitle={<TitleWithCopy code={codeAfter}>{rightTitle}</TitleWithCopy>}
        compareMethod={'diffWords' as DiffMethod}
        renderContent={renderPrismContent}
      />
    </Wrapper>
  );
};

export default DiffViewer;
