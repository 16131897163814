import edgeIdFn from './edgeIdFn';
import { getVisibleAnimationsOnAllEdgesGivenAnchors, VisibleAnimationRecord } from './highlight-utils';
import highlightDownstream from './highlightDownstream';
import { EdgeShouldBeSkippedFunctionSignature, HighlightFunctionType } from './highlightFunctionType';

const highlightRequestDataSource: HighlightFunctionType = (id, nodesMap, edgesList) => {
  const requestDataSourceNode = nodesMap[id];

  if (
    !requestDataSourceNode.upstreamNodes ||
    requestDataSourceNode.upstreamNodes.length !== 1 ||
    !requestDataSourceNode.upstreamNodes[0]
  ) {
    throw new Error(`Expecting Request Data Source '${id}' to have a Feature Service as its only upstreamNode.`);
  }

  const parentFeatureServiceId = requestDataSourceNode.upstreamNodes[0];
  const parentFeatureService = nodesMap[parentFeatureServiceId];

  const skipOtherFeatureServices: EdgeShouldBeSkippedFunctionSignature = (sourceNode, targetNode) => {
    if (targetNode.type === 'feature_service' && targetNode.id !== parentFeatureService.id) {
      return true;
    }

    return false;
  };

  const { linkedIds, linkedEdges } = highlightDownstream(id, nodesMap, edgesList, {
    edgeShouldBeSkipped: skipOtherFeatureServices,
  });

  /**
   * Special Case around RDS being embedded in the FS
   */

  const intoRDS = edgeIdFn({ source: parentFeatureServiceId, target: id });
  const outOfRDS = edgeIdFn({ source: id, target: parentFeatureServiceId });

  linkedIds.delete(parentFeatureServiceId);
  linkedEdges.add(intoRDS);
  linkedEdges.add(outOfRDS);

  /**
   * Compute Animations
   */
  const anchors = new Set<string>([parentFeatureServiceId]); // Animations for all the upstream FVs
  anchors.add(id); // Animations on the read side

  const animations = getVisibleAnimationsOnAllEdgesGivenAnchors(anchors, edgesList, linkedEdges);

  const cosmeticLineAnimations: VisibleAnimationRecord = {
    showOfflineReadPath: true,
  };

  if (parentFeatureService.type === 'feature_service' && parentFeatureService.isOnlineServingEnabled) {
    cosmeticLineAnimations.showOnlineServingPath = true;
  }

  animations[intoRDS] = cosmeticLineAnimations;
  animations[outOfRDS] = cosmeticLineAnimations;

  return {
    linkedIds,
    linkedEdges,
    animations,
  };
};

export default highlightRequestDataSource;
