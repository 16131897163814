import React, { useState } from 'react';
import { Box } from '@mui/system';
import { Stack } from '@mui/material';
import DateRangeSelectorMonitoring from '../../../../components/monitoring/DateRangeSelectorMonitoring';
import {
  getDefaultMonitoringDateRange,
  MonitoringDateRange,
} from '../../../../components/monitoring/DateRange-Dropdown-util';
import { logEvent } from '../../../../utils/analytics-utils';
import MonitoringGraphs from '../../../feature-views/MonitoringGraphs';
import { useParams } from 'react-router';
import { FEATURE_SERVICE_GLOBAL_MONITORING } from '../../../graph-configuration';

export const FeatureServiceMonitoring = () => {
  const { workspace } = useParams();
  const defaultMonitoringDateRange: MonitoringDateRange = getDefaultMonitoringDateRange();

  const [monitoringDateRange, setMonitoringDateRange] = useState<MonitoringDateRange>(defaultMonitoringDateRange);

  const onChange = (monitoringDateRange: MonitoringDateRange) => {
    setMonitoringDateRange(monitoringDateRange);
  };

  logEvent('Graphs: Online Store Monitoring');

  return (
    <Stack spacing={2}>
      <Box display={'flex'}>
        <Box flex={1}>
          <DateRangeSelectorMonitoring onChange={onChange} />
        </Box>
      </Box>

      <>
        <MonitoringGraphs
          monitoringDateRange={monitoringDateRange}
          graphsData={FEATURE_SERVICE_GLOBAL_MONITORING}
          customGraphRecord={{}}
          workspace={workspace ?? ''}
        />
      </>
    </Stack>
  );
};

export default FeatureServiceMonitoring;
