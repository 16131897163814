import _sortBy from 'lodash/sortBy';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// generated
import { Group } from '../../../api/gql/graphql';

// @tecton
import {
  ButtonEmpty,
  ButtonIcon,
  EmptyPrompt,
  ErrorPromptButtons,
  EuiBasicTableColumn,
  FlexGroupWrapper,
  FlexItem,
  SearchAndActionButton,
  Table,
  Tooltip,
} from '@tecton/ComponentRedesign';

// types
import AccountTypeIconName from './AccountTypeIconName';
import { TableFieldWrapper } from './StyledComponents';

// svg
import GroupIcon from '@svg/group.svg';
import { ReactComponent as Trash } from '@svg/trash.svg';

export interface ACLGroupListProps {
  groups: Group[];
  isAdminView: boolean;
  isError?: boolean;
  isLoading?: boolean;
  onCreateGroupClick: VoidFunction;
  onAccountTypeRowClicked: (group: Group) => void;
  onDeleteGroupRowClicked: (group: Group) => void;
}

const ACLGroupList: FC<ACLGroupListProps> = ({
  groups,
  isAdminView,
  isError,
  isLoading,
  onCreateGroupClick,
  onAccountTypeRowClicked,
  onDeleteGroupRowClicked,
}) => {
  const [searchText, setSearchText] = useState<string | undefined>(undefined);
  const navigate = useNavigate();
  const filteredGroups = searchText
    ? groups?.filter((group: Group) => {
        const { name, id } = group;
        return [name, id].join('-')?.toLowerCase()?.includes(searchText?.toLowerCase());
      })
    : groups;

  const sortedGroups = isLoading ? undefined : _sortBy(filteredGroups, ['name']);

  const columns: EuiBasicTableColumn<Group>[] = [
    {
      name: 'Name',
      render: (group: Group) => {
        const isEnabled = group.isMember ?? isAdminView;

        return isEnabled ? (
          <TableFieldWrapper>
            <ButtonEmpty
              iconType={GroupIcon}
              onClick={() => {
                navigate(`./${group.id}`);
              }}
            >
              {group.name}
            </ButtonEmpty>
          </TableFieldWrapper>
        ) : (
          group.name
        );
      },
    },
    {
      name: 'Members',
      render: (group: Group) => {
        return group.numMembers;
      },
    },
    {
      name: 'Workspaces',
      render: (group: Group) => {
        return group.workspaceCount;
      },
    },
    ...(isAdminView
      ? [
          {
            name: 'Account Type',
            render: (group: Group) => {
              return (
                <>
                  <AccountTypeIconName
                    accountType={group.accountType}
                    onAccountTypeRowClicked={() => {
                      onAccountTypeRowClicked(group);
                    }}
                  />
                </>
              );
            },
          },
        ]
      : []),
    {
      name: 'Actions',
      actions: [
        {
          render: (group: Group) => {
            const isEnabled = group.isMember ?? isAdminView;
            return (
              <>
                {/* Delete */}
                {isEnabled ? (
                  <ButtonIcon
                    variant={!isEnabled ? 'disabledAction' : 'dangerousAction'}
                    iconType={Trash}
                    isDisabled={!isEnabled}
                    size="xs"
                    onClick={() => {
                      onDeleteGroupRowClicked(group);
                    }}
                  />
                ) : (
                  <Tooltip
                    trigger={
                      <ButtonIcon
                        variant={!isEnabled ? 'disabledAction' : 'dangerousAction'}
                        iconType={Trash}
                        isDisabled={!isEnabled}
                        size="xs"
                        onClick={() => {
                          onDeleteGroupRowClicked(group);
                        }}
                      />
                    }
                    content="Administrator Privileges Required."
                  />
                )}
              </>
            );
          },
        },
      ],
    },
  ];

  if (isError) {
    const emptyPrompt = (
      <EmptyPrompt
        orientation="horizontal"
        variant="storm"
        title={<>Error Loading Groups</>}
        body={
          <>
            <p>We ran into an error while loading Groups.</p>
          </>
        }
        actions={<ErrorPromptButtons />}
      />
    );
    return <Table items={[]} columns={columns} emptyPrompt={emptyPrompt} />;
  }

  return (
    <FlexGroupWrapper gap="s" direction="column">
      <FlexItem>
        {' '}
        <SearchAndActionButton
          searchPlaceHolder={'Search for Group'}
          actionButtonLabel={'Create Group'}
          actionButtonVariant={isAdminView ? 'primaryAction' : 'disabledAction'}
          onActionButtonClick={onCreateGroupClick}
          onSearchInputChange={function (searchString: string): void {
            setSearchText(searchString);
          }}
        />
      </FlexItem>
      <FlexItem>
        {isLoading ? (
          <Table
            items={[]}
            columns={columns}
            emptyPrompt={<EmptyPrompt title={<>Loading Groups</>} body={<></>} variant="loading" />}
          />
        ) : (
          <Table items={sortedGroups ?? []} columns={columns} allowWordBreak={false} />
        )}
      </FlexItem>
    </FlexGroupWrapper>
  );
};

export default ACLGroupList;
