import React, { FC } from 'react';
import { FeatureViewFCO, FeatureViewFCOFields, FeatureViewFCOType } from '../../../core/types/fcoTypes';
import { ConfigCardItemType } from '../../featureViews/FeatureViewConfigurationCard';
import { MaterializationStorageCard } from './FeatureViewConfigurationCard';

interface MaterializationStorageProps {
  fco: FeatureViewFCO;
}

const BatchFeatureViewStorageCard: FC<MaterializationStorageProps> = ({ fco }) => {
  const onlineItems = [
    ConfigCardItemType.ONLINE_MATERIALIZATION,
    ConfigCardItemType.SERVING_TTL,
    ConfigCardItemType.ONLINE_STORE_TYPE,
    ConfigCardItemType.PRIMARY_ENDPOINT,
    ConfigCardItemType.CACHE_MAX_AGE,
    ConfigCardItemType.FRESHNESS_MONITORING_ENABLED,
    ConfigCardItemType.EXPECTED_FRESHNESS,
  ];

  const offlineItems = [
    ConfigCardItemType.OFFLINE_MATERIALIZATION,
    ConfigCardItemType.OFFLINE_STORE_TYPE,
    ConfigCardItemType.PUBLISH_FULL_FEATURES,
    ConfigCardItemType.PUBLISH_START_TIME,
  ];

  return <MaterializationStorageCard fco={fco} onlineItems={onlineItems} offlineItems={offlineItems} />;
};

const StreamFeatureViewStorageCard: FC<MaterializationStorageProps> = ({ fco }) => {
  const onlineItems = [
    ConfigCardItemType.ONLINE_MATERIALIZATION,
    ConfigCardItemType.SERVING_TTL,
    ConfigCardItemType.ONLINE_STORE_TYPE,
    ConfigCardItemType.PRIMARY_ENDPOINT,
    ConfigCardItemType.CACHE_MAX_AGE,
    ConfigCardItemType.FRESHNESS_MONITORING_ENABLED,
    ConfigCardItemType.EXPECTED_FRESHNESS,
  ];

  const offlineItems = [
    ConfigCardItemType.OFFLINE_MATERIALIZATION,
    ConfigCardItemType.OFFLINE_STORE_TYPE,
    ConfigCardItemType.PUBLISH_FULL_FEATURES,
  ];

  return <MaterializationStorageCard fco={fco} onlineItems={onlineItems} offlineItems={offlineItems} />;
};

const FeatureTableStorageCard: FC<MaterializationStorageProps> = ({ fco }) => {
  const onlineItems = [
    ConfigCardItemType.ONLINE_MATERIALIZATION,
    ConfigCardItemType.SERVING_TTL,
    ConfigCardItemType.PRIMARY_ENDPOINT,
    ConfigCardItemType.ONLINE_STORE_TYPE,
    ConfigCardItemType.CACHE_MAX_AGE,
  ];

  const offlineItems = [ConfigCardItemType.OFFLINE_MATERIALIZATION, ConfigCardItemType.OFFLINE_STORE_TYPE];

  return <MaterializationStorageCard fco={fco} onlineItems={onlineItems} offlineItems={offlineItems} />;
};

const FeatureViewMaterializationStorageCard: FC<MaterializationStorageProps> = ({ fco }) => {
  switch (fco[FeatureViewFCOFields.FEATURE_VIEW_TYPE]) {
    case FeatureViewFCOType.BATCH:
      return <BatchFeatureViewStorageCard fco={fco} />;
    case FeatureViewFCOType.STREAM:
      return <StreamFeatureViewStorageCard fco={fco} />;
    case FeatureViewFCOType.FEATURE_TABLE:
      return <FeatureTableStorageCard fco={fco} />;
    default:
      return <></>;
  }
};

export default FeatureViewMaterializationStorageCard;
