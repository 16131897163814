export type MonitoringRoutes =
  | 'Feature View Monitoring'
  | 'SLO Monitoring'
  | 'Feature Server Monitoring'
  | 'Stream Ingest Monitoring';

export const getMonitoringRoutes = (): Record<MonitoringRoutes, string> => {
  return {
    'Feature View Monitoring': 'feature-view-monitoring',
    'SLO Monitoring': 'slo-monitoring',
    'Feature Server Monitoring': 'feature-service-monitoring',
    'Stream Ingest Monitoring': 'stream-ingest-monitoring',
  };
};

export type MonitoringFeatureServiceRoutes = 'Feature View' | 'Realtime Server' | 'Stream Ingest';

export const getMonitoringFeatureServiceGroupRoutes = (): Record<MonitoringFeatureServiceRoutes, string> => {
  return {
    'Feature View': 'feature-view-monitoring',
    'Realtime Server': 'realtime-server',
    'Stream Ingest': 'stream-ingest',
  };
};

export const getMonitoringRouteName = (
  pathname: string,
  isFeatureServiceGroupEnabled: boolean
): MonitoringRoutes | MonitoringFeatureServiceRoutes => {
  const pathSegments = pathname.split('/');
  const lastPathSegment = pathSegments[pathSegments.length - 1];
  const routes = isFeatureServiceGroupEnabled ? getMonitoringFeatureServiceGroupRoutes() : getMonitoringRoutes();
  const record = Object?.entries(routes)?.find((record) => {
    return record[1] === lastPathSegment;
  });

  return (record?.[0] ?? 'Feature View Monitoring') as MonitoringRoutes;
};
