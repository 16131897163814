import styled from '@emotion/styled';
import React, { FC } from 'react';

// The text element within an FCO icon that shows the name of the icon
// Equivalent to Tony's old <ObjectTitle />
// This can probably just be refactored as a standalone styled-component

interface DataFlowFCONodeNameProps {
  denseMode?: boolean;
  name: string;
}

const denseModeTextTruncationCss = `
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

const Name = styled.p<{ denseMode?: boolean }>`
  font-size: ${({ theme }) => theme.font.fontSizes.xs};
  ${({ denseMode }) => denseMode && denseModeTextTruncationCss};
  line-height: 1.1;
  width: 100%;
  word-break: keep-all;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const DataFlowFCONodeName: FC<DataFlowFCONodeNameProps> = ({ denseMode, name }) => {
  return <Name denseMode={denseMode}>{name}</Name>;
};

export default DataFlowFCONodeName;
