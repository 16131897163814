import React, { useEffect } from 'react';
import { LoadingIndicator } from '../../../components/LoadingIndicator';
import { logEvent } from '../../../utils/analytics-utils';
import { FirstClassObject, FirstClassObjectTypes } from '../../../components/FirstClassObject';
import Avatar from '../../../components/Avatar';
import CreatedAt from '../../../components/CreatedAt';
import { Routes } from '../../../core/routes';
import TableView, { wrapFirstClassObjectForSearch } from '../../../components/TableView';
import MaterializationEnabledDisabled from './MaterializationEnabledDisabled';
import { useFcoContainer } from '../../query';
import NoFcosMessage from '../../../components/NoFcos/NoFcosMessage';
import UrlUtils from '../../../utils/url-utils';
import { Link } from 'react-router-dom';
import { useUserSettings } from '../../../components/context/UserSettingsContext';

const FeaturesTableView = ({ features, title }) => {
  const { workspace } = useUserSettings();
  const fcoContainer = useFcoContainer(workspace);

  useEffect(() => {
    logEvent('View Features');
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'feature',
        Cell: ({ value }) => <FirstClassObject name={value.name} type={FirstClassObjectTypes.FEATURE} />,
      },
      {
        Header: 'Feature View or Table',
        accessor: 'view',
        Cell: ({ value }) => (
          <FirstClassObject
            name={value.name}
            type={FirstClassObjectTypes.FEATURE_VIEW}
            path={Routes.featureView}
            workspace={workspace}
          />
        ),
      },
      {
        Header: 'Entities',
        accessor: 'entities',
        Cell: ({ value }) => {
          const entities = value?.map((name, index) => {
            const path = UrlUtils.buildPath(Routes.entity, {
              name: name,
              workspace: workspace,
              tab: '',
            });

            return (
              <>
                {index > 0 && ', '}
                <Link to={path}>{name}</Link>
              </>
            );
          });
          return <div className="owa"> {entities} </div>;
        },
      },
      {
        Header: 'Type',
        accessor: 'type',
      },
      {
        Header: 'Materialization',
        accessor: 'materialization',
        Cell: ({ value }) => (
          <MaterializationEnabledDisabled
            isMaterializable={value.isMaterializable}
            isOnlineEnabled={value.online}
            isOfflineEnabled={value.offline}
          />
        ),
      },
      {
        Header: 'Created',
        accessor: 'created',
        Cell: ({ value }) => <CreatedAt date={value} />,
      },
      {
        Header: 'Owner',
        accessor: 'owner',
        Cell: ({ value }) => <Avatar username={value} />,
      },
    ],
    [workspace]
  );

  const data = React.useMemo(() => {
    const keys = Object.keys(features);
    return keys.map((key) => {
      const feature = features[key];
      return {
        feature: wrapFirstClassObjectForSearch(feature),
        view: wrapFirstClassObjectForSearch(feature.view),
        entities: feature?.entity_names,
        type: feature.type,
        materialization: {
          online: feature.view.isOnlineMaterializationEnabled,
          offline: feature.view.isOfflineMaterializationEnabled,
          isMaterializable: !feature.view.isOnline && !feature.view.isRealtime,
        },
        created: feature.view.createdAt,
        owner: feature.view.owner,
      };
    });
  }, [features]);

  if (features === null || fcoContainer.isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <>
      {data.length > 0 ? (
        <TableView data={data} columns={columns} title={title} searchPlaceHolder="Search Features..." />
      ) : (
        <NoFcosMessage
          fcoLabel="Features"
          documentationLink="https://docs.tecton.ai/docs/defining-features/feature-views"
        />
      )}
    </>
  );
};

export default FeaturesTableView;
