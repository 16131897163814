import { FC, useContext, useState } from 'react';

import { useUserSettings } from '../../context/UserSettingsContext';

import {
  useAssignRoleMutation,
  useCreateGroupMutation,
  useDeleteGroupMutation,
  useUpdateAccountTypeMutation,
} from '../../../api/acl';
import { useGetACLGroupsTable } from '../../../api/queries/aclGraphql';

import { getRoleDescriptionMap } from './acl-util';

import ACLGroupList from './ACLGroupList';
import CreateGroup from './CreateGroup';
import Delete from './Delete';
import ModifyGroupAccountType from './ModifyGroupAccountType';

// generated code
import { Group } from '../../../api/gql/graphql';
import { PrincipalType } from '../../../types/tecton_proto/auth/principal';
import { ResourceType } from '../../../types/tecton_proto/auth/resource';

// @tecton
import { Modal, getToastComponent } from '@tecton/ComponentRedesign';
import { ToastContext, addToast } from '@tecton/ToastContext';
import { logEvent } from '../../../utils/analytics-utils';

const ACLGroupListContainer: FC = () => {
  const { user, isAdmin } = useUserSettings();
  const toastContext = useContext(ToastContext);

  // CRUD hooks
  const { data, isLoading, isError } = useGetACLGroupsTable(user?.sub ?? '', isAdmin ?? false);
  const createGroupMutation = useCreateGroupMutation();
  const deleteGroupMutation = useDeleteGroupMutation();
  const assignRoleMutation = useAssignRoleMutation();
  const updateAccountTypeToRegularMutation = useUpdateAccountTypeMutation();

  // General States
  const [newGroupName, setNewGroupName] = useState<string>('');
  const [newGroupDescription, setNewGroupDescription] = useState<string>('');
  const [selectedGroup, setSelectedGroup] = useState<Group | undefined>(undefined);
  const [selectedAccountType, setSelectedAccountType] = useState<string | undefined>(undefined);

  // Validation States
  const [hasDuplicateGroupName, setHasDuplicateGroupName] = useState<boolean>(false);

  // Modal states
  const [showCreateServiceAccountModal, setShowCreateServiceAccountModal] = useState<boolean>(false);
  const [showAccountTypeModal, setShowAccountTypeModal] = useState<boolean>(false);
  const [showDeleteGroupModal, setShowDeleteGroupModal] = useState<boolean>(false);

  // Account Type descriptions
  const roleDescriptionMap = getRoleDescriptionMap();

  const reset = () => {
    setShowAccountTypeModal(false);
    setSelectedAccountType(undefined);
    setSelectedGroup(undefined);
    setShowDeleteGroupModal(false);
    setShowCreateServiceAccountModal(false);
    setHasDuplicateGroupName(false);
    setNewGroupName('');
    setNewGroupDescription('');
  };

  // --------------------------------------- Start: Actions hooks ---------------------------------
  const onCreateGroupChange = (groupName: string, groupDescription: string) => {
    setNewGroupName(groupName);
    setNewGroupDescription(groupDescription);
    const groupNames = data?.groups?.map((group) => group.name?.toLowerCase()) ?? [];
    setHasDuplicateGroupName(groupNames.includes(groupName.toLowerCase()));
  };

  const onCreateGroupConfirmClick = () => {
    createGroupMutation.mutate(
      { name: newGroupName, description: newGroupDescription },
      {
        onSuccess: () => {
          reset();
          logEvent('Group Created', '', {
            name: newGroupName,
            description: newGroupDescription,
          });
          const toastContent = getToastComponent({
            variant: 'success',
            title: `Group Created`,
            children: <>{newGroupName} group create.</>,
            showIcon: true,
          });

          toastContext?.dispatchToast(
            addToast({
              text: toastContent,
            })
          );
        },
        onError: () => {
          const toastContent = getToastComponent({
            variant: 'danger',
            title: `Failed to Create Group`,
            children: <>Failed to create {newGroupName} Group</>,
            showIcon: true,
          });

          toastContext?.dispatchToast(
            addToast({
              text: toastContent,
            })
          );
        },
      }
    );
  };

  const onSelectedAccountType = (selectedAccountType: string) => {
    setSelectedAccountType(selectedAccountType);
  };

  const onConfirmAccountTypeChange = () => {
    const principal_id = selectedGroup?.id ?? '';
    const payload = {
      assignments: [
        {
          principal_id,
          role: 'admin_role',
          principal_type: PrincipalType.PRINCIPAL_TYPE_GROUP,
          resource_type: ResourceType.RESOURCE_TYPE_ORGANIZATION,
        },
      ],
    };

    if (selectedGroup?.accountType === 'Regular') {
      // We set the account type to 'Admin'
      assignRoleMutation.mutate(payload, {
        onSuccess: () => {
          reset();
          logEvent('Account & Access: Edit Group Account Type', '', {
            group_name: selectedGroup.name,
            account_type: selectedAccountType,
          });
          const toastContent = getToastComponent({
            variant: 'success',
            title: `Account Type Updated`,
            children: (
              <>
                Group {selectedGroup?.name} Account type has been updated to {selectedAccountType}.
              </>
            ),
            showIcon: true,
          });

          toastContext?.dispatchToast(
            addToast({
              text: toastContent,
            })
          );
        },
        onError: () => {
          const toastContent = getToastComponent({
            variant: 'danger',
            title: `Account Type Updated Failed`,
            children: <>Account type updated failed for {selectedGroup?.name} Group. Please try again.</>,
            showIcon: true,
          });

          toastContext?.dispatchToast(
            addToast({
              text: toastContent,
            })
          );
        },
      });
    } else {
      // We set the account type to 'Regular'
      updateAccountTypeToRegularMutation.mutate(payload, {
        onSuccess: () => {
          reset();
          logEvent('Account & Access: Edit Group Account Type', '', {
            group_name: selectedGroup?.name,
            account_type: selectedAccountType,
          });
          const toastContent = getToastComponent({
            variant: 'success',
            title: `Account Type Updated`,
            children: (
              <>
                Group {selectedGroup?.name} Account type has been updated to {selectedAccountType}.
              </>
            ),
            showIcon: true,
          });

          toastContext?.dispatchToast(
            addToast({
              text: toastContent,
            })
          );
        },
        onError: () => {
          const toastContent = getToastComponent({
            variant: 'danger',
            title: `Account Type Updated Failed`,
            children: <>Failed to Update Account Type for {selectedGroup?.name} Group. Please try again.</>,
            showIcon: true,
          });

          toastContext?.dispatchToast(
            addToast({
              text: toastContent,
            })
          );
        },
      });
    }
  };

  const onDeleteGroupConfirmClicked = () => {
    const principal_id = selectedGroup?.id ?? '';

    deleteGroupMutation.mutate(
      { ids: [principal_id] },
      {
        onSuccess: () => {
          reset();
          logEvent('Group Deleted', '', {
            group_name: selectedGroup?.name,
            group_id: selectedGroup?.id,
          });
          const toastContent = getToastComponent({
            variant: 'success',
            title: `Group Deleted`,
            children: <>{selectedGroup?.name} Group deleted.</>,
            showIcon: true,
          });

          toastContext?.dispatchToast(
            addToast({
              text: toastContent,
            })
          );
        },
        onError: () => {
          const toastContent = getToastComponent({
            variant: 'danger',
            title: `Failed to Delete Group`,
            children: <>Failed to delete {selectedGroup?.name} Group. Please try again.</>,
            showIcon: true,
          });

          toastContext?.dispatchToast(
            addToast({
              text: toastContent,
            })
          );
        },
      }
    );
  };

  // ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ Start: Actions hooks ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^

  return (
    <>
      {showCreateServiceAccountModal && (
        <>
          <Modal
            title={`Create Group`}
            body={<CreateGroup onChange={onCreateGroupChange} hasDuplicateGroupName={hasDuplicateGroupName} />}
            onCancel={() => {
              reset();
            }}
            confirmLabel="Create Group"
            onConfirm={onCreateGroupConfirmClick}
            variant="positive"
            confirmVariant={newGroupName === '' || hasDuplicateGroupName ? 'disabledAction' : 'primaryAction'}
            isConfirmButtonLoading={createGroupMutation.isLoading}
          />
        </>
      )}

      {showAccountTypeModal && (
        <Modal
          title={`Modify Account Type of ${selectedGroup?.name}`}
          body={
            <ModifyGroupAccountType
              group={selectedGroup}
              selectedAccountType={selectedAccountType}
              roleDescriptionMap={roleDescriptionMap}
              onSelectionClicked={onSelectedAccountType}
            />
          }
          onCancel={() => {
            reset();
          }}
          confirmLabel="Change assigned Account Type to Admin"
          onConfirm={onConfirmAccountTypeChange}
          variant="positive"
          confirmVariant={selectedGroup?.accountType === selectedAccountType ? 'disabledAction' : 'primaryAction'}
          isConfirmButtonLoading={assignRoleMutation.isLoading || updateAccountTypeToRegularMutation?.isLoading}
          maxWidth={1000}
        />
      )}

      {showDeleteGroupModal && (
        <Modal
          title={`Delete ${selectedGroup?.name}`}
          body={<Delete message={`Are you sure you want to delete this group?`} />}
          onCancel={() => {
            reset();
          }}
          confirmLabel="Delete"
          onConfirm={onDeleteGroupConfirmClicked}
          variant="negative"
          isConfirmButtonLoading={deleteGroupMutation.isLoading}
        />
      )}

      <ACLGroupList
        groups={data?.groups ?? []}
        isAdminView={isAdmin ?? false}
        onCreateGroupClick={() => {
          setShowCreateServiceAccountModal(true);
        }}
        onAccountTypeRowClicked={(group) => {
          setSelectedGroup(group);
          setShowAccountTypeModal(true);
          setSelectedAccountType(group?.accountType);
        }}
        onDeleteGroupRowClicked={(group) => {
          setSelectedGroup(group);
          setShowDeleteGroupModal(true);
        }}
        isLoading={isLoading}
        isError={isError}
      />
    </>
  );
};

export default ACLGroupListContainer;
