import { FC } from 'react';
import { DataSourceFCO } from '../../../core/types/fcoTypes';
import { CardGridLayout, TwoCardRow } from '../../@tecton/ComponentRedesign/CardRowLayout';
import FCOMetadataCard from '../../@tecton/ComponentRedesign/FCOMetadataCard';
import FCOTagsCard from '../../@tecton/ComponentRedesign/FCOTagsCard';
import BatchConfigCard from './BatchConfigCard';
import SchemaCard from './SchemaCard';
import StreamConfigCard from './StreamConfigCard';

interface DataSourceOverviewProps {
  source: DataSourceFCO;
}

const DataSourceOverview: FC<DataSourceOverviewProps> = ({ source }) => {
  return (
    <CardGridLayout>
      <TwoCardRow>
        <FCOMetadataCard fco={source} />
        <FCOTagsCard fco={source} />
        <BatchConfigCard source={source} />
        <StreamConfigCard source={source} />
        <SchemaCard source={source} />
      </TwoCardRow>
    </CardGridLayout>
  );
};

export default DataSourceOverview;
