import React, { FC, useState } from 'react';

import CapabilityList from './CapabilityList';
import { RadioGroupOption } from './acl-types';
import { getAccountTypeOptions } from './acl-util';
import AccountTypeRadioOption from './AccountTypeRadioOption';
import { Permission, User } from '../../../api/gql/graphql';

// @tecton
import { FlexItem, Callout, FlexGroupWrapper } from '@tecton/ComponentRedesign';

interface ModifyAccountTypeProps {
  user?: User;
  selectedAccountType?: string;
  roleDescriptionMap: Record<string, Permission[]>;
  onSelectionClicked: (selectedAccountType: string /** undefined means the selection didn't change */) => void;
}

const ModifyUserAccountType: FC<ModifyAccountTypeProps> = ({
  user,
  selectedAccountType,
  roleDescriptionMap,
  onSelectionClicked,
}) => {
  const accountType = selectedAccountType ?? user?.accountType ?? 'Regular';
  const options = getAccountTypeOptions(accountType);
  const [accountTypeOptions, setAccountTypeOptions] = useState<RadioGroupOption[]>(options);
  const [roleDescription, setRoleDescription] = useState<Permission[]>(roleDescriptionMap[accountType]);

  return (
    <>
      <FlexGroupWrapper gap="xl" direction="column">
        <FlexItem>
          <FlexGroupWrapper gap="xl" direction="row">
            <FlexItem grow={4}>
              <AccountTypeRadioOption
                options={accountTypeOptions}
                onSelection={(selectedOption) => {
                  const options = getAccountTypeOptions(selectedOption.id);
                  setAccountTypeOptions(options);
                  setRoleDescription(roleDescriptionMap[selectedOption.id]);
                  onSelectionClicked(selectedOption?.id);
                }}
              />
            </FlexItem>
            <FlexItem grow={5}>
              <CapabilityList roleDescriptions={roleDescription} maxHeight={600} />
            </FlexItem>
          </FlexGroupWrapper>
        </FlexItem>
        <FlexItem>
          <Callout mode="neutral" title={'Note'}>
            {user?.loginEmail} also inherits Admin privileges from groups. This modal only modifies their directly
            assigned account type
          </Callout>
        </FlexItem>
      </FlexGroupWrapper>
    </>
  );
};

export default ModifyUserAccountType;
