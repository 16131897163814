import React from 'react';

function AmazonKinesisIcon() {
  return (
    <g transform="translate(-12, -12)">
      <g transform="scale(0.05)">
        <path d="M48 239V105L240 9V239H48Z" fill="#9E5025" />
        <path d="M278 239V93.5L310.5 84.5V239H278Z" fill="#9E5025" />
        <path d="M340.5 239V143.5L366.5 139V132.5L411 124.5V239H389.5V146.5L366.5 139V239H340.5Z" fill="#9E5025" />
        <path d="M48 305.5V266L240 286V354.5L48 305.5Z" fill="#9E5025" />
        <path d="M48 373.5V333.5L240 401.5V470.5L48 373.5Z" fill="#9E5025" />
        <path d="M278 29L240 9V239H278V29Z" fill="#F58535" />
        <path d="M310.5 239V84.5L340.5 96.5V239H310.5Z" fill="#F58535" />
        <path d="M389.5 146.5L366.5 139V239H389.5V146.5Z" fill="#F58535" />
        <path d="M429.5 132.5L411 124.5V239H429.5V132.5Z" fill="#F58535" />
        <path d="M240 401.5V470.5L429.5 374V334L240 401.5Z" fill="#F58535" />
        <path d="M240 286V354.5L429.5 306.5V266L240 286Z" fill="#F58535" />
        <path d="M240 286L48 266L240 258L429.5 266L240 286Z" fill="#FCC093" />
        <path d="M240 401.5L48 333.5L120.5 324L240 354.5L360.5 324L429.5 334L240 401.5Z" fill="#FCC093" />
      </g>
    </g>
  );
}

export default AmazonKinesisIcon;
