import styled from '@emotion/styled';
import moment from 'moment-timezone';
import { FC, useState } from 'react';
import {
  MonitoringDateRange,
  calculateStep,
  calculateUnit,
  getDefaultMonitoringDateRange,
  getMonitoringDateRangeByTimeComboOption,
} from '../../monitoring/DateRange-Dropdown-util';

// @tecton
import { CardGridLayout, EmptyPrompt } from '@tecton/ComponentRedesign';
import TectonTimeComboSelector, {
  TimeComboDateRange,
  TimeComboOptionType,
} from '@tecton/ComponentRedesign/lib/TimeComboSelector';

import { ResultMood } from '@tecton/ComponentRedesign/ComponentTypes';
import { useSearchParams } from 'react-router-dom';
import { useListServerGroups } from '../../../api/serverGroup';
import { ServerGroupStatus } from '../../../types/tecton_proto/common/server_group_status';
import { ServerGroupType } from '../../../types/tecton_proto/common/server_group_type';
import { useUserSettings } from '../../context/UserSettingsContext';
import FeatureServerGroup from './FeatureServerGroup';
import { ServerGroupSelectable } from './FeatureServerGroupLists';
import FeatureServerGroupSelector from './FeatureServerGroupSelector';
import RealtimeMonitoringWorkspaceSelector from './RealtimeMonitoringWorkspaceSelector';
import TransformationServerGroup from './TransformationServerGroup';

const SelectorWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.md};
  margin-bottom: ${({ theme }) => theme.padding.l};
`;

export type ServerGroupStatusType = Record<ServerGroupStatus, { title: string; status: ResultMood }>;

const serverGroupStatusMap: ServerGroupStatusType = {
  [ServerGroupStatus.SERVER_GROUP_STATUS_UNSPECIFIED]: { title: 'Unspecified', status: 'warning' },
  [ServerGroupStatus.SERVER_GROUP_STATUS_CREATING]: { title: 'Creating', status: 'warning' },
  [ServerGroupStatus.SERVER_GROUP_STATUS_PENDING]: { title: 'Pending', status: 'warning' },
  [ServerGroupStatus.SERVER_GROUP_STATUS_READY]: { title: 'Ready', status: 'success' },
  [ServerGroupStatus.SERVER_GROUP_STATUS_UPDATING]: { title: 'Updating', status: 'warning' },
  [ServerGroupStatus.SERVER_GROUP_STATUS_ERROR]: { title: 'Error', status: 'danger' },
  [ServerGroupStatus.SERVER_GROUP_STATUS_DELETING]: { title: 'Deleting', status: 'danger' },
};

const RealtimeServerMonitoring: FC = () => {
  const { workspace } = useUserSettings();

  const [selectedWorkspace, setSelectedWorkspace] = useState<string>(workspace ?? 'prod');
  const { data, isLoading } = useListServerGroups(selectedWorkspace ?? 'prod', true);

  if (isLoading) {
    return <EmptyPrompt variant="loading" title="Loading..." body="" />;
  }

  return (
    <RealtimeService
      serverGroups={data}
      selectedWorkspace={selectedWorkspace}
      setSelectedWorkspace={setSelectedWorkspace}
    />
  );
};

const RealtimeService: FC<{
  serverGroups?: ServerGroupSelectable[];
  selectedWorkspace: string;
  setSelectedWorkspace: React.Dispatch<React.SetStateAction<string>>;
}> = ({ serverGroups, selectedWorkspace, setSelectedWorkspace }) => {
  // Date Selector states
  const defaultMonitoringDateRange: MonitoringDateRange = getDefaultMonitoringDateRange();
  const [monitoringDateRange, setMonitoringDateRange] = useState<MonitoringDateRange>(defaultMonitoringDateRange);
  const [selectedTimeComboOption, setSelectedTimeComboOption] = useState<TimeComboOptionType>('1Day');
  const [dateRange, setDateRange] = useState<TimeComboDateRange>({
    startDate: moment().add(-1, 'day'),
    endDate: moment(),
  });

  const [searchParams, setSearchParams] = useSearchParams();
  const groupIdParam = searchParams.get('id');

  const foundSelectedGroup = serverGroups?.find((serverGroup) => {
    return serverGroup.serverGroupId === groupIdParam;
  });

  // General States
  const [selectedServerGroup, setSelectedServerGroup] = useState<ServerGroupSelectable | undefined>(foundSelectedGroup);

  const onWorkspaceSelected = (workspace?: string) => {
    setSelectedWorkspace(workspace ?? '');
    setSelectedServerGroup(undefined);
  };

  const onServerGroupSelected = (serverGroup?: ServerGroupSelectable) => {
    if (serverGroup?.type) {
      // Update the query string params for FSG/TSG
      searchParams.set('id', `${serverGroup?.serverGroupId}`);
    } else {
      // default Feature Server
      searchParams.delete('id');
    }

    setSearchParams(searchParams);
    setSelectedServerGroup(serverGroup);
  };

  return (
    <>
      <CardGridLayout>
        <SelectorWrapper>
          <TectonTimeComboSelector
            dateRange={dateRange}
            setDateRange={setDateRange}
            initialOption={selectedTimeComboOption}
            onCustomDateSelection={(startDate, endDate) => {
              const startTime = () => startDate.valueOf();
              const endTime = () => endDate.valueOf();
              if (endDate) {
                const step = calculateStep(startDate, endDate);
                const unit = calculateUnit(startDate, endDate);

                const customDateRange: MonitoringDateRange = {
                  startTime: startTime,
                  endTime: endTime,
                  requestId: `${startTime()}${endTime()}`,
                  step: step,
                  unit: unit,
                };
                setSelectedTimeComboOption('custom');
                setMonitoringDateRange(customDateRange);
              }
            }}
            onComboSelection={(timeComboOption) => {
              setSelectedTimeComboOption(timeComboOption);
              const monitoringDateRangeOption = getMonitoringDateRangeByTimeComboOption(timeComboOption);
              setMonitoringDateRange(monitoringDateRangeOption);
            }}
          />
          <RealtimeMonitoringWorkspaceSelector
            onWorkspaceSelected={onWorkspaceSelected}
            selectedWorkspace={selectedWorkspace}
          />
          <FeatureServerGroupSelector
            featureServerGroups={
              serverGroups?.filter(
                (serverGroup) => serverGroup.type === ServerGroupType.SERVER_GROUP_TYPE_FEATURE_SERVER_GROUP
              ) ?? []
            }
            transformationServerGroups={
              serverGroups?.filter(
                (serverGroup) => serverGroup.type === ServerGroupType.SERVER_GROUP_TYPE_TRANSFORM_SERVER_GROUP
              ) ?? []
            }
            selectedServerGroup={selectedServerGroup}
            onServerGroupSelected={onServerGroupSelected}
          />
        </SelectorWrapper>
      </CardGridLayout>

      {selectedServerGroup?.type === ServerGroupType.SERVER_GROUP_TYPE_TRANSFORM_SERVER_GROUP ? (
        <TransformationServerGroup
          selectedServerGroup={selectedServerGroup}
          serverGroupStatusMap={serverGroupStatusMap}
          monitoringDateRange={monitoringDateRange}
          selectedWorkspace={selectedWorkspace}
        />
      ) : (
        <FeatureServerGroup
          selectedServerGroup={selectedServerGroup}
          serverGroupStatusMap={serverGroupStatusMap}
          monitoringDateRange={monitoringDateRange}
          selectedWorkspace={selectedWorkspace}
        />
      )}
    </>
  );
};

export default RealtimeServerMonitoring;
