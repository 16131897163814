import { WorkspaceFCOContainer } from '../../../core/types/fcoTypes';

import { useGetFcos } from '../../../api/fcos';
import { useUserSettings } from '../../context/UserSettingsContext';
import TransformationsList from './TransformationsList';

// @tecton
import {
  Breadcrumbs,
  EmptyPrompt,
  FlexGroup,
  FlexItem,
  HeaderLayout,
  Subtitle,
  ViewLayout,
} from '@tecton/ComponentRedesign';
import { TECTON_DOCUMENTATION_LINKS } from '../application-links';
import WorkspaceRootBreadcrumb from '../WorkspaceRootBreadcrumb';

export const TransformationsContainer = () => {
  const { workspace, workspaceDetails } = useUserSettings();

  const { data, isLoading, isError } = useGetFcos<WorkspaceFCOContainer>(workspace as string, {
    select: (data: WorkspaceFCOContainer) => {
      return {
        transformations: data.transformations,
        idToFcoMap: data.idToFcoMap,
      };
    },
  });

  const subtitle = (
    <Subtitle
      description="Transformations define the logic for transforming raw data to features."
      learnMoreUrl={TECTON_DOCUMENTATION_LINKS.TRANSFORMATIONS}
    />
  );

  const header = (
    <HeaderLayout
      breadcrumbs={
        <Breadcrumbs
          workspace={<WorkspaceRootBreadcrumb workspace={workspaceDetails} />}
          crumbs={[{ label: 'Transformations' }]}
        />
      }
      subtitle={subtitle}
    />
  );

  if (isLoading) {
    return (
      <ViewLayout
        header={header}
        body={
          <FlexGroup direction="column" alignItems="center">
            <FlexItem>
              <EmptyPrompt
                title={<>Loading Transformations</>}
                body={<>Transformations are loading.</>}
                variant="loading"
                orientation="vertical"
              />
            </FlexItem>
          </FlexGroup>
        }
      />
    );
  }

  if (isError) {
    return (
      <ViewLayout
        header={header}
        body={
          <EmptyPrompt
            title={<>Error Loading Transformations</>}
            body={<>There was an error loading Transformations for this workspace. Please try again.</>}
            variant="storm"
            orientation="vertical"
          />
        }
      />
    );
  }
  return (
    <ViewLayout
      header={header}
      body={
        <TransformationsList
          workspace={workspace ?? ''}
          items={data?.transformations ?? []}
          idToFcoMap={data?.idToFcoMap ?? {}}
        />
      }
    />
  );
};

export default TransformationsContainer;
