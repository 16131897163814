import React, { FC } from 'react';
import {
  RawBatchNode,
  RawStreamNode,
  RequestNode,
  OnlineStoreNode,
  OfflineStoreNode,
  ModelTrainerNode,
  ModelInferenceNode,
} from './dataFlowTypes';
import { Position } from '@xyflow/react';
import styled from '@emotion/styled';
import { fcoColors } from './DataFlowConstants';
import { BatchDataSourceType, StreamingDataSourceType } from '../../../../core/types/fcoTypes';
import AmazonKinesisIcon from '../../../shared/icons/AmazonKinesisIcon';
import AmazonRedshiftIcon from '../../../shared/icons/AmazonRedshiftIcon';
import DatabricksIcon from '../../../shared/icons/DatabricksIcon';
import HiveIcon from '../../../shared/icons/HiveIcon';
import SnowflakeIcon from '../../../shared/icons/SnowflakeIcon';
import DataFlowHandle from './DataFlowHandle';

const opacityTransitionDuration = '0.3s';

const StoreIconWrapper = styled.div<{
  isFaded?: boolean;
  hasFocus?: boolean;
  isNotClickable?: boolean;
}>`
  text-align: center;
  width: 80px;
  line-height: 1;
  opacity: ${(props) => (props.isFaded ? 0.15 : 1)};
  transition: opacity ${opacityTransitionDuration};
  cursor: ${(props) => (props.isNotClickable ? 'default' : 'pointer')};
  position: relative;

  span:first-child {
    display: inline-block;
  }

  .tecton-store-type {
    position: relative;
    z-index: 10;
    font-size: 10px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 2px;
  }
`;

const SvgWithGlowWrapper = styled.span<{
  isFaded?: boolean;
  hasFocus?: boolean;
  inset?: [number, number];
}>`
  position: relative;
  opacity: ${(props) => (props.isFaded ? 0.15 : 1)};
  transition: opacity ${opacityTransitionDuration};

  svg {
    display: block;
    position: relative;
    z-index: 2;
  }

  ${({ hasFocus, inset }) => {
    if (!hasFocus) {
      return '';
    }

    return `
      &:after {
        content: '';
        position: absolute;
        z-index: 1;
        top: ${inset ? inset[1] : 0}px;
        left: ${inset ? inset[0] : 0}px;
        width: calc(100% - ${inset ? inset[0] * 2 : 0}px);
        height: calc(100% - ${inset ? inset[1] * 2 : 0}px);
        box-shadow: 0 0 20px 10px ${fcoColors['feature_view'].glow};
      }
      `;
  }}
`;

const Wrapper = styled.div`
  cursor: pointer;
`;

interface IconNodeSharedProps {
  denseMode: boolean;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  onClick: (event: React.MouseEvent) => void;
  hasFocus: boolean;
  isFaded: boolean;
  DEBUG_MODE?: boolean;
}

interface StreamDataSourceNodeProps extends IconNodeSharedProps {
  data: RawStreamNode;
}

const StreamDataSourceNode: FC<StreamDataSourceNodeProps> = ({
  onMouseEnter,
  onMouseLeave,
  onClick,
  hasFocus,
  isFaded,
  data,
}) => {
  // Default icon
  let icon: React.ReactNode = (
    <svg>
      {OutflowMask}
      <g mask="url(#outflowClip)">
        <rect fill={fcoColors.data_source['background']} width="38" height="38" rx="6" />
        <g>
          <path
            d="M14 11C17.3333 11 20.6667 7 24 7C28.5 7 31.5 11.5 32 13C29 11 26.5 11 26 11C22.6667 11 19.3333 15 16 15C11.5 15 8.5 10.5 8 9C11 11 13.5 11 14 11Z"
            fill="white"
          />
          <path
            d="M13 19C16.3333 19 19.6667 15 23 15C27.5 15 30.5 19.5 31 21C28 19 25.5 19 25 19C21.6667 19 18.3333 23 15 23C10.5 23 7.5 18.5 7 17C10 19 12.5 19 13 19Z"
            fill="white"
          />
          <path
            d="M12 27C15.3333 27 18.6667 23 22 23C26.5 23 29.5 27.5 30 29C27 27 24.5 27 24 27C20.6667 27 17.3333 31 14 31C9.5 31 6.5 26.5 6 25C9 27 11.5 27 12 27Z"
            fill="white"
          />
        </g>
      </g>
    </svg>
  );

  // Show the specific icon for different data sources
  switch (data.sourceType) {
    case StreamingDataSourceType.KAFKA:
      icon = (
        <svg>
          {OutflowMask}
          <g mask="url(#outflowClip)">
            <rect fill="#231f20" width="38" height="38" rx="6" />
            <g transform="translate(9,3.5) scale(0.075)">
              <path
                d="M201.816 230.216c-16.186 0-30.697 7.171-40.634 18.461l-25.463-18.026c2.703-7.442 4.255-15.433 4.255-23.797 0-8.219-1.498-16.076-4.112-23.408l25.406-17.835c9.936 11.233 24.409 18.365 40.548 18.365 29.875 0 54.184-24.305 54.184-54.184 0-29.879-24.309-54.184-54.184-54.184-29.875 0-54.184 24.305-54.184 54.184 0 5.348.808 10.505 2.258 15.389l-25.423 17.844c-10.62-13.175-25.911-22.374-43.333-25.182v-30.64c24.544-5.155 43.037-26.962 43.037-53.019C124.171 24.305 99.862 0 69.987 0 40.112 0 15.803 24.305 15.803 54.184c0 25.708 18.014 47.246 42.067 52.769v31.038C25.044 143.753 0 172.401 0 206.854c0 34.621 25.292 63.374 58.355 68.94v32.774c-24.299 5.341-42.552 27.011-42.552 52.894 0 29.879 24.309 54.184 54.184 54.184 29.875 0 54.184-24.305 54.184-54.184 0-25.883-18.253-47.553-42.552-52.894v-32.775a69.965 69.965 0 0 0 42.6-24.776l25.633 18.143c-1.423 4.84-2.22 9.946-2.22 15.24 0 29.879 24.309 54.184 54.184 54.184 29.875 0 54.184-24.305 54.184-54.184 0-29.879-24.309-54.184-54.184-54.184zm0-126.695c14.487 0 26.27 11.788 26.27 26.271s-11.783 26.27-26.27 26.27-26.27-11.787-26.27-26.27c0-14.483 11.783-26.271 26.27-26.271zm-158.1-49.337c0-14.483 11.784-26.27 26.271-26.27s26.27 11.787 26.27 26.27c0 14.483-11.783 26.27-26.27 26.27s-26.271-11.787-26.271-26.27zm52.541 307.278c0 14.483-11.783 26.27-26.27 26.27s-26.271-11.787-26.271-26.27c0-14.483 11.784-26.27 26.271-26.27s26.27 11.787 26.27 26.27zm-26.272-117.97c-20.205 0-36.642-16.434-36.642-36.638 0-20.205 16.437-36.642 36.642-36.642 20.204 0 36.641 16.437 36.641 36.642 0 20.204-16.437 36.638-36.641 36.638zm131.831 67.179c-14.487 0-26.27-11.788-26.27-26.271s11.783-26.27 26.27-26.27 26.27 11.787 26.27 26.27c0 14.483-11.783 26.271-26.27 26.271z"
                fill="#ffffff"
              />
            </g>
          </g>
        </svg>
      );
      break;

    case StreamingDataSourceType.KINESIS:
      icon = (
        <svg>
          {OutflowMask}
          <g mask="url(#outflowClip)">
            <rect fill="#ffffff" width="38" height="38" rx="6" />
            <g transform="translate(19, 19) scale(1.6)">
              <AmazonKinesisIcon />
            </g>
          </g>
        </svg>
      );
      break;

    case StreamingDataSourceType.PUSH:
      icon = (
        <svg>
          {OutflowMask}
          <g mask="url(#outflowClip)">
            <rect fill={fcoColors.data_source['background']} width="38" height="38" rx="6" />
            <g>
              <path d="M26.4167 19L19 8H26.5833L34 19L26.5833 30H19L26.4167 19Z" fill="white" />
              <path d="M12.5 17L12 19H13L13.5 17H12.5Z" fill="white" />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5 8V30H15.5833L23 19L15.5833 8H5ZM10 15H16L17 16L16 20L15 21H11.5L11 23H8L10 15Z"
                fill="white"
              />
            </g>
          </g>
        </svg>
      );
      break;
  }

  return (
    <Wrapper>
      <SvgWithGlowWrapper
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={onClick}
        hasFocus={hasFocus}
        isFaded={isFaded}
        inset={[5, 5]}
      >
        <svg width="38" height="38" style={{ display: 'block' }}>
          <g transform={'translate(0,-5'}>{icon}</g>
        </svg>
      </SvgWithGlowWrapper>
    </Wrapper>
  );
};
interface BatchDataSourceNodeProps extends IconNodeSharedProps {
  data: RawBatchNode;
}

const OutflowMask = (
  <defs>
    <mask id="outflowClip">
      <rect width={40} height={40} fill="white" />
      <circle cx={40} cy={20} r={8} fill={'black'} />
    </mask>
  </defs>
);

const InflowMask = (
  <defs>
    <mask id="inflowClip">
      <rect width={40} height={40} fill="white" />
      <circle cx={0} cy={20} r={8} fill={'black'} />
    </mask>
  </defs>
);

const BatchDataSourceNode: FC<BatchDataSourceNodeProps> = ({
  onMouseEnter,
  onMouseLeave,
  onClick,
  hasFocus,
  isFaded,
  data,
}) => {
  // Default icon
  let icon: React.ReactNode = (
    <svg>
      {OutflowMask}
      <g mask="url(#outflowClip)">
        <rect fill={fcoColors.data_source['glow']} width="38" height="38" rx="6" />
        <g transform="translate(3, 3)">
          <OfflineStoreIconSVG stroke={fcoColors.data_source['fontColor']} fill={fcoColors.data_source['glow']} />
        </g>
      </g>
    </svg>
  );

  // Show the specific icon for different data sources
  switch (data.sourceType) {
    case BatchDataSourceType.HIVE:
      icon = (
        // <svg>
        <>
          {OutflowMask}
          <g mask="url(#outflowClip)">
            <rect fill="#5294CF" width="38" height="38" rx="6" />
            <g transform="translate(19, 19) scale(1.25)">
              <HiveIcon />
            </g>
          </g>
        </>
        // </svg>
      );
      break;

    case BatchDataSourceType.FILE:
      icon = (
        <svg>
          {OutflowMask}
          <g mask="url(#outflowClip)">
            <rect fill={fcoColors.data_source['glow']} width="38" height="38" rx="6" />
            <g>
              <path
                d="M28 32H11C9.89543 32 9 31.1046 9 30V8C9 6.89543 9.89543 6 11 6H22L30 14V30C30 31.1046 29.1046 32 28 32Z"
                fill="white"
              />
              <path
                d="M22 6H11C9.89543 6 9 6.89543 9 8V30C9 31.1046 9.89543 32 11 32H28C29.1046 32 30 31.1046 30 30V14M22 6L30 14M22 6V12C22 13.1046 22.8954 14 24 14H30"
                stroke={fcoColors.data_source['fontColor']}
                strokeWidth="2"
                fill="none"
              />
              <path
                d="M13 17H22.5M13 19.5H17.6M13 22H21M13 24.5H16.9M13 27H17.6"
                stroke={fcoColors.data_source['fontColor']}
                strokeWidth="2"
                fill="none"
              />
            </g>
          </g>
        </svg>
      );
      break;

    case BatchDataSourceType.UNITY:
      icon = (
        <svg>
          {OutflowMask}
          <g mask="url(#outflowClip)">
            <rect fill="#FF3621" width="38" height="38" rx="6" />
            <g transform="translate(4, 4) scale(1.25)">
              <DatabricksIcon invert />
            </g>
          </g>
        </svg>
      );
      break;

    case BatchDataSourceType.REDSHIFT:
      icon = (
        <svg>
          {OutflowMask}
          <g mask="url(#outflowClip)">
            <rect fill="#ffffff" width="38" height="38" rx="6" />
            <g transform="translate(19, 19) scale(1.25)">
              <AmazonRedshiftIcon />
            </g>
          </g>
        </svg>
      );
      break;

    case BatchDataSourceType.SNOWFLAKE:
      icon = (
        <svg>
          {OutflowMask}
          <g mask="url(#outflowClip)">
            <rect fill={'#29b5e8'} width="38" height="38" rx="6" />
            <g transform="translate(18.5, 19) scale(1.2)">
              <SnowflakeIcon invert />
            </g>
          </g>
        </svg>
      );
      break;
  }

  return (
    <Wrapper>
      <SvgWithGlowWrapper
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={onClick}
        hasFocus={hasFocus}
        isFaded={isFaded}
        inset={[5, 5]}
      >
        <svg width="38" height="38" style={{ display: 'block', overflow: 'visible' }}>
          <g transform="translate(0,-5)">{icon}</g>
        </svg>
      </SvgWithGlowWrapper>
      {/* <Handle isConnectable={false} type="source" position={Position.Right} /> */}
    </Wrapper>
  );
};

interface OnlineStoreNodeIconProps extends IconNodeSharedProps {
  data: OnlineStoreNode;
}

const onlineStorePath = `
M 4 7 L 4 19 A 12 6 0 0 0 28 19 L 28 7 A 12 6 0 0 0 4 7 Z
`;
const OnlineStoreIconSVG = ({ fill, stroke }: { fill: string; stroke: string }) => {
  return (
    <>
      <path d={onlineStorePath} fill={fill} stroke={stroke} strokeWidth="2" />
      <path d={topOvalPath} fill={fill} stroke={stroke} strokeWidth="2" />
    </>
  );
};

const OnlineStoreNodeIcon: FC<OnlineStoreNodeIconProps> = ({
  onMouseEnter,
  onMouseLeave,
  onClick,
  hasFocus,
  isFaded,
  data,
}) => {
  if (!data) {
    return <DisabledState />;
  }

  return (
    <>
      <StoreIconWrapper
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={onClick}
        hasFocus={hasFocus}
        isFaded={isFaded}
      >
        <SvgWithGlowWrapper hasFocus={hasFocus} inset={[5, 8]}>
          <svg width={ICON_SIZE} height={28} style={{ display: 'inline-block' }}>
            <OnlineStoreIconSVG fill={fcoColors['feature_view'].glow} stroke={fcoColors['feature_view'].background} />
          </svg>
        </SvgWithGlowWrapper>
        <p className="tecton-store-type">Online Store</p>
      </StoreIconWrapper>
    </>
  );
};

interface OfflineStoreNodeIconProps extends IconNodeSharedProps {
  data?: OfflineStoreNode;
}

const ICON_SIZE = 32;

const topOvalPath = `
M 4 7 A 12 6 0 0 0 28 7 A 12 6 0 0 0 4 7 Z`;

const offlineStorePath = `
M 4 7 L 4 25 A 12 6 0 0 0 28 25 L 28 7 A 12 6 0 0 0 4 7 Z
`;

const bands = `
M 4 13 A 12 6 0 0 0 28 13 M 4 19 A 12 6 0 0 0 28 19`;

const OfflineStoreIconSVG = ({ fill, stroke }: { fill: string; stroke: string }) => {
  return (
    <>
      <path d={offlineStorePath} fill={fill} stroke={stroke} strokeWidth="2" />
      <path d={topOvalPath} fill={fill} stroke={stroke} strokeWidth="2" />
      <path d={bands} fill="none" stroke={stroke} strokeWidth="2" />
    </>
  );
};

const DisabledState = () => {
  return (
    <>
      <StoreIconWrapper>
        <SvgWithGlowWrapper inset={[5, 5]}>
          <svg width={ICON_SIZE} height={ICON_SIZE} style={{ display: 'block' }}>
            <OfflineStoreIconSVG fill={'#aaa'} stroke={'#555'} />
          </svg>
        </SvgWithGlowWrapper>
        <p className="tecton-store-type">Offline Store</p>
        <p>Disabled</p>
      </StoreIconWrapper>
    </>
  );
};

const OfflineStoreNodeIcon: FC<OfflineStoreNodeIconProps> = ({
  onMouseEnter,
  onMouseLeave,
  onClick,
  hasFocus,
  isFaded,
  data,
}) => {
  if (!data) {
    return <DisabledState />;
  }

  return (
    <>
      <StoreIconWrapper
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={onClick}
        hasFocus={hasFocus}
        isFaded={isFaded}
      >
        <SvgWithGlowWrapper hasFocus={hasFocus} inset={[5, 5]}>
          <svg width={ICON_SIZE} height={ICON_SIZE} style={{ display: 'block' }}>
            <OfflineStoreIconSVG fill={fcoColors['feature_view'].glow} stroke={fcoColors['feature_view'].background} />
          </svg>
        </SvgWithGlowWrapper>
        <p className="tecton-store-type">Offline Store</p>
      </StoreIconWrapper>
    </>
  );
};

interface ModelTrainerNodeIconProps extends IconNodeSharedProps {
  data: ModelTrainerNode;
}

const STROKE_COLOR = fcoColors['feature_service'].fontColor;

const ModelTrainerNodeIcon: FC<ModelTrainerNodeIconProps> = ({
  onMouseEnter,
  onMouseLeave,
  onClick,
  hasFocus,
  isFaded,
}) => {
  return (
    <Wrapper>
      <SvgWithGlowWrapper
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={onClick}
        hasFocus={hasFocus}
        isFaded={isFaded}
        inset={[5, 5]}
      >
        <DataFlowHandle position={Position.Left} top={'20px'} />
        <svg width="38" height="38">
          {InflowMask}
          <g mask={'url(#inflowClip'}>
            <rect width={38} height={38} rx={8} ry={8} fill={fcoColors['feature_service'].glow} />
            <g>
              <path
                d="M15.7348 15.3217C15.3602 14.9159 14.7275 14.8906 14.3217 15.2652C13.9159 15.6398 13.8906 16.2725 14.2652 16.6783L14.9231 17.391V21.8793L9.21992 28.9897C9.07758 29.1672 9 29.3879 9 29.6154V33C9 33.5523 9.44772 34 10 34H28C28.5523 34 29 33.5523 29 33V29.6154C29 29.3879 28.9224 29.1672 28.7801 28.9897L23.0769 21.8793V17.391L23.7348 16.6783C24.1094 16.2725 24.0841 15.6398 23.6783 15.2652C23.2725 14.8906 22.6398 14.9159 22.2652 15.3217L21.3421 16.3217C21.1716 16.5064 21.0769 16.7486 21.0769 17V22.2308C21.0769 22.4583 21.1545 22.679 21.2968 22.8565L24.3839 26.7052C24.3241 26.6811 24.2641 26.6566 24.2039 26.632C23.4514 26.3251 22.6546 26 21.4884 26C20.3222 26 19.6484 26.4334 19.0122 26.8427C18.4432 27.2088 17.9042 27.5556 17.0698 27.5556C16.3089 27.5556 16.0393 27.4114 15.7323 27.2472C15.364 27.0503 14.9418 26.8245 13.5528 26.7841L16.7032 22.8565C16.8455 22.679 16.9231 22.4583 16.9231 22.2308V17C16.9231 16.7486 16.8284 16.5064 16.6579 16.3217L15.7348 15.3217Z"
                fill={STROKE_COLOR}
              />
              <path
                d="M20 7C19.4286 7 19.4286 7.57143 19.4286 7.57143C19.4286 9.28571 18.2857 10.4286 16.5714 10.4286C16.5714 10.4286 16 10.4286 16 11C16 11.5714 16.5714 11.5714 16.5714 11.5714C18.2857 11.5714 19.4286 12.7143 19.4286 14.4286C19.4286 14.4286 19.4286 15 20 15C20.5714 15 20.5714 14.4286 20.5714 14.4286C20.5714 12.7143 21.7143 11.5714 23.4286 11.5714C23.4286 11.5714 24 11.5714 24 11C24 10.4286 23.4286 10.4286 23.4286 10.4286C21.7143 10.4286 20.5714 9.28571 20.5714 7.57143C20.5714 7.57143 20.5714 7 20 7Z"
                fill={STROKE_COLOR}
              />
              <path
                d="M27 3C26.4286 3 26.4286 3.57143 26.4286 3.57143C26.4286 5.28571 25.2857 6.42857 23.5714 6.42857C23.5714 6.42857 23 6.42857 23 7C23 7.57143 23.5714 7.57143 23.5714 7.57143C25.2857 7.57143 26.4286 8.71429 26.4286 10.4286C26.4286 10.4286 26.4286 11 27 11C27.5714 11 27.5714 10.4286 27.5714 10.4286C27.5714 8.71429 28.7143 7.57143 30.4286 7.57143C30.4286 7.57143 31 7.57143 31 7C31 6.42857 30.4286 6.42857 30.4286 6.42857C28.7143 6.42857 27.5714 5.28571 27.5714 3.57143C27.5714 3.57143 27.5714 3 27 3Z"
                fill={STROKE_COLOR}
              />
            </g>
          </g>
        </svg>
      </SvgWithGlowWrapper>
    </Wrapper>
  );
};

interface ModelInferenceNodeIconProps extends IconNodeSharedProps {
  data: ModelInferenceNode;
}

const ModelInferenceNodeIcon: FC<ModelInferenceNodeIconProps> = ({
  onMouseEnter,
  onMouseLeave,
  onClick,
  hasFocus,
  isFaded,
}) => {
  return (
    <>
      <Wrapper>
        <SvgWithGlowWrapper
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onClick={onClick}
          hasFocus={hasFocus}
          isFaded={isFaded}
          inset={[5, 5]}
        >
          <DataFlowHandle position={Position.Left} top={'20px'} />

          <svg width="38" height="38">
            {InflowMask}
            <g mask={'url(#inflowClip'}>
              <rect width={38} height={38} rx={8} ry={8} fill={fcoColors.feature_service.background} />
              <g>
                <path
                  d="M17 9C16.2 9 16.2 9.8 16.2 9.8C16.2 13 13 16.2 9.8 16.2C9.8 16.2 9 16.2 9 17C9 17.8 9.8 17.8 9.8 17.8C13 17.8 16.2 21 16.2 24.2C16.2 24.2 16.2 25 17 25C17.8 25 17.8 24.2 17.8 24.2C17.8 21 21 17.8 24.2 17.8C24.2 17.8 25 17.8 25 17C25 16.2 24.2 16.2 24.2 16.2C21 16.2 17.8 13 17.8 9.8C17.8 9.8 17.8 9 17 9Z"
                  fill="white"
                />
                <path
                  d="M25 22C24.4286 22 24.4286 22.5714 24.4286 22.5714C24.4286 24.2857 23.2857 25.4286 21.5714 25.4286C21.5714 25.4286 21 25.4286 21 26C21 26.5714 21.5714 26.5714 21.5714 26.5714C23.2857 26.5714 24.4286 27.7143 24.4286 29.4286C24.4286 29.4286 24.4286 30 25 30C25.5714 30 25.5714 29.4286 25.5714 29.4286C25.5714 27.7143 26.7143 26.5714 28.4286 26.5714C28.4286 26.5714 29 26.5714 29 26C29 25.4286 28.4286 25.4286 28.4286 25.4286C26.7143 25.4286 25.5714 24.2857 25.5714 22.5714C25.5714 22.5714 25.5714 22 25 22Z"
                  fill="white"
                />
              </g>
            </g>
          </svg>
        </SvgWithGlowWrapper>
      </Wrapper>
    </>
  );
};

interface DataFlowIconNodeProps extends IconNodeSharedProps {
  iconType:
    | 'batch'
    | 'stream'
    | 'requestDataSource'
    | 'onlineStore'
    | 'offlineStore'
    | 'modelTrainer'
    | 'modelInference';
  data:
    | RawBatchNode
    | RawStreamNode
    | RequestNode
    | OnlineStoreNode
    | OfflineStoreNode
    | ModelTrainerNode
    | ModelInferenceNode;
}

const DataFlowIconNode: FC<DataFlowIconNodeProps> = ({
  data,
  onMouseEnter,
  onMouseLeave,
  onClick,
  hasFocus,
  isFaded,
  iconType,
  denseMode,
}) => {
  switch (iconType) {
    case 'batch':
      return (
        <div
          style={{
            width: '38px',
            height: '38px',

            position: 'relative',
            overflow: 'visible',
          }}
        >
          <BatchDataSourceNode
            data={data as RawBatchNode}
            denseMode={denseMode}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onClick}
            hasFocus={hasFocus}
            isFaded={isFaded}
          />
          <DataFlowHandle position={Position.Right} top={'15px'} />
        </div>
      );
    case 'stream':
      return (
        <div style={{ width: '40px', height: '40px', position: 'relative', overflow: 'visible' }}>
          <StreamDataSourceNode
            data={data as RawStreamNode}
            denseMode={denseMode}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onClick}
            hasFocus={hasFocus}
            isFaded={isFaded}
          />
          <DataFlowHandle position={Position.Right} top={'20px'} />
        </div>
      );
    case 'requestDataSource':
      return <></>;
    case 'onlineStore':
      return (
        <OnlineStoreNodeIcon
          data={data as OnlineStoreNode}
          denseMode={denseMode}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onClick={onClick}
          hasFocus={hasFocus}
          isFaded={isFaded}
        />
      );
    case 'offlineStore':
      return (
        <OfflineStoreNodeIcon
          data={data as OfflineStoreNode}
          denseMode={denseMode}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onClick={onClick}
          hasFocus={hasFocus}
          isFaded={isFaded}
        />
      );
    case 'modelInference':
      return (
        <ModelInferenceNodeIcon
          data={data as ModelInferenceNode}
          denseMode={denseMode}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onClick={onClick}
          hasFocus={hasFocus}
          isFaded={isFaded}
        />
      );
    case 'modelTrainer':
      return (
        <ModelTrainerNodeIcon
          data={data as ModelTrainerNode}
          denseMode={denseMode}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onClick={onClick}
          hasFocus={hasFocus}
          isFaded={isFaded}
        />
      );
  }
};

export default DataFlowIconNode;
