import _ from 'lodash';
import { FC } from 'react';
import { usePrometheus } from '../../../feature/query';
import { MonitoringDateRange } from '../../monitoring/DateRange-Dropdown-util';
import FeatureViewHealthTable from './FeatureViewHealthTable';

// @tecton
import { Card, Loading } from '@tecton/ComponentRedesign/';
import { getFeatureHealthResults } from '@tecton/ComponentRedesign/Charts/graph-util';

interface FeatureViewFailingIdsProps {
  monitoringDateRange: MonitoringDateRange;
}

const FeatureViewFailingIds: FC<FeatureViewFailingIdsProps> = ({ monitoringDateRange }) => {
  const queries = [
    {
      label: 'Failing Feature View IDs',
      query: `count by (tecton_cluster, alertname, feature_package_id, workspace_name, feature_view_name) (ALERTS{alertstate='firing', alertname=~'BatchMaterializationTaskRecurrentFailures|StreamingMaterializationTaskRecurrentFailures|MaterializationTaskMovedToPermanentFailure|FeatureTableIngestTaskRecurrentFailures', tecton_cluster=~'.*', aws_region=~'.*'})`,
    },
  ];

  const state = usePrometheus(queries, monitoringDateRange);
  if (state.isLoading) {
    return <Loading size={'xxl'} />;
  }

  const result = _.get(state, 'data');
  const healthResults: any = getFeatureHealthResults(result ? result : []);
  const healthResultsLength = healthResults.length;

  return (
    <Card title={healthResultsLength > 0 ? `Failing Feature Views (${healthResults.length})` : `Failing Feature Views`}>
      <FeatureViewHealthTable healthResults={healthResults} />
    </Card>
  );
};

export default FeatureViewFailingIds;
