import React, { FC } from 'react';
import { MonitoringDateRange } from '../../components/monitoring/DateRange-Dropdown-util';

import { X_AXIS_LABEL } from '../../components/monitoring/GraphContainer';
import { GraphType } from '../../feature/feature-views/graph-types';
import GraphiteGraph from './GraphiteGraph';
interface GraphiteContainerProps {
  monitoringDateRange: MonitoringDateRange;
  workspace: string;
  graphMetadata: GraphType;
  featureViewId: string;
  height?: number;
}

const GraphiteContainer: FC<GraphiteContainerProps> = ({
  monitoringDateRange,
  graphMetadata,
  featureViewId,
  height,
}) => {
  const chartTitle = graphMetadata.chartTitle ?? '';
  const yAxisLabel = graphMetadata?.yAxisLabel ?? '';

  const yTicksCallback = graphMetadata.yTickCallBack;
  const toolTipLabelCallback = graphMetadata.toolTipLabelCallback;
  const graphInfoTooltip = graphMetadata?.graphInfoTooltip;
  const parameters = graphMetadata?.parameters?.apply(this, [featureViewId]) ?? [];

  const hideLegendOverride = {
    display: true,
    labels: {
      display: false,
      generateLabels: () => {
        return '';
      },
    },
  };

  const yOverride = graphMetadata?.yOptions
    ? { ...graphMetadata?.yOptions, time: { unit: monitoringDateRange.unit } }
    : null;

  return (
    <>
      <GraphiteGraph
        chartTitle={chartTitle}
        monitoringDateRange={monitoringDateRange}
        parameters={parameters}
        xAxisLabel={X_AXIS_LABEL}
        yAxisLabel={yAxisLabel}
        yOverride={yOverride}
        yTicksCallback={yTicksCallback}
        yGrace={3}
        toolTipLabelCallback={toolTipLabelCallback}
        pluginLegendOverride={graphMetadata?.showLegend ? undefined : hideLegendOverride}
        graphInfoTooltip={graphInfoTooltip}
        height={height}
      />
    </>
  );
};

export default GraphiteContainer;
