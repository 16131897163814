import React, { FC } from 'react';
import styled from '@emotion/styled';
import { ColoredFCOTypes, fcoColors } from './DataFlowConstants';
import { useTectonTheme } from '@tecton/ComponentRedesign';

interface DataFlowFCONodeContainerProps {
  width?: number;
  height?: number;
  denseMode?: boolean;
  fcoType?: ColoredFCOTypes;
  background?: string;
  isFaded?: boolean;
  hasFocus?: boolean;
  isRightToLeft?: boolean;
  isUnused?: boolean;
  isNotClickable?: boolean;
  children: React.ReactNode;
  hasRequestDataSource: boolean;
}

const CARD_WIDTH = 244;

const DataFlowCard = styled.div<{
  isUnused: boolean;
  padding: string;
  width?: number;
  cardHeight: string;
  isNotClickable: boolean;
  hasFocus: boolean;
  background?: string;
  defaultBackground: string;
  glowColor: string;
}>`
    display: flex;
    height:36px;
    background: ${({ isUnused, background, defaultBackground }) =>
      isUnused ? '#E8E9ED' : background ? background : defaultBackground};
    padding:${({ padding }) => padding};
    width: ${({ width }) => (width ? width : CARD_WIDTH)}px;
    height: ${({ cardHeight }) => cardHeight};
    overflow: visible;
    border-radius: ${({ theme }) => theme.border.radius.small};
    cursor: ${({ isNotClickable }) => (isNotClickable ? 'default' : 'pointer')};
    box-shadow: ${({ hasFocus, glowColor, theme }) => (hasFocus ? `0 0 20px 10px ${glowColor}` : theme.shadow.s)}};
    border:${({ theme }) => theme.border.thin};
  `;

const DataFlowFCONodeContainer: FC<DataFlowFCONodeContainerProps> = ({
  width,
  denseMode,
  fcoType,
  background,
  hasFocus,
  isUnused,
  isNotClickable,
  children,
}) => {
  const { theme } = useTectonTheme();

  const cardHeight = denseMode ? '36px' : '64px';

  const defaultBackground = theme.colors.emptyShade;
  const glowColor = fcoType ? fcoColors[fcoType].glow : '#56CCF2'; // TODO: USE TECTON COLOR

  const padding = denseMode ? `0px ${theme.padding.l}` : `${theme.padding.m} ${theme.padding.l}`;

  return (
    <DataFlowCard
      isUnused={isUnused ?? false}
      padding={padding}
      width={width}
      cardHeight={cardHeight}
      isNotClickable={isNotClickable ?? false}
      hasFocus={hasFocus ?? false}
      background={background}
      defaultBackground={defaultBackground}
      glowColor={glowColor}
    >
      {children}
    </DataFlowCard>
  );
};

export default DataFlowFCONodeContainer;
