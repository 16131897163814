import React, { FC } from 'react';

// @tecton
import { FlexItem, Text } from '@tecton/ComponentRedesign';
import { FlexGroupWrapper } from '../../@tecton/ComponentRedesign/StyledComponents';

interface DeleteProps {
  message?: string;
}

const Delete: FC<DeleteProps> = ({ message = 'Are you sure you want to delete this account?' }) => {
  return (
    <>
      <FlexGroupWrapper gap="l" direction="column">
        <FlexItem grow={false}>
          <Text>{message}</Text>
        </FlexItem>
        <FlexItem grow={false}></FlexItem>
      </FlexGroupWrapper>
    </>
  );
};

export default Delete;
