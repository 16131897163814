import styled from '@emotion/styled';
import { FC } from 'react';
import { TransformationNode } from '../../../dataflow/dataFlowTypes';
import { fcoColors } from './DataFlowConstants';
import {
  DataSourceNode,
  EmbeddingModelNode,
  FeatureServiceNode,
  FeatureViewNode,
  ODFVNode,
  RequestNode,
} from './dataFlowTypes';
import fvTypeToNameMapping from './DFFeatureView/fvTypeToNameMapping';

const RequestDataSourceIcon = () => {
  return (
    <svg width="13px" height="12px" style={{ verticalAlign: 'text-bottom', marginRight: '8px' }}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.47726 0.319987C5.61159 0.243189 5.76398 0.202759 5.91909 0.202759C6.07421 0.202759 6.22659 0.243189 6.36092 0.319987L10.5268 2.70099C10.5788 2.73099 10.6273 2.76499 10.6712 2.80399C10.7227 2.81502 10.7713 2.83661 10.8139 2.86738C10.8565 2.89814 10.8921 2.93739 10.9185 2.9826C10.9448 3.02781 10.9613 3.07799 10.9669 3.12989C10.9725 3.1818 10.9671 3.23429 10.9509 3.28399C10.9625 3.34049 10.9686 3.39899 10.9686 3.45899V5.12499C10.9686 5.22444 10.9287 5.31982 10.8577 5.39015C10.7867 5.46048 10.6903 5.49999 10.5899 5.49999C10.4894 5.49999 10.3931 5.46048 10.3221 5.39015C10.2511 5.31982 10.2112 5.22444 10.2112 5.12499V3.81999L6.29781 6.05699V11.18C6.29785 11.2458 6.28038 11.3105 6.24716 11.3676C6.21394 11.4246 6.16613 11.472 6.10855 11.5049C6.05097 11.5379 5.98565 11.5552 5.91915 11.5552C5.85265 11.5552 5.78733 11.5379 5.72974 11.505L1.31142 8.97898C1.17713 8.90221 1.0656 8.7918 0.988045 8.65883C0.910489 8.52587 0.869636 8.37503 0.869588 8.22149V3.45849C0.869588 3.39899 0.875647 3.34049 0.887261 3.28349C0.871128 3.23379 0.865681 3.1813 0.871268 3.12939C0.876856 3.07749 0.893356 3.02731 0.919705 2.9821C0.946055 2.93689 0.981671 2.89764 1.02426 2.86688C1.06685 2.83611 1.11548 2.81452 1.167 2.80349C1.21154 2.7646 1.25993 2.73026 1.31142 2.70099L5.47726 0.319987ZM1.62701 3.81899V8.22048C1.62701 8.26548 1.65125 8.30648 1.69013 8.32848L5.54038 10.5295V6.05599L1.62701 3.81899ZM5.91909 5.40649L2.00573 3.16999L5.85597 0.968986C5.87516 0.958015 5.89693 0.95224 5.91909 0.95224C5.94125 0.95224 5.96302 0.958015 5.98221 0.968986L9.83246 3.16999L5.91909 5.40699V5.40649Z"
        fill={fcoColors.feature_service.fontColor}
      />
      <path
        d="M9.25984 8.74998L10.7217 7.39899C10.7939 7.33082 10.8361 7.23716 10.839 7.13837C10.8419 7.03958 10.8053 6.94365 10.7372 6.87143C10.6691 6.79922 10.5749 6.75656 10.4752 6.75273C10.3754 6.7489 10.2782 6.78421 10.2046 6.85099L8.04089 8.85098C8.00298 8.88607 7.97274 8.92849 7.95208 8.97563C7.93141 9.02276 7.92074 9.0736 7.92074 9.12498C7.92074 9.17637 7.93141 9.22721 7.95208 9.27434C7.97274 9.32148 8.00298 9.3639 8.04089 9.39898L10.2046 11.399C10.2408 11.4332 10.2836 11.4599 10.3303 11.4776C10.377 11.4953 10.4268 11.5037 10.4768 11.5023C10.5269 11.5008 10.5761 11.4896 10.6217 11.4692C10.6673 11.4488 10.7084 11.4197 10.7425 11.3835C10.7767 11.3473 10.8033 11.3047 10.8207 11.2583C10.8382 11.2118 10.8462 11.1624 10.8442 11.1129C10.8423 11.0634 10.8305 11.0148 10.8094 10.9698C10.7884 10.9248 10.7586 10.8845 10.7217 10.851L9.25984 9.49998H12.6092C12.7096 9.49998 12.8059 9.46048 12.877 9.39015C12.948 9.31982 12.9879 9.22444 12.9879 9.12498C12.9879 9.02553 12.948 8.93015 12.877 8.85982C12.8059 8.78949 12.7096 8.74998 12.6092 8.74998H9.25984Z"
        fill={fcoColors.feature_service.fontColor}
      />
    </svg>
  );
};

const Headline = styled.p`
  font-size: ${({ theme }) => theme.font.fontSizes.xxxs};
  color: ${({ theme }) => theme.colors.subduedText};
  font-weight: ${({ theme }) => theme.font.weight.regular};
  line-height: 0.95;
  text-transform: uppercase;
  letter-spacing: 0.05em;
`;
interface DataFlowFCONodeTitleProps {
  data:
    | DataSourceNode
    | FeatureViewNode
    | ODFVNode
    | FeatureServiceNode
    | RequestNode
    | TransformationNode
    | EmbeddingModelNode;
}

const DataFlowFCONodeTitle: FC<DataFlowFCONodeTitleProps> = ({ data }) => {
  let content = <></>;

  switch (data.type) {
    case 'data_source':
      content = <>{data.dataSourceType} Data Source</>;
      break;
    case 'feature_view':
      content = <>{fvTypeToNameMapping(data)}</>;
      break;
    case 'odfv':
      content = <>Realtime Feature View</>;
      break;
    case 'feature_service':
      content = <>Feature Service</>;
      break;
    case 'transformation':
      content = <>Transformation</>;
      break;
    case 'embeddingModel':
      content = data.isCustomModel ? <>Custom Model</> : <>Embedding Model</>;
      break;
    case 'request_data_source':
      content = (
        <>
          <RequestDataSourceIcon />
          Request Data Source
        </>
      );
  }

  return <Headline>{content}</Headline>;
};

export default DataFlowFCONodeTitle;
