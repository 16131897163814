import React, { FC } from 'react';
import { useTectonTheme } from '../../Theme/ThemeProvider';
import styled from '@emotion/styled';

import { ReactComponent as AccountsAndAccess } from '../Icons/Icon/AccountsAndAccess.svg';
import { ReactComponent as Admin } from '../Icons/Icon/Admin.svg';
import { ReactComponent as Aggregation } from '../Icons/Icon/Aggregation.svg';
import { ReactComponent as App } from '../Icons/Icon/App.svg';
import { ReactComponent as ArrowUp } from '../Icons/Icon/ArrowUp.svg';
import { ReactComponent as ArrowUpRight } from '../Icons/Icon/ArrowUpRight.svg';
import { ReactComponent as Book } from '../Icons/Icon/Book.svg';
import { ReactComponent as Brain } from '../Icons/Icon/Brain.svg';
import { ReactComponent as Calendar } from '../Icons/Icon/Calendar.svg';
import { ReactComponent as Cart } from '../Icons/Icon/Cart.svg';
import { ReactComponent as Check } from '../Icons/Icon/Check.svg';
import { ReactComponent as CheckCircle } from '../Icons/Icon/CheckCircle.svg';
import { ReactComponent as Checkmark } from '../Icons/Icon/Checkmark.svg';
import { ReactComponent as ChevronDown } from '../Icons/Icon/ChevronDown.svg';
import { ReactComponent as ChevronRight } from '../Icons/Icon/ChevronRight.svg';
import { ReactComponent as ChipSparkle } from '../Icons/Icon/ChipSparkle.svg';
import { ReactComponent as CloseSmall } from '../Icons/Icon/CloseSmall.svg';
import { ReactComponent as Code } from '../Icons/Icon/Code.svg';
import { ReactComponent as CodeDocument } from '../Icons/Icon/CodeDocument.svg';
import { ReactComponent as Collapse } from '../Icons/Icon/Collapse.svg';
import { ReactComponent as Columns } from '../Icons/Icon/Columns.svg';
import { ReactComponent as Comment } from '../Icons/Icon/Comment.svg';
import { ReactComponent as Compute } from '../Icons/Icon/Compute.svg';
import { ReactComponent as ComputeV2 } from '../Icons/Icon/ComputeV2.svg';
import { ReactComponent as Copy } from '../Icons/Icon/Copy.svg';
import { ReactComponent as Danger } from '../Icons/Icon/Danger.svg';
import { ReactComponent as DataQuality } from '../Icons/Icon/DataQuality.svg';
import { ReactComponent as Dataflow } from '../Icons/Icon/Dataflow.svg';
import { ReactComponent as DataflowSummary } from '../Icons/Icon/DataflowSummary.svg';
import { ReactComponent as DataflowV2 } from '../Icons/Icon/DataflowV2.svg';
import { ReactComponent as Dataframe } from '../Icons/Icon/Dataframe.svg';
import { ReactComponent as Dataset } from '../Icons/Icon/Dataset.svg';
import { ReactComponent as DatasetV2 } from '../Icons/Icon/DatasetV2.svg';
import { ReactComponent as DevelopmentWorkspace } from '../Icons/Icon/DevelopmentWorkspace.svg';
import { ReactComponent as Documentation } from '../Icons/Icon/Documentation.svg';
import { ReactComponent as DrawerHide } from '../Icons/Icon/DrawerHide.svg';
import { ReactComponent as DrawerShow } from '../Icons/Icon/DrawerShow.svg';
import { ReactComponent as Edit } from '../Icons/Icon/Edit.svg';
import { ReactComponent as Embedding } from '../Icons/Icon/Embedding.svg';
import { ReactComponent as EmbeddingModel } from '../Icons/Icon/EmbeddingModel.svg';
import { ReactComponent as Entities } from '../Icons/Icon/Entities.svg';
import { ReactComponent as EntitiesV2 } from '../Icons/Icon/EntitiesV2.svg';
import { ReactComponent as Exit } from '../Icons/Icon/Exit.svg';
import { ReactComponent as Expand } from '../Icons/Icon/Expand.svg';
import { ReactComponent as Eye } from '../Icons/Icon/Eye.svg';
import { ReactComponent as FeatureBatch } from '../Icons/Icon/FeatureBatch.svg';
import { ReactComponent as FeatureService } from '../Icons/Icon/FeatureService.svg';
import { ReactComponent as FeatureServiceV2 } from '../Icons/Icon/FeatureServiceV2.svg';
import { ReactComponent as FeatureStream } from '../Icons/Icon/FeatureStream.svg';
import { ReactComponent as FeatureView } from '../Icons/Icon/FeatureView.svg';
import { ReactComponent as FeatureViewV2 } from '../Icons/Icon/FeatureViewV2.svg';
import { ReactComponent as Filters } from '../Icons/Icon/Filters.svg';
import { ReactComponent as FlowRag } from '../Icons/Icon/FlowRag.svg';
import { ReactComponent as Folder } from '../Icons/Icon/Folder.svg';
import { ReactComponent as Fraud } from '../Icons/Icon/Fraud.svg';
import { ReactComponent as GearCogs } from '../Icons/Icon/GearCogs.svg';
import { ReactComponent as Group } from '../Icons/Icon/Group.svg';
import { ReactComponent as Images } from '../Icons/Icon/Images.svg';
import { ReactComponent as Information } from '../Icons/Icon/Information.svg';
import { ReactComponent as Integration } from '../Icons/Icon/Integration.svg';
import { ReactComponent as Jobs } from '../Icons/Icon/Jobs.svg';
import { ReactComponent as KeyChain } from '../Icons/Icon/KeyChain.svg';
import { ReactComponent as Lock } from '../Icons/Icon/Lock.svg';
import { ReactComponent as Log } from '../Icons/Icon/Log.svg';
import { ReactComponent as LogOut } from '../Icons/Icon/LogOut.svg';
import { ReactComponent as Logo } from '../Icons/Icon/Logo.svg';
import { ReactComponent as MagnifyingGlass } from '../Icons/Icon/MagnifyingGlass.svg';
import { ReactComponent as Mail } from '../Icons/Icon/Mail.svg';
import { ReactComponent as Monitoring } from '../Icons/Icon/Monitoring.svg';
import { ReactComponent as NotSet } from '../Icons/Icon/NotSet.svg';
import { ReactComponent as OfflineDatabase } from '../Icons/Icon/OfflineDatabase.svg';
import { ReactComponent as OnlineDatabase } from '../Icons/Icon/OnlineDatabase.svg';
import { ReactComponent as PermissionsIcon } from '../Icons/Icon/PermissionsIcon.svg';
import { ReactComponent as Plus } from '../Icons/Icon/Plus.svg';
import { ReactComponent as Popout } from '../Icons/Icon/Popout.svg';
import { ReactComponent as Preview } from '../Icons/Icon/Preview.svg';
import { ReactComponent as ProductionWorkspace } from '../Icons/Icon/ProductionWorkspace.svg';
import { ReactComponent as Prompt } from '../Icons/Icon/Prompt.svg';
import { ReactComponent as Question } from '../Icons/Icon/Question.svg';
import { ReactComponent as QuestionInCircle } from '../Icons/Icon/QuestionInCircle.svg';
import { ReactComponent as ReceiptOrders } from '../Icons/Icon/ReceiptOrders.svg';
import { ReactComponent as Recent } from '../Icons/Icon/Recent.svg';
import { ReactComponent as Refresh } from '../Icons/Icon/Refresh.svg';
import { ReactComponent as Registry } from '../Icons/Icon/Registry.svg';
import { ReactComponent as RequestDataSource } from '../Icons/Icon/RequestDataSource.svg';
import { ReactComponent as Retrieval } from '../Icons/Icon/Retrieval.svg';
import { ReactComponent as Retry } from '../Icons/Icon/Retry.svg';
import { ReactComponent as Robot } from '../Icons/Icon/Robot.svg';
import { ReactComponent as Rocket } from '../Icons/Icon/Rocket.svg';
import { ReactComponent as Running } from '../Icons/Icon/Running.svg';
import { ReactComponent as Scalable } from '../Icons/Icon/Scalable.svg';
import { ReactComponent as Search } from '../Icons/Icon/Search.svg';
import { ReactComponent as Settings } from '../Icons/Icon/Settings.svg';
import { ReactComponent as Sources } from '../Icons/Icon/Sources.svg';
import { ReactComponent as SourcesV2 } from '../Icons/Icon/SourcesV2.svg';
import { ReactComponent as Sparkles } from '../Icons/Icon/Sparkles.svg';
import { ReactComponent as SparklesCircle } from '../Icons/Icon/SparklesCircle.svg';
import { ReactComponent as Star } from '../Icons/Icon/Star.svg';
import { ReactComponent as StructuredData } from '../Icons/Icon/StructuredData.svg';
import { ReactComponent as Support } from '../Icons/Icon/Support.svg';
import { ReactComponent as SupportLifeRing } from '../Icons/Icon/SupportLifeRing.svg';
import { ReactComponent as SupportV2 } from '../Icons/Icon/SupportV2.svg';
import { ReactComponent as Tag } from '../Icons/Icon/Tag.svg';
import { ReactComponent as Template } from '../Icons/Icon/Template.svg';
import { ReactComponent as Text } from '../Icons/Icon/Text.svg';
import { ReactComponent as Timestamp } from '../Icons/Icon/Timestamp.svg';
import { ReactComponent as Timezone } from '../Icons/Icon/Timezone.svg';
import { ReactComponent as Transfer } from '../Icons/Icon/Transfer.svg';
import { ReactComponent as Transformations } from '../Icons/Icon/Transformations.svg';
import { ReactComponent as Trash } from '../Icons/Icon/Trash.svg';
import { ReactComponent as UnstructuredData } from '../Icons/Icon/UnstructuredData.svg';
import { ReactComponent as UserCircle } from '../Icons/Icon/UserCircle.svg';
import { ReactComponent as UserGroup } from '../Icons/Icon/UserGroup.svg';
import { ReactComponent as UserInfo } from '../Icons/Icon/UserInfo.svg';
import { ReactComponent as Viewer } from '../Icons/Icon/Viewer.svg';
import { ReactComponent as Warning } from '../Icons/Icon/Warning.svg';
import { ReactComponent as WhatsNew } from '../Icons/Icon/WhatsNew.svg';
import { ReactComponent as Wordmark } from '../Icons/Icon/Wordmark.svg';
import { ReactComponent as Workspaces } from '../Icons/Icon/Workspaces.svg';
import { ReactComponent as Fail } from '../Icons/Icon/Fail.svg';
import { ReactComponent as ChevronUp } from '../Icons/Icon/ChevronUp.svg';
import { ReactComponent as ChevronLeft } from '../Icons/Icon/ChevronLeft.svg';
import { ReactComponent as LargeChevronRight } from '../Icons/Icon/LargeChevronRight.svg';
import { ReactComponent as LargeChevronUp } from '../Icons/Icon/LargeChevronUp.svg';
import { ReactComponent as LargeChevronDown } from '../Icons/Icon/LargeChevronDown.svg';
import { ReactComponent as LargeChevronLeft } from '../Icons/Icon/LargeChevronLeft.svg';
import { ReactComponent as Error } from '../Icons/Icon/Error.svg';

export type IconType =
  | 'AccountsAndAccess'
  | 'Admin'
  | 'Aggregation'
  | 'App'
  | 'ArrowUp'
  | 'ArrowUpRight'
  | 'Book'
  | 'Brain'
  | 'Calendar'
  | 'Cart'
  | 'Check'
  | 'CheckCircle'
  | 'Checkmark'
  | 'ChevronDown'
  | 'ChevronRight'
  | 'ChipSparkle'
  | 'CloseSmall'
  | 'Code'
  | 'CodeDocument'
  | 'Collapse'
  | 'Columns'
  | 'Comment'
  | 'Compute'
  | 'ComputeV2'
  | 'Copy'
  | 'Danger'
  | 'DataQuality'
  | 'Dataflow'
  | 'DataflowSummary'
  | 'DataflowV2'
  | 'Dataframe'
  | 'Dataset'
  | 'DatasetV2'
  | 'DevelopmentWorkspace'
  | 'Documentation'
  | 'DrawerHide'
  | 'DrawerShow'
  | 'Edit'
  | 'Embedding'
  | 'EmbeddingModel'
  | 'Entities'
  | 'EntitiesV2'
  | 'Exit'
  | 'Expand'
  | 'Eye'
  | 'FeatureBatch'
  | 'FeatureService'
  | 'FeatureServiceV2'
  | 'FeatureStream'
  | 'FeatureView'
  | 'FeatureViewV2'
  | 'Filters'
  | 'FlowRag'
  | 'Folder'
  | 'Fraud'
  | 'GearCogs'
  | 'Group'
  | 'Images'
  | 'Information'
  | 'Integration'
  | 'Jobs'
  | 'KeyChain'
  | 'Lock'
  | 'Log'
  | 'LogOut'
  | 'Logo'
  | 'MagnifyingGlass'
  | 'Mail'
  | 'Monitoring'
  | 'NotSet'
  | 'OfflineDatabase'
  | 'OnlineDatabase'
  | 'PermissionsIcon'
  | 'Plus'
  | 'Popout'
  | 'Preview'
  | 'ProductionWorkspace'
  | 'Prompt'
  | 'Question'
  | 'QuestionInCircle'
  | 'ReceiptOrders'
  | 'Recent'
  | 'Refresh'
  | 'Registry'
  | 'RequestDataSource'
  | 'Retrieval'
  | 'Retry'
  | 'Robot'
  | 'Rocket'
  | 'Running'
  | 'Scalable'
  | 'Search'
  | 'Settings'
  | 'Sources'
  | 'SourcesV2'
  | 'Sparkles'
  | 'SparklesCircle'
  | 'Star'
  | 'StructuredData'
  | 'Support'
  | 'SupportLifeRing'
  | 'SupportV2'
  | 'Tag'
  | 'Template'
  | 'Text'
  | 'Timestamp'
  | 'Timezone'
  | 'Transfer'
  | 'Transformations'
  | 'Trash'
  | 'UnstructuredData'
  | 'UserCircle'
  | 'UserGroup'
  | 'UserInfo'
  | 'Viewer'
  | 'Warning'
  | 'WhatsNew'
  | 'Wordmark'
  | 'Workspaces'
  | 'Fail'
  | 'ChevronUp'
  | 'ChevronLeft'
  | 'LargeChevronLeft'
  | 'LargeChevronRight'
  | 'LargeChevronUp'
  | 'LargeChevronDown'
  | 'Error';

export type IconSize = 'xs' | 's' | 'm' | 'l';

interface IconProps {
  icon: IconType;
  type: 'mono' | 'color' | 'custom';
  size: IconSize;
  color?: string;
}

const Icons: Record<IconType, React.ReactNode> = {
  AccountsAndAccess: <AccountsAndAccess />,
  Admin: <Admin />,
  Aggregation: <Aggregation />,
  App: <App />,
  ArrowUp: <ArrowUp />,
  ArrowUpRight: <ArrowUpRight />,
  Book: <Book />,
  Brain: <Brain />,
  Calendar: <Calendar />,
  Cart: <Cart />,
  Check: <Check />,
  CheckCircle: <CheckCircle />,
  Checkmark: <Checkmark />,
  ChevronDown: <ChevronDown />,
  ChevronUp: <ChevronUp />,
  ChevronLeft: <ChevronLeft />,
  ChevronRight: <ChevronRight />,
  ChipSparkle: <ChipSparkle />,
  CloseSmall: <CloseSmall />,
  Code: <Code />,
  CodeDocument: <CodeDocument />,
  Collapse: <Collapse />,
  Columns: <Columns />,
  Comment: <Comment />,
  Compute: <Compute />,
  ComputeV2: <ComputeV2 />,
  Copy: <Copy />,
  Danger: <Danger />,
  DataQuality: <DataQuality />,
  Dataflow: <Dataflow />,
  DataflowSummary: <DataflowSummary />,
  DataflowV2: <DataflowV2 />,
  Dataframe: <Dataframe />,
  Dataset: <Dataset />,
  DatasetV2: <DatasetV2 />,
  DevelopmentWorkspace: <DevelopmentWorkspace />,
  Documentation: <Documentation />,
  DrawerHide: <DrawerHide />,
  DrawerShow: <DrawerShow />,
  Edit: <Edit />,
  Embedding: <Embedding />,
  EmbeddingModel: <EmbeddingModel />,
  Entities: <Entities />,
  EntitiesV2: <EntitiesV2 />,
  Error: <Error />,
  Exit: <Exit />,
  Expand: <Expand />,
  Eye: <Eye />,
  Fail: <Fail />,
  FeatureBatch: <FeatureBatch />,
  FeatureService: <FeatureService />,
  FeatureServiceV2: <FeatureServiceV2 />,
  FeatureStream: <FeatureStream />,
  FeatureView: <FeatureView />,
  FeatureViewV2: <FeatureViewV2 />,
  Filters: <Filters />,
  FlowRag: <FlowRag />,
  Folder: <Folder />,
  Fraud: <Fraud />,
  GearCogs: <GearCogs />,
  Group: <Group />,
  Images: <Images />,
  Information: <Information />,
  Integration: <Integration />,
  Jobs: <Jobs />,
  KeyChain: <KeyChain />,
  Lock: <Lock />,
  Log: <Log />,
  LogOut: <LogOut />,
  Logo: <Logo />,
  MagnifyingGlass: <MagnifyingGlass />,
  Mail: <Mail />,
  Monitoring: <Monitoring />,
  NotSet: <NotSet />,
  OfflineDatabase: <OfflineDatabase />,
  OnlineDatabase: <OnlineDatabase />,
  PermissionsIcon: <PermissionsIcon />,
  Plus: <Plus />,
  Popout: <Popout />,
  Preview: <Preview />,
  ProductionWorkspace: <ProductionWorkspace />,
  Prompt: <Prompt />,
  Question: <Question />,
  QuestionInCircle: <QuestionInCircle />,
  ReceiptOrders: <ReceiptOrders />,
  Recent: <Recent />,
  Refresh: <Refresh />,
  Registry: <Registry />,
  RequestDataSource: <RequestDataSource />,
  Retrieval: <Retrieval />,
  Retry: <Retry />,
  Robot: <Robot />,
  Rocket: <Rocket />,
  Running: <Running />,
  Scalable: <Scalable />,
  Search: <Search />,
  Settings: <Settings />,
  Sources: <Sources />,
  SourcesV2: <SourcesV2 />,
  Sparkles: <Sparkles />,
  SparklesCircle: <SparklesCircle />,
  Star: <Star />,
  StructuredData: <StructuredData />,
  Support: <Support />,
  SupportLifeRing: <SupportLifeRing />,
  SupportV2: <SupportV2 />,
  Tag: <Tag />,
  Template: <Template />,
  Text: <Text />,
  Timestamp: <Timestamp />,
  Timezone: <Timezone />,
  Transfer: <Transfer />,
  Transformations: <Transformations />,
  Trash: <Trash />,
  UnstructuredData: <UnstructuredData />,
  UserCircle: <UserCircle />,
  UserGroup: <UserGroup />,
  UserInfo: <UserInfo />,
  Viewer: <Viewer />,
  Warning: <Warning />,
  WhatsNew: <WhatsNew />,
  Wordmark: <Wordmark />,
  Workspaces: <Workspaces />,
  LargeChevronDown: <LargeChevronDown />,
  LargeChevronLeft: <LargeChevronLeft />,
  LargeChevronRight: <LargeChevronRight />,
  LargeChevronUp: <LargeChevronUp />,
};

export const IconSizeMap: Record<IconSize, string> = {
  xs: '12px',
  s: '16px',
  m: '24px',
  l: '48px',
};

const IconWrapper = styled.div<{ color: string; size: IconSize }>`
  display: inline-block;
  line-height: 0;

  width: ${({ size }) => {
    return IconSizeMap[size];
  }};
  height: ${({ size }) => {
    return IconSizeMap[size];
  }};

  svg {
    width: 100%;
    height: 100%;
    display: inline;
  }

  path,
  circle,
  ellipse,
  rect {
    stroke: ${({ color }) => {
      return color;
    }};
  }
`;

const Icon: FC<IconProps> = ({ icon, type = 'mono', size = 'm', color }) => {
  const { theme } = useTectonTheme();

  const customIconColors = new Map<IconType, string>([
    ['Dataset', theme.FCOColors.dataset],
    ['DatasetV2', theme.FCOColors.dataset],
    ['Entities', theme.FCOColors.entity],
    ['EntitiesV2', theme.FCOColors.entity],
    ['FeatureView', theme.FCOColors.featureView],
    ['FeatureViewV2', theme.FCOColors.featureView],
    ['FeatureService', theme.FCOColors.featureService],
    ['FeatureServiceV2', theme.FCOColors.featureService],
    ['Transformations', theme.FCOColors.transformation],
    ['Sources', theme.FCOColors.source],
    ['SourcesV2', theme.FCOColors.source],
    ['DevelopmentWorkspace', theme.colors.success],
    ['Prompt', theme.colors.accent],
    ['Aggregation', theme.colors.text],
    ['FeatureBatch', theme.FCOColors.featureView],
    ['FeatureStream', theme.FCOColors.featureView],
    ['Embedding', theme.colors.accent],
    ['Transfer', theme.colors.text],
    ['Registry', theme.colors.text],
    ['Tag', theme.colors.text],
    ['Retrieval', theme.colors.text],
    ['Integration', theme.colors.text],
    ['Timestamp', theme.colors.text],
    ['Scalable', theme.colors.text],
    ['SupportV2', theme.colors.text],
    ['Retrieval', theme.colors.text],
    ['ComputeV2', theme.colors.text],
    ['ChipSparkle', theme.colors.text],
    ['Code', theme.colors.text],
    ['Sparkles', theme.colors.accent],
    ['SparklesCircle', theme.colors.accent],
    ['Brain', theme.colors.accent],
    ['FlowRag', theme.colors.accent],
    ['StructuredData', theme.colors.text],
    ['UnstructuredData', theme.colors.text],
    ['ReceiptOrders', theme.colors.text],
    ['Text', theme.colors.text],
    ['Settings', theme.colors.text],
    ['Dataframe', theme.colors.text],
    ['Log', theme.colors.text],
    ['App', theme.colors.text],
    ['Collapse', theme.colors.text],
    ['Expand', theme.colors.text],
    ['Question', theme.colors.text],
    ['DataflowSummary', theme.colors.text],
    ['EmbeddingModel', theme.colors.text],
    ['Mail', theme.colors.text],
    ['Information', theme.colors.text],
    ['QuestionInCircle', theme.colors.text],
    ['Calendar', theme.colors.text],
    ['Documentation', theme.colors.text],
    ['CheckCircle', theme.colors.text],
    ['Check', theme.colors.text],
    ['CodeDocument', theme.colors.text],
    ['ChevronDown', theme.colors.text],
    ['ChevronRight', theme.colors.text],
    ['Plus', theme.colors.text],
    ['Admin', theme.colors.text],
    ['UserGroup', theme.colors.text],
    ['SupportLifeRing', theme.colors.text],
    ['Retry', theme.colors.text],
    ['Refresh', theme.colors.text],
    ['Warning', theme.colors.text],
    ['NotSet', theme.colors.text],
    ['Recent', theme.colors.text],
    ['Running', theme.colors.text],
    ['GearCogs', theme.colors.text],
    ['Filters', theme.colors.text],
    ['Timezone', theme.colors.text],
    ['Viewer', theme.colors.text],
    ['Edit', theme.colors.text],
    ['Lock', theme.colors.text],
    ['Trash', theme.colors.text],
    ['Rocket', theme.colors.text],
    ['Comment', theme.colors.text],
    ['Popout', theme.colors.text],
    ['Search', theme.colors.text],
    ['RequestDataSource', theme.colors.text],
  ]);

  let stroke =
    type === 'mono' ? theme['v1'].colors.icon.primary : customIconColors.get(icon) ?? theme['v1'].colors.icon.danger;

  if (color) {
    stroke = color;
  }

  return (
    <IconWrapper color={stroke} size={size} theme={theme}>
      {Icons[icon]}
    </IconWrapper>
  );
};

export default Icon;
