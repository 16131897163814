import { useQuery } from '@tanstack/react-query';
import MetadataService from '../../../service/MetadataService';

export function useAllMaterializingFeatureViews() {
  const state = useQuery(['allMaterializing'], () => {
    return MetadataService.sharedInstance().getAllMaterializingFeatureViews();
  });
  if (state.error) {
    throw state.error;
  }
  return state;
}

export function useFindFCOWorkspace(fcoId) {
  const state = useQuery(
    [],
    () => {
      return MetadataService.sharedInstance().getFcoWorkspace(fcoId);
    },
    { enabled: false }
  );
  if (state.error) {
    throw state.error;
  }
  return state;
}

export function useTenantSettings() {
  const state = useQuery(['tenantSettings'], () => {
    return MetadataService.sharedInstance().getUserDeploymentSettings();
  });
  if (state.error) {
    throw state.error;
  }
  return state;
}

export function useQueryMetric(workspace, featureViewName, metricType, startTime, endTime) {
  const state = useQuery(['queryMetric', workspace, featureViewName, metricType, startTime, endTime], () => {
    return MetadataService.sharedInstance().queryMetric(workspace, featureViewName, metricType, startTime, endTime);
  });
  if (state.error) {
    throw state.error;
  }
  return state;
}
