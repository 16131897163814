import styled from '@emotion/styled';

export const CollapsedNavWrapper = styled.div`
  width: 64px;
  height: 100vh;
  overflow: hidden;
  left: 0;
  top: 0;
  background-color: ${({ theme }) => theme.colors.body};
  display: grid;
  grid-template-rows: 80px 1fr 96px;
  gap: 8px;

  &:not(.no-stroke) svg {
    stroke: ${({ theme }) => theme.colors.text};
  }

  a {
    :hover {
      text-decoration: none;
    }
  }
`;

export const CollapsedHeaderWrapper = styled.div`
  margin: auto;
`;

export const CollapsedContentWrapper = styled.div`
  height: auto;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const CollapsedItemGroupWrapper = styled.div`
  margin-left: 12px;
`;

export const CollapsedSpacer = styled.div`
  height: ${({ theme }) => theme.gap.xl};
`;

export const CollapsedFooterWrapper = styled.div`
  margin: auto;
`;

export const NavWrapper = styled.div`
  width: 240px;
  height: 100vh;
  left: 0;
  top: 0;
  background-color: ${({ theme }) => theme.colors.body};
  display: grid;
  padding: 16px 12px 0px 12px;
  overflow: hidden;

  svg.NavigationCollapseIcon {
    display: none;
  }

  &:hover {
    svg.NavigationCollapseIcon {
      display: inline;
    }
  }

  a {
    :hover {
      text-decoration: none;
    }
  }
`;

export const HeaderBlock = styled.div`
  left: 12px;
  top: 0;
  background-color: ${({ theme }) => theme.colors.body};
  z-index: 2;
`;

export const MiddleSpacer = styled.div`
  min-height: 8px;
  flex: 1 0 0;
  align-self: stretch;
  overflow: auto;
`;

export const MiddleSection = styled.div`
  height: 100vh;
  overflow: auto;
  padding-bottom: 100px;
  display: flex;
`;

export const FixedSpacer = styled.div`
  height: ${({ theme }) => theme.gap.xl};
`;

export const FooterBlock = styled.div`
  background-color: ${({ theme }) => theme.colors.body};
  width: 216px;
  height: 64px;
  position: absolute;
  left: 12px;
  bottom: 0;
`;
