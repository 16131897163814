import React, { FC, useContext } from 'react';
import { Tooltip, Box, Typography } from '@mui/material';
import style from './style.module.scss';
import LockIcon from '@mui/icons-material/Lock';
import { AccessControlsWorkspace } from '../../../../feature/access-control/types';
import AccessControlWorkspaceNameSpan from '../../../../feature/access-control/Components/AccessControlWorkspaceNameSpan';
import { UserSettingsContext } from '../../../../components/context/UserSettingsContext';

interface NewWorkspaceSelectorOptionInaccessibleProperties {
  workspace: AccessControlsWorkspace;
}

const NewWorkspaceSelectorOptionInaccessible: FC<NewWorkspaceSelectorOptionInaccessibleProperties> = ({
  workspace,
}) => {
  const userSettingsContext = useContext(UserSettingsContext);
  const isAdmin = userSettingsContext?.isAdmin;

  return (
    <Tooltip
      arrow
      placement="right"
      title={
        <>
          <Typography>
            {isAdmin
              ? `You do not have access to the workspace ${workspace.name}. As an admin, you can assign yourself access from the Accounts & Access page.`
              : `You do not have access to the workspace ${workspace.name}. Contact your Tecton administrator to request access.`}
          </Typography>
        </>
      }
    >
      <Box className={style.workspaceSelectorOuter}>
        <Box className={style.workspaceSelectorOption}>
          <AccessControlWorkspaceNameSpan
            workspace={workspace}
            isInaccessible={true}
            isDevelopment={false}
            showIcon={true}
          />
        </Box>
        <Box className="pr3" sx={{ opacity: 0.5 }}>
          <LockIcon />
        </Box>
      </Box>
    </Tooltip>
  );
};

export default NewWorkspaceSelectorOptionInaccessible;
