import { Callout, Card, FlexGroupWrapper, FlexItem } from '@tecton/ComponentRedesign';
import { FC } from 'react';
import { CustomGraph } from '../../../feature/feature-views/graph-types';
import { FEATURE_SERVER_GROUPS, FEATURE_SERVICE_GLOBAL_SLO } from '../../../feature/graph-configuration';
import { ServerGroupStatus } from '../../../types/tecton_proto/common/server_group_status';
import { MonitoringDateRange } from '../../monitoring/DateRange-Dropdown-util';
import { ServerGroupSelectable } from './FeatureServerGroupLists';
import MonitoringGraphs from './MonitoringGraphs';
import { ServerGroupStatusType } from './RealtimeServerMonitoring';

import styled from '@emotion/styled';
import { Button, Icon, Popover } from '@tecton/ComponentRedesign/lib/v1';
import { Link } from 'react-router-dom';
import { TECTON_DOCUMENTATION_LINKS } from '../application-links';
import SLOReliability30Days from './SLOReliability30Days';

const SLOWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TriggerWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const ButtonLinkWrapper = styled.div`
  width: 100%;
`;

const FeatureServerGroup: FC<{
  selectedServerGroup?: ServerGroupSelectable;
  serverGroupStatusMap: ServerGroupStatusType;
  monitoringDateRange: MonitoringDateRange;
  selectedWorkspace: string;
}> = ({ monitoringDateRange, serverGroupStatusMap, selectedWorkspace, selectedServerGroup }) => {
  const serverGroupName = selectedServerGroup?.serverGroupId
    ? `${selectedWorkspace}/${selectedServerGroup?.name}`
    : `default`;

  const customGraphs: Record<string, React.ReactNode> = {
    [CustomGraph.FEATURE_SERVICE_GLOBAL_SLO_RELIABILITY]: (
      <>
        <Card
          title={
            <>
              <Popover
                body={
                  <>
                    This metric is calculated using a 30-day rolling window. Different time periods show snapshots of
                    these rolling averages at each point within that period.
                  </>
                }
                trigger={
                  <TriggerWrapper>
                    Reliability SLO
                    <Icon type="mono" size="s" icon="Information" />
                  </TriggerWrapper>
                }
                buttons={
                  <ButtonLinkWrapper>
                    <Link to={TECTON_DOCUMENTATION_LINKS.FEATURE_SERVER_PRODUCTION_SLO}>
                      <Button iconLeft="Book" iconRight="Popout" type="secondary" fullWidth label={'Learn more'} />
                    </Link>
                  </ButtonLinkWrapper>
                }
              />
            </>
          }
          noScroll
          centerBody
        >
          <SLOWrapper>
            <SLOReliability30Days monitoringDateRange={monitoringDateRange} />
          </SLOWrapper>
        </Card>
      </>
    ),
  };
  return (
    <>
      <FlexGroupWrapper>
        <FlexItem>
          {selectedServerGroup?.status && (
            <Callout
              mode={
                serverGroupStatusMap[selectedServerGroup?.status ?? ServerGroupStatus.SERVER_GROUP_STATUS_UNSPECIFIED]
                  .status
              }
              title={`Status: ${
                serverGroupStatusMap[selectedServerGroup?.status ?? ServerGroupStatus.SERVER_GROUP_STATUS_UNSPECIFIED]
                  .title
              }`}
            >
              {selectedServerGroup.description}
            </Callout>
          )}
        </FlexItem>
        <FlexItem>
          <MonitoringGraphs
            monitoringDateRange={monitoringDateRange}
            graphsData={FEATURE_SERVICE_GLOBAL_SLO}
            customGraphRecord={customGraphs}
            workspace={selectedWorkspace ?? ''}
          />
        </FlexItem>
        <FlexItem>
          <MonitoringGraphs
            monitoringDateRange={monitoringDateRange}
            graphsData={FEATURE_SERVER_GROUPS}
            customGraphRecord={{}}
            workspace={selectedWorkspace ?? ''}
            serverGroupName={`${serverGroupName}`}
          />
        </FlexItem>
      </FlexGroupWrapper>
    </>
  );
};

export default FeatureServerGroup;
