import React, { createContext, useCallback, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ReactFlowInstance } from '@xyflow/react';
import { DataFlowEdge, NodesMapType } from './dataFlowTypes';
import { getDefaultNodeSize } from './helpers';

export const useReactFlowInstanceRef = () => {
  const reactFlowInstanceRef = useRef<ReactFlowInstance | undefined>();
  const setReactFlowInstance = (reactFlowInstance: ReactFlowInstance) => {
    reactFlowInstanceRef.current = reactFlowInstance;
  };

  return {
    reactFlowInstanceRef,
    setReactFlowInstance,
  };
};

type ReactFlowInstanceRefType = ReturnType<typeof useReactFlowInstanceRef>['reactFlowInstanceRef'];

type DetailsPanelState = {
  nodeId: string | undefined;
  focusType: 'none' | 'hover' | 'clicked';
  DEBUG_MODE: boolean;
  reduceAnimation: boolean;
  denseMode: boolean;
  reactFlowInstanceRef: ReactFlowInstanceRefType | undefined;
  nodesMap: NodesMapType;
  edgesList: DataFlowEdge[];
  nodeIsCurrentlyClicked: (id: string) => boolean;
  detailsPanelHoverNodeId: (id: string) => void;
  detailsPanelClearHover: () => void;
  detailsPanelClickNodeId: (id: string) => void;
  detailsPanelClearAll: () => void;
  getNodePosition?: (id: string) => { x: number; y: number };
  zoomToNode?: (nodeId: string) => void;
};

export const DetailsPanelContext = createContext<DetailsPanelState>({
  nodeId: undefined,
  focusType: 'none',
  DEBUG_MODE: false,
  reduceAnimation: false,
  denseMode: false,
  reactFlowInstanceRef: undefined,
  nodesMap: {},
  edgesList: [],
  nodeIsCurrentlyClicked: () => false,
  detailsPanelHoverNodeId: () => {
    return;
  },
  detailsPanelClearHover: () => {
    return;
  },
  detailsPanelClickNodeId: () => {
    return;
  },
  detailsPanelClearAll: () => {
    return;
  },
});

const DetailsPanelFocusProvider = ({
  positionMap,
  reactFlowInstanceRef,
  nodesMap,
  edgesList,
  denseMode,
  reduceAnimation,
  children,
}: {
  positionMap: Record<string, { x: number; y: number }>;
  reactFlowInstanceRef: ReactFlowInstanceRefType | undefined;
  nodesMap: NodesMapType;
  edgesList: DataFlowEdge[];
  reduceAnimation: boolean;
  denseMode: boolean;
  children: React.ReactNode;
}) => {
  const [nodeId, setNodeId] = useState<DetailsPanelState['nodeId']>();
  const [focusType, setFocusType] = useState<DetailsPanelState['focusType']>('none');

  const [searchParams] = useSearchParams();

  const DEBUG_MODE = searchParams.has('debug');

  const detailsPanelHoverNodeId = (id: string) => {
    if (focusType !== 'clicked') {
      setFocusType('hover');
      setNodeId(id);
    }
  };

  const detailsPanelClearAll = () => {
    setFocusType('none');
    setNodeId(undefined);
  };

  const detailsPanelClearHover = () => {
    if (focusType !== 'clicked') {
      detailsPanelClearAll();
    }
  };

  const nodeIsCurrentlyClicked = (id: string) => {
    if (focusType !== 'clicked') {
      return false;
    } else {
      return id === nodeId;
    }
  };

  const getNodePosition = useCallback(
    (id: string) => {
      const position = positionMap[id];

      if (!position) {
        throw new Error(`Position for node '${id}' not in positionMap.`);
      }

      return position;
    },
    [positionMap]
  );

  const zoomToNode = useCallback(
    (nodeId: string) => {
      if (!getNodePosition) {
        console.warn('getNodePosition is not ready');
        return;
      }

      if (!reactFlowInstanceRef?.current) {
        console.warn(`reactFlowInstanceRef's current value is undefined.`);
        return;
      }

      const node = nodesMap[nodeId];
      if (!node) {
        throw new Error(`No node with the id '${nodeId}`);
      }

      // TODO: find a better way to access this node.
      // Using an ID here means that we can only ever have one instance of the dataflow diagram on a page.
      const detailsPanelDOMNode = document.getElementById('tecton-dataflow-context-panel');

      /**
       * In order to center the node on the canvas when the details
       * panel is open, we need to adjust for the width of the
       * details panel. i.e.
       *
       *     w     p
       * +-------+---+
       * |   x   |   |
       * +-------+---+
       *
       * React Flow's setCenter method naively centers the canvas
       * assuming it is full width (w + p). With the panel open
       * however, we need to center it within v. So we
       *
       * 1. getBoundingClientRect of the details panel
       * 2. Divide by two to get the amount of the adjustment.
       */

      let detailsPanelAdjustment = 0;
      if (detailsPanelDOMNode) {
        const detailsPanelRect = detailsPanelDOMNode.getBoundingClientRect();
        detailsPanelAdjustment = detailsPanelRect.width / 2;
      }

      const nodeSize = getDefaultNodeSize(node);

      const nodePosition = getNodePosition(nodeId);

      const zoomCoordinateX = nodePosition.x + nodeSize[1] / 2 + detailsPanelAdjustment;
      const zoomCoordinateY = nodePosition.y + nodeSize[0] / 2;

      const ZOOM_DURATION_MS = 300;
      const ZOOM_LEVEL = 1;

      reactFlowInstanceRef.current.setCenter(zoomCoordinateX, zoomCoordinateY, {
        duration: ZOOM_DURATION_MS,
        zoom: ZOOM_LEVEL,
      });
    },
    [getNodePosition, reactFlowInstanceRef, nodesMap]
  );

  const detailsPanelClickNodeId = (id: string) => {
    setFocusType('clicked');
    setNodeId(id);

    zoomToNode(id);
  };

  return (
    <DetailsPanelContext.Provider
      value={{
        nodeId,
        focusType,
        DEBUG_MODE,
        denseMode,
        reduceAnimation,
        nodesMap,
        edgesList,
        reactFlowInstanceRef,
        detailsPanelHoverNodeId,
        detailsPanelClearHover,
        detailsPanelClickNodeId,
        detailsPanelClearAll,
        nodeIsCurrentlyClicked,
        getNodePosition,
        zoomToNode,
      }}
    >
      {children}
    </DetailsPanelContext.Provider>
  );
};

export default DetailsPanelFocusProvider;
