import { Metadata_Service } from './';
import { useQuery } from '@tanstack/react-query';
import { GetFeatureAnalyticsResponse } from '../types/tecton_proto/metadataservice/metadata_service';

const fetchFeatureAnalytics = async (): Promise<GetFeatureAnalyticsResponse> => {
  const { data } = await Metadata_Service('get-feature-analytics', { method: 'POST' });
  return data;
};

export const useFeatureAnalytics = <T = GetFeatureAnalyticsResponse>() => {
  return useQuery(['get-feature-analytics'], () => fetchFeatureAnalytics());
};
