import React, { useState } from 'react';
import styled from '@emotion/styled';
import { ButtonEmpty, Panel, Title } from '@tecton';
import { DataFlowEdge, PathDataflowType } from './dataFlowTypes';
import { PanelCloseButton } from './DetailsPanel/StyledPanelComponents';
import {
  BackgroundPath,
  BatchMaterializationPath,
  OfflineReadPath,
  OnlineRequestPath,
  OnlineResponsePath,
  StreamMaterializationPath,
} from './DataFlowEdge';

const EDGE_LENGTH = 180;

const GAP = `24px`;
const PADDING = `16px`;

const LegendTitle = styled(Title)`
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-weight: 600;
  font-size: 10px;
`;

const LegendPanel = styled(Panel)<{ isClosed: boolean }>`
  width: calc(2 * ${PADDING} + ${GAP} + 2 * ${EDGE_LENGTH}px);
  padding: 14px ${PADDING} 10px;

  transition: transform 0.4s, opacity 0.2s;
  transition-timing-function: ease-in-out;
  transform: translate(0, ${(props) => (props.isClosed ? 'calc(100% + 2em)' : '0')});
  opacity: ${(props) => (props.isClosed ? '0' : '1')};

  position: absolute;
  bottom: 8px;
  left: 8px;
  z-index: 200;
`;

const ShowLegend = styled(ButtonEmpty)`
  position: absolute;
  bottom: 8px;
  left: 8px;
  z-index: 100;
`;

const PanelContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 2fr;
  gap: ${GAP};
`;

const EdgeSection = styled.div`
  li {
    margin: 8px 0;
    cursor: pointer;
  }

  li > p {
    font-size: 10px;
    line-height: 1.2;
    margin: 0;
    padding: 0;
  }

  li:hover > p {
    font-weight: 600;
  }

  li svg {
    display: block;
  }
`;

const DataflowLegend = ({
  setDataflowMode,
  reduceAnimation,
}: {
  edgesList: DataFlowEdge[];
  setDataflowMode: (pathTypes: PathDataflowType[] | undefined) => void;
  reduceAnimation: boolean;
}) => {
  const [localClosed, setLocalClosed] = useState<boolean>(false);

  const highlightDataflowType = (type?: PathDataflowType | undefined) => {
    if (!type) {
      setDataflowMode(undefined);
    } else {
      setDataflowMode([type]);
    }
  };

  const mouseOut = () => {
    highlightDataflowType();
  };

  return (
    <>
      <ShowLegend
        size="xs"
        onClick={() => {
          setLocalClosed(false);
        }}
      >
        Show Legend
      </ShowLegend>
      <LegendPanel hasShadow paddingSize="s" isClosed={localClosed}>
        <LegendTitle size="xxxs">
          <h6>Dataflow Legend</h6>
        </LegendTitle>
        <PanelCloseButton
          aria-label="Close Legend Panel"
          iconType={'cross'}
          onClick={() => {
            setLocalClosed(true);
          }}
        />
        <PanelContent>
          <EdgeSection>
            <ul>
              <li
                onFocus={() => {
                  highlightDataflowType('isStreamMaterializationPath');
                }}
                onMouseOver={() => {
                  highlightDataflowType('isStreamMaterializationPath');
                }}
                onMouseOut={mouseOut}
                onBlur={mouseOut}
              >
                <p>Stream Materialization</p>
                <svg width={`${EDGE_LENGTH}px`} height="5px" style={{ display: 'block' }}>
                  <BackgroundPath d={`M 0 3 L ${EDGE_LENGTH} 3`} displayMode={'default'} />
                  <StreamMaterializationPath
                    d={`M 0 3 L ${EDGE_LENGTH} 3`}
                    displayMode={'default'}
                    reduceAnimation={reduceAnimation}
                  />
                </svg>
              </li>

              <li
                onFocus={() => {
                  highlightDataflowType('isBatchMaterializationPath');
                }}
                onMouseOver={() => {
                  highlightDataflowType('isBatchMaterializationPath');
                }}
                onMouseOut={mouseOut}
                onBlur={mouseOut}
              >
                <p>Batch Materialization</p>
                <svg width={`${EDGE_LENGTH}px`} height="5px" style={{ display: 'block' }}>
                  <BackgroundPath d={`M 0 3 L ${EDGE_LENGTH} 3`} displayMode={'default'} />
                  <BatchMaterializationPath
                    d={`M 0 3 L ${EDGE_LENGTH} 3`}
                    displayMode={'default'}
                    reduceAnimation={reduceAnimation}
                  />
                </svg>
              </li>
            </ul>
          </EdgeSection>
          <EdgeSection>
            <ul>
              <li
                onFocus={() => {
                  highlightDataflowType('isOnlineServingPath');
                }}
                onMouseOver={() => {
                  highlightDataflowType('isOnlineServingPath');
                }}
                onMouseOut={mouseOut}
                onBlur={mouseOut}
              >
                <p>Online Serving</p>
                <svg width={`${EDGE_LENGTH}px`} height="5px" style={{ display: 'block' }}>
                  <BackgroundPath d={`M 0 3 L ${EDGE_LENGTH} 3`} displayMode={'default'} />
                  <OnlineResponsePath
                    d={`M 0 3 L ${EDGE_LENGTH} 3`}
                    displayMode={'default'}
                    reduceAnimation={reduceAnimation}
                  />
                  <OnlineRequestPath
                    d={`M 0 3 L ${EDGE_LENGTH} 3`}
                    displayMode={'default'}
                    reduceAnimation={reduceAnimation}
                  />
                </svg>
              </li>

              <li
                onFocus={() => {
                  highlightDataflowType('isOfflineReadPath');
                }}
                onMouseOver={() => {
                  highlightDataflowType('isOfflineReadPath');
                }}
                onMouseOut={mouseOut}
                onBlur={mouseOut}
              >
                <p>Batch Data Generation</p>
                <svg width={`${EDGE_LENGTH}px`} height="5px" style={{ display: 'block' }}>
                  <BackgroundPath
                    d={`M 0 3 L ${EDGE_LENGTH} 3`}
                    displayMode={'default'}
                    reduceAnimation={reduceAnimation}
                  />
                  <OfflineReadPath
                    d={`M 0 3 L ${EDGE_LENGTH} 3`}
                    displayMode={'default'}
                    reduceAnimation={reduceAnimation}
                  />
                </svg>
              </li>
            </ul>
          </EdgeSection>
        </PanelContent>
      </LegendPanel>
    </>
  );
};

export default DataflowLegend;
