import React, { FC } from 'react';
import { usePrometheus } from '../../../query';
import { getFeatureHealthResults } from '../../../graph-util';
import _ from 'lodash';

import { MonitoringDateRange } from '../../../../components/monitoring/DateRange-Dropdown-util';
import FeatureViewHealthTable from './FeatureViewHealthTable';

interface FeatureViewFailingIdsProps {
  monitoringDateRange: MonitoringDateRange;
}

const FeatureViewFailingIds: FC<FeatureViewFailingIdsProps> = ({ monitoringDateRange }) => {
  const queries = [
    {
      label: 'Failing Feature View IDs',
      query: `count by (tecton_cluster, alertname, feature_package_id, workspace_name, feature_view_name) (ALERTS{alertstate='firing', alertname=~'BatchMaterializationTaskRecurrentFailures|StreamingMaterializationTaskRecurrentFailures|MaterializationTaskMovedToPermanentFailure|FeatureTableIngestTaskRecurrentFailures', tecton_cluster=~'.*', aws_region=~'.*'})`,
    },
  ];

  const state = usePrometheus(queries, monitoringDateRange);
  if (state.isLoading) {
    return <>Loading...</>;
  }

  const result = _.get(state, 'data');
  const healthResults: any = getFeatureHealthResults(result ? result : []);
  return <FeatureViewHealthTable columnLabel="Failing Feature View IDs" healthResults={healthResults} />;
};

export default FeatureViewFailingIds;
